import React from "react";
import ReactDOM from "react-dom";
import "./custom.scss";
import "./index.css";
import App from "./App.js";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import store from 'store';

Sentry.init({
    dsn: "https://4a5d55c7026a4846bf64ca2bb8c8576c@o302617.ingest.sentry.io/5740680", environment: process.env.NODE_ENV,
    beforeBreadcrumb(breadcrumb, hint) {
        return breadcrumb.category === "console" ? null : breadcrumb;
    },
});

ReactDOM.render(
    <Router>
        <Switch>
            <Route path={"/:slug"}>
                <Provider store={store}>
                    <App />
                </Provider>
            </Route>
            <Route
                path={"/"} render={() => {
                window.location.href = "https://events.liveto.io";
                return null;
            }} />
        </Switch>
    </Router>,
    document.getElementById("root")
);

document.getElementById("root")
