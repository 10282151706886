/** @format */

import Lobby_icon_white from "./static/img/Lobby_icon_white.svg";
import Stream_icon_white from "./static/img/Stream_icon_white.svg";
import Partners_icon_white from "./static/img/Partners_icon_white.svg";
import Networking_icon_white from "./static/img/Networking_icon_white.svg";
import More_icon_white from "./static/img/More_icon_white.svg";
import Rooms_icon_white from "./static/img/Rooms_icon_white.svg";

export const HOST = process.env.REACT_APP_API_URL || "https://api.staging.liveto.io/";
export const WS_HOST = process.env.REACT_APP_WS_URL || "wss://staging.liveto.io/ws/chat/";
export const MEET_DOMAIN = process.env.REACT_APP_MEET_DOMAIN || "meet.liveto.io";

export const LINKEDIN_SIGNIN_PATH = `${HOST}oauth/login/linkedin-oauth2/?next=`;
export const FACEBOOK_SIGNIN_PATH = `${HOST}oauth/login/facebook/?next=`;
export const TWITTER_SIGNIN_PATH = `${HOST}oauth/login/twitter/?next=`;
export const GOOGLE_SIGNIN_PATH = `${HOST}oauth/login/google-oauth2/?next=`;

export const API_PATH = `${HOST}api/v1/`;
export const ADMIN_PATH = `${HOST}api/adm/`;

export const UNSUPPORTED_BROWSER_COOKIE = "unsupported_browser";
export const UNSUPPORTED_BROWSER_ACCEPTED = "unsupported_browser_accept";

// When true, this sets the 'secure' attribute for login cookies
// Doing so will break logins on non-HTTPS connections (i.e. the development server)
export const USE_SECURE_COOKIE = false;

// Default search parameters
export const SEARCH_LIMIT = 12;
export const EVENT_TYPES = ["", "live", "online", "hybrid"];

export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN || ".liveto.io";
export const CUID_COOKIE = process.env.REACT_APP_COOKIE_PREFIX + "_cuid";
export const AUTH_COOKIE = process.env.REACT_APP_COOKIE_PREFIX + "_auth";
export const AUTH_COOKIE_TIMESTAMP = process.env.REACT_APP_COOKIE_PREFIX + "_login_ts";

export const GRIDMAP = {
	12: 1,
	11: 1,
	10: 1,
	9: 1,
	8: 1,
	7: 1,
	6: 2,
	5: 2,
	4: 3,
	3: 4,
	2: 6,
	1: 12
};

export const ICONMAP = {
	lobby: Lobby_icon_white,
	streams: Stream_icon_white,
	meets: Networking_icon_white,
	custom: Rooms_icon_white,
	partners: Partners_icon_white,
	other: More_icon_white
};

export const APP_PATHS = {
	organizers: "/organizers",
	events: "/events",
	streams: "/streams",
	virtual: "", // CHANGE FOR PRODUCTION!!!!
	tools: "/tools",
	user: "/user",
	login: "/login",
	signup: "/signup"
};

export const AVAILABLE_LOCALES = {
	en: "English",
	fi: "Finnish"
};

export const DEFAULT_LOCALE = "fi";

export const CHAT_REACTIONS = ["smile", "grin", "rofl", "wave", "thumbs_up", "clap", "heart", "sick"];

export const QUERY_LIMIT = 3000;
