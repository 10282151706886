export const getLocString = (l10n, id) => {
    return l10n.getString(id)
}
export const sortArray = (list, cond) => {
    const sorted = list.sort((a, b) => a[cond]?.toLowerCase() > b[cond]?.toLowerCase() ? 1 : -1);
    return sorted
}
export const sortStringArr = (list) => {
    return list.sort((a, b) => (a.toLowerCase() > b.toLowerCase()) ? 1 : -1)
}
export const checkLists = (arr1 = [], arr2 = []) => {
    const cond1 = arr1.every(a => arr2.includes(a))
    const cond2 = arr2.every(a => arr1.includes(a))
    return cond1 && cond2
}
export const stripFromPunctuations = (word) => {
    return word.replace(/[.,\/#!$%\^&\*;:{}=\_`~()]/g, "").trim();
}