/** @format */

export const generateModalClasses = (rootClass, displayType = "") => {
	return {
		root: rootClass,
		inner: `${rootClass}-inner ${displayType}`,
		modal: `${rootClass}-modal ${displayType}`,
		header: `${rootClass}-header ${displayType}`,
		overlay: `${rootClass}-overlay ${displayType}`,
		title: `${rootClass}-title ${displayType}`,
		body: `${rootClass}-body ${displayType}`,
	};
};
