import * as a from 'actiontypes'
import notificationSound from 'static/sound/notification.mp3'

const NOTIFICATION_SOUND = new Audio(notificationSound)

export function soundNotificationMiddleware({ dispatch, getState }) {
  return next => action => {
    try {
      const isAlert = action.type === a.ADD_ALERT
      if (isAlert) {
        const { payload = {} } = action
        const { notification_settings = {} } = getState().user
        const { setting } = payload
        const isSoundOn = checkSetting(notification_settings, setting)
        if (isSoundOn) {
          const playSound = NOTIFICATION_SOUND.play()
          if (playSound !== undefined) {
            playSound.then(() => { }).catch(error => console.error(error));
          }
        }
      }
      return next(action);
    } catch (error) {
      console.error(error)
    }
  };
}

const checkSetting = (notification_settings = {}, setting) => {
  const settingToCheck = notification_settings[`${setting}_sound`]
  if (settingToCheck === undefined) return false
  if (settingToCheck) return true
  return false
}