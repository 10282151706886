const initialState = {
    data: null,
    status: null,
    loaded: false,
};

const roomReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ROOM_LOAD': return { ...state, loaded: false };
        case 'ROOM_FETCHED': return { ...state, data: action.payload.data };
        case 'ROOM_LOADED': return { ...state, loaded: true, status: action.payload.status };
        default: return state;
    }
};

export default roomReducer;
