import React, { useEffect, useState } from "react";
import { SketchPicker } from 'react-color';
import useDebounce from "../hooks";
import '../styles/widgets.scss';

const fallbackBg = {
  color: 'rgba(0,0,0,0)',
  blendMode: 'unset',
}

export default function OverlayWidget(props) {
  const { identifiers, editProperties, properties, editLocks, editorDispatch, room } = props;
  const property = 'overlay';
  const value = properties[property] ? properties[property] : fallbackBg;
  let { color, blendMode } = value;
  if (properties[property] && typeof (properties[property]) !== 'object') {
    color = splitRGBA(properties[property]);
    blendMode = fallbackBg.blendMode;
  }

  const [overlayColor, setOverlayColor] = useState(null)

  const debouncedColor = useDebounce(overlayColor, 500)

  useEffect(() => {
    if (debouncedColor) {
      const { r, g, b, a } = overlayColor;
      const newColor = `rgba(${r},${g},${b},${a})`;
      const overlayObject = {
        color: newColor,
        blendMode
      }
      editProperties(identifiers, property, overlayObject, editorDispatch, room, editLocks);
    }
  }, [debouncedColor])

  // SketchPicker displaying the correct color value on history travel
  useEffect(() => {
    setOverlayColor(null)
  }, [color])

  function splitRGBA(rgba) {
    const splittedColor = rgba.replace('rgba(', '').replace(')', '').split(',');
    return {
      r: splittedColor[0],
      g: splittedColor[1],
      b: splittedColor[2],
      a: splittedColor[3],
    }
  }

  function setOverlay(newValue) {
    let newBlendMode = newValue;
    const overlayObject = {
      color,
      blendMode: newBlendMode,
    }
    editProperties(identifiers, property, overlayObject, editorDispatch, room, editLocks);
  }

  const blendModes = [
    'normal',
    'multiply',
    'screen',
    'overlay',
    'darken',
    'lighten',
    'color-dodge',
    'color-burn',
    'hard-light',
    'soft-light',
    'difference',
    'exclusion',
    'hue',
    'saturation',
    'color',
    'luminosity'
  ]

  const blendModeOptions = blendModes.map(mode => {
    return <option value={mode}>{mode}</option>
  })

  return (<div className="tool-inner-container">
    OVERLAY
    <div className="tools">
      <div className="tool">
        Blend mode
        <select onChange={e => setOverlay(e.target.value)} value={blendMode} className="widget-select">
          {blendModeOptions}
        </select>
      </div>

    </div>
    <div className="tools">
      Color
      <SketchPicker color={overlayColor || color} onChange={(e) => setOverlayColor(e.rgb)} style={{ boxShadow: 'none' }} />
    </div>
  </div>);
};