import parse from 'html-react-parser';
import Helmet from 'react-helmet';

export default function Embed(props) {
    // TODO: Add a wrapper for click events?
    const { elem, hilight, elementClick, editorMode } = props;
    const { content } = elem;
    const { script = "", container = "" } = content;
    const embed = parse(container);
    const styles = { pointerEvents: editorMode ? 'none' : '', width: '100%', height: '100%' }
    return <>
        {script && <Helmet>{parse(script)}</Helmet>}
        {<div style={styles}>{embed}</div>}
    </>;
}
