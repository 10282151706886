const sortTheLeaderboard = (a, b) => {
    if (a.xp === b.xp) {
        if (a.positon > b.position) return 1
        else return -1
    } else if (a.xp < b.xp) return 1
    else return -1
}

export const populateLeaderboard = (leaderboard, attendees, myChatID, isGuest, windowHeight) => {
    let leaderboardWithUserInfo = []
    let isInLeaderboard = isGuest ? true : false

    for (const prop in leaderboard) {
        let attendee = attendees.find(a => a.user_id === prop)
        if (attendee) {
            attendee.xp = leaderboard[prop].xp
            attendee.position = leaderboard[prop].position
            leaderboardWithUserInfo.push(attendee)
        }
    }

    leaderboardWithUserInfo = leaderboardWithUserInfo.sort((a, b) => sortTheLeaderboard(a, b))

    leaderboardWithUserInfo.forEach(u => u.position = u.position + 1)

    leaderboardWithUserInfo = leaderboardWithUserInfo.filter(u => u.type !== "anonymous")

    const leaderboardLength = leaderboardWithUserInfo.length
    
    let first = leaderboardWithUserInfo[0]
    let second = leaderboardWithUserInfo[1]
    let third = leaderboardWithUserInfo[2]

    let top4to10 = []

    if (windowHeight <= 568) {
        top4to10 = []
        const top3 = leaderboardWithUserInfo.filter(i => leaderboardWithUserInfo.indexOf(i) < 3)
        if (top3.map(u => u.user_id).includes(myChatID) || isGuest) isInLeaderboard = true
    } else if (windowHeight > 568 && windowHeight <= 926) {
        top4to10 = leaderboardWithUserInfo.filter(i => leaderboardWithUserInfo.indexOf(i) > 2 && leaderboardWithUserInfo.indexOf(i) < 5)
        if (leaderboardWithUserInfo.filter(l => leaderboardWithUserInfo.indexOf(l) < 5).map(u => u.user_id).includes(myChatID) || isGuest) isInLeaderboard = true
    } else {
        top4to10 = leaderboardWithUserInfo.filter(i => leaderboardWithUserInfo.indexOf(i) > 2 && leaderboardWithUserInfo.indexOf(i) < 10)
        if (leaderboardWithUserInfo.filter(l => leaderboardWithUserInfo.indexOf(l) < 10).map(u => u.user_id).includes(myChatID) || isGuest) isInLeaderboard = true
    }

    let myLeaderBoardPosition = null

    if (leaderboard[myChatID]) {
      myLeaderBoardPosition = (parseInt(leaderboard[myChatID].position) + 1)
    }

    return { first, second, third, top4to10, isInLeaderboard, leaderboardLength, myLeaderBoardPosition }
}