import React, { Children, useEffect, useState } from "react";

import { negotiateLanguages } from "@fluent/langneg";
import { FluentBundle, FluentResource } from "@fluent/bundle";
import { ReactLocalization, LocalizationProvider } from "@fluent/react";

import LoaderView from "LoaderView/loaderview";
import { useCurrentLanguage, usePossibleLanguages } from "util/localization";
import { API_PATH } from "constants.js";

const DEFAULT_LOCALE = "en";
const AVAILABLE_LOCALES = {
    "en": "English",
    "fi": "Finnish",
    "se": "Swedish"
};

async function fetchMessages(locale, eventSlug) {
    // Temporary solution
    const addresses = [
        'header.ftl',
        'main.ftl',
        'loginForm.ftl',
        'chat.ftl',
        'userProfile.ftl',
        'polls.ftl',
        'moderator.ftl',
        'onetoonemeet.ftl',
        'virtualevent.ftl',
        'notificationCenter.ftl',
        'invitation.ftl',
        'calendar.ftl',
        'engagement.ftl',
        'errors.ftl'
    ]

    
    let messages = await Promise.all(addresses.map(addr =>
        fetch(`/locales/${locale}/${addr}`).then(resp => resp.text()).catch(err => "")
    ));

    let custom = await fetch(`${API_PATH}events/${eventSlug}/lobby/custom.${locale}.ftl/`).then(resp => resp.text()).catch(err => "");
    messages += custom;

    return [locale, messages];
}

function* lazilyParsedBundles(fetchedMessages) {
    for (let [locale, messages] of fetchedMessages) {
        let resource = new FluentResource(messages);
        let bundle = new FluentBundle(locale);
        bundle.addResource(resource, { allowOverrides: true });
        yield bundle;
    }
}

function AppLocalizationProvider(props) {
    const { possibleLanguages, eventSlug } = props;
    let [l10n, setL10n] = useState(null);
    const currentLanguage = useCurrentLanguage();

    // Initialize the provider with all the possible languages in the user preferred order
    useEffect(() => {
        changeLocales(possibleLanguages);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Update the current wanted language after initialization
    useEffect(() => {
        if (currentLanguage) {
            changeLocales([currentLanguage]);
        }
    }, [currentLanguage])

    async function changeLocales(userLocales) {
        let currentLocales = negotiateLanguages(
            userLocales,
            Object.keys(AVAILABLE_LOCALES),
            { defaultLocale: DEFAULT_LOCALE }
        );

        let fetchedMessages = await Promise.all(
            currentLocales.map(locale => fetchMessages(locale, eventSlug))
        );

        let bundles = lazilyParsedBundles(fetchedMessages);
        setL10n(new ReactLocalization(bundles));
    }
    if (l10n === null) {
        return <LoaderView loadingContent="Loading localization for event.."/>;
    }

    return (
        <LocalizationProvider l10n={l10n}>
            {Children.only(props.children)}
        </LocalizationProvider>
    )
}

export default AppLocalizationProvider;