import cloneDeep from "lodash/cloneDeep";

export const editorInitial = {
    data: null,
    status: null,
    loaded: false,
    changed: false,
    tempData: null,
    useTemplate: false,
    crated: null,
    newItem: false,
    history: [],
    currentPointer: 0,
    historyMax: 5,
    historyLabels: []
};

// NEED TO MERGE THIS AND THE OTHER REDUCER in ./reducers/editor.js

export const editorReducer = (state, action) => {
    switch (action.type) {
        case "EDITOR_ROOM_LOAD":
            return editorInitial;
        case "EDITOR_ROOM_FETCHED":
            return {
                ...state,
                data: action.payload.data,
                status: action.payload.status,
                history: [action.payload.data],
                historyLabels: [{ text: 'Initial room', time: new Date().getTime() }]
            };
        case "EDITOR_ROOM_LOADED":
            return { ...state, loaded: true };
        case "EDITOR_NEW_COMPONENT":
            return { ...state, data: action.payload, changed: true, newItem: true };
        case "EDITOR_NEW_ELEMENT":
            return { ...state, data: action.payload.room, changed: true, created: true, newItem: action.payload.newItem };
        case "EDITOR_MOVE_ELEMENT":
            return { ...state, data: action.payload, changed: true };
        case "EDITOR_DELETE_ITEM":
            return { ...state, data: action.payload, changed: true };
        case "EDITOR_LAYOUT_CHANGE":
            return {
                ...state,
                data: action.payload,
                changed: action.changed,
            };
        case "EDITOR_PROPERTY_CHANGE":
            return { ...state, data: action.payload, changed: true };
        case "EDITOR_ACTION_CHANGE":
            return { ...state, data: action.payload, changed: true };
        case "EDITOR_CONTENT_CHANGE":
            return { ...state, data: action.payload, changed: true };
        case "EDITOR_NAME_CHANGE":
            return { ...state, data: action.payload, changed: true };
        case "EDITOR_CHANGE_SAVED":
            return { ...state, changed: false };
        case "EDITOR_BREAKPOINT_CHANGE":
            return { ...state };
        case "EDITOR_ITEM_ACTIVATED":
            return { ...state, newItem: false, created: null };
        case "SET_TEMPLATE": {
            const data = cloneDeep(state.data);
            let tempData = cloneDeep(state.tempData)
            if (!state.useTemplate) {
                tempData = state.data;
            }
            data["components"] = action.payload.components;
            data["styles"] = action.payload.styles;

            return { ...state, data: data, tempData: tempData, useTemplate: true };
        }
        case "RESET_TEMPLATE": {
            if (state.useTemplate) {
                return { ...state, data: state.tempData, tempData: null, useTemplate: false };
            }
            return { ...state };
        }
        case "PUSH_TO_HISTORY": {
            const { history, currentPointer, historyMax, historyLabels } = cloneDeep(state);
            const { room: incomingValue, label } = action.payload;
            let newPointer = currentPointer;
            if (history[currentPointer] !== incomingValue) {
                if (currentPointer < history.length - 1) {
                    history.splice(currentPointer + 1)
                    historyLabels.splice(currentPointer + 1)
                }
                history.push(incomingValue)
                historyLabels.push(label)
                while (history.length > historyMax) {
                    history.shift()
                    historyLabels.shift()
                }
                newPointer = history.length - 1;
            }
            return { ...state, history, historyLabels, currentPointer: newPointer }
        }
        case "HISTORY_TRAVEL": {
            const pointer = state.currentPointer + action.payload;
            if (pointer > state.history.length || pointer < 0) return state;
            const fromHistory = state.history[pointer];
            if (!fromHistory) return state
            return { ...state, data: fromHistory, currentPointer: pointer }
        }
        case "HISTORY_TRAVEL_TO": {
            const pointer = action.payload;
            const clonedState = cloneDeep(state)
            const toHistory = clonedState.history[pointer];
            if (!toHistory) return state
            return { ...state, data: toHistory, currentPointer: pointer }
        }
        default:
            return { ...state };
    }
};
