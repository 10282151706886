import React from 'react';

export const OrganizerContext = React.createContext();

export const defaultSettings = {
    view: null,
    isEditor: false,
    preventCollision: true,
    activeItem: null,
    setActiveItem: null,
    userType: 'guest',
    languages: null,
    showTemplates: false,
    showSiteMap: false,
    darkMode: false

}

export const SettingContext = React.createContext(defaultSettings);