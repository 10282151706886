/** @format */

import { IconTimes, LivetoLoader } from "components/icons";
import HeaderModal from "headingModal/modal";
import "./groupSelector.scss";
import { Localized } from "@fluent/react";
import { useState } from "react";
import Select from "react-select";
import { useHandleGroupMembership } from "../../apiCalls";
import { useDispatch } from "react-redux";
import { statusHandler } from "containers/VirtualEvent/editorView/errors";
import { handleLogout } from "../hooks/hooks";

const GroupSelectorModal = props => {
	const { show, setShow, groups = [], slug } = props;
	const [info, setInfo] = useState({ error: false, msg: "" });
	const [joined, setJoined] = useState([]);
	const [action, setAction] = useState(null);
	const [data, setData] = useState(false);
	const options = groups
		.filter(gr => gr.joinable)
		.map(g => {
			return { label: g.name, value: g.slug };
		});
	const [status, error, success, loading, setHandled] = useHandleGroupMembership(action, data, slug);
	const dispatch = useDispatch();

	const handleSubmit = () => {
		dispatch({ type: "INIT_GROUPS", payload: true });
	};

	const disableSubmitButton = () => {
		return loading || error || !success || joined.length === 0;
	};

	const handleGroups = groupArr => {
		// react-select multi select element is returning an array, so the onChange is kinda messy, there might be a better way of doing this
		const incomingVal = groupArr ? groupArr : [];
		if (joined.length === 0) {
			const [newVal] = incomingVal;
			setJoined(incomingVal);
			setData(newVal.value);
			setAction("join");
			setHandled(true);
		} else {
			let arrToFilter = [];
			let filterCond = [];

			if (joined.length > incomingVal.length) {
				arrToFilter = joined;
				setData(incomingVal.value);
				setAction("leave");
			} else {
				arrToFilter = incomingVal;
				filterCond = joined;
				setAction("join");
			}
			const [newValue] = arrToFilter.filter(el => !filterCond.map(g => g.value).includes(el.value));
			setJoined(incomingVal);
			setData(newValue.value);
			setHandled(true);
		}
	};
	return (
		<HeaderModal
			showModal={show}
			id="session-warning"
			modalClassName="warning-modal"
			backdrop="static"
			keyboard={false}
		>
			<div className="warning-card">
				<div className="warning-card-header">
					<div className="warning-card-title">
						<Localized id="virtual-event-group-selector-title">Join user group</Localized>
					</div>
				</div>
				<div className="warning-card-body group-selector-modal-body">
					<Localized id="virtual-event-group-selector-description">
						Event organizer requires you to join at least one user group before you can attend the event
					</Localized>
					<Select
						options={options}
						isMulti={true}
						placeholder={<Localized id="virtual-event-group-selector-placeholder" />}
						className={`select-group generic-input`}
						menuPortalTarget={document.body}
						styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
						onChange={value => handleGroups(value)}
						isClearable={false}
					/>
				</div>
				<div className="warning-card-footer">
					<div className={`info ${info?.error ? "error" : ""}`}>
						{loading ? <LivetoLoader size="50" /> : ""}
						{error && statusHandler(status)}
					</div>
					<div className="buttons">
						<button className="generic-liveto decline" onClick={() => handleLogout(dispatch)}>
							<Localized id="error-logout-button">Logout</Localized>
						</button>
						<button
							className="generic-liveto accept"
							disabled={disableSubmitButton()}
							onClick={handleSubmit}
						>
							<Localized id="virtual-event-group-selector-send">Go to event</Localized>
						</button>
					</div>
				</div>
			</div>
		</HeaderModal>
	);
};

export default GroupSelectorModal;
