import React, { useEffect, useState } from "react";
import {
    IconArrowDown,
    IconArrowRight,
    IconArrowLeft,
    IconArrowUp,
} from "components/icons";
import '../styles/widgets.scss';
import { GenericTooltip } from "components/ToolTip/Tooltip";
import { FlexRowIcon, FlexRowReverseIcon, FlexColumnIcon, FlexColumnReverseIcon } from "components/icons";

const fallbackFlex = 'row';

export default function FlexDirectionWidget(props) {
    const { identifiers, editProperties, properties, editLocks, editorDispatch, room } = props;
    const property = 'flexDirection';
    const value = properties[property] ? properties[property] : fallbackFlex;
    const [flexDirection, setFlexDirection] = useState(value);
    const changeValue = (value) => {
        editProperties(identifiers, property, value, editorDispatch, room, editLocks);
    };
    useEffect(() => {
        setFlexDirection(value);
    }, [value, identifiers, editProperties, property])

    return (
        <div className="tool-inner-container alignment">
            <label>Flex direction</label>

            <div className="tools">
                <GenericTooltip text={'Lay inner elements from left to right'} show={200} hide={200} orientation={'bottom'}>
                    <div className="tool">
                        ROW
                        <button className={"simple-button" + (value === 'row' ? ' active' : '')} onClick={() => changeValue('row')}>
                            <FlexRowIcon />
                        </button>
                    </div>
                </GenericTooltip>

                <GenericTooltip text={'Lay inner elements from right to left'} show={200} hide={200} orientation={'bottom'}>
                    <div className="tool">
                        ROW REVERSE
                        <button className={"simple-button" + (value === 'row-reverse' ? ' active' : '')} onClick={() => changeValue('row-reverse')}>
                            <FlexRowReverseIcon />
                        </button>
                    </div>
                </GenericTooltip>

                <GenericTooltip text={'Lay inner elements from top to bottom'} show={200} hide={200} orientation={'bottom'}>
                    <div className="tool">
                        COLUMN
                        <button className={"simple-button" + (value === 'column' ? ' active' : '')} onClick={() => changeValue('column')}>
                            <FlexColumnIcon />
                        </button>
                    </div>
                </GenericTooltip>

                <GenericTooltip text={'Lay inner elements from bottom to top'} show={200} hide={200} orientation={'bottom'}>
                    <div className="tool">
                        COLUMN REVERSE
                        <button className={"simple-button" + (value === 'column-reverse' ? ' active' : '')} onClick={() => changeValue('column-reverse')}>
                            <FlexColumnReverseIcon />
                        </button>
                    </div>
                </GenericTooltip>
            </div>
        </div>
    );
};