import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles.scss';
import Slide from '../../elementLibrary/elements/slide';
import { COMPONENT_CONFIGS, COMPONENT_DEFAULT_STYLE } from 'containers/VirtualEvent/constants';

const defaultSettings = {
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    vertical: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
    className: 'custom-carousel-inner'
};

const BLACKLISTED_KEYS = ['overlay'];

const componentStyles = { ...COMPONENT_DEFAULT_STYLE, ...COMPONENT_CONFIGS.gallery.component.defaultStyles }

const parseStyle = (properties) => {
    const styles = {};
    for (let key in componentStyles) {
        if (properties[key]) {
            if (!BLACKLISTED_KEYS.includes(key)) {
                if (key === 'border') {
                    styles['border'] = properties['border']['border'];
                    styles['borderRadius'] = properties['border']['borderRadius']
                } else if (key === 'background') {
                    styles['backgroundImage'] = properties['background']['image'];
                    styles['backgroundSize'] = properties['background']['size'];
                    styles['backgroundPosition'] = properties['background']['position'];
                    styles['backgroundColor'] = properties['background']['color'];
                    styles['backgroundRepeat'] = properties['background']['repeat'];
                } else {
                    styles[key] = properties[key]
                }
            }
        } else {
            styles[key] = componentStyles[key]
        }
    }
    return styles
}

export default function CustomCarousel(props) {
    const { properties, elements, content } = props;
    const { speed, autoplaySpeed } = content?.['settings']
    const carouselSettings = { ...defaultSettings, ...content?.['settings'], speed: parseInt(speed), autoplaySpeed: parseInt(autoplaySpeed)  }
    const slides = elements.map(element => <Slide key={`${element.i}`} elem={{...element}} />)
    return (<div className="virtual-event-carousel-content">
        <div className="virtual-event-carousel-wrapper" style={{ ...parseStyle(properties), display: 'block', overflow: 'hidden' }}>
            <Slider {...carouselSettings}>
                {slides}
            </Slider>
        </div>
    </div>);
}

