import * as a from "actiontypes";

const viewInitial = {
    view: "loading",
    minimized: false,
    showJoinPrompt: false,
    joinPromptRoomId: null,
};

const view = (state = viewInitial, action) => {
    switch (action.type) {
        case a.WS_RESET_APPLICATION:
            return viewInitial;
        case a.WS_CHANGE_VIEW:
            return { ...state, view: action.payload };
        case a.WS_CHANGE_ROOM:
            return { ...state, view: "chat" };

        case a.WS_SHOW_JOIN_PROMPT:
            return {
                ...state,
                showJoinPrompt: action.payload.show_prompt,
                joinPromptRoomId: action.payload.room_id,
            };
        case a.WS_CLOSE_PROMPT:
            return { ...state, joinPromptRoomId: null, showJoinPrompt: false };
        case a.WS_ROOM_JOINED:
            return { ...state, joinPromptRoomId: null, showJoinPrompt: false, view: "chat" };
        case a.WS_ROOM_JOIN_FAILED:
            return { ...state, showJoinPrompt: true, joinPromptRoomId: action.payload.room_id };
        default:
            return state;
    }
};
export default view;
