/** @format */

import { IconTimes } from "components/icons";
import { Modal } from "@mantine/core";
import { useSelector } from "react-redux";
import "./modal.scss";
import { generateModalClasses } from "./helpers";

export default function HeaderModal(props) {
	const {
		showModal,
		setShowModal,
		children,
		customHeading,
		headingText = "",
		id,
		header,
		backdrop,
		keyboard,
		classNames = {},
		size = "100%",
	} = props;
	const displayType = useSelector(state => state.displayType);

	function closeModal() {
		setShowModal(false);
	}

	const hideHeader = !header ? { header: "hide-header" } : {};

	return (
		<Modal
			opened={showModal}
			id={id}
			animation={false}
			onClose={closeModal}
			className={props.modalClassName || ""}
			backdrop={backdrop} // pass 'static' to prevent close on outside click
			closeOnClickOutside={false} // pass false to prevent close on ESC
			size={size}
			title={headingText}
			classNames={{
				...generateModalClasses("header-modal", displayType),
				...classNames,
				...hideHeader,
			}}
		>
			<div className={`modal-content-section ${displayType}`}>{children}</div>
		</Modal>
	);
}
