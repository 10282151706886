import { Modal } from "react-bootstrap"
import { connect } from "react-redux"
import * as a from 'actiontypes'
import { IconCalendar, IconBellFilled, IconBarChart, IconUsers, IconCalendarCheck, IconLaptop, IconTimes, IconTabletEditor, IconIphone } from 'components/icons';
import { Localized } from '@fluent/react';
import DesktopView from "containers/VirtualEvent/contentView/contents/desktopView";
import TabletView from "containers/VirtualEvent/contentView/contents/tabletView";
import MobileView from "containers/VirtualEvent/contentView/contents/mobileView";
import { useState } from "react";
import { IphoneFrame } from "components/PreviewFrames";
import { GenericTooltip } from 'components/ToolTip/Tooltip';
import { useWindowDimensions } from "containers/VirtualEvent/contentView/hooks/hooks";

const mapStateToProps = state => ({
    modalData: state.editorProgress?.previewModal
})

const mapDispatchToProps = dispatch => ({
    dispatchPreviewData: payload => dispatch({ type: a.HANDLE_PREVIEW_MODAL, payload })
})

const PreviewModal = (props) => {
    const { modalData = {}, dispatchPreviewData, view = "desktop", lobby = {} } = props
    const { showModal = false, previewData = null } = modalData
    const { styles = {} } = previewData
    const { background = {} } = styles
    const [previewView, setPreviewView] = useState(view)
    // Sorry
    const { height } = useWindowDimensions()

    const mobileFrameWidth = height <= 800 ? 340 : 380

    const viewProps = {
        layout: previewData.components[previewView],
        view: view,
        isPreview: true,
        setShowChat: () => {}
    }

    const previewContainerStyle = previewView === 'desktop' ?
        {
            width: "calc(100% - 2rem)",
            height: "calc(100% - 2rem)"
        } : previewView === 'mobile' ?
            {
                width: "auto",
                height: "auto",
                borderRadius: "40px",

            } :
            {
                width: "auto",
                height: "calc(100% - 2rem)",
            }

    const generateContent = () => {
        switch (previewView) {
            case 'desktop': return <DesktopView {...viewProps} />;
            case 'tablet': return <TabletView {...viewProps} />;
            case 'mobile': return <MobileView {...viewProps} />;
            default: return null;
        }
    }

    const backgroundColor = () => {
        if (!background.color) return "#FFF"
        else {
            const splittedColor = background.color.replace('rgba(', '').replace(')', '').split(',');
            if (splittedColor[3] && parseInt(splittedColor[3]) === 0) return "#FFF"
            else return background.color
        }
    }

    const backgroundStyle = {
        "--preview-bg-color": backgroundColor(),
        "--preview-bg-image": background.image,
        "--preview-bg-position": background.position,
        "--preview-bg-repeat": background.repeat,
        "--preview-bg-size": background.size,
    }

    return (

        <Modal
            fullscreen={true}
            show={showModal}
            onHide={() => dispatchPreviewData({ showModal: false, previewData: null })}
            className="preview-modal"
            dialogClassName="preview-modal-dialog"
            contentClassName={`preview-modal-content ${previewView}`}
            backdropClassName="preview-modal-backdrop"
            style={{ ...backgroundStyle }}
        >
            <div className="preview-content-container" style={{ ...previewContainerStyle }}>

                {previewView === 'desktop' && <Header view={previewView} />}
                <div className={`preview-modal-inner-content ${previewView}`} >
                    {previewView === 'mobile' && <div className="black-box" />}
                    {(previewView === 'mobile' || previewView === 'tablet') && <HeaderMobile view={previewView} lobby={lobby} />}
                    <div className={`virtual-event-view ${previewView}`}>
                        <div className="virtual-event">
                            {(previewView === 'desktop' || previewView === 'tablet') && <div className={`virtual-event-navigator ${previewView}`}></div>}
                            {generateContent()}
                        </div>
                        {previewView === 'desktop' && <div className="liveto-chat"></div>}
                    </div>
                    {previewView === 'mobile' && <IphoneFrame width={mobileFrameWidth} className="preview-mobile-container" />}
                    
                </div>
            </div>
            <div className="preview-view-selector">
                <GenericTooltip
                    text="Desktop view"
                    hide="100"
                    orientation="right">
                    <div className="view-button-container" onClick={() => setPreviewView('desktop')}>
                        <IconLaptop className={`${previewView === 'desktop' ? 'active' : ''}`} />
                    </div>
                </GenericTooltip>

                <GenericTooltip
                    text="Tablet view"
                    hide="100"
                    orientation="right">
                    <div className="view-button-container" onClick={() => setPreviewView('tablet')}>
                        <IconTabletEditor className={`${previewView === 'tablet' ? 'active' : ''}`} />
                    </div>
                </GenericTooltip>

                <GenericTooltip
                    text="Mobile view"
                    hide="100"
                    orientation="right">
                    <div className="view-button-container" onClick={() => setPreviewView('mobile')}>
                        <IconIphone className={`${previewView === 'mobile' ? 'active' : ''}`} />
                    </div>
                </GenericTooltip>

                <GenericTooltip
                    text="Close preview"
                    hide="100"
                    orientation="right">
                    <div className="view-button-container" onClick={() => dispatchPreviewData({ showModal: false, previewData: null })}>
                        <IconTimes size={30} />
                    </div>
                </GenericTooltip>
            </div>


        </Modal>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewModal)

const Header = (props) => {
    return <div className={`virtual-event-header ${props.view}`}>
        <div className="event-name">Event name</div>

        <div className="event-image"></div>
        <div className="user-options">
            <button className="header-button polls-button">
                <IconBarChart size="25" className="header-button-icon" />
                <Localized id="header-polls-button">
                    <span className="header-button-text">Polls</span>
                </Localized>
            </button>
            <button className="header-button calendar-button">
                <IconCalendar size="25" className="header-button-icon" />
                <Localized id="header-schedule-button">
                    <span className="header-button-text">Program</span>
                </Localized>
            </button>
            <button className="header-button calendar-button">
                <IconUsers size="25" className="header-button-icon" />
                <Localized id="header-performers-button">
                    <span className="header-button-text">Performers</span>
                </Localized>
            </button>
        </div>

        <div className="user-options">
            <div id="header-online-count">
                <span className="dot" style={{ backgroundColor: 'green' }}></span>
                <span className="header-attendees">
                    0
                </span>
            </div>


            <div className="notification-bell">
                <IconBellFilled size='20' />
            </div>

            <div className="personal-calendar-icon-container">
                <IconCalendarCheck size='22' className='personal-calendar-icon' />
            </div>

            <button className="header-button profile-button">
                <div className="virtual-profile-icon guest-border">

                </div>
            </button>

        </div>
    </div>
}

const HeaderMobile = (props) => {
    const { lobby = {}, view = "mobile" } = props
    const { logo = "" } = lobby

    return <div className={`virtual-event-header ${view}`}>

        <div className="event-image" style={{ backgroundImage: `url("${logo}")` }}></div>
        <div className="user-options">
            <button className="header-button polls-button">
                <IconBarChart size="25" className="header-button-icon" />
            </button>
            <button className="header-button calendar-button">
                <IconCalendar size="25" className="header-button-icon" />
            </button>
            <button className="header-button calendar-button">
                <IconUsers size="25" className="header-button-icon" />
            </button>
        </div>

        <div className="user-options">

            <div className="notification-bell">
                <IconBellFilled size='20' />
            </div>

            <div className="personal-calendar-icon-container">
                <IconCalendarCheck size='22' className='personal-calendar-icon' />
            </div>

            <button className="header-button profile-button">
                <div className="virtual-profile-icon guest-border">

                </div>
            </button>

        </div>
    </div>
}