import { cloneDeep } from "lodash";
import * as a from "actiontypes";

export const allAttendeesInitial = [];

const all_attendees = (state = allAttendeesInitial, action) => {
    switch (action.type) {
        case a.GET_ALL_ATTENDEES:
            return action.payload;
        default:
            return state;
    }
};
export default all_attendees;
