import React from "react";

export const IphoneFrame = (props) => {
    const multiplier = 722 / 370
    const width = props.width
    const height = props.width * multiplier
    return (
        <svg className={props.className} width={width} height={height} viewBox="0 0 360 722" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.96094 53.5312C1.96094 24.2909 25.4998 0.751953 54.7402 0.751953H305.26C334.5 0.751953 358.039 24.2909 358.039 53.5312V668.209C358.039 697.449 334.5 720.99 305.26 720.99H198.297L196.109 717.893H188.353H171.646H163.889L161.703 720.99H54.7402C25.4998 720.99 1.96094 697.449 1.96094 668.209V53.5312ZM23.707 54.2734C23.707 36.5054 38.0112 22.2012 55.7793 22.2012H87.1523C88.5732 22.2013 89.9358 22.7658 90.9405 23.7705C91.9452 24.7751 92.5096 26.1378 92.5098 27.5586V30.0254C92.5098 39.6789 100.28 47.4512 109.934 47.4512H250.066C259.72 47.4512 267.49 39.6789 267.49 30.0254V27.5586C267.49 26.1378 268.055 24.7751 269.059 23.7704C270.064 22.7658 271.427 22.2013 272.848 22.2012H304.221C321.989 22.2012 336.293 36.5054 336.293 54.2734V667.469C336.293 685.237 321.989 699.541 304.221 699.541H55.7793C38.0112 699.541 23.707 685.237 23.707 667.469V54.2734Z" fill="black" />
            <path d="M358.295 167.079C359.239 167.079 360 167.838 360 168.782V247.943C360 248.887 359.239 249.648 358.295 249.648H345.164C344.22 249.648 343.459 248.887 343.459 247.943V168.782C343.459 167.838 344.22 167.079 345.164 167.079H358.295Z" fill="black" />
            <g filter="url(#filter0_f_2_2)">
                <path d="M357.932 168.599C358.774 168.599 359.452 169.277 359.452 170.119V246.607C359.452 247.449 358.774 248.127 357.932 248.127H357.46V168.599H357.932Z" fill="url(#paint0_linear_2_2)" />
            </g>
            <path d="M14.8366 97.7257H1.70463C0.76319 97.7257 0 98.4889 0 99.4303V124.053C0 124.994 0.76319 125.757 1.70463 125.757H14.8366C15.7781 125.757 16.5412 124.994 16.5412 124.053V99.4303C16.5412 98.4889 15.7781 97.7257 14.8366 97.7257Z" fill="black" />
            <g filter="url(#filter1_f_2_2)">
                <path d="M2.0675 99.2465C1.2256 99.2465 0.547974 99.9241 0.547974 100.766V122.717C0.547974 123.559 1.2256 124.237 2.0675 124.237H2.54016V99.2465H2.0675Z" fill="url(#paint1_linear_2_2)" />
            </g>
            <path d="M1.70508 150.002C0.760712 150.002 0 150.761 0 151.705V200.509C0 201.453 0.760712 202.214 1.70508 202.214H14.8359C15.7803 202.214 16.541 201.453 16.541 200.509V151.705C16.541 150.761 15.7803 150.002 14.8359 150.002H1.70508Z" fill="black" />
            <g filter="url(#filter2_f_2_2)">
                <path d="M2.0675 151.522C1.2256 151.522 0.547974 152.199 0.547974 153.041V199.173C0.547974 200.015 1.2256 200.693 2.0675 200.693H2.54016V151.522H2.0675Z" fill="url(#paint2_linear_2_2)" />
            </g>
            <path d="M1.70508 215.662C0.760712 215.662 0 216.421 0 217.365V266.169C0 267.113 0.760712 267.874 1.70508 267.874H14.8359C15.7803 267.874 16.541 267.113 16.541 266.169V217.365C16.541 216.421 15.7803 215.662 14.8359 215.662H1.70508Z" fill="black" />
            <g filter="url(#filter3_f_2_2)">
                <path d="M2.0675 217.182C1.2256 217.182 0.547974 217.859 0.547974 218.701V264.833C0.547974 265.675 1.2256 266.353 2.0675 266.353H2.54016V217.182H2.0675Z" fill="url(#paint3_linear_2_2)" />
            </g>
            <path d="M3.16211 74.9336H7.32809V70.0098H3.16211V74.9336Z" fill="#7D7E7F" />
            <path d="M3.16211 647.058H7.32809V74.9336H3.16211V647.058Z" fill="#EBEBEB" />
            <path d="M3.16211 651.984H7.32809V647.059H3.16211V651.984Z" fill="#7D7E7F" />
            <path d="M356.838 651.984H352.672V668.461C352.672 695.043 331.272 716.443 304.689 716.443H55.3105C28.7284 716.443 7.32809 695.043 7.32809 668.461V651.984H3.16211V666.281C3.16211 695.96 27.0558 719.853 56.7343 719.853H303.266C332.944 719.853 356.838 695.96 356.838 666.281V651.984Z" fill="#EBEBEB" />
            <path d="M356.838 647.059H352.672V651.984H356.838V647.059Z" fill="#7D7E7F" />
            <path d="M356.838 74.9336H352.672V647.058H356.838V74.9336Z" fill="#EBEBEB" />
            <path d="M356.838 70.0098H352.672V74.9336H356.838V70.0098Z" fill="#7D7E7F" />
            <path d="M56.7343 2.26562C27.0558 2.26562 3.16211 26.1593 3.16211 55.8379V70.0098H7.32809V54.2246C7.32809 27.6424 28.7284 6.24414 55.3105 6.24414H304.689C331.272 6.24414 352.672 27.6424 352.672 54.2246V70.0098H356.838V55.8379C356.838 26.1593 332.944 2.26562 303.266 2.26562H56.7343Z" fill="#EBEBEB" />
            <path d="M325.152 15.3633C323.978 15.247 324.532 16.418 327.168 18.8594C335.864 26.9145 341.263 38.5735 341.263 51.5508V361.123V670.695C341.263 683.673 335.864 695.332 327.168 703.387C324.532 705.828 323.978 706.999 325.152 706.883C325.857 706.813 327.184 706.28 329.056 705.281C341.766 698.501 349.146 683.873 349.146 668.336V361.123V53.9102C349.146 38.3734 341.766 23.7452 329.056 16.9649C327.184 15.9661 325.857 15.4331 325.152 15.3633V15.3633Z" fill="url(#paint4_linear_2_2)" />
            <path d="M34.8479 15.3633C36.0225 15.247 35.468 16.418 32.8323 18.8594C24.1361 26.9145 18.7366 38.5735 18.7366 51.5508V361.123V670.695C18.7366 683.673 24.1361 695.332 32.8323 703.387C35.468 705.828 36.0225 706.999 34.8479 706.883C34.1432 706.813 32.8157 706.28 30.9436 705.281C18.2341 698.501 10.8538 683.873 10.8538 668.336V361.123V53.9102C10.8538 38.3734 18.2341 23.7452 30.9436 16.9649C32.8157 15.9661 34.1432 15.4331 34.8479 15.3633Z" fill="url(#paint5_linear_2_2)" />
            <path d="M199.062 27.4464H161.563C160.059 27.4464 158.839 28.6656 158.839 30.1696C158.839 31.6736 160.059 32.8928 161.563 32.8928H199.062C200.566 32.8928 201.786 31.6736 201.786 30.1696C201.786 28.6656 200.566 27.4464 199.062 27.4464Z" fill="#1E1E1E" />
            <path d="M218.891 34.9018C221.698 34.9018 223.973 32.6263 223.973 29.8195C223.973 27.0126 221.698 24.7371 218.891 24.7371C216.084 24.7371 213.809 27.0126 213.809 29.8195C213.809 32.6263 216.084 34.9018 218.891 34.9018Z" fill="#2B2B2B" />
            <path d="M218.891 33.8916C221.14 33.8916 222.963 32.0684 222.963 29.8194C222.963 27.5704 221.14 25.7473 218.891 25.7473C216.642 25.7473 214.819 27.5704 214.819 29.8194C214.819 32.0684 216.642 33.8916 218.891 33.8916Z" fill="#0A0D13" />
            <path d="M218.891 32.9221C220.604 32.9221 221.994 31.533 221.994 29.8194C221.994 28.1059 220.604 26.7167 218.891 26.7167C217.177 26.7167 215.788 28.1059 215.788 29.8194C215.788 31.533 217.177 32.9221 218.891 32.9221Z" fill="#091427" />
            <g filter="url(#filter4_f_2_2)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M216.755 27.7437C216.387 27.7824 215.864 28.3439 215.826 29.4477C215.787 30.5515 216.271 31.1518 216.542 31.1518C216.813 31.1518 217.936 29.5445 216.755 27.7437H216.755Z" fill="#235A91" fill-opacity="0.556075" />
            </g>
            <g filter="url(#filter5_f_2_2)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M219.397 27.3571C218.817 27.5357 218.705 28.4732 218.728 29.0089C218.75 29.5446 219.509 30.9509 220.424 30.6384C221.339 30.3259 221.496 29.1651 221.116 28.3839C220.737 27.6026 220.045 27.0446 219.397 27.3571Z" fill="#235A91" fill-opacity="0.556075" />
            </g>
            <path d="M152.091 30.2879C152.091 31.345 151.672 32.3591 150.926 33.108C150.18 33.8568 149.168 34.2795 148.111 34.2834C147.053 34.2873 146.038 33.8721 145.286 33.1287C144.535 32.3853 144.108 31.3744 144.1 30.3173C144.093 29.2602 144.504 28.2431 145.245 27.4887C145.985 26.7344 146.995 26.3043 148.052 26.2926C149.109 26.2809 150.127 26.6887 150.884 27.4265C151.642 28.1643 152.075 29.1721 152.091 30.2291L148.096 30.2879H152.091Z" fill="#0F0F0F" />
            <path d="M129.433 30.3817C129.433 31.951 128.801 33.4563 127.676 34.568C126.55 35.6796 125.023 36.3071 123.428 36.3129C121.834 36.3186 120.302 35.7023 119.168 34.5988C118.035 33.4953 117.391 31.9946 117.38 30.4253C117.368 28.8561 117.989 27.3463 119.106 26.2265C120.223 25.1067 121.745 24.4682 123.34 24.4509C124.934 24.4335 126.471 25.0388 127.613 26.134C128.755 27.2293 129.409 28.7253 129.432 30.2944L123.406 30.3817H129.433Z" fill="#101010" />
            <path d="M55.2721 9.74084C46.5846 9.74084 38.503 12.2255 31.6803 16.5143V22.2018C38.1825 17.9367 45.9671 15.4537 54.358 15.4537H154.182H206.323H306.147C314.538 15.4537 322.323 17.9367 328.825 22.2018V16.5143C322.002 12.2255 313.921 9.74084 305.233 9.74084H206.323H154.182H55.2721Z" fill="url(#paint6_linear_2_2)" />
            <path d="M55.2721 712.001C46.5846 712.001 38.503 709.517 31.6803 705.228V699.54C38.1825 703.805 45.9671 706.288 54.358 706.288H154.182H206.323H306.147C314.538 706.288 322.323 703.805 328.825 699.54V705.228C322.002 709.517 313.921 712.001 305.233 712.001H206.323H154.182H55.2721Z" fill="url(#paint7_linear_2_2)" />
            <path d="M250.953 29.7425C250.953 31.852 250.107 33.8755 248.602 35.3699C247.097 36.8642 245.054 37.7077 242.921 37.7154C240.788 37.7232 238.739 36.8947 237.222 35.4113C235.706 33.9279 234.846 31.9106 234.83 29.8012C234.814 27.6917 235.644 25.6621 237.139 24.1568C238.633 22.6515 240.669 21.7932 242.802 21.77C244.935 21.7467 246.99 22.5603 248.518 24.0326C250.045 25.5049 250.92 27.5159 250.952 29.6251L242.891 29.7425H250.953Z" fill="#0F0F0F" />
            <defs>
                <filter id="filter0_f_2_2" x="356.92" y="168.059" width="3.07148" height="80.6072" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="0.269824" result="effect1_foregroundBlur_2_2" />
                </filter>
                <filter id="filter1_f_2_2" x="0.0083254" y="98.7068" width="3.07148" height="26.0695" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="0.269824" result="effect1_foregroundBlur_2_2" />
                </filter>
                <filter id="filter2_f_2_2" x="0.0083254" y="150.982" width="3.07148" height="50.2501" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="0.269824" result="effect1_foregroundBlur_2_2" />
                </filter>
                <filter id="filter3_f_2_2" x="0.0083254" y="216.642" width="3.07148" height="50.2501" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="0.269824" result="effect1_foregroundBlur_2_2" />
                </filter>
                <filter id="filter4_f_2_2" x="215.589" y="27.5094" width="1.93008" height="3.87656" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="0.117104" result="effect1_foregroundBlur_2_2" />
                </filter>
                <filter id="filter5_f_2_2" x="218.491" y="27.0346" width="3.05209" height="3.88278" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="0.117104" result="effect1_foregroundBlur_2_2" />
                </filter>
                <linearGradient id="paint0_linear_2_2" x1="357.46" y1="168.599" x2="357.46" y2="248.127" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CBCBCB" stop-opacity="0.27451" />
                    <stop offset="0.04" stop-color="#CBCBCB" />
                    <stop offset="0.96" stop-color="#CBCBCB" />
                    <stop offset="1" stop-color="#CBCBCB" stop-opacity="0.275701" />
                </linearGradient>
                <linearGradient id="paint1_linear_2_2" x1="2.54016" y1="99.2465" x2="2.54016" y2="124.237" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CBCBCB" stop-opacity="0.27451" />
                    <stop offset="0.05" stop-color="#CBCBCB" />
                    <stop offset="0.95" stop-color="#CBCBCB" />
                    <stop offset="1" stop-color="#CBCBCB" stop-opacity="0.275701" />
                </linearGradient>
                <linearGradient id="paint2_linear_2_2" x1="2.54016" y1="151.522" x2="2.54016" y2="200.693" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CBCBCB" stop-opacity="0.27451" />
                    <stop offset="0.05" stop-color="#CBCBCB" />
                    <stop offset="0.95" stop-color="#CBCBCB" />
                    <stop offset="1" stop-color="#CBCBCB" stop-opacity="0.275701" />
                </linearGradient>
                <linearGradient id="paint3_linear_2_2" x1="2.54016" y1="217.182" x2="2.54016" y2="266.353" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CBCBCB" stop-opacity="0.27451" />
                    <stop offset="0.05" stop-color="#CBCBCB" />
                    <stop offset="0.95" stop-color="#CBCBCB" />
                    <stop offset="1" stop-color="#CBCBCB" stop-opacity="0.275701" />
                </linearGradient>
                <linearGradient id="paint4_linear_2_2" x1="340.224" y1="361.123" x2="349.146" y2="361.123" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#404040" />
                    <stop offset="0.6" stop-color="#838383" />
                    <stop offset="1" stop-color="#A7A7A7" />
                </linearGradient>
                <linearGradient id="paint5_linear_2_2" x1="19.7758" y1="361.123" x2="10.8538" y2="361.123" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#404040" />
                    <stop offset="0.6" stop-color="#838383" />
                    <stop offset="1" stop-color="#A7A7A7" />
                </linearGradient>
                <linearGradient id="paint6_linear_2_2" x1="31.6803" y1="22.2018" x2="328.825" y2="22.2018" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#4F4F4F" stop-opacity="0.130841" />
                    <stop offset="0.07" stop-color="#4F4F4F" />
                    <stop offset="0.937" stop-color="#4F4F4F" stop-opacity="0.953271" />
                    <stop offset="1" stop-color="#4F4F4F" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint7_linear_2_2" x1="31.6803" y1="699.54" x2="328.825" y2="699.54" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#4F4F4F" stop-opacity="0.130841" />
                    <stop offset="0.07" stop-color="#4F4F4F" />
                    <stop offset="0.937" stop-color="#4F4F4F" stop-opacity="0.953271" />
                    <stop offset="1" stop-color="#4F4F4F" stop-opacity="0" />
                </linearGradient>
            </defs>
        </svg>

    );
}

export const IpadFrame = (props) => {
    const multiplier = 1060 / 764
    const width = props.width || 920
    const height = width * multiplier
    return <svg className={props.className} width={width} height={height} viewBox="0 0 764 1060" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3_98)">
            <g filter="url(#filter0_d_3_98)">
                <path d="M763.055 75.533C763.577 75.533 764 75.9561 764 76.4781L764 113.067C764 113.589 763.577 114.012 763.055 114.012L761.763 114.012L761.763 75.533L763.055 75.533Z" fill="url(#paint0_linear_3_98)" />
                <path d="M763.055 75.533C763.577 75.533 764 75.9561 764 76.4781L764 113.067C764 113.589 763.577 114.012 763.055 114.012L761.763 114.012L761.763 75.533L763.055 75.533Z" fill="url(#paint1_linear_3_98)" />
                <path d="M763.055 75.533C763.577 75.533 764 75.9561 764 76.4781L764 113.067C764 113.589 763.577 114.012 763.055 114.012L761.763 114.012L761.763 75.533L763.055 75.533Z" fill="url(#paint2_linear_3_98)" />
                <path d="M763.055 121.137C763.577 121.137 764 121.56 764 122.082L764 158.671C764 159.193 763.577 159.616 763.055 159.616L761.763 159.616L761.763 121.137L763.055 121.137Z" fill="url(#paint3_linear_3_98)" />
                <path d="M763.055 121.137C763.577 121.137 764 121.56 764 122.082L764 158.671C764 159.193 763.577 159.616 763.055 159.616L761.763 159.616L761.763 121.137L763.055 121.137Z" fill="url(#paint4_linear_3_98)" />
                <path d="M763.055 121.137C763.577 121.137 764 121.56 764 122.082L764 158.671C764 159.193 763.577 159.616 763.055 159.616L761.763 159.616L761.763 121.137L763.055 121.137Z" fill="url(#paint5_linear_3_98)" />
                <path d="M673.644 0.945121C673.644 0.423146 674.067 0 674.589 0H715.64C716.162 0 716.586 0.423145 716.586 0.94512V2.37524H673.644V0.945121Z" fill="url(#paint6_linear_3_98)" />
                <path d="M673.644 0.945121C673.644 0.423146 674.067 0 674.589 0H715.64C716.162 0 716.586 0.423145 716.586 0.94512V2.37524H673.644V0.945121Z" fill="url(#paint7_linear_3_98)" />
                <path d="M673.644 0.945121C673.644 0.423146 674.067 0 674.589 0H715.64C716.162 0 716.586 0.423145 716.586 0.94512V2.37524H673.644V0.945121Z" fill="url(#paint8_linear_3_98)" />
            </g>
            <g filter="url(#filter1_i_3_98)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M761.764 53.6262C761.764 25.3213 738.818 2.3757 710.514 2.3757L51.2507 2.37567C22.9458 2.37567 0.000193056 25.3213 0.000191891 53.6261L0.000152588 1008.53C0.000151423 1036.84 22.9458 1059.78 51.2507 1059.78H710.514C738.818 1059.78 761.764 1036.84 761.764 1008.53L761.764 53.6262ZM727.787 52.7186C727.787 43.7802 720.541 36.5342 711.603 36.5342L52.516 36.5342C43.5777 36.5342 36.3317 43.7802 36.3317 52.7186L36.3316 1009.62C36.3316 1018.56 43.5776 1025.81 52.516 1025.81L711.603 1025.81C720.541 1025.81 727.787 1018.56 727.787 1009.62L727.787 52.7186Z" fill="url(#paint9_linear_3_98)" />
            </g>
            <g filter="url(#filter2_d_3_98)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M757.617 55.2601C757.617 28.445 735.879 6.70706 709.064 6.70706L52.3482 6.70703C25.533 6.70703 3.79508 28.4451 3.79508 55.2602L3.79504 1007.08C3.79504 1033.9 25.533 1055.63 52.3481 1055.63H709.064C735.879 1055.63 757.617 1033.9 757.617 1007.08L757.617 55.2601ZM727.787 52.7187C727.787 43.7803 720.541 36.5343 711.603 36.5343L52.5159 36.5343C43.5776 36.5343 36.3316 43.7803 36.3316 52.7187L36.3315 1009.62C36.3315 1018.56 43.5776 1025.81 52.5159 1025.81L711.603 1025.81C720.541 1025.81 727.787 1018.56 727.787 1009.62L727.787 52.7187Z" fill="url(#paint10_linear_3_98)" />
            </g>
            <circle opacity="0.2" cx="330.779" cy="18.5299" r="7.36199" transform="rotate(90 330.779 18.5299)" fill="black" stroke="#444444" stroke-width="0.47256" />
            <circle opacity="0.2" cx="407.737" cy="18.5299" r="7.36199" transform="rotate(90 407.737 18.5299)" fill="black" stroke="#444444" stroke-width="0.47256" />
            <circle opacity="0.2" cx="305.602" cy="18.5298" r="4.98675" transform="rotate(90 305.602 18.5298)" fill="black" stroke="#444444" stroke-width="0.47256" />
            <circle opacity="0.2" cx="381.609" cy="18.5298" r="2.13647" transform="rotate(90 381.609 18.5298)" fill="black" stroke="#444444" stroke-width="0.47256" />
            <g opacity="0.5">
                <circle opacity="0.2" cx="356.432" cy="18.5298" r="4.27542" transform="rotate(90 356.432 18.5298)" fill="#D5D5D5" />
                <circle cx="356.432" cy="18.5298" r="3.73251" transform="rotate(90 356.432 18.5298)" fill="#070707" />
                <circle cx="356.432" cy="18.5298" r="2.74973" transform="rotate(90 356.432 18.5298)" stroke="url(#paint11_radial_3_98)" stroke-width="0.47256" />
                <circle cx="356.432" cy="18.5298" r="2.74973" transform="rotate(90 356.432 18.5298)" stroke="url(#paint12_linear_3_98)" stroke-width="0.47256" />
                <g opacity="0.5">
                    <circle cx="356.432" cy="18.5298" r="2.61276" transform="rotate(90 356.432 18.5298)" fill="url(#paint13_linear_3_98)" />
                    <circle cx="356.432" cy="18.5298" r="2.61276" transform="rotate(90 356.432 18.5298)" fill="url(#paint14_linear_3_98)" />
                </g>
                <circle cx="356.432" cy="18.5299" r="1.49301" transform="rotate(90 356.432 18.5299)" fill="#0C0B0B" />
            </g>
        </g>
        <defs>
            <filter id="filter0_d_3_98" x="602.76" y="-75.6098" width="317.185" height="386.445" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dx="42.5304" dy="37.8048" />
                <feGaussianBlur stdDeviation="56.7073" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.623529 0 0 0 0 0.623529 0 0 0 0 0.623529 0 0 0 1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_98" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_98" result="shape" />
            </filter>
            <filter id="filter1_i_3_98" x="0.000152588" y="2.37567" width="761.764" height="1057.41" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset />
                <feGaussianBlur stdDeviation="0.47256" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.38 0" />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3_98" />
            </filter>
            <filter id="filter2_d_3_98" x="1.9048" y="4.81679" width="757.602" height="1052.71" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset />
                <feGaussianBlur stdDeviation="0.94512" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_98" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_98" result="shape" />
            </filter>
            <linearGradient id="paint0_linear_3_98" x1="764" y1="94.7724" x2="761.763" y2="94.7724" gradientUnits="userSpaceOnUse">
                <stop stop-color="#A2A3A6" />
                <stop offset="0.302083" stop-color="#FAFAFA" />
                <stop offset="1" stop-color="#DBDCDC" />
            </linearGradient>
            <linearGradient id="paint1_linear_3_98" x1="761.763" y1="75.533" x2="761.763" y2="78.6208" gradientUnits="userSpaceOnUse">
                <stop stop-color="#4A5157" stop-opacity="0" />
                <stop offset="0.489583" stop-color="#4A5157" stop-opacity="0.42" />
                <stop offset="1" stop-color="#4A5157" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint2_linear_3_98" x1="761.763" y1="112.112" x2="761.763" y2="114.012" gradientUnits="userSpaceOnUse">
                <stop stop-color="#4A5157" stop-opacity="0" />
                <stop offset="0.489583" stop-color="#4A5157" stop-opacity="0.43" />
                <stop offset="1" stop-color="#4A5157" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint3_linear_3_98" x1="764" y1="140.376" x2="761.763" y2="140.376" gradientUnits="userSpaceOnUse">
                <stop stop-color="#A2A3A6" />
                <stop offset="0.302083" stop-color="#FAFAFA" />
                <stop offset="1" stop-color="#DBDCDC" />
            </linearGradient>
            <linearGradient id="paint4_linear_3_98" x1="761.763" y1="121.137" x2="761.763" y2="124.225" gradientUnits="userSpaceOnUse">
                <stop stop-color="#4A5157" stop-opacity="0" />
                <stop offset="0.489583" stop-color="#4A5157" stop-opacity="0.42" />
                <stop offset="1" stop-color="#4A5157" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint5_linear_3_98" x1="761.763" y1="157.716" x2="761.763" y2="159.616" gradientUnits="userSpaceOnUse">
                <stop stop-color="#4A5157" stop-opacity="0" />
                <stop offset="0.489583" stop-color="#4A5157" stop-opacity="0.43" />
                <stop offset="1" stop-color="#4A5157" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint6_linear_3_98" x1="695.115" y1="0" x2="695.115" y2="2.37524" gradientUnits="userSpaceOnUse">
                <stop stop-color="#A2A3A6" />
                <stop offset="0.302083" stop-color="#FAFAFA" />
                <stop offset="1" stop-color="#DBDCDC" />
            </linearGradient>
            <linearGradient id="paint7_linear_3_98" x1="673.644" y1="2.37524" x2="677.09" y2="2.37524" gradientUnits="userSpaceOnUse">
                <stop stop-color="#4A5157" stop-opacity="0" />
                <stop offset="0.489583" stop-color="#4A5157" stop-opacity="0.42" />
                <stop offset="1" stop-color="#4A5157" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint8_linear_3_98" x1="714.465" y1="2.37525" x2="716.586" y2="2.37525" gradientUnits="userSpaceOnUse">
                <stop stop-color="#4A5157" stop-opacity="0" />
                <stop offset="0.489583" stop-color="#4A5157" stop-opacity="0.43" />
                <stop offset="1" stop-color="#4A5157" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint9_linear_3_98" x1="747.205" y1="10.7742" x2="226.715" y2="1162.27" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EEEEEE" />
                <stop offset="1" stop-color="#C7C8CA" />
            </linearGradient>
            <linearGradient id="paint10_linear_3_98" x1="743.21" y1="15.0383" x2="226.063" y2="1156.36" gradientUnits="userSpaceOnUse">
                <stop stop-color="#121212" />
                <stop offset="1" stop-color="#0B0B0B" />
            </linearGradient>
            <radialGradient id="paint11_radial_3_98" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(356.432 18.5298) rotate(90) scale(2.98601)">
                <stop offset="0.916667" />
                <stop offset="1" stop-color="#181818" />
            </radialGradient>
            <linearGradient id="paint12_linear_3_98" x1="358.298" y1="16.6636" x2="356.432" y2="20.2095" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0C1D27" />
                <stop offset="1" stop-color="#0D1615" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint13_linear_3_98" x1="357.738" y1="16.8502" x2="356.619" y2="19.2763" gradientUnits="userSpaceOnUse">
                <stop stop-color="#59AFFF" />
                <stop offset="0.432292" stop-color="#25487C" />
                <stop offset="1" stop-color="#135766" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint14_linear_3_98" x1="354.752" y1="20.3961" x2="356.992" y2="18.5298" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2775EA" />
                <stop offset="0.286458" stop-color="#134366" />
                <stop offset="0.541667" stop-color="#557D9A" stop-opacity="0" />
                <stop offset="0.765625" stop-color="white" stop-opacity="0.47" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <clipPath id="clip0_3_98">
                <rect width="764" height="1060" fill="white" />
            </clipPath>
        </defs>
    </svg>


}
