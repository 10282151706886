import * as T from './types';

export type SPLCallback = (s: string) => void;

export interface StringPayloadLimiterOptions {
  callback: SPLCallback, // Function called when a payload is ready to get sent out
  timeOut?: number,      // Timeout in MS from a new thing getting added to batch getting made and sent
  charLimit?: number,    // Character limit of payloads to generate
  logWarnFunc?: T.LogWarnFunc, // For logging warnings
  extendTimerOnPacket?: boolean, // Whether to re-extend the timer after an add packet, to batch more aggresively
}

export default class StringPayloadLimiter {
  private cb: SPLCallback;
  private timeOut: number = 1000;
  private charLimit: number = 16000;
  private logWarnFunc?: T.LogWarnFunc;
  private destroyed: boolean = false;
  private extendTimerOnPacket: boolean = false;

  private timer: any;

  private waiting: any[];

  constructor(options: StringPayloadLimiterOptions) {
    if (!options) throw new Error('Missing options');
    if (!options.callback) throw new Error('Missing options.callback');
    this.cb = options.callback;
    this.waiting = [];

    if (options.hasOwnProperty('timeOut')) this.timeOut = options.timeOut;
    if (options.hasOwnProperty('charLimit')) this.charLimit = options.charLimit;
    if (options.hasOwnProperty('logWarnFunc')) this.logWarnFunc = options.logWarnFunc;
    if (options.hasOwnProperty('extendTimerOnPacket')) this.extendTimerOnPacket = options.extendTimerOnPacket;
  }

  public destroy() {
    if (this.destroyed) return;
    this.destroyed = true;
    this.waiting = [];
    if (this.timer) clearTimeout(this.timer);
    this.timer = null;
    this.cb = null;
    this.logWarnFunc = null;
  }

  public addPacket(packet: T.RpcPacket | T.RpcPacket[] | string) {
    if (this.destroyed) throw new Error('Destroyed');
    if (!packet) {
      return;
    } else if (typeof packet === 'string') {
      if (packet.length >= this.charLimit) {
        this.logWarnFunc('Packet exceeds max size, case 1', packet.length, this.charLimit, packet);
        throw new Error('Cannot add packet, exceeds maximum length');
      }
      this.waiting.push(packet);
    } else if (Array.isArray(packet)) {
      for (const p of packet) {
        this.addPacket(p);
      }
    } else {
      const str = JSON.stringify(packet);
      if (str.length >= this.charLimit) {
        this.logWarnFunc('Packet exceeds max size, case 2', str.length, this.charLimit, str);
        throw new Error('Cannot add packet, exceeds maximum length');
      }
      this.waiting.push(str);
    }

    this.pokeTimer();
  }

  private onTimer() {
    if (this.destroyed) return;
    this.timer = null; // Assure a new timer can be set later

    let acumulator = 0;
    let strings = [];

    // Remove things from waiting list into strings acumulator until we would hit the limit
    while (this.waiting.length) {
      if (strings.length && acumulator + this.waiting[0].length >= this.charLimit) break; // Cannot add, escape
      const s = this.waiting.shift();
      strings.push(s);
      acumulator += s.length;
    }

    // Attempt to send the acumulated strings, catching error so as to not blow up internally because of bad callbacks
    try {
      // this.cb(JSON.stringify(strings));
      this.cb('[' + strings.join(',') + ']');
    } catch (err) {
      if (this.logWarnFunc) this.logWarnFunc('StringPayloadLimiter onTimer', err);
      this.waiting.unshift(...strings); // Recover things that failed to send
    }

    // If we still have something left, set a timer to end of event queue
    if (this.waiting.length) {
      this.immediateTimer();
    }
  }

  private pokeTimer() {
    if (this.destroyed) return;
    if (this.extendTimerOnPacket && this.timer) {
      clearTimeout(this.timer); // extending until some limit
      this.timer = null;
    }
    if (!this.timer) {
      this.timer = setTimeout(this.onTimer.bind(this), this.timeOut);
    }
  }

  private immediateTimer() {
    // We want to run now, if there is a timer already set somehow, get rid of it.
    if (this.destroyed) return;
    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(this.onTimer.bind(this), 1);
  }
}