import '../styles/virtual-event.scss';
import 'react-grid-layout/css/styles.css';
import { LAYOUT_COLUMNS, LAYOUT_ROW_HEIGHT } from '../../constants';
import ComponentRenderer from '../../componentLibrary/component';
import { useMemo } from 'react';
import { gridifyComponent } from 'util/format';

function DesktopView(props) {
    const { slug, room, view, layout = [], isPreview, helmet } = props;
    const components = useMemo(() => layout.map(cmp => {
        const cProps = { cmp, slug, view, room }
        const grid = gridifyComponent(cmp);
        return <ComponentRenderer key={cmp.i} grid={grid} {...cProps} editing={false} isPreview={isPreview} />
    }), [layout]) // eslint-disable-line react-hooks/exhaustive-deps

    return <div className={`virtual-event-content desktop`}>
        {helmet}
        {isPreview && <div className="preview-overlay"></div>}
        {helmet}
        <div style={{ display: 'grid', gridAutoRows: `minmax(${LAYOUT_ROW_HEIGHT}px, auto)`, gridTemplateColumns: `repeat(${LAYOUT_COLUMNS}, 1fr)` }} >
            {components}
        </div>
    </div>

}

export default DesktopView;