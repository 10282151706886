import './errors.scss';

export function ContentErrorComponent(props) {
    // Handling for views???
    return <div className={`virtual-event-content-error ${props.view}`}>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', background: 'white', padding: '20px' }}>
            An error occurred, please refresh page.
        </div>
    </div>
}

export function HeaderErrorComponent(props) {
    return <div id="virtual-event-header" className={props.darkmode ? "darkmode" : ''}></div>
}

export function NavigatorErrorComponent(props){
    return <div className={`virtual-event-navigator-error ${props.view}`}>
        <div className="navigator-content">Error</div>
    </div>
}