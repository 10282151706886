import * as a from "actiontypes";

export const displayInitial = 'desktop'

const displayType = (state = displayInitial, action) => {
    switch (action.type) {

        case a.GET_DISPLAY_TYPE:
            return action.payload;

        default:
            return state;
    }
};
export default displayType;
