import {
    IconImage,
    IconText,
    StreamIcon,
    IconUsers,
    IconComponentTree,
    IconSeparator,
    IconList,
    IconCard,
    IconSocial,
    IconConditional,
    IconGallery,
    IconModal,
    IconButton,
    IconShadow,
    IconCarousel,
    IconLink,
    IconEmbed,
    IconVideo,
    IconBarChart,
    EditorBorderIcon,
    EditorTextIcon,
    EditorPaddingIcon,
    EditorAlignmentIcon,
    EditorBackgroundIcon,
    EditorShadowIcon,
    EditorOverlayIcon,
    EditorModalPollIcon,
    EditorModalEmptyIcon,
    EditorModalButtonIcon,
    EditorModalMeetIcon,
    EditorModalStreamIcon,
    EditorModalCardIcon,
    EditorModalSeparatorIcon,
    EditorModalVideoIcon,
    EditorModalEmbedIcon,
    EditorModalImageIcon,
    EditorModalGalleryIcon
} from "components/icons";
import cloneDeep from 'lodash/cloneDeep';
import placeholderImage from '../../static/img/liveto-logo.png'
import placeholderBg from '../../static/img/hero-img.jpg'
import placeholderBgSimpleCard from '../../static/img/forest-card.jpg'
import { AVAILABLE_LOCALES } from "../../constants";
import BorderWidget from "./editorView/widgets/border";
import PaddingWidget from "./editorView/widgets/padding";
import AlignmentMenu from "./editorView/widgets/alignment";
import LinkWidget from "./editorView/widgets/link";
import UrlWidget from "./editorView/widgets/url";
import TextWidget from "./editorView/widgets/text";
import EmbedWidget from "./editorView/widgets/embed";
import SeparatorWidget from "./editorView/widgets/separator";
import CarouselWidget from "./editorView/widgets/carousel";
import ImageUrlWidget from "./editorView/widgets/imageUrl";
import BackgroundWidget from "./editorView/widgets/background";
import BoxShadowWidget from "./editorView/widgets/boxShadow";
import DropShadowWidget from "./editorView/widgets/dropShadow";
import VideoSettingsWidget from "./editorView/widgets/videoSettings";
import StreamWidget from "./editorView/widgets/stream";
import PollWidget from "./editorView/widgets/poll";
import MeetWidget from "./editorView/widgets/meet";
import OverlayWidget from "./editorView/widgets/overlay";
import { EditorModalTextIcon } from "components/icons";
import { fetchCustomStreams, fetchMeets } from "./editorView/apiCalls";
import { fetchStreams } from "./editorView/apiCalls";
import store from "store";
import { BlankComponentPreview, CardComponentPreview, CarouselComponentPreview, ComponentPreview } from "./editorView/addModal";

export const COMPONENT_DEFAULT_STYLE = {
    position: 'relative',
    zIndex: '1',
    padding: '0 0 0 0',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    border: {
        border: '0px solid rgba(0,0,0,0)',
        borderRadius: '0px',
    },
    background: {
        "size": "contain",
        "color": "rgba(0,0,0,0)",
        "image": "",
        "repeat": "repeat",
        "position": "center"
    },
    boxShadow: 'none',
    height: '100%',
    minHeight: 'fit-content',
    alignItems: 'center',
    display: 'grid',
    overflowWrap: 'anywhere',
    overlay: 'rgba(0,0,0,0)',
    wordBreak: 'break-word',
}

export const COMPONENT_DEFAULT_PROPERTIES = {
    padding: '0 0 0 0',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    border: {
        border: '0px solid rgba(0,0,0,0)',
        borderRadius: '0px',
    },
    background: {
        "size": "contain",
        "color": "rgba(0,0,0,0)",
        "image": "",
        "repeat": "repeat",
        "position": "center"
    },
    boxShadow: 'none',
    alignItems: 'center',
    display: 'flex',
}

export const ELEMENT_DEFAULT_PROPERTIES = {
    padding: '0 0 0 0',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    background: {
        "size": "contain",
        "color": "rgba(0,0,0,0)",
        "image": "",
        "repeat": "repeat",
        "position": "center"
    },
    border: {
        border: '0px solid rgba(0,0,0,0)',
        borderRadius: '0px',
    },
    filter: 'none',
    alignItems: 'center',
    display: 'flex',
    width: 'auto',
}

export const LAYOUT_COLUMNS = 48; // Number of columns in the editor and layout
export const LAYOUT_ROW_HEIGHT = 10; // The height of rows (in pixels) in the editor and layout
// Change this if ELEMENT_DEFAULT_PROPERTIES has any other properties than styles
export const ELEMENT_DEFAULT_STYLE = ELEMENT_DEFAULT_PROPERTIES;

export const DEFAULT_ACTIONS = {
    link: { type: 'none', link: '' },
}

export const ELEMENT_TYPES = [
    'text',
    'image',
    'button',
    'separator',
    'slide',
    'video',
    'embed',
    'poll'
]

export const COMPONENT_CATEGORY_ICONS = {
    blank: <IconComponentTree size="26" />,
    list: <IconList size="26" />,
    card: <IconCard size="26" />,
    meet: <IconSocial size="26" />,
    stream: <IconVideo size="26" />,
    conditional: <IconConditional size="26" />,
    gallery: <IconGallery size="26" />,
    modal: <IconModal size="26" />,
    text: <IconText size="26" />,
    poll: <IconBarChart size="26" />,
}

export const ELEMENT_TYPE_ICONS = {
    text: <IconText size={30} style={{ color: '#C4281A' }} />,
    embed: <IconEmbed size={30} style={{ color: '#C4281A' }} />,
    image: <IconImage size={30} style={{ color: '#C4281A' }} />,
    button: <IconButton size={30} style={{ color: '#C4281A' }} />,
    separator: <IconSeparator size={30} style={{ color: '#C4281A' }} />,
    slide: <IconCarousel size={30} style={{ color: '#C4281A' }} />,
    video: <IconVideo size={30} style={{ color: '#C4281A' }} />,
    poll: <IconBarChart size={30} style={{ color: '#C4281A' }} />,
}


export const COMPONENT_PRESETS = [
    'stream',
    'meet',
    'poll',
    'carousel',
    'listing card',
    'listing card multi'
]

export const COMPONENT_ICONS = {
    'stream': <StreamIcon size={60} color={'#C4281A'} />,
    'meet': <IconUsers size={26} />,
    'poll': <IconBarChart size={26} />,
    'carousel': <IconCarousel size={26} />,
    'listing card': <IconComponentTree size="60" style={{ color: '#C4281A' }} />,
    'listing card multi': <IconComponentTree size="60" style={{ color: '#C4281A' }} />,
    'blank': <IconComponentTree size="60" style={{ color: '#C4281A' }} />,
}

export function calculateY(components) {
    return components.reduce((acc, component) =>
        acc = acc > (component.h + component.y) ? acc : (component.h + component.y),
        0);
}

const createElementActions = (elem) => {
    let elemActions = {}
    const elementConfig = COMPONENT_CONFIGS[elem].element
    elementConfig.actions.forEach(a => {
        elemActions[a] = DEFAULT_ACTIONS[a]
    })
    if (Object.keys(elemActions).length === 0) elemActions = null
    return elemActions
}

const createElemArray = (isAdditional, arr, index, defaultElement, content, languages = ['en', 'fi']) => {
    if (!isAdditional) {
        if (arr.length <= 0) return []
        return arr.map((e, i) => {

            // Populating actions object for each element
            const elemConfig = COMPONENT_CONFIGS[e.type].element
            const initialProperties = elemConfig.initialProperties || {}
            return {
                ...defaultElement,
                properties: initialProperties,
                i: `c${index}e${i}`,
                index: i,
                name: e.name,
                content: e.placeholder ? e.placeholder : content,
                renderType: e.type,
                actions: createElementActions(e.type)
            }
        })
    } else if (parseInt(arr)) {
        let elemArray = []

        for (let i = 0; i < parseInt(arr); i++) {
            let slideContent = {}
            languages.forEach(lang => {
                let c = { ...slideContent, [lang]: `<p>Slide ${i + 1}</p>` }
                slideContent = c
            })
            elemArray.push({
                ...defaultElement,
                i: `c${index}e${i}`,
                name: `Slide ${i + 1}`,
                content: slideContent,
                index: i,
                renderType: 'slide'
            })
        }
        return elemArray
    } else {
        if (arr.length <= 0) return []
        return arr.map((e, i) => {
            const config = COMPONENT_CONFIGS[e].element
            const placeholder = config?.placeholder
            const initialProperties = config?.initialProperties ? config.initialProperties : {}
            return {
                ...defaultElement,
                properties: initialProperties,
                i: `c${index}e${i}`,
                index: i,
                name: `${e} ${i + 1}`,
                content: placeholder || content || COMPONENT_CONFIGS[e].element?.initialContent,
                renderType: e,
                actions: createElementActions(e)
            }
        })
    }
}

// Map different components into view
export function newComponents(parentId, components, name, preset, content = null, additionalElements, editLocks, initialContent, languages) {
    const modifiedComponents = cloneDeep(components);
    let index = 0;
    const isAdditional = additionalElements.length > 0 || !isNaN(parseInt(additionalElements))
    let newComponentId

    for (let view of editLocks) {
        for (let comp of components[view]) {
            if (comp.index >= index) {
                index = comp.index;
            }
        }
    }
    index++;

    for (let view of editLocks) {
        const componentMaxY = components[view].reduce((acc, component) =>
            acc = acc > (component.h + component.y) ? acc : (component.h + component.y),
            0);
        const defaultComponent = {
            i: `c${index}`,
            x: 0,
            y: componentMaxY,
            index: index,
            type: "component",
            preset: preset === 'gallery' ? 'carousel' : preset,
            parentId: parentId,
            properties: COMPONENT_DEFAULT_PROPERTIES,
            actions: DEFAULT_ACTIONS,
        }
        const defaultElement = {
            type: "element",
            parentId: `c${index}`,
            properties: ELEMENT_DEFAULT_PROPERTIES,
            actions: DEFAULT_ACTIONS,
        }
        const componentConf = COMPONENT_CONFIGS[preset].component
        const { height, width } = componentConf.dimensions[view]
        const { name, initialElements, link } = componentConf
        const newComponent = {
            ...defaultComponent,
            h: height,
            w: width,
            name,
            link: link ? link : null,
            content: initialContent ? initialContent : "",
            elements: isAdditional ?
                createElemArray(isAdditional, additionalElements, index, defaultElement, content, languages) :
                createElemArray(isAdditional, initialElements, index, defaultElement, content || initialContent, languages)
        }
        newComponentId = newComponent.i
        modifiedComponents[view].push(newComponent)
    }
    return { modifiedComponents, newComponentId };
}

export function newElement(elements, parentId, renderType) {
    let elementIndex = 0;

    for (let element of elements) {
        if (element.index >= elementIndex) {
            elementIndex = element.index;
        }
    }
    elementIndex++;
    const languageDefaults = () => {
        const availableLocales = { ...AVAILABLE_LOCALES }
        Object.keys(availableLocales).forEach(key => availableLocales[key] = '')
        return availableLocales
    }
    const config = COMPONENT_CONFIGS[renderType].element
    const initialContent = config.initialContent ? config.initialContent : '';
    const content = renderType === 'text' ? languageDefaults() : initialContent;
    const initialProperties = config.initialProperties ? config.initialProperties : ELEMENT_DEFAULT_PROPERTIES
    //const content = renderType === 'text' ? COMPONENT_CONFIGS[renderType].element.initialContent : '';
    return {
        i: `${parentId}e${elementIndex}`,
        index: elementIndex,
        name: `${renderType}`,
        type: "element",
        content: content,
        parentId: parentId,
        properties: initialProperties,
        renderType: renderType,
        actions: DEFAULT_ACTIONS,
    }
}

// WIDGET MAPPERS

// Map layouts property widgets
export const LAYOUT_PROPERTIES_WIDGETS = [
    'background'
]

// Map component / element widgets
export const parseElementStyle = (properties, defaults) => {
    const styles = { zIndex: 2 };
    for (let key in defaults) {
        if (properties[key]) {
            if (key === 'border') {
                styles['border'] = properties['border']['border'];
                styles['borderRadius'] = properties['border']['borderRadius']
            } else if (key === 'background') {
                styles['backgroundImage'] = properties['background']['image'];
                styles['backgroundSize'] = properties['background']['size'];
                styles['backgroundPosition'] = properties['background']['position'];
                styles['backgroundColor'] = properties['background']['color'];
                styles['backgroundRepeat'] = properties['background']['repeat'];
            } else {
                styles[key] = properties[key]
            }
        } else {
            styles[key] = defaults[key]
        }
    }
    return styles
}

// Setup keywords for different stages of the tutorial
export const tutorialTargets = {
    tree: 'Tree',
    room: 'Room',
    widgets: 'Widgets',
    lockComponents: 'Lock components',
    fluidComponents: 'Fluid components',
    layoutChoose: 'Layout choose',
    sitemap: 'Sitemap',
    editors: 'Editors',
    preview: 'Preview',
    saveChanges: 'Save changes'
}

// Setup the order in which the tutorial will be showed
const tutorialProgress = [
    tutorialTargets.tree,
    tutorialTargets.room,
    tutorialTargets.widgets,
    tutorialTargets.lockComponents,
    tutorialTargets.fluidComponents,
    tutorialTargets.layoutChoose,
    tutorialTargets.sitemap,
    tutorialTargets.editors,
    tutorialTargets.preview,
    tutorialTargets.saveChanges,
]

// Function an component can call to check if it's their tutorial 'turn'
export const checkActiveTutorialStep = (target, step) => {
    return tutorialProgress[step] === target;
}

export const DEFAULT_DIMENSIONS = {
    mobile: {
        height: 20,
        width: LAYOUT_COLUMNS
    },
    tablet: {
        height: 24,
        width: LAYOUT_COLUMNS
    },
    desktop: {
        height: 20,
        width: LAYOUT_COLUMNS
    }
}

export const DEFAULT_CARD_DIMENSIONS = {
    mobile: {
        height: 30,
        width: LAYOUT_COLUMNS
    },
    tablet: {
        height: 36,
        width: LAYOUT_COLUMNS
    },
    desktop: {
        height: 30,
        width: LAYOUT_COLUMNS / 2
    }
}

export const COMPONENT_CONFIGS = {
    // BLANK COMPONENT
    blank: {
        category: 'blank',
        categoryDescription: 'Empty component you can tweak as you like',
        component: {
            properties: [
                'border',
                'padding',
                'alignment',
                'background',
                'boxShadow',
                'overlay'
            ],
            actions: [
                'link'
            ],
            initialElements: [],
            can_add_elements: ['blank', 'text', 'image', 'button', 'separator', 'video', 'embed', 'poll'],
            icon: <IconComponentTree size="26" />,
            addModalIcon: <EditorModalEmptyIcon />,
            addModalComponent: (props) => { return <BlankComponentPreview {...props} /> },
            addModalHeader: "Add new element",
            name: 'Blank component',
            description: 'Empty component you can tweak as you like',
            link: { type: 'none', link: '' },
            dimensions: DEFAULT_DIMENSIONS
        },
        element: {
            description: 'Leave the component as empty',
            addModalIcon: <EditorModalEmptyIcon />
        },
    },
    // MEET COMPONENT
    meet: {
        category: 'meet',
        categoryDescription: 'Component for conference meetings',
        component: {
            properties: [
                'border',
                'padding',
                'background',
                'boxShadow',
            ],
            actions: [],
            initialElements: [
                {
                    name: 'Meet',
                    type: 'meet'
                }
            ],
            can_add_elements: [],
            icon: <IconSocial size="26" />,
            addModalIcon: <EditorModalMeetIcon />,
            addModalComponent: (props) => { return <ComponentPreview {...props} /> },
            name: 'Meet component',
            description: 'Component for conference meetings',
            dimensions: DEFAULT_DIMENSIONS,
            getAdditionalData: (props) => {
                const { organizer, slug } = props
                return fetchMeets(organizer, slug)
            }
        },
        element: {
            properties: [],
            actions: [],
            content: [
                'meet'
            ],
            name: 'Meet',
            helpText: 'Add new meet element',
            defaultWidget: 'meet',
            addModalIcon: <EditorModalMeetIcon />
        }
    },
    // STREAM COMPONENT
    stream: {
        category: 'stream',
        categoryDescription: 'Basic stream component',
        component: {
            properties: [
                'border',
                'padding',
                'boxShadow',
                'background'
            ],
            actions: [],
            initialElements: [
                {
                    name: 'Stream',
                    type: 'stream'
                }
            ],
            can_add_elements: [],
            icon: <IconVideo size="26" />,
            addModalIcon: <EditorModalStreamIcon />,
            addModalComponent: (props) => { return <ComponentPreview {...props} /> },
            name: 'Stream component',
            description: 'Basic stream component',
            dimensions: {
                mobile: {
                    height: 20,
                    width: LAYOUT_COLUMNS
                },
                tablet: {
                    height: 45,
                    width: LAYOUT_COLUMNS
                },
                desktop: {
                    height: 65,
                    width: LAYOUT_COLUMNS
                }
            },
            getAdditionalData: async (props) => {
                const { organizer, slug } = props
                const customStreams = await fetchCustomStreams(organizer)
                const streams = await fetchStreams(organizer, slug)
                return [...streams, ...customStreams]
            }
        },
        element: {
            properties: [
                'videoSettings'
            ],
            actions: [],
            content: [
                'stream'
            ],
            name: 'Stream',
            defaultWidget: 'stream',
            helpText: 'Add new stream element',
            initialProperties: {
                videoSettings: { autoPlay: true, loop: false }
            },
        }
    },
    // POLL COMPONENT
    poll: {
        category: 'poll',
        categoryDescription: 'Single poll component',
        component: {
            properties: [
                'border',
                'padding',
                'boxShadow',
                'background'
            ],
            actions: [],
            initialElements: [
                {
                    name: 'Poll',
                    type: 'poll'
                }
            ],
            can_add_elements: [],
            icon: <IconBarChart size="26" />,
            addModalIcon: <EditorModalPollIcon />,
            addModalComponent: (props) => { return <ComponentPreview {...props} /> },
            name: 'Poll component',
            description: 'Single poll component',
            dimensions: {
                mobile: {
                    height: 40,
                    width: LAYOUT_COLUMNS / 2
                },
                tablet: {
                    height: 40,
                    width: LAYOUT_COLUMNS / 2
                },
                desktop: {
                    height: 40,
                    width: LAYOUT_COLUMNS / 2
                }
            },
            getAdditionalData: () => {
                return store.getState().polls.currentPolls
            }
        },
        element: {
            properties: [],
            actions: [],
            content: [
                'poll'
            ],
            initialContent: "",
            name: 'Poll',
            description: 'Add a new poll',
            helpText: 'Add new poll element',
            defaultWidget: 'poll',
            icon: <IconBarChart size={30} style={{ color: '#C4281A' }} />,
            addModalIcon: <EditorModalPollIcon />
        }
    },
    // POLL COMPONENT
    gallery: {
        category: 'gallery',
        categoryDescription: 'Carousel component',
        component: {
            properties: [
                'border',
                'padding',
                'alignment',
                'background',
                'boxShadow',
            ],
            actions: [
                'link'
            ],
            content: [
                'carousel'
            ],
            initialElements: [],
            excludeWidgetsForInnerElements: { //Excluding unwanted widgets for child components
                slide: ['overlay']
            },
            can_add_elements: [
                'slide'
            ],
            icon: <IconGallery size="26" />,
            defaultStyles: { width: "100%" },
            addModalIcon: <EditorModalGalleryIcon />,
            addModalComponent: (props) => { return <CarouselComponentPreview {...props} /> },
            name: 'Carousel',
            description: 'Use carousel component to add some movement to your page and showcase important content.',
            dimensions: DEFAULT_DIMENSIONS
        },
        element: {}
    },
    "simple card": {
        category: 'card',
        categoryDescription: 'Card component',
        component: {
            properties: [
                'boxShadow',
                'border',
                'background'
            ],
            actions: [],
            can_add_elements: [],
            icon: <IconCard size="26" />,
            addModalIcon: <EditorModalCardIcon />,
            addModalComponent: (props) => { return <CardComponentPreview {...props} /> },
            name: 'Simple card',
            description: 'Used when listing different rooms',
            link: { type: 'none', link: '' },
            initialElements: [
                {
                    name: 'Background',
                    type: 'image',
                    placeholder: placeholderBgSimpleCard,
                },
                {
                    name: 'Title',
                    type: 'text',
                    placeholder: { 'en': 'Title', 'fi': 'Otsikko' }
                },
                {
                    name: 'Link Button',
                    type: 'button',
                    placeholder: { 'en': 'Proceed', 'fi': 'Siirry' }
                }
            ],
            excludeWidgetsForInnerElements: { //Excluding unwanted widgets for child components
                image: ['link']
            },
            dimensions: {
                ...DEFAULT_CARD_DIMENSIONS,
                desktop: {
                    height: 30,
                    width: (LAYOUT_COLUMNS / 2) - 1
                }
            }
        },
        element: {}
    },
    "listing card": {
        category: 'card',
        categoryDescription: 'Card component',
        component: {
            properties: [
                'boxShadow',
                'border'
            ],
            actions: [],
            can_add_elements: [],
            icon: <IconCard size="26" />,
            addModalIcon: <EditorModalCardIcon />,
            addModalDescription: "Add new card type component",
            name: 'Listing card',
            description: 'Used when listing different rooms (e.g. a card containing partner information and link to their partner room)',
            initialElements: [
                {
                    name: 'Logo',
                    type: 'image',
                    placeholder: placeholderImage,
                },
                {
                    name: 'Background',
                    type: 'image',
                    placeholder: placeholderBg,
                },
                {
                    name: 'Title',
                    type: 'text',
                    placeholder: { 'en': 'Title', 'fi': 'Otsikko' }
                },
                {
                    name: 'Description',
                    type: 'text',
                    placeholder: { 'en': 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.', 'fi': 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.' }
                },
                {
                    name: 'Link Button',
                    type: 'button',
                    placeholder: { 'en': 'Go to room', 'fi': 'Mene huoneeseen' }
                }
            ],
            excludeWidgetsForInnerElements: { //Excluding unwanted widgets for child components
                image: ['link']
            },
            dimensions: DEFAULT_CARD_DIMENSIONS
        },
        element: {}
    },
    "listing card multi": {
        category: 'card',
        categoryDescription: 'Card component',
        component: {
            properties: [
                'boxShadow'
            ],
            actions: [],
            can_add_elements: ['button'],
            icon: <IconCard size="26" />,
            addModalIcon: <EditorModalCardIcon />,
            name: 'Listing card multi',
            description: 'Larger listing card, used when listing multiple rooms inside one card',
            initialElements: [
                {
                    name: 'Background',
                    type: 'image',
                    placeholder: placeholderBg,
                },
                {
                    name: 'Title',
                    type: 'text',
                    placeholder: { 'en': 'Title', 'fi': 'Otsikko' }
                },
                {
                    name: 'Description',
                    type: 'text',
                    placeholder: { 'en': 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.', 'fi': 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.' }
                },
                {
                    name: 'Link Button',
                    type: 'button',
                    placeholder: { 'en': 'Go to room', 'fi': 'Mene huoneeseen' }
                }
            ],
            dimensions: DEFAULT_CARD_DIMENSIONS
        },
        element: {}
    },
    slide: {
        component: {},
        element: {
            properties: [
                'background',
                'overlay'
            ],
            actions: [
                'link'
            ],
            content: [
                'text'
            ],
            name: 'Slide',
            helpText: 'Add new slide',
            description: 'Add a new slide for your carousel',
            defaultWidget: 'text',
            addModalIcon: <EditorModalGalleryIcon />,
            icon: <IconCarousel size={30} style={{ color: '#C4281A' }} />
        }
    },
    image: {
        component: {},
        element: {
            properties: ['padding'],
            actions: [
                'link'
            ],
            content: [
                'image'
            ],
            name: 'Image',
            placeholder: placeholderBg,
            initialProperties: { height: 'auto' },
            description: 'Add visualization to your page by adding tempting images.',
            helpText: 'Add new image',
            defaultWidget: 'image',
            icon: <IconImage size={30} style={{ color: '#C4281A' }} />,
            addModalIcon: <EditorModalImageIcon />
        }
    },
    text: {
        component: {},
        element: {
            properties: ['filter', 'background', 'padding'],
            actions: [
                'link'
            ],
            content: [
                'text'
            ],
            initialContent: { en: 'Placeholder text', fi: 'Teksti', se: 'Text' },
            name: 'Text',
            description: 'Add readable content to your component. Text can be lists, headings, paragraphs and links.',
            helpText: 'Add new text element',
            defaultWidget: 'text',
            icon: <IconText size={30} style={{ color: '#C4281A' }} />,
            addModalIcon: <EditorModalTextIcon />,
            placeholder: { 'en': 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.', 'fi': 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.' }
        }
    },
    video: {
        component: {},
        element: {
            properties: ['videoSettings'],
            actions: [],
            initialProperties: {
                videoSettings: { autoPlay: false, loop: false }
            },
            content: [
                'url'
            ],
            name: 'Video',
            description: 'Add an embedded video (youtube, vimeo, etc.)',
            helpText: 'Add new video element',
            defaultWidget: 'url',
            icon: <IconVideo size={30} style={{ color: '#C4281A' }} />,
            addModalIcon: <EditorModalVideoIcon />
        }
    },
    separator: {
        component: {},
        element: {
            properties: ['background'],
            actions: [],
            content: [
                'separator'
            ],
            initialContent: {
                size: "100%",
                orientation: 'horizontal',
                thickness: "1px",
                color: 'rgba(0,0,0,1)'
            },
            initialProperties: "",
            name: 'Separator',
            description: 'Add a separator element',
            helpText: 'Add separator element',
            defaultWidget: 'separator',
            icon: <IconSeparator size={30} style={{ color: '#C4281A' }} />,
            addModalIcon: <EditorModalSeparatorIcon />
        }
    },
    button: {
        component: {},
        element: {
            properties: [
                'background',
                'border',
                'padding',
                'boxShadow'
            ],
            actions: ['link'],
            content: [
                'text'
            ],
            initialContent: { en: 'Button', fi: 'Nappi', se: '' },
            initialProperties: {
                padding: '5px 10px 5px 10px',
                background: {
                    "color": "rgba(255,255,255,1)",
                },
                border: {
                    border: '1px solid rgba(0,0,0,1)',
                    borderRadius: '5px',
                },
                filter: 'none',
                alignItems: 'center',
                display: 'flex',
                width: 'auto',
                height: 'fit-content',
            },
            name: 'Button',
            description: 'Use buttons for internal or external navigation.',
            helpText: 'Add new button',
            defaultWidget: 'text',
            icon: <IconButton size={30} style={{ color: '#C4281A' }} />,
            addModalIcon: <EditorModalButtonIcon />
        }
    },
    embed: {
        component: {},
        element: {
            properties: [],
            actions: [],
            content: [
                'embed'
            ],
            initialContent: "",
            name: 'Embed',
            description: 'Need external elements to your page such as embeddable shop, calendars or forms? Use this element to add those to your page.',
            helpText: 'Add embedded',
            defaultWidget: 'embed',
            icon: <IconEmbed size={30} style={{ color: '#C4281A' }} />,
            addModalIcon: <EditorModalEmbedIcon />
        }
    },
}

export const WIDGET_COMPONENTS = {
    content: {
        'separator': {
            component: <SeparatorWidget />,
            button: <IconSeparator />,
        },
        'text': {
            component: <TextWidget />,
            button: <EditorTextIcon />
        },
        'embed': {
            component: <EmbedWidget />,
            button: <IconEmbed />,
        },
        'image': {
            component: <ImageUrlWidget />,
            button: <IconImage />,
        },
        'carousel': {
            component: <CarouselWidget />,
            button: <IconCarousel />,
        },
        'url': {
            component: <UrlWidget />,
            button: <IconVideo />,
        },
        'stream': {
            component: <StreamWidget />,
            button: <IconVideo />,
        },
        'meet': {
            component: <MeetWidget />,
            button: <IconUsers />
        },
        'poll': {
            component: <PollWidget />,
            button: <IconBarChart />
        }
    },
    actions: {
        'link': {
            component: <LinkWidget />,
            button: <IconLink />
        },
    },
    properties: {
        'border': {
            component: <BorderWidget />,
            button: <EditorBorderIcon />,
        },
        'padding': {
            component: <PaddingWidget />,
            button: <EditorPaddingIcon />,
        },
        'alignment': {
            component: <AlignmentMenu />,
            button: <EditorAlignmentIcon />,
        },
        'background': {
            component: <BackgroundWidget />,
            button: <EditorBackgroundIcon />
        },
        'boxShadow': {
            component: <BoxShadowWidget />,
            button: <EditorShadowIcon />
        },
        'overlay': {
            component: <OverlayWidget />,
            button: <EditorOverlayIcon />
        },
        'filter': {
            component: <DropShadowWidget />,
            button: <IconShadow />
        },
        'videoSettings': {
            component: <VideoSettingsWidget />,
            button: <IconVideo />
        }
    }
}