/** @format */

import { useSelector } from "react-redux";
import { useDeleteEventSlot, useAddEventSlot } from "../apiCalls";
import { IconStar, LivetoLoader } from "components/icons";
import { LoadingOverlay } from "@mantine/core";
import { Localized } from "@fluent/react";
import { useEffect, useMemo, useState } from "react";

export const useManageMyCalendar = (slot, buttonClassName = "sidebar-handle-favourites", icon = null) => {
	const { loading, deleteEventSlot } = useDeleteEventSlot();
	const { loading: addLoading, addEventSlot } = useAddEventSlot();
	const { calendar = {} } = useSelector(state => state.schedule);
	const { slug = "" } = useSelector(state => state.event);
	const { guestuser } = useSelector(state => state.user);
	const { event_timeslots = [] } = calendar;

	if (guestuser) return ["", ""];

	const isIn = event_timeslots.map(et => et.id).includes(slot.id);

	const handleAddToFavorites = (e, isFavorite, slot) => {
		e.stopPropagation();
		try {
			if (isFavorite) {
				deleteEventSlot(slot, slug);
			} else {
				addEventSlot(slot, slug);
			}
		} catch (error) {
			console.error("Error", error);
		}
	};

	const isLoading = loading || addLoading;

	let button = isIn ? (
		<button
			className={buttonClassName}
			onClick={e => handleAddToFavorites(e, true, slot)}
			data-cy="remove-timeslot-btn"
		>
			{icon && icon}
			<Localized id="remove-favourite-from-calendar-button-text">
				<div className="w-100">Remove from your calendar</div>
			</Localized>
		</button>
	) : (
		<button
			className={buttonClassName}
			onClick={e => handleAddToFavorites(e, false, slot)}
			data-cy="add-timeslot-btn"
		>
			{icon && icon}
			<Localized id="add-favourite-to-calendar-button-text">Add to your calendar</Localized>
		</button>
	);

	let overlay = isLoading ? <LoadingOverlay loader={<LivetoLoader />} visible={isLoading} /> : "";

	return [button, overlay];
};

export const useScheduleHistory = (
	view,
	setView,
	date,
	setDate,
	filteredSchedules,
	setFilteredSchedules,
	eventListType,
	setEventListType
) => {
	const [history, setHistory] = useState([]);
	const [currentHistoryIndex, setCurrentHistoryIndex] = useState(-1);
	const [initialized, setInitialized] = useState(false);

	const stateToWatch = useMemo(
		() => ({
			filteredSchedules,
			eventListType,
			view,
			date
		}),
		[filteredSchedules, eventListType, view, date]
	);

	// Set initial history state only after initializing
	useEffect(() => {
		if (!initialized) return;

		// Compare with the last state in history to avoid duplicates
		const lastHistoryState = history[currentHistoryIndex];
		const isStateDifferent = JSON.stringify(lastHistoryState) !== JSON.stringify(stateToWatch);

		if (isStateDifferent) {
			// If navigating backward, truncate forward history
			if (currentHistoryIndex < history.length - 1) {
				setHistory(prevHistory => prevHistory.slice(0, currentHistoryIndex + 1));
			}

			// Add new state to history
			setHistory(prevHistory => [...prevHistory, stateToWatch]);
			setCurrentHistoryIndex(prevIndex => prevIndex + 1);
		}
	}, [JSON.stringify(stateToWatch), initialized]);

	// Go back in history
	const goBack = () => {
		if (currentHistoryIndex > 0) {
			const previousState = history[currentHistoryIndex - 1];
			restoreState(previousState);
			setCurrentHistoryIndex(currentHistoryIndex - 1);
		}
	};

	// Go forward in history
	const goForward = () => {
		if (currentHistoryIndex < history.length - 1) {
			const nextState = history[currentHistoryIndex + 1];
			restoreState(nextState);
			setCurrentHistoryIndex(currentHistoryIndex + 1);
		}
	};

	// Restore state function to revert to a specific history snapshot
	const restoreState = ({ filteredSchedules, eventListType, view, date }) => {
		setFilteredSchedules(filteredSchedules);
		setEventListType(eventListType);
		setView(view);
		setDate(date);
	};

	const backDisabled = currentHistoryIndex <= 0;
	const forwardDisabled = currentHistoryIndex === history.length - 1;

	const initialize = () => setInitialized(true);

	return [goBack, goForward, backDisabled, forwardDisabled, initialize];
};
