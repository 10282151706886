import { cloneDeep } from "lodash";
import * as a from "actiontypes";

export const inboxInitial = {
    active_profile: null, // user_id to match from private_messages,
    private_messages: [],
};

const inbox = (state = inboxInitial, action) => {
    switch (action.type) {
        case a.WS_REQUEST_CLIENT_TOKEN: {
            return inboxInitial;
        }
        case a.WS_RECONNECTED: {
            // Clear has_joined on reconnect, so user
            let newState = cloneDeep(state);
            for (let prof of newState.private_messages) {
                prof.has_joined = false;
            }
            return newState;
        }
        case a.WS_JOIN_ROOM:
            return { ...state };
        case a.WS_TOGGLE_INBOX:
            return { ...state, show_person_profile: false };
        case a.WS_TOGGLE_USER_SETTINGS:
            return { ...state, show_person_profile: false, active_profile: null };
        case a.WS_MAXIMIZE_AND_SHOW_USER_SETTINGS:
            return { ...state, show_person_profile: false, active_profile: null };
        case a.WS_MAXIMIZE_AND_NAVIGATE:
            return { ...state, show_person_profile: false, active_profile: null };
        case a.WS_TOGGLE_PROFILE: {
            let newState = cloneDeep(state);
            if (action.payload) {
                const user_id = action.payload.user_id ? action.payload.user_id : action.payload.chat_user_id
                let profile = newState.private_messages.find((prof) => prof.user_id === user_id);
                if (!profile) {
                    let new_profile = cloneDeep(action.payload);
                    new_profile["chat_history"] = action.payload.chat_history ? action.payload.chat_history : [];
                    newState.private_messages.push(new_profile);
                }
                newState.active_profile = user_id;
            } else {
                newState.active_profile = null;
            }

            return newState;
        }

        case a.WS_CLEAR_NOTIFICATION: {
            let newState = cloneDeep(state);
            let prof = newState.private_messages.find((prof) => prof.user_id === action.payload.user_id);
            prof.preview_message.seen = true;
            return newState;
        }
        case a.WS_REMOVE_OWN_NOTIFICATION: {
            let newState = cloneDeep(state);
            let prof = newState.private_messages.find((prof) => prof.user_id === action.payload);
            prof.preview_message.seen = true;
            return newState;
        }

        case a.WS_NEW_PRIVATE_MESSAGE: {
            let newState = cloneDeep(state);
            let profile = newState.private_messages.find((prof) => prof.user_id === action.payload.inbox_key);
            if (profile && profile.preview_message) {
                profile.preview_message.message = action.payload.incoming_message.message;
                profile.preview_message.seen = false;
            } else {
                profile.preview_message = {
                    user_id: profile.user_id,
                    icon: profile.icon,
                    message: action.payload.incoming_message.message,
                    first_name: profile.first_name,
                    last_name: profile.last_name,
                    seen: false,
                };
            }
            profile.chat_history.push(action.payload.incoming_message);
            return newState;
        }

        case a.WS_CREATE_NEW_INBOX: {
            // If the person who is sending you message is not included in the inbox private_messages, create a new entry for it
            let newState = cloneDeep(state);
            const preview_message = {
                // Used in inbox listing (views/inbox/inbox)
                user_id: action.payload.inbox.user_id,
                icon: action.payload.inbox.icon,
                message: action.payload.message.message,
                first_name: action.payload.inbox.first_name,
                last_name: action.payload.inbox.last_name,
                seen: action.payload.message.seen,
            };
            let new_profile = action.payload.inbox;
            new_profile.preview_message = preview_message;
            new_profile.chat_history = [];
            newState.private_messages.push(new_profile);

            return {...newState};
        }

        case a.WS_PRIVATE_ROOM_JOINED: {
            let newState = cloneDeep(state);
            // Find user to map this room to
            let room = newState.private_messages.find((room) => room.user_id === action.payload.receiver_id);
            if (room) {
                room.chat_history = action.payload.chat_history;
                room.has_joined = true;
                room.room_id = action.payload.room_id;
            }
            return newState;
        }

        case a.WS_RESET_APPLICATION:
            return inboxInitial;

        default:
            return state;
    }
};
export default inbox;
