/** @format */

import { Localized } from "@fluent/react";
import moment from "moment";
import { IconArrowRight, IconChevronRight, IconTimes } from "./icons";
import { LvtBadge } from "./LvtComponents";

const lvtColors = ["#ee8e88", "#00a3a8", "#8bc400"];

/// Seeded random number generator
function seededRandom(seed) {
	let x = Math.sin(seed++) * 10000;
	return x - Math.floor(x);
}

// Generate a pastel color with a seeded random function
function generatePastelColor(seed) {
	// Generate random values for r, g, b within a pastel range (e.g., 127-255)
	const r = Math.floor(seededRandom(seed) * 128) + 127; // Limit to pastel range
	const g = Math.floor(seededRandom(seed + 1) * 128) + 127;
	const b = Math.floor(seededRandom(seed + 2) * 128) + 127;
	return { r, g, b };
}

// Convert RGB to hex format
function rgbToHex({ r, g, b }) {
	const toHex = c => c.toString(16).padStart(2, "0");
	return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

// Check luminance for contrast
function luminance({ r, g, b }) {
	const a = [r, g, b].map(v => {
		v /= 255;
		return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
	});
	return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

// Generate an array of colors with high contrast against black text
export function generateColors(count) {
	const colors = [];
	const seed = 12345; // Constant seed to ensure the same colors

	for (let i = 0; i < count; i++) {
		let color;
		let lum;
		let attempts = 0; // Failsafe counter

		do {
			color = generatePastelColor(seed + i * 1000 + attempts); // Vary seed based on attempts
			lum = luminance(color);
			attempts++;
			// Break out of the loop if too many attempts have been made
			if (lum > 0.4 || attempts > 10) break; // Ensure contrast is with black (luminance < 0.4 is darker)
		} while (lum > 0.4); // Adjust threshold for darker colors

		colors.push(rgbToHex(color));
	}
	return [...lvtColors, ...colors];
}

export const earliestDate = dates => new Date(Math.min(...dates.map(date => new Date(date))));
export const latestDate = dates => new Date(Math.max(...dates.map(date => new Date(date))));

export const checkTimes = (start, end) => {
	if (moment().isAfter(end)) return "ended";
	else if (moment(start).isAfter(moment())) return "upcoming";
	else return "live";
};

export const indicator = status => {
	switch (status) {
		case "ended":
			return (
				<LvtBadge color="orange" variant="filled">
					<Localized id="schedule-agenda-ended">ENDED</Localized>
				</LvtBadge>
			);
		case "live":
			return (
				<LvtBadge color="red" variant="filled">
					<Localized id="schedule-agenda-live">LIVE</Localized>
				</LvtBadge>
			);

		default:
			return (
				<LvtBadge color="green" variant="filled">
					<Localized id="schedule-agenda-upcoming">UPCOMING</Localized>
				</LvtBadge>
			);
	}
};
