/** @format */

import { combineReducers } from "redux";

import eventReducer from "./eventReducer";
import userReducer from "./userReducer";
import attendees from "./websocket/attendees";
import all_attendees from "./allAttendees";
import chatrooms from "./websocket/chatrooms";
import connection from "./websocket/connection";
import inbox from "./websocket/inbox";
import message from "./websocket/message";
import moderator from "./websocket/moderator";
import polls from "./websocket/polls";
import user from "./websocket/user";
import view from "./websocket/view";
import config from "./websocket/config";
import webrtc from "./webrtc/webrtc";
import webrtcSettings from "./webrtc/settings";
import lobbyReducer from "./lobby";
import roomReducer from "./room";
import notifications from "./websocket/notifications";
import calendar from "./schedule/calendar";
import editorReducer from "./editor";
import displayType from "./displayType";
import notificationReducer from "./schedule/notificationReducer";
import closeModals from "./schedule/closeModals";
import engagement from "./websocket/engagement";
import messages from "./messages";
import refs from "./refs";
import componentReducer from "./components";
import editorProgress from "./editorProgress";
import performerReducer from "./performers";

const rootReducer = combineReducers({
	user: userReducer,
	event: eventReducer,
	attendees: attendees,
	all_attendees: all_attendees,
	chatrooms: chatrooms,
	connection: connection,
	inbox: inbox,
	message: message,
	moderator: moderator,
	polls: polls,
	websocketUser: user,
	view: view,
	config: config,
	webrtc: webrtc,
	webrtcSettings: webrtcSettings,
	room: roomReducer,
	lobby: lobbyReducer,
	notifications: notifications,
	schedule: calendar,
	editorLanguage: editorReducer,
	displayType: displayType,
	alerts: notificationReducer,
	close_modals: closeModals,
	components: componentReducer,
	engagement: engagement,
	messages: messages,
	refs: refs,
	editorProgress: editorProgress,
	performerReducer: performerReducer,
});

export default rootReducer;
