export const SOCKET_NAMESPACE = "WEBSOCKET::";

export const WS_PRIVATE_MESSAGE_NOTICE = "PRIVATE_MESSAGE_NOTICE";
export const WS_STOP_SILENCE = "STOP_SILENCE";
export const WS_REQUEST_ATTENDEES = "REQUEST_ATTENDEES";
export const WS_ATTENDEES_REQUESTED = "ATTENDEES_REQUESTED";
export const WS_USER_JOINED_CHANNEL = "USER_JOINED_CHANNEL";
export const WS_MESSAGE_RECEIVED = "MESSAGE_RECEIVED";
export const WS_MESSAGE_RECEIVED_BATCH = "MESSAGE_RECEIVED_BATCH";
export const WS_USER_JOINED_ROOM = "USER_JOINED_ROOM";
export const WS_REQUEST_CHAT_HISTORY = "REQUEST_CHAT_HISTORY";
export const WS_UPDATE_CHAT_HISTORY = "UPDATE_CHAT_HISTORY";
export const WS_CHAT_HISTORY_REQUESTED = "CHAT_HISTORY_REQUESTED";
export const WS_REMOVE_ALL_MESSAGES_FROM_USER = "REMOVE_ALL_MESSAGES_FROM_USER";
export const WS_REMOVE_MESSAGE_FROM_HISTORY = "REMOVE_MESSAGE_FROM_HISTORY";
export const WS_ANNOUNCE_SILENCE = "ANNOUNCE_SILENCE";
export const WS_OPEN_CONNECTION = "OPEN_CONNECTION";
export const WS_CLOSE_CONNECTION = "CLOSE_CONNECTION";
export const WS_CONNECTION_ESTABLISHED = "CONNECTION_ESTABLISHED";
export const WS_JOIN_ROOM = "JOIN_ROOM";
export const WS_ROOM_JOINED = "ROOM_JOINED";
export const WS_CHANGE_ROOM = "CHANGE_ROOM";
export const WS_START_WAIT = "START_WAIT";
export const WS_RESET_CONNECTION = "RESET_CONNECTION";
export const WS_RESET_APPLICATION = "RESET_APPLICATION";
export const WS_TOGGLE_PROFILE = "TOGGLE_PROFILE";
export const WS_NEW_PRIVATE_MESSAGE = "NEW_PRIVATE_MESSAGE";
export const WS_PRIVATE_ROOM_JOINED = "PRIVATE_ROOM_JOINED";
export const WS_CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const WS_REMOVE_OWN_NOTIFICATION = "REMOVE_OWN_NOTIFICATION";
export const WS_CREATE_NEW_INBOX = "CREATE_NEW_INBOX";
export const WS_SEND_MESSAGE = "SEND_MESSAGE";
export const WS_MESSAGE_SENT = "MESSAGE_SENT";
export const WS_SEND_PRIVATE_MESSAGE = "SEND_PRIVATE_MESSAGE";
export const WS_DELETE_MESSAGE = "DELETE_MESSAGE";
export const WS_DELETE_ALL_USER_MESSAGES = "DELETE_ALL_USER_MESSAGES";
export const WS_SILENCE_USER = "SILENCE_USER";
export const WS_TOGGLE_MODERATOR_PROMPT = "TOGGLE_MODERATOR_PROMPT";
export const WS_TOGGLE_MODERATOR_MESSAGE_PROMPT = "TOGGLE_MODERATOR_MESSAGE_PROMPT";
export const WS_VOTE_FOR_POLL = "VOTE_FOR_POLL";
export const WS_WANT_TO_VOTE_FOR_POLL = "WANT_TO_VOTE_FOR_POLL";
export const WS_REQUEST_POLL_RESULTS = "REQUEST_POLL_RESULTS";
export const WS_POLL_VOTED = "POLL_VOTED";
export const WS_POLLS_REQUESTED = "POLLS_REQUESTED";
export const WS_HAS_VOTED = "HAS_VOTED";
export const WS_JOIN_ROOM_FAILED = "JOIN_ROOM_FAILED";
export const WS_SEND_HEARTBEAT = "SEND_HEARTBEAT";
export const WS_HEARTBEAT_RECEIVED = "HEARTBEAT";
export const WS_SEND_MESSAGE_FAILED = "SEND_MESSAGE_FAILED";
export const WS_USER_ANONYMOUS_SIGNIN = "USER_ANONYMOUS_SIGNIN";
export const WS_USER_FETCH_AUTHENTICATED_USER_DATA = "USER_FETCH_AUTHENTICATED_USER_DATA";
export const WS_USER_AUTHENTICATE = "USER_AUTHENTICATE";
export const WS_SET_USER = "SET_USER";
export const WS_TOGGLE_USER_SETTINGS = "TOGGLE_USER_SETTINGS";
export const WS_TOGGLE_INBOX = "TOGGLE_INBOX";
export const WS_TOGGLE_DARK_MODE = "TOGGLE_DARK_MODE";
export const WS_TOGGLE_LANGUAGE = "TOGGLE_LANGUAGE";
export const WS_TOGGLE_SYSTEM_MESSAGES = "TOGGLE_SYSTEM_MESSAGES";
export const WS_TOGGLE_ANONYMOUS_MODE = "TOGGLE_ANONYMOUS_MODE";
export const WS_TOGGLE_MODERATOR_MODE = "TOGGLE_MODERATOR_MODE";
export const WS_SIGNOUT = "SIGNOUT";
export const WS_RECONNECT = "RECONNECT";
export const WS_RECONNECTED = "RECONNECTED";
export const WS_RECONNECT_FAILED = "RECONNECT_FAILED";
export const WS_REQUEST_CLIENT_TOKEN = "REQUEST_CLIENT_TOKEN";
export const WS_CLIENT_TOKEN_REQUESTED = "CLIENT_TOKEN_REQUESTED";
export const WS_CHANGE_VIEW = "CHANGE_VIEW";
export const WS_TOGGLE_MINIMIZE = "TOGGLE_MINIMIZE";
export const WS_MAXIMIZE_AND_NAVIGATE = "MAXIMIZE_AND_NAVIGATE";
export const WS_MAXIMIZE_AND_SHOW_USER_SETTINGS = "MAXIMIZE_AND_SHOW_USER_SETTINGS";
export const WS_SHOW_JOIN_PROMPT = "SHOW_JOIN_PROMPT";
export const WS_CLOSE_PROMPT = "CLOSE_PROMPT";
export const WS_ALL_USER_MESSAGES_DELETED = "ALL_USER_MESSAGES_DELETED";
export const WS_MESSAGE_DELETED = "MESSAGE_DELETED";
export const WS_ROOM_JOIN_FAILED = "JOIN_ROOM_FAILED";
export const WS_SIGNIN = "SIGNIN";
export const WS_USER_AUTHENTICATE_ERROR = "USER_AUTHENTICATE_ERROR";
export const WS_USER_AUTHENTICATE_RESULT = "USER_AUTHENTICATE_RESULT";
export const WS_USER_SILENCED = "USER_SILENCED";
export const WS_VOTE_POLL = "VOTE_POLL";
export const WS_WAIT_FOR_HEARTBEAT = "WAIT_FOR_HEARTBEAT";
export const WS_REACT_WITH_EMOJI = "REACT_WITH_EMOJI";
export const WS_MESSAGE_REACTION = "MESSAGE_REACTION";
export const WS_MOD_HELD_MSG_APPROVE = "MOD_HELD_MSG_APPROVE";
export const WS_MOD_HELD_MSG_DELETE = "MOD_HELD_MSG_DELETE";
export const WS_MOD_REQUEST_HELD_MESSAGES = "MOD_REQUEST_HELD_MESSAGES";
export const WS_ROOM_CHANGE_SETTING = "ROOM_CHANGE_SETTING";
export const WS_MOD_HELD_MESSAGES_REQUESTED = "MOD_HELD_MESSAGES_REQUESTED";
export const WS_HELD_ROOM_MESSAGES = "WS_HELD_ROOM_MESSAGES";
export const WS_MOD_HELD_MSG = "MOD_HELD_MSG";
export const WS_MOD_HELD_MSG_SENT = "MOD_HELD_MSG_SENT";
export const WS_MOD_HELD_MSG_DELETED = "MOD_HELD_MSG_DELETED";
export const WS_CHAT_MESSAGE_HELD = "MSG_HELD";
export const WS_CHAT_MESSAGE_HELD_SENT = "MSG_HELD_SENT";
export const WS_HELD_MESSAGE_DECLINED = "MSG_HELD_DELETED";
export const WS_HELD_MESSAGE_DELETED = "HELD_MESSAGE_DELETED";
export const WS_CHATROOM_ANONYMOUS_MODE_CHANGED = "ANONYMOUS_CHATROOM_SETTING_CHANGED"
export const WS_CHAT_ANONYMOUS_MODE_CHANGED = "ANONYMOUS_CHAT_SETTING_CHANGED"
export const WS_CHATROOM_SLOWMODE_MODE_CHANGED = "SLOWMODE_CHATROOM_SETTING_CHANGED"
export const WS_CHAT_SLOWMODE_MODE_CHANGED = "SLOWMODE_CHAT_SETTING_CHANGED"
export const WS_CHATROOM_SETTING_CHANGED = "CHATROOM_SETTING_CHANGED"
export const WS_CHAT_SETTING_CHANGED = "CHAT_SETTING_CHANGED"

export const WRTC_SETTING_SET_USERMEDIA_VIDEO_QUALITY = "SETTING_SET_USERMEDIA_VIDEO_QUALITY";
export const WRTC_SETTING_SET_USERMEDIA_VIDEO_DEVICEID = "SETTING_SET_USERMEDIA_VIDEO_DEVICEID";
export const WRTC_SETTING_SET_USERMEDIA_AUDIO_DEVICEID = "SETTING_SET_USERMEDIA_AUDIO_DEVICEID";
export const WRTC_RING = "WRTC_RING";
export const WRTC_RINGING = "WRTC_RINGING";
export const WRTC_USER_BUSY = "WRTC_USER_BUSY";
export const WRTC_RING_ACCEPT = "WRTC_RING_ACCEPT";
export const WRTC_RING_ACCEPTED = "WRTC_RING_ACCEPTED";
export const WRTC_RING_REJECT = "WRTC_RING_REJECT";
export const WRTC_RING_REJECTED = "WRTC_RING_REJECTED";
export const WRTC_SEND_SIGNAL = "WRTC_SEND_SIGNAL";
export const WRTC_RECV_SIGNAL = "WRTC_RECV_SIGNAL";
export const WRTC_HANGUP = "WRTC_HANGUP";
export const WRTC_REMOTE_HANGUP = "WRTC_REMOTE_HANGUP";
export const WRTC_ENDED = "WRTC_ENDED";
export const WRTC_CONNECTED = "WRTC_CONNECTED";
export const WRTC_SET_LOCALUSERSTREAM = "SET_LOCALUSERSTREAM";
export const WRTC_SET_REMOTEUSERSTREAM = "SET_REMOTEUSERSTREAM";
export const WRTC_SET_LOCALDISPLAYSTREAM = "SET_LOCALDISPLAYSTREAM";
export const WRTC_SET_REMOTEDISPLAYSTREAM = "SET_REMOTEDISPLAYSTREAM";
export const WRTC_SET_USERADIODISABLED = "SET_USERADIODISABLED";
export const WRTC_SET_USERVIDEODISABLED = "SET_USERVIDEODISABLED";
export const WRTC_SCREENSHARE_ENABLE = "SCREENSHARE_ENABLE";
export const WRTC_SCREENSHARE_DISABLE = "SCREENSHARE_DISABLE";
export const WRTC_UPDATE_USERMEDIA_DEVICES_LIST = "UPDATE_USERMEDIA_DEVICES_LIST";
export const WRTC_SET_USERMEDIA_DEVICES_LIST = "SET_USERMEDIA_DEVICES_LIST";
export const WS_NEW_NOTIFICATION = "NOTIFICATION"
export const WS_RESET_NEW_NOTIFICATION = "RESET_NEW_NOTIFICATION"
export const WS_GET_NOTIFICATIONS = "GET_NOTIFICATIONS"
export const WS_RESET_NOTIFICATIONS = "WS_RESET_NOTIFICATIONS"
export const WS_REMOVE_NOTIFICATION = "WS_REMOVE_NOTIFICATION"
export const WS_MARK_NOTIFICATION_AS_READ = "WS_MARK_NOTIFICATION_AS_READ"
export const CLEAR_NOTIFICATION_STATE = "CLEAR_NOTIFICATION_STATE"
export const HANDLE_UNREAD = "HANDLE_UNREAD"
export const HANDLE_INVITES = "HANDLE_INVITES"
export const GET_ATTENDEE_CALENDAR = "GET_ATTENDEE_CALENDAR"
export const ADD_INVITE_ATTENDEE_CALENDAR = "ADD_INVITE_ATTENDEE_CALENDAR"
export const REMOVE_INVITE_FROM_ATTENDEE_CALENDAR = "REMOVE_INVITE_FROM_ATTENDEE_CALENDAR"
export const GET_CALENDAR = "GET_CALENDAR"
export const GET_SCHEDULE = "GET_SCHEDULE"
export const CREATE_SLOT = "CREATE_SLOT"
export const CREATE_AVAILABLE_SLOT = "CREATE_AVAILABLE_SLOT"
export const DELETE_SLOT = "DELETE_SLOT"
export const HANDLE_INVITE = "HANDLE_INVITE"
export const CANCEL_INVITE = "CANCEL_INVITE"
export const ADD_FAVORITE = "ADD_FAVORITE"
export const DELETE_FAVORITE = "DELETE_FAVORITE"
export const SET_ALERT = "SET_ALERT"
export const POP_ALERT = "POP_ALERT"
export const ADD_ALERT = "ADD_ALERT"
export const HANDLE_CANCEL_RECEIVED_INVITE = "HANDLE_CANCEL_RECEIVED_INVITE"
export const HANDLE_INVITE_RESPONSE = "HANDLE_INVITE_RESPONSE"
export const SET_CALENDAR_VISIBILITY = "SET_CALENDAR_VISIBILITY"
export const GET_DISPLAY_TYPE = "GET_DISPLAY_TYPE"
export const HANDLE_MODALS = "HANDLE_MODALS"
export const HANDLE_NOTIFICATION_CENTER = "HANDLE_NOTIFICATION_CENTER"
export const WS_ENGAGEMENT_MY_TOTAL_XP_CHANGE = "WS_ENGAGEMENT_MY_TOTAL_XP_CHANGE"
export const WS_ENGAGEMENT_MY_TOTAL_EVENT_XP_CHANGE = "WS_ENGAGEMENT_MY_TOTAL_EVENT_XP_CHANGE"
export const WS_ENGAGEMENT_MY_EVENT_LEVEL_CHANGE = "WS_ENGAGEMENT_MY_EVENT_LEVEL_CHANGE"
export const WS_EVENT_ACHIEVEMENT_UNLOCKED = "WS_EVENT_ACHIEVEMENT_UNLOCKED"
export const WS_ENGAGEMENT_LEADERBOARD_CHANGE = "WS_ENGAGEMENT_LEADERBOARD_CHANGE"
export const WS_OWN_POSITION_CHANGE = "WS_OWN_POSITION_CHANGE"
export const ENGAGEMENT_GET_INITIAL_TOTAL_XP = "ENGAGEMENT_GET_INITIAL_TOTAL_XP"
export const ENGAGEMENT_GET_INITIAL_TOTAL_EVENT_XP = "ENGAGEMENT_GET_INITIAL_TOTAL_EVENT_XP"
export const ENGAGEMENT_GET_CURRENT_LEADERBOARD = "ENGAGEMENT_GET_CURRENT_LEADERBOARD"
export const ENGAGEMENT_GET_ACHIEVEMENTS = "ENGAGEMENT_GET_ACHIEVEMENTS"
export const ENGAGEMENT_GET_MY_LEVEL = "ENGAGEMENT_GET_MY_LEVEL"
export const ENGAGEMENT_GET_LEVEL_THRESHOLDS = "ENGAGEMENT_GET_LEVEL_THRESHOLDS"
export const ENGAGEMENT_GET_MY_POSITION = "ENGAGEMENT_GET_MY_POSITION"
export const ENGAGEMENT_REMOVE_PERSON_FROM_LEADERBOARD = "ENGAGEMENT_REMOVE_PERSON_FROM_LEADERBOARD"
export const COMBINE_ATTENDEES = "COMBINE_ATTENDEES"
export const SET_QUOTED_MESSAGE = "SET_QUOTED_MESSAGE"
export const HANDLE_CANCEL_INVITE = "HANDLE_CANCEL_INVITE"
export const HANDLE_BELL_REF = "HANDLE_BELL_REF"
export const HANDLE_INVITE_PUSH_NOTIFICATION_REF = "HANDLE_INVITE_PUSH_NOTIFICATION_REF"
export const CANCEL_OUTGOING_INVITE = "CANCEL_OUTGOING_INVITE"
export const OUTGOING_INVITE_RESPONSE = "OUTGOING_INVITE_RESPONSE"
export const INCOMING_INVITE_RESPONSE = "INCOMING_INVITE_RESPONSE"
export const HANDLE_SIDEBAR = "HANDLE_SIDEBAR"
export const USER_GROUPS_LOADED = "USER_GROUPS_LOADED"
export const GET_ALL_ATTENDEES = "GET_ALL_ATTENDEES"
export const SET_ROOM_IN_PROGRESS = "SET_ROOM_IN_PROGRESS"
export const SET_SHOW_ADD_MODAL = "SET_SHOW_ADD_MODAL"
export const SET_ADD_MODAL_DATA = "SET_ADD_MODAL_DATA"
export const HANDLE_PREVIEW_MODAL = "HANDLE_PREVIEW_MODAL"
export const HANDLE_IMAGE_GALLERY = "HANDLE_IMAGE_GALLERY"
export const HANDLE_RIGHT_NAV = "HANDLE_RIGHT_NAV"
export const HANDLE_DEFAULT_WIDGET = "HANDLE_DEFAULT_WIDGET"
export const SET_ACTIVE_COMPONENT_REF = "SET_ACTIVE_COMPONENT_REF"
export const POPULATE_COMPONENT_REFS = "POPULATE_COMPONENT_REFS"
export const HANDLE_SETTINGS_MODAL = "HANDLE_SETTINGS_MODAL"
export const SET_OWN_HELD_MESSAGES = "SET_OWN_HELD_MESSAGES"
export const SET_ANONYMOUS_MODE = "SET_ANONYMOUS_MODE"
export const SET_SLOWMODE = "SET_SLOWMODE"
export const SET_RESTRICTED = "SET_RESTRICTED"
export const SET_GLOBAL_ANONYMOUS_MODE = "SET_GLOBAL_ANONYMOUS_MODE"
export const SET_GLOBAL_SLOWMODE = "SET_GLOBAL_SLOWMODE"
export const SET_CHATROOM_RESTRICTED = "SET_CHATROOM_RESTRICTED"
export const SET_CHANNEL_SETTINGS = "SET_CHANNEL_SETTINGS"
