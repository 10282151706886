/** @format */

import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { HOST, QUERY_LIMIT, AUTH_COOKIE } from "constants.js";
import { AUTH_HEADERS } from "util/api";

import { getCookie } from "util/cookies";

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export function useAccessKey() {
	let query = useQuery();
	return query.get("access_key") ? `?access_key=${query.get("access_key")}` : ``;
}

export async function fetchMeets(organizer, slug) {
	let meets;
	try {
		const headers = AUTH_HEADERS;
		headers["X-Organizer"] = organizer;

		const response = await fetch(`${HOST}api/adm/events/${slug}/lobby/meets/?limit=${QUERY_LIMIT}`, {
			headers: headers,
		});
		const json = await response.json();

		if (response.ok) {
			meets = json.results.map(m => ({
				slug: m.slug,
				id: m.id,
				name: m.name,
			}));
		}
	} catch (error) {}
	return meets;
}

export function useFetchMeets(organizer, slug) {
	const [meets, setMeets] = useState([]);
	useEffect(() => {
		async function fetchMeets() {
			try {
				const headers = AUTH_HEADERS;
				headers["X-Organizer"] = organizer;

				const response = await fetch(`${HOST}api/adm/events/${slug}/lobby/meets/?limit=${QUERY_LIMIT}`, {
					headers: headers,
				});
				const json = await response.json();

				if (response.ok) {
					setMeets(
						json.results.map(m => ({
							slug: m.slug,
							id: m.id,
							name: m.name,
						}))
					);
				}
			} catch (error) {}
		}
		if (organizer) {
			fetchMeets();
		}
	}, [organizer]); // eslint-disable-line react-hooks/exhaustive-deps
	return meets;
}

export async function fetchStreams(organizer, slug) {
	let streams;
	try {
		const headers = AUTH_HEADERS;
		headers["X-Organizer"] = organizer;
		const response = await fetch(`${HOST}api/adm/events/${slug}/streams/?limit=${QUERY_LIMIT}`, {
			headers: headers,
		});
		const json = await response.json();

		if (response.ok) {
			streams = json.results.map(s => ({
				slug: s.slug,
				name: s.name,
				start_time: s.start_time,
				end_time: s.end_time,
				source: s.source,
				url: s.url,
				...s,
			}));
		}
	} catch (error) {}
	return streams;
}

export async function fetchCustomStreams(organizer) {
	let streams;
	try {
		const headers = AUTH_HEADERS;
		headers["X-Organizer"] = organizer;
		const response = await fetch(`${HOST}api/adm/videos/streams/?limit=${QUERY_LIMIT}`, {
			headers: headers,
		});
		const json = await response.json();

		if (response.ok) {
			streams = json.results.map(s => ({
				source: "custom",
				slug: s.stream_id,
				name: s.name
			}));
		}
	} catch (error) {}
	return streams;
}

export function useFetchStreams(organizer, slug) {
	const [streams, setStreams] = useState([]);
	useEffect(() => {
		async function fetchStreams() {
			try {
				const headers = AUTH_HEADERS;
				headers["X-Organizer"] = organizer;
				const response = await fetch(`${HOST}api/adm/events/${slug}/streams/?limit=${QUERY_LIMIT}`, {
					headers: headers,
				});
				const json = await response.json();
				const customStreams = await fetchCustomStreams(organizer);

				if (response.ok) {
					const nonCustomStreams = json.results.map(s => ({
						slug: s.slug,
						name: s.name,
						start_time: s.start_time,
						end_time: s.end_time,
						source: s.source,
						url: s.url,
					}));

					setStreams([...nonCustomStreams, ...customStreams]);
				}
			} catch (error) {}
		}
		if (organizer) {
			fetchStreams();
		}
	}, [organizer]); // eslint-disable-line react-hooks/exhaustive-deps
	return streams;
}

export function useFetchLobby(slug, organizer) {
	const [lobby, setLobby] = useState(null);
	const [links, setLinks] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [status, setStatus] = useState(null);
	const queryString = useAccessKey();
	useEffect(() => {
		async function fetchLobby() {
			const headers = AUTH_HEADERS;
			headers["X-Organizer"] = organizer;
			try {
				const response = await fetch(`${HOST}api/v1/events/${slug}/lobby/${queryString}`, { headers: headers });
				const json = await response.json();
				setLinks(json.rooms);
				setLobby(json);
				setStatus(response.status);
			} catch (error) {
			} finally {
				setLoaded(true);
			}
		}
		if (organizer) {
			fetchLobby();
		}
	}, [organizer]); // eslint-disable-line react-hooks/exhaustive-deps

	return [lobby, links, loaded, status];
}

export function useFetchRoom(slug, room, editorDispatch, organizer) {
	useEffect(() => {
		async function fetchRoom() {
			const headers = AUTH_HEADERS;
			headers["X-Organizer"] = organizer;

			editorDispatch({ type: "EDITOR_ROOM_LOAD" });

			try {
				const response = await fetch(`${HOST}api/adm/events/${slug}/lobby/rooms/${room}/`, {
					headers: headers,
				});
				const json = await response.json();
				editorDispatch({ type: "EDITOR_ROOM_FETCHED", payload: { data: json, status: response.status } });
			} catch (error) {
			} finally {
				editorDispatch({ type: "EDITOR_ROOM_LOADED" });
			}
		}
		if (organizer) {
			fetchRoom();
		}
	}, [room, organizer]); // eslint-disable-line react-hooks/exhaustive-deps
}

// Used to determine user level on
export function useFetchUserInfo(organizer) {
	const [type, setType] = useState("guest"); // guest / organizer / superuser

	useEffect(() => {
		async function fetchUser() {
			try {
				const response = await fetch(`${HOST}api/adm/users/me/`, { headers: AUTH_HEADERS });
				const json = await response.json();
				if (response.ok) {
					if (json.is_superuser) {
						setType("superuser");
					} else if (organizer === json.organizer) {
						setType("organizer");
					} else {
						setType("guest");
					}
				}
			} catch (error) {
			} finally {
			}
		}
		if (organizer) {
			fetchUser();
		}
	}, [organizer]); // eslint-disable-line react-hooks/exhaustive-deps

	return type;
}

export function useSaveRoom(slug, room, saved, setSaved, data, editorDispatch) {
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [status, setStatus] = useState(false);
	const [show, setShow] = useState(false);
	const [detail, setDetail] = useState(null);
	useEffect(() => {
		async function saveRoom() {
			setLoading(true);
			try {
				const response = await fetch(`${HOST}api/adm/events/${slug}/lobby/rooms/${room}/`, {
					headers: AUTH_HEADERS,
					method: "PATCH",
					body: JSON.stringify(data),
				});
				const json = await response.json();
				if (response.ok) {
					setSuccess(true);
					editorDispatch({ type: "EDITOR_CHANGE_SAVED" });
				} else {
					setSuccess(false);
					setDetail(json?.detail);
				}
				setStatus(response.status);
				setShow(true);
			} catch (error) {
			} finally {
				setSaved(false);
				setLoading(false);
				setShow(false);
			}
		}
		if (saved) {
			saveRoom();
		}
	}, [saved]); // eslint-disable-line react-hooks/exhaustive-deps
	return [loading, success, show, status, detail];
}

export function useTemplates(organizer, load) {
	const [templates, setTemplates] = useState([]);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		async function fetchTemplates() {
			setLoading(true);
			const headers = AUTH_HEADERS;
			headers["X-Organizer"] = organizer;

			try {
				const response = await fetch(`${HOST}api/adm/templates/?limit=${QUERY_LIMIT}`, { headers: headers });
				const json = await response.json();
				if (response.ok) {
					setTemplates(json.results);
				}
			} catch (error) {
			} finally {
				setLoading(false);
			}
		}
		if (organizer && load) {
			fetchTemplates();
		}
	}, [organizer, load]); // eslint-disable-line react-hooks/exhaustive-deps
	return [templates, loading];
}

export function useSaveTemplate(save, organizer, data) {
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState(null);
	const [template, setTemplate] = useState(null);
	useEffect(() => {
		async function saveTemplates() {
			setLoading(true);
			const headers = AUTH_HEADERS;
			headers["X-Organizer"] = organizer;

			try {
				const response = await fetch(`${HOST}api/adm/templates/`, {
					headers: headers,
					method: "POST",
					body: JSON.stringify(data),
				});
				setStatus(response.status);
				const json = await response.json();
				setTemplate(json);
			} catch (error) {
			} finally {
				setLoading(false);
			}
		}
		if (save && data) {
			saveTemplates();
		}
	}, [save]); // eslint-disable-line react-hooks/exhaustive-deps
	return [status, loading, template];
}

export function useFetchFolders(slug, organizer, room, fetchFolders, setFetchFolders, userType) {
	const [folders, setFolders] = useState([]);
	const [status, setStatus] = useState(null);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		async function fetchFolders() {
			try {
				setLoading(true);
				const headers = AUTH_HEADERS;
				if (userType === "superuser" || userType === "guest") {
					headers["X-Organizer"] = organizer;

					if (userType === "guest") {
						headers["X-Authorized-By"] = slug + "/" + room;
					}
				}
				const response = await fetch(`${HOST}api/adm/images/folders/`, {
					headers: headers,
				});
				setStatus(response.status);
				if (response.ok) {
					const json = await response.json();
					setFolders(json);
				}
			} catch (error) {
				console.error("Error fetching folders", error);
			} finally {
				setLoading(false);
			}
		}
		setFetchFolders(false);
		fetchFolders();
	}, [fetchFolders]);
	return [folders, loading, status];
}

export function useFetchImages(slug, organizer, room, fetchImages, setFetchImages, userType, folder = "") {
	const [images, setImages] = useState([]);
	const [status, setStatus] = useState(null);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		async function fetchImages() {
			try {
				setLoading(true);
				const headers = AUTH_HEADERS;
				if (userType === "superuser" || userType === "guest") {
					headers["X-Organizer"] = organizer;

					if (userType === "guest") {
						headers["X-Authorized-By"] = slug + "/" + room;
					}
				}

				const response = await fetch(`${HOST}api/adm/images/?limit=3000${folder}&ordering=-id`, {
					headers: headers,
				});
				const json = await response.json();
				setStatus(response.status);
				if (response.ok) {
					let imgs = [];
					for (let img of json.results) {
						if (img.placement === "other") {
							imgs.push(img);
						}
					}
					setImages(imgs);
				}
			} catch (error) {
				console.error("Error fetching images", error);
			} finally {
				setLoading(false);
			}
		}
		if (fetchImages) {
			setFetchImages(false);
			fetchImages();
		}
	}, [fetchImages]);
	return [images, loading, status];
}

export function usePostImage(
	slug,
	organizer,
	room,
	image,
	setFetchImages,
	setFetchFolders,
	postImage,
	setPostImage,
	setUploadImages,
	userType,
	folder,
	resetState
) {
	const [showMessage, setShowMessage] = useState(false);
	const [uploadMessage, setUploadMessage] = useState(null);
	const [uploadLoading, setUploadLoading] = useState(false);

	useEffect(() => {
		async function fetchPostImage() {
			const formData = new FormData();
			formData.append("folder", folder);
			formData.append("placement", "other");
			formData.append("image", image[0], image[0].name);
			try {
				setUploadLoading(true);
				const headers = {
					Authorization: `Token ${getCookie(AUTH_COOKIE)}`,
				};
				if (userType === "superuser" || userType === "guest") {
					headers["X-Organizer"] = organizer;

					if (userType === "guest") {
						headers["X-Authorized-By"] = slug + "/" + room;
					}
				}

				const response = await fetch(`${HOST}api/adm/images/`, {
					headers: headers,
					method: "POST",
					body: formData,
				});

				switch (response.status) {
					case 201:
						setUploadMessage("Success");
						break;
					default:
						setUploadMessage("Upload failed");
						break;
				}

				if (response.ok) {
					setPostImage(false);
					resetState();
					setFetchImages(true);
					setFetchFolders(true);
					setUploadImages([]);
				}
				setShowMessage(true);
			} catch (error) {
			} finally {
				setUploadLoading(false);
				setTimeout(() => {
					setShowMessage(false);
				}, 1000);
			}
		}

		if (postImage) {
			fetchPostImage();
		}
	}, [postImage]);
	return [showMessage, uploadMessage, uploadLoading];
}

export const patchImage = async (id, folder) => {
	let ok;
	let error;
	let res;
	let status;
	try {
		const response = await fetch(`${HOST}api/adm/images/${id}/`, {
			headers: AUTH_HEADERS,
			method: "PATCH",
			body: JSON.stringify({ folder }),
		});
		status = response.status;
		res = await response.json();
		ok = response.ok;
	} catch (err) {
		error = err;
	}
	return [ok, error, res, status];
};
