import { setHours, setMinutes } from "date-fns";
import moment from "moment";
import notificationSound from 'static/sound/notification.mp3'

export const disableButton = (start, end, slots = []) => {
    if (!start) return true

    const startDate = moment(start)
    const endDate = moment(end)
    let isDisabled = false

    slots.forEach(slot => {
        const slotStartTime = moment(slot.start_time)
        const slotEndTime = moment(slot.end_time)
        if (startDate.isBetween(slotStartTime, slotEndTime, undefined, '[]')) { isDisabled = true }
        else if (endDate.isBetween(slotStartTime, slotEndTime, undefined, '[]')) { isDisabled = true }
        else if (slotStartTime.isBetween(startDate, endDate, undefined, '[]')) { isDisabled = true }
        else if (slotEndTime.isBetween(startDate, endDate, undefined, '[]')) { isDisabled = true }
        else isDisabled = false
    })

    return isDisabled
}

export const defineDefaultEndTime = (from, to) => {
    if (!from && !to) return null
    else if (!to) return new Date(moment(from).add(1, 'hour').format('YYYY-MM-DDTHH:mm:ss'))
    else return to
}

export const getMinTime = (fromDateTime) => {
    if (moment(fromDateTime).isSame(moment(), 'day') || !fromDateTime) {
        return {
            min: setHours(setMinutes(new Date(), moment().get('minutes')), moment().get('hours')),
            max: setHours(setMinutes(new Date(), 30), 23)
        }
    }
    else return { min: null, max: null }
}

function unlockAudio() {
    const sound = new Audio(notificationSound);

    sound.play();
    sound.pause();
    sound.currentTime = 0;

    document.body.removeEventListener('click', unlockAudio)
    document.body.removeEventListener('touchstart', unlockAudio)
}

export const unlockAutoPlay = () => {
    document.body.addEventListener('click', unlockAudio);
    document.body.addEventListener('touchstart', unlockAudio);
}