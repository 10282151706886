/** @format */

import React from "react";
import { Icon } from "react-icons-kit";
import { paragraphLeft } from "react-icons-kit/icomoon/paragraphLeft";
import { paragraphRight } from "react-icons-kit/icomoon/paragraphRight";
import { paragraphCenter } from "react-icons-kit/icomoon/paragraphCenter";
import { smallUp } from "react-icons-kit/entypo/smallUp";
import { smallDown } from "react-icons-kit/entypo/smallDown";
import { ic_delete_forever } from "react-icons-kit/md/ic_delete_forever";
import { signOut } from "react-icons-kit/fa/signOut";
import { code } from "react-icons-kit/fa/code";
import { calendar } from "react-icons-kit/fa/calendar";
import { ic_group } from "react-icons-kit/md/ic_group";
import { times } from "react-icons-kit/fa/times";
import { angleDoubleRight } from "react-icons-kit/fa/angleDoubleRight";
import { angleDoubleLeft } from "react-icons-kit/fa/angleDoubleLeft";
import { iosArrowThinRight } from "react-icons-kit/ionicons/iosArrowThinRight";
import { iosArrowThinLeft } from "react-icons-kit/ionicons/iosArrowThinLeft";
import { iosArrowThinDown } from "react-icons-kit/ionicons/iosArrowThinDown";
import { iosArrowThinUp } from "react-icons-kit/ionicons/iosArrowThinUp";
import { chevronUp } from "react-icons-kit/fa/chevronUp";
import { chevronDown } from "react-icons-kit/fa/chevronDown";
import { chevronLeft } from "react-icons-kit/fa/chevronLeft";
import { chevronRight } from "react-icons-kit/fa/chevronRight";
import { iosPhotosOutline } from "react-icons-kit/ionicons/iosPhotosOutline";
import { iosPhotos } from "react-icons-kit/ionicons/iosPhotos";
import { mobile } from "react-icons-kit/fa/mobile";
import { desktop } from "react-icons-kit/fa/desktop";
import { tablet } from "react-icons-kit/fa/tablet";
import { edit } from "react-icons-kit/fa/edit";
import { helpCircle } from "react-icons-kit/feather/helpCircle";
import { font } from "react-icons-kit/fa/font";
import { image } from "react-icons-kit/fa/image";
import { plus } from "react-icons-kit/iconic/plus";
import { warning } from "react-icons-kit/ikons/warning";
import { users } from "react-icons-kit/fa/users";
import { archive } from "react-icons-kit/fa/archive";
import { send } from "react-icons-kit/fa/send";
import { ic_send } from "react-icons-kit/md/ic_send";
import { envelopeO } from "react-icons-kit/fa/envelopeO";
import { envelope } from "react-icons-kit/fa/envelope";
import { linkedinSquare } from "react-icons-kit/fa/linkedinSquare";
import { twitter } from "react-icons-kit/fa/twitter";
import { home } from "react-icons-kit/fa/home";
import { eye } from "react-icons-kit/fa/eye";
import { save } from "react-icons-kit/fa/save";
import { arrowMove } from "react-icons-kit/typicons/arrowMove";
import { link } from "react-icons-kit/icomoon/link";
import { software_layout_header_complex as room } from "react-icons-kit/linea/software_layout_header_complex";
import { screen_expand_3 as componentIcon, screen_expand_3 } from "react-icons-kit/ikons/screen_expand_3";
import { wrench } from "react-icons-kit/fa/wrench";
import { sitemap } from "react-icons-kit/fa/sitemap";
import { pencil } from "react-icons-kit/fa/pencil";
import { ic_view_carousel } from "react-icons-kit/md/ic_view_carousel";
import { square_dashed } from "react-icons-kit/ikons/square_dashed";
import { arrows_horizontal } from "react-icons-kit/ikons/arrows_horizontal";
import { arrows_vertical } from "react-icons-kit/ikons/arrows_vertical";
import { arrowsV } from "react-icons-kit/fa/arrowsV";
import { arrows_expand_horizontal1 } from "react-icons-kit/linea/arrows_expand_horizontal1";
import { ic_filter_none } from "react-icons-kit/md/ic_filter_none";
import { handOUp } from "react-icons-kit/fa/handOUp";
import { software_vector_line } from "react-icons-kit/linea/software_vector_line";
import { repeat } from "react-icons-kit/fa/repeat";
import { lock } from "react-icons-kit/fa/lock";
import { video } from "react-icons-kit/feather/video";
import { idBadge } from "react-icons-kit/fa/idBadge";
import { pencilSquareO } from "react-icons-kit/fa/pencilSquareO";
import { barChart } from "react-icons-kit/fa/barChart";
import { cog } from "react-icons-kit/fa/cog";
import { list_2 } from "react-icons-kit/ikons/list_2";
import { list } from "react-icons-kit/fa/list";
import { polaroids } from "react-icons-kit/ikons/polaroids";
import { speech_bubbles } from "react-icons-kit/ikons/speech_bubbles";
import { shuffle } from "react-icons-kit/ikons/shuffle";
import { tree } from "react-icons-kit/entypo/tree";
import { profile } from "react-icons-kit/icomoon/profile";
import { table2 } from "react-icons-kit/icomoon/table2";
import { clockO } from "react-icons-kit/fa/clockO";
import { user } from "react-icons-kit/fa/user";
import { checkCircle } from "react-icons-kit/fa/checkCircle";
import { timesCircle } from "react-icons-kit/fa/timesCircle";
import { microphone } from "react-icons-kit/fa/microphone";
import { microphoneSlash } from "react-icons-kit/fa/microphoneSlash";
import { videoOff } from "react-icons-kit/feather/videoOff";
import { phoneCall } from "react-icons-kit/feather/phoneCall";
import { share } from "react-icons-kit/feather/share";
import { phoneHangUp } from "react-icons-kit/icomoon/phoneHangUp";
import { phone } from "react-icons-kit/icomoon/phone";
import { phoneOff } from "react-icons-kit/feather/phoneOff";
import { phoneForwarded } from "react-icons-kit/feather/phoneForwarded";
import { userCircle } from "react-icons-kit/fa/userCircle";
import { facebookSquare } from "react-icons-kit/fa/facebookSquare";
import { comment } from "react-icons-kit/fa/comment";
import { commentO } from "react-icons-kit/fa/commentO";
import { bellO } from "react-icons-kit/fa/bellO";
import { starO } from "react-icons-kit/fa/starO";
import { star } from "react-icons-kit/fa/star";
import { compress } from "react-icons-kit/fa/compress";
import { trashO } from "react-icons-kit/fa/trashO";
import { bell } from "react-icons-kit/fa/bell";
import { ban } from "react-icons-kit/fa/ban";
import { copy } from "react-icons-kit/fa/copy";
import { arrowLeft } from "react-icons-kit/fa/arrowLeft";
import { arrowRight } from "react-icons-kit/fa/arrowRight";
import { calendar as personalCalendar } from "react-icons-kit/iconic/calendar";
import { calendar as selectDate } from "react-icons-kit/entypo/calendar";
import { calendarCheckO } from "react-icons-kit/fa/calendarCheckO";
import { ic_close } from "react-icons-kit/md/ic_close";
import { columns } from "react-icons-kit/ikons/columns";
import { lightbulb } from "react-icons-kit/typicons/lightbulb";
import { trophy } from "react-icons-kit/fa/trophy";
import { ic_more_horiz } from "react-icons-kit/md/ic_more_horiz";
import { check } from "react-icons-kit/fa/check";
import { reply } from "react-icons-kit/fa/reply";
import { alertCircled } from "react-icons-kit/ionicons/alertCircled";
import { bubble } from "react-icons-kit/icomoon/bubble";
import { folder } from "react-icons-kit/fa/folder";
import { download } from "react-icons-kit/fa/download";
import { infoCircle } from "react-icons-kit/fa/infoCircle";
import { bars } from "react-icons-kit/fa/bars";

export const IconBars = props => <Icon icon={bars} {...props} />;
export const IconInfoCircle = props => <Icon icon={infoCircle} {...props} />;
export const IconDownload = props => <Icon icon={download} {...props} />;
export const IconLightbulb = props => <Icon icon={lightbulb} {...props} />;
export const IconCheck = props => <Icon icon={check} {...props} />;
export const IconCalendarCheck = props => <Icon icon={calendarCheckO} {...props} />;
export const IconCopy = props => <Icon icon={copy} {...props} />;
export const IconBellFilled = props => <Icon icon={bell} {...props} />;
export const IconTrash = props => <Icon icon={trashO} {...props} />;
export const IconCompress = props => <Icon icon={compress} {...props} />;
export const IconStar = props => <Icon icon={star} {...props} />;
export const IconStarEmpty = props => <Icon icon={starO} {...props} />;
export const IconAlert = props => <Icon icon={alertCircled} {...props} />;

export const IconBan = props => <Icon icon={ban} {...props} />;
export const IconSignout = props => <Icon icon={signOut} {...props} />;
export const IconFacebook = props => <Icon icon={facebookSquare} {...props} />;
export const IconUserCircle = props => <Icon icon={userCircle} {...props} />;

export const IconCalling = props => <Icon icon={phoneForwarded} {...props} />;

export const IconAcceptCall = props => <Icon icon={phone} {...props} />;
export const IconRejectCall = props => <Icon icon={phoneOff} {...props} />;

export const IconHangup = props => <Icon icon={phoneHangUp} {...props} />;

export const IconShareScreen = props => <Icon icon={share} {...props} />;
export const IconStartPhoneCall = props => <Icon icon={phoneCall} {...props} />;
export const IconVideoOn = props => <Icon icon={video} {...props} />;
export const IconVideoOff = props => <Icon icon={videoOff} {...props} />;
export const IconMicrophoneOn = props => <Icon icon={microphone} {...props} />;
export const IconMicrophoneMuted = props => <Icon icon={microphoneSlash} {...props} />;

export const IconClock = props => <Icon icon={clockO} {...props} />;
export const IconTrophy = props => <Icon icon={trophy} {...props} />;
export const IconSettings = props => <Icon icon={cog} {...props} />;
export const IconBarChart = props => <Icon icon={barChart} {...props} />;
export const IconPencilSquare = props => <Icon icon={pencilSquareO} {...props} />;
export const IconUserProfile = props => <Icon icon={idBadge} {...props} />;
export const IconPencil = props => <Icon icon={pencil} {...props} />;
export const IconVideo = props => <Icon icon={video} {...props} />;
export const IconLock = props => <Icon icon={lock} {...props} />;
export const IconEnvelopeNotification = props => <Icon icon={envelope} {...props} />;

export const IconRepeat = props => <Icon icon={repeat} {...props} />;
export const IconShadow = props => <Icon icon={ic_filter_none} {...props} />;
export const IconPadding = props => <Icon icon={arrows_expand_horizontal1} {...props} />;
export const IconArrowsV = props => <Icon icon={arrowsV} {...props} />;

export const IconLink = props => <Icon icon={link} {...props} />;
export const IconDrag = props => <Icon icon={arrowMove} {...props} />;

export const IconAlignItems = props => <Icon icon={arrows_vertical} {...props} />;
export const IconFlexDirection = props => <Icon icon={arrows_horizontal} {...props} />;
export const IconBorder = props => <Icon icon={square_dashed} {...props} />;

export const IconSave = props => <Icon icon={save} {...props} />;
export const IconEye = props => <Icon icon={eye} {...props} />;
export const IconWebsite = props => <Icon icon={home} {...props} />;
export const IconTwitter = props => <Icon icon={twitter} {...props} />;
export const IconLinkedIn = props => <Icon icon={linkedinSquare} {...props} />;

export const IconEnvelope = props => <Icon icon={envelopeO} {...props} />;

export const IconSend = props => <Icon icon={send} {...props} />;
export const IconSendIC = props => <Icon icon={ic_send} {...props} />;

export const IconArchive = props => <Icon icon={archive} {...props} />;
export const IconComponentTree = props => <Icon icon={screen_expand_3} {...props} />;
export const IconUsers = props => <Icon icon={users} {...props} />;

export const IconMobile = props => <Icon icon={mobile} {...props} />;
export const IconTablet = props => <Icon icon={tablet} {...props} />;
export const IconDesktop = props => <Icon icon={desktop} {...props} />;

export const IconGridLock = props => <Icon icon={iosPhotosOutline} {...props} />;
export const IconGridUnlock = props => <Icon icon={iosPhotos} {...props} />;

export const IconChevronUp = props => <Icon icon={chevronUp} {...props} />;
export const IconChevronDown = props => <Icon icon={chevronDown} {...props} />;
export const IconChevronLeft = props => <Icon icon={chevronLeft} {...props} />;
export const IconChevronRight = props => <Icon icon={chevronRight} {...props} />;
export const IconArrowUp = props => <Icon icon={iosArrowThinUp} {...props} />;
export const IconArrowDown = props => <Icon icon={iosArrowThinDown} {...props} />;
export const IconArrowLeft = props => <Icon icon={iosArrowThinLeft} {...props} />;
export const IconArrowLeftBack = props => <Icon icon={arrowLeft} {...props} />;
export const IconArrowForward = props => <Icon icon={arrowRight} {...props} />;
export const IconArrowRight = props => <Icon icon={iosArrowThinRight} {...props} />;
export const IconArrowsRight = props => <Icon icon={angleDoubleRight} {...props} />;
export const IconArrowsLeft = props => <Icon icon={angleDoubleLeft} {...props} />;
export const IconTimes = props => <Icon icon={times} {...props} />;
export const IconPersons = props => <Icon icon={ic_group} {...props} />;
export const IconCalendar = props => <Icon icon={calendar} {...props} />;
export const IconCode = props => <Icon icon={code} {...props} />;
export const IconAlignLeft = props => <Icon icon={paragraphLeft} {...props} />;
export const IconAlignRight = props => <Icon icon={paragraphRight} {...props} />;
export const IconAlignCenter = props => <Icon icon={paragraphCenter} {...props} />;
export const IconMoveUp = props => <Icon icon={smallUp} {...props} />;
export const IconMoveDown = props => <Icon icon={smallDown} {...props} />;
export const IconDelete = props => <Icon icon={ic_delete_forever} {...props} />;
export const IconHelp = props => <Icon icon={helpCircle} {...props} />;
export const IconEdit = props => <Icon icon={edit} {...props} />;
export const IconRoom = props => <Icon icon={room} {...props} />;
export const IconWarning = props => <Icon icon={warning} {...props} />;
export const IconProperties = props => <Icon icon={wrench} {...props} />;
export const IconActions = props => <Icon icon={sitemap} {...props} />;
export const IconContent = props => <Icon icon={pencil} {...props} />;
export const IconCarousel = props => <Icon icon={ic_view_carousel} {...props} />;
export const IconComment = props => <Icon icon={comment} {...props} />;
export const IconComment0 = props => <Icon icon={commentO} {...props} />;
export const IconClose = props => <Icon icon={ic_close} {...props} />;
export const IconMore = props => <Icon icon={ic_more_horiz} {...props} />;
export const IconReply = props => <Icon icon={reply} {...props} />;
export const IconChatBubble = props => <Icon icon={bubble} {...props} />;
export const IconFolder = props => <Icon icon={folder} {...props} />;

// Component category icons
export const IconList = props => <Icon icon={list_2} {...props} />;
export const IconCard = props => <Icon icon={polaroids} {...props} />;
export const IconSocial = props => <Icon icon={speech_bubbles} {...props} />;
export const IconIntegrated = props => <Icon icon={tree} {...props} />;
export const IconConditional = props => <Icon icon={shuffle} {...props} />;
export const IconGallery = props => <Icon icon={table2} {...props} />;
export const IconModal = props => <Icon icon={profile} {...props} />;

// Element type icons
export const IconText = props => <Icon icon={font} {...props} />;
export const IconImage = props => <Icon icon={image} {...props} />;
export const IconEmbed = props => <Icon icon={code} {...props} />;
export const IconButton = props => <Icon icon={handOUp} {...props} />;
export const IconSeparator = props => <Icon icon={software_vector_line} {...props} />;

export const IconNewItem = props => <Icon icon={plus} {...props} />;

export const IconComponent = props => <Icon icon={componentIcon} {...props} />;
//export const IconClock = (props) => (<Icon icon={clockO} {...props} /> );
export const IconUser = props => <Icon icon={user} {...props} />;
export const IconAccept = props => <Icon icon={checkCircle} {...props} />;
export const IconDecline = props => <Icon icon={timesCircle} {...props} />;
export const IconBell = props => <Icon icon={bellO} {...props} />;
export const IconPersonalCalendar = props => <Icon icon={personalCalendar} {...props} />;
export const IconSelectDate = props => <Icon icon={selectDate} {...props} />;
export const IconList2 = props => <Icon icon={list} {...props} />;
export const iconElement = renderType => {
	switch (renderType) {
		case "text":
			return <Icon size="12" className="item-type-icon" icon={font} />;
		case "embed":
			return <Icon size="12" className="item-type-icon" icon={code} />;
		case "image":
			return <Icon size="12" className="item-type-icon" icon={image} />;
		case "button":
			return <Icon size="12" className="item-type-icon" icon={handOUp} />;
		case "separator":
			return <Icon size="12" className="item-type-icon" icon={software_vector_line} />;
		case "poll":
			return <Icon size="12" className="item-type-icon" icon={barChart} />;
		default:
			return <Icon size="12" className="item-type-icon" icon={font} />;
	}
};

export const IconColumns = props => <Icon icon={columns} {...props} />;

export const ChatIcon = props => {
	const color = props.color ? props.color : "#fff";
	const size = props.size ? props.size : 40;
	return (
		<svg width={size} height={size} viewBox="0 0 112 112" fill={"none"} xmlns="http://www.w3.org/2000/svg">
			<path
				d="M88.9131 24.9637C84.5308 21.2839 79.4369 18.5501 73.9467 16.9364C70.5833 15.9113 67.1123 15.2879 63.6005 15.0823C62.7019 15 61.7875 15 60.8794 15C59.9713 15 59.2594 15 58.4494 15.0728C56.1997 15.1898 53.9595 15.4335 51.7352 15.8005C47.0239 16.6548 42.4898 18.3001 38.3288 20.67C32.7315 23.7044 28.0199 28.1436 24.6567 33.5474C20.2618 40.4546 18.9044 48.8646 20.8978 56.8035C22.5147 63.4005 26.166 69.3172 31.3396 73.7155C37.8101 79.3855 45.8532 82.9455 54.3996 83.9232C55.4216 83.9801 56.374 84.4548 57.0289 85.2363C57.6871 86.021 57.9908 87.0398 57.8674 88.0555C57.8674 89.3844 57.5669 90.7038 57.4055 92.0232C57.1555 93.0041 57.1017 94.0261 57.2441 95.0291C57.2853 95.5511 57.7061 95.9656 58.2314 96.0004H58.4117C59.2438 95.8928 60.0602 95.6808 60.8417 95.3676C64.2937 93.7665 67.5717 91.808 70.6152 89.5203C77.3832 84.7395 83.7713 79.4455 89.7232 73.684C91.7988 71.7823 93.6751 69.6751 95.33 67.4001C98.9718 62.2427 100.953 56.0949 101 49.7825C101.003 46.9792 100.62 44.1917 99.8577 41.4962C97.9625 35.0067 94.1497 29.2417 88.913 24.964L88.9131 24.9637ZM91.2862 64.6539H91.2893C89.8244 66.6631 88.1664 68.5235 86.3375 70.21C80.5536 75.782 74.3552 80.9078 67.7901 85.5337C66.0815 86.7392 64.3887 87.9162 62.6643 88.9445V88.2073C62.9775 83.5973 59.5762 79.5694 54.9756 79.111C47.4135 78.2567 40.2975 75.1021 34.5797 70.081C30.1373 66.3474 27.0017 61.2974 25.6219 55.6622C23.9132 48.9702 25.0682 41.867 28.8113 36.0609C31.738 31.3559 35.8418 27.5024 40.7175 24.8756C44.41 22.7873 48.4283 21.3381 52.6019 20.5882C54.6458 20.2528 56.7057 20.0313 58.775 19.9238C59.4458 19.8605 60.1513 19.8605 60.8791 19.8605C61.6069 19.8605 62.4991 19.8605 63.3091 19.9333C66.4321 20.1073 69.5234 20.6515 72.5197 21.5533C77.3797 22.9708 81.8882 25.385 85.7649 28.6408C90.2768 32.3048 93.5643 37.26 95.1844 42.841C95.8204 45.097 96.14 47.4289 96.14 49.7735C96.0672 55.1049 94.3712 60.2873 91.2799 64.6289L91.2862 64.6539Z"
				fill={props.color ? props.color : "#fff"}
			/>
			<path
				d="M60.5017 46.7925C58.6159 46.7893 56.9168 47.922 56.1922 49.6623C55.4708 51.4025 55.8663 53.4054 57.1984 54.7406C58.5273 56.0727 60.5333 56.4745 62.2736 55.7531C64.0169 55.0349 65.1497 53.3358 65.1497 51.45C65.1307 48.8902 63.0615 46.8178 60.5017 46.7925V46.7925Z"
				fill={color}
			/>
			<path
				d="M44.1152 46.7927C42.2326 46.7673 40.5176 47.8779 39.7709 49.6087C39.0273 51.3395 39.4007 53.3487 40.7138 54.6998C42.0301 56.0508 44.0298 56.4748 45.7795 55.7755C47.5293 55.0763 48.6841 53.3898 48.7094 51.5072C48.7284 50.2732 48.2538 49.0804 47.3932 48.1945C46.5294 47.3117 45.3524 46.8055 44.1152 46.7927V46.7927Z"
				fill={color}
			/>
			<path
				d="M76.9674 46.7927C75.0785 46.7673 73.3604 47.8843 72.6199 49.6214C71.8796 51.3616 72.2624 53.3739 73.5881 54.7187C74.9139 56.0666 76.9231 56.4748 78.6696 55.7597C80.4194 55.0415 81.5616 53.3392 81.5616 51.4503C81.5711 48.9 79.5208 46.818 76.9673 46.7927L76.9674 46.7927Z"
				fill={color}
			/>
		</svg>
	);
};

export const StreamIcon = props => {
	const color = props.color ? props.color : "#fff";
	const size = props.size ? props.size : 40;
	return (
		<svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M22.5671 19.7278C22.7042 19.8161 22.7042 20.0166 22.5671 20.105L18.8318 22.5104C18.6825 22.6065 18.4861 22.4993 18.4861 22.3218V17.511C18.4861 17.3335 18.6825 17.2263 18.8318 17.3225L22.5671 19.7278Z"
				fill={color}
			/>
			<circle cx="20" cy="20" r="7.5" stroke={color} />
			<path
				d="M30.316 27C31.3918 24.9896 32 22.704 32 20.28C32 17.628 31.272 15.1416 30 13"
				stroke={color}
				stroke-linecap="round"
			/>
			<path
				d="M9.68405 13C8.60819 15.0104 8 17.296 8 19.72C8 22.372 8.72801 24.8584 10 27"
				stroke={color}
				stroke-linecap="round"
			/>
		</svg>
	);
};

export const ShieldIcon = props => {
	const color = props.color ? props.color : "#fff";
	const size = props.size ? props.size : 40;
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
			<path fill={color} d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12c5.16-1.26 9-6.45 9-12V5l-9-4Z" />
		</svg>
	);
};

export const IncognitoIcon = props => {
	const color = props.color ? props.color : "#fff";
	const size = props.size ? props.size : 40;
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
			<path
				fill={color}
				d="M17.06 13c-1.86 0-3.42 1.33-3.82 3.1c-.95-.41-1.82-.3-2.48-.01C10.35 14.31 8.79 13 6.94 13C4.77 13 3 14.79 3 17s1.77 4 3.94 4c2.06 0 3.74-1.62 3.9-3.68c.34-.24 1.23-.69 2.32.02c.18 2.05 1.84 3.66 3.9 3.66c2.17 0 3.94-1.79 3.94-4s-1.77-4-3.94-4M6.94 19.86c-1.56 0-2.81-1.28-2.81-2.86s1.26-2.86 2.81-2.86c1.56 0 2.81 1.28 2.81 2.86s-1.25 2.86-2.81 2.86m10.12 0c-1.56 0-2.81-1.28-2.81-2.86s1.25-2.86 2.81-2.86s2.82 1.28 2.82 2.86s-1.27 2.86-2.82 2.86M22 10.5H2V12h20v-1.5m-6.47-7.87c-.22-.49-.78-.75-1.31-.58L12 2.79l-2.23-.74l-.05-.01c-.53-.15-1.09.13-1.29.64L6 9h12l-2.44-6.32l-.03-.05Z"
			/>
		</svg>
	);
};

export const CloseIcon = props => {
	const color = props.color ? props.color : "#fff";
	const size = props.size ? props.size : 40;
	return (
		<svg width={size} height={size} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18.8113 19.9032L38.434 36.1295"
				stroke={color}
				stroke-width="4"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M18.8113 36.1818L38.0415 19.8182"
				stroke={color}
				stroke-width="4"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export const ShoppingCartIcon = props => {
	const color = props.color ? props.color : "#fff";
	const size = props.size ? props.size : 40;
	return (
		<svg width={size} height={size} viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M92.4743 37.3358C91.536 36.1527 90.1489 35.5 88.6395 35.5H39.1746L36.4209 21.3867C36.1965 20.224 35.2785 19.3265 34.1159 19.1225L22.3458 17.0419C20.7955 16.7767 19.3064 17.7966 19.0413 19.3673C18.7761 20.9176 19.8164 22.4066 21.3667 22.6718L31.1986 24.3852L39.7045 66.9562C40.1533 69.2407 42.1727 70.9338 44.498 70.9338H82.6627C84.9473 70.9338 86.9258 69.3223 87.4358 67.0989L93.4125 41.4768C93.7592 40.0082 93.4126 38.5189 92.4743 37.3358ZM82.6627 65.2222H44.5592L39.7045 40.9487H88.2518L82.6627 65.2222Z"
				fill={color}
			/>
			<circle cx="78.5" cy="85.5" r="7" stroke={color} strokeWidth="5" />
			<circle cx="47.5" cy="85.5" r="7" stroke={color} strokeWidth="5" />
		</svg>
	);
};

export const ThreeDotsIcon = props => {
	const color = props.color ? props.color : "#fff";
	const size = props.size ? props.size : 40;
	return (
		<svg width={size} height={size} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M27.8621 20.7241C29.995 20.7241 31.7241 18.995 31.7241 16.8621C31.7241 14.7291 29.995 13 27.8621 13C25.7291 13 24 14.7291 24 16.8621C24 18.995 25.7291 20.7241 27.8621 20.7241Z"
				fill={color}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M27.8621 32.3103C29.995 32.3103 31.7241 30.5812 31.7241 28.4483C31.7241 26.3153 29.995 24.5862 27.8621 24.5862C25.7291 24.5862 24 26.3153 24 28.4483C24 30.5812 25.7291 32.3103 27.8621 32.3103Z"
				fill={color}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M27.8621 43.8965C29.995 43.8965 31.7241 42.1674 31.7241 40.0345C31.7241 37.9015 29.995 36.1724 27.8621 36.1724C25.7291 36.1724 24 37.9015 24 40.0345C24 42.1674 25.7291 43.8965 27.8621 43.8965Z"
				fill={color}
			/>
		</svg>
	);
};

export const EditorBorderIcon = () => {
	return (
		<svg width="21" height="19" viewBox="0 0 21 19" xmlns="http://www.w3.org/2000/svg">
			<path
				opacity="0.7"
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M3.50418 4.6449V1.97083H6.19092V3.47083H5.00418V4.6449H3.50418ZM13.7138 1.97083H8.34031V3.47083H13.7138V1.97083ZM15.8632 1.97083V3.47083H17.0499V4.6449H18.5499V1.97083H15.8632ZM18.5499 6.78416H17.0499V12.1323H18.5499V6.78416ZM18.5499 14.2716H17.0499V15.4457H15.8632V16.9457H18.5499V14.2716ZM13.7138 16.9457V15.4457H8.34031V16.9457H13.7138ZM6.19092 16.9457V15.4457H5.00418V14.2716H3.50418V16.9457H6.19092ZM3.50418 12.1323H5.00418V6.78416H3.50418V12.1323Z"
			/>
		</svg>
	);
};

export const EditorPaddingIcon = () => {
	return (
		<svg width="21" height="19" viewBox="0 0 21 19" xmlns="http://www.w3.org/2000/svg">
			<g opacity="0.7">
				<path d="M20.4991 9.51482C20.4969 9.57939 20.483 9.64301 20.4576 9.70375C20.4437 9.72527 20.4282 9.74584 20.4105 9.76497C20.395 9.79319 20.3772 9.82045 20.3573 9.84628L17.5793 12.7897C17.3671 13.004 16.9938 13.0288 16.7461 12.8457C16.498 12.6625 16.4686 12.3406 16.6808 12.1263L18.6256 10.0252H13.2639C13.217 9.97829 13.217 9.79655 13.217 9.51484C13.217 9.23313 13.217 9.05138 13.2639 9.00451H18.6256L16.6808 6.90334C16.4686 6.68907 16.4979 6.36719 16.7461 6.18401C16.9938 6.00082 17.3671 6.02569 17.5793 6.23997L20.3573 9.18339C20.3772 9.20922 20.395 9.23648 20.4105 9.2647C20.4282 9.28383 20.4437 9.3044 20.4576 9.32592C20.483 9.38666 20.4969 9.45025 20.4991 9.51482Z" />
				<path d="M0.499126 9.51484C0.501342 9.45027 0.515192 9.38666 0.540675 9.32591C0.554524 9.30439 0.570035 9.28382 0.587762 9.26469C0.603274 9.23648 0.621 9.20921 0.640942 9.18339L3.41893 6.23996C3.6311 6.02569 4.00447 6.00082 4.2521 6.184C4.50028 6.36718 4.52963 6.68906 4.31747 6.90333L2.37266 9.0045H7.71835C7.76523 9.05138 7.76523 9.23312 7.76523 9.51483C7.76523 9.79654 7.76523 9.97828 7.71835 10.0252H2.37266L4.31747 12.1263C4.52964 12.3406 4.50028 12.6625 4.2521 12.8457C4.00447 13.0288 3.6311 13.004 3.41893 12.7897L0.640942 9.84627C0.621 9.82044 0.603274 9.79318 0.587762 9.76496C0.570035 9.74583 0.554524 9.72526 0.540675 9.70374C0.515192 9.643 0.501343 9.57941 0.499126 9.51484Z" />
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M8.19031 1.00134C8.60452 1.00134 8.94031 1.33713 8.94031 1.75134V18.3522C8.94031 18.7664 8.60452 19.1022 8.19031 19.1022C7.77609 19.1022 7.44031 18.7664 7.44031 18.3522V1.75134C7.44031 1.33713 7.77609 1.00134 8.19031 1.00134Z"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M12.6288 1.00134C13.043 1.00134 13.3788 1.33713 13.3788 1.75134L13.3788 18.3522C13.3788 18.7664 13.043 19.1022 12.6288 19.1022C12.2146 19.1022 11.8788 18.7664 11.8788 18.3522L11.8788 1.75134C11.8788 1.33713 12.2146 1.00134 12.6288 1.00134Z"
				/>
			</g>
		</svg>
	);
};

export const EditorTextIcon = () => {
	return (
		<svg width="21" height="19" viewBox="0 0 21 19" xmlns="http://www.w3.org/2000/svg">
			<g opacity="0.7">
				<path d="M5.05534 10.6226H8.17852L8.97173 14.0023H10.7325L8.15777 3.01831H5.07476L2.5 14.0023H4.26216L5.05534 10.6226ZM6.79124 4.70815L7.78158 8.93276H5.44954L6.43987 4.70815H6.79124Z" />
				<path d="M17.5985 7.24292V8.40864C16.9695 7.6997 16.0543 7.24292 15.0237 7.24292C13.1275 7.24292 11.5907 8.75585 11.5907 10.6226C11.5907 12.4894 13.1275 14.0023 15.0237 14.0023C16.0543 14.0023 16.9695 13.5455 17.5985 12.8366V14.0023H18.4567V7.24292H17.5985ZM15.0237 12.3124C14.0763 12.3124 13.3072 11.5553 13.3072 10.6226C13.3072 9.6899 14.0763 8.93276 15.0237 8.93276C15.9705 8.93276 16.7402 9.6899 16.7402 10.6226C16.7402 11.5553 15.9705 12.3124 15.0237 12.3124Z" />
			</g>
		</svg>
	);
};

export const EditorAlignmentIcon = () => {
	return (
		<svg width="21" height="20" viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg">
			<g opacity="0.7">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M1.44867 10.25C1.44867 9.83579 1.78446 9.5 2.19867 9.5L18.7995 9.5C19.2138 9.5 19.5495 9.83579 19.5495 10.25C19.5495 10.6642 19.2138 11 18.7995 11L2.19867 11C1.78446 11 1.44867 10.6642 1.44867 10.25Z"
				/>
				<path d="M10.0457 8.07483C9.98112 8.07262 9.91751 8.05877 9.85677 8.03328C9.83524 8.01943 9.81468 8.00392 9.79555 7.9862C9.76733 7.97069 9.74007 7.95296 9.71424 7.93302L6.77081 5.15503C6.55654 4.94286 6.53167 4.56949 6.71485 4.32186C6.89803 4.07368 7.21992 4.04432 7.43419 4.25649L9.53535 6.2013V2.09436C9.58223 2.04749 9.76397 2.04749 10.0457 2.04749C10.3274 2.04749 10.5091 2.04749 10.556 2.09436V6.2013L12.6572 4.25649C12.8714 4.04432 13.1933 4.07367 13.3765 4.32186C13.5597 4.56949 13.5348 4.94286 13.3206 5.15503L10.3771 7.93302C10.3513 7.95296 10.324 7.97069 10.2958 7.9862C10.2767 8.00392 10.2561 8.01944 10.2346 8.03328C10.1739 8.05877 10.1103 8.07262 10.0457 8.07483Z" />
				<path d="M10.0457 12.8993C10.1102 12.9015 10.1739 12.9154 10.2346 12.9408C10.2561 12.9547 10.2767 12.9702 10.2958 12.9879C10.324 13.0034 10.3513 13.0212 10.3771 13.0411L13.3206 15.8191C13.5348 16.0313 13.5597 16.4046 13.3765 16.6523C13.1933 16.9004 12.8715 16.9298 12.6572 16.7176L10.556 14.7728V18.8798C10.5091 18.9266 10.3274 18.9266 10.0457 18.9266C9.76398 18.9266 9.58224 18.9266 9.53536 18.8798V14.7728L7.43419 16.7176C7.21992 16.9298 6.89804 16.9004 6.71486 16.6523C6.53168 16.4046 6.55654 16.0313 6.77082 15.8191L9.71425 13.0411C9.74007 13.0212 9.76734 13.0034 9.79556 12.9879C9.81469 12.9702 9.83525 12.9547 9.85678 12.9408C9.91752 12.9154 9.9811 12.9015 10.0457 12.8993Z" />
			</g>
		</svg>
	);
};

export const EditorBackgroundIcon = () => {
	return (
		<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				opacity="0.7"
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M3.49911 4.5H17.4991C17.7753 4.5 17.9991 4.72386 17.9991 5V10.6161L16.6078 9.22473C15.9994 8.61634 15.0222 8.58762 14.3792 9.15924L10.4949 12.6119L9.0868 11.2037C8.49251 10.6094 7.54311 10.5664 6.89745 11.1044L2.99911 14.353V5C2.99911 4.72386 3.22297 4.5 3.49911 4.5ZM2.99911 15.9802V16C2.99911 16.2761 3.22297 16.5 3.49911 16.5H6.1208L9.55906 13.4438L8.20291 12.0876C8.06577 11.9505 7.84668 11.9405 7.69768 12.0647L2.99911 15.9802ZM8.0023 16.5H17.4991C17.7753 16.5 17.9991 16.2761 17.9991 16V12.3838L15.7239 10.1086C15.5835 9.96822 15.358 9.96159 15.2096 10.0935L8.0023 16.5ZM1.49911 5C1.49911 3.89543 2.39455 3 3.49911 3H17.4991C18.6037 3 19.4991 3.89543 19.4991 5V16C19.4991 17.1046 18.6037 18 17.4991 18H3.49911C2.39455 18 1.49911 17.1046 1.49911 16V5Z"
			/>
			<circle opacity="0.33" cx="8.49911" cy="8" r="2" />
		</svg>
	);
};

export const EditorShadowIcon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				opacity="0.33"
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M17.9824 5.49878C18.5347 5.49878 18.9824 5.94649 18.9824 6.49878V14.5184C18.9824 16.7275 17.1915 18.5184 14.9824 18.5184H4.49911C3.94683 18.5184 3.49911 18.0707 3.49911 17.5184C3.49911 16.9661 3.94683 16.5184 4.49911 16.5184H14.9824C16.0869 16.5184 16.9824 15.623 16.9824 14.5184V6.49878C16.9824 5.94649 17.4301 5.49878 17.9824 5.49878Z"
			/>
			<path
				opacity="0.7"
				d="M3.49911 3.74878H13.4991C14.1895 3.74878 14.7491 4.30842 14.7491 4.99878V12.9988C14.7491 13.6891 14.1895 14.2488 13.4991 14.2488H3.49911C2.80876 14.2488 2.24911 13.6891 2.24911 12.9988V4.99878C2.24911 4.30842 2.80876 3.74878 3.49911 3.74878Z"
				stroke="#555555"
				stroke-width="1.5"
			/>
		</svg>
	);
};

export const EditorOverlayIcon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="Overlay">
				<path
					id="Union"
					opacity="0.7"
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M3.50119 3.5H13.5012C13.7773 3.5 14.0012 3.72386 14.0012 4V5H6.50119C5.39662 5 4.50119 5.89543 4.50119 7V12.5H3.50119C3.22505 12.5 3.00119 12.2761 3.00119 12V4C3.00119 3.72386 3.22505 3.5 3.50119 3.5ZM4.50119 14H3.50119C2.39662 14 1.50119 13.1046 1.50119 12V4C1.50119 2.89543 2.39662 2 3.50119 2H13.5012C14.6058 2 15.5012 2.89543 15.5012 4V5H16.5012C17.6058 5 18.5012 5.89543 18.5012 7V15C18.5012 16.1046 17.6058 17 16.5012 17H6.50119C5.39662 17 4.50119 16.1046 4.50119 15V14ZM15.5012 6.5H16.5012C16.7773 6.5 17.0012 6.72386 17.0012 7V15C17.0012 15.2761 16.7773 15.5 16.5012 15.5H6.50119C6.22505 15.5 6.00119 15.2761 6.00119 15V14H13.5012C14.6058 14 15.5012 13.1046 15.5012 12V6.5ZM14.0012 6.5V12C14.0012 12.2761 13.7773 12.5 13.5012 12.5H6.00119V7C6.00119 6.72386 6.22505 6.5 6.50119 6.5H14.0012Z"
				/>
				<path
					id="Intersect"
					opacity="0.33"
					d="M15.5012 5V12C15.5012 13.1046 14.6058 14 13.5012 14H4.50119V7C4.50119 5.89543 5.39662 5 6.50119 5H15.5012Z"
				/>
			</g>
		</svg>
	);
};

export const FlexRowIcon = () => {
	return (
		<svg
			className="flex-direction-icon"
			width="80"
			height="80"
			viewBox="0 0 80 80"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect className="flex-rect-main" x="0.5" y="0.5" width="79" height="79" rx="3.5" />
			<rect className="flex-rect-small" x="7.5" y="31.5" width="17" height="17" rx="1.5" />
			<rect className="flex-rect-small" x="31.5" y="31.5" width="17" height="17" rx="1.5" />
			<rect className="flex-rect-small" x="55.5" y="31.5" width="17" height="17" rx="1.5" />
			<path
				className="flex-num"
				d="M16.9033 44H15.7217V38.292L13.9785 38.8877V37.8867L16.752 36.8662H16.9033V44Z"
			/>
			<path
				className="flex-num"
				d="M42.4707 44H37.6904V43.1846L40.0586 40.6016C40.4004 40.2207 40.6429 39.9033 40.7861 39.6494C40.9326 39.3923 41.0059 39.1351 41.0059 38.8779C41.0059 38.5394 40.9098 38.266 40.7178 38.0576C40.529 37.8493 40.2734 37.7451 39.9512 37.7451C39.5671 37.7451 39.2692 37.8623 39.0576 38.0967C38.846 38.3311 38.7402 38.6517 38.7402 39.0586H37.5537C37.5537 38.6257 37.6514 38.2367 37.8467 37.8916C38.0452 37.5433 38.3268 37.2731 38.6914 37.0811C39.0592 36.889 39.4824 36.793 39.9609 36.793C40.651 36.793 41.1947 36.9671 41.5918 37.3154C41.9922 37.6605 42.1924 38.139 42.1924 38.751C42.1924 39.1058 42.0915 39.4785 41.8896 39.8691C41.6911 40.2565 41.3639 40.6976 40.9082 41.1924L39.1699 43.0527H42.4707V44Z"
			/>
			<path
				className="flex-num"
				d="M63.0625 39.9033H63.7852C64.1497 39.9001 64.4378 39.8057 64.6494 39.6201C64.8643 39.4346 64.9717 39.166 64.9717 38.8145C64.9717 38.4759 64.8822 38.2139 64.7031 38.0283C64.5273 37.8395 64.2572 37.7451 63.8926 37.7451C63.5736 37.7451 63.3115 37.8379 63.1064 38.0234C62.9014 38.2057 62.7988 38.445 62.7988 38.7412H61.6123C61.6123 38.3766 61.7083 38.0446 61.9004 37.7451C62.0957 37.4456 62.3659 37.2129 62.7109 37.0469C63.0592 36.8776 63.4482 36.793 63.8779 36.793C64.5876 36.793 65.1442 36.972 65.5479 37.3301C65.9548 37.6849 66.1582 38.1797 66.1582 38.8145C66.1582 39.1335 66.0557 39.4346 65.8506 39.7178C65.6488 39.9977 65.3867 40.2093 65.0645 40.3525C65.4551 40.486 65.7513 40.696 65.9531 40.9824C66.1582 41.2689 66.2607 41.6107 66.2607 42.0078C66.2607 42.6458 66.041 43.1536 65.6016 43.5312C65.1654 43.9089 64.5908 44.0977 63.8779 44.0977C63.1943 44.0977 62.6344 43.9154 62.1982 43.5508C61.762 43.1862 61.5439 42.7012 61.5439 42.0957H62.7305C62.7305 42.4082 62.8346 42.6621 63.043 42.8574C63.2546 43.0527 63.5378 43.1504 63.8926 43.1504C64.2604 43.1504 64.5501 43.0527 64.7617 42.8574C64.9733 42.6621 65.0791 42.3789 65.0791 42.0078C65.0791 41.6335 64.9684 41.3454 64.7471 41.1436C64.5257 40.9417 64.1969 40.8408 63.7607 40.8408H63.0625V39.9033Z"
			/>
		</svg>
	);
};

export const FlexRowReverseIcon = () => {
	return (
		<svg
			className="flex-direction-icon"
			width="80"
			height="80"
			viewBox="0 0 80 80"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect className="flex-rect-main" x="0.5" y="0.5" width="79" height="79" rx="3.5" />
			<rect className="flex-rect-small" x="56.5" y="31.5" width="17" height="17" rx="1.5" />
			<rect className="flex-rect-small" x="32.5" y="31.5" width="17" height="17" rx="1.5" />
			<rect className="flex-rect-small" x="8.5" y="31.5" width="17" height="17" rx="1.5" />
			<path
				className="flex-num"
				d="M65.9033 44H64.7217V38.292L62.9785 38.8877V37.8867L65.752 36.8662H65.9033V44Z"
			/>
			<path
				className="flex-num"
				d="M43.4707 44H38.6904V43.1846L41.0586 40.6016C41.4004 40.2207 41.6429 39.9033 41.7861 39.6494C41.9326 39.3923 42.0059 39.1351 42.0059 38.8779C42.0059 38.5394 41.9098 38.266 41.7178 38.0576C41.529 37.8493 41.2734 37.7451 40.9512 37.7451C40.5671 37.7451 40.2692 37.8623 40.0576 38.0967C39.846 38.3311 39.7402 38.6517 39.7402 39.0586H38.5537C38.5537 38.6257 38.6514 38.2367 38.8467 37.8916C39.0452 37.5433 39.3268 37.2731 39.6914 37.0811C40.0592 36.889 40.4824 36.793 40.9609 36.793C41.651 36.793 42.1947 36.9671 42.5918 37.3154C42.9922 37.6605 43.1924 38.139 43.1924 38.751C43.1924 39.1058 43.0915 39.4785 42.8896 39.8691C42.6911 40.2565 42.3639 40.6976 41.9082 41.1924L40.1699 43.0527H43.4707V44Z"
			/>
			<path
				className="flex-num"
				d="M16.0625 39.9033H16.7852C17.1497 39.9001 17.4378 39.8057 17.6494 39.6201C17.8643 39.4346 17.9717 39.166 17.9717 38.8145C17.9717 38.4759 17.8822 38.2139 17.7031 38.0283C17.5273 37.8395 17.2572 37.7451 16.8926 37.7451C16.5736 37.7451 16.3115 37.8379 16.1064 38.0234C15.9014 38.2057 15.7988 38.445 15.7988 38.7412H14.6123C14.6123 38.3766 14.7083 38.0446 14.9004 37.7451C15.0957 37.4456 15.3659 37.2129 15.7109 37.0469C16.0592 36.8776 16.4482 36.793 16.8779 36.793C17.5876 36.793 18.1442 36.972 18.5479 37.3301C18.9548 37.6849 19.1582 38.1797 19.1582 38.8145C19.1582 39.1335 19.0557 39.4346 18.8506 39.7178C18.6488 39.9977 18.3867 40.2093 18.0645 40.3525C18.4551 40.486 18.7513 40.696 18.9531 40.9824C19.1582 41.2689 19.2607 41.6107 19.2607 42.0078C19.2607 42.6458 19.041 43.1536 18.6016 43.5312C18.1654 43.9089 17.5908 44.0977 16.8779 44.0977C16.1943 44.0977 15.6344 43.9154 15.1982 43.5508C14.762 43.1862 14.5439 42.7012 14.5439 42.0957H15.7305C15.7305 42.4082 15.8346 42.6621 16.043 42.8574C16.2546 43.0527 16.5378 43.1504 16.8926 43.1504C17.2604 43.1504 17.5501 43.0527 17.7617 42.8574C17.9733 42.6621 18.0791 42.3789 18.0791 42.0078C18.0791 41.6335 17.9684 41.3454 17.7471 41.1436C17.5257 40.9417 17.1969 40.8408 16.7607 40.8408H16.0625V39.9033Z"
			/>
		</svg>
	);
};

export const FlexColumnIcon = () => {
	return (
		<svg
			className="flex-direction-icon"
			width="80"
			height="80"
			viewBox="0 0 80 80"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				className="flex-rect-main"
				x="0.5"
				y="79.5"
				width="79"
				height="79"
				rx="3.5"
				transform="rotate(-90 0.5 79.5)"
			/>
			<rect className="flex-rect-small" x="33.5" y="7.5" width="17" height="17" rx="1.5" />
			<rect className="flex-rect-small" x="33.5" y="31.5" width="17" height="17" rx="1.5" />
			<rect className="flex-rect-small" x="33.5" y="55.5" width="17" height="17" rx="1.5" />
			<path
				className="flex-num"
				d="M42.9033 20H41.7217V14.292L39.9785 14.8877V13.8867L42.752 12.8662H42.9033V20Z"
			/>
			<path
				className="flex-num"
				d="M44.4707 44H39.6904V43.1846L42.0586 40.6016C42.4004 40.2207 42.6429 39.9033 42.7861 39.6494C42.9326 39.3923 43.0059 39.1351 43.0059 38.8779C43.0059 38.5394 42.9098 38.266 42.7178 38.0576C42.529 37.8493 42.2734 37.7451 41.9512 37.7451C41.5671 37.7451 41.2692 37.8623 41.0576 38.0967C40.846 38.3311 40.7402 38.6517 40.7402 39.0586H39.5537C39.5537 38.6257 39.6514 38.2367 39.8467 37.8916C40.0452 37.5433 40.3268 37.2731 40.6914 37.0811C41.0592 36.889 41.4824 36.793 41.9609 36.793C42.651 36.793 43.1947 36.9671 43.5918 37.3154C43.9922 37.6605 44.1924 38.139 44.1924 38.751C44.1924 39.1058 44.0915 39.4785 43.8896 39.8691C43.6911 40.2565 43.3639 40.6976 42.9082 41.1924L41.1699 43.0527H44.4707V44Z"
			/>
			<path
				className="flex-num"
				d="M41.0625 63.9033H41.7852C42.1497 63.9001 42.4378 63.8057 42.6494 63.6201C42.8643 63.4346 42.9717 63.166 42.9717 62.8145C42.9717 62.4759 42.8822 62.2139 42.7031 62.0283C42.5273 61.8395 42.2572 61.7451 41.8926 61.7451C41.5736 61.7451 41.3115 61.8379 41.1064 62.0234C40.9014 62.2057 40.7988 62.445 40.7988 62.7412H39.6123C39.6123 62.3766 39.7083 62.0446 39.9004 61.7451C40.0957 61.4456 40.3659 61.2129 40.7109 61.0469C41.0592 60.8776 41.4482 60.793 41.8779 60.793C42.5876 60.793 43.1442 60.972 43.5479 61.3301C43.9548 61.6849 44.1582 62.1797 44.1582 62.8145C44.1582 63.1335 44.0557 63.4346 43.8506 63.7178C43.6488 63.9977 43.3867 64.2093 43.0645 64.3525C43.4551 64.486 43.7513 64.696 43.9531 64.9824C44.1582 65.2689 44.2607 65.6107 44.2607 66.0078C44.2607 66.6458 44.041 67.1536 43.6016 67.5312C43.1654 67.9089 42.5908 68.0977 41.8779 68.0977C41.1943 68.0977 40.6344 67.9154 40.1982 67.5508C39.762 67.1862 39.5439 66.7012 39.5439 66.0957H40.7305C40.7305 66.4082 40.8346 66.6621 41.043 66.8574C41.2546 67.0527 41.5378 67.1504 41.8926 67.1504C42.2604 67.1504 42.5501 67.0527 42.7617 66.8574C42.9733 66.6621 43.0791 66.3789 43.0791 66.0078C43.0791 65.6335 42.9684 65.3454 42.7471 65.1436C42.5257 64.9417 42.1969 64.8408 41.7607 64.8408H41.0625V63.9033Z"
			/>
		</svg>
	);
};

export const FlexColumnReverseIcon = () => {
	return (
		<svg
			className="flex-direction-icon"
			width="80"
			height="80"
			viewBox="0 0 80 80"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				className="flex-rect-main"
				x="0.5"
				y="79.5"
				width="79"
				height="79"
				rx="3.5"
				transform="rotate(-90 0.5 79.5)"
			/>
			<rect className="flex-rect-small" x="34.5" y="55.5" width="17" height="17" rx="1.5" />
			<rect className="flex-rect-small" x="34.5" y="31.5" width="17" height="17" rx="1.5" />
			<rect className="flex-rect-small" x="34.5" y="7.5" width="17" height="17" rx="1.5" />
			<path
				className="flex-num"
				d="M43.9033 68H42.7217V62.292L40.9785 62.8877V61.8867L43.752 60.8662H43.9033V68Z"
			/>
			<path
				className="flex-num"
				d="M45.4707 44H40.6904V43.1846L43.0586 40.6016C43.4004 40.2207 43.6429 39.9033 43.7861 39.6494C43.9326 39.3923 44.0059 39.1351 44.0059 38.8779C44.0059 38.5394 43.9098 38.266 43.7178 38.0576C43.529 37.8493 43.2734 37.7451 42.9512 37.7451C42.5671 37.7451 42.2692 37.8623 42.0576 38.0967C41.846 38.3311 41.7402 38.6517 41.7402 39.0586H40.5537C40.5537 38.6257 40.6514 38.2367 40.8467 37.8916C41.0452 37.5433 41.3268 37.2731 41.6914 37.0811C42.0592 36.889 42.4824 36.793 42.9609 36.793C43.651 36.793 44.1947 36.9671 44.5918 37.3154C44.9922 37.6605 45.1924 38.139 45.1924 38.751C45.1924 39.1058 45.0915 39.4785 44.8896 39.8691C44.6911 40.2565 44.3639 40.6976 43.9082 41.1924L42.1699 43.0527H45.4707V44Z"
			/>
			<path
				className="flex-num"
				d="M42.0625 15.9033H42.7852C43.1497 15.9001 43.4378 15.8057 43.6494 15.6201C43.8643 15.4346 43.9717 15.166 43.9717 14.8145C43.9717 14.4759 43.8822 14.2139 43.7031 14.0283C43.5273 13.8395 43.2572 13.7451 42.8926 13.7451C42.5736 13.7451 42.3115 13.8379 42.1064 14.0234C41.9014 14.2057 41.7988 14.445 41.7988 14.7412H40.6123C40.6123 14.3766 40.7083 14.0446 40.9004 13.7451C41.0957 13.4456 41.3659 13.2129 41.7109 13.0469C42.0592 12.8776 42.4482 12.793 42.8779 12.793C43.5876 12.793 44.1442 12.972 44.5479 13.3301C44.9548 13.6849 45.1582 14.1797 45.1582 14.8145C45.1582 15.1335 45.0557 15.4346 44.8506 15.7178C44.6488 15.9977 44.3867 16.2093 44.0645 16.3525C44.4551 16.486 44.7513 16.696 44.9531 16.9824C45.1582 17.2689 45.2607 17.6107 45.2607 18.0078C45.2607 18.6458 45.041 19.1536 44.6016 19.5312C44.1654 19.9089 43.5908 20.0977 42.8779 20.0977C42.1943 20.0977 41.6344 19.9154 41.1982 19.5508C40.762 19.1862 40.5439 18.7012 40.5439 18.0957H41.7305C41.7305 18.4082 41.8346 18.6621 42.043 18.8574C42.2546 19.0527 42.5378 19.1504 42.8926 19.1504C43.2604 19.1504 43.5501 19.0527 43.7617 18.8574C43.9733 18.6621 44.0791 18.3789 44.0791 18.0078C44.0791 17.6335 43.9684 17.3454 43.7471 17.1436C43.5257 16.9417 43.1969 16.8408 42.7607 16.8408H42.0625V15.9033Z"
			/>
		</svg>
	);
};

export const JustifyCenterIcon = () => {
	return (
		<svg width="104" height="40" viewBox="0 0 104 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect className="flex-rect-main" x="0.5" y="0.5" width="103" height="39" rx="3.5" />
			<rect className="flex-rect-small" x="30.5" y="7.70483" width="11" height="24.7952" rx="1.5" />
			<rect className="flex-rect-small" x="45.8496" y="7.5" width="11" height="24.7952" rx="1.5" />
			<rect className="flex-rect-small" x="60.5576" y="7.5" width="11" height="24.7952" rx="1.5" />
		</svg>
	);
};

export const JustifyFlexStartIcon = () => {
	return (
		<svg width="104" height="40" viewBox="0 0 104 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect className="flex-rect-main" x="0.5" y="0.5" width="103" height="39" rx="3.5" />
			<rect className="flex-rect-small" x="8.5" y="7.5" width="11" height="25" rx="1.5" />
			<rect className="flex-rect-small" x="23.5" y="7.5" width="11" height="25" rx="1.5" />
			<rect className="flex-rect-small" x="38.5" y="7.5" width="11" height="25" rx="1.5" />
		</svg>
	);
};

export const JustifyFlexEndIcon = () => {
	return (
		<svg width="104" height="40" viewBox="0 0 104 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect className="flex-rect-main" x="0.5" y="0.5" width="103" height="39" rx="3.5" />
			<rect className="flex-rect-small" x="55.5" y="7.5" width="11" height="25" rx="1.5" />
			<rect className="flex-rect-small" x="70.5" y="7.5" width="11" height="25" rx="1.5" />
			<rect className="flex-rect-small" x="85.5" y="7.5" width="11" height="25" rx="1.5" />
		</svg>
	);
};

export const JustifySpaceBetweenIcon = () => {
	return (
		<svg width="104" height="40" viewBox="0 0 104 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect className="flex-rect-main" x="0.5" y="0.5" width="103" height="39" rx="3.5" />
			<rect className="flex-rect-small" x="6.5" y="8.25" width="11" height="25" rx="1.5" />
			<rect className="flex-rect-small" x="45.5" y="8.25" width="11" height="25" rx="1.5" />
			<rect className="flex-rect-small" x="86.5" y="8.25" width="11" height="25" rx="1.5" />
		</svg>
	);
};

export const JustifySpaceAroundIcon = () => {
	return (
		<svg width="104" height="40" viewBox="0 0 104 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect className="flex-rect-main" x="0.5" y="0.5" width="103" height="39" rx="3.5" />
			<rect className="flex-rect-small" x="13.5" y="8.5" width="11" height="25" rx="1.5" />
			<rect className="flex-rect-small" x="45.5" y="8.25" width="11" height="25" rx="1.5" />
			<rect className="flex-rect-small" x="79.5" y="8.25" width="11" height="25" rx="1.5" />
		</svg>
	);
};

export const JustifySpaceEvenlyIcon = () => {
	return (
		<svg width="104" height="40" viewBox="0 0 104 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect className="flex-rect-main" x="0.5" y="0.5" width="103" height="39" rx="3.5" />
			<rect className="flex-rect-small" x="16.5" y="8.25" width="11" height="25" rx="1.5" />
			<rect className="flex-rect-small" x="45.5" y="8.25" width="11" height="25" rx="1.5" />
			<rect className="flex-rect-small" x="74.5" y="8.25" width="11" height="25" rx="1.5" />
		</svg>
	);
};

export const AlignItemsCenterIcon = () => {
	return (
		<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect className="flex-rect-main" x="0.5" y="0.5" width="79" height="79" rx="3.5" />
			<rect className="flex-rect-small" x="19.5" y="33.5" width="11" height="13" rx="1.5" />
			<rect className="flex-rect-small" x="34.5" y="23.5" width="11" height="33" rx="1.5" />
			<rect className="flex-rect-small" x="49.5" y="33.5" width="11" height="13" rx="1.5" />
		</svg>
	);
};

export const AlignItemsFlexStartIcon = () => {
	return (
		<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect className="flex-rect-main" x="0.5" y="0.5" width="79" height="79" rx="3.5" />
			<rect className="flex-rect-small" x="19.5" y="9.5" width="11" height="13" rx="1.5" />
			<rect className="flex-rect-small" x="34.8496" y="9.5" width="11" height="24.7952" rx="1.5" />
			<rect className="flex-rect-small" x="49.5" y="9.5" width="11" height="10" rx="1.5" />
		</svg>
	);
};

export const AlignItemsFlexEndIcon = () => {
	return (
		<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				className="flex-rect-main"
				x="0.5"
				y="79.5"
				width="79"
				height="79"
				rx="3.5"
				transform="rotate(-90 0.5 79.5)"
			/>
			<rect
				className="flex-rect-small"
				x="59.5"
				y="69.2952"
				width="11"
				height="13"
				rx="1.5"
				transform="rotate(-180 59.5 69.2952)"
			/>
			<rect
				className="flex-rect-small"
				x="44.1504"
				y="69.2952"
				width="11"
				height="24.7952"
				rx="1.5"
				transform="rotate(-180 44.1504 69.2952)"
			/>
			<rect
				className="flex-rect-small"
				x="29.5"
				y="69.2952"
				width="11"
				height="10"
				rx="1.5"
				transform="rotate(-180 29.5 69.2952)"
			/>
		</svg>
	);
};

export const AlignItemsStretchIcon = () => {
	return (
		<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				className="flex-rect-main"
				x="0.5"
				y="79.5"
				width="79"
				height="79"
				rx="3.5"
				transform="rotate(-90 0.5 79.5)"
			/>
			<rect className="flex-rect-small" x="32.5" y="9.5" width="11" height="61" rx="1.5" />
			<rect className="flex-rect-small" x="17.5" y="9.5" width="11" height="61" rx="1.5" />
			<rect className="flex-rect-small" x="47.5" y="9.5" width="11" height="61" rx="1.5" />
		</svg>
	);
};

export const EditorModalTextIcon = () => {
	return (
		<svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M25.1032 42.6795H32.0723L33.8423 50.2211H37.7714L32.026 25.7111H25.1465L19.4011 50.2211H23.3332L25.1032 42.6795ZM28.9767 29.4818L31.1866 38.9088H25.9828L28.1927 29.4818H28.9767Z"
				fill="#555555"
			/>
			<path
				opacity="0.7"
				d="M51.0924 35.1381V37.7393C49.6889 36.1573 47.6466 35.1381 45.347 35.1381C41.1157 35.1381 37.6865 38.5141 37.6865 42.6796C37.6865 46.8451 41.1157 50.2211 45.347 50.2211C47.6466 50.2211 49.689 49.2019 51.0924 47.6199V50.2211H53.0075V35.1381H51.0924ZM45.347 46.4504C43.2329 46.4504 41.5167 44.7609 41.5167 42.6796C41.5167 40.5983 43.2329 38.9088 45.347 38.9088C47.4596 38.9088 49.1772 40.5983 49.1772 42.6796C49.1772 44.7609 47.4596 46.4504 45.347 46.4504Z"
				fill="#555555"
			/>
		</svg>
	);
};

export const EditorModalPollIcon = () => {
	return (
		<svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="28.3416"
				y="23.8889"
				width="25.0418"
				height="4.78914"
				rx="2.39457"
				fill="#555555"
				fill-opacity="0.3"
			/>
			<rect
				x="28.3416"
				y="33.3203"
				width="25.0418"
				height="4.78914"
				rx="2.39457"
				fill="#555555"
				fill-opacity="0.3"
			/>
			<rect
				x="28.3416"
				y="43.3219"
				width="25.0418"
				height="4.78914"
				rx="2.39457"
				fill="#555555"
				fill-opacity="0.3"
			/>
			<rect
				x="19.5151"
				y="23.6517"
				width="5.40821"
				height="5.26352"
				rx="2.63176"
				stroke="#555555"
				stroke-width="2.49998"
			/>
			<rect
				x="19.5151"
				y="33.0832"
				width="5.40821"
				height="5.26352"
				rx="2.63176"
				fill="#555555"
				stroke="#555555"
				stroke-width="2.49998"
			/>
			<rect
				x="19.5151"
				y="43.0848"
				width="5.40821"
				height="5.26352"
				rx="2.63176"
				stroke="#555555"
				stroke-width="2.49998"
			/>
			<path
				opacity="0.5"
				d="M18.8571 16.25H53.1429C56.2154 16.25 58.75 18.7999 58.75 22V50C58.75 53.2001 56.2154 55.75 53.1429 55.75H18.8571C15.7846 55.75 13.25 53.2001 13.25 50V22C13.25 18.7999 15.7846 16.25 18.8571 16.25Z"
				stroke="#555555"
				stroke-width="2.5"
			/>
		</svg>
	);
};

export const EditorModalEmptyIcon = () => {
	return (
		<svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="21.2041" y="23" width="30" height="30" rx="2.99997" stroke="#555555" stroke-width="2" />
			<g opacity="0.5">
				<mask id="path-2-inside-1_488_3145" fill="white">
					<rect x="17.2041" y="19" width="9" height="9" rx="1.99998" />
				</mask>
				<rect
					x="17.2041"
					y="19"
					width="9"
					height="9"
					rx="1.99998"
					stroke="#555555"
					stroke-width="4.99996"
					mask="url(#path-2-inside-1_488_3145)"
				/>
			</g>
			<g opacity="0.5">
				<mask id="path-3-inside-2_488_3145" fill="white">
					<rect x="45.2041" y="19" width="9" height="9" rx="1.99998" />
				</mask>
				<rect
					x="45.2041"
					y="19"
					width="9"
					height="9"
					rx="1.99998"
					stroke="#555555"
					stroke-width="4.99996"
					mask="url(#path-3-inside-2_488_3145)"
				/>
			</g>
			<g opacity="0.5">
				<mask id="path-4-inside-3_488_3145" fill="white">
					<rect x="45.2041" y="48" width="9" height="9" rx="1.99998" />
				</mask>
				<rect
					x="45.2041"
					y="48"
					width="9"
					height="9"
					rx="1.99998"
					stroke="#555555"
					stroke-width="4.99996"
					mask="url(#path-4-inside-3_488_3145)"
				/>
			</g>
			<g opacity="0.5">
				<mask id="path-5-inside-4_488_3145" fill="white">
					<rect x="17.2041" y="48" width="9" height="9" rx="1.99998" />
				</mask>
				<rect
					x="17.2041"
					y="48"
					width="9"
					height="9"
					rx="1.99998"
					stroke="#555555"
					stroke-width="4.99996"
					mask="url(#path-5-inside-4_488_3145)"
				/>
			</g>
		</svg>
	);
};

export const EditorModalButtonIcon = () => {
	return (
		<svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="19.3941"
				y="33.6616"
				width="21.4114"
				height="4.67688"
				rx="2.33844"
				fill="#555555"
				fill-opacity="0.3"
			/>
			<rect
				opacity="0.7"
				x="13.1355"
				y="28.0323"
				width="45.729"
				height="15.9354"
				rx="7.96768"
				stroke="#555555"
				stroke-width="2"
			/>
			<path
				d="M54.529 35.9656C54.5248 36.0946 54.499 36.2218 54.4513 36.3432C54.4254 36.3862 54.3965 36.4273 54.3633 36.4655C54.3343 36.5219 54.3012 36.5764 54.264 36.628L50.1397 41.1043C49.7432 41.5325 49.0454 41.5822 48.5827 41.2161C48.1189 40.85 48.064 40.2067 48.4605 39.7785L51.0277 36.9856H45.0219C44.4121 36.9856 43.9172 36.5286 43.9172 35.9656C43.9172 35.4026 44.4121 34.9457 45.0219 34.9457H51.0277L48.5827 32.2216C48.1861 31.7933 48.241 31.15 48.7048 30.7839C49.1676 30.4178 49.8653 30.4675 50.2619 30.8958L54.264 35.3032C54.3012 35.3548 54.3343 35.4093 54.3633 35.4657C54.3965 35.5039 54.4255 35.545 54.4513 35.5881C54.499 35.7095 54.5248 35.8365 54.529 35.9656Z"
				fill="#555555"
			/>
		</svg>
	);
};

export const EditorModalMeetIcon = () => {
	return (
		<svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				opacity="0.7"
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M24.3847 49.7073C21.8295 46.8718 20.2742 43.1175 20.2742 39C20.2742 30.1634 27.4376 23 36.2742 23C45.1107 23 52.2742 30.1634 52.2742 39C52.2742 43.1175 50.7188 46.8718 48.1636 49.7073C47.5644 45.2291 44.5853 41.5156 40.5743 39.9392C42.384 38.5849 43.562 36.3998 43.562 33.9389C43.562 29.8364 40.293 26.5 36.2738 26.5C32.2545 26.5 28.9857 29.8366 28.9857 33.9389C28.9857 36.3999 30.1636 38.5849 31.9733 39.9392C27.9621 41.5156 24.9838 45.2292 24.3847 49.7073ZM26.4802 51.6531C29.1867 53.7512 32.5846 55 36.2742 55C39.9645 55 43.363 53.7506 46.0699 51.6518C46.0681 51.5652 46.0672 51.4726 46.0672 51.3737C46.0672 45.8635 41.6728 41.378 36.2742 41.378C30.8757 41.378 26.4811 45.8633 26.4811 51.3737C26.4811 51.4704 26.4808 51.5635 26.4802 51.6531ZM17.7742 39C17.7742 28.7827 26.0569 20.5 36.2742 20.5C46.4914 20.5 54.7742 28.7827 54.7742 39C54.7742 49.2173 46.4914 57.5 36.2742 57.5C26.0569 57.5 17.7742 49.2173 17.7742 39ZM36.2737 28.7524C33.4709 28.7524 31.1923 31.0781 31.1923 33.9389C31.1923 36.7997 33.4709 39.1255 36.2737 39.1255C39.0764 39.1255 41.3551 36.7997 41.3551 33.9389C41.3551 31.0782 39.0764 28.7524 36.2737 28.7524Z"
				fill="#555555"
			/>
			<path
				d="M58.4899 11.3384H40.1273C37.3273 11.3384 35.028 13.5679 35.028 16.2831V23.5712C35.028 26.3143 37.3272 28.5437 40.1273 28.5437H40.7755L40.4221 31.5155C40.3633 32.0581 40.6581 32.5728 41.1589 32.8017C41.3362 32.8876 41.5423 32.9156 41.7184 32.9156C42.0719 32.9156 42.3966 32.8017 42.6614 32.5438L46.8178 28.5133H58.49C61.2901 28.5133 63.5893 26.2838 63.5893 23.5686V16.2816C63.5893 13.5664 61.3187 11.3384 58.4899 11.3384Z"
				fill="#555555"
			/>
			<path
				d="M44.125 20.4245C44.125 23.1324 39.9344 23.1324 39.9344 20.4245C39.9344 17.7149 44.125 17.7149 44.125 20.4245Z"
				fill="white"
			/>
			<path
				d="M51.6925 20.4245C51.6925 23.1324 47.502 23.1324 47.502 20.4245C47.502 17.7149 51.6925 17.7149 51.6925 20.4245Z"
				fill="white"
			/>
			<path
				d="M59.1749 20.4245C59.1749 23.1324 54.9844 23.1324 54.9844 20.4245C54.9844 17.7149 59.1749 17.7149 59.1749 20.4245Z"
				fill="white"
			/>
		</svg>
	);
};

export const EditorModalStreamIcon = () => {
	return (
		<svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g opacity="0.5">
				<path
					d="M49.7328 50.8005C49.9497 51.34 50.4643 51.6908 51.033 51.6872C51.4075 51.6854 51.7645 51.5314 52.027 51.2562C55.9645 47.2095 58.1747 41.7202 58.1747 35.9971C58.1747 30.274 55.9643 24.7848 52.027 20.7381C51.4827 20.1733 50.5955 20.1715 50.046 20.7309C49.4966 21.2902 49.4948 22.2021 50.039 22.7668C53.4548 26.2758 55.3727 31.0348 55.3727 35.9968C55.3727 40.9588 53.4549 45.7178 50.039 49.2267C49.6348 49.6386 49.514 50.2609 49.7328 50.8005Z"
					fill="#555555"
				/>
				<path
					d="M51.1693 36.0039C51.1693 39.8186 49.6959 43.4788 47.071 46.1766C46.5215 46.736 45.6343 46.7337 45.0901 46.169C44.8276 45.8974 44.6823 45.5305 44.6841 45.1492C44.6841 44.7679 44.8328 44.4028 45.0971 44.133C47.1917 41.975 48.3677 39.0522 48.3677 36.0037C48.3677 32.9552 47.1917 30.0327 45.0971 27.8744C44.5476 27.315 44.5458 26.4032 45.0901 25.8384C45.6343 25.2737 46.5215 25.2719 47.071 25.8312C49.6959 28.5294 51.1693 32.1893 51.1693 36.0039Z"
					fill="#555555"
				/>
			</g>
			<g opacity="0.5">
				<path
					d="M22.2672 21.1996C22.0503 20.6601 21.5358 20.3094 20.9671 20.313C20.5925 20.3148 20.2356 20.4688 19.9731 20.744C16.0355 24.7907 13.8254 30.2799 13.8254 36.003C13.8254 41.7261 16.0357 47.2153 19.9731 51.2621C20.5173 51.8268 21.4045 51.8286 21.954 51.2692C22.5035 50.7099 22.5052 49.798 21.961 49.2333C18.5452 45.7244 16.6273 40.9654 16.6273 36.0033C16.6273 31.0413 18.5451 26.2824 21.961 22.7734C22.3652 22.3615 22.486 21.7392 22.2672 21.1996Z"
					fill="#555555"
				/>
				<path
					d="M20.8307 35.9962C20.8307 32.1815 22.3041 28.5213 24.929 25.8235C25.4785 25.2641 26.3658 25.2664 26.91 25.8312C27.1725 26.1027 27.3177 26.4696 27.316 26.8509C27.316 27.2322 27.1672 27.5973 26.903 27.8671C24.8083 30.0251 23.6323 32.9479 23.6323 35.9964C23.6323 39.0449 24.8083 41.9675 26.903 44.1257C27.4524 44.6851 27.4542 45.597 26.91 46.1617C26.3657 46.7264 25.4785 46.7282 24.929 46.1689C22.3042 43.4708 20.8307 39.8108 20.8307 35.9962Z"
					fill="#555555"
				/>
			</g>
			<path
				d="M42.1448 34.6655L33.5719 30.2067C33.0404 29.9309 32.3845 29.9309 31.8552 30.2086C31.3236 30.4844 30.9979 30.9974 31 31.5489V40.4512C30.9979 41.0028 31.3236 41.5157 31.8552 41.7915C32.3845 42.0692 33.0404 42.0692 33.5719 41.7935L42.1448 37.3346C42.6742 37.0588 43 36.5497 43 36C43 35.4504 42.6742 34.9413 42.1448 34.6655Z"
				fill="#555555"
			/>
		</svg>
	);
};

export const EditorModalCardIcon = () => {
	return (
		<svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				opacity="0.25"
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M17.1752 11.5C12.9363 11.5 9.5 15.0512 9.5 19.4317V43.5683C9.5 46.5729 11.1166 49.1873 13.5001 50.5333C13.5 50.5222 13.5 50.5111 13.5 50.5V22.5C13.5 18.634 16.57 15.5 20.3571 15.5H54.4922C53.1699 13.1104 50.6793 11.5 47.8248 11.5H17.1752Z"
				fill="#555555"
			/>
			<path
				opacity="0.7"
				d="M22.7857 19.75H54.2143C56.9866 19.75 59.25 22.017 59.25 24.8333V50.1667C59.25 52.983 56.9866 55.25 54.2143 55.25H22.7857C20.0134 55.25 17.75 52.983 17.75 50.1667V24.8333C17.75 22.017 20.0134 19.75 22.7857 19.75Z"
				stroke="#555555"
				stroke-width="2.5"
			/>
			<path
				d="M42.7604 29.4937C42.7604 28.4338 43.6196 27.5745 44.6796 27.5745H52.5236C53.5835 27.5745 54.4428 28.4338 54.4428 29.4937V29.4937C54.4428 30.5536 53.5835 31.4128 52.5236 31.4128H44.6796C43.6196 31.4128 42.7604 30.5536 42.7604 29.4937V29.4937Z"
				fill="#555555"
				fill-opacity="0.3"
			/>
			<path
				d="M42.7604 36.3064C42.7604 35.2464 43.6196 34.3872 44.6796 34.3872H52.5236C53.5835 34.3872 54.4428 35.2464 54.4428 36.3064V36.3064C54.4428 37.3663 53.5835 38.2255 52.5236 38.2255H44.6796C43.6196 38.2255 42.7604 37.3663 42.7604 36.3064V36.3064Z"
				fill="#555555"
				fill-opacity="0.3"
			/>
			<path
				d="M22.841 48.4047C22.841 47.3448 23.7003 46.4855 24.7602 46.4855H52.5236C53.5835 46.4855 54.4428 47.3448 54.4428 48.4047V48.4047C54.4428 49.4646 53.5835 50.3239 52.5236 50.3239H24.7602C23.7003 50.3239 22.841 49.4646 22.841 48.4047V48.4047Z"
				fill="#555555"
				fill-opacity="0.3"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M30.7541 23.9315C25.7836 23.9315 21.7541 27.961 21.7541 32.9315C21.7541 35.0921 22.5155 37.0749 23.7845 38.6262C24.4143 37.1037 25.8234 35.7217 27.6332 34.8944C26.7416 34.0874 26.1799 32.9372 26.1799 31.6591C26.1799 29.2055 28.2413 27.2285 30.7539 27.2285C33.2664 27.2285 35.328 29.2054 35.328 31.6591C35.328 32.9372 34.7662 34.0874 33.8747 34.8944C35.6687 35.7145 37.0692 37.1519 37.7071 38.6465C38.9861 37.0922 39.7541 35.1016 39.7541 32.9315C39.7541 27.961 35.7247 23.9315 30.7541 23.9315ZM36.0476 40.211C35.5225 37.865 33.3552 36.0897 30.7541 36.0897C28.1532 36.0897 25.9857 37.865 25.4606 40.211C26.9462 41.2931 28.7756 41.9315 30.7541 41.9315C32.7327 41.9315 34.5621 41.2931 36.0476 40.211ZM28.0943 31.6591C28.0943 30.2575 29.277 29.0996 30.7538 29.0996C32.2307 29.0996 33.4134 30.2575 33.4134 31.6591C33.4134 33.0606 32.2307 34.2186 30.7538 34.2186C29.277 34.2186 28.0943 33.0606 28.0943 31.6591Z"
				fill="#555555"
			/>
		</svg>
	);
};

export const EditorModalSeparatorIcon = () => {
	return (
		<svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M20.6045 38.1992L25.8561 42.59L25.8563 42.5899C26.3346 42.995 26.4079 43.6931 26.0402 44.2074C25.8204 44.466 25.4886 44.6125 25.1582 44.6125C24.8637 44.6125 24.6439 44.5392 24.4227 44.3554L16.6628 37.8825C16.4042 37.6627 16.2577 37.3683 16.2577 37.0005C16.2577 36.6328 16.4057 36.3398 16.6628 36.1185L24.3852 29.6446C24.6064 29.4607 24.8636 29.3875 25.1207 29.3875C25.4511 29.3875 25.7814 29.5354 26.0027 29.7925C26.4077 30.2709 26.3345 30.969 25.8561 31.3741L20.6483 35.6992H51.2426L46.0703 31.3748C45.5934 30.9697 45.5187 30.2716 45.9238 29.7932C46.1436 29.5346 46.4754 29.3881 46.8058 29.3881C47.0629 29.3881 47.32 29.4614 47.5413 29.6453L55.3369 36.1181C55.5954 36.3379 55.742 36.6697 55.742 37.0001L55.7423 36.9999C55.7423 37.3303 55.5944 37.6607 55.3372 37.8819L47.5788 44.3548C47.3575 44.5013 47.1004 44.6119 46.8433 44.6119C46.4756 44.6119 46.1825 44.4639 45.9613 44.2068C45.5562 43.7284 45.6295 43.0303 46.1078 42.6252L51.3749 38.1992H20.6045Z"
				fill="#555555"
			/>
		</svg>
	);
};

export const EditorModalVideoIcon = () => {
	return (
		<svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				opacity="0.5"
				x="19.25"
				y="19.25"
				width="33.5"
				height="33.5"
				rx="16.75"
				stroke="#555555"
				stroke-width="2.5"
			/>
			<path
				d="M42.1448 34.6655L33.5719 30.2066C33.0404 29.9308 32.3845 29.9308 31.8552 30.2085C31.3236 30.4843 30.9979 30.9973 31 31.5489V40.4511C30.9979 41.0027 31.3236 41.5157 31.8552 41.7915C32.3845 42.0692 33.0404 42.0692 33.5719 41.7934L42.1448 37.3345C42.6742 37.0587 43 36.5496 43 36C43 35.4503 42.6742 34.9413 42.1448 34.6655Z"
				fill="#555555"
			/>
		</svg>
	);
};

export const EditorModalEmbedIcon = () => {
	return (
		<svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				opacity="0.7"
				d="M28.0972 41.2365L21.3673 35.6098L28.0972 30.0206C28.5755 29.6155 28.6488 28.9174 28.2437 28.439C28.0225 28.1819 27.6921 28.0339 27.3617 28.0339C27.1046 28.0339 26.8475 28.1072 26.6263 28.2911L18.9038 34.765C18.6467 34.9862 18.4987 35.2793 18.4987 35.647C18.4987 36.0147 18.6453 36.3092 18.9038 36.529L26.6638 43.0018C26.885 43.1857 27.1048 43.259 27.3992 43.259C27.7296 43.259 28.0615 43.1124 28.2812 42.8539C28.649 42.3396 28.5757 41.6415 28.0974 41.2364L28.0972 41.2365Z"
				fill="#555555"
			/>
			<path
				opacity="0.7"
				d="M53.5009 35.6466C53.5009 35.3162 53.3544 34.9844 53.0958 34.7646L45.3002 28.2917C45.079 28.1079 44.8218 28.0346 44.5647 28.0346C44.2343 28.0346 43.9025 28.1811 43.6827 28.4397C43.2777 28.9181 43.3524 29.6162 43.8293 30.0213L50.5592 35.648L43.8668 41.2717C43.3884 41.6768 43.3152 42.3749 43.7203 42.8533C43.9415 43.1104 44.2345 43.2584 44.6022 43.2584C44.8594 43.2584 45.1165 43.1478 45.3377 43.0013L53.0962 36.5284C53.3533 36.3072 53.5013 35.9768 53.5013 35.6464L53.5009 35.6466Z"
				fill="#555555"
			/>
			<path
				d="M39.348 23.463C39.2351 23.441 39.1249 23.4507 39.0161 23.4602C38.5396 23.5019 38.1221 23.7893 38.0142 24.2369L31.7854 47.1526C31.6458 47.6666 32.0211 48.1661 32.6214 48.2702C32.7344 48.2922 32.8446 48.2825 32.9533 48.273C33.4656 48.2282 33.8447 47.9135 33.9553 47.4963L40.1426 24.5536C40.3235 24.0666 39.9481 23.567 39.3478 23.4629L39.348 23.463Z"
				fill="#555555"
			/>
		</svg>
	);
};

export const EditorModalImageIcon = () => {
	return (
		<svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				opacity="0.7"
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M19.9466 21.2527H53.5223C54.3507 21.2527 55.0222 21.9243 55.0222 22.7527V38.5114L51.3436 35.3045C50.0555 34.1815 48.043 34.1357 46.6942 35.1809L36.9744 42.7074L33.2449 39.4561C31.9849 38.3577 30.0298 38.2886 28.6801 39.2692L18.4466 46.7036V22.7527C18.4466 21.9243 19.1182 21.2527 19.9466 21.2527ZM15.9466 48.5552V49.2474C15.9466 51.4565 17.7375 53.2474 19.9466 53.2474H53.5222C55.7314 53.2474 57.5222 51.4565 57.5222 49.2474V22.7527C57.5222 20.5435 55.7314 18.7527 53.5223 18.7527H19.9466C17.7375 18.7527 15.9466 20.5435 15.9466 22.7527V48.5198L15.9298 48.532L15.9466 48.5552ZM18.4488 49.3286L29.9291 40.9884C30.4916 40.5798 31.3483 40.6219 31.8485 41.0579L35.2618 44.0336L26.5915 50.7474H19.9466C19.1455 50.7474 18.491 50.1193 18.4488 49.3286ZM30.0624 50.7474H53.5222C54.3507 50.7474 55.0222 50.0758 55.0222 49.2474V41.3306L49.9471 36.9063C49.4333 36.4583 48.5516 36.43 47.9958 36.8607L47.9955 36.8609L30.0624 50.7474Z"
				fill="#555555"
			/>
			<circle cx="30.2216" cy="30.6436" r="4.00195" fill="#555555" />
		</svg>
	);
};

export const EditorModalGalleryIcon = () => {
	return (
		<svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				opacity="0.7"
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M17.4286 21.5H54.5714C57.6822 21.5 59.5 23.5754 59.5 25.3333V43.1213L54.434 39.4341C52.8911 38.3112 50.4806 38.2654 48.865 39.3106L37.2228 46.837L32.7557 43.5858C31.2465 42.4873 28.9047 42.4183 27.288 43.3988L12.8955 52.1282C12.6354 51.6498 12.5 51.1464 12.5 50.6667V25.3333C12.5 23.5754 14.3178 21.5 17.4286 21.5ZM14.527 53.7652C15.2984 54.2144 16.2745 54.5 17.4286 54.5H25.3695L35.1715 48.1632L31.0831 45.1876C30.484 44.7516 29.4578 44.7094 28.784 45.1181L14.527 53.7652ZM29.5269 54.5L50.4238 40.9906L50.4241 40.9903C51.0898 40.5596 52.1459 40.588 52.7613 41.0359L59.4867 45.9308L59.5 45.918V50.6667C59.5 52.4246 57.6822 54.5 54.5714 54.5H29.5269ZM25.6598 57L54.5714 57C58.6741 57 62 54.1645 62 50.6667V25.3333C62 21.8355 58.6741 19 54.5714 19H17.4286C13.3259 19 10 21.8355 10 25.3333V50.6667C10 54.1645 13.3259 57 17.4286 57L25.6598 57Z"
				fill="#555555"
			/>
			<rect
				x="22"
				y="46"
				width="17"
				height="17"
				rx="8.5"
				transform="rotate(-180 22 46)"
				fill="white"
				stroke="#555555"
				stroke-width="2"
			/>
			<rect x="51" y="29" width="17" height="17" rx="8.5" fill="white" stroke="#555555" stroke-width="2" />
			<path
				d="M9.02765 37.593C9.03116 37.4867 9.05311 37.3819 9.0935 37.2819C9.11545 37.2464 9.14004 37.2125 9.16813 37.181C9.19272 37.1346 9.22081 37.0897 9.25242 37.0471L12.7503 33.3587C13.0866 33.0058 13.6783 32.9649 14.0708 33.2665C14.4642 33.5682 14.5107 34.0983 14.1744 34.4512L11.9971 36.7525H17.0908C17.608 36.7525 18.0277 37.129 18.0277 37.593C18.0277 38.0569 17.608 38.4334 17.0908 38.4334H11.9971L14.0708 40.6781C14.4071 41.031 14.3606 41.5611 13.9672 41.8627C13.5747 42.1644 12.983 42.1234 12.6467 41.7706L9.25242 38.1388C9.22081 38.0963 9.19272 38.0514 9.16813 38.0049C9.14004 37.9734 9.11545 37.9395 9.0935 37.9041C9.05311 37.804 9.03116 37.6993 9.02765 37.593Z"
				fill="#555555"
			/>
			<path
				d="M64.0276 37.5363C64.0241 37.6426 64.0022 37.7474 63.9618 37.8474C63.9398 37.8829 63.9153 37.9167 63.8872 37.9482C63.8626 37.9947 63.8345 38.0396 63.8029 38.0821L60.305 41.7706C59.9687 42.1234 59.3769 42.1644 58.9845 41.8627C58.5911 41.5611 58.5446 41.031 58.8809 40.6781L61.0582 38.3767H55.9645C55.4473 38.3767 55.0276 38.0002 55.0276 37.5363C55.0276 37.0724 55.4473 36.6959 55.9645 36.6959H61.0582L58.9845 34.4512C58.6482 34.0983 58.6947 33.5682 59.0881 33.2665C59.4806 32.9649 60.0723 33.0058 60.4086 33.3587L63.8029 36.9905C63.8345 37.033 63.8626 37.0779 63.8872 37.1244C63.9153 37.1559 63.9398 37.1897 63.9618 37.2252C64.0022 37.3252 64.0241 37.4299 64.0276 37.5363Z"
				fill="#555555"
			/>
			<circle cx="30.0019" cy="32.0019" r="4.00195" fill="#555555" />
		</svg>
	);
};

export const IconLaptop = props => {
	const height = props.height ? props.height : 28;
	const width = props.width ? props.width : 40;
	return (
		<svg
			className={props.className}
			width={width}
			height={height}
			viewBox="0 0 33 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M32.5039 20.2381C32.5039 20.2381 32.5007 21.8412 31.3283 21.8412H1.70413C0.500335 21.8412 0.503906 20.2381 0.503906 20.2381H32.5039ZM26.907 2.60315C28.2308 2.60315 29.3039 3.68176 29.3039 5.01495V18.6359C29.3039 19.0781 28.942 19.4365 28.5097 19.4365H4.49814C4.05949 19.4365 3.70391 19.0849 3.70391 18.6359V5.01495C3.70391 3.68295 4.77131 2.60315 6.10079 2.60315H26.907Z"
			/>
		</svg>
	);
};

export const IconTabletEditor = props => {
	const height = props.height ? props.height : 30;
	const width = props.width ? props.width : 38;
	return (
		<svg
			className={props.className}
			width={width}
			height={height}
			viewBox="0 0 21 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M15.6843 1H5.33134C3.77195 1 2.50781 2.23122 2.50781 3.75V20.25C2.50781 21.7688 3.77195 23 5.33134 23H15.6843C17.2437 23 18.5078 21.7688 18.5078 20.25V3.75C18.5078 2.23122 17.2437 1 15.6843 1Z" />
		</svg>
	);
};

export const IconIphone = props => {
	const height = props.height ? props.height : 30;
	const width = props.width ? props.width : 20;
	return (
		<svg
			className={props.className}
			width={width}
			height={height}
			viewBox="0 0 14 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M9.25391 3C10.4965 3 11.5039 4.00736 11.5039 5.25V18.75C11.5039 19.9926 10.4965 21 9.25391 21H4.75391C3.51127 21 2.50391 19.9926 2.50391 18.75V5.25C2.50391 4.00736 3.51127 3 4.75391 3H9.25391ZM8.87891 19.5H5.12891C4.9218 19.5 4.75391 19.6679 4.75391 19.875C4.75391 20.0821 4.9218 20.25 5.12891 20.25H8.87891C9.08601 20.25 9.25391 20.0821 9.25391 19.875C9.25391 19.6679 9.08601 19.5 8.87891 19.5Z"
				fill="#555555"
			/>
		</svg>
	);
};

export const IconCheckmarkDone = props => {
	const height = props.height ? props.height : 30;
	const width = props.width ? props.width : 20;
	return (
		<svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
			<circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
			<path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
		</svg>
	);
};

export const RoomsIcon = props => {
	const color = props.color ? props.color : "#fff";
	const size = props.size ? props.size : 40;
	return (
		<svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18.2623 28.1291H5.44222C5.19831 28.1291 5 27.9317 5 27.6888C5 27.446 5.19832 27.2485 5.44222 27.2485H12.0734V25.0481C12.0734 24.8042 12.2717 24.6078 12.5156 24.6078C12.7596 24.6078 12.9579 24.8042 12.9579 25.0481V27.2485H18.2624V12.2826H12.9579V14.483C12.9579 14.727 12.7595 14.9233 12.5156 14.9233C12.2717 14.9233 12.0734 14.727 12.0734 14.483V11.8423C12.0734 11.5995 12.2717 11.402 12.5156 11.402H18.2622V7.44033C18.2622 7.10557 18.6246 6.89335 18.9198 7.05563L26.8766 11.4574C27.0179 11.5357 27.1045 11.6821 27.1045 11.8421V27.2482H33.7357C33.9797 27.2482 34.178 27.4456 34.178 27.6885C34.178 27.9313 33.9796 28.1288 33.7357 28.1288H26.7775L18.9199 32.4751C18.6247 32.6374 18.2623 32.4252 18.2623 32.0904L18.2623 28.1291ZM19.1467 8.18938V31.3416L26.22 27.4299V12.1008L19.1467 8.18938ZM12.7743 19.3252L11.3189 17.8761C11.1456 17.7036 11.1456 17.4255 11.3189 17.2531C11.491 17.0817 11.7714 17.0817 11.9434 17.2531L14.1546 19.4546C14.3267 19.6259 14.3267 19.9051 14.1546 20.0764L11.9434 22.278C11.7713 22.4493 11.491 22.4493 11.3189 22.278C11.1456 22.1055 11.1456 21.8274 11.3189 21.655L12.7743 20.2058H7.21015C6.96624 20.2058 6.76907 20.0083 6.76907 19.7655C6.76907 19.5227 6.96625 19.3252 7.21015 19.3252H12.7743ZM21.1367 20.6461C20.7697 20.6461 20.4733 20.35 20.4733 19.9857C20.4733 19.6214 20.7697 19.3252 21.1367 19.3252C21.5025 19.3252 21.7988 19.6214 21.7988 19.9857C21.7988 20.35 21.5025 20.6461 21.1367 20.6461Z"
				fill={color}
			/>
		</svg>
	);
};

export const PartnersIcon = props => {
	const color = props.color ? props.color : "#fff";
	const size = props.size ? props.size : 40;
	return (
		<svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M22.354 11.1708C22.354 12.3697 21.3821 13.3415 20.1832 13.3415C18.9843 13.3415 18.0124 12.3697 18.0124 11.1708C18.0124 9.97185 18.9843 9 20.1832 9C21.3821 9 22.354 9.97185 22.354 11.1708"
				stroke={color}
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M15.6123 20.8433C15.6123 17.5418 17.6579 14.8647 20.1828 14.8647C22.7068 14.8647 24.7533 17.542 24.7533 20.8433"
				stroke={color}
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10 20.8434H30.366"
				stroke={color}
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M13.5564 20.8434H26.808V31.9255H13.5564V20.8434Z"
				stroke={color}
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M13.0789 20.5872C13.0789 20.5872 12.8453 17.5558 15.2712 16.0625"
				stroke={color}
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M23.389 26.5355C23.389 28.4296 21.8545 29.9641 19.9604 29.9641C18.0671 29.9641 16.5318 28.4296 16.5318 26.5355C16.5318 24.6422 18.0671 23.1069 19.9604 23.1069C21.8545 23.1069 23.389 24.6422 23.389 26.5355Z"
				stroke={color}
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M21.5242 26.5356C21.5242 28.4298 20.8243 29.9651 19.9608 29.9651C19.0974 29.9651 18.3984 28.4298 18.3984 26.5356C18.3984 24.6414 19.0974 23.1061 19.9608 23.1061C20.8243 23.1061 21.5242 24.6414 21.5242 26.5356Z"
				stroke={color}
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M16.6478 26.5356H23.2723"
				stroke={color}
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export const NetworkingIcon = props => {
	const color = props.color ? props.color : "#fff";
	const size = props.size ? props.size : 40;
	return (
		<svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.94414 22.1064C7.94414 22.366 7.73174 22.5784 7.47213 22.5784C7.21251 22.5784 7.00011 22.366 7.00011 22.1064C6.99381 19.9477 7.53033 17.8647 8.51053 16.0176C9.49545 14.1594 10.9288 12.542 12.7051 11.3385C12.9175 11.1906 13.2117 11.2504 13.3596 11.4628C13.5075 11.6752 13.4477 11.9694 13.2353 12.1173C11.5896 13.2328 10.2617 14.7306 9.34755 16.4597C8.43973 18.1762 7.94411 20.1114 7.94411 22.1111L7.94414 22.1064Z"
				fill={color}
			/>
			<path
				d="M26.752 12.1123C26.5396 11.9644 26.4814 11.6765 26.6277 11.4578C26.7756 11.2454 27.0635 11.1871 27.2823 11.3335C29.0696 12.5434 30.4919 14.1592 31.4703 16.0126C32.4553 17.8707 32.9871 19.9648 32.9808 22.1127C32.9808 22.3723 32.7684 22.5847 32.5088 22.5847C32.2492 22.5847 32.0367 22.3723 32.0367 22.1127C32.043 20.1239 31.5474 18.1777 30.6333 16.4548C29.7255 14.7383 28.4038 13.2389 26.7518 12.1185L26.752 12.1123Z"
				fill={color}
			/>
			<path
				d="M25.7732 32.6491C26.0029 32.5248 26.2861 32.6082 26.4104 32.8379C26.5347 33.0676 26.4513 33.3508 26.2216 33.4751C24.3273 34.5135 22.2394 35.039 20.1501 35.0626C18.0433 35.0862 15.9382 34.6032 14.0315 33.623C13.8017 33.505 13.7073 33.2218 13.8316 32.9858C13.9496 32.7561 14.2328 32.6617 14.4688 32.786C16.2388 33.7001 18.1914 34.1485 20.144 34.1249C22.0792 34.1013 24.0207 33.612 25.772 32.6506L25.7732 32.6491Z"
				fill={color}
			/>
			<path
				d="M22.613 7.11049C22.613 8.27604 21.6682 9.22085 20.5026 9.22085C19.3371 9.22085 18.3923 8.27604 18.3923 7.11049C18.3923 5.94493 19.3371 5.00012 20.5026 5.00012C21.6682 5.00012 22.613 5.94493 22.613 7.11049"
				stroke={color}
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M16.0591 16.514C16.0591 13.3044 18.0477 10.7018 20.5024 10.7018C22.9562 10.7018 24.9457 13.3045 24.9457 16.514"
				stroke={color}
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path d="M15.8133 16.4629H24.9239" stroke={color} />
			<path
				d="M29.9075 20.6575C29.9075 21.823 28.9627 22.7678 27.7972 22.7678C26.6316 22.7678 25.6868 21.823 25.6868 20.6575C25.6868 19.4919 26.6316 18.5471 27.7972 18.5471C28.9627 18.5471 29.9075 19.4919 29.9075 20.6575"
				stroke={color}
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M23.3536 30.061C23.3536 26.8513 25.3422 24.2487 27.7969 24.2487C30.2507 24.2487 32.2402 26.8515 32.2402 30.061"
				stroke={color}
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path d="M23.1078 30.0099H32.2184" stroke={color} />
			<path
				d="M14.2765 20.6575C14.2765 21.823 13.3317 22.7678 12.1662 22.7678C11.0006 22.7678 10.0558 21.823 10.0558 20.6575C10.0558 19.4919 11.0006 18.5471 12.1662 18.5471C13.3317 18.5471 14.2765 19.4919 14.2765 20.6575"
				stroke={color}
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M7.7226 30.061C7.7226 26.8513 9.71126 24.2487 12.1659 24.2487C14.6197 24.2487 16.6092 26.8515 16.6092 30.061"
				stroke={color}
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path d="M7.47684 30.0099H16.5875" stroke={color} />
		</svg>
	);
};

export const MoreIcon = props => {
	const color = props.color ? props.color : "#fff";
	const size = props.size ? props.size : 40;
	return (
		<svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M15 19.5C15 18.6716 14.3284 18 13.5 18C12.6716 18 12 18.6716 12 19.5C12 20.3284 12.6716 21 13.5 21C14.3284 21 15 20.3284 15 19.5Z"
				fill={color}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M22 19.5C22 18.6716 21.3284 18 20.5 18C19.6716 18 19 18.6716 19 19.5C19 20.3284 19.6716 21 20.5 21C21.3284 21 22 20.3284 22 19.5Z"
				fill={color}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M29 19.5C29 18.6716 28.3284 18 27.5 18C26.6716 18 26 18.6716 26 19.5C26 20.3284 26.6716 21 27.5 21C28.3284 21 29 20.3284 29 19.5Z"
				fill={color}
			/>
		</svg>
	);
};

export const MaterialsIcon = props => {
	const color = props.color ? props.color : "#fff";
	const size = props.size ? props.size : 40;
	return (
		<svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M27.6077 27.2193H12.864C12.3465 27.2193 12 27.4453 12 27.704C12 27.9943 12.4027 28.1887 12.864 28.1887H27.61C28.1275 28.1887 28.474 27.9628 28.474 27.704C28.5302 27.4465 28.1275 27.2193 27.6078 27.2193H27.6077Z"
				fill={color}
			/>
			<path
				d="M19.3513 24.1526C19.4446 24.2481 19.5682 24.3114 19.7233 24.3114C19.8469 24.3114 20.002 24.2481 20.0953 24.1526L23.4457 20.977C23.539 20.8815 23.6008 20.755 23.6323 20.5962C23.6323 20.4374 23.6008 20.3109 23.4773 20.1831C23.3839 20.0876 23.2289 20.0244 23.1053 19.9921C22.9502 19.9921 22.8266 20.0244 22.7018 20.1509L20.282 22.4693V11.5711C20.282 11.2536 20.002 11 19.7233 11C19.4131 11 19.1962 11.2543 19.1962 11.5396L19.2278 22.4693L16.808 20.1509C16.6844 20.0554 16.5596 19.9921 16.4045 19.9921C16.2494 19.9921 16.1258 20.0554 16.0325 20.1831C15.9392 20.2787 15.8774 20.4374 15.8774 20.5962C15.8774 20.755 15.9392 20.8815 16.064 20.977L19.3513 24.1526Z"
				fill={color}
			/>
		</svg>
	);
};

export const LobbyIcon = props => {
	const color = props.color ? props.color : "#fff";
	const size = props.size ? props.size : 40;
	return (
		<svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M21.5252 11.0252C21.5252 12.1437 20.6186 13.0503 19.5001 13.0503C18.3816 13.0503 17.4749 12.1437 17.4749 11.0252C17.4749 9.90665 18.3816 8.99998 19.5001 8.99998C20.6186 8.99998 21.5252 9.90665 21.5252 11.0252"
				stroke={color}
				stroke-width="0.929914"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M15.2358 20.0491C15.2358 16.969 17.1442 14.4715 19.4998 14.4715C21.8545 14.4715 23.7637 16.9692 23.7637 20.0491"
				stroke={color}
				stroke-width="0.929914"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10 20.0491H29"
				stroke={color}
				stroke-width="0.929914"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M13.3179 20.0491H25.6806V30.3879H13.3179V20.0491Z"
				stroke={color}
				stroke-width="0.929914"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export const LivetoLoader = props => {
	const color = props.color ? props.color : "#d62b1e";
	const width = props.size ? props.size : 200;
	const height = parseInt((width / 59) * 25);
	return (
		<svg
			style={{ margin: "auto" }}
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 59.170112 25.013427"
			version="1.1"
			id="svg8"
		>
			<defs id="defs2" />
			<g id="layer1" transform="translate(312.85437,517.33411)">
				<filter id="blurMe">
					<feGaussianBlur in="SourceGraphic" stdDeviation="1" />
				</filter>
				<path
					mask="url(#hole)"
					style={{ fill: color, fillOpacity: "1" }}
					d="m -310.06006,-498.58309 c -1.59114,-3.62807 -2.79256,-6.66306 -2.79256,-6.66306 0,0 3.43418,-1.55602 7.50877,-3.37647 4.07458,-1.82044 9.94833,-4.45484 13.05277,-5.85423 6.53353,-2.94511 5.91859,-3.06875 7.9422,1.59692 0.81556,1.88036 1.61456,3.54716 1.61456,3.54716 0,0 -1.12317,0.49003 -2.06703,0.88408 -0.61417,0.25642 -1.22128,0.49085 -1.22128,0.49085 l -2.04079,-4.31668 -20.21821,8.90536 3.13226,6.45109 c 0,0 10.78779,-4.66876 23.34823,-10.32004 12.56044,-5.65129 23.04902,-10.19377 23.30795,-10.0944 0.19784,-0.003 1.74099,3.02013 3.29345,6.4906 1.17751,2.6323 1.81002,4.59252 1.28029,5.90836 -0.74008,1.83839 -3.79996,2.82455 -11.26376,6.20974 -7.04336,3.1945 -12.3729,5.6158 -13.11805,5.71339 -0.4309,0.038 -0.8551,-0.32059 -1.31618,-1.11797 -0.19275,-0.33333 -2.91645,-6.17712 -2.91645,-6.17712 l 2.98778,-1.39366 2.17013,4.16465 19.8209,-8.90188 -2.65072,-6.41118 c 0,0 -12.07808,5.36747 -27.32278,12.25941 -4.94771,2.23679 -9.49742,4.27899 -10.11047,4.53821 -0.61305,0.25922 -2.65483,1.19097 -4.53728,2.07056 -5.38449,2.51593 -4.54677,3.00512 -7.88373,-4.60369 z"
					id="path56"
				/>
				<mask id="hole">
					<path
						xmlns="http://www.w3.org/2000/svg"
						d="m -283.30226,-508.76903 -3.2976,-5.76732 -22.12238,10.23045 3.64175,9.71793 46.54237,-20.49431 6.17857,9.58619 -22.74206,10.21346 -3.04954,-4.75108 -0.007,-1.5145"
						id="path"
						style={{
							fill: "none",
							stroke: "white",
							strokeWidth: "9px",
							strokeLinejoin: "round",
							strokeOpacity: "1",
							strokeDasharray: "35",
							strokeDashoffset: "10",
						}}
					>
						<animate
							xmlns="http://www.w3.org/2000/svg"
							id="firstanim"
							attributeType="CSS"
							attributeName="stroke-dashoffset"
							from="0"
							to="140"
							dur="3s"
							repeatCount="indefinite"
						/>
					</path>
				</mask>
			</g>
		</svg>
	);
};

export const LivetoMiniLogo = props => {
	const color = props.color ? props.color : "#fff";
	const size = props.size ? props.size : 150;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 49.795819 21.289088"
			version="1.1"
			id="svg8"
		>
			<path
				style={{ fill: color, fillOpacity: "1", strokeWidth: "0.0846666" }}
				d="M 5.03326,21.213152 C 4.9312,21.169662 4.79243,21.046299 4.72488,20.939019 4.53359,20.635212 0,10.205639 0,10.205639 c 0,0 20.8687,-9.44925761 21.45846,-9.60327561 0.35892,-0.09373 0.66329,-0.0068 0.85394,0.21556 0.1479,0.172352 2.77024,6.25782701 2.77024,6.25782701 l -3.07591,1.382309 -1.5548,-3.574841 -15.86811,7.2150356 2.14307,4.793376 c 0,0 8.5019,-3.795526 18.78875,-8.4345066 16.72027,-7.54020901 18.2107,-8.25202501 18.46926,-8.35387901 0.59541,-0.234544 0.88909,-0.01611 0.96146,0.129455 0.0596,0.119798 4.84946,10.68508561 4.84946,10.68508561 -10.21066,4.648656 -18.39215,8.421179 -21.50903,9.860153 -0.50428,0.19454 -0.90029,-0.292483 -1.01008,-0.535402 -0.1295,-0.286521 -2.7159,-6.085221 -2.7159,-6.085221 l 3.07961,-1.384806 1.69301,3.686818 c 5.2927,-2.342757 10.57884,-4.864381 15.8673,-7.2168336 l -2.19554,-4.811171 c 0,0 -11.7919,5.294142 -26.7797,12.0794986 -5.7277,2.593076 -10.54482,4.666833 -10.71034,4.748054 -0.14724,0.07225 -0.37983,-0.0022 -0.48189,-0.04571 z"
				id="path57"
			/>
		</svg>
	);
};

export const IconGoogle = props => {
	const size = props.size ? props.size : 40;
	const className = props.className ? props.className : "";
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 48 48"
			width={size}
			height={size}
		>
			<defs>
				<path
					id="a"
					d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
				/>
			</defs>
			<clipPath id="b">
				<use xlinkHref="#a" overflow="visible" />
			</clipPath>
			<path clipPath="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z" />
			<path clipPath="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z" />
			<path clipPath="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z" />
			<path clipPath="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z" />
		</svg>
	);
};
