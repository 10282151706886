import { LivetoLoader } from "components/icons";
import { IconTimes } from "components/icons";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useSaveTemplate } from "../../apiCalls";

export const SaveTemplateModal = props => {
    const { showTemplateSaveModal, setShowTemplateSaveModal, room, addSavedTemplate } = props;
    const organizer = useSelector(state => state.event.organizer.slug)
    const dispatch = useDispatch()
    function close() {
        setShowTemplateSaveModal(false);
    }

    function saveTemplate() {
        setData({
            name: templateName,
            components: room.components,
            styles: room.styles
        })
        setSave(true);
    }

    const [data, setData] = useState(null);
    const [save, setSave] = useState(false);
    const [templateName, setTemplateName] = useState('');

    const [status, loading, template] = useSaveTemplate(save, organizer, data);
    let message = '';
    switch (status) {
        case 201: {
            if (template) addSavedTemplate(template)
            message = 'Success!';
            break;
        }
        case 403: message = 'Failed'; break;
        default: message = ''; break;
    }
    useEffect(() => {
        if (status === 201) {
            setTimeout(() => {
                setSave(false);
                setData(null)
                dispatch({ type: 'HANDLE_RIGHT_NAV', payload: false })
                setShowTemplateSaveModal(false)
            }, 1000)

        }
    }, [status])// eslint-disable-line react-hooks/exhaustive-deps
    return <Modal centered show={showTemplateSaveModal} onHide={close} contentClassName="save-template-modal" backdropClassName="modalbg">
        <Modal.Header className="save-template-modal-header">
            <div className="template-modal-heading">Save template</div>
            <button className="close-button" onClick={() => close()}><IconTimes /></button>
        </Modal.Header>
        <div className="save-tempate-modal-body border-bottom">
            Name for this template
            <input type="text" className="modal-input" placeholder="name..." onChange={e => setTemplateName(e.target.value)} />
        </div>
        <div className="save-tempate-modal-footer">
            <div className="status-message">{!loading && status !== null && save && message}</div>
            <button onClick={() => saveTemplate()} className="save-button">{loading ? <LivetoLoader color="#c4281a" size="40" /> : 'Save template'}</button>
        </div>

    </Modal>
}

export default SaveTemplateModal