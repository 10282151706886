import { Localized } from "@fluent/react"
import moment from "moment"

export const calculateWhen = (date) => {
  if (moment().diff(moment(date), 'years') === 1)
    return <Localized id="notification-center-when-year">
      A year ago
    </Localized>
  else if (moment().diff(moment(date), 'years') > 1)
    return <Localized id="notification-center-when-years" vars={{ length: moment().diff(moment(date), 'years') }}>
      {'{$length} years ago'}
    </Localized>
  else if (moment().diff(moment(date), 'months') === 1) {
    return (
      <Localized id="notification-center-when-month">
        A month ago
      </Localized>
    )
  }
  else if (moment().diff(moment(date), 'months') > 1) {
    return (
      <Localized id="notification-center-when-months" vars={{ length: moment().diff(moment(date), 'months') }}>
        {'{$length} months ago'}
      </Localized>
    )
  }
  else if (moment().diff(moment(date), 'days') === 1) {
    return (
      <Localized id="notification-center-when-day">
        A day ago
      </Localized>
    )
  }
    
  else if (moment().diff(moment(date), 'days') > 1) {
    return (
      <Localized id="notification-center-when-days" vars={{ length: moment().diff(moment(date), 'days') }}>
        {'{$length} days ago'}
      </Localized>
    )
  }
  else if (moment().diff(moment(date), 'hours') === 1) {
    return (
      <Localized id="notification-center-when-hour">
        An hour ago
      </Localized>
    )
  }
    
  else if (moment().diff(moment(date), 'hours') > 1) {
    return (
      <Localized id="notification-center-when-hours" vars={{ length: moment().diff(moment(date), 'hours') }}>
        {'{$length} hours ago'}
      </Localized>
    )
  }
  
  else if (moment().diff(moment(date), 'minutes') === 1)
    return <Localized id="notification-center-when-minute">
      A minute ago
    </Localized>
  else if (moment().diff(moment(date), 'minutes') > 1)
  return <Localized id="notification-center-when-minutes" vars={{ length: moment().diff(moment(date), 'minutes') }}>
    {'{$length} minutes ago'}
  </Localized>
  else if (moment().diff(moment(date), 'minutes') < 1)
    return <Localized id="notification-center-when-now">
      Just now
    </Localized>
}