/** @format */

import {
	REQUEST_CLIENT_TOKEN,
	REQUEST_JOIN_ROOM,
	SEND_MESSAGE,
	REQUEST_CHAT_HISTORY,
	REQUEST_ATTENDEES,
	HEARTBEAT,
	RECONNECT,
	DELETE_MESSAGE,
	DELETE_ALL_USER_MESSAGES,
	SILENCE_USER,
	VOTE_FOR_POLL,
	REQUEST_POLL_RESULTS,
	SEND_PRIVATE_MESSAGE,
	UPDATE_LAST_SEEN,
	WRTC_RING,
	WRTC_RING_ACCEPT,
	WRTC_RING_REJECT,
	WRTC_SEND_SIGNAL,
	WRTC_HANGUP,
	REACT_TO_MESSAGE,
	APPROVE_HELD_MSG,
	DELETE_HELD_MSG,
	REQUEST_HELD_MESSAGES,
	CHANGE_ROOM_SETTING,
	GET_MODERATION_SETTINGS,
} from "./socketRequests";

import * as a from "actiontypes";
import { getCookie } from "util/cookies";
import { AUTH_COOKIE, WS_HOST, HOST } from "constants.js";
//import { reAuthenticate, requestClientToken, requestChatHistory } from "./actions";

// import ReconnectingWebSocket from "reconnecting-websocket";
import SignallingClient from "../util/signallingClient";
import { sendAlert } from "util/functions";
import { Localized } from "@fluent/react";
import { makeSubstring } from "containers/VirtualEvent/editorView/helpers/mainToolbarHelpers";
import {
	fetchSingleSetting,
	getUserHeldMessages,
} from "containers/VirtualEvent/signallingCalls";
// import SignallingClient from '../util/sig/';

const HEARTBEAT_INTERVAL = 7000;

export async function chatServiceNotify(msg) {
	// console.log('chatServiceNotify', msg);
	return await SignallingClient.ServiceInvoker.notifyService("chat", "push", msg);
}

export async function chatServiceInvoke(method, msg) {
	// console.log('chatServiceNotify', msg);
	return await SignallingClient.ServiceInvoker.invokeService("chat", method, msg);
}
async function pollServiceInvoke(func, ...args) {
  // console.log('pollServiceInvoke', func, ...args);
  return await SignallingClient.ServiceInvoker.invokeService("polls", func, ...args);
}

async function pollServiceNotify(func, ...args) {
  // console.log('pollServiceNotify', func, ...args);
  return await SignallingClient.ServiceInvoker.notifyService("polls", func, ...args);
}

async function pollServicePush(msg) {
  // console.log('pollServicePush', msg);
  return await pollServiceNotify("push", msg);
}

async function engagementServiceInvoke(method, ...args) {
	try {
		return await SignallingClient.ServiceInvoker.invokeService("engagement", method, ...args);
	} catch (err) {
		console.error("engagementServiceInvoke", method, args, err);
		throw err;
	}
}

async function engagementServiceNotify(method, ...args) {
	try {
		return await SignallingClient.ServiceInvoker.notifyService("engagement", method, ...args);
	} catch (err) {
		console.error("engagementServiceNotify", method, args, err);
		throw err;
	}
}

async function notificationServiceInvoke(method, ...args) {
	try {
		return await SignallingClient.ServiceInvoker.invokeService("notification", method, ...args);
	} catch (err) {
		console.error("notificationServiceInvoke", method, args, err);
		throw err;
	}
}

async function notificationServiceNotify(method, ...args) {
	try {
		return await SignallingClient.ServiceInvoker.notifyService("notification", method, ...args);
	} catch (err) {
		console.error("notificationServiceNotify", method, args, err);
		throw err;
	}
}

export async function addNotification(notification) {
	console.log("addNotification notification:", notification);
	try {
		return await notificationServiceInvoke("addOwnNotification", notification);
	} catch (error) {
		console.log("addNotification error: ", error);
	}
}

export async function removeNotification(notificationID) {
	try {
		return await notificationServiceInvoke("removeOwnNotificationByID", notificationID);
	} catch (error) {
		console.log("removeNotification error: ", error);
	}
}

export async function markNotificationAsRead(notificationID) {
	try {
		return await notificationServiceInvoke("setOwnNotificationReadByID", notificationID);
	} catch (err) {
		console.log("setOwnNotificationReadByID error:", err);
	}
}

async function pokeWatchTimer(event) {
	try {
		return await engagementServiceInvoke("pokeStreamWatchingRunningScore", event);
	} catch (error) {
		console.log("pokeWatchTimer error:", error);
	}
	return false;
}

export async function resetUserFromLeaderboard(event, user) {
	return await engagementServiceInvoke("clearUserInLeaderboard", event, user);
}

let watchTimer = null;
export async function setWatchTimer(event) {
	clearWatchTimer();

	if (!SignallingClient.isAuthed) return false;

	let msLeft = 15000;
	try {
		msLeft = await pokeWatchTimer(event);
	} catch (error) {
		console.warn("setWatchTimer pokeWatchTimer", error.message);
		msLeft = false;
	}

	if (msLeft === false) return;

	watchTimer = setTimeout(() => {
		setWatchTimer(event);
	}, msLeft);
}

export async function clearWatchTimer() {
	if (watchTimer) {
		clearTimeout(watchTimer);
		watchTimer = null;
	}
}

export default function websocketMiddleware({ dispatch, getState }) {
	let ws = null;
	let heartBeat;

	let batchedMessages = [];
	let batchedMessagesTimer = null;

	function addMessageToBatch(payload) {
		// Add message to buffer
		batchedMessages.push(payload);
		// console.log('Msg Batch Push', batchedMessages.length);
		// Set timer if it does not exist
		if (!batchedMessagesTimer) batchedMessagesTimer = requestAnimationFrame(applyMessageBatch);
	}

	function applyMessageBatch() {
		// Copy and reset the buffer and timer holder
		const copy = [...batchedMessages];
		// console.log('Msg Batch Apply', copy.length);
		batchedMessages = [];
		batchedMessagesTimer = null;
		// Actually dispatch the batched version
		dispatch({ type: a.WS_MESSAGE_RECEIVED_BATCH, payload: copy });
	}

	const restore_inboxes = inboxes => {
		const current = getState().inbox;
		for (const [receiver_id, inbox] of Object.entries(inboxes)) {
			if (!inbox.preview_message || !inbox.preview_message.length) continue; // history is gone so no point in showing the room before new messages
			const personal_inbox =
				current.private_messages && current.private_messages.find(prof => prof.user_id === receiver_id);
			const message = inbox.preview_message[0];
			if (personal_inbox) {
				if (!personal_inbox.preview_message || personal_inbox.preview_message.message !== message.message) {
					dispatch({ type: a.WS_NEW_PRIVATE_MESSAGE, payload: { receiver_id, message } });
				}
			} else {
				dispatch({ type: a.WS_CREATE_NEW_INBOX, payload: { inbox, message } });
			}
		}
	};

	const onOpen = (dispatch, allow_reconnect) => event => {
		const api_token = getCookie(AUTH_COOKIE);
		if (!api_token) return dispatch({ type: a.WS_RESET_APPLICATION }); // TODO: a more correct action to dispatch? Not that this should happen normally
		const user = getState().websocketUser;

		// Dont reconnect when:
		// - Changing event
		// - Coming to your first event, event = null
		if (user.channel_name && user.user_id && allow_reconnect) {
			let payload = { channel_name: user.channel_name, user_id: user.user_id };
			payload["api_token"] = api_token;
			chatServiceNotify(RECONNECT(payload));
		} else {
			dispatch({ type: a.WS_REQUEST_CLIENT_TOKEN });
		}
		heartBeat = setInterval(() => {
			dispatch({ type: a.WS_SEND_HEARTBEAT });
			chatServiceNotify(HEARTBEAT());
		}, HEARTBEAT_INTERVAL);
	};

	const onNotification = dispatch => msg => {
		const event = getState().event.slug;
		const message = typeof msg === "string" ? JSON.parse(msg) : msg;
		const { user = {} } = getState();
		const { chat_user_id: myChatID, notification_settings: settings = {} } = user;

		switch (message.type) {
			case "newNotification": {
				if (message.notification.event === event) {
					sendAlert(myChatID, message, dispatch, settings);
				}
				break;
			}

			case "removedNotification": {
				dispatch({ type: a.WS_REMOVE_NOTIFICATION, payload: message.notificationID });
				break;
			}

			case "readNotification": {
				dispatch({ type: a.WS_MARK_NOTIFICATION_AS_READ, payload: message.notificationID });
				break;
			}

			default:
				break;
		}
	};

	const onEngagement = dispatch => msg => {
		const isEngagementUnlocked = getState().lobby.data.features.engagement;
		const myChatID = getState().user.chat_user_id;

		if (isEngagementUnlocked) {
			let message = typeof msg === "string" ? JSON.parse(msg) : msg;
			const isGuest = getState().user.guestuser;
			const event = getState().event.slug;

			switch (message.type) {
				case "OwnTotalXPChanged":
					dispatch({ type: a.WS_ENGAGEMENT_MY_TOTAL_XP_CHANGE, payload: message });
					//console.log('Engagement OwnTotalXPChanged', message.xp, '(+' + message.value + ')');
					break;

				case "OwnEventXPChanged":
					dispatch({ type: a.WS_ENGAGEMENT_MY_TOTAL_EVENT_XP_CHANGE, payload: message });
					//console.log('Engagement OwnEventXPChanged', message.eventName, message.xp, '(+' + message.value + ')');
					break;

				case "OwnEventLevelChanged":
					dispatch({ type: a.WS_ENGAGEMENT_MY_EVENT_LEVEL_CHANGE, payload: message });
					if (!isGuest && message.level !== 1) {
						dispatch({
							type: a.ADD_ALERT,
							payload: {
								variant: "info",
								type: message.level === 10 ? "ENGAGEMENT_NEW_LEVEL_MAX" : "ENGAGEMENT_NEW_LEVEL",
								data: message.level,
								setting: "engagement",
							},
						});
					}
					break;

				case "EventAchievementUnlocked":
					dispatch({ type: a.WS_EVENT_ACHIEVEMENT_UNLOCKED, payload: message });
					if (!isGuest) {
						dispatch({
							type: a.ADD_ALERT,
							payload: {
								variant: "info",
								type: "ENGAGEMENT_NEW_ACHIEVEMENT",
								data: message.achievementInfo,
								setting: "engagement",
							},
						});
					}
					break;

				case "LeaderboardXPChanged":
					dispatch({ type: a.WS_ENGAGEMENT_LEADERBOARD_CHANGE, payload: message });
					//console.log('Engagement LeaderboardXPChanged', message.eventName, message.userID, message.position, message.level, message.xp, '(+' + message.value + ')');
					break;

				case "OwnLeaderboardPositionChange":
					dispatch({ type: a.WS_OWN_POSITION_CHANGE, payload: message });
					//console.log('Engagement OwnLeaderboardPositionChange', message.eventName, message.userID, message.position, message.level, message.xp, '(+' + message.value + ')');
					break;

				case "removeFromLeaderboard":
					dispatch({
						type: a.ENGAGEMENT_REMOVE_PERSON_FROM_LEADERBOARD,
						payload: { userID: message.userID, myChatID },
					});
					break;

				default:
					console.log("Unhandled Engagement Callback:", message);
					break;
			}
		}
	};

	const onMessage = dispatch => msg => {
		let message = typeof msg === "string" ? JSON.parse(msg) : msg;
		message.type = message.type.toUpperCase();

		switch (message.type) {
			case a.WS_SEND_MESSAGE_FAILED:
				if (message.payload.type === "restricted")
					dispatch({
						type: a.SET_CHATROOM_RESTRICTED,
						payload: { room_id: getState().connection.active_channel, restricted: true },
					});
				dispatch({
					type: a.ADD_ALERT,
					payload: {
						variant: "error",
						type: "SEND_MESSAGE_FAILED",
						data: message.payload.error,
						setting: "other",
					},
				});
				break;

			case a.WS_HEARTBEAT_RECEIVED:
				dispatch({ type: a.WS_HEARTBEAT_RECEIVED, payload: message.payload });
				break;

			// AUTH LOGIC
			case a.WS_CLIENT_TOKEN_REQUESTED:
				dispatch({ type: a.WS_SET_USER, payload: message.payload });

				restore_inboxes(message.payload.inboxes);
				chatServiceNotify(REQUEST_ATTENDEES());
				chatServiceNotify(REQUEST_POLL_RESULTS());
				// JOIN MAIN ROOM
				const main_room_id = getState().chatrooms.find(r => r.main_room === true).room_id;
				const chat_room_id = getState().room?.data?.chat_room;
				const roomID = chat_room_id || main_room_id;
				dispatch({ type: a.WS_JOIN_ROOM, payload: { room_id: roomID } });
				break;

			case a.WS_RECONNECTED:
				dispatch({ type: a.WS_RECONNECTED, payload: message.payload });
				chatServiceNotify(REQUEST_ATTENDEES());
				chatServiceNotify(REQUEST_POLL_RESULTS());

				let rooms_to_ignore = [];
				// Map inboxes to ignore
				for (let key in message.payload.inboxes) {
					rooms_to_ignore.push(message.payload.inboxes[key].room_id);
				}
				for (let room of message.payload.joined_rooms) {
					if (!rooms_to_ignore.includes(room)) {
						chatServiceNotify(
							REQUEST_CHAT_HISTORY({ room_id: room, user_id: getState().websocketUser_id })
						);
					}
				}
				dispatch({ type: a.WS_CHANGE_VIEW, payload: "chat" });
				restore_inboxes(message.payload.inboxes);
				break;

			case a.WS_RECONNECT_FAILED:
				dispatch({ type: a.WS_RESET_APPLICATION, payload: SignallingClient.isConnected ? true : false });
				if (getCookie(AUTH_COOKIE)) {
					dispatch({ type: a.WS_REQUEST_CLIENT_TOKEN });
				}
				break;

			case a.WS_ATTENDEES_REQUESTED:
				dispatch({ type: a.WS_ATTENDEES_REQUESTED, payload: message.payload });
				break;

			// ROOM LOGIC
			case a.WS_ROOM_JOINED:
				// Check if the room is a private 1-to-1 conversation
				if (message.payload.is_private_chat) {
					dispatch({ type: a.WS_PRIVATE_ROOM_JOINED, payload: message.payload });
				} else {
					dispatch({ type: a.WS_ROOM_JOINED, payload: message.payload });
				}

				break;

			case a.WS_ROOM_JOIN_FAILED:
				dispatch({ type: a.WS_ROOM_JOIN_FAILED, payload: message.payload });
				break;

			case a.WS_USER_JOINED_CHANNEL:
				dispatch({ type: a.WS_USER_JOINED_CHANNEL, payload: message.payload });
				break;
			// Another user has joined a channel this client has already joined
			case a.WS_USER_JOINED_ROOM:
				dispatch({ type: a.WS_USER_JOINED_ROOM, payload: message.payload });
				break;
			case a.WS_JOIN_ROOM_FAILED:
				dispatch({ type: a.WS_JOIN_ROOM_FAILED, payload: message.payload });
				break;

			// CHAT LOGIC
			case a.WS_MESSAGE_SENT:
				// dispatch({ type: a.WS_MESSAGE_RECEIVED, payload: message.payload });
				addMessageToBatch(message.payload);
				break;

			// Response from websocket server when user has requested chat history for specific room
			case a.WS_CHAT_HISTORY_REQUESTED:
				const { room_id } = message.payload;
				const { user_id } = getState().websocketUser;
				const { slug } = getState().event;
				dispatch({ type: a.WS_UPDATE_CHAT_HISTORY, payload: message.payload });
				getUserHeldMessages(room_id, user_id, dispatch);
				fetchSingleSetting(room_id, slug, "allow_anonymous", dispatch, a.SET_ANONYMOUS_MODE);
				fetchSingleSetting(room_id, slug, "slowmode", dispatch, a.SET_SLOWMODE);
				fetchSingleSetting(room_id, slug, "restricted", dispatch, a.SET_RESTRICTED);
				break;

			case a.WS_MESSAGE_REACTION:
				dispatch({ type: a.WS_MESSAGE_REACTION, payload: message.payload });
				break;

			case a.WS_POLLS_REQUESTED:
				dispatch({ type: a.WS_POLLS_REQUESTED, payload: message.payload });
				break;

			case a.WS_POLL_VOTED:
				dispatch({ type: a.WS_POLL_VOTED, payload: message.payload });
				break;

			case a.WS_VOTE_FOR_POLL:
				dispatch({ type: a.WS_VOTE_FOR_POLL, payload: message.payload });
				break;

			case a.WS_PRIVATE_MESSAGE_NOTICE: {
				const inbox = getState().inbox;

				const my_user_id = getState().websocketUser.user_id;
				const sender = message.payload.sender_id;
				const receiver = message.payload.receiver_id;

				// Check which party is sending the message, and map the message to correct inbox
				let inbox_key = sender;
				if (sender === my_user_id) {
					inbox_key = receiver;
				}

				const new_message = {
					receiver_id: message.payload.receiver_id,
					message: message.payload.message,
					sender_id: message.payload.sender_id,
					timestamp: message.payload.timestamp,
					message_id: message.payload.message_id,
					seen: false,
				};

				// If inbox is found already, just push the new message to inbox, otherwise create new inbox
				const personal_inbox = inbox.private_messages.find(prof => prof.user_id === inbox_key);
				if (personal_inbox) {
					let incoming_message = message.payload;
					dispatch({ type: a.WS_NEW_PRIVATE_MESSAGE, payload: { inbox_key, incoming_message } });
				} else {
					// Create inbox data from the private_message_payload
					let new_inbox = message.payload.sender;
					if (new_inbox) {
						new_inbox.room_id = message.payload.room_id;
						new_inbox.user_id = inbox_key;
						dispatch({ type: a.WS_CREATE_NEW_INBOX, payload: { inbox: new_inbox, message: new_message } });
					}
				}
				// Notifying the receiver
				const { active_profile } = getState().inbox;
				const isInboxOpen = active_profile === sender;
				if (inbox_key === sender && !isInboxOpen) {
					const senderAttendee = getState().all_attendees.find(att => att.user_id === sender);
					dispatch({
						type: a.ADD_ALERT,
						payload: {
							variant: "info",
							type: "PRIVATE_MSG_RECEIVED",
							data: { message: new_message.message, sender: senderAttendee },
							setting: "direct_message",
						},
					});
				}
				break;
			}

			//MODERATION

			case a.WS_ALL_USER_MESSAGES_DELETED: {
				dispatch({ type: a.WS_ALL_USER_MESSAGES_DELETED, payload: message.payload });
				break;
			}
			case a.WS_MESSAGE_DELETED: {
				dispatch({ type: a.WS_MESSAGE_DELETED, payload: message.payload });
				break;
			}

			case a.WS_USER_SILENCED: {
				dispatch({ type: a.WS_USER_SILENCED, payload: message.payload });
				break;
			}

			case a.WS_MOD_HELD_MESSAGES_REQUESTED: {
				dispatch({ type: a.WS_HELD_ROOM_MESSAGES, payload: message.payload });
				break;
			}

			case a.WS_MOD_HELD_MSG: {
				dispatch({ type: a.WS_MOD_HELD_MSG, payload: message.payload });
				break;
			}

			case a.WS_MOD_HELD_MSG_SENT: {
				dispatch({ type: a.WS_MOD_HELD_MSG_SENT, payload: message.payload });
				break;
			}

			case a.WS_MOD_HELD_MSG_DELETED: {
				dispatch({ type: a.WS_MOD_HELD_MSG_DELETED, payload: message.payload });
				break;
			}

			case a.WS_CHAT_MESSAGE_HELD: {
				//dispatch({ type: a.WS_MOD_HELD_MSG_DELETED, payload: message.payload });
				const msg = message.payload;
				msg.held = true;
				msg.timestamp = msg.timestamp_recv;
				dispatch({ type: a.WS_MESSAGE_RECEIVED, payload: msg });
				break;
			}

			case a.WS_HELD_MESSAGE_DECLINED: {
				const { message: msg = "" } = message.payload;
				const data = {
					message: (
						<Localized
							id="push-notification-message-declined"
							vars={{ declinedMsg: makeSubstring(msg, 0, 20, 20) }}
						/>
					),
					title: <Localized id="push-notification-message-declined-title" />,
				};
				dispatch({ type: a.WS_HELD_MESSAGE_DELETED, payload: message.payload });
				dispatch({ type: a.ADD_ALERT, payload: { variant: "info", type: "GENERIC", data, setting: "other" } });
				break;
			}

			case a.WS_CHAT_MESSAGE_HELD_SENT: {
				dispatch({ type: a.WS_HELD_MESSAGE_DELETED, payload: message.payload });
				break;
			}

			case a.WS_CHATROOM_SETTING_CHANGED: {
				const { room_id, roomSettings, globalSettings } = message.payload;
				if (roomSettings.anonymous_mode !== undefined) {
					dispatch({
						type: a.SET_ANONYMOUS_MODE,
						payload: { [room_id]: roomSettings.anonymous_mode, global: globalSettings.anonymous_mode },
					});
				}
				if (roomSettings.slowmode !== undefined) {
					dispatch({
						type: a.SET_SLOWMODE,
						payload: { [room_id]: roomSettings.slowmode, global: globalSettings.slowmode },
					});
				}
				if (roomSettings.restricted !== undefined) {
					const restricted =
						roomSettings.restricted === null ? globalSettings.restricted : roomSettings.restricted;
					dispatch({
						type: a.SET_CHATROOM_RESTRICTED,
						payload: { room_id, restricted },
					});
				}
				break;
			}

			case a.WS_CHAT_SETTING_CHANGED: {
				if (message.payload.restricted !== undefined) {
					const room_id = getState().connection.active_channel;
					const restrictedSettings = getState().connection.channelSettings;

					if (restrictedSettings[room_id] === null) {
						const restricted = message.payload.restricted;
						dispatch({
							type: a.SET_CHATROOM_RESTRICTED,
							payload: { room_id, restricted },
						});
					}
				}
				if (message.payload.anonymous_mode !== undefined) {
					dispatch({
						type: a.WS_CHAT_ANONYMOUS_MODE_CHANGED,
						payload: message.payload.anonymous_mode,
					});
				}
				if (message.payload.slowmode !== undefined) {
					dispatch({
						type: a.WS_CHAT_SLOWMODE_MODE_CHANGED,
						payload: message.payload.slowmode,
					});
				}

				break;
			}

			case a.WS_CHAT_ANONYMOUS_MODE_CHANGED: {
				dispatch({ type: a.SET_GLOBAL_ANONYMOUS_MODE, payload: message.payload });
				break;
			}

			case a.WS_CHAT_SLOWMODE_MODE_CHANGED: {
				dispatch({ type: a.SET_GLOBAL_SLOWMODE, payload: message.payload });
				break;
			}

			// WEBRTC
			case a.WRTC_RINGING: {
				if (message.payload.unring) {
					if (getState().webrtc.busy && !getState().webrtc.accepted) {
						dispatch({ type: a.WRTC_ENDED, payload: message.payload });
					}
				} else {
					console.log("BUSY:", getState().webrtc.busy);
					if (getState().webrtc.busy) {
						// console.warn('WRTC_RINGING, but busy!');
						message.payload.userBusy = true;
						chatServiceNotify(WRTC_RING_REJECT(message.payload));
						return;
					}
					dispatch({ type: a.WRTC_RINGING, payload: message.payload });
				}
				break;
			}
			case "WRTC_SIGNAL": {
				const payload = message.payload;
				//console.log('WRTC_SIGNAL', payload);
				if (payload.sub === "ring_answer") {
					if (payload.accept) {
						dispatch({ type: a.WRTC_RING_ACCEPTED, payload: payload });
						//store.dispatch(ringAccepted(payload));
					} else {
						if (payload.userBusy) {
							dispatch({ type: a.WRTC_USER_BUSY, payload: true });
						} else {
							dispatch({ type: a.WRTC_RING_REJECTED, payload: payload });
						}
						//store.dispatch(ringRejected(payload));
					}
				} else if (payload.sub === "hangup") {
					dispatch({ type: a.WRTC_REMOTE_HANGUP, payload: payload });
					//store.dispatch(remoteHangup(payload));
				} else {
					dispatch({ type: a.WRTC_RECV_SIGNAL, payload: payload });
					//store.dispatch(recvSignal(payload));
				}
				break;
			}

			default:
				break;
		}
	};

	const onClose = dispatch => event => {
		dispatch({ type: a.WS_CLOSE_CONNECTION });
		clearInterval(heartBeat);
	};

	const onError = dispatch => event => {
		console.log(event);
	};

	return next => action => {
		switch (action.type) {
			case "WEBSOCKET::POPULATE_CONFIGURATIONS":
				const event = getState().connection.event;
				const allow_reconnect = event !== null && event === action.payload.event;

				SignallingClient.on("open", onOpen(dispatch, allow_reconnect));
				SignallingClient.on("close", onClose(dispatch));
				SignallingClient.on("error", onError(dispatch));
				SignallingClient.exposeRPCMethod("chatCallback", onMessage(dispatch), { replace: true });
				SignallingClient.exposeRPCMethod("engagementCallback", onEngagement(dispatch), { replace: true });
				SignallingClient.exposeRPCMethod("notificationCallback", onNotification(dispatch), { replace: true });
				SignallingClient.init({ signallingURL: `${WS_HOST}`, token: getCookie(AUTH_COOKIE) });

				SignallingClient.on("authed", async () => {
					const event = getState().connection.event || action.payload.event;
					// Subscribe to updates test
					engagementServiceNotify("subscribeToOwnTotalScore"); // Global XP updates
					engagementServiceNotify("subscribeToOwnEventScore", event); // Event XP updates + Achievements + Level increases
					engagementServiceNotify("subscribeToLeaderboard", event); // Event leaderboard changes

					notificationServiceNotify("subscribeToOwnNotifications", event); // Subscribe to notifications

					// Current level thresholds
					// const eventLevels = await engagementServiceInvoke('getEventLevelThresholds', event);
					engagementServiceInvoke("getEventLevelThresholds", event).then(eventLevels => {
						dispatch({ type: a.ENGAGEMENT_GET_LEVEL_THRESHOLDS, payload: eventLevels });
						//console.log('onAuthed getEventLevelThresholds', eventLevels);
					});

					// Current score
					// const ownEventScore = await engagementServiceInvoke('getOwnEventScore', event);
					engagementServiceInvoke("getOwnEventScore", event).then(ownEventScore => {
						dispatch({ type: a.ENGAGEMENT_GET_INITIAL_TOTAL_EVENT_XP, payload: ownEventScore });
						//console.log('onAuthed ownEventScore', ownEventScore);
					});

					// Current leaderboard
					// const eventLeaderboard = await engagementServiceInvoke('getCurrentLeaderboard', event);
					engagementServiceInvoke("getCurrentLeaderboard", event).then(eventLeaderboard => {
						dispatch({ type: a.ENGAGEMENT_GET_CURRENT_LEADERBOARD, payload: eventLeaderboard });
						//console.log('onAuthed eventLeaderboard', eventLeaderboard);
					});

					// Current score total
					// const ownTotalScore = await engagementServiceInvoke('getOwnTotalScore');
					engagementServiceInvoke("getOwnTotalScore").then(ownTotalScore => {
						dispatch({ type: a.ENGAGEMENT_GET_INITIAL_TOTAL_XP, payload: ownTotalScore });
						//console.log('onAuthed ownTotalScore', ownTotalScore);
					});

					// Current own level
					// const ownLevel = await engagementServiceInvoke('getOwnEventLevel', event);
					engagementServiceInvoke("getOwnEventLevel", event).then(ownLevel => {
						dispatch({ type: a.ENGAGEMENT_GET_MY_LEVEL, payload: ownLevel });
						//console.log('onAuthed getOwnEventLevel', ownLevel);
					});

					// Current own postition
					// const ownPosition = await engagementServiceInvoke('getOwnLeaderboardPosition', event);
					engagementServiceInvoke("getOwnLeaderboardPosition", event).then(ownPosition => {
						dispatch({ type: a.ENGAGEMENT_GET_MY_POSITION, payload: ownPosition });
						//console.log('onAuthed getOwnLeaderboardPosition', ownPosition);
					});

					// Current own achievements
					// const ownAchievements = await engagementServiceInvoke('getOwnAchievements', event);
					engagementServiceInvoke("getOwnAchievements", event).then(ownAchievements => {
						dispatch({ type: a.ENGAGEMENT_GET_ACHIEVEMENTS, payload: ownAchievements });
						//console.log('onAuthed getOwnAchievements', ownAchievements);
					});

					// Get own notification map
					notificationServiceInvoke("getOwnNotificationMap").then(ownNotificationMap => {
						//console.log('onAuthed ownNotificationMap', ownNotificationMap);
					});

					// Get own notifications, sorted
					notificationServiceInvoke("getOwnNotificationSortedArray").then(ownNotifications => {
						//console.log('onAuthed ownNotifications', ownNotifications);
						const filteredByEvent = ownNotifications.filter(notification => notification.event === event);
						dispatch({ type: a.WS_GET_NOTIFICATIONS, payload: filteredByEvent });
					});
				});

				// ws = new ReconnectingWebSocket(`${WS_HOST}${action.payload.event}/`);
				// ws.onopen = onOpen(dispatch, allow_reconnect);
				// ws.onclose = onClose(dispatch);
				// ws.onmessage = onMessage(dispatch);
				// ws.onerror = onError(dispatch);
				break;

			case a.WS_RECONNECT: {
				if (SignallingClient.isConnected) {
					let payload = {
						channel_name: getState().websocketUser.channel_name,
						user_id: getState().websocketUser.user_id,
					};
					const api_token = getCookie(AUTH_COOKIE);
					payload["api_token"] = api_token;
					SignallingClient.updateToken(api_token);
					chatServiceNotify(RECONNECT(payload));
				}
				break;
			}
			case a.WS_REQUEST_CLIENT_TOKEN:
				const api_token = getCookie(AUTH_COOKIE);
				if (!api_token) {
					setTimeout(() => dispatch({ type: a.WS_RESET_APPLICATION }), 500); // TODO: a more correct action to dispatch? Not that this should happen normally
				} else if (SignallingClient.isConnected) {
					let payload = {};
					payload["api_token"] = api_token;
					const event = getState().connection.event;
					payload["event"] = event;
					SignallingClient.updateToken(api_token);
					chatServiceNotify(REQUEST_CLIENT_TOKEN(payload));
				} else {
					setTimeout(() => dispatch({ type: a.WS_REQUEST_CLIENT_TOKEN }), 500);
				}
				// Add logic to reauthenticate user
				break;

			case a.WS_REQUEST_CHAT_HISTORY:
				if (SignallingClient.isConnected && getState().websocketUser.signed_in) {
					chatServiceNotify(REQUEST_CHAT_HISTORY(action.payload));
				} else {
					setTimeout(() => dispatch({ type: a.WS_REQUEST_CHAT_HISTORY, payload: action.payload }), 2000);
				}
				break;

			case a.WS_JOIN_ROOM:
				if (SignallingClient.isConnected) {
					let payload = action.payload;
					payload.user_id = getState().websocketUser.user_id;
					chatServiceNotify(REQUEST_JOIN_ROOM(payload));
				}
				break;

			case a.WS_SEND_MESSAGE:
				chatServiceNotify(SEND_MESSAGE(action.payload));
				break;

			case a.WS_SEND_PRIVATE_MESSAGE:
				chatServiceNotify(SEND_PRIVATE_MESSAGE(action.payload));
				break;

			case a.WS_REQUEST_ATTENDEES:
				chatServiceNotify(REQUEST_ATTENDEES());
				break;

			case a.WS_REACT_WITH_EMOJI:
				chatServiceNotify(REACT_TO_MESSAGE(action.payload));
				break;

			// Notifications
			case a.WS_CLEAR_NOTIFICATION: {
				const payload = { message_id: action.payload.message_id, receiver_id: action.payload.user_id };
				chatServiceNotify(UPDATE_LAST_SEEN(payload));
				break;
			}

			// WEBRTC
			case a.WRTC_RING: // Ring a person by userID
				chatServiceNotify(WRTC_RING(action.payload));
				break;

			case a.WRTC_RING_ACCEPT: // Reply that a call was accepted, and by which channel_name
				chatServiceNotify(WRTC_RING_ACCEPT(action.payload));
				break;

			case a.WRTC_RING_REJECT: // Reply that a call was not accepted
				chatServiceNotify(WRTC_RING_REJECT(action.payload));
				break;

			case a.WRTC_SEND_SIGNAL: // Send signals back in forth, by channel_name
				chatServiceNotify(WRTC_SEND_SIGNAL(action.payload));
				break;

			case a.WRTC_HANGUP: // Send signals back in forth, by channel_name
				chatServiceNotify(WRTC_HANGUP(action.payload));
				break;

			// POLLS
			case a.WS_WANT_TO_VOTE_FOR_POLL:
				chatServiceNotify(VOTE_FOR_POLL(action.payload));
				break;

			case a.WS_REQUEST_POLL_RESULTS:
				chatServiceNotify(REQUEST_POLL_RESULTS());
				break;

			// MODERATOR THINGS
			case a.WS_DELETE_MESSAGE:
				if (getState().websocketUser.is_moderator) {
					chatServiceNotify(DELETE_MESSAGE(action.payload));
				}
				break;

			case a.WS_DELETE_ALL_USER_MESSAGES:
				if (getState().websocketUser.is_moderator) {
					chatServiceNotify(DELETE_ALL_USER_MESSAGES(action.payload));
				}
				break;

			case a.WS_SILENCE_USER:
				if (getState().websocketUser.is_moderator) {
					chatServiceNotify(SILENCE_USER(action.payload));
				}
				break;

			default:
				break;
		}
		return next(action);
	};
}
