import { connect } from 'react-redux';
import OngoingCall from './ongoingCall';
import Calling from './calling';
import WaitingForAnswer from './waitingForAnswer';

const mapStateToProps = state => ({
  busy: state.webrtc.busy,
  incoming: state.webrtc.incoming,
  accepted: state.webrtc.accepted,
})

const OneToOneVideoMeet = (props) => {
  const { darkmode, accepted, busy, incoming } = props
  const generateVideoCallComponent = () => {
    if (busy) {
      if (accepted) {
        return <OngoingCall darkmode={darkmode} />
      } else {
        return incoming ? <WaitingForAnswer darkmode={darkmode} /> : <Calling darkmode={darkmode} />;
      }
    } else {
      return <div></div>
    }
  }
  return (
    <>
      {generateVideoCallComponent()}
    </>
  )
}

export default connect(mapStateToProps)(OneToOneVideoMeet);