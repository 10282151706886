import { APP_PATHS } from 'constants.js';
import { ReactSVG } from 'react-svg';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { RoomsIcon } from 'components/icons';
import { useCurrentLanguage } from 'util/localization';
import { SettingContext } from 'containers/VirtualEvent/contexts';
import { useSelector } from 'react-redux';

const SiteMap = (props) => {
    const { leftNavView, links, accessKey, darkMode } = useContext(SettingContext);
    const slug = useSelector(state => state.event.slug)
    return <div className={`editor-sitemap ${leftNavView === 'sitemap' ? 'active' : ''} ${darkMode ? 'dark' : ''}`}>
        {links.map(link => <Link key={`sitemap_link_${link.slug}`} event_slug={slug} access_key={accessKey} link={link} />)}
    </div>
}


const Link = props => {
    const { event_slug, access_key, link, padMultiplier = 0 } = props;
    const { name, icon = null, children, slug } = link;
    const { isEditor } = useContext(SettingContext);
    const to = `${APP_PATHS.virtual}/${event_slug}/${slug}/editor${access_key}`;
    const language = useCurrentLanguage(isEditor);
    const currentName = name[language] ? name[language] : 'unnamed room';
    // For intending sublinks
    const childPadMultiplier = padMultiplier + 2;
    const injectSvg = (svg) => {
        svg.setAttribute('style', 'width: 35px; height: 35px;'); 
        svg.classList.add(`nav-icon-${slug}`); 
    }
    const roomIcon = icon ? <ReactSVG src={icon} beforeInjection={(svg) => injectSvg(svg)}/> : <RoomsIcon color={'#131313'} size="40" />
    return (
        <div className={`sitemap-room-item`} style={{ marginLeft: `${padMultiplier}0px` }}>
            <NavLink className="sitemap-link" to={to}>
                {roomIcon}
                <span classaName="sitemap-room-name">{currentName}</span>
            </NavLink>
            {children.map(child => <Link key={`sitemap_link_${child.slug}`} event_slug={event_slug} access_key={access_key} link={child}  padMultiplier={childPadMultiplier} />)}
        </div>
    )
}

export default SiteMap;