import React from "react";
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { useFetchMeets } from "../apiCalls";
import '../styles/widgets.scss';

const mapStateToProps = state => {
    return {
        organizer: state.event.organizer.slug,
    }
}

const jitsiInitial = {
    slug: '',
    id: '',
    name: ''
}
function MeetWidget(props) {
    const { identifiers, editContent, content, editLocks, editorDispatch, room } = props;
    const { slug } = useParams();

    const meets = useFetchMeets(props.organizer, slug)

    const edit = (value) => {
        if (value) {
            const selectedMeet = meets.find(m => m.slug === value);
            editContent(identifiers, selectedMeet, editorDispatch, room, editLocks)
        } else {
            editContent(identifiers, jitsiInitial, editorDispatch, room, editLocks)
        }

    }

    const meetOptions = meets.map(meet => {
        return <option value={meet.slug}>{meet.name}</option>
    })

    return (<div className="tool-inner-container">
        MEETS
        <div className="tools">
            <div className="tool">
                Choose a session
                <select onChange={(e) => edit(e.target.value)} value={content.slug}>
                    <option value={""}>No selection</option>
                    {meetOptions}
                </select>
            </div>

        </div>

    </div>);
};

export default connect(mapStateToProps)(MeetWidget);