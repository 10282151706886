import { setHours, setMinutes } from "date-fns"
import moment from "moment"

const defineStartTime = (date = new Date(), availableSlots = []) => {
    let selectedDate = moment(date)
    const isSameDay = selectedDate.isSame(moment(), 'day')
    let startMinute = date.getMinutes()
    let startHour = date.getHours()

    if (availableSlots.length < 1) {
        if (isSameDay) {
            if (selectedDate.isSame(moment(), 'hour')) {
                startMinute = moment().minutes()
                const runningDate = moment()

                while (startMinute % 5 !== 0) {
                    runningDate.add(1, 'minutes')
                    startMinute = runningDate.minutes()
                }
                runningDate.add(5, 'minutes')
                startMinute = runningDate.minutes()
                startHour = runningDate.hours()
            } else {
                startMinute = selectedDate.startOf('hour').minutes()
            }
        }
    }
    return { m: startMinute, h: startHour }
}

export const calculateTime = (date = new Date(), existingSlot = null) => {
    let time
    let endTime
    if (existingSlot) {
        time = setHours(setMinutes(new Date(), new Date(existingSlot.start_time).getMinutes()), new Date(existingSlot.start_time).getHours())
        endTime = setHours(setMinutes(new Date(), new Date(existingSlot.end_time).getMinutes()), new Date(existingSlot.end_time).getHours())
    } else {
        const { m, h } = defineStartTime(date)
        const start = moment(date)
        const end = moment(date)
        const startDate = start.set({ m: m, hour: h })
        const endDate = end.set({ m: m, hour: h }).add(15, 'minutes')
        const startHr = startDate.hours()
        const startMinutes = startDate.minutes()
        const endHr = endDate.hours()
        const endMinutes = endDate.minutes()
        time = setHours(setMinutes(new Date(date), startMinutes), startHr)
        endTime = setHours(setMinutes(new Date(date), endMinutes), endHr)
    }

    return { time, endTime }
}

export const getMinMaxDate = (arr, type, key) => {
    if (type === 'min') {
        return moment.min(arr.map(s => moment(s[key]))).startOf('hour')
    } else {
        return moment.max(arr.map(s => moment(s[key]))).startOf('hour')
    }
}

export const includeDatepickerTimes = (arr, interval, start, isEnd) => {
    if (arr.length < 1) return null
    const minTime = getMinMaxDate(arr, 'min', 'start_time')
    const maxTime = getMinMaxDate(arr, 'max', 'end_time')
    const runningTime = start ? moment(start) : moment(minTime)
    if (isEnd) {
        maxTime.add(interval, 'minutes')
        if (start) runningTime.add(interval, 'minutes')
    }
    const timeArr = []
    while (!runningTime.isSame(maxTime, 'minute')) {
        const h = runningTime.hours()
        const m = runningTime.minutes()
        const timeToAdd = setHours(setMinutes(new Date(), m), h)
        timeArr.push(timeToAdd)
        runningTime.add(interval, 'minutes')
    }
    return timeArr
}