/** @format */

import { useState, useEffect, useRef } from "react";
import "./header.scss";
import { connect, useDispatch } from "react-redux";
import {
	IconCalendar,
	IconBellFilled,
	IconUserCircle,
	IconSignout,
	IconBarChart,
	IconUsers,
	IconCalendarCheck,
	IconProperties,
} from "components/icons";

import { parseImageLetter } from "../chat/message";
import UserOptions from "./userOptions";
import Schedule from "../../schedule/schedule";
import Performers from "../../performers/performers";
import Polls from "../polls/polls";
import { Localized } from "@fluent/react";
import ErrorHandler from "components/ErrorHandler/errorHandler";
import CalendarModal from "containers/VirtualEvent/schedule/calendar_modal";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import { GenericTooltip } from "components/ToolTip/Tooltip";
import { markNotificationAsRead } from "middlewares/websocket";
import * as a from "actiontypes";
import moment from "moment";
import Moderation from "../moderation/moderation";
import { handleLogout } from "../hooks/hooks";
import OutsideClickHandler from "react-outside-click-handler";
import { useFetchCalendarData } from "containers/VirtualEvent/schedule/apiCalls";

const mapStateToProps = state => {
	return {
		user: state.user,
		event: state.event,
		connection: state.connection,
		notifications: state.notifications.notifications,
		unread: state.notifications.unread,
		myEngagementEventXP: state.engagement.totalEventXP,
		myEngagementLevel: state.engagement.level,
		isEngagementUnlocked: state.lobby.data.features.engagement,
		isNotificationsVisible: state.schedule.notification_center,
		schedules: state.schedule.schedule?.schedules,
		polls: state.polls.currentPolls,
		isModerator: state.websocketUser.is_moderator,
		performers: state.schedule.schedule?.performers,
		settingsModal: state.components.settings,
	};
};

const mapDispatchToProps = dispatch => ({
	signOut: () => dispatch({ type: "SIGNOUT" }),
	resetNotifications: () => dispatch({ type: a.WS_RESET_NEW_NOTIFICATION }),
	emptyNotifications: () => dispatch({ type: a.WS_RESET_NOTIFICATIONS }),
	handleModals: () => dispatch({ type: a.HANDLE_MODALS, payload: false }),
	handleUnread: () => dispatch({ type: a.HANDLE_UNREAD, payload: false }),
	handleOpenNotifications: payload => dispatch({ type: a.HANDLE_NOTIFICATION_CENTER, payload }),
	handleOpenSettings: payload => dispatch({ type: a.HANDLE_SETTINGS_MODAL, payload }),
});

function Header(props) {
	const [time, setTime] = useState(moment());
	const timer = useRef(null);
	const bellRef = useRef(null);
	const dispatch = useDispatch();

	const [showPolls, setShowPolls] = useState(false);
	const [showSchedule, setShowSchedule] = useState(false);
	const [showCalendar, setShowCalendar] = useState(false);
	const [showPerformers, setShowPerformers] = useState(false);
	const [showModeration, setShowModeration] = useState(false);

	const [showDropdown, setShowDropdown] = useState(false);
	const { connection, lobby } = props;
	const { users_online, connected } = connection;
	const { logo = null, features } = lobby.data;
	const {
		calendar: calendarFeature = true,
		performers: performersFeature = true,
		polls: pollsFeature = true,
		schedule: scheduleFeature = true,
	} = features;

	const {
		event,
		user,
		pageTheme,
		slug,
		handleOpenNotifications,
		notifications,
		unread,
		isNotificationsVisible,
		view,
		handleModals,
		myEngagementEventXP = 0,
		myEngagementLevel = 0,
		isEngagementUnlocked,
		handleUnread,
		polls,
		schedules = [],
		isModerator = false,
		handleOpenSettings,
		settingsModal,
	} = props;

	const { isOpen: showSettings = false, view: settingsView = "profile" } = settingsModal;

	const { name = "" } = event;
	const { first_name = "", last_name = "", image, guestuser, username = "", event_settings = {} } = user;

	const user_name = guestuser ? username : `${first_name} ${last_name}`;

	useFetchCalendarData(slug);

	useEffect(() => {
		timer.current = setInterval(() => {
			setTime(moment());
		}, 10000);

		return function cleanup() {
			timer.current = null;
		};
	}, []);

	useEffect(() => {
		if (bellRef) {
			dispatch({ type: a.HANDLE_BELL_REF, payload: bellRef });
		}
	}, [bellRef]);

	useEffect(() => {
		if (isNotificationsVisible && notifications.length > 0) {
			const unreadNotifications = notifications.filter(notification => !notification.read).map(notif => notif.id);
			if (unreadNotifications.length > 0) {
				handleUnread();
				unreadNotifications.forEach(notification => {
					markNotificationAsRead(notification);
				});
			}
		}
	}, [isNotificationsVisible, notifications]); // eslint-disable-line react-hooks/exhaustive-deps

	function signOut() {
		handleLogout(dispatch, () => setShowDropdown(false));
	}
	function openProfile() {
		handleModals();
		handleOpenSettings({ isOpen: true, view: "profile" });
		setShowDropdown(false);
	}

	const handleNotifications = () => {
		handleModals();
		handleOpenNotifications(!isNotificationsVisible);
		props.resetNotifications();
	};

	const handleSchedule = () => {
		handleModals();
		setShowSchedule(!showSchedule);
	};

	const handleCalendar = () => {
		handleModals();
		setShowCalendar(!showCalendar);
	};

	const handlePerformersModal = () => {
		handleModals();
		setShowPerformers(!showPerformers);
	};

	const handlePolls = () => {
		handleModals();
		setShowPolls(!showPolls);
	};

	const showPollButton =
		polls?.length > 0 && pollsFeature && !polls.every(p => moment(p.end_time).isBefore(moment()));

	return (
		<>
			<div id="virtual-event-header" className={`${pageTheme} ${view}`}>
				<div className={`event-name ${pageTheme}`}>{name}</div>

				<div className="event-image" style={{ backgroundImage: `url("${logo}")` }}></div>
				<div className="user-options">
					{showPollButton && (
						<button
							className="header-button polls-button"
							data-cy="header-polls-button"
							onClick={handlePolls}
						>
							<IconBarChart size="25" className={`header-button-icon ${pageTheme}`} />
							<Localized id="header-polls-button">
								<span className="header-button-text">Polls</span>
							</Localized>
						</button>
					)}
					{scheduleFeature && (
						<button
							className="header-button calendar-button"
							data-cy="header-schedule-button"
							onClick={handleSchedule}
						>
							<IconCalendar size="25" className={`header-button-icon ${pageTheme}`} />
							<Localized id="header-schedule-button">
								<span className="header-button-text">Program</span>
							</Localized>
						</button>
					)}
					{performersFeature && (
						<button
							className="header-button calendar-button"
							data-cy="header-performers-button"
							onClick={handlePerformersModal}
						>
							<IconUsers size="25" className={`header-button-icon ${pageTheme}`} />
							<Localized id="header-performers-button">
								<span className="header-button-text">Performers</span>
							</Localized>
						</button>
					)}
				</div>

				<div className="user-options">
					<div id="header-online-count">
						<span
							className="dot"
							style={connected ? { backgroundColor: "green" } : { backgroundColor: "red" }}
						></span>
						<span className="header-attendees">{connected ? `${users_online}` : "Offline"}</span>
					</div>

					{!user.guestuser && (
						<GenericTooltip
							orientation="bottom"
							text={<Localized id="header-notification-tooltip" />}
							hide="100"
						>
							<div
								ref={bellRef}
								className={`notification-bell${unread && !isNotificationsVisible ? " new" : ""}`}
								onClick={handleNotifications}
							>
								<IconBellFilled size="20" />
								{unread && !isNotificationsVisible && !user.guestuser && (
									<span className="notification-amount"></span>
								)}
							</div>
						</GenericTooltip>
					)}

					{!user.guestuser && calendarFeature && (
						<GenericTooltip
							orientation="bottom"
							text={<Localized id="header-mycalendar-tooltip" />}
							hide="100"
						>
							<div className="personal-calendar-icon-container" onClick={handleCalendar}>
								<IconCalendarCheck size="22" className="personal-calendar-icon" />
							</div>
						</GenericTooltip>
					)}

					{!user.guestuser && isEngagementUnlocked && event_settings.engagement ? (
						<button className="header-button profile-button" onClick={() => setShowDropdown(!showDropdown)}>
							<div
								className="virtual-profile-icon"
								style={{ backgroundImage: image ? 'url("' + image + '")' : "unset" }}
							>
								<CircularProgressbarWithChildren
									value={myEngagementEventXP}
									minValue={myEngagementLevel.min}
									maxValue={myEngagementLevel.max ? myEngagementLevel.max : undefined}
									styles={buildStyles({
										pathColor: "#C4281A",
										trailColor: "#DDD",
										rotation: 0.385,
									})}
								>
									{parseImageLetter(image, user_name)}
								</CircularProgressbarWithChildren>
								<div className="engagement-level-icon">
									<div className="engagement-level-amount">{myEngagementLevel.level || 0}</div>
								</div>
							</div>
						</button>
					) : (
						<button className="header-button profile-button" onClick={() => setShowDropdown(!showDropdown)}>
							<div
								className="virtual-profile-icon guest-border"
								style={{ backgroundImage: image ? 'url("' + image + '")' : "unset" }}
							>
								{parseImageLetter(image, user_name)}
							</div>
						</button>
					)}

					{showDropdown && (
						<OutsideClickHandler onOutsideClick={() => setShowDropdown(false)}>
							<div className="user-dropdown">
								<button onClick={() => openProfile()}>
									<IconUserCircle />
									<Localized id="user-dropdown-profile">Profile</Localized>
								</button>
								{isModerator && (
									<button onClick={() => setShowModeration(true)}>
										<IconProperties />
										<Localized id="user-dropdown-moderation">Moderation</Localized>
									</button>
								)}
								<button onClick={() => signOut()}>
									<IconSignout />
									<Localized id="user-dropdown-signout">Signout</Localized>
								</button>
							</div>
						</OutsideClickHandler>
					)}
				</div>
			</div>

			{showSettings && (
				<ErrorHandler scope="User settings">
					<UserOptions
						showUser={showSettings}
						slug={slug}
						setShowUser={val => handleOpenSettings({ isOpen: val, view: "profile" })}
						defaultView={settingsView}
					/>
				</ErrorHandler>
			)}

			{showSchedule && (
				<ErrorHandler scope="Schedule">
					<Schedule slug={slug} showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
				</ErrorHandler>
			)}

			{showCalendar && (
				<ErrorHandler scope="Calendar">
					<CalendarModal slug={slug} showCalendar={showCalendar} setShowCalendar={setShowCalendar} />
				</ErrorHandler>
			)}

			{
				<ErrorHandler scope="Performers">
					<Performers slug={slug} showPerformers={showPerformers} setShowPerformers={setShowPerformers} />
				</ErrorHandler>
			}

			<ErrorHandler scope="Polls">
				{showPolls && (
					<Polls
						time={time}
						showPolls={showPolls}
						setShowPolls={setShowPolls}
						polls={polls}
						keySuffix={"header-"}
					/>
				)}
			</ErrorHandler>

			<ErrorHandler scope="Moderation">
				{showModeration && <Moderation showModeration={showModeration} setShowModeration={setShowModeration} />}
			</ErrorHandler>
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
