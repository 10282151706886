import { Localized } from "@fluent/react"
import { useFetchEventParticipants } from "containers/VirtualEvent/apiCalls"
import { useState } from "react"
import { useEffect } from "react"
import { connect } from "react-redux"
import { LeaderboardRow } from "./components/leaderboard_spots"
import { Top3Spot } from "./components/leaderboard_spots"
import './css/engagement.scss'
import { populateLeaderboard } from "./helpers/populateLeaderboard"
import * as a from 'actiontypes'
import EngagementSkeleton from "./components/skeleton"

const mapStateToProps = state => ({
    myTotalXp: state.engagement.totalXP,
    myEventXp: state.engagement.totalEventXP,
    myPosition: state.engagement.myPosition,
    leaderboard: state.engagement.leaderboard,
    engagement: state.engagement,
    myChatID: state.user.chat_user_id,
    user: state.user,
    displayType: state.displayType,
    theme: state.room.data?.theme,
    attendees: state.all_attendees
})

const mapDispatchToProps = dispatch => ({
    toggleProfile: payload => dispatch({ type: a.WS_TOGGLE_PROFILE, payload: payload }),
    changeView: view => dispatch({ type: a.WS_CHANGE_VIEW, payload: view }),
})

const Engagement = (props) => {
    const { leaderboard, engagement, myChatID, user, myEventXp, myPosition, displayType = 'desktop', theme = 'desktop', attendees = [] } = props;
    const [leaderboardWithUserInfo, setLeaderboardWithUserInfo] = useState(null)
    const [loadingEngagement, setLoadingEngagement] = useState(true)

    useFetchEventParticipants()
    
    useEffect(() => {
        if (leaderboard && attendees) {
            setLeaderboardWithUserInfo(populateLeaderboard(leaderboard, attendees, myChatID, user.guestuser, window.innerHeight))
        }
    }, [engagement, attendees, displayType])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const checkIfLoading = () => {
            if (leaderboardWithUserInfo) {
                const { first=null, second=null, third=null, top4to10=[], leaderboardLength=0 } = leaderboardWithUserInfo
                if (leaderboardLength === 1 && first) { setLoadingEngagement(false) }
                else if (leaderboardLength === 2 && (first && second)) { setLoadingEngagement(false) }
                else if (leaderboardLength === 3 && (first && second && third)) { setLoadingEngagement(false) }
                else if (leaderboardLength > 3 && (first && second && third && top4to10)) { setLoadingEngagement(false) }
            }
        }

        checkIfLoading()
        
    }, [leaderboardWithUserInfo])// eslint-disable-line react-hooks/exhaustive-deps

    const handleGoToProfile = (user) => {
        if (user.user_id !== myChatID) {
            props.toggleProfile(user)
            props.changeView('inbox')
        }
    }

    return (
        <div className={`engagement-leaderboard-container ${displayType} ${theme}`}>
            <div className="engagement-leaderboard-wrapper">
                <Localized id="engagement-leaderboard-title">
                    <div className="engagement-leaderboard-title">Event Leaderboard</div>
                </Localized>
                {
                    !loadingEngagement
                        ?
                        <>
                            <div className="leaderboard-top3-container">
                                <div className={`leaderboard-top3-position-container ${leaderboardWithUserInfo.second ? 'second' : ''}`}>
                                    <Top3Spot spot={leaderboardWithUserInfo.second} handleGoToProfile={handleGoToProfile} position={2} />
                                </div>
                                <div className={`leaderboard-top3-position-container ${leaderboardWithUserInfo.first ? 'first' : ''}`}>
                                    <Top3Spot spot={leaderboardWithUserInfo.first} handleGoToProfile={handleGoToProfile} position={1} />
                                </div>
                                <div className={`leaderboard-top3-position-container ${leaderboardWithUserInfo.third ? 'third' : ''}`}>
                                    <Top3Spot spot={leaderboardWithUserInfo.third} handleGoToProfile={handleGoToProfile} position={3} />
                                </div>
                            </div>
                            <div className="leaderboard-rest-container">
                                <div className="leaderboard-top4to10-container">
                                    {leaderboardWithUserInfo.top4to10.length > 0 && leaderboardWithUserInfo.top4to10.map((spot, i) =>
                                        <LeaderboardRow key={`leaderboard_spot_${i + 4}`} spot={spot} position={i + 4} myChatID={myChatID} handleGoToProfile={handleGoToProfile} />
                                    )}
                                </div>
                                {
                                    !leaderboardWithUserInfo.isInLeaderboard && user.event_settings.engagement &&
                                    <>
                                        <div className={`three-dots ${displayType} ${theme}`}>...</div>
                                        <LeaderboardRow key={`leaderboard_spot_${leaderboardWithUserInfo.myLeaderBoardPosition || myPosition + 1}`} spot={{ user_id: user.chat_user_id, icon: user.image, first_name: user.first_name, last_name: user.last_name, xp: myEventXp }} position={myPosition + 1} myChatID={myChatID} isInLeaderboard={leaderboardWithUserInfo.isInLeaderboard} isStealth={user.stealth} />
                                    </>
                                }
                            </div>
                        </>
                        :
                        <EngagementSkeleton />
                }
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Engagement)