/** @format */

import { generateModalClasses } from "headingModal/helpers";
import "./lvt-components.scss";
import { Accordion, Badge, Box, Checkbox, CloseButton, Drawer, Modal, MultiSelect, Select, Tooltip } from "@mantine/core";
import { generateDrawerClassNames } from "containers/VirtualEvent/contentView/hooks/hooks";
import { Localized } from "@fluent/react";

export const LvtModal = ({
	opened = false,
	setOpened,
	title = "",
	content = "",
	actions = "",
	contentStyle = {},
	size = "md",
	centered = true,
	classNames = {},
	radius = 8,
	withinPortal = true,
	children = "",
	contentClassName = "",
	variant = "",
	...rest
}) => {
	return (
		<Modal
			title={title}
			opened={opened}
			onClose={() => setOpened(false)}
			centered={centered}
			size={size}
			classNames={{
				...generateModalClasses(`lvt-modal${variant}`),
				...classNames,
			}}
			radius={radius}
			withinPortal={withinPortal}
			{...rest}
		>
			<div className={`lvt-modal-content-col ${contentClassName}`} style={contentStyle}>
				{content}
				{children}
			</div>
			{actions && (
				<div justify="end" className="lvt-modal-actions">
					{actions}
				</div>
			)}
		</Modal>
	);
};

export const LvtDrawer = ({ children, classNames = {}, ...props }) => {
	return (
		<Drawer classNames={{ ...generateDrawerClassNames("lvt-drawer"), ...classNames }} {...props}>
			{children}
		</Drawer>
	);
};

export const LvtTooltip = ({ children, ...props }) => {
	return <Tooltip {...props}>{children}</Tooltip>;
};

export const LvtMultiselect = ({ className = "", ...props }) => {
	return <MultiSelect className={`lvt-multiselect ${className}`} {...props} />;
};

export const LvtBox = ({ children, ...props }) => {
	return <Box {...props}>{children}</Box>;
};

export const LvtCloseButton = props => {
	return <CloseButton {...props} />;
};

export const LvtCheckboxGroup = ({ items = [], ...props }) => {
	return (
		<Checkbox.Group {...props}>
			{items.map((item, i) => {
				const { dataCy = `lvt-checkbox-${i}`, ...restOfItem } = item;
				return <Checkbox data-cy={dataCy} {...restOfItem} />;
			})}
		</Checkbox.Group>
	);
};

export const LvtSelect = ({ className = "", ...props }) => {
	return <Select className={`lvt-select ${className}`} {...props} />;
};

export const LvtBadge = ({ children, ...props }) => <Badge {...props}>{children}</Badge>;

export const LvtBtn = ({ children, className = "", variant = "md", ...props }) => {
	return (
		<button className={`lvt-btn ${variant} ${className}`} {...props}>
			{children}
		</button>
	);
};

export const LvtAccordion = props => {
	const { items = [], locale = "lvtaccordion-", controlProps = {}, panelProps = {}, ...rest } = props;
	return (
		<Accordion {...rest}>
			{items.map((item, index) => {
				const { component, value, label, ...restOfItem } = item;
				return (
					<Accordion.Item value={value} {...restOfItem}>
						<Accordion.Control {...controlProps}>
							<Localized id={`${locale}${value}`}>{label}</Localized>
						</Accordion.Control>
						<Accordion.Panel {...panelProps}>{component}</Accordion.Panel>
					</Accordion.Item>
				);
			})}
		</Accordion>
	);
};