/** @format */

import { editorInitial, editorReducer } from "./reducer";
import { connect } from "react-redux";
import React, { useState, useReducer, useEffect } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { APP_PATHS } from "constants.js";
import Layout from "./layout";
import { LivetoLoader } from "components/icons";
import cloneDeep from "lodash/cloneDeep";
import "./styles/editor.scss";

// NEW UI COMPONENTS, TODO: CLEANUP AFTER THE NEW LAYOUT IMPLEMENTATION
import EditorContent from "./UIContainers/EditorContent";
import MainToolbar from "./UIContainers/MainToolbar";
import BottomToolbar from "./UIContainers/BottomToolbar";
import NavigationLeft from "./UIContainers/NavigationLeft";
import PreviewModal from "./UIContainers/modals/PreviewModal";

import { useFetchUserInfo, useFetchLobby, useFetchRoom, useSaveRoom, useFetchMeets, useFetchStreams } from "./apiCalls";
import { useFetchWebsocketConfiguration } from "../apiCalls";

import { SettingContext, OrganizerContext } from "../contexts";
import { useAccessKey } from "./apiCalls";
import { ErrorPage } from "./errors";
import { Localized } from "@fluent/react";
import AddModal from "./addModal";
import { useHistoryShortCut } from "./hooks/useKeyPress";
import { useDetectResize } from "../contentView/hooks/hooks";
import UnsupportedWarning from "./UnsupportedWarning";
import WarningModal from "../warnings/sessionWarning";

function usePageRefreshInterupter(changed) {
	useEffect(() => {
		if (changed) {
			window.addEventListener("beforeunload", interrupt);
		}
		return () => {
			window.removeEventListener("beforeunload", interrupt);
		};
	}, [changed]);

	const interrupt = e => {
		if (changed) {
			e.preventDefault();
			e.returnValue = "";
		}
	};
}

function useSelectCreatedItem(created, elem, setActiveItem, editorDispatch) {
	useEffect(() => {
		if (created) {
			setActiveItem(elem);
			editorDispatch({
				type: "EDITOR_ITEM_ACTIVATED"
			});
		}
	}, [created]);
}

const mapStateToProps = state => {
	return {
		organizer: state.event.organizer.slug,
		user: state.user,
		polls: state.polls.currentPolls,
		addModal: state.editorProgress.addModal,
		showPreviewModal: state.editorProgress?.previewModal?.showModal,
		displayType: state.displayType,
		languages: state.event.languages
	};
};
const mapDispatchToProps = dispatch => ({
	populateWebsocketConfigurations: conf => dispatch({ type: "WEBSOCKET::POPULATE_CONFIGURATIONS", payload: conf }),
	displayTypeFetched: displayMode => dispatch({ type: "GET_DISPLAY_TYPE", payload: displayMode })
});

function VirtualEventEditorView(props) {
	const ref = useDetectResize(props.displayTypeFetched);
	const { addModal = {}, showPreviewModal = false, displayType, populateWebsocketConfigurations } = props;
	const { showModal, modalData = {} } = addModal;
	const [isEditor] = useState(true);
	const [preventCollision, setPreventCollision] = useState(true);
	const { slug, room_slug } = useParams();
	const userType = useFetchUserInfo(props.organizer); // Mandatory, need organizer from user to specify user type
	const accessKey = useAccessKey();
	const [saved, setSaved] = useState(false);
	const [editor, editorDispatch] = useReducer(editorReducer, editorInitial);
	const [activeItem, setActiveItem] = useState(null);
	const [view, setView] = useState("desktop");
	const [darkMode, setDarkMode] = useState(false);
	const [editLocks, setEditLocks] = useState(["desktop", "tablet", "mobile"]);
	const [lobby, links, loaded, status] = useFetchLobby(slug, props.organizer);
	useFetchRoom(slug, room_slug, editorDispatch, props.organizer);
	const [loading, success, show, saveStatus, saveDetail] = useSaveRoom(
		slug,
		room_slug,
		saved,
		setSaved,
		editor.data,
		editorDispatch
	);
	const [leftNavView, setLeftNavView] = useState("components");

	const location = useLocation();
	const redirectUrl = location.pathname.replace("/editor", "") + accessKey;
	const history = useHistory();

	const [clearBackground, setClearBackground] = useState(false);
	const clearBackgroundStyles = darkMode => {
		return {
			backgroundImage: "none",
			backgroundColor: darkMode ? "#555" : "transparent"
		};
	};
	const [showTemplates, setShowTemplates] = useState(false);

	function toggleTemplates() {
		setActiveItem(null);
		setShowTemplates(show => !show);
	}
	const [showGrid, setShowGrid] = useState(false);

	useHistoryShortCut(editorDispatch, setActiveItem); //ctrl+z and ctrl+shift+z shortcuts

	function changeEditLocks(value) {
		const newLocks = cloneDeep(editLocks);

		if (editLocks.includes(value)) {
			for (let [idx, v] of editLocks.entries()) {
				if (v === value) {
					newLocks.splice(idx, 1);
				}
			}
		} else {
			newLocks.push(value);
		}
		setEditLocks(newLocks);
	}

	function changeView(view) {
		setActiveItem(null);
		setView(view);
		setEditLocks([view]);
	}

	const historyTravelTo = pointer => {
		editorDispatch({ type: "HISTORY_TRAVEL_TO", payload: pointer });
	};

	const meets = useFetchMeets(props.organizer, slug);
	const streams = useFetchStreams(props.organizer, slug);

	usePageRefreshInterupter(editor.changed);
	useSelectCreatedItem(editor.created, editor.newItem, setActiveItem, editorDispatch);
	const [conf, confLoading, confStatus] = useFetchWebsocketConfiguration(slug);
	useEffect(() => {
		if (confStatus === 200 && !confLoading) {
			populateWebsocketConfigurations(conf);
		}
	}, [confStatus, confLoading]);

	const viewLoaded = [loaded, editor.loaded].every(l => l === true);

	const editorContextValues = {
		accessKey,
		roomData: editor.data,
		roomSlug: room_slug,
		toggleTemplates,
		showTemplates,
		setShowTemplates,
		darkMode,
		setDarkMode,
		clearBackground,
		setClearBackground,
		view: view,
		streams: streams,
		meets: meets,
		setView: setView,
		isEditor: isEditor,
		preventCollision: preventCollision,
		activeItem: activeItem,
		setActiveItem: setActiveItem,
		userType: userType,
		editLocks: editLocks,
		setEditLocks: setEditLocks,
		links: links,
		leftNavView,
		setLeftNavView,
		editorDispatch,
		organizer: props.organizer,
		languages: props.languages || ["en", "fi"],
		tempData: editor.tempData
	};

	if (viewLoaded) {
		if ([status, editor.status].every(s => s === 200)) {
			let roomBackgroundStyles;
			if (editor.data.styles && editor.data.styles["background"]) {
				roomBackgroundStyles = {
					backgroundImage: editor.data.styles["background"].image,
					backgroundSize: editor.data.styles["background"].size,
					backgroundPosition: editor.data.styles["background"].position,
					backgroundRepeat: editor.data.styles["background"].repeat,
					backgroundColor: editor.data.styles["background"].color
				};
			}
			return (
				<div ref={ref}>
					{(displayType === "desktop" || displayType === "tablet") && (
						<div
							className={`virtual-event-editor-view-layout ${view} ${darkMode ? "dark" : ""}`}
							style={clearBackground ? clearBackgroundStyles(darkMode) : roomBackgroundStyles}
						>
							<SettingContext.Provider value={editorContextValues}>
								<OrganizerContext.Provider value={props.organizer}>
									<MainToolbar
										darkMode={darkMode}
										editor={editor}
										setSaved={setSaved}
										saveLoading={loading}
										saveSuccess={success}
										showSaveStatus={show}
										saveDetail={saveDetail}
										lobby={lobby}
										preventCollision={preventCollision}
										setPreventCollision={setPreventCollision}
										key="virtual-event-main-toolbar-component"
									></MainToolbar>
									<NavigationLeft
										darkMode={darkMode}
										leftNavView={leftNavView}
										setLeftNavView={setLeftNavView}
										history={editor.history}
										historyLabels={editor.historyLabels}
										historyTravelTo={historyTravelTo}
										currentPointer={editor.currentPointer}
										userType={userType}
										organizer={props.organizer}
									/>
									<EditorContent
										data={editor.data}
										editLocks={editLocks}
										meets={meets}
										streams={streams}
									>
										<Layout
											activeItem={activeItem}
											room={editor.data}
											view={view}
											darkMode={darkMode}
											setView={setView}
											showGrid={showGrid}
											editorDispatch={editorDispatch}
											isEditor={isEditor}
											preventCollision={preventCollision}
											editLocks={editLocks}
											slug={slug}
										/>
										<BottomToolbar
											editLocks={editLocks}
											setEditLocks={setEditLocks}
											showGrid={showGrid}
											setShowGrid={setShowGrid}
										/>
									</EditorContent>
									{showModal && (
										<AddModal
											showModal={showModal}
											meets={meets}
											streams={streams}
											activeItem={activeItem}
											organizer={props.organizer}
											slug={slug}
											languages={editorContextValues.languages}
											{...modalData}
										/>
									)}
									<WarningModal
										isEditor={isEditor}
										slug={slug}
										room_slug={room_slug}
										editorData={editor.data}
										editorDispatch={editorDispatch}
									/>
									{showPreviewModal && <PreviewModal lobby={lobby} view={view} />}
								</OrganizerContext.Provider>
							</SettingContext.Provider>
						</div>
					)}
					{displayType === "mobile" && <UnsupportedWarning displayType={displayType} />}
				</div>
			);
		} else if ([status, editor.status].some(s => s === 401)) {
			return <Redirect to={APP_PATHS.login} />;
		} else if ([status, editor.status].some(s => s === 403)) {
			return (
				<ErrorPage
					title={<Localized id="error-auth-editor-title" />}
					description={<Localized id="error-auth-editor-desc" />}
					additionalButton={{
						show: true,
						handleClick: () => history.push(redirectUrl),
						text: <Localized id="error-event-button" />
					}}
					logoutButton={true}
					logo={true}
					status={403}
				/>
			);
		}
	} else {
		return (
			<div className="editor-loading-screen">
				<LivetoLoader />
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VirtualEventEditorView);
