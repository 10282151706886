import { AUTH_COOKIE_TIMESTAMP } from "../constants";
import moment from "moment";
import { getCookie } from "util/cookies";
import * as a from 'actiontypes'
import { fetchSingleRestrictedSetting } from "containers/VirtualEvent/signallingCalls";

const SESSION_EXPIRATION_THRESHOLD = 120 // Threshold when session expiration warning is given (in minutes)

export function sessionMiddleware({ dispatch, getState }) {
	return next => action => {
		const roomChanged = action.type === a.WS_CHANGE_ROOM
		const componentActivated = action.type === a.SET_ACTIVE_COMPONENT_REF
		if (roomChanged || componentActivated) {
			// Check for expiry
			const expiry = parseInt(getCookie(AUTH_COOKIE_TIMESTAMP));

			const expirationDate = moment(expiry).add(1, 'days')

			if (expiry && moment()) {
				const duration = expirationDate.diff(moment(), 'minutes');
				const expiring = getState().user?.session?.expiring
				const ignored = getState().user?.session?.ignored
				if (!expiring && !ignored && duration < SESSION_EXPIRATION_THRESHOLD) {
					dispatch({ type: "SESSION_EXPIRING", payload: true });
				}
			}
		}

		// To track global restricted mode setting change
		if (roomChanged || action.type === a.WS_JOIN_ROOM) {
			const room_id = roomChanged ? action.payload : action.payload.room_id
			fetchSingleRestrictedSetting(room_id, getState().event.slug, "restricted", dispatch);
		}
		return next(action);
	};
}

export function initGroupsMiddleware({ dispatch, getState }) {
	return next => action => {
		if (action.type === "USER_FETCHED" || action.type === "SET_USER_DATA_FETCHED") {
			const { groups } = action.payload
			const initGroups = groups?.length > 0
			dispatch({ type: 'INIT_GROUPS', payload: initGroups})
		}
		return next(action)
	}
}