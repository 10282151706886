import React, { useState, useEffect, useRef } from "react";

import '../styles/widgets.scss';

export default function UrlWidget(props) {
    const { content = '', editContent, identifiers, editLocks, editorDispatch, room } = props;
    const [value, setValue] = useState(content);
    const inputRef = useRef(null);
    useEffect(() => {

        inputRef.current.focus();
        inputRef.current.select();

        setValue(content)

    }, [content, identifiers])

    const changeValue = (e) => {
        editContent(identifiers, e.target.value, editorDispatch, room, editLocks)
    };

    return (
        <div className="tool-inner-container">
            <div className="tools">
                <div className="tool">
                    URL
                    <input
                        ref={inputRef}
                        className="editor-menu-input"
                        type="text"
                        value={value}
                        onChange={changeValue}
                    />
                </div>
            </div>
        </div>
    );
};