import { Localized } from "@fluent/react"

export const SidebarInviteButtons = (props) => {
    const { handleCancelInvite, slot, handleProceedToProfile} = props
    return (
        <div className="sidebar-button-container">
            <Localized id='sidebar-proceed-to-profile'>
                <button className="sidebar-proceed-to-profile-button proceed" onClick={(e) => handleProceedToProfile(e, slot)}>Proceed to profile</button>
            </Localized>
            <Localized id='sidebar-cancel-invite'>
                <button className="sidebar-cancel-invite-button cancel" variant="danger" onClick={(e) => handleCancelInvite(e, slot)}>Cancel Invitation</button>
            </Localized>
        </div>
    )
}