import { useState, useRef, useEffect } from 'react';
import { parseImageLetter } from './message';
import './chat.scss';
import { IconTimes } from 'components/icons';
import * as a from 'actiontypes';
import { compareDates, getLocaleTime } from 'util/time';
import { MessageWrapper } from './message';
import { connect, useSelector } from 'react-redux';
import { IconStartPhoneCall } from 'components/icons';
import { IconCalendar } from 'components/icons';
import InviteModal from './InviteModal';
import { Localized } from "@fluent/react";
import { IconTwitter } from 'components/icons';
import { IconLinkedIn } from 'components/icons';
import { IconWebsite } from 'components/icons';
import { GenericTooltip } from 'components/ToolTip/Tooltip';
import { extend } from "lodash";
import { useFetchEventParticipants } from 'containers/VirtualEvent/apiCalls';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';
import { useFetchAttendeeCalendarData } from 'containers/VirtualEvent/schedule/apiCalls';
import moment from 'moment';


const mapStateToProps = state => ({
    inbox: state.inbox,
    websocketUser: state.websocketUser,
    guestuser: state.user.guestuser,
    available_slots: state.schedule.attendee_calendar?.slots?.filter(s => s?.status === 'available'),
    all_attendees: state.all_attendees,
    lobbyFeatures: state.lobby.data.features,
    eventEndTime: state.event.end_time
})

const mapDispatchToProps = dispatch => ({
    joinRoom: payload => dispatch({ type: a.WS_JOIN_ROOM, payload: payload }),
    toggleProfile: payload => dispatch({ type: a.WS_TOGGLE_PROFILE, payload: payload }),
    clearNotification: payload => dispatch({ type: a.WS_CLEAR_NOTIFICATION, payload: payload }),
    sendPrivateMessage: payload => dispatch({ type: a.WS_SEND_PRIVATE_MESSAGE, payload: payload }),
    callPerson: payload => dispatch({ type: a.WRTC_RING, payload: payload }),
    handleModals: () => dispatch({ type: a.HANDLE_MODALS, payload: false }),
})

function Inbox(props) {
    const { inbox, websocketUser, available_slots = [], eventEndTime, all_attendees, lobbyFeatures, handleModals } = props;
    const { active_profile } = inbox;

    useFetchEventParticipants()

    let selectedInbox = null;
    let attendee = null;

    if (active_profile) {
        selectedInbox = inbox.private_messages.find(pv => pv.user_id === active_profile);
        attendee = all_attendees.find(a => a.user_id === active_profile || a.chat_user_id === active_profile)
    }

    return <div className={'inbox'}>
        {!active_profile && <>
            <Localized id="inbox-heading">
                <div className="inbox-heading">Inbox</div>
            </Localized>
            {inbox.private_messages.length > 0 ? inbox.private_messages.filter(priv => priv.preview_message).sort((a, b) => (
                !b.preview_message || b.preview_message.seen) - (!a.preview_message || a.preview_message.seen
                )).map((pv, index) => (<PreviewRow profile={pv} key={`inbox_${index}`} my_user_id={websocketUser.user_id} toggleProfile={props.toggleProfile} />)) :
                <Localized id="inbox-no-messages">
                    <div className="no-private-messages">no private messages</div>
                </Localized>
            }
        </>}


        {active_profile && <OpenInbox {...extend({}, selectedInbox, attendee)}
            websocketUser={websocketUser}
            inbox={inbox}
            guestuser={props.guestuser}
            joinRoom={props.joinRoom}
            clearNotification={props.clearNotification}
            sendPrivateMessage={props.sendPrivateMessage}
            callPerson={props.callPerson}
            toggleProfile={props.toggleProfile}
            available_slots={available_slots}
            lobbyFeatures={lobbyFeatures}
            handleModals={handleModals}
            eventEndTime={eventEndTime}
        />}
    </div>
}



const PreviewRow = (props) => {
    const { my_user_id, profile } = props;

    const { preview_message, chat_history } = profile;

    const { first_name = 'John', last_name = 'Smith', message = '', icon = null, seen = true } = preview_message;

    const name = first_name + ' ' + last_name;

    function onClick() { props.toggleProfile(profile) }

    let message_seen = seen;
    if ((chat_history.length > 0 && chat_history[chat_history.length - 1].sender_id === my_user_id) || seen) {
        message_seen = true;
    }
    return <div className="inbox-row" onClick={() => onClick()}>
        <div className="preview-icon" style={icon ? { backgroundImage: `url("${icon}")` } : {}}>{parseImageLetter(icon, name)}</div>
        <div className="preview-message-content">
            <div className="inbox-row space-between no-padding">
                <div className="sender-name">{name}</div>
                {!message_seen && <div className="notification-dot"></div>}
            </div>
            <div className="sender-message-stripped">{message}</div>
        </div>
    </div>
}


function generatePrivateChatHistory(history = [], user_id, first_name = '', last_name = '') {
    let messages = [];

    const name = `${first_name} ${last_name}`
    for (let [index, msg] of history.entries()) {
        let previousIndex = index - 1;

        if (index === 0) {
            messages.push(<DateDivider date={msg.timestamp} key={'divider_' + index} />)
        }

        if (previousIndex >= 0) {
            let sameDates = compareDates(history[previousIndex].timestamp, msg.timestamp);

            if (!sameDates) {
                // Push date 
                messages.push(<DateDivider date={msg.timestamp} key={'divider_' + index} />)
            }
        }
        messages.push(<PrivateMessage {...msg} name={name} user_id={user_id} key={'message_' + index + user_id} />)
    }

    messages.unshift(<MessageHistoryNotification key={'notification-' + user_id} first_name={first_name} last_name={last_name} />)
    return messages;
}

const OpenInbox = props => {
    const scrollRef = useRef();
    const chatFeedRef = useRef();
    const [scrollBlocked, setScrollBlocked] = useState(false);
    const slug = useSelector(state => state.event.slug)

    const { first_name = "",
        last_name = "",
        company_name = "",
        title = "",
        icon = null,
        description = "",
        chat_history = [],
        user_id,
        preview_message = {},
        room_id = null,
        has_joined = false,
        websocketUser,
        twitter = '',
        linkedin = '',
        website = '',
        isOnline,
        available_slots = [],
        lobbyFeatures,
        handleModals,
        eventEndTime
    } = props;

    const { invites: invitesFeature = true, video_calls } = lobbyFeatures

    const [message, setMessage] = useState('');
    const [showInviteModal, setShowInviteModal] = useState(false)
    const { calendarVisibility } = useFetchAttendeeCalendarData(slug, {
        id: user_id,
        first_name,
        last_name
    })

    // To join the private discussion room
    useEffect(() => {
        if (room_id && !has_joined) {
            props.joinRoom({ room_id: room_id, user_id: websocketUser.user_id })
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    // For clearing notifications when viewing current profile
    useEffect(() => {
        if (chat_history.length > 0 && preview_message) {
            const last_message = chat_history[chat_history.length - 1]
            const last_message_sender = last_message.sender_id;
            if (!preview_message.seen && last_message.sender_id !== websocketUser.user_id) {
                const last_message_id = last_message.message_id
                props.clearNotification({ user_id: last_message_sender, message_id: last_message_id })

            }
        }
        if (scrollRef && scrollRef.current && !scrollBlocked) {
            elementScrollIntoView(scrollRef.current, { block: 'nearest', behavior: 'auto' })
        }
    }, [chat_history])// eslint-disable-line react-hooks/exhaustive-deps



    function onScroll() {
        let blocked = false;
        if (chatFeedRef) {
            const scrollThreshold = chatFeedRef.current.scrollHeight - chatFeedRef.current.scrollTop - chatFeedRef.current.clientHeight;
            if (scrollThreshold > 150) {
                blocked = true;
            }
        }

        setScrollBlocked(blocked);
    }

    function handleMessage(e) {
        let value = e.target.value;
        if (value.length > 255) {
            value = value.substring(0, 255);
        }
        setMessage(value);
    }
    function onKeyDown(e) {
        if (message.length > 0 && e.keyCode === 13) {
            e.preventDefault();
            props.sendPrivateMessage({
                message: message,
                receiver_id: user_id,
                sender_id: websocketUser.user_id,
            })
            setMessage('');
        }
    }
    function sendMessage() {
        props.sendPrivateMessage({
            message: message,
            receiver_id: user_id,
            sender_id: websocketUser.user_id,
        })
        setMessage('');
    }
    function callPerson() {
        props.callPerson({ user_id: user_id, first_name: first_name, last_name: last_name, icon: icon })
    }

    const isInviteDisabled = () => {
        const areAvailableSlotsInThePast = available_slots.every(slot => moment(slot.end_time).isBefore(moment()))
        if (!calendarVisibility) return true
        if (moment(eventEndTime).isBefore(moment())) return true
        if (calendarVisibility && available_slots.length > 0 && areAvailableSlotsInThePast) return true
        return false
    }

    const handleOpenInvites = () => {
        if (!isInviteDisabled()) {
            handleModals()
            setShowInviteModal(true)
        }
    }

    return <div className="user-profile">
        <div className="profile-info-container">
            <button className="close-button" onClick={() => props.toggleProfile(null)}><IconTimes /></button>
            <div className="profile-info">
                <div className={`profile-icon ${isOnline === false ? 'offline' : ''}`} style={icon ? { backgroundImage: `url("${icon}")` } : {}}>
                    {parseImageLetter(icon, `${first_name} ${last_name}`)}
                    <div className="status-indicator" />
                </div>
                <div className="profile-data">
                    <p className="profile-name">{`${first_name} ${last_name}`}</p>
                    <p className="profile-title">{title}</p>
                    <p className="profile-company">{company_name}</p>
                </div>

            </div>

            <div className="profile-description">
                {description && `"${description}"`}
            </div>
            <div className="profile-socials">
                {linkedin && <GenericTooltip text="Linkedin" hide="100">
                    <a href={linkedin} target="_blank" rel="noreferrer" className="link"><IconLinkedIn size="22" className="linkedin-icon" /></a>
                </GenericTooltip>}
                {website && <GenericTooltip text={<Localized id="inbox-profile-website" />} hide="100">
                    <a href={website} target="_blank" rel="noreferrer" className="link"><IconWebsite size="22" className="website-icon" /></a>
                </GenericTooltip>}
                {invitesFeature && <GenericTooltip text={isInviteDisabled() ? <Localized id='invitation-private-calendar-notification' /> : <Localized id="inbox-invite-to-a-timeslot" />} hide="100">
                    <div className={`link ${isInviteDisabled() ? 'disabled' : ''}`} onClick={handleOpenInvites}><IconCalendar className="interact-icon" size="22" /></div>
                </GenericTooltip>}
                {(isOnline && video_calls) && <GenericTooltip text={<Localized id="inbox-profile-call" />} hide="100">
                    <div className="link" onClick={() => callPerson()}><IconStartPhoneCall className="interact-icon" size="22" /></div>
                </GenericTooltip>}
            </div>
        </div>
        <div className="chat-history" onScroll={e => onScroll(e)} ref={chatFeedRef}>
            <div>
                {generatePrivateChatHistory(chat_history, websocketUser.user_id, first_name, last_name)}
                <div id="dummy" ref={scrollRef}></div>
            </div>
        </div>
        <div className="send">
            <div className="send-message">
                <Localized id="inbox-send-private-message" attrs={{ placeholder: true }}>
                    <textarea className="send-input" placeholder="send a private message..." value={message}
                        onChange={e => handleMessage(e)}
                        onKeyDown={e => onKeyDown(e)}
                    />
                </Localized>

                <div className="send-message-actions">
                    <Localized id="inbox-profile-send-message">
                        <button className="send-message-button" disabled={message.length === 0} onClick={() => sendMessage()}>
                            Chat
                        </button>
                    </Localized>

                </div>
            </div>
        </div>
        {(calendarVisibility && showInviteModal) && <InviteModal show={showInviteModal} onHide={() => setShowInviteModal(false)} person_id={user_id} person_first_name={first_name} person_last_name={last_name} user={websocketUser} />}
    </div>
}

const PrivateMessage = props => {
    const { user_id, sender_id, message, timestamp, name } = props;

    const message_content = <MessageWrapper message={message} moderator_mode={false} />;

    if (sender_id === user_id) {
        return <div className="message my-message">
            <div className="sender">
                {`${getLocaleTime(timestamp, 'HH:mm')}`}
            </div>

            <div className="message-content">
                {message_content}
            </div>
        </div>
    }
    return <div className="message other-message">
        <div className="sender">
            {` ~ ${name}, ${getLocaleTime(timestamp, 'HH:mm')}`}
        </div>
        <div className="message-content">
            {message_content}
        </div>
    </div>
}


const MessageHistoryNotification = (props) => {
    const { first_name, last_name } = props;
    return <div className="no-private-messages">

        <Localized id="inbox-profile-first-message-heading" vars={{ firstname: first_name, lastname: last_name }}>
            <h1>{`This is the start of your message history with {$firstname} {$lastname}`}</h1>
        </Localized>

        <Localized id="inbox-profile-first-message-subheading">
            <i>Start a conversation by sending a message</i>
        </Localized>
    </div>
}

const DateDivider = (props) => (<div className="date-divider">
    <div className="date-divider-content">
        {getLocaleTime(props.date, 'DD.MM.YYYY')}
    </div>
    <div className="date-divider-border"></div>
</div>)


export default connect(mapStateToProps, mapDispatchToProps)(Inbox)