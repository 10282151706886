import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Rnd } from 'react-rnd';

import { IconSettings, IconMicrophoneMuted, IconMicrophoneOn, IconVideoOff, IconVideoOn, IconShareScreen, IconHangup, IconCalling } from 'components/icons';
import './callView.scss';
import * as a from 'actiontypes';
import { Localized } from "@fluent/react";

function mapStateToProps(state) {
    return {
        busy: state.webrtc.busy,
        incoming: state.webrtc.incoming,
        accepted: state.webrtc.accepted,
        connected: state.webrtc.connected,

        callingUserId: state.webrtc.callingUserId,
        callingChannelName: state.webrtc.callingChannelName,

        otherParticipantFirstName: state.webrtc.otherParticipantFirstName,
        otherParticipantLastName: state.webrtc.otherParticipantLastName,
        otherParticipantIcon: state.webrtc.otherParticipantIcon,

        localUserStream: state.webrtc.localUserStream,
        localDisplayStream: state.webrtc.localDisplayStream,
        remoteUserStream: state.webrtc.remoteUserStream,
        remoteDisplayStream: state.webrtc.remoteDisplayStream,

        userStreamAudioDisabled: state.webrtc.userStreamAudioDisabled,
        userStreamVideoDisabled: state.webrtc.userStreamVideoDisabled,

        user_id: state.websocketUser.user_id,
        first_name: state.websocketUser.userdata.first_name,
        last_name: state.websocketUser.userdata.last_name,
        userMediaVideoQuality: state.webrtcSettings.userMediaVideoQuality,
        userMediaVideoDeviceId: state.webrtcSettings.userMediaVideoDeviceId,
        userMediaAudioDeviceId: state.webrtcSettings.userMediaAudioDeviceId,
        userMediaDevices: state.webrtc.userMediaDevices,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        hangup: (data) => dispatch({ type: a.WRTC_HANGUP, payload: data }),
        disableScreenshare: (boolean) => dispatch({ type: a.WRTC_SCREENSHARE_DISABLE, payload: boolean }),
        enableScreenshare: (boolean) => dispatch({ type: a.WRTC_SCREENSHARE_ENABLE, payload: boolean }),
        setUserVideoDisabled: (boolean) => dispatch({ type: a.WRTC_SET_USERVIDEODISABLED, payload: boolean }),
        setUserAudioDisabled: (boolean) => dispatch({ type: a.WRTC_SET_USERADIODISABLED, payload: boolean }),
        setUserMediaAudioDeviceId: (device) => dispatch({ type: a.WRTC_SETTING_SET_USERMEDIA_AUDIO_DEVICEID, payload: device }),
        setUserMediaVideoDeviceId: (device) => dispatch({ type: a.WRTC_SETTING_SET_USERMEDIA_VIDEO_DEVICEID, payload: device }),
        updateUserMediaDevicesList: (devices) => dispatch({ type: a.WRTC_UPDATE_USERMEDIA_DEVICES_LIST, payload: devices }),
    }
}



class OngoingCall extends Component {
    constructor(props) {
        super(props);
        this.localUserVideoRef = React.createRef();
        this.localDisplayVideoRef = React.createRef();
        this.remoteUserVideoRef = React.createRef();
        this.remoteDisplayVideoRef = React.createRef();
        this.intervalHolder = setInterval(this.intervalTick.bind(this), 1000);

        this.state = {
            localUserWidth: 0,
            localUserHeight: 0,
            localDisplayHeight: 0,
            localDisplayWidth: 0,
            remoteUserWidht: 0,
            remoteUserHeight: 0,
            remoteDisplayWidth: 0,
            remoteDisplayHeight: 0,
            useDesktop: false,
            showControls: false
        };
    }


    componentDidMount() {
        this.props.updateUserMediaDevicesList();
        this.updateVideoStream();
    }

    componentDidUpdate() {
        this.updateVideoStream();
    }

    componentWillUnmount() {
        clearInterval(this.intervalHolder);
    }

    showControls = (e) => { this.setState({ showControls: !this.state.showControls }) }


    intervalTick = () => {
        if (this.localUserVideoRef && this.localUserVideoRef.current && this.props.localUserStream) {
            // console.log('Local User', this.localUserVideoRef.current.videoWidth, this.localUserVideoRef.current.videoHeight);
            this.setState({
                localUserWidth: this.localUserVideoRef.current.videoWidth,
                localUserHeight: this.localUserVideoRef.current.videoHeight
            });
        }
        if (this.localDisplayVideoRef && this.localDisplayVideoRef.current && this.props.localDisplayStream) {
            // console.log('Local Display', this.localDisplayVideoRef.current.videoWidth, this.localDisplayVideoRef.current.videoHeight);
            this.setState({
                localDisplayWidth: this.localDisplayVideoRef.current.videoWidth,
                localDisplayHeight: this.localDisplayVideoRef.current.videoHeight
            });
        }
        if (this.remoteUserVideoRef && this.remoteUserVideoRef.current && this.props.remoteUserStream) {
            // console.log('Remote User', this.remoteUserVideoRef.current.videoWidth, this.remoteUserVideoRef.current.videoHeight);
            this.setState({
                remoteUserWidth: this.remoteUserVideoRef.current.videoWidth,
                remoteUserHeight: this.remoteUserVideoRef.current.videoHeight
            });
        }
        if (this.remoteDisplayVideoRef && this.remoteDisplayVideoRef.current && this.props.remoteDisplayStream) {
            // console.log('Remote Display', this.remoteDisplayVideoRef.current.videoWidth, this.remoteDisplayVideoRef.current.videoHeight);
            this.setState({
                remoteDisplayWidth: this.remoteDisplayVideoRef.current.videoWidth,
                remoteDisplayHeight: this.remoteDisplayVideoRef.current.videoHeight
            });
        }
    }

    updateVideoStream() {
        if (this.localUserVideoRef && this.localUserVideoRef.current && this.localUserVideoRef.current.srcObject !== this.props.localUserStream) {
            this.localUserVideoRef.current.srcObject = this.props.localUserStream;
        }
        if (this.localDisplayVideoRef && this.localDisplayVideoRef.current && this.localDisplayVideoRef.current.srcObject !== this.props.localDisplayStream) {
            this.localDisplayVideoRef.current.srcObject = this.props.localDisplayStream;
        }
        if (this.remoteUserVideoRef && this.remoteUserVideoRef.current && this.remoteUserVideoRef.current.srcObject !== this.props.remoteUserStream) {
            this.remoteUserVideoRef.current.srcObject = this.props.remoteUserStream;
        }
        if (this.remoteDisplayVideoRef && this.remoteDisplayVideoRef.current && this.remoteDisplayVideoRef.current.srcObject !== this.props.remoteDisplayStream) {
            this.remoteDisplayVideoRef.current.srcObject = this.props.remoteDisplayStream;
        }
    }

    render() {
        const mobileDevice = /Mobi|Android/i.test(navigator.userAgent) ? true : false;

        const windowWidth = window.innerWidth;
        let webcamWidth = 400;

        if (windowWidth < 400) { webcamWidth = windowWidth; }

        let startXPosition = 0;

        if (windowWidth > 768) { startXPosition = windowWidth - 400; }

        const videoStreamDefaultCoordinates = { x: startXPosition, y: 60, width: webcamWidth, height: 570 }

        const remoteUserScreenShareDefaultCoordinates = { x: 0, y: 0, width: 400, height: 256 };


        return <div className={`call-container fixed${this.props.darkmode ? ' darkmode' : ''}`}>
            {/* This prevented the UI from showing up at all until connected. */}
            {/* {this.props.remoteUserStream && <Rnd default={videoStreamDefaultCoordinates} disableDragging={mobileDevice}> */}

            {<Rnd default={videoStreamDefaultCoordinates} disableDragging={mobileDevice}>
                <div className="ongoing-call">
                    <div className="participant-heading">{this.props.otherParticipantFirstName + ' ' + this.props.otherParticipantLastName}</div>
                    <div className="video-content">
                        <div className="web-cam-container">
                            {!this.props.connected ? <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <IconCalling style={{ color: '#fff' }} />
                            </div> : this.props.remoteUserStream ? <video playsInline autoPlay style={{ width: '100%', height: '100%' }} ref={this.remoteUserVideoRef} /> : <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <IconVideoOff style={{ color: '#fff' }} />
                            </div>}
                        </div>
                        <div style={{ display: 'flex' }}>
                            {this.props.localUserStream && !mobileDevice && <div className={"web-cam-container minimized"}>
                                <video muted playsInline autoPlay style={{ maxWidth: '100%', maxHeight: '100%' }} ref={this.localUserVideoRef} />
                            </div>}
                            {this.props.localDisplayStream && <div className="my-screen-share-container">
                                <video muted playsInline autoPlay style={{ maxWidth: '100%', maxHeight: '100%' }} ref={this.localDisplayVideoRef} />
                            </div>}
                        </div>
                    </div>
                    <div className="call-controls">
                        <div className="extra-controls">
                            <button className={"ongoing-call-control-button" + (this.props.localDisplayStream ? ' enabled' : '')} onClick={this.toggleScreenCapture}>
                                <span className="tooltip-text">{this.props.localDisplayStream ?
                                    <Localized id="onetoonemeet-call-stop-share-tooltip">
                                        {'stop_sharing_tooltip'}
                                    </Localized>
                                    :
                                    <Localized id="onetoonemeet-call-share-tooltip">
                                        {'share_screen_tooltip'}
                                    </Localized>
                                }</span>
                                <IconShareScreen />
                            </button>
                        </div>

                        <button className={"ongoing-call-control-button" + (this.props.userStreamAudioDisabled ? ' disabled' : ' enabled')} onClick={this.toggleUserAudioDisable}>
                            <span className="tooltip-text">{this.props.userStreamAudioDisabled ?
                                <Localized id="onetoonemeet-call-unmute-tooltip">
                                    {'unmute_tooltip'}
                                </Localized>
                                :
                                <Localized id="onetoonemeet-call-mute-tooltip">
                                    {'mute_tooltip'}
                                </Localized>
                            }</span>
                            {this.props.userStreamAudioDisabled ? <IconMicrophoneMuted /> : <IconMicrophoneOn />}
                        </button>
                        <button className="ongoing-call-control-button hangup" onClick={this.hangup}>
                            <span className="tooltip-text">
                                <Localized id="onetoonemeet-call-hangup-tooltip">
                                    {'End call'}
                                </Localized>
                            </span>
                            <IconHangup size="26" />
                        </button>
                        <button className={"ongoing-call-control-button" + (this.props.userStreamVideoDisabled ? ' disabled' : ' enabled')} onClick={this.toggleUserVideoDisable}>
                            <span className="tooltip-text">{this.props.userStreamVideoDisabled ?
                                <Localized id="onetoonemeet-call-cameraon-tooltip">
                                    {'camera_on_tooltip'}
                                </Localized>
                                :
                                <Localized id="onetoonemeet-call-cameraoff-tooltip">
                                    {'camera_off_tooltip'}
                                </Localized>
                            }</span>
                            {this.props.userStreamVideoDisabled ? <IconVideoOff /> : <IconVideoOn />}
                        </button>
                        <button className="ongoing-call-control-button" onClick={this.showControls}>
                            <span className="tooltip-text">
                                <Localized id="onetoonemeet-call-settings-tooltip">
                                    {'settings_tooltip'}
                                </Localized>
                            </span>
                            <IconSettings size="15" />
                        </button>
                    </div>

                    {this.state.showControls && <div className="ongoing-call-controls">
                        {this.props.userMediaDevices && <div className="media-devices">
                            <div className="media-device-row">
                                <select className="media-device-select" name="videoInput" value={this.props.userMediaVideoDeviceId} onChange={this.onVideoInputChange}>
                                    {this.props.userMediaDevices.videoInput.map((e) => (<option key={e.id} value={e.id}>{e.label}</option>))}
                                </select>
                            </div>
                            <div className="media-device-row">
                                <select className="media-device-select" name="audioInput" value={this.props.userMediaAudioDeviceId} onChange={this.onAudioInputChange}>
                                    {this.props.userMediaDevices.audioInput.map((e) => (<option key={e.id} value={e.id}>{e.label}</option>))}
                                </select>
                            </div>
                        </div>}
                    </div>}
                </div>
            </Rnd>}

            {this.props.remoteDisplayStream && <div className="screen-share-container">
                <Rnd default={remoteUserScreenShareDefaultCoordinates} disableDragging={mobileDevice} className="rnd-override">
                    <div className="share-screen">
                        <div className="share-screen-heading">
                            <Localized id="onetoonemeet-share-screen-tooltip">
                                {'Jaettu ruutu (Tätä voi liikuttaa ja vaihtaa kokoa)'}
                            </Localized>
                        </div>
                        <video className="screen-share-video" playsInline autoPlay style={{ maxWidth: '100%', maxHeight: '100%' }} ref={this.remoteDisplayVideoRef} />
                    </div>
                </Rnd>
            </div>}
        </div >
    }


    onVideoInputChange = (ev) => {
        this.props.setUserMediaVideoDeviceId(ev.target.value);
    }

    onAudioInputChange = (ev) => {
        this.props.setUserMediaAudioDeviceId(ev.target.value);
    }

    hangup = () => {
        if (!this.props.busy) return;
        this.props.hangup({ user_id: this.props.callingUserId, channel_name: this.props.callingChannelName });
    }

    toggleUserAudioDisable = () => {
        if (!this.props.busy) return;
        this.props.setUserAudioDisabled(!this.props.userStreamAudioDisabled);
    }

    toggleUserVideoDisable = () => {
        if (!this.props.busy) return;
        this.props.setUserVideoDisabled(!this.props.userStreamVideoDisabled);
    }

    toggleScreenCapture = () => {
        if (this.props.localDisplayStream) {
            this.props.disableScreenshare();
        } else {
            this.props.enableScreenshare();
        }
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(OngoingCall);