import cloneDeep from "lodash/cloneDeep";
import * as a from "actiontypes";

export const pollsInitial =  {
    currentPolls: [],
    initialized: false
};

const polls = (state = pollsInitial, action) => {
    switch (action.type) {
        case "WEBSOCKET::POPULATE_CONFIGURATIONS":
            return {...state, currentPolls:action.payload.polls};
        case a.WS_VOTE_FOR_POLL:
            // TODO: add userID to this for own vote logic too?
            let newPolls = cloneDeep(state.currentPolls);
            const poll = newPolls.find(poll => poll.id === action.payload.poll_id);
            if (poll) {
                const option = poll.options.find(option => option.id === action.payload.answer_id);
                if (option) {
                    if (!option.votes) option.votes = 0;
                    option.votes++;
                }
            }
            return {...state, currentPolls: newPolls};
        case a.WS_REQUEST_POLL_RESULTS:
            return state
        case a.WS_POLLS_REQUESTED:
            return {currentPolls:action.payload, initialized: true};
        case a.WS_POLL_VOTED: {
            let newState = cloneDeep(state.currentPolls);
            for (let poll of newState) {
                let options = action.payload.find((incoming_data) => incoming_data.id === poll.id).options;
                poll.options = options;
            }
            return {...state, currentPolls: newState};
        }

        case a.WS_HAS_VOTED: {
            let newState = cloneDeep(state.currentPolls);
            for (let poll of newState) {
                let options = action.payload.find((incoming_data) => incoming_data.id === poll.id).options;
                if(!options.some(option => option.has_voted > 0)) poll.options = options;
            }
            return {...state, currentPolls: newState};
        }

        default:
            return state;
    }
};
export default polls;
