import '../css/skeleton.scss'

const EngagementSkeleton = () => {
    return (
        <div className="engagement-skeletor-container">
            <div className="top3-container">
                <div className="second"></div>
                <div className="first"></div>
                <div className="third"></div>
            </div>
            <div className="post">
                <div className="avatar"></div>
                <div className="line-container">
                    <div className="line"></div>
                    <div className="line"></div>
                </div>

            </div>

            <div className="post">
                <div className="avatar"></div>
                <div className="line-container">
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </div>

            <div className="post">
                <div className="avatar"></div>
                <div className="line-container">
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </div>
        </div>
    )
}

export default EngagementSkeleton