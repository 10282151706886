import { COMPONENT_CONFIGS, LAYOUT_PROPERTIES_WIDGETS } from 'containers/VirtualEvent/constants';
import { SettingContext } from 'containers/VirtualEvent/contexts';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { createWidgetComponent, WIDGET_COMPONENTS } from '../containers/widgets';
import { capitalizeFirstLetter, editActions, editContent, editName, editProperties, roomWidgets } from '../helpers/mainToolbarHelpers';
import Switch from "react-switch";
import OutsideClickHandler from 'react-outside-click-handler';
import { useSelector } from 'react-redux';

const ToolbarWidgets = (props) => {
  const { editor = {}, preventCollision, setPreventCollision } = props
  const contextProperties = useContext(SettingContext)
  const { activeItem, setActiveItem, view, editLocks, editorDispatch, links } = contextProperties
  const { data } = editor
  const [activeLink, setActiveLink] = useState('')
  const widgetsForComponent = roomWidgets(activeItem, setActiveItem, data, data?.components[view])

  let propertyWidgets = [];
  let actionWidgets = [];
  let contentWidgets = [];
  let elementAddButton = [];
  if (activeItem && widgetsForComponent) {
    const { type, preset, renderType } = widgetsForComponent
    if (activeItem.isRoom) {
      propertyWidgets = LAYOUT_PROPERTIES_WIDGETS;
    } else {
      const presetKey = type === 'component' ? preset : renderType;
      const config = COMPONENT_CONFIGS[presetKey === 'carousel' ? 'gallery' : presetKey][type]
      propertyWidgets = config['properties'];
      actionWidgets = props.actions ? Object.keys(props.actions).filter(action => config['actions'].includes(action)) : config['actions'];
      contentWidgets = config['content'] ? config['content'] : [];
      elementAddButton = config['can_add_elements']?.length > 0;
    }
  }

  const editorProps = {
    editLocks,
    activeLink,
    setActiveLink,
    activeItem,
    setActiveItem,
    editActions,
    editContent,
    editName,
    editProperties,
    editorDispatch,
    data,
    links,
    view
  }


  return (
    <div className="virtual-event-toolbar-widgets" key="virtual-event-toolbar-widgets-header">
      <div className="toolbar-widget-container switch">
        <div className="widget-wrapper">
          <div className="toolbar-single-widget-name">
            Fluid
          </div>
          <Switch onChange={(e) => setPreventCollision(!preventCollision)} checked={!preventCollision} onColor={'#8bC400'} offColor={'#C4C4C4'} />
        </div>
      </div>
      {contentWidgets?.map((widget, i) => <SingleWidget key={`content_${widget}`} {...roomWidgets(activeItem, setActiveItem, data, data?.components[view])} widget={widget} widgetType={"content"} widgetIndex={i} {...editorProps} />)}
      {propertyWidgets?.map((widget, i) => <SingleWidget key={`properties_${widget}`} {...roomWidgets(activeItem, setActiveItem, data, data?.components[view])} widget={widget} widgetType={"properties"} widgetIndex={i} {...editorProps} />)}
      {actionWidgets?.map((widget, i) => <SingleWidget key={`actions_${widget}`} {...roomWidgets(activeItem, setActiveItem, data, data?.components[view])} widget={widget} widgetType={"actions"} widgetIndex={i} {...editorProps} />)}
    </div>
  )
};

export default ToolbarWidgets;

/*
    Single Widget (dropdown from main toolbar)
*/

function useExcludeWidget(data, view, widget, parentId, type, elemType, isRoom) {
  if (type === 'component' || isRoom) return false
  const parentComponent = data.components[view].find(c => c.i === parentId)
  const { preset } = parentComponent
  const componentProps = COMPONENT_CONFIGS[preset === 'carousel' ? 'gallery' : preset]?.component
  if (!componentProps?.excludeWidgetsForInnerElements?.[elemType]) return false
  const widgetsToExclude = componentProps.excludeWidgetsForInnerElements[elemType]
  if (widgetsToExclude.includes(widget)) return true
  else return false
}

const SingleWidget = (props) => {
  const { widget, widgetType, renderType, setActiveLink, activeLink, activeItem, data, view, ...updatedProps } = props
  const { id, i, parentId, isRoom } = activeItem
  const isImageGalleryOpen = useSelector(state => state.editorProgress.showImageGallery)
  const widgetHandler = useSelector(state => state.editorProgress.widget)
  const identifiers = {
    component: props.type === 'element' ? parentId : id || i,
    element: props.type === 'element' ? id || i : null
  }
  const memoizedIdentifiers = useMemo(() => (identifiers), [identifiers.component, identifiers.element])
  const [showToolContainer, setShowToolContainer] = useState(false)
  const defaultWidget = COMPONENT_CONFIGS[widgetHandler.name]?.element.defaultWidget

  const excludeThisWidget = useExcludeWidget(data, view, widget, parentId, props.type, renderType, isRoom)

  useEffect(() => {
    if (widgetHandler.show && defaultWidget === widget) {
      setShowToolContainer(true)
      setActiveLink(widget)
    }
  }, [widgetHandler])

  const handleWidgetClick = (e) => {
    e.stopPropagation()
    setActiveLink(widget)
    setShowToolContainer(!showToolContainer)
  }

  const handleOutsideClick = (e) => {
    const tinymceDropdown = document.getElementsByClassName("tox-menu")
    const tinymceColorpicker = document.getElementsByClassName("tox-color-picker-container")
    const tinymceLink = document.getElementsByClassName("tox-dialog")
    const isTinymce = (tinymceDropdown.length > 0 || tinymceColorpicker.length > 0 || tinymceLink.length > 0)
    if (!isImageGalleryOpen && !isTinymce) setShowToolContainer(false) // Sorry, there is no other way
  }

  return (
    <>
      {!excludeThisWidget && <OutsideClickHandler onOutsideClick={(e) => handleOutsideClick(e)}>
        <div className={`toolbar-widget-container ${showToolContainer ? 'active' : ''}`} >
          <div className="widget-wrapper" onClick={(e) => handleWidgetClick(e)}>
            {WIDGET_COMPONENTS[widgetType][widget].button}
            <div className="toolbar-single-widget-name">
              {capitalizeFirstLetter(widget)}
            </div>
          </div>
          {(showToolContainer && activeLink === widget) &&
            <ToolContainer
              key={`widget_${widget}`}
              widget={widget}
              updatedProps={updatedProps}
              data={data}
              memoizedIdentifiers={memoizedIdentifiers}
              widgetType={widgetType}
              renderType={renderType}
            />}
        </div>
      </OutsideClickHandler>}
    </>
  )
}

const ToolContainer = (props) => {
  const { widget, updatedProps, data, memoizedIdentifiers, widgetType, renderType } = props
  return <div key={`widget_${widget}_container`} className={`tool-container ${widget}`}>
    {createWidgetComponent({ ...updatedProps, room: data, renderType }, memoizedIdentifiers, widget, widgetType)}
  </div>
}