import { useEffect, useState } from "react";
import { createFolderHierarchy } from "./helpers";

export function useUploadImages(images, setUploadImage) {
  useEffect(() => {
    setUploadImage(images);
  }, [images])
}

export function useHandleFolderHierarchy(folders, setFetchImages) {
  const hierarchy = createFolderHierarchy(folders)
  const [folderList, setFolderList] = useState(hierarchy); // Defines when to fetch images
  const [hierarchyHistory, setHierarchyHistory] = useState([])
  const [activeFolder, setActiveFolder] = useState("");
  const [folderQuery, setFolderQuery] = useState("");
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    setFolderList(hierarchy)
  }, [JSON.stringify(hierarchy), JSON.stringify(folders)])

  const filterHistory = (newIndex) => { return hierarchyHistory.filter((v, i) => (v.index <= activeIndex && v.index !== newIndex)) }
  const checkHistory = () => { return hierarchyHistory.find(obj => obj.index === activeIndex) }

  const handleHistory = () => {
    const newIndex = activeIndex + 1
    const newHistory = filterHistory(newIndex)
    if (!checkHistory()) {
      const newHistoryObj = { path: activeFolder, nodes: folderList, index: activeIndex }
      setHierarchyHistory([...newHistory, newHistoryObj])
    } else {
      setHierarchyHistory(newHistory)
    }
    setActiveIndex(newIndex)
  }

  const handleHome = () => {
    const newIndex = activeIndex + 1
    const newHistoryObj = { path: activeFolder, nodes: folderList, index: activeIndex }
    setHierarchyHistory(prev => [...prev, newHistoryObj])
    setActiveIndex(newIndex)
    setActiveFolder("")
    setFolderQuery("")
    setFolderList(hierarchy)
    setFetchImages(true)
  }

  const handleMove = (val, path) => {
    const moveToObj = path ? hierarchyHistory.find(obj => obj.path === path) : hierarchyHistory.find(obj => obj.index === val)
    if (!moveToObj || moveToObj?.path === activeFolder) return
    if (!checkHistory() || path) {
      setHierarchyHistory(prev => [...prev, { ...moveToObj, path: activeFolder, nodes: folderList, index: activeIndex }])
    }
    setFolderList(moveToObj.nodes)
    setActiveIndex(path ? (activeIndex + 1) : val)
    setActiveFolder(moveToObj.path)
    setFolderQuery(`&folder=${moveToObj.path}`)
    setFetchImages(true)
  }

  const handleSelectFolder = (path) => {
    setActiveFolder(path)
    setFolderQuery(`&folder=${path}`)
    setFetchImages(true)
  }

  const resetState = () => {
    setActiveFolder("")
    setHierarchyHistory([])
    setFolderQuery("")
    setActiveIndex(0)
  }

  return [
    folderList,
    setFolderList,
    handleMove,
    handleHistory,
    hierarchyHistory,
    handleSelectFolder,
    setFolderQuery,
    folderQuery,
    activeFolder,
    activeIndex,
    resetState,
    handleHome
  ]
}