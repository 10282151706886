import { videoConstraints, attemptVideoReapplyConstraints, attemptAudioReapplyConstraints } from 'util/webrtc';

const initialState = {
    userMediaVideoQuality: 'vga', // Check 'videoConstraints' for valid values, use low-ish quality by default

    // TODO: check if applyConstraints can change these on the fly nicely if not already
    userMediaVideoDeviceId: undefined,
    userMediaAudioDeviceId: undefined,
};

export const setUserMediaVideoQuality = (data) => ({
    type: 'SETTING_SET_USERMEDIA_VIDEO_QUALITY',
    payload: data,
});

export const setUserMediaVideoDeviceId = (data) => ({
    type: 'SETTING_SET_USERMEDIA_VIDEO_DEVICEID',
    payload: data,
});

export const setUserMediaAudioDeviceId = (data) => ({
    type: 'SETTING_SET_USERMEDIA_AUDIO_DEVICEID',
    payload: data,
});

const webrtcSettings = (state = initialState, action) => {
    switch (action.type) {

        case 'SETTING_SET_USERMEDIA_VIDEO_QUALITY': {
            if (action.payload in videoConstraints) {
                setImmediate(() => attemptVideoReapplyConstraints());
                return { ...state, userMediaVideoQuality: action.payload };
            } else {
                console.warn('SETTING_SET_USERMEDIA_VIDEO_QUALITY - Invalid value!', action.payload, videoConstraints);
                return state;
            }
        }

        case 'SETTING_SET_USERMEDIA_VIDEO_DEVICEID': {
            setImmediate(() => attemptVideoReapplyConstraints());
            return { ...state, userMediaVideoDeviceId: action.payload };
        }

        case 'SETTING_SET_USERMEDIA_AUDIO_DEVICEID': {
            setImmediate(() => attemptAudioReapplyConstraints());
            return { ...state, userMediaAudioDeviceId: action.payload };
        }

        default: return state
    }
}
export default webrtcSettings;
