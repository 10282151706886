/** @format */

import { SettingContext } from "containers/VirtualEvent/contexts";
import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import SaveRoomModal from "./modals/SaveRoomModal";
import { ToggleSwitchSimple } from "./ToggleSwitch";
import * as a from "actiontypes";
import { NavLink, useLocation } from "react-router-dom";
import { useAccessKey } from "../apiCalls";
import { GenericTooltip } from "components/ToolTip/Tooltip";

const mapStateToProps = state => ({
	lang: state.editorLanguage,
	modalData: state.editorProgress?.previewModal,
	languages: state.event.languages
});
const mapDispatchToProps = dispatch => ({
	setEditorLanguage: lang => dispatch({ type: "SET_EDITOR_LANGUAGE", payload: lang }),
	dispatchPreviewModalData: payload => dispatch({ type: a.HANDLE_PREVIEW_MODAL, payload })
});

const ToolbarActions = props => {
	const {
		setSaved,
		saveLoading,
		saveSuccess,
		showSaveStatus,
		setEditorLanguage,
		lang,
		lobby,
		dispatchPreviewModalData,
		modalData = {},
		saveDetail = null,
		languages = ["en", "fi"]
	} = props;
	const { showModal = false } = modalData;
	const settingsContext = useContext(SettingContext);
	const { setDarkMode, darkMode, roomSlug, roomData } = settingsContext;
	const [showSaveModal, setShowSaveModal] = useState(false);
	const location = useLocation();

	const queryString = useAccessKey();
	const redirectUrl = location.pathname.replace("/editor", "") + queryString;

	const handleSaveChanges = () => {
		setSaved(true);
		setShowSaveModal(true);
	};

	const handlePreview = () => {
		if (showModal) dispatchPreviewModalData({ showModal: false, previewData: null });
		else dispatchPreviewModalData({ showModal: true, previewData: roomData });
	};

	return (
		<div className="virtual-event-toolbar-actions">
			<select className="language-selector" defaultValue={lang} onChange={e => setEditorLanguage(e.target.value)}>
				{languages.map(lang => (
					<option key={`editor_toolbar_language_${lang}`} value={lang}>
						{lang}
					</option>
				))}
			</select>
			<GenericTooltip text="Dark mode toggle, only for editor" hide="100">
				<div>
					<ToggleSwitchSimple set={setDarkMode} darkMode={darkMode} name="Dark mode" />
				</div>
			</GenericTooltip>
			<div className="buttons">
				<div
					className={`editor-header-link action save-button ${darkMode ? "dark" : ""}`}
					onClick={handleSaveChanges}
				>
					Save
				</div>
				<div className={`editor-header-link action ${darkMode ? "dark" : ""}`} onClick={handlePreview}>
					Preview
				</div>
				<NavLink to={redirectUrl} target="_blank" rel="noopener noreferrer">
					<div className={`editor-header-link action ${darkMode ? "dark" : ""}`}>To event</div>
				</NavLink>
				{showSaveModal && (
					<SaveRoomModal
						redirectUrl={redirectUrl}
						showSaveModal={showSaveModal}
						setShowSaveModal={setShowSaveModal}
						roomSlug={roomSlug}
						saveLoading={saveLoading}
						saveSuccess={saveSuccess}
						showSaveStatus={showSaveStatus}
						saveDetail={saveDetail}
					/>
				)}
			</div>
		</div>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(ToolbarActions);
