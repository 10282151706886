import cloneDeep from 'lodash/cloneDeep';


const getUserLangPreference = () => {
    let lang = (navigator.languages && navigator.languages[0]) || // Chrome / Firefox
        navigator.language ||   // All browsers
        navigator.userLanguage; // IE <= 10

    let parsedLang = '';
    if (lang.indexOf('-') > 0) {
        parsedLang = lang.substring(0, lang.indexOf('-'));
    }
    if (parsedLang !== 'fi' && parsedLang !== 'en') {
        return 'en';
    }
    return parsedLang;
}

const initialState = {
    session: { expiring: false, ignored: false },
    language: getUserLangPreference(),
    guestuser: false,
    initGroups: true
};

const eventReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_GUEST_USER": return { ...state, guestuser: action.payload };

        case "SESSION_EXPIRING": return { ...state, session: { ...state.session, expiring: action.payload } };
        case "SESSION_IGNORED": return { ...state, session: { ...state.session, ignored: action.payload } };

        case "USER_FETCHED": {
            let newState = cloneDeep(state);
            for (let key in action.payload) {
                newState[key] = action.payload[key];
            }
            return newState;
        }
        case 'HANDLE_GROUPS': {
            let clonedState = cloneDeep(state)
            const { actionType, slug } = action.payload
            if (actionType === 'join') {
                clonedState.groups.push(slug)
            } else {
                let groups = clonedState.groups.filter(g => g !== slug)
                clonedState.groups = groups
            }
            return clonedState;
        }
        case 'INIT_GROUPS': {
            return { ...state, initGroups: action.payload}
        }
        case "GUEST_USER_FETCHED": {
            let newState = cloneDeep(state);
            for (let key in action.payload) {
                newState[key] = action.payload[key];
            }
            return newState;
        }
        case "SET_USER_DATA_FETCHED": {
            const newUserData = action.payload
            return { ...state, ...newUserData }
        };
        case 'EDIT_FIELD': return { ...state, [action.payload.key]: action.payload.value };
        case 'EDIT_NOTIFICATION_SETTINGS': return { ...state, notification_settings: action.payload };

        case 'SIGNOUT':
            window.location.reload();
            return initialState;
        default:
            return state;
    }
};

export default eventReducer;
