import DesktopView from "./desktopView";
import MobileView from "./mobileView";
import TabletView from "./tabletView";
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { HOST } from 'constants.js';
import { AUTH_HEADERS } from 'util/api';
import '../styles/virtual-event.scss';
import 'react-grid-layout/css/styles.css';
import { useAccessKey } from '../../editorView/apiCalls';
import * as a from 'actiontypes';
import { IconBan } from "components/icons";
import { Localized } from "@fluent/react";
import { Helmet } from "react-helmet";

const useFetchRoom =
    (slug, room_slug, roomLoad, roomFetched, roomLoaded, changeRoom, setPageTheme) => {
        const queryString = useAccessKey();
        useEffect(() => {
            let status = null;
            async function fetchRoom() {
                roomLoad();
                try {
                    const response = await fetch(`${HOST}api/v1/events/${slug}/lobby/${room_slug}/${queryString}`, { headers: AUTH_HEADERS })
                    const json = await response.json();
                    status = response.status;
                    if (response.ok) {
                        changeRoom(json.chat_room);
                        setPageTheme(json.theme);
                        roomFetched({ data: json })
                    } else {
                        roomFetched({ data: null })
                    }
                } catch (error) {
                    console.log(error)
                } finally {
                    roomLoaded({ status: status })
                }
            }
            if (room_slug) {
                fetchRoom();
            }
        }, [room_slug])// eslint-disable-line react-hooks/exhaustive-deps
    }


function useBackground(setBgStyle, setHasBg, room) {
    useEffect(() => {

        if (room?.data?.styles?.['background']) {
            setBgStyle({
                backgroundImage: room.data.styles['background'].image,
                backgroundSize: room.data.styles['background'].size,
                backgroundPosition: room.data.styles['background'].position,
                backgroundRepeat: room.data.styles['background'].repeat,
                backgroundColor: room.data.styles['background'].color,
            });
        } else {
            setBgStyle({});
        }
        setHasBg(true);
    }, [room])// eslint-disable-line react-hooks/exhaustive-deps
}

const mapStateToProps = state => ({
    connection: state.connection,
    room: state.room,
    chatrooms: state.chatrooms,
    userLanguage: state.user.language
})
const mapDispatchToProps = dispatch => ({
    changeRoom: payload => dispatch({ type: a.WS_CHANGE_ROOM, payload: payload }),
    joinRoom: payload => dispatch({ type: a.WS_JOIN_ROOM, payload: payload }),
    roomLoad: payload => dispatch({ type: 'ROOM_LOAD', payload: payload }),
    roomFetched: payload => dispatch({ type: 'ROOM_FETCHED', payload: payload }),
    roomLoaded: payload => dispatch({ type: 'ROOM_LOADED', payload: payload })
})


function ContentView(props) {
    const { setBgStyle, setHasBg, setPageTheme, params, room, bgStyle, hasBg, view, setShowChat, chatrooms, userLanguage } = props;
    const { slug, room_slug } = params;
    useBackground(setBgStyle, setHasBg, room);

    function changeRoom(room_id) {
        if (props.connection.connected) {
            if (room_id) {
                if (props.connection.joined_channels.includes(room_id)) {
                    props.changeRoom(room_id)
                } else {
                    props.joinRoom({ room_id: room_id })
                }
            } else {
                const mainRoom = chatrooms.find(ch => ch.main_room);
                // We can do this since user always connects to main channel
                if (mainRoom) {
                    props.changeRoom(mainRoom.room_id)
                }
            }
        }
    }
    useFetchRoom(slug,
        room_slug,
        props.roomLoad,
        props.roomFetched,
        props.roomLoaded,
        changeRoom,
        setPageTheme
    )

    if (room.loaded) {
        switch (room.status) {
            case 200: {

                const viewProps = {
                    slug: slug,
                    layout: room.data?.components[view] || [],
                    view: view,
                    room: room,
                    bgStyle: bgStyle,
                    hasBg: hasBg,
                    setShowChat: setShowChat,
                    helmet: <Helmet>
                        <title>{room.data?.name[userLanguage] ? `Virtual Events: ${room.data.name[userLanguage]}` : 'Virtual Events'}</title>
                    </Helmet>
                }

                switch (view) {
                    case 'desktop': return <DesktopView {...viewProps} />;
                    case 'tablet': return <TabletView {...viewProps} />;
                    case 'mobile': return <MobileView {...viewProps} />;
                    default: return null;
                }
            }
            case 401:
            case 402:
            case 403: return <div className={`virtual-event-content ${view}`}>
                <div className="unauthorized-content">
                    <IconBan size="120" style={{ color: 'red' }} />
                    <h2>
                        <Localized id="room-restricted-heading">
                            Restricted content
                        </Localized>
                    </h2>
                    <p><b>
                        <Localized id="room-restricted-why">
                            Why am i seeing this?
                        </Localized>
                    </b></p>

                    <p>
                        <Localized id="room-restricted-explanation">
                            The event organizer has set this room behind a paywall. If the tickets to this event are sold publicly, you might be able to buy the ticket to access this content from
                        </Localized>
                    </p>
                    <a href={`https://events.liveto.io/events/${slug}`}>{`https://events.liveto.io/${slug}`}</a>
                </div>
            </div>
            case 404: return <div className={`virtual-event-content ${view}`}>
                <div className="unauthorized-content">
                    <h1>404</h1>
                    <p><Localized id="room-404">Room not found</Localized></p>
                </div>
            </div>
            default: break;

        }
    }
    return <div></div>
}


export default connect(mapStateToProps, mapDispatchToProps)(ContentView);