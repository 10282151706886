import * as a from 'actiontypes'
import { cloneDeep } from 'lodash'

const initialState = {
    roomInProgress: {},
    addModal: {
        showModal: false,
        modalData: {}
    },
    previewModal: {
        showModal: false,
        previewData: null
    },
    showImageGallery: false,
    widget: {
        name: "",
        show: false
    },
    activeComponentRef: {
        components: [],
        active: null
    },
    rightNav: false,
};

const editorProgress = (state = initialState, action) => {
    switch (action.type) {
        case a.SET_ROOM_IN_PROGRESS: return { ...state, roomInProgress: action.payload };
        case a.SET_SHOW_ADD_MODAL: return { ...state, addModal: { ...state.addModal, showModal: action.payload } };
        case a.SET_ADD_MODAL_DATA: return { ...state, addModal: { ...state.addModal, modalData: action.payload } };
        case a.HANDLE_IMAGE_GALLERY: return { ...state, showImageGallery: action.payload };
        case a.HANDLE_RIGHT_NAV: return { ...state, rightNav: action.payload };
        case a.HANDLE_DEFAULT_WIDGET: return { ...state, widget: action.payload };
        case a.POPULATE_COMPONENT_REFS: {
          let components = cloneDeep(state.activeComponentRef.components)
          const incomingComponent = action.payload
          const { cmp } = incomingComponent
          let findComponent = components.find(c => c.cmp === cmp)
          let filteredComponents = []
          if (findComponent) filteredComponents = components.filter(c => c.cmp !== cmp)
          else filteredComponents = cloneDeep(components)
          const updatedComponents = [ ...filteredComponents, incomingComponent ]
          return { ...state, activeComponentRef: { ...state.activeComponentRef, components: updatedComponents } }
        }
        case a.SET_ACTIVE_COMPONENT_REF: {
            const components = cloneDeep(state.activeComponentRef.components)
            const activeComponent = components.find(c => c.cmp === action.payload)
            const activeRef = activeComponent ? activeComponent.ref : null
            return { ...state, activeComponentRef: { ...state.activeComponentRef, active: activeRef } }
        }
        case a.HANDLE_PREVIEW_MODAL: {
            const { showModal, previewData } = action.payload
            let updatedPreviewModal = cloneDeep(state.previewModal)
            updatedPreviewModal.showModal = showModal
            updatedPreviewModal.previewData = previewData
            return { ...state, previewModal: updatedPreviewModal }
        }
        default: return state;
    }
};

export default editorProgress;