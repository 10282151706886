import { HOST } from 'constants.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AUTH_HEADERS } from 'util/api';
import { useAccessKey } from './editorView/apiCalls';
import * as a from 'actiontypes'
import { combineAndSort } from './contentView/chat/helpers/combineAttendees';

export function useFetchWebsocketConfiguration(slug) {
    const [conf, setConf] = useState(null);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);
    useEffect(() => {
        async function fetchConfiguration() {
            setLoading(true);
            try {
                const response = await fetch(`${HOST}api/v1/chat/${slug}.json`)
                const json = await response.json();
                if (response.ok) {
                    setConf(json);
                }
                setStatus(response.status)
            } catch (error) {

            } finally {
                setLoading(false);
            }
        }

        fetchConfiguration();
    }, [])// eslint-disable-line react-hooks/exhaustive-deps
    return [conf, loading, status];
}


export function useFetchLobby(slug, lobbyLoad, lobbyFetched, lobbyLoaded, history, params) {
    const queryString = useAccessKey();
    const dispatch = useDispatch()
    useEffect(() => {
        let room = null;
        let status = null;
        async function fetchLobby() {
            lobbyLoad();
            try {
                const response = await fetch(`${HOST}api/v1/events/${slug}/lobby/${queryString}`, { headers: AUTH_HEADERS })
                const json = await response.json();
                status = response.status;

                if (response.ok) {
                    let data = {};
                    data.rooms = json.rooms;
                    data.styles = json.styles;
                    data.logo = json.logo;
                    data.enable_chat = json.enable_chat;
                    lobbyFetched({ data: json })
                    room = data.rooms.find(r => r.slug === json.landing_page).slug;
                }
                if (response.status === 404) dispatch({ type: 'LOBBY_ERROR_DETAIL', payload: { detail: json.detail } })
            } catch (error) {

            } finally {
                if (!params.room_slug && room) {
                    history.push(`/${slug}/${room}/${queryString}`)
                }
                lobbyLoaded({ status: status })

            }
        }

        fetchLobby();
    }, [])// eslint-disable-line react-hooks/exhaustive-deps
}

export function useFetchEventParticipants() {
    const [offlineUsers, setOfflineUsers] = useState([])
    const slug = useSelector(state => state.event.slug)
    const onlineUsers = useSelector(state => state.attendees);
    const queryString = useAccessKey();
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchParticipants = async () => {
            try {
                const response = await fetch(`${HOST}api/v1/events/${slug}/visitors/${queryString}`, { headers: AUTH_HEADERS })
                if (response.status === 200) {
                    const json = await response.json()
                    setOfflineUsers(json)
                }
            } catch (error) {
                console.error('Error fetching all users: ', error)
            } finally {
                dispatch({ type: a.WS_REQUEST_ATTENDEES })
            }
        }
        fetchParticipants()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch({ type: a.GET_ALL_ATTENDEES, payload: combineAndSort(onlineUsers, offlineUsers) })
    }, [onlineUsers, offlineUsers])

}

export function useFetchUserGroups() {
    const dispatch = useDispatch()
    const slug = useSelector(state => state.event.slug)
    const accessKey = useAccessKey()

    useEffect(() => {
        const fetchUserGroups = async () => {
            try {
                const response = await fetch(`${HOST}api/v1/events/${slug}/groups/${accessKey}`, { headers: AUTH_HEADERS })
                if (response.status === 200) {
                    const json = await response.json()
                    dispatch({ type: a.USER_GROUPS_LOADED, payload: json })
                }
            } catch (error) {
                console.error('Error fetching response groups', error)
            }
        }
        fetchUserGroups()
    }, [])
}

export function useHandleGroupMembership (action, group, slug) {
    const [status, setStatus] = useState(null)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [handled, setHandled] = useState(false)
    const dispatch = useDispatch()
    const accessKey = useAccessKey()

    useEffect(() => {
        const handleGroups = async () => {
            try {
                setLoading(true)
                const body = { action }
                const response = await fetch(`${HOST}api/v1/events/${slug}/groups/${group}/${accessKey}`, {
                    headers: AUTH_HEADERS,
                    body: JSON.stringify(body),
                    method: 'POST'
                })
                setStatus(response.status)
                if (response.ok) {
                    dispatch({ type: 'HANDLE_GROUPS', payload: { actionType: action, slug: group } })
                    setSuccess(true)
                } else setSuccess(false)
            } catch (err) {
                console.error('Error: ', err)
                setSuccess(false)
                setError(err)
            } finally {
                setHandled(false)
                setLoading(false)
            }
        }
        if (handled) handleGroups()
    }, [handled])
    return [status, error, success, loading, setHandled]
}

export const fetchUserGroups = async (slug, accessKey) => {
    let status
    let error
    let result

    try {
        const response = await fetch(`${HOST}api/v1/events/${slug}/groups/${accessKey}`, {
            headers: AUTH_HEADERS,
            method: 'GET'
        })
        status = response.status
        if (response.ok) result = await response.json()
    } catch (err) {
        console.error('Error: ', err)
        error = err
    } finally {
        return [result, status, error]
    }
}

export const refreshAuthToken = async () => {
    let ok = false
    let error = false
    let data
    let status

    try {
        const response = await fetch(`${HOST}api/login/refresh/`, {
            headers: AUTH_HEADERS,
            method: 'POST'
        })

        status = response.status

        if (response.ok) {
            const json = await response.json()
            ok = true
            data = json
        }
    } catch (error) {
        error = error
    } finally {
        return [ok, error, data, status]
    }
}

export function useEditNotificationSettings(data, setShowSuccess, setSuccess, setDisabled) {
    const [status, setStatus] = useState(null)
    const [loading, setLoading] = useState(false)
    const [saved, setSaved] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        const edit = async () => {
            try {
                setLoading(true)
                const response = await fetch(`${HOST}api/v1/users/me/`, {
                    headers: AUTH_HEADERS,
                    body: JSON.stringify({ notification_settings: data }),
                    method: 'PATCH'
                })
                setStatus(response.status)
                if (response.ok) {
                    const responseJson = await response.json()
                    const { notification_settings } = responseJson
                    dispatch({ type: 'EDIT_NOTIFICATION_SETTINGS', payload: notification_settings })
                    setSuccess(true)
                    setDisabled(true)
                }
                else setSuccess(false)
            } catch (error) {
                console.error("Error editing notification settings:", error.message)
                setSuccess(false)
            } finally {
                setLoading(false)
                setSaved(false)
                setShowSuccess(true)
            }
        }
        if (saved && data) edit()
    }, [saved])
    return [setSaved, loading, status]
}