import ElementWrapper from "containers/VirtualEvent/wrappers/elementWrapper";
import { parseElementStyle } from "../../constants";

export const SEPARATOR_ELEMENT_DEFAULT_PROPERTIES = {
    padding: '0 0 0 0',
    background: {
        "size": "contain",
        "color": "rgba(0,0,0,0)",
        "image": "",
        "repeat": "repeat",
        "position": "center"
    },
    display: 'flex',
    width: '100%',
    height: '100%',
}


export default function Separator(props) {
    const { content, properties, i } = props.elem;
    const { orientation, size, color, thickness } = content;
    const styles = {
        ...parseElementStyle(properties, SEPARATOR_ELEMENT_DEFAULT_PROPERTIES),
        width: orientation === 'horizontal' ? `${size}` : 'auto',
        height: orientation === 'vertical' ? `${size}` : 'auto',
        borderBottom: orientation === 'horizontal' ? `solid ${thickness} ${color}` : 'none',
        borderRight: orientation === 'vertical' ? `solid ${thickness} ${color}` : 'none',
    }
    const wrapperProps = {
        overrideStyle: styles,
        className: `separator-element-wrapper ${i}`,
        elem: props.elem
    }

    return <ElementWrapper {...wrapperProps}/>
}