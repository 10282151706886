import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ImageGallery from "../imageGallery/imageGallery";

const fallbackContent = '';

export default function ImageUrlWidget(props) {
    const { content = '', identifiers, editProperties, editContent, properties, editLocks, editorDispatch, room } = props;
    const { width = '100%' } = properties;
    const [url, setUrl] = useState(content);
    const [newWidth, setWidth] = useState(width.replace('%', ''));
    const dispatch = useDispatch()
    const showImageGallery = useSelector(state => state.editorProgress.showImageGallery)

    useEffect(() => {
        setUrl(content)
        setWidth(width.replace('%', ''))
    }, [content, identifiers, editProperties, width])

    const changeUrl = (value) => {
        editContent(identifiers, value, editorDispatch, room, editLocks)
    }

    const changeWidth = (e) => {
        const formattedWidth = `${e.target.value}%`
        editProperties(identifiers, 'width', formattedWidth, editorDispatch, room, editLocks)
    }

    const setWidthPreset = (width) => {
        const formattedWidth = `${width}%`
        editProperties(identifiers, 'width', formattedWidth, editorDispatch, room, editLocks)
    }


    function setFromGallery(img) {
        editContent(identifiers, img, editorDispatch, room, editLocks)
    }

    const handleGallery = (val) => {
        dispatch({ type: "HANDLE_IMAGE_GALLERY", payload: val })
    }

    return (
        <div className="tool-inner-container">
            IMAGE SETTINGS
            <div className="tools">
                <div className="tool">
                    URL
                    <input className="editor-menu-input"
                        value={content}
                        type="text"
                        onChange={e => changeUrl(e.target.value)}
                    />
                </div>

                <button className="editor-menu-button" onClick={e => handleGallery(!showImageGallery)}>Select from gallery</button>
                {content && <button className="editor-menu-button" onClick={() => changeUrl('')}>Remove image</button>}
                <div className="tool">
                    Image width
                    <input className="editor-menu-input" value={newWidth} type="number" onChange={changeWidth} />
                    <span>%</span>
                </div>
                <div>
                    Width presets
                    <div className="buttons">
                        <button className={"editor-menu-button" + (width === '25%' ? ' active' : '')} onClick={() => setWidthPreset(25)}>25%</button>
                        <button className={"editor-menu-button" + (width === '50%' ? ' active' : '')} onClick={() => setWidthPreset(50)}>50%</button>
                        <button className={"editor-menu-button" + (width === '75%' ? ' active' : '')} onClick={() => setWidthPreset(75)}>75%</button>
                        <button className={"editor-menu-button" + (width === '100%' ? ' active' : '')} onClick={() => setWidthPreset(100)}>100%</button>
                    </div>
                </div>

            </div>


            {showImageGallery && <ImageGallery showGallery={showImageGallery} setShowGallery={handleGallery} setFromGallery={setFromGallery} />}

            <div className="image-widget-preview" style={{ backgroundImage: `url(${url})` }}></div>
        </div>
    );
};