const initialState = {
    data: null,
    status: null,
    loaded: false,
    user_groups: [],
    detail: null
};

const lobbyReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOBBY_LOAD': return initialState;
        case 'LOBBY_FETCHED': return { ...state, data: action.payload.data };
        case 'LOBBY_ERROR_DETAIL': return { ...state, detail: action.payload.detail };
        case 'LOBBY_LOADED': return { ...state, loaded: true, status: action.payload.status };
        case 'USER_GROUPS_LOADED': return { ...state, user_groups: action.payload };
        default: return state;
    }
};

export default lobbyReducer;

