import React from "react";
import { connect } from 'react-redux';
import '../styles/widgets.scss';

const fallback = {
    type: 'none',
    link: '',
}

function parseLinks(rooms) {
    // Recursively parse room slugs from rooms
    let slugs = [];
    for (let room of rooms) {
        slugs.push({ slug: room.slug, name: room.name });
        if (room.children.length > 0) {
            const childSlugs = parseLinks(room.children);
            for (const childSlug of childSlugs) {
                slugs.push(childSlug);
            }
        }
    }
    return slugs;
}

const mapStateToProps = state => ({
    editorLanguage: state.editorLanguage
})

function LinkWidget(props) {
    const { identifiers, editActions, actions, links, editorLanguage, editorDispatch, room, editLocks } = props;
    const property = 'link';
    const value = (!actions || !actions[property]) ? fallback : actions[property];
    const { type, link } = value;

    const linkList = parseLinks(links);

    function changeLink(key, newValue) {
        const linkValue = { type, link };
        switch (key) {
            case 'type': {
                linkValue['type'] = newValue;
                if (newValue === 'inner') linkValue['link'] = linkList[0].slug
                break;
            }
            case 'link': linkValue['link'] = newValue; break;
            default: return;
        }

        editActions(identifiers, property, linkValue, editorDispatch, room, editLocks);
    }

    return (<div className="tool-inner-container">
        LINK
        <div className="tools">
            Select linking type
            <select className="widget-select" onChange={e => changeLink('type', e.target.value)}>
                <option default hidden selected>Select linking type</option>
                <option value="inner" selected={type === 'inner'}>Inner link (Link to virtual event inner content)</option>
                <option value="outer" selected={type === 'outer'}>Outer link (Link to other content, opens in new tab)</option>
                <option value="none" selected={type === 'none'}>No linking</option>
            </select>
        </div>

        {type === 'inner' && <div className="tools">
            Select inner link
            <select className="widget-select" onChange={e => changeLink('link', e.target.value)}>
                {linkList.map(l => (<option value={l.slug} selected={link === l.slug}>{l.name[editorLanguage]}</option>))}
                <option value="" selected={link === ''}>None</option>
            </select>
        </div>}
        {type === 'outer' && <div className="tools">
            Outer link e.g 'https://google.com'
            <div className="buttons">
                <input type="text" className="editor-menu-input" value={link} onChange={e => changeLink('link', e.target.value)} />
            </div>

        </div>}
    </div>);
};

export default connect(mapStateToProps, null)(LinkWidget)