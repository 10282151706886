/** @format */

import { statusHandler } from "containers/VirtualEvent/editorView/errors";
import { useEffect, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { CUID_COOKIE, AUTH_COOKIE, AUTH_COOKIE_TIMESTAMP } from "constants.js";
import { createCookie } from "util/cookies";
import * as a from "actiontypes";
import moment from "moment";

export function useOutsideClick(isOpen) {
	const [target, setTarget] = useState(null);
	useEffect(() => {
		if (isOpen) {
			function handleClickOutside(event) {
				setTarget(event.target);
			}

			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		} else {
			setTarget(null);
		}
	}, [isOpen]);
	return target;
}

export function useDetectResize(displayTypeFetched, view) {
	const { ref } = useResizeDetector({
		handleHeight: false,
		refreshMode: "debounce",
		refreshRate: 10,
		onResize: (width, height) => {
			if (width < 600) {
				if (view !== "mobile") {
					displayTypeFetched("mobile");
				}
			} else if (width < 1000 && width > 600) {
				if (view !== "tablet") {
					displayTypeFetched("tablet");
				}
			} else {
				if (view !== "desktop") {
					displayTypeFetched("desktop");
				}
			}
		},
	});
	return ref;
}

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

export function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowDimensions;
}

export function useCreateSuccessMessage(ok, successMsg, status) {
	const [msg, setMsg] = useState("");
	useEffect(() => {
		if (ok) setMsg(<span className="success">{successMsg}</span>);
		else if (status) setMsg(<span className="error">{statusHandler(status)}</span>);
	}, [ok, status, successMsg]);
	return msg;
}

export const handleLogout = (dispatch, additional) => {
	createCookie(CUID_COOKIE, "", 0);
	createCookie(AUTH_COOKIE, "", 0);
	createCookie(AUTH_COOKIE_TIMESTAMP, "", 0);
	if (additional) additional();
	dispatch({ type: a.WS_RESET_NOTIFICATIONS });
	setTimeout(() => {
		dispatch({ type: "SIGNOUT" });
		sessionStorage.removeItem("visited");
	}, 1000);
};

export const useCountdown = targetDate => {
	const countdownDate = moment(targetDate);
	const [countDown, setCountDown] = useState(0);
	useEffect(() => {
		const interval = setInterval(() => {
			setCountDown(moment().diff(countdownDate, "milliseconds"));
		}, 1000);

		return () => clearInterval(interval);
	}, [countdownDate]);

	return getReturnValues(countDown * -1);
};

const getReturnValues = countDown => {
	// calculate time left
	const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
	const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));

	return [days, hours, minutes];
};

export const generateDrawerClassNames = (str, theme, view) => {
	return {
		root: `drawer-${str}-root ${theme} ${view}`,
		inner: `drawer-${str}-inner ${theme} ${view}`,
		content: `drawer-${str}-content ${theme} ${view}`,
		header: `drawer-${str}-header ${theme} ${view}`,
		overlay: `drawer-${str}-overlay ${theme} ${view}`,
		title: `drawer-${str}-title ${theme} ${view}`,
		body: `drawer-${str}-body ${theme} ${view}`,
		close: `drawer-${str}-close ${theme} ${view}`,
		drawer: `drawer-${str}-drawer ${theme} ${view}`,
	};
};
