import { startCall, endCall, receiveSignal, userAudioDisabled, userVideoDisabled, startScreenCapture, stopScreenCapture } from "util/webrtc";
import * as a from 'actiontypes';

export default function webrtcMiddleware({ dispatch }) {
    return next => action => {
        try {
            switch (action.type) {
                case a.WRTC_RING: {
                    // console.log('webrtcMiddleware WRTC_RING', action.payload);
                    return next(action);
                }

                case a.WRTC_RINGING: {
                    // console.log('webrtcMiddleware WRTC_RINGING', action.payload);
                    return next(action);
                }

                case a.WRTC_RING_ACCEPT: {
                    // console.log('webrtcMiddleware WRTC_RING_ACCEPT', action.payload);
                    startCall(action.payload.user_id, action.payload.channel_name, false);
                    return next(action);
                }

                case a.WRTC_RING_ACCEPTED: {
                    // console.log('webrtcMiddleware WRTC_RING_ACCEPTED', action.payload);
                    startCall(action.payload.user_id, action.payload.channel_name, true);
                    return next(action);
                }

                case a.WRTC_RING_REJECT: {
                    // console.log('webrtcMiddleware WRTC_RING_REJECT', action.payload);
                    return next(action);
                }

                case a.WRTC_RING_REJECTED: {
                    // console.log('webrtcMiddleware WRTC_RING_REJECTED', action.payload);
                    return next(action);
                }

                case a.WRTC_RECV_SIGNAL: {
                    // console.log('webrtcMiddleware WRTC_RECV_SIGNAL', action.payload);
                    receiveSignal(action.payload.signal);
                    return next(action);
                }

                case a.WRTC_HANGUP: {
                    // console.log('webrtcMiddleware WRTC_HANGUP', action.payload);
                    endCall();
                    return next(action);
                }

                case a.WRTC_REMOTE_HANGUP: {
                    // console.log('webrtcMiddleware WRTC_REMOTE_HANGUP', action.payload);
                    endCall();
                    return next(action);
                }

                case a.WRTC_SET_USERADIODISABLED: {
                    userAudioDisabled(action.payload);
                    return next(action);
                }

                case a.WRTC_SET_USERVIDEODISABLED: {
                    userVideoDisabled(action.payload);
                    return next(action);
                }

                case a.WRTC_SCREENSHARE_ENABLE: {
                    startScreenCapture();
                    return next(action);
                }

                case a.WRTC_SCREENSHARE_DISABLE: {
                    stopScreenCapture();
                    return next(action);
                }

                default: return next(action);
            }
        } catch (err) {
            console.warn('webrtcMiddleware error', err);
            return next(action);
        }
    }
}