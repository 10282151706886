// export function uniqid(prefix: string = '') {
//   return prefix + Date.now() + '-' + (Math.random() * 100000).toFixed(0);
// }

// Taken from mole client
export function uniqid(prefix: string = '') {
  // from "nanoid" package
  const alphabet = 'bjectSymhasOwnProp-0123456789ABCDEFGHIJKLMNQRTUVWXYZ_dfgiklquvxz';
  let size = 10;
  let id = '';

  while (0 < size--) {
      id += alphabet[(Math.random() * 64) | 0];
  }

  return prefix + Date.now() + '-' + id;
}

// https://stackoverflow.com/questions/18391212/is-it-not-possible-to-stringify-an-error-using-json-stringify
if (!('toJSON' in Error.prototype))
Object.defineProperty(Error.prototype, 'toJSON', {
  value: function () {
    var alt = {};

    Object.getOwnPropertyNames(this).forEach(function (key) {
      alt[key] = this[key];
    }, this);

    return alt;
  },
  configurable: true,
  writable: true
});

// From mole
// const methodNotFound =
//   !this.methods[methodName] ||
//   typeof this.methods[methodName] !== 'function' ||
//   methodName === 'constructor' ||
//   methodName.startsWith('_') ||
//   this.methods[methodName] === Object.prototype[methodName];

// True means bad
export function safetyFilterFunctionName(methodName: string) {
  return methodName === 'constructor' || methodName.startsWith('_') || !!Object.prototype[methodName];
}

// Filters base object functions, constructors and anything starting with '_'
export function getAllFuncs(toCheck: any) {
  var props = [];
  var obj = toCheck;
  do {
    // if (obj instanceof Object) break;
    if (obj === Object.prototype) break;
    // console.log('o', obj);
    props = props.concat(Object.getOwnPropertyNames(obj));
  } while (obj = Object.getPrototypeOf(obj));

  return props.sort().filter(function(e, i, arr) {
    if (e === 'constructor') return false;
    if (e.startsWith('_')) return false;
    if (e!=arr[i+1] && typeof toCheck[e] == 'function') return true;
    return false;
  });
}

// export function immediate() {
//   if (global.setImmediate) {
//     return new Promise((resolve) => { global.setImmediate(resolve); });
//   } else if (global.requestAnimationFrame) {
//     return new Promise((resolve) => { global.requestAnimationFrame(resolve); });
//   } else if (global.setTimeout) {
//     return new Promise((resolve) => { global.setTimeout(resolve, 0); });
//   } else {
//     throw new Error('immediate in insane environment');
//   }
// }
