import { USE_SECURE_COOKIE, COOKIE_DOMAIN } from "../constants";

export const createCookie = (name, value, days) => {
  let expires = "";
  let date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  expires = "; expires=" + date.toGMTString();
  const cookieDomain = COOKIE_DOMAIN !== 'localhost' ? `domain=${COOKIE_DOMAIN};` : null;
  const secureCookie = USE_SECURE_COOKIE ? "secure;" : ""

  document.cookie = `${name}=${value};${expires}; path=/;${cookieDomain}${secureCookie}SameSite=Lax`;
};

export const deleteCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const getCookie = (cname) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};