import { createStore, applyMiddleware, compose } from "redux";
import { createLogger } from "redux-logger";
import { install as installReduxLoop } from 'redux-loop';

import rootReducer from "./reducers/rootReducer";
import thunk from "redux-thunk";
import websocketMiddleware from "middlewares/websocket";
import webrtcMiddleware from "middlewares/webrtc";
import analyticsMiddleware from "middlewares/analytics";
import { soundNotificationMiddleware } from "middlewares/sound";
import { initGroupsMiddleware, sessionMiddleware } from "middlewares/session";

const loadStore = () => {
    try {
        const serializedState = sessionStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};
const saveStore = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem('state', serializedState);
    } catch (err) { }
};

let middleware = [
    thunk, 
    websocketMiddleware, 
    webrtcMiddleware, 
    sessionMiddleware, 
    initGroupsMiddleware, 
    soundNotificationMiddleware, 
    analyticsMiddleware,
];
let composeEnhancers = compose;


if (process.env.NODE_ENV === "development") {
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
        composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    }
    const disabledActions = ['HEARTBEAT', 'HEARTBEAT_RECEIVED', 'SEND_HEARTBEAT']

    const logger = createLogger({
        timestamp: false,
        duration: false,
        predicate: (getState, action) => !disabledActions.includes(action.type),
        collapsed: (getState, action, logEntry) => !logEntry.error


    })
    middleware.push(logger);
}

const enhancer = composeEnhancers(installReduxLoop(), applyMiddleware(...middleware));
const persistedState = loadStore();
export const store = createStore(rootReducer, persistedState, enhancer);

store.subscribe(() => {
    saveStore({
        user: store.getState().user,
        event: store.getState().event,
        connection: store.getState().connection,
        inbox: store.getState().inbox,
        websocketUser: store.getState().websocketUser,
        notifications: store.getState().notifications,
    });
});

export default store;