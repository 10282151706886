/** @format */

import { useState, useEffect } from "react";

import { HOST, API_PATH } from "constants.js";
import { AUTH_HEADERS } from "util/api";

import { useDispatch, useSelector } from "react-redux";
import * as a from "actiontypes";
import { calculateScheduleDuration } from "./helpers/getScheludeDuration";
import { checkScheduleDuration } from "./helpers/getWeekHelper";
import { cloneDeep } from "lodash";

export function useFetchSchedules(slug, getSchedule) {
	const [performers] = useState([]);
	const [scheduleLoading, setScheduleLoading] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		async function fetchSchedules() {
			try {
				setScheduleLoading(true);
				const response = await fetch(`${HOST}api/v1/events/${slug}/schedule/?show_in=virtual`, {
					headers: AUTH_HEADERS,
				});
				const json = await response.json();
				if (response.ok) {
					//dispatch({ type: 'GET_SCHEDULE', payload: checkScheduleDuration(calculateScheduleDuration(json)) } )
					dispatch({ type: "GET_SCHEDULE", payload: json });
				}
			} catch (error) {
				console.error("error: ", error);
			} finally {
				setScheduleLoading(false);
			}
		}
		if (getSchedule) {
			fetchSchedules();
		}
	}, [getSchedule]); // eslint-disable-line react-hooks/exhaustive-deps
	return [scheduleLoading, performers];
}

export function useFetchCalendarData(eventSlug) {
	const dispatch = useDispatch();
	const [calendarLoading, setCalendarLoading] = useState(false);
	const activeUser = useSelector(state => state.user);

	useEffect(() => {
		async function fetchCalendar() {
			if (!activeUser.guestuser) {
				try {
					setCalendarLoading(true);
					const response = await fetch(`${HOST}api/v1/calendars/${eventSlug}/`, { headers: AUTH_HEADERS });
					const json = await response.json();
					dispatch({ type: a.GET_CALENDAR, payload: json });
				} catch (error) {
					console.error("calendar error", error);
				} finally {
					setCalendarLoading(false);
				}
			}
		}
		fetchCalendar();
	}, [activeUser, eventSlug]); // eslint-disable-line react-hooks/exhaustive-deps

	return { calendarLoading };
}

export function useFetchAttendeeCalendarData(eventSlug, person) {
	const dispatch = useDispatch();
	const [calendarStatus, setCalendarStatus] = useState(null);
	const [calendarLoading, setCalendarLoading] = useState(false);
	const [calendarVisibility, setCalendarVisibility] = useState(false);

	useEffect(() => {
		async function fetchCalendar() {
			try {
				setCalendarLoading(true);
				const response = await fetch(`${HOST}api/v1/calendars/${eventSlug}/?user=${person.id}`, {
					headers: AUTH_HEADERS,
				});
				const json = await response.json();
				json.user = person;

				if (response.status === 200) {
					setCalendarVisibility(true);
					dispatch({ type: a.GET_ATTENDEE_CALENDAR, payload: json });
				} else if (response.status === 404) {
					dispatch({ type: a.GET_ATTENDEE_CALENDAR, payload: null });
				}

				setCalendarStatus(response.status);
			} catch (error) {
				console.error("calendar error", error);
			} finally {
				setCalendarLoading(false);
			}
		}
		fetchCalendar();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return { calendarStatus, calendarLoading, calendarVisibility };
}

export const sendInvite = async (data, eventSlug, userData, me, dispatch) => {
	let status;
	let error;
	let newSlot;
	const { myChatID } = me;
	if (data) {
		const headers = AUTH_HEADERS;
		try {
			const response = await fetch(`${HOST}api/v1/calendars/${eventSlug}/slots/`, {
				headers: headers,
				method: "POST",
				body: JSON.stringify(data),
			});

			const res = await response.json();

			status = response.status;

			if (response.status === 201) {
				const invResponse = await fetch(`${HOST}api/v1/calendars/${eventSlug}/slots/${res.id}/invites/`, {
					headers: headers,
					method: "POST",
					body: JSON.stringify(userData),
				});

				const invResponseJson = await invResponse.json();

				dispatch({ type: a.CREATE_SLOT, payload: { slot: res, inv: invResponseJson, eventSlug, myChatID } });
				dispatch({ type: a.ADD_INVITE_ATTENDEE_CALENDAR, payload: { slot: res, invite: invResponseJson, me } });

				newSlot = res;
			}
		} catch (err) {
			error = err;
		} finally {
		}
	}
	return { status, error, newSlot };
};

// SEND AN INVITE
export function useSendInvite(save, data, eventSlug, userData) {
	const myChatID = useSelector(state => state.user.chat_user_id);
	const me = useSelector(state => state.user);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState(null);
	const [error, setError] = useState(null);
	const [newSlot, setNewSlot] = useState({});

	useEffect(() => {
		async function createSlot() {
			setLoading(true);
			const headers = AUTH_HEADERS;
			try {
				const response = await fetch(`${HOST}api/v1/calendars/${eventSlug}/slots/`, {
					headers: headers,
					method: "POST",
					body: JSON.stringify(data),
				});

				const res = await response.json();

				if (response.status === 201) {
					const invResponse = await fetch(`${HOST}api/v1/calendars/${eventSlug}/slots/${res.id}/invites/`, {
						headers: headers,
						method: "POST",
						body: JSON.stringify(userData),
					});

					const invResponseJson = await invResponse.json();

					dispatch({
						type: a.CREATE_SLOT,
						payload: { slot: res, inv: invResponseJson, eventSlug, myChatID },
					});
					dispatch({
						type: a.ADD_INVITE_ATTENDEE_CALENDAR,
						payload: { slot: res, invite: invResponseJson, me },
					});

					setNewSlot(res);
					setStatus(invResponse.status);
				}
			} catch (error) {
				setError(error);
			} finally {
				setLoading(false);
			}
		}
		if (save && data) {
			createSlot();
		}
	}, [save]); // eslint-disable-line react-hooks/exhaustive-deps
	return { status, loading, error, newSlot };
}

export const deleteTimeSlot = async (slot, dispatch, slug) => {
	let error;
	let status;

	const headers = AUTH_HEADERS;
	try {
		const response = await fetch(`${HOST}api/v1/calendars/${slug}/slots/${slot.id}/`, {
			headers: headers,
			method: "DELETE",
		});

		status = response.status;

		if (response.status === 204) {
			dispatch({ type: a.DELETE_SLOT, payload: slot });
			if (slot.status !== "available")
				dispatch({
					type: a.ADD_ALERT,
					payload: { variant: "success", type: "DELETE_INVITE", data: slot },
					setting: "other",
				});
		}
	} catch (err) {
		error = err;
	} finally {
	}
	return [status, error];
};

export const useAddEventSlot = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState(null);
	const [error, setError] = useState(null);
	const [ok, setOk] = useState(false);

	const addEventSlot = async (data, eventSlug) => {
		setLoading(true);

		try {
			const headers = AUTH_HEADERS;
			const response = await fetch(`${HOST}api/v1/calendars/${eventSlug}/event_timeslots/`, {
				headers: headers,
				method: "POST",
				body: JSON.stringify({ id: data.id }),
			});

			const json = await response.json();

			setStatus(response.status);
			setOk(response.ok);

			if (response.ok) {
				dispatch({ type: a.ADD_FAVORITE, payload: json });
				dispatch({
					type: a.ADD_ALERT,
					payload: { variant: "success", type: "ADD_FAVOURITE", data: json, setting: "other" },
				});
			}
		} catch (error) {
			console.error("Error adding event timeslot to favourites:", error);
			setError(error);
		} finally {
			setLoading(false);
		}
	};
	return { addEventSlot, status, loading, error, ok };
};

export const useDeleteEventSlot = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState(null);
	const [error, setError] = useState(null);
	const [ok, setOk] = useState(false);

	const deleteEventSlot = async (data, eventSlug) => {
		setLoading(true);
		if (data && eventSlug) {
			try {
				const headers = AUTH_HEADERS;
				const response = await fetch(
					`${HOST}api/v1/calendars/${eventSlug}/event_timeslots/${parseInt(data.id)}/`,
					{
						headers: headers,
						method: "DELETE",
					}
				);

				setStatus(response.status);
				setOk(response.ok);

				if (response.ok) {
					dispatch({ type: a.DELETE_FAVORITE, payload: data.id });
					dispatch({
						type: a.ADD_ALERT,
						payload: { variant: "success", type: "REMOVE_FAVOURITE", data, setting: "other" },
					});
				}
			} catch (error) {
				console.error("Error deleting event timeslot to favourites:", error);
				setError(error);
			} finally {
				setLoading(false);
			}
		}
	};
	return { deleteEventSlot, status, loading, error, ok };
};

// ACCEPTING AN INVITE
export function useAcceptInvite(save, data, ids, eventSlug) {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState(null);
	const [error, setError] = useState(null);
	const [inviteData, setInviteData] = useState(null);

	useEffect(() => {
		async function acceptInvite() {
			setLoading(true);
			const headers = AUTH_HEADERS;

			try {
				const response = await fetch(`${HOST}api/v1/calendars/${eventSlug}/invites/${ids.id}/`, {
					headers: headers,
					method: "PATCH",
					body: JSON.stringify(data),
				});

				if (response.status === 200) {
					const res = await response.json();
					dispatch({ type: a.HANDLE_INVITE, payload: res });
					dispatch({
						type: a.ADD_ALERT,
						payload: { variant: "success", type: "ACCEPT_INVITE", data: res.slot, setting: "other" },
					});

					setInviteData(res);
				}
				setStatus(response.status);
			} catch (error) {
				console.error(error);
				setError(error);
			} finally {
				setLoading(false);
			}
		}
		if (save && data) {
			acceptInvite();
		}
	}, [save]); // eslint-disable-line react-hooks/exhaustive-deps
	return { status, loading, inviteData, error };
}

// REJECTING AN INVITE
export function useRejectInvite(save, ids, eventSlug) {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState(null);
	const [error, setError] = useState(null);
	const [inviteRejData, setInviteRejData] = useState(null);
	useEffect(() => {
		async function rejectInvite() {
			setLoading(true);
			const headers = AUTH_HEADERS;

			try {
				const response = await fetch(`${HOST}api/v1/calendars/${eventSlug}/invites/${ids.id}/`, {
					headers: headers,
					method: "DELETE",
				});

				if (response.status === 200) {
					const res = await response.json();
					dispatch({ type: a.HANDLE_INVITES, payload: res.slot });
					dispatch({
						type: a.ADD_ALERT,
						payload: { variant: "success", type: "REJECT_INVITE", data: res.slot, setting: "other" },
					});

					setInviteRejData(res);
				}
				setStatus(response.status);
			} catch (error) {
				console.error(error);
				setError(error);
			} finally {
				setLoading(false);
			}
		}
		if (save && ids) {
			rejectInvite();
		}
	}, [ids]); // eslint-disable-line react-hooks/exhaustive-deps
	return { status, loading, inviteRejData, error };
}
// CANCEL AN INVITE
export function useCancelInvite(save, slotData, eventSlug) {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		async function cancelInvite() {
			setLoading(true);
			const headers = AUTH_HEADERS;

			try {
				let apiUrl = "";
				let apiMethod = "DELETE";
				let apiBody = {};

				if (slotData.isDuplicate) {
					apiUrl = `${HOST}api/v1/calendars/${eventSlug}/invites/${slotData.id}/`;
					apiMethod = "PATCH";
					apiBody = {
						status: "rejected",
						invitee_status: "busy",
					};
				} else if (slotData.sent_invites[0].status === "accepted") {
					apiUrl = `${HOST}api/v1/calendars/${eventSlug}/slots/${slotData.id}/invites/${slotData.sent_invites[0].user.user_id}/`;
				} else {
					apiUrl = `${HOST}api/v1/calendars/${eventSlug}/slots/${slotData.id}/invites/${slotData.sent_invites[0].user.user_id}/`;
				}

				const response = await fetch(apiUrl, {
					headers: headers,
					method: apiMethod,
					body: JSON.stringify(apiBody),
				});

				if (response.status === 204) {
					if (slotData.sent_invites[0].status === "accepted") {
						dispatch({ type: a.DELETE_SLOT, payload: slotData });
					} else {
						dispatch({ type: a.HANDLE_INVITES, payload: slotData });
					}

					dispatch({
						type: a.REMOVE_INVITE_FROM_ATTENDEE_CALENDAR,
						payload: { isReceived: true, data: slotData },
					});
				} else if (response.status === 200) {
					dispatch({ type: a.DELETE_SLOT, payload: slotData });
					dispatch({
						type: a.ADD_ALERT,
						payload: {
							variant: "success",
							type: "CANCEL_OUTGOING_INVITE",
							data: slotData.received_invite,
							setting: "other",
						},
					});
				}

				setStatus(response.status);
			} catch (error) {
				setError(error);
			} finally {
				setLoading(false);
			}
		}
		if (save && slotData) {
			cancelInvite();
		}
	}, [slotData]); // eslint-disable-line react-hooks/exhaustive-deps
	return { status, loading, error };
}

// CANCEL AN INVITE
export async function cancelInvite(slotData, eventSlug, dispatch, myChatID) {
	const headers = AUTH_HEADERS;
	let status;
	let error;
	let ok;

	try {
		let apiUrl = "";
		let apiMethod = "DELETE";
		let apiBody = {};

		if (slotData.isDuplicate) {
			apiUrl = `${HOST}api/v1/calendars/${eventSlug}/invites/${slotData.id}/`;
			apiMethod = "PATCH";
			apiBody = {
				status: "rejected",
				invitee_status: "busy",
			};
		} else if (slotData.sent_invites[0].status === "accepted") {
			apiUrl = `${HOST}api/v1/calendars/${eventSlug}/slots/${slotData.id}/invites/${slotData.sent_invites[0].user.user_id}/`;
		} else {
			apiUrl = `${HOST}api/v1/calendars/${eventSlug}/slots/${slotData.id}/invites/${slotData.sent_invites[0].user.user_id}/`;
		}
		const response = await fetch(apiUrl, {
			headers: headers,
			method: apiMethod,
			body: JSON.stringify(apiBody),
		});
		status = response.status;
		ok = response.ok;
		if (response.status === 204) {
			if (slotData.sent_invites[0].status === "accepted") {
				dispatch({ type: a.DELETE_SLOT, payload: slotData });
			} else {
				dispatch({ type: a.HANDLE_INVITES, payload: slotData });
			}
			dispatch({
				type: a.REMOVE_INVITE_FROM_ATTENDEE_CALENDAR,
				payload: { isReceived: true, data: slotData, myChatID },
			});
		} else if (response.status === 200) {
			dispatch({ type: a.DELETE_SLOT, payload: slotData });
			if (!slotData.isDuplicate)
				dispatch({
					type: a.ADD_ALERT,
					payload: {
						variant: "success",
						type: "CANCEL_OUTGOING_INVITE",
						data: slotData.received_invite,
						setting: "other",
					},
				});
			else
				dispatch({
					type: a.ADD_ALERT,
					payload: { variant: "success", type: "CANCEL_INCOMING_INVITE", data: slotData, setting: "other" },
				});
		}
	} catch (err) {
		error = err;
	} finally {
	}
	return { status, error, ok };
}

export function useFetchInvites(eventSlug, newNotification, invite) {
	const dispatch = useDispatch();
	const activeUser = useSelector(state => state.user);

	useEffect(() => {
		async function fetchInvites() {
			if (!activeUser.guestuser) {
				try {
					const response = await fetch(`${HOST}api/v1/calendars/${eventSlug}/invites/`, {
						headers: AUTH_HEADERS,
					});
					const json = await response.json();
					dispatch({ type: "GET_NOTIFICATIONS", payload: json });
				} catch (error) {
					console.error("Error fetching invites", error);
				} finally {
				}
			}
		}
		fetchInvites();
	}, [newNotification, invite]); // eslint-disable-line react-hooks/exhaustive-deps
}

export async function createTimeSlot(slotData, dispatch, slug) {
	let status;
	let error;

	try {
		const data = {
			start_time: slotData.start_time,
			end_time: slotData.end_time,
			name: "Available",
			description: "This slot is bookable",
			status: "available",
		};
		const result = await fetch(`${HOST}api/v1/calendars/${slug}/slots/`, {
			headers: AUTH_HEADERS,
			method: "POST",
			body: JSON.stringify(data),
		});

		if (result.status === 201) {
			const json = await result.json();
			dispatch({ type: a.CREATE_AVAILABLE_SLOT, payload: json });
		}
		status = result.status;
	} catch (err) {
		error = err;
	} finally {
	}
	return [status, error];
}

export const handleCalendarVisibility = async (calendarVisibility, slug) => {
	let error;
	let status;
	let result;

	try {
		const body = { visibility: calendarVisibility };
		const response = await fetch(`${API_PATH}calendars/${slug}/`, {
			headers: AUTH_HEADERS,
			method: "PATCH",
			body: JSON.stringify(body),
		});
		status = response.status;
		const json = await response.json();
		result = json;
	} catch (err) {
		error = err;
	} finally {
		return [result, status, error];
	}
};
