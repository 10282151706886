import React, { useEffect, useState, useContext } from 'react';
import { SettingContext } from '../../contexts';
import { connect } from 'react-redux';
import * as a from 'actiontypes';
import { Poll } from 'containers/VirtualEvent/contentView/polls/polls';
import ElementWrapper from 'containers/VirtualEvent/wrappers/elementWrapper';
import { ELEMENT_DEFAULT_PROPERTIES } from 'containers/VirtualEvent/constants';
import { usePolls } from 'containers/VirtualEvent/contentView/hooks/pollHelpers';
import moment from 'moment';

const mapStateToProps = state => ({
    connection: state.connection,
    polls: state.polls.currentPolls,
    initialized: state.polls.initialized
})

const mapDispatchToProps = dispatch => ({
    voteForPoll: (payload) => dispatch({ type: a.WS_WANT_TO_VOTE_FOR_POLL, payload: payload })
})

const PollElement = (props) => {

    const { elem, voteForPoll, connection, editorMode, polls, initialized } = props;
    const { content, i } = elem;
    const [currentPoll, setCurrentPoll] = useState(null);

    const [time, filteredPolls] = usePolls(polls, editorMode, true);
    // Polls added as elements are shown even as hidden (not showing in the main polls tab)



    useEffect(() => {
        if (polls) {
            setCurrentPoll(polls.find(poll => poll.id === content.id))
        }
    }, [content, polls])

    const show = () => {
        const questionBeforeStartAfter = currentPoll.show_question_before_start && moment(currentPoll.start_time).isSameOrAfter(moment())
        const startBefore = moment(currentPoll.start_time).isSameOrBefore(moment())
        const endAfter = moment(currentPoll.end_time).isAfter(moment())
        return (editorMode || ((questionBeforeStartAfter || startBefore) && endAfter))
    }

    // Hiding polls
    const showPoll = currentPoll ? show() : false

    const wrapperProps = {
        elem: elem,
        defaultStyle: ELEMENT_DEFAULT_PROPERTIES,
        className: `poll-element ${elem.i}`
    }
    return <ElementWrapper {...wrapperProps}>
        {(currentPoll && showPoll) && <Poll time={time} initialized={initialized} {...currentPoll} editorMode={editorMode} voteForPoll={voteForPoll} keySuffix={i} />}
    </ElementWrapper>

}
export default connect(mapStateToProps, mapDispatchToProps)(PollElement);