/** @format */

import cloneDeep from "lodash/cloneDeep";
import * as a from "actiontypes";
import moment from "moment";

const initial = {
	connected: false,
	users_online: null,
	joined_channels: [],
	active_channel: null,
	reconnected: false, // observe if chat is reconnected, fetch chat history if true
	event: null,
	heartbeatTimestamp: null,
	channelSettings: {},
};

const connection = (state = initial, action) => {
	switch (action.type) {
		case "WEBSOCKET::POPULATE_CONFIGURATIONS":
			return { ...state, connected: true, users_online: 0, event: action.payload.event };

		case a.WS_OPEN_CONNECTION:
			return { ...state, connected: true, users_online: 0 };

		case a.WS_CLOSE_CONNECTION:
			return { ...state, connected: false, users_online: "-" };

		case a.WS_CONNECTION_ESTABLISHED:
			return { ...state, user_id: action.payload };

		case a.WS_REQUEST_CLIENT_TOKEN:
			return { ...state, joined_channels: [] };

		case a.WS_RECONNECTED: {
			let newState = cloneDeep(state);
			let joined_channels = [];
			let rooms_to_ignore = [];
			for (let key in action.payload.inboxes) {
				rooms_to_ignore.push(action.payload.inboxes[key].room_id);
			}
			for (let room of action.payload.joined_rooms) {
				if (!rooms_to_ignore.includes(room)) {
					joined_channels.push(room);
				}
			}
			newState.joined_channels = joined_channels;
			newState.reconnected = true;
			newState.connected = true;
			return newState;
		}

		case a.WS_ROOM_JOINED: {
			let newState = cloneDeep(state);
			if (!newState.joined_channels.includes(action.payload.room_id)) {
				newState.joined_channels.push(action.payload.room_id);
			}
			newState.active_channel = action.payload.room_id;
			return newState;
		}

		case a.WS_CHANGE_ROOM:
			return { ...state, active_channel: action.payload };

		case a.WS_RESET_CONNECTION:
			return { ...state, connected: false };

		case a.WS_REQUEST_CHAT_HISTORY:
			return { ...state, waitForChatHistoryResponse: true };
		case a.WS_UPDATE_CHAT_HISTORY:
			return { ...state, waitForChatHistoryResponse: false };

		case a.WS_REQUEST_ATTENDEES:
			return { ...state, waitForAttendeesListing: true };
		case a.WS_ATTENDEES_REQUESTED:
			return { ...state, waitForAttendeesListing: false };
		case a.WS_HEARTBEAT_RECEIVED: {
			let users_online = cloneDeep(state.users_online);
			if (action.payload) {
				users_online = action.payload.attendees_count;
			}
			return { ...state, users_online: users_online, heartbeatTimestamp: moment().format("YYYY-MM-DD HH:mm:ss") };
		}
		case a.SET_CHANNEL_SETTINGS: {
			let newChannelSettings = state.channelSettings ? cloneDeep(state.channelSettings) : {};
			newChannelSettings[action.payload.room_id] = action.payload.restricted;
			return { ...state, channelSettings: newChannelSettings };
		}
		default:
			return state;
	}
};
export default connection;
