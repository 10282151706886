import React, { useEffect, useState } from "react";
import '../styles/widgets.scss';

const fallbackShadow = 'none';

export default function DropShadowWidget(props) {
    const { identifiers, editProperties, properties, editLocks, editorDispatch, room } = props;
    const property = 'dropShadow';
    const value = properties[property] ? properties[property] : fallbackShadow;
    const shadows = {
        sm: 'drop-shadow(2px 2px 2px rgba(0, 0, 0,.5))',
        md: 'drop-shadow(2px 2px 4px rgba(0, 0, 0,.7))',
        lg: 'drop-shadow(2px 2px 4px rgba(0, 0, 0,.8))',
        none: 'none'
    }

    const [shadow, setShadow] = useState(getShadowKey(value));

    function getShadowKey(shadowValue) {
        for (let key in shadows) {
            if (shadows[key] === shadowValue) {
                return key;
            }
        }
    }

    function editProperty(value) {
        editProperties(identifiers, property, shadows[value], editorDispatch, room, editLocks);
    }

    useEffect(() => {
        setShadow(getShadowKey(value))
    }, [value, identifiers, editProperties, property]);

    return (<div className="tool-inner-container">
        <div className="tool">
            <button className={"simple-button" + (shadow === 'none' ? ' active' : '')} onClick={() => editProperty("none")}>
                No shadow
            </button>
        </div>
        <div className="tool">
            <button className={"simple-button" + (shadow === 'sm' ? ' active' : '')} onClick={() => editProperty("sm")}>
                Small
            </button>
        </div>
        <div className="tool">
            <button className={"simple-button" + (shadow === 'md' ? ' active' : '')} onClick={() => editProperty("md")}>
                Medium
            </button>
        </div>
        <div className="tool">
            <button className={"simple-button" + (shadow === 'lg' ? ' active' : '')} onClick={() => editProperty("lg")}>
                Large
            </button>
        </div>
    </div>);
};