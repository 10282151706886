
const initial = {
    allow_anonymous: false,
    event: null,
};

const config = (state = initial, action) => {
    switch (action.type) {
        case "WEBSOCKET::POPULATE_CONFIGURATIONS":
            return { ...state, allow_anonymous: action.payload.allow_anonymous, event: action.payload.event };
        default:
            return state;
    }
};
export default config;
