const initialState = {
    replyTo: null
};

const messages = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_QUOTED_MESSAGE': return { ...state, replyTo: action.payload };
        default: return state;
    }
};

export default messages;

