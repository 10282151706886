/** @format */

import { connect } from "react-redux";
import { CUID_COOKIE, AUTH_COOKIE, AUTH_COOKIE_TIMESTAMP } from "constants.js";
import React, { useEffect, useState } from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import "./styles/virtual-event.scss";
import Header from "./header/header";
import { Helmet } from "react-helmet";
import { SettingContext } from "../contexts";
import { defaultSettings } from "../contexts";
import { useFetchLobby, useFetchWebsocketConfiguration } from "../apiCalls";
import NotificationCenter from "./notificationCenter/NotificationCenter";
import { useAcceptInvite, useRejectInvite } from "../schedule/apiCalls";
import moment from "moment";

import ContentView from "./contents/content";
import OnetoOneVideoMeet from "1-to-1-video/onetoOneVideoMeet";
import Navigator from "../navigator/navigator";
import Chat from "./chat/chat";
import defaultHeroImage from "static/img/hero-image.jpg";
import { Localized } from "@fluent/react";
import ErrorHandler from "components/ErrorHandler/errorHandler";
import { ContentErrorComponent } from "./errorComponents/errorComponents";
import { NavigatorErrorComponent } from "./errorComponents/errorComponents";
import { HeaderErrorComponent } from "./errorComponents/errorComponents";
import LoaderView from "LoaderView/loaderview";
import { createCookie } from "util/cookies";
import { IconArrowsLeft, IconBars, RoomsIcon } from "components/icons";
import { useFetchSchedules } from "../schedule/apiCalls";
import PushNotification from "../notification/PushNotification";
import { useFetchEventParticipants } from "../apiCalls";
import { ChatIcon } from "components/icons";
import { clearWatchTimer, setWatchTimer } from "middlewares/websocket";
import * as a from "actiontypes";
import { usePolls } from "./hooks/pollHelpers";
import { usePossibleLanguages } from "util/localization";
import { generateDrawerClassNames, useDetectResize, useNavigator } from "./hooks/hooks";
import { ErrorPage } from "../editorView/errors";
import WarningModal from "../warnings/sessionWarning";
import { MaintenancePage } from "../editorView/errors";
import GroupSelectorModal from "./components/groupSelector";
import { Drawer } from "@mantine/core";
import { saveVisit } from "App";

const mapStateToProps = state => ({
	heartbeatTimestamp: state.connection.heartbeatTimestamp,
	lobby: state.lobby,
	event: state.event,
	calendar: state.schedule.calendar,
	notifications: state.notifications.notifications,
	room: state.room,
	view: state.displayType,
	isEngagement: state.user.event_settings?.engagement,
	isGuestuser: state.user?.guestuser,
	sound: state.notifications.sound,
	showChat: state.components.showChat,
	user: state.user
});
const mapDispatchToProps = dispatch => ({
	populateWebsocketConfigurations: conf => dispatch({ type: "WEBSOCKET::POPULATE_CONFIGURATIONS", payload: conf }),
	lobbyLoad: () => dispatch({ type: "LOBBY_LOAD" }),
	lobbyFetched: payload => dispatch({ type: "LOBBY_FETCHED", payload: payload }),
	lobbyLoaded: payload => dispatch({ type: "LOBBY_LOADED", payload: payload }),
	signOut: () => dispatch({ type: "SIGNOUT" }),
	displayTypeFetched: displayMode => dispatch({ type: "GET_DISPLAY_TYPE", payload: displayMode }),
	handleNotificationCenter: payload => dispatch({ type: a.HANDLE_NOTIFICATION_CENTER, payload }),
	setShowChat: payload => dispatch({ type: "HANDLE_SHOW_CHAT", payload })
});

function useAnalytics() {
	useEffect(() => {
		if (window.sp) {
			const COOKIE_PREFIX = process.env.REACT_APP_COOKIE_PREFIX;
			const sp = window.sp;
			const allow_pii = (document.cookie || "").indexOf(`${COOKIE_PREFIX}_auth`) !== -1;
			const allow_cookies = allow_pii || (document.cookie || "").indexOf("cookie_consent=true") !== -1;
			sp("newTracker", "sp", process.env.REACT_APP_COLLECTOR_DOMAIN, {
				appId: "virtual-event",
				platform: "web",
				contexts: { webPage: true },
				anonymousTracking: allow_pii
					? false
					: { withSessionTracking: allow_cookies, withServerAnonymisation: true },
				eventMethod: "post",
				stateStorageStrategy: allow_cookies ? "cookieAndLocalStorage" : "none",
				bufferSize: 1,
				forceSecureTracker: true
			});
			sp("enableActivityTracking", 30, 30);
			if (allow_pii) {
				const user = (document.cookie || "").match(`${COOKIE_PREFIX}_cuid=([a-zA-Z0-9]+);?`);
				if (user) sp("setUserId", user[1]);
			}
			sp("trackPageView");
			window.sp_tracking = true;
		}
	}, [window.sp]); // eslint-disable-line react-hooks/exhaustive-deps
}

function VirtualEventView(props) {
	const ref = useDetectResize(props.displayTypeFetched);
	const [pageTheme, setPageTheme] = useState("light");
	const [showInbox, setShowInbox] = useState(false);
	const [showSchedule, setShowSchedule] = useState(false);
	const [showPolls, setShowPolls] = useState(false);
	const [showCalendar, setShowCalendar] = useState(false);
	const [bgStyle, setBgStyle] = useState(null);
	const [hasBg, setHasBg] = useState(false);
	const history = useHistory();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const {
		lobby = {},
		calendar,
		notifications,
		room = {},
		view = "desktop",
		polls,
		event,
		handleNotificationCenter,
		sound = false,
		showChat,
		setShowChat,
		user
	} = props;
	const { guestuser: isGuestuser = false, event_settings = {}, initGroups = false } = user;
	const { engagement: isEngagement = false } = event_settings;

	const { slug } = props.match.params;
	useFetchLobby(slug, props.lobbyLoad, props.lobbyFetched, props.lobbyLoaded, history, props.match.params);
	useAnalytics();
	const { data: lobbyData = {}, user_groups = [] } = lobby;
	const joinableGroups = user_groups?.filter(g => g.joinable);
	const selectGroup = joinableGroups?.length > 0 && !initGroups && lobbyData?.require_group_join && !isGuestuser;
	const [conf, confLoading, confStatus] = useFetchWebsocketConfiguration(slug);
	const websocketConf = { conf, confLoading, confStatus };
	const [isNotificationsVisible, setIsNotificationsVisible] = useState(false);
	const [invite, setInvite] = useState(false);
	const [save, setSave] = useState(false);
	const [saveRej, setSaveRej] = useState(false);
	const [data, setData] = useState(null);
	const [ids, setIds] = useState(null);

	const [showGroupModal, setShowGroupModal] = useState(false);

	const [scheduleLoading, dates, schedules, timeslots] = useFetchSchedules(slug, true);

	useAcceptInvite(save, data, ids, slug);
	useRejectInvite(saveRej, ids, slug);

	const eventLanguageOptions = usePossibleLanguages(slug);

	useFetchEventParticipants();

	useEffect(() => {
		if (confStatus === 200 && !confLoading) {
			props.populateWebsocketConfigurations(conf);
		}
		setShowGroupModal(selectGroup);
	}, [confStatus, confLoading, selectGroup]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setSave(false);
		setSaveRej(false);
	}, [notifications]);

	// Engagement stream watching trigger
	useEffect(() => {
		if (room.data && isEngagement) {
			if (room.data.components.desktop.length > 0) {
				const streamComponents = room.data.components.desktop.filter(sc => sc.preset === "stream");
				if (streamComponents.length > 0) {
					setWatchTimer(event.slug);
				} else {
					clearWatchTimer();
				}
			}
		}
	}, [room]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleOpenNotifications = isOpen => {
		setIsNotificationsVisible(isOpen);
		if (!isOpen) {
			handleNotificationCenter(false);
		}
	};

	const handleInvitation = (e, invite, isAccepted) => {
		e.stopPropagation();
		setIds({ id: invite.slot_id, notification_id: invite.notification_id });

		if (isAccepted) {
			setData({
				status: "accepted",
				invitee_status: "busy"
			});
			setSave(true);
		} else {
			setSaveRej(true);
		}
	};

	const handleOpenInvite = (invite, isOpen) => {
		setInvite(invite);
	};

	function signOut() {
		createCookie(CUID_COOKIE, "", 0);
		createCookie(AUTH_COOKIE, "", 0);
		createCookie(AUTH_COOKIE_TIMESTAMP, "", 0);
		setTimeout(() => {
			props.signOut();
		}, 1000);
	}
	const [time, filteredPolls] = usePolls(polls, false, false);

	const [showNavigator, setShowNavigator] = useState(false);

	// Prevents background scroll
	useEffect(() => {
		let htmlNode = document.getElementsByTagName("html")[0];
		if (showChat && view !== "desktop") {
			htmlNode.style.overflowY = "hidden";
		} else {
			htmlNode.style.overflowY = "auto";
		}
	}, [showChat]);

	const visited = sessionStorage.getItem("visited");

	useEffect(() => {
		if (user.chat_user_id && visited !== user.chat_user_id && confStatus === 200) handleSaveVisit();
	}, [visited, confStatus]);

	const errorTitle = detail => {
		switch (detail) {
			case "Event has ended":
				return <Localized id="error-event-has-ended">Event has ended</Localized>;
			case "Event is not a virtual event":
				return <Localized id="not-found-explanation" />;
			case "Event is private":
				return <Localized id="error-private-event" />;
			default:
				break;
		}
	};

	const handleSaveVisit = async () => {
		const access_key = queryParams.get("access_key");
		await saveVisit(slug, user.chat_user_id, access_key);
	};

	// TODO Dont force chat configurations
	if (lobby.loaded && !confLoading && confStatus === 200) {
		switch (lobby.status) {
			case 500:
				return <MaintenancePage />;
			case 401:
				return <loginForm />;
			case 200: {
				const { styles } = lobby.data;
				const viewProps = {
					view: view,
					params: props.match.params,
					setBgStyle: setBgStyle,
					setHasBg: setHasBg,
					setPageTheme: setPageTheme,
					pageTheme: pageTheme,
					bgStyle: bgStyle,
					hasBg: hasBg,
					setShowChat: setShowChat
				};
				return (
					<div className="virtual-event-container" style={styles} ref={ref}>
						<div className="virtual-event-static-background"></div>
						<ErrorHandler scope="Notification alert">
							<PushNotification />
						</ErrorHandler>

						<ErrorHandler scope="Snowplow">
							<Helmet>
								<script>
									{`; (function (p, l, o, w, i, n, g) {
                                    if (!p[i]) {
                                        p.GlobalSnowplowNamespace = p.GlobalSnowplowNamespace || []; p.GlobalSnowplowNamespace.push(i);
                                        p[i] = function () {(p[i].q = p[i].q || []).push(arguments)}; p[i].q = p[i].q || []; n = l.createElement(o); g = l.getElementsByTagName(o)[0];
                                        n.async = 1; n.src = w; g.parentNode.insertBefore(n, g)
                                    }
                                }(window, document, "script", 'https://${process.env.REACT_APP_COLLECTOR_DOMAIN}/client.js', "sp"));`}
								</script>
							</Helmet>
						</ErrorHandler>

						<ErrorHandler scope="Header" fallback={<HeaderErrorComponent pageTheme={pageTheme} />}>
							<Header
								pageTheme={pageTheme}
								slug={slug}
								showInbox={showInbox}
								setShowInbox={setShowInbox}
								showSchedule={showSchedule}
								setShowSchedule={setShowSchedule}
								showPolls={showPolls}
								setShowPolls={setShowPolls}
								lobby={lobby}
								showCalendar={showCalendar}
								setShowCalendar={setShowCalendar}
								receivedInvites={calendar.received_invites ? calendar.received_invites : []}
								handleOpenNotifications={handleOpenNotifications}
								invite={invite}
								handleInvitation={handleInvitation}
								isNotificationsVisible={isNotificationsVisible}
								view={view}
								schedules={schedules}
								time={time}
							/>
						</ErrorHandler>
						<SettingContext.Provider value={{ ...defaultSettings, languages: eventLanguageOptions }}>
							<div className={`virtual-event-view ${view}`}>
								{!isGuestuser && (
									<ErrorHandler scope="Notification center">
										<NotificationCenter
											received_invites={
												calendar.received_invites
													? calendar.received_invites.filter(i => i.status === "pending")
													: []
											}
											handleInvitation={handleInvitation}
											handleOpenInvite={handleOpenInvite}
											slug={slug}
										/>
									</ErrorHandler>
								)}
								<div className={`virtual-event-bg ${hasBg ? " loaded" : ""}`} style={bgStyle}></div>
								<div className="virtual-event" id="virtual-event">
									<ErrorHandler scope="Navigator" fallback={<NavigatorErrorComponent view={view} />}>
										<>
											{view === "desktop" ? (
												<Navigator
													view={view}
													slug={slug}
													pageTheme={pageTheme}
													setShowNavigator={setShowNavigator}
												/>
											) : (
												<Drawer
													zIndex={2}
													opened={showNavigator}
													onClose={() => setShowNavigator(false)}
													title={
														<h2>
															<Localized id="virtual-event-navigator-title">
																Rooms
															</Localized>
														</h2>
													}
													size={view === "mobile" ? "full" : "50%"}
													classNames={generateDrawerClassNames("navigator", pageTheme, view)}
													withCloseButton={false}
												>
													<Navigator
														slug={slug}
														pageTheme={pageTheme}
														view={view}
														setShowNavigator={setShowNavigator}
													/>
												</Drawer>
											)}
										</>
									</ErrorHandler>

									<ErrorHandler
										scope="Layout content"
										fallback={<ContentErrorComponent view={view} />}
									>
										<ContentView {...viewProps} />
									</ErrorHandler>
								</div>

								{lobbyData?.features?.chat && (
									<ErrorHandler scope="chat">
										{view === "desktop" ? (
											<Chat
												view={view}
												pageTheme={pageTheme}
												showChat={showChat}
												setShowChat={setShowChat}
											/>
										) : (
											<Drawer
												zIndex={2}
												opened={showChat}
												onClose={() => setShowChat(false)}
												size={view === "mobile" ? "full" : "50%"}
												classNames={generateDrawerClassNames("chat", pageTheme, view)}
												withCloseButton={false}
												position="right"
											>
												<Chat
													view={view}
													pageTheme={pageTheme}
													showChat={true}
													setShowChat={setShowChat}
												/>
											</Drawer>
										)}
									</ErrorHandler>
								)}

								{(view === "mobile" || view === "tablet") && (
									<div className={`fixed-buttons ${view} ${pageTheme}`}>
										<div
											className={`chat-ribbon ${view}`}
											onClick={() => setShowNavigator(!showNavigator)}
										>
											<IconBars color={"#000"} bgColor={"#fff"} size="30" />
										</div>

										<div className={`chat-ribbon ${view}`} onClick={() => setShowChat(!showChat)}>
											<ChatIcon color={"#000"} bgColor={"#fff"} size="35" />
										</div>
									</div>
								)}

								<ErrorHandler scope="warning-modal">
									<WarningModal />
								</ErrorHandler>

								{showGroupModal && (
									<ErrorHandler scope="user-group-modal">
										<GroupSelectorModal
											slug={slug}
											show={showGroupModal}
											setShow={setShowGroupModal}
											groups={user_groups}
										/>
									</ErrorHandler>
								)}
							</div>
						</SettingContext.Provider>

						<ErrorHandler scope="1-to-1">
							<OnetoOneVideoMeet pageTheme={pageTheme} />
						</ErrorHandler>
					</div>
				);
			}
			case 402:
			case 403:
				return (
					<div
						className="paywall-view"
						style={{ backgroundImage: `url("${event.event_hero_image ?? defaultHeroImage}")` }}
					>
						<div className="glass-container">
							<div className="informational">
								{moment().isBefore(moment(event.entry_time)) ? (
									<>
										<Localized id="paywall-heading-not-started">
											<h2 className="not-started">Event has not started yet</h2>
										</Localized>
										<Localized
											id="paywall-event-entry-starts"
											vars={{ time: moment(event.entry_time).format("DD.MM.YYYY HH:mm") }}
										>
											<p className="entry-time">{`Entry opens at ${moment(
												event.entry_time
											).format("DD.MM.YYYY HH:mm")}`}</p>
										</Localized>
									</>
								) : (
									<>
										<Localized id="paywall-heading">
											<h1>Missing ticket!</h1>
										</Localized>
										<Localized id="paywall-description">
											<p>
												Please make sure you are logged in to the correct account, and that the
												account has the necessary ticket for this event.
											</p>
										</Localized>

										{!isGuestuser && (
											<p>
												<Localized id="paywall-redirect-orders">
													You can find your orders from
												</Localized>
												<Localized id="paywall-redirect-orders-here">
													<a
														href={`https://events.liveto.io/user/orders`}
														className="orders-link"
														target="_blank"
														rel="noreferrer"
													>
														{" "}
														here
													</a>
												</Localized>
											</p>
										)}

										<p>
											<Localized id="paywall-redirect-event-page">
												If the event organizer is selling or allowing signups for the event,
												they can be found on the
											</Localized>
											<Localized id="paywall-redirect-event-page-link">
												<a
													href={`https://events.liveto.io/events/${slug}`}
													className="event-link"
												>
													event page
												</a>
											</Localized>
										</p>
									</>
								)}

								<button className="signout" onClick={() => signOut()}>
									<IconArrowsLeft size="30" />
									<Localized id="paywall-back-to-login">{"Back to login"}</Localized>
								</button>
							</div>
						</div>
					</div>
				);
			case 404:
				return <ErrorPage title={errorTitle(lobby.detail)} logo={true} logoutButton={true} />;
			default:
				return null;
		}
	} else if (confStatus !== 200 && lobby.status !== 200) {
		if (lobby.status === 404) return <ErrorPage title={errorTitle(lobby.detail)} logo={true} logoutButton={true} />;
	}
	return <LoaderView loadingContent="Loading virtual content for event.." />;
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VirtualEventView));
