/** @format */

import { connect } from "react-redux";
import { IconArrowsLeft, RoomsIcon } from "components/icons";
import { ReactSVG } from "react-svg";
import { NavLink } from "react-router-dom";
import "./navigator.scss";
import { useAccessKey } from "../editorView/apiCalls";
import { useCurrentLanguage } from "util/localization";
import { useEffect, useState } from "react";
import uniqueId from "lodash/uniqueId";
import { useSwipeable } from "react-swipeable";

const mapStateToProps = state => {
	return {
		rooms: state?.lobby?.data?.rooms || [],
		logo: state?.lobby?.data?.logo,
	};
};
function Navigator(props) {
	const { logo, rooms, slug, pageTheme, view = "desktop", editor, setShowNavigator } = props;
	const lang = useCurrentLanguage();
	const [buttons, setButtons] = useState([]);

	const handler = useSwipeable({
		onSwipedLeft: () => {
			if (view === "tablet" || view === "mobile") setShowNavigator(false);
		},
	});

	useEffect(() => {
		setButtons(
			rooms.map((r, i) => (
				<Button
					data={r}
					lang={lang}
					slug={slug}
					key={uniqueId()}
					editor={editor}
					pageTheme={pageTheme}
					setShowNavigator={setShowNavigator}
				/>
			))
		);
	}, [lang, rooms, editor, slug, pageTheme]);

	return (
		<div className={`virtual-event-navigator ${view} ${pageTheme}`} {...handler}>
			{(view === "mobile" || view === "tablet") && (
				<IconArrowsLeft className="swipe-to-close" size="40" onClick={() => setShowNavigator(false)} />
			)}
			<div className={`navigator-content ${pageTheme}`}>
				<div className={"navigator-logo-container"}>
					<div className="navigator-logo" style={{ backgroundImage: 'url("' + logo + '")' }}></div>
				</div>
				<div className="scroll-content">{buttons}</div>
			</div>
		</div>
	);
}

function Button(props) {
	const { slug, data, pageTheme, lang, setShowNavigator } = props;

	const injectSvg = svg => {
		svg.setAttribute("style", "width: 35px; height: 35px;");
		svg.classList.add(`nav-icon-${data.slug}`);
		svg.classList.add(pageTheme);
	};
	const icon = data.icon ? (
		<ReactSVG src={data.icon} beforeInjection={svg => injectSvg(svg)} />
	) : (
		<RoomsIcon
			color={pageTheme === "dark" || pageTheme === "transparent-dark" ? "#fff" : "#000"}
			size="40"
			style={{ minWidth: "30px", marginLeft: "5px" }}
		/>
	);
	const queryString = useAccessKey();
	const link = `/${slug}/${data.slug}${queryString}`;
	return (
		<NavLink to={link}>
			<div className="navigator-link nav-link" onClick={() => setShowNavigator(false)}>
				<div className="hideMobile">{icon}</div>
				<p className={`navigator-link-text ${pageTheme}`}>{data.name[lang]}</p>
			</div>
		</NavLink>
	);
}
export default connect(mapStateToProps, null)(Navigator);
