/** @format */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Editor as TextEditor } from "@tinymce/tinymce-react";
import "../styles/widgets.scss";

const mapStateToProps = state => ({
	lobby: state.lobby,
	editorLanguage: state.editorLanguage,
});

function generateFontSizes(start, end) {
	let fonts = "";
	for (let i = start; i < end; i++) {
		fonts += `${i}pt `;
	}
	return fonts;
}

const TextWidget = props => {
	const { content = "", identifiers, editContent, editLocks, editorDispatch, room } = props;
	const [value, setValue] = useState(content);

	useEffect(() => {
		setValue(content);
	}, [identifiers]);

	const changeValue = incomingValue => {
		if (incomingValue !== content[props.editorLanguage]) {
			const newValue = { ...value, [props.editorLanguage]: incomingValue };
			setValue(newValue);
			editContent(identifiers, newValue, editorDispatch, room, editLocks);
		}
	};

	const init = {
		content_style: "* { color: black !important; background-color: transparent !important; }",
		width: 420,
		menubar: false,
		relative_urls: false,
		fontsize_formats: generateFontSizes(10, 100),
		lineheight_formats: "1 1.1 1.2 1.3 1.4 1.5 1.6 1.7 1.8 1.9 2",
		plugins: [
			"advlist autolink lists link charmap anchor",
			"searchreplace visualblocks code fullscreen",
			"insertdatetime table paste code help wordcount",
			"autoresize",
		],
		max_height: 300,
		visual: true,
		font_formats: `'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats'`,

		toolbar_mode: "wrap",
		toolbar:
			"formatselect fontselect \
             fontsizeselect lineheight \
             bold italic link forecolor alignleft aligncenter alignright \
             bullist numlist",
	};

	return (
		<div className="tool-inner-container">
			TEXT CONTENT
			<TextEditor
				className="text-widget"
				apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
				init={init}
				value={value[props.editorLanguage]}
				onEditorChange={value => changeValue(value)}
			/>
		</div>
	);
};

export default connect(mapStateToProps, null)(TextWidget);
