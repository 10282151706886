import { Component } from 'react';
import * as Sentry from '@sentry/react';
import { getCookie } from 'util/cookies';
import { UNSUPPORTED_BROWSER_COOKIE } from 'constants.js';

export default class ErrorHandler extends Component {
    render() {
        return <Sentry.ErrorBoundary
            fallback={({ error, componentStack, resetError }) => this.props.fallback ? this.props.fallback : ''}
            beforeCapture={(scope) => {
                scope.setTag("Crashing Component", this.props.scope);
                scope.setTag("Unsupported browser", getCookie(UNSUPPORTED_BROWSER_COOKIE) ? true : false)
            }}
        >
            {this.props.children}
        </Sentry.ErrorBoundary >
    }
}
