import moment from "moment"
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as a from "actiontypes";

// Should only be called once inside the app for performance reasons
export function usePollsTimer() {
    const timer = useRef(null);
    const [time, setTime] = useState(moment());
    
    useEffect(() => {
        if (!timer.current) {
            timer.current = setInterval(() => {
                setTime(moment());
            }, 10000);
        }
        return () => clearInterval(timer.current);
    }, [])
    return time
}

export function usePolls(polls, editorMode, showHidden) {
    const [filteredList, setFilteredList] = useState(polls);

    const time = usePollsTimer();
    useEffect(() => {
        if (polls) {
            const list = polls.filter(poll => {
                if (editorMode) return true;
                
                // If showHidden is true (the element is added inside the page content) default to true, else check polls hidden status
                let show = showHidden ? true : !poll.hidden;
                // If poll has not started && show_question_before_start
                if (moment(poll.start_time).isAfter(time) && !poll.show_question_before_start) {
                    show = false;
                }
                // If poll has not ended
                if (moment(poll.end_time).isBefore(time)) {
                    show = false;
                }
                if (show) {
                    return true;
                }
                return false;
                
            })
            setFilteredList(list);
        }
        

    }, [time, polls, editorMode, showHidden])

    return [time, filteredList];
}