import React from 'react';

import ToolbarWidgets from "./ToolbarWidgets";
import ToolbarActions from "./ToolbarActions";

const MainToolbar = ({
    darkMode,
    children,
    editor,
    setSaved,
    saveLoading,
    saveSuccess,
    showSaveStatus,
    lobby,
    preventCollision,
    setPreventCollision,
    saveDetail
}) => {
    
    return (
        <div className="virtual-event-main-toolbar" key="virtual-event-main-toolbar-header">
            <h2 className="main-title">Virtual Editor</h2>
            <ToolbarWidgets editor={editor} preventCollision={preventCollision} setPreventCollision={setPreventCollision} />
            <ToolbarActions lobby={lobby} setSaved={setSaved} saveLoading={saveLoading} saveSuccess={saveSuccess} showSaveStatus={showSaveStatus} saveDetail={saveDetail} />
            {children}
        </div>)
}

export default MainToolbar;