/** @format */

import { Localized } from "@fluent/react";
import { useCreateExtraFields } from "./hooks";

const ExtraFields = props => {
	const { setFormView, extraFields, guestLogin } = props;

	const [formComponents, errorMsg, handleSubmit] = useCreateExtraFields(extraFields, guestLogin);

	return (
		<div className="extra-field-form-container">
			<div className="header">
				<h4 className="title">
					<Localized id="auth-guest-heading">Guest signin</Localized>
				</h4>
			</div>

			<div className="form-content">{formComponents}</div>
			<div className="footer">
				<div className="error-msg">{errorMsg}</div>
				<div className="buttons">
					<button className="signin-button guest" onClick={() => setFormView("")}>
						<Localized id="auth-guest-back-btn">
						Back
						</Localized>
						
					</button>
					<button className="signin-button guest" onClick={handleSubmit}>
						<Localized id="auth-guest-button">Singin</Localized>
					</button>
				</div>
			</div>
		</div>
	);
};

export default ExtraFields;
