import { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { OrganizerContext } from '../../contexts';
import { Modal } from 'react-bootstrap';
import './imageGallery.scss';
import { IconArrowForward, IconArrowLeftBack, IconFolder, LivetoLoader, IconWebsite } from 'components/icons';
import { SettingContext } from '../../contexts';
import { useFetchFolders, useFetchImages } from '../apiCalls';
import { useHandleFolderHierarchy } from './hooks';
import { FolderElement, ImageElement, ImageSelectContainer, ImageUploadContainer, NavigationButtons } from './components';

function ImageGallery(props) {
    const { match, setShowGallery, showGallery, setFromGallery } = props;

    // image upload identifiers
    const organizer = useContext(OrganizerContext);
    const { userType } = useContext(SettingContext);

    const { slug, room_slug } = match.params

    const [fetchImages, setFetchImages] = useState(true); // Defines when to fetch images
    const [fetchFolders, setFetchFolders] = useState(true); // Defines when to fetch folders

    const [folders, loadingFolders, folderStatus] = useFetchFolders(slug, organizer, room_slug, fetchFolders, setFetchFolders, userType)

    const [
        folderList,
        setFolderList,
        handleMove,
        handleHistory,
        hierarchyHistory,
        handleSelectFolder,
        setFolderQuery,
        folderQuery,
        activeFolder,
        activeIndex,
        resetState,
        handleHome
    ] = useHandleFolderHierarchy(folders, setFetchImages)

    const [images, loading, status] = useFetchImages(slug, organizer, room_slug, fetchImages, setFetchImages, userType, folderQuery) // Images & get status

    const [selectedImage, setSelectedImage] = useState(null);

    function setImageToUse() {
        setFromGallery(selectedImage.image);
        setShowGallery(false);
    }

    const folderProps = {
        handleHistory,
        hierarchyHistory,
        handleSelect: handleSelectFolder,
        setFolderQuery,
        setFolderList
    }
    const uploadProps = {
        setShowGallery,
        slug,
        organizer,
        room_slug,
        setFetchImages,
        setFetchFolders,
        userType,
        activeFolder,
        resetState
    }
    const selectImageProps = {
        selectedImage,
        setImageToUse,
        activeFolder,
        folders,
        setFetchFolders,
        setFetchImages,
        setSelectedImage,
        resetState
    }

    return <Modal show={showGallery} animation={false} onHide={e => setShowGallery(false)} contentClassName={'image-gallery-modal'}>
        <div className="image-listing-container">
            <h1>Event images</h1>
            <div className="folder-navigation">
                <button className='modal-back-button nav-button' disabled={activeIndex === 0} onClick={() => handleMove(activeIndex - 1)}><IconArrowLeftBack /></button>
                <button className='modal-back-button nav-button' disabled={!hierarchyHistory[activeIndex + 1]} onClick={() => handleMove(activeIndex + 1)}><IconArrowForward /></button>
                <button className='modal-back-button nav-button' disabled={!activeFolder} onClick={handleHome}><IconWebsite size="25" /></button>
                <NavigationButtons path={activeFolder} handleMove={handleMove} />
            </div>
            <p>Click image to select</p>
            <div className="list">
                {(loading || loadingFolders) ? <LivetoLoader /> : <div className="list-content">
                    {folderList.map(f => (<FolderElement folder={f} {...folderProps}>
                        <div className='folder-container'>
                            <div className="icon-container">
                                <IconFolder size='70' />
                            </div>
                            {f.name}
                        </div>
                    </FolderElement>))}
                    {images.map(img => (<ImageElement imageObject={img} type="image" handleSelect={setSelectedImage} selected={selectedImage} />))}
                </div>}
            </div>
            {selectedImage && <ImageSelectContainer {...selectImageProps} />}

        </div>
        <ImageUploadContainer {...uploadProps} />
    </Modal>
}

export default withRouter(ImageGallery);

