import {
    JustifyCenterIcon,
    JustifyFlexStartIcon,
    JustifyFlexEndIcon,
    JustifySpaceBetweenIcon,
    JustifySpaceAroundIcon,
    JustifySpaceEvenlyIcon
} from "components/icons";
import React, { useEffect, useState } from "react";
import '../styles/widgets.scss';

const fallbackJustify = 'flex-start';

export default function JustifyContentWidget(props) {
    const { identifiers, editProperties, properties, editLocks, editorDispatch, room } = props;
    const property = 'justifyContent';
    const value = properties[property] ? properties[property] : fallbackJustify;
    const [justifyContent, setJustifyContent] = useState(value);
    const changeValue = (newValue) => {
        editProperties(identifiers, property, newValue, editorDispatch, room, editLocks);
    };

    useEffect(() => {
        setJustifyContent(value);
    }, [value, identifiers, editProperties, property])

    const justifyContentOptions = ['center', 'flex-start', 'flex-end', 'space-between', 'space-around', 'space-evenly']

    const justifyComponent = (option) => {
        switch (option) {
            case "center":
                return { title: "CENTER", icon: <JustifyCenterIcon /> }
            case "flex-start":
                return { title: "FLEX-START", icon: <JustifyFlexStartIcon /> }
            case "flex-end":
                return { title: "FLEX-END", icon: <JustifyFlexEndIcon /> }
            case "space-between":
                return { title: "SPACE BETWEEN", icon: <JustifySpaceBetweenIcon /> }
            case "space-around":
                return { title: "SPACE AROUND", icon: <JustifySpaceAroundIcon /> }
            case "space-evenly":
                return { title: "SPACE EVENLY", icon: <JustifySpaceEvenlyIcon /> }
        }
    }

    return (<div className="tool-inner-container alignment">
        <label>Justify content</label>
        <div className="tools">
            {justifyContentOptions.map((option, i) =>
                <div className="tool" key={`justifyContent_${i}`}>
                    {justifyComponent(option).title}
                    <button className={"simple-button" + (option === value ? ' active' : '')} value={option} onClick={() => changeValue(option)}>
                        {justifyComponent(option).icon}
                    </button>
                </div>
            )}
        </div>
    </div>);
};