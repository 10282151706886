import React from "react";
import { Tooltip, OverlayTrigger, Popover } from "react-bootstrap";
import { IconHelp } from "components/icons";

import './tooltip.scss';

export const HelpTooltip = (props) => {
  const renderTooltip = (text) => (
    <Tooltip id="help-tooltip">
      {text}
    </Tooltip>
  );
  return (<OverlayTrigger
    placement={props.orientation || 'left'}
    delay={{ show: 150, hide: 400 }}
    overlay={renderTooltip(props.text)}
  >
    <IconHelp className="help-tooltip-icon" size={props.size || 20} />
  </OverlayTrigger>)
}

export const GenericTooltip = (props) => {
  const renderTooltip = (text) => (
    <Tooltip id="generic-tooltip">
      {text}
    </Tooltip>
  );
  return (<OverlayTrigger
    placement={props.orientation || 'left'}
    delay={{ show: props.show || 150, hide: props.hide || 400 }}
    overlay={renderTooltip(props.text)}
  >
    {props.children}
  </OverlayTrigger>)
}

export const CustomOverlay = (props) => {
  const renderPopover = (text) => (
    <Popover className={props.customClass} id={`popover-positioned-${props.orientation}`}>
        {text}
    </Popover>
  );
  return (<OverlayTrigger
    placement={props.orientation || 'left'}
    delay={{ show: props.show || 150, hide: props.hide || 400 }}
    overlay={renderPopover(props.text)}
  >
    {props.children}
  </OverlayTrigger>)
}