/** @format */

import { useCurrentLanguage } from "util/localization";
import {
	CheckboxInput,
	LinkComponent,
	SelectInput,
	TextInput,
	DateInput,
	NoteComponent,
	RatingComponent,
	PhoneFieldInput,
	ConditionalLogic,
} from "./components";
import { useState } from "react";
import { createInitialValues, removeEmptyDataFromExtraInfoObject, validateForm } from "./helpers";
import { Localized } from "@fluent/react";

export function useCreateExtraFields(data = [], login) {
	const language = useCurrentLanguage();

	const [form, setForm] = useState(createInitialValues(data));
	const [errors, setErrors] = useState([]);
	const [errorMsg, setErrorMsg] = useState("");

	const componentsWithoutCondition = data.filter(d => !d.condition);
	const componentsWithCondition = data.filter(d => d.condition);

	const handleChange = (key, value) => {
		setForm(oldForm => ({ ...oldForm, [key]: value }));
	};

	const handleSubmit = () => {
		const validated = validateForm(data, form);
		setErrors(validated);
		if (validated.length > 0) {
			setErrorMsg(<Localized id="auth-guest-warning">Please fill in all the required fields</Localized>);
		} else {
			setErrorMsg("");
			const body = removeEmptyDataFromExtraInfoObject(form, data);
			login(body);
		}
	};

	const formComponents = generateExtraFieldComponents({
		data: componentsWithoutCondition,
		errors,
		language,
		handleChange,
		form,
		componentsWithCondition,
	});

	return [formComponents, errorMsg, handleSubmit];
}

export const generateExtraFieldComponents = (props = {}) => {
	const { data = [], errors = [], language, handleChange, form } = props;
	if (data.length === 0) return [];
	const formComponents = data.map(field => {
		const { key, ...fieldProps } = field;
		const warning = errors.includes(key);
		const componentProps = {
			...fieldProps,
			fieldKey: key,
			language,
			handleChange,
			warning,
			form,
			errors,
			...props,
		};

		switch (field.type) {
			case "hr":
				return <hr />;
			case "link": {
				return <LinkComponent {...componentProps} />;
			}
			case "checkbox": {
				return (
					<ConditionalLogic {...componentProps}>
						<CheckboxInput {...componentProps} />
					</ConditionalLogic>
				);
			}
			case "country": {
				return <SelectInput {...componentProps} />;
			}
			case "select": {
				return (
					<ConditionalLogic {...componentProps}>
						<SelectInput {...componentProps} />
					</ConditionalLogic>
				);
			}
			case "textarea":
			case "email":
			case "text": {
				return <TextInput {...componentProps} />;
			}
			case "date": {
				return <DateInput {...componentProps} />;
			}
			case "note": {
				return <NoteComponent {...componentProps} />;
			}
			case "rating": {
				return <RatingComponent {...componentProps} />;
			}
			case "phone": {
				return <PhoneFieldInput {...componentProps} />;
			}
			default:
				return "";
		}
	});
	return formComponents;
};
