/** @format */
import { isEmpty } from "lodash";
import validator from "validator";

export const createInitialValues = extraFields => {
	let initialValues = {};
	extraFields
		.filter(f => f.key)
		.forEach(field => {
			const { key, type, placeholder, questions } = field;
			switch (type) {
				case "checkbox": {
					initialValues = { ...initialValues, [key]: false };
					break;
				}
				case "select": {
					initialValues = { ...initialValues, [key]: [] };
					break;
				}
				case "country":
				case "email":
				case "textarea":
				case "phone":
				case "text": {
					initialValues = { ...initialValues, [key]: "" };
					break;
				}
				case "date": {
					initialValues = { ...initialValues, [key]: placeholder };
					break;
				}
				case "rating": {
					const qs = {};
					questions.forEach(q => {
						if (q.required) {
							qs[q.key] = null;
						}
					});
					if (!isEmpty(qs)) {
						initialValues = { ...initialValues, [key]: qs };
					}
					break;
				}
				default:
					break;
			}
		});
	return initialValues;
};

export const validateForm = (extraFields, form) => {
	const fieldsWithCondition = extraFields.filter(ef => ef.condition);
	let fieldsToValidate = extraFields.filter(ef => !ef.condition);

	extraFields.forEach(field => {
		const fieldsWithConditionMet = fieldsWithCondition.filter(f => {
			const [key, value] = Object.entries(f.condition)[0];
			if (field.key !== key) return false;
			else {
				if (field.type === "checkbox" && form[field.key] === value) return true;
				else if (field.type === "select" && form[field.key].includes(value)) return true;
				else return false;
			}
		});
		if (fieldsWithConditionMet.length > 0) fieldsToValidate = [...fieldsToValidate, ...fieldsWithConditionMet];
	});

	const requiredFields = fieldsToValidate.filter(f => f.required).map(field => field.key);
	const requiredRatingFields = fieldsToValidate
		.filter(f => f.type === "rating")
		.filter(field => field.questions.map(q => q.required === true).length > 0);
	requiredRatingFields.forEach(f => {
		const { key, questions } = f;
		questions.forEach(q => {
			if (q.required) requiredFields.push(`${key}:${q.key}`);
		});
	});
	let required = [];
	for (const prop in form) {
		if (!checkValue(extraFields, prop, form[prop], requiredFields.includes(prop))) required.push(prop);
		requiredRatingFields.forEach(rField => {
			const { key, questions } = rField;
			questions.forEach(q => {
				if (!form[key][q.key] && requiredFields.includes(`${key}:${q.key}`)) required.push(`${key}:${q.key}`);
			});
		});
	}

	return required;
};

const checkValue = (fields, key, value, isRequired) => {
	if (isRequired) {
		if (!value) return false;
		else {
			const field = fields.find(f => f.key === key);
			if (!field) return false;
			else if (field.type === "select" && value.length === 0) return false;
			else return true;
		}
	} else {
		const field = fields.find(f => f.key === key);
		if (field.type === "email" && !validator.isEmail(value)) return false;
		else return true;
	}
};

export const removeEmptyDataFromExtraInfoObject = (values = {}, form = []) => {
	let body = values;
	const findRatingKeys = form.filter(f => f.type === "rating").map(entry => entry?.key);
	// Delete empty strings and arrays, so they don't get sent
	Object.entries(values).forEach(data => {
		const [key, value] = data;
		if (value === "" || value.length === 0) delete body[key];
		// Removing conditional fields if the condition is not met
		const conditionalField = form.find(f => f.key === key && f.condition);
		if (conditionalField) {
			const [referenceFieldKey, referenceFieldValue] = Object.entries(conditionalField.condition)[0];
			const referenceField = body[referenceFieldKey];
			if (referenceField === undefined || referenceField === null) {
				delete body[key];
			} else {
				const referenceFormField = form.find(f => f.key === referenceFieldKey);
				if (referenceFormField.type === "checkbox" && referenceField !== referenceFieldValue) {
					delete body[key];
				} else {
					if (referenceFormField.type === "select" && !referenceField.includes(referenceFieldValue)) {
						delete body[key];
					}
				}
			}
		}
		findRatingKeys.forEach(key => {
			if (body[key]) {
				Object.keys(body[key]).forEach(k => {
					if (body[key][k] === "" || body[key][k] === null || body[key][k] === undefined) delete body[key][k];
				});
			}
		});
	});

	return body;
};
