import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import "../styles/widgets.scss";
import useDebounce from "../hooks";
import { useDispatch, useSelector } from "react-redux";
import ImageGallery from "../imageGallery/imageGallery";
const fallbackBg = {
  image: "",
  color: "rgba(255,255,255,1)",
  size: "cover",
  position: "center",
  repeat: "no-repeat",
};
export default function BackgroundWidget(props) {
  const {
    identifiers,
    editProperties,
    properties,
    slug,
    styles,
    editLocks,
    editorDispatch,
    room,
  } = props;
  const dispatch = useDispatch();
  const property = "background";
  const value = slug
    ? styles[property] ?? fallbackBg
    : properties[property] ?? fallbackBg;
  const { image, color, size, position, repeat } = value;

  const splittedColor = color.replace("rgba(", "").replace(")", "").split(",");
  const rgbaColor = {
    r: splittedColor[0],
    g: splittedColor[1],
    b: splittedColor[2],
    a: splittedColor[3],
  };

  const [newColor, setColor] = useState(rgbaColor);
  const [newImage, setImage] = useState(image);
  const [newSize, setSize] = useState(size);
  const [newRepeat, setRepeat] = useState(repeat);
  const [newPosition, setPosition] = useState(position);
  const [backgroundColor, setBackgroundColor] = useState(null);

  const debouncedColor = useDebounce(backgroundColor, 500);

  const showImageGallery = useSelector(
    (state) => state.editorProgress.showImageGallery
  );

  useEffect(() => {
    if (debouncedColor) {
      const { r, g, b, a } = backgroundColor;
      const cssColor = `rgba(${r},${g},${b},${a})`;
      const backgroundObject = {};
      backgroundObject["color"] = cssColor;
      editProperties(
        identifiers,
        property,
        backgroundObject,
        editorDispatch,
        room,
        editLocks
      );
    }
  }, [debouncedColor]);

  // SketchPicker displaying the correct color value on history travel
  useEffect(() => {
    setBackgroundColor(null);
  }, [newColor]);

  const handleGallery = (val) => {
    dispatch({ type: "HANDLE_IMAGE_GALLERY", payload: val });
  };

  function setBackground(key, value) {
    const backgroundObject = {
      color: newColor,
      image: newImage,
      size: newSize,
      repeat: newRepeat,
      position: newPosition,
    };

    switch (key) {
      case "image":
        backgroundObject["image"] = `url(${value})`;
        break;
      case "size":
        backgroundObject["size"] = value;
        break;
      case "repeat":
        backgroundObject["repeat"] = value;
        break;
      case "position":
        backgroundObject["position"] = value;
        break;
      default:
        break;
    }

    editProperties(
      identifiers,
      property,
      backgroundObject,
      editorDispatch,
      room,
      editLocks
    );
  }

  function setFromGallery(value) {
    setBackground("image", value);
  }

  useEffect(() => {
    const { image, color, size, position, repeat } = value;
    setColor(color);
    setImage(image);
    setSize(size);
    setRepeat(repeat);
    setPosition(position);
  }, [value, identifiers, editProperties, property]);

  const positionOptions = ["left", "right", "center"];
  const sizeOptions = ["cover", "contain"];
  const repeatOptions = [
    "repeat",
    "repeat-x",
    "repeat-y",
    "no-repeat",
    "space",
    "round",
  ];

  return (
    <div className="tool-inner-container">
      <div className="tools">
        BACKGROUND
        <div>
          Color
          <SketchPicker
            color={backgroundColor || newColor}
            onChange={(e) => setBackgroundColor(e.rgb)}
            style={{ boxShadow: "none" }}
            presetColors={[]}
          />
        </div>
        <div className="tool">
          Image
          <input
            className="editor-menu-input image"
            value={newImage?.replace("url(", "").replace(")", "") || ""}
            type="text"
            onChange={(e) => setBackground("image", e.target.value)}
          />
        </div>
        <div className="tool">
          <button
            className="editor-menu-button"
            onClick={(e) => handleGallery(true)}
          >
            Open gallery
          </button>
          <button
            className="editor-menu-button"
            onClick={(e) => setBackground("image", "")}
          >
            Remove image
          </button>
        </div>
        {newImage?.replace("url(", "").replace(")", "") && (
          <>
            <div>
              Position
              <div className="buttons">
                {positionOptions.map((option) => (
                  <button
                    className={
                      "editor-menu-button" +
                      (option === newPosition ? " active" : "")
                    }
                    value={option}
                    onClick={() => setBackground("position", option)}
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>

            <div>
              Size
              <div className="buttons">
                {sizeOptions.map((option) => (
                  <button
                    className={
                      "editor-menu-button" +
                      (option === newSize ? " active" : "")
                    }
                    value={option}
                    onClick={() => setBackground("size", option)}
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
            <div>
              Repeat
              <div className="buttons">
                {repeatOptions.map((option) => (
                  <button
                    className={
                      "editor-menu-button" +
                      (option === newRepeat ? " active" : "")
                    }
                    value={option}
                    onClick={() => setBackground("repeat", option)}
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
          </>
        )}
        <ImageGallery
          showGallery={showImageGallery}
          setShowGallery={handleGallery}
          setFromGallery={setFromGallery}
        />
      </div>
    </div>
  );
}
