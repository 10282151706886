import { useEffect, useRef } from "react";
import { useState } from "react";
import { connect } from "react-redux"
import { useAccessKey } from "../editorView/apiCalls";
import './notifications.scss';
import { NotificationType } from "./NotificationType";
import * as a from 'actiontypes'

const mapStateToProps = state => ({
    push_notifications: state.alerts,
    calendarSlots: state.schedule.calendar.slots,
    notification_settings: state.user.notification_settings
})

const mapDispatchToProps = dispatch => ({
    popAlert: id => dispatch({ type: a.POP_ALERT, payload: id }),
    handlePushNotificationRef: payload => dispatch({ type: a.HANDLE_INVITE_PUSH_NOTIFICATION_REF, payload })
})

const Notification = ({ note, popAlert, calendarSlots, handlePushNotificationRef }) => {
    const [exit, setExit] = useState(false)
    const [barWidth, setBarWidth] = useState(0)
    const [intervalID, setIntervalID] = useState(null)
    const invitePushNotificationRef = useRef(null)
    const accessKeyString = useAccessKey()

    const handleStartTimer = async () => {
        const id = setInterval(() => {
            setBarWidth((prevState) => {
                if (prevState < 100) {
                    return prevState + 0.2
                } else {
                    return prevState
                }
            })
        }, 10)
        setIntervalID(id)
    }

    const handlePauseTimer = () => {
        handlePushNotificationRef(invitePushNotificationRef)
        clearInterval(intervalID)
    }

    const handleCloseNotification = () => {
        handlePauseTimer()
        setExit(true)
        setTimeout(() => {
            popAlert(note.id)
        }, 1000)
    }

    useEffect(() => {
        if (barWidth > 100) {
            handleCloseNotification()
        }
    }, [barWidth])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        handleStartTimer()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div onMouseEnter={handlePauseTimer} onMouseLeave={handleStartTimer} className={`push-notification-item ${note.variant} ${exit ? 'exit' : ''}`} ref={invitePushNotificationRef}>
            <div className="note-msg">
                <NotificationType alert={note} calendarSlots={calendarSlots} accessKeyString={accessKeyString} />
            </div>
            <div className="bar" style={{ width: `${barWidth}%` }} />
        </div>
    )
}

const PushNotifications = (props) => {
    const { push_notifications, popAlert, calendarSlots, handlePushNotificationRef, notification_settings = {} } = props
    const filteredBySetting = push_notifications.filter(note => notification_settings[note.setting])
    const notsToPop = push_notifications.filter(note => !notification_settings[note.setting])
    usePopNotifications(notsToPop, popAlert)
    return (
        <div>
            <div className="push-notification-wrapper">
                {filteredBySetting.map(note =>
                    <Notification note={note} popAlert={popAlert} calendarSlots={calendarSlots} key={note.id} handlePushNotificationRef={handlePushNotificationRef} />
                )}
            </div>
        </div>
    )
}

function usePopNotifications(notifications = [], pop) {
    if (notifications.length > 0) notifications.forEach(n => pop(n.id))
}

export default connect(mapStateToProps, mapDispatchToProps)(PushNotifications)