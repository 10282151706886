import { unionBy } from "lodash";

export const combineAndSort = (onlineUsers = [], offlineUsers = []) => {
    offlineUsers.forEach((attendee) => {
        attendee.user_id = attendee.chat_user_id;
        attendee.isOnline = false;
    });
    onlineUsers.forEach((attendee) => {
        attendee.isOnline = true;
    });
    // Sort by first name that is stripped from special characters. The 'fi' option makes it sort by the finnish alphabet.
    const sortStripped = (a, b) => {
        const aName = a.type === "anonymous" ? a.username : a.first_name;
        const bName = b.type === "anonymous" ? b.username : b.first_name;
        return aName.localeCompare(bName, 'fi', {ignorePunctuation: true})
    };
    const sortedOnlineUsers = onlineUsers.sort(sortStripped);
    const sortedOfflineUsers = offlineUsers.sort(sortStripped);

    const combined = unionBy(sortedOnlineUsers, sortedOfflineUsers, "user_id");

    return combined;
};
