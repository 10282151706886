import React from "react";
import '../styles/widgets.scss';

const fallbackSettings = { autoPlay: false, loop: false };
const streamFallbackSettings = { autoPlay: true, loop: false };

const VideoSettingsWidget = (props) => {
    const { identifiers, editProperties, properties, editLocks, editorDispatch, room, renderType } = props;

    const property = 'videoSettings'
    const { autoPlay, loop } = !properties[property] ? (renderType === 'stream' ? streamFallbackSettings : fallbackSettings) : properties[property];

    const updateSettings = (name, value) => {
        let newSettings;
        switch (name) {
            case 'autoPlay': {
                newSettings = { ...properties[property], autoPlay: value }
                break;
            }
            case 'loop': {
                newSettings = { ...properties[property], loop: value }
                break;
            }
            default: break;
        }
        editProperties(identifiers, property, newSettings, editorDispatch, room, editLocks);
    }
    return (
        <div className="tool-inner-container">
            VIDEO SETTINGS
            <div className="tools">
                <div className="tool">
                    Autoplay
                    <input type="checkbox" checked={autoPlay} onChange={() => updateSettings('autoPlay', !autoPlay)} />
                </div>
                <div className="tool">
                    Loop
                    <input type="checkbox" checked={loop} onChange={() => updateSettings('loop', !loop)} />
                </div>
            </div>

        </div>
    )
}

export default VideoSettingsWidget