const { cloneDeep } = require("lodash")
const moment = require("moment")

function sortDate(dateA, dateB, direction = 'asc') {
    const formats = ['DD-MM-YYYY']; // can be several
    return (moment(dateA, formats).isBefore(moment(dateB, formats)) ? -1
        : moment(dateA, formats).isAfter(moment(dateB, formats)) ? 1 : 0) * (direction === 'asc' ? 1 : -1)
}

export const calculateScheduleDuration = (scheduleData) => {
    let updatedScheduleData = cloneDeep(scheduleData)

    const newSchedules = updatedScheduleData.schedules

    let arrHelper = newSchedules.map(s => s.timeslots).map(tArr => tArr.map(t => t.start_time))
    
    let allDistinctDates = [].concat.apply([], arrHelper)
        .map(date => moment(date).format('DD.MM.YYYY'))
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((a, b) => sortDate(a, b))

    newSchedules.forEach(schedule => {
        let distinctDates = schedule.timeslots.map(ts => moment(ts.start_time).format('DD.MM.YYYY'))
        .filter((value, index, self) => self.indexOf(value) === index)

        let updatedTimeslots = []
        
        distinctDates.forEach(d => {
            let dayObject = {}
            let newTimeslots = schedule.timeslots.filter(ts => moment(ts.start_time).format('DD.MM.YYYY') === d)
            
            const maxT = moment.max(newTimeslots.map(d => moment(d.end_time)))
            const minT = moment.min(newTimeslots.map(d => moment(d.start_time)))
            dayObject = { [d]: { timeslots: newTimeslots, duration: moment(maxT).diff(moment(minT), 'minutes'), start: minT, end: maxT } }
            
            updatedTimeslots.push(dayObject)
            

        })

        delete schedule.timeslots

        schedule.days = updatedTimeslots
    });

    updatedScheduleData.schedules = cloneDeep(newSchedules)
    updatedScheduleData.dates = allDistinctDates

    return updatedScheduleData
}