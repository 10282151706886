import React, { useState, useRef, useEffect } from 'react';
import { Emojione } from 'react-emoji-render';
import { getLocaleTime } from 'util/time';
import { IconUserCircle, IconReply } from 'components/icons';
import { useContextMenu } from 'react-contexify';
import MessageMenu from './messageMenu';
import ReactionMenu from './reactionMenu';
import { createPortal } from 'react-dom';
import { GenericTooltip } from 'components/ToolTip/Tooltip';
import { Localized } from '@fluent/react';

const BG_THRESHOLD = 7;
const BOOST_THRESHOLD = 7;

function textContainsLink(message, regex) {

    if (regex.test(message)) {
        return true;
    }
    return false;
}

export const MessageWrapper = (props) => {
    const { message = '' } = props

    const linkRegex = new RegExp(/(https?:\/\/\S+)/i);

    if (textContainsLink(message, linkRegex)) {
        let messageArray = [];
        const splitted_message = message.split(linkRegex);
        for (let [index, sentence] of splitted_message.entries()) {
            if (textContainsLink(sentence, linkRegex)) {
                messageArray.push(<a key={index} target="_blank" rel="noreferrer" className={"message-link"} href={sentence}>{sentence}</a>)
            } else {
                if (sentence.length > 0) {
                    messageArray.push(<Emojione key={index} onlyEmojiClassName="liveto-chat-emoji" text={sentence} />);
                }
            }
        }
        return messageArray;
    }

    return <Emojione onlyEmojiClassName="liveto-chat-emoji" text={message} />;
}

function Portal({ children }) {
    if (!document.querySelector("#liveto-chat")) return children
    return createPortal(children, document.querySelector("#liveto-chat"));
}

export const UserMessage = (props) => {
    const {
        icon,
        sender_name,
        message,
        held,
        quoted,
        timestamp,
        timestamp_recv,
        reactions,
        message_id,
        reactionsVisible,
        setReactionsVisible,
        handleReply,
        chat_history
    } = props;
    const ts = timestamp_recv || timestamp
    const [showReactionButton, setShowReactionButton] = useState(true);
    const [totalReactions, mostVotedReaction] = calculateReactions(reactions);
    const messageRef = useRef(null);
    const isReactions = Object.keys(reactions).length !== 0
    const isQuotedRemoved = quoted && !chat_history.find(m => m.message_id === quoted?.message_id)
    const { show } = useContextMenu({ id: message_id });
    function handleModeratorMenu(e) {
        const position = {
            x: messageRef?.current.getBoundingClientRect().x,
            y: messageRef?.current.getBoundingClientRect().y
        };
        show(e, {
            position
        });
    }
    const handleReactions = () => {
        setReactionsVisible(reactionsVisible ? null : message_id);
    }

    const handleReactionButtonClose = () => {
        if (reactionsVisible) return;
        setShowReactionButton(false)
    }

    useEffect(() => {
        if (!reactionsVisible) {
            setShowReactionButton(false)
        }
    }, [reactionsVisible])

    return <div className="chat-message"
        onContextMenu={handleModeratorMenu}
        onMouseOver={() => setShowReactionButton(true)}
        onMouseLeave={handleReactionButtonClose}
        ref={messageRef}
    >
        <Portal>
            <MessageMenu {...props} />
        </Portal>
        <div className="message-icon" style={{ backgroundImage: `url("${icon}")` }}>
            {parseImageLetter(icon, sender_name)}
        </div>
        <div className={`message-content ${quoted ? 'quoted' : ''} ${held ? 'held' : ''}`}>
            <div className="message-info">
                <div className="message-content-name">{sender_name}</div>
                <div className="message-info-timestamp">
                    {getLocaleTime(ts, 'DD.MM.YYYY HH:mm')}
                </div>
            </div>
            <div className={`message-wrapper ${isReactions ? 'reactions' : ''}`}>
                {quoted && <div className='quoted-msg-container'>
                    <div className="sender">
                        {quoted.isAnonymous ? <Localized id="chat-anonymous-name">Anonymous</Localized> : quoted.sender_name}
                    </div>
                    <div className="message">
                        {isQuotedRemoved ?
                            <span className="quoted-removed"><Localized id="moderation-chatfeed-message-removed">This message has been removed</Localized></span> :
                            <MessageWrapper message={quoted.message} />}
                    </div>
                </div>}
                <div className={`message-content-message ${quoted ? 'quoted' : ''} ${reactionsVisible ? ' hilight' : ''}` + (totalReactions > BOOST_THRESHOLD ? ` boosted ${totalReactions > BG_THRESHOLD ? mostVotedReaction : ''}` : '')}>
                    <MessageWrapper message={message} />
                    {isReactions && <Reactions {...props} />}
                    {!held && <MessageButtons {...props} handleReply={handleReply} showReactionButton={showReactionButton} handleReactions={handleReactions} reactionsVisible={reactionsVisible} setReactionsVisible={setReactionsVisible} messageRef={messageRef} />}
                </div>
                {held && <div className='held-msg-info'>
                    <Localized id="chat-held-message-info">This message has been held for review</Localized>
                </div>}
            </div>


        </div>
    </div>
}

const MessageButtons = (props) => {
    const {
        showReactionButton,
        handleReactions,
        reactionsVisible,
        setReactionsVisible,
        messageRef,
        handleReply,
        message,
        message_id,
        sender_name,
        sender
    } = props
    const isAnonymous = sender === 'ANONYMOUS_PERSON'
    return <>
        {showReactionButton && <div className="chat-message-buttons">
            <GenericTooltip
                text={<Localized id="chat-reply-button-tooltip">Reply</Localized>}
                hide="100"><button className="reaction-button" onClick={() => handleReply(message, message_id, sender_name, isAnonymous)}>
                    <span className={`reactions-button-icon ${reactionsVisible ? ' close-reactions' : ''}`}><IconReply /></span>
                    {reactionsVisible && <ReactionMenu {...props} setReactionsVisible={setReactionsVisible} top={messageRef?.current?.offsetTop} />}
                </button>
            </GenericTooltip>

            <button className="reaction-button emojis" onClick={() => handleReactions()}>
                <span className={`reactions-button-icon ${reactionsVisible ? ' close-reactions' : ''}`}>🙂</span>
                {reactionsVisible && <ReactionMenu {...props} setReactionsVisible={setReactionsVisible} top={messageRef?.current?.offsetTop} />}
            </button>
        </div>}
    </>
}

export function parseImageLetter(icon, name) {
    if (icon || !name) {
        return '';
    }
    let cleaned = String(name).replace(/[&\\#,+()$~%.'":*?<>{}]/g, "");
    const names = cleaned.split(' ');
    if (names.length > 1) {
        let lastName = names[1] === "Guest" ? "" : names[1];
        return names[0].charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
    }
    return names[0].charAt(0).toUpperCase()
}

export const AnonymousMessage = (props) => {
    const { message, held, sender_name, quoted, timestamp, message_id, reactions, setReactionsVisible, reactionsVisible, handleReply, chat_history } = props;
    const [showReactionButton, setShowReactionButton] = useState(false);

    const [totalReactions, mostVotedReaction] = calculateReactions(reactions);
    const messageRef = useRef(null);
    const isReactions = Object.keys(reactions).length !== 0
    const isQuotedRemoved = quoted && !chat_history.find(m => m.message_id === quoted?.message_id)

    const { show } = useContextMenu({ id: message_id });
    function handleModeratorMenu(e) {
        const position = {
            x: messageRef?.current.getBoundingClientRect().x,
            y: messageRef?.current.getBoundingClientRect().y
        };
        show(e, {
            position
        });
    }
    const handleReactions = () => {
        setReactionsVisible(reactionsVisible ? null : message_id);
    }
    const handleReactionButtonClose = () => {
        if (reactionsVisible) return;
        setShowReactionButton(false)
    }
    useEffect(() => {
        if (!reactionsVisible) {
            setShowReactionButton(false)
        }
    }, [reactionsVisible])
    return <div className="chat-message"
        onContextMenu={handleModeratorMenu}
        onMouseOver={() => setShowReactionButton(true)}
        onMouseLeave={handleReactionButtonClose}
        ref={messageRef}
    >
        <Portal>
            <MessageMenu {...props} />
        </Portal>
        <div className="message-icon"><IconUserCircle size="40" style={{ color: '#fff' }} /></div>
        <div className={`message-content ${quoted ? 'quoted' : ''} ${held ? 'held' : ''}`}>
            <div className="message-info">
                <div className="message-content-name">
                    <Localized id="chat-anonymous-name">
                        Anonymous
                    </Localized>
                </div>
                <div className="message-info-timestamp">
                    {getLocaleTime(timestamp, 'DD.MM.YYYY HH:mm')}
                </div>
            </div>
            <div className={`message-wrapper ${isReactions ? 'reactions' : ''}`}>
                {quoted && <div className='quoted-msg-container'>
                    <div className="sender">
                        {quoted.isAnonymous ? <Localized id="chat-anonymous-name">Anonymous</Localized> : quoted.sender_name}
                    </div>
                    <div className="message">
                        {isQuotedRemoved ?
                            <span className="quoted-removed"><Localized id="moderation-chatfeed-message-removed">This message has been removed</Localized></span> :
                            <MessageWrapper message={quoted.message} />}
                    </div>
                </div>}
                <div className={`message-content-message ${quoted ? 'quoted' : ''} ${reactionsVisible ? ' hilight' : ''}` + (totalReactions > BOOST_THRESHOLD ? ` boosted ${totalReactions > BG_THRESHOLD ? mostVotedReaction : ''}` : '')}>
                    <MessageWrapper message={message} />
                    {Object.keys(reactions).length !== 0 && <Reactions {...props} />}
                    {!held && <MessageButtons {...props} handleReply={handleReply} showReactionButton={showReactionButton} handleReactions={handleReactions} reactionsVisible={reactionsVisible} setReactionsVisible={setReactionsVisible} messageRef={messageRef} />}
                </div>
                {held && <div className='held-msg-info'>
                    <Localized id="chat-held-message-info">This message has been held for review</Localized>
                </div>}
            </div>
        </div>
    </div>
}

export const ModeratorMessage = (props) => {
    const { message, timestamp, message_id, messageRef, quoted, chat_history } = props;
    const isQuotedRemoved = quoted && !chat_history.find(m => m.message_id === quoted?.message_id)

    const { show } = useContextMenu({ id: message_id });

    function handleModeratorMenu(e) {
        const position = {
            x: messageRef?.current.getBoundingClientRect().x,
            y: messageRef?.current.getBoundingClientRect().y
        };
        show(e, {
            position
        });
    }
    return <div className="chat-message"
        onContextMenu={handleModeratorMenu}
    >
        <Portal>
            <MessageMenu {...props} />
        </Portal>

        <div className={"message-content-moderator"}>
            <div className="message-info">
                <div className="message-content-name moderator">{'Moderator'}</div>
                <div className="message-info-timestamp">
                    {getLocaleTime(timestamp, 'DD.MM.YYYY HH:mm')}
                </div>
            </div>
            {quoted && <div className='quoted-msg-container'>
                <div className="sender">
                    {quoted.isAnonymous ? <Localized id="chat-anonymous-name">Anonymous</Localized> : quoted.sender_name}
                </div>
                <div className="message">
                    {isQuotedRemoved ?
                        <span className="quoted-removed"><Localized id="moderation-chatfeed-message-removed">This message has been removed</Localized></span> :
                        <MessageWrapper message={quoted.message} />}
                </div>
            </div>}
            <div className={`message-content-message-moderator ${quoted ? 'quoted' : ''}`}>
                <MessageWrapper message={message} />
            </div>
        </div>
    </div>
}

export const ContinuedMessage = (props) => {
    const {
        message,
        timestamp,
        quoted,
        held,
        reactions,
        message_id,
        reactionsVisible,
        setReactionsVisible,
        handleReply,
        chat_history
    } = props;
    const [showReactionButton, setShowReactionButton] = useState(false);
    const [totalReactions, mostVotedReaction] = calculateReactions(reactions);
    const isReactions = Object.keys(reactions).length !== 0
    const { show } = useContextMenu({ id: message_id });
    const messageRef = useRef(null);
    const isQuotedRemoved = quoted && !chat_history.find(m => m.message_id === quoted?.message_id)
    const handleReactions = () => {
        setReactionsVisible(reactionsVisible ? null : message_id);
    }
    function handleModeratorMenu(e) {
        const position = {
            x: messageRef?.current.getBoundingClientRect().x,
            y: messageRef?.current.getBoundingClientRect().y
        };
        show(e, {
            position
        });
    }
    const handleReactionButtonClose = () => {
        if (reactionsVisible) return;
        setShowReactionButton(false)
    }
    useEffect(() => {
        if (!reactionsVisible) {
            setShowReactionButton(false)
        }
    }, [reactionsVisible])
    return <div ref={messageRef} className="chat-message-continued"
        onContextMenu={handleModeratorMenu}
        onMouseOver={() => setShowReactionButton(true)}
        onMouseLeave={handleReactionButtonClose}
    >
        <Portal>
            <MessageMenu {...props} />
        </Portal>
        <div className="message-timestamp">
            {getLocaleTime(timestamp, 'HH:mm')}
        </div>
        <div className={`message-content ${quoted ? 'quoted' : ''}  ${held ? 'held' : ''}`} >
            <div className={`message-wrapper ${isReactions ? 'reactions' : ''}`}>
                {quoted && <div className='quoted-msg-container'>
                    <div className="sender">
                        {quoted.isAnonymous ? <Localized id="chat-anonymous-name">Anonymous</Localized> : quoted.sender_name}
                    </div>
                    <div className="message">
                        {isQuotedRemoved ?
                            <span className="quoted-removed"><Localized id="moderation-chatfeed-message-removed">This message has been removed</Localized></span> :
                            <MessageWrapper message={quoted.message} />}
                    </div>
                </div>}
                <div className={`message-content-message ${quoted ? 'quoted' : ''} ${reactionsVisible ? ' hilight' : ''}` + (totalReactions > BOOST_THRESHOLD ? ` boosted ${totalReactions > BG_THRESHOLD ? mostVotedReaction : ''}` : '')}>
                    <MessageWrapper message={message} />
                    {(isReactions && !held) && <Reactions {...props} />}
                    {!held && <MessageButtons {...props} handleReply={handleReply} showReactionButton={showReactionButton} handleReactions={handleReactions} reactionsVisible={reactionsVisible} setReactionsVisible={setReactionsVisible} messageRef={messageRef} />}
                </div>
                {held && <div className='held-msg-info'>
                    <Localized id="chat-held-message-info">This message has been held for review</Localized>
                </div>}
            </div>


        </div>
    </div>
}

export const ModeratorInfo = (props) => {
    const { sender_name, message } = props;
    <div className="chat-message" >
        <div className="message-content-moderator">
            <div className="message-info">
                <div className="message-content-name moderator">{sender_name}</div>
            </div>
            <div className="message-content-message-moderator">
                <Emojione onlyEmojiClassName="liveto-chat-emoji" text={message} />
                {message.reason ? <p className="moderator-reason-p">{'Reason:' + message.reason}</p> : ''}
            </div>
        </div>
    </div>
}

function calculateReactions(reactions) {
    let totalReactions = 0;
    let mostVotedReactionTotal = 0;
    let mostVotedReactionType = '';
    for (let e in reactions) {
        totalReactions += parseInt(reactions[e]);
        if (parseInt(reactions[e]) > mostVotedReactionTotal) {
            mostVotedReactionTotal = parseInt(reactions[e]);
            mostVotedReactionType = e;
        }
    }
    return [totalReactions, mostVotedReactionType];
}

const Reactions = (props) => {
    const { reactions = {} } = props;

    const emojis = [];
    for (let emoji in reactions) {
        if (reactions[emoji] > 0) {
            emojis.push(<div className="reaction-pill" key={emoji}>
                <Emojione onlyEmojiClassName="message-reactions-emoji" text={":" + emoji + ":"} />
                <span className="reaction-amount">{reactions[emoji]}</span>
            </div>)
        }
    }

    return <div className="message-reactions">
        {emojis}
    </div>
}
