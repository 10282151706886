import { SettingContext } from "containers/VirtualEvent/contexts";
import moment from "moment";
import { useContext } from "react";

const History = (props) => {
  const { historyLabels, historyTravelTo, currentPointer } = props
  const settings = useContext(SettingContext)
  const { darkMode, leftNavView } = settings

  return <div className={`editor-history ${leftNavView} ${darkMode ? 'dark' : ''}`}>
    {historyLabels.map(({ text, time }, i) =>
      <div className={`history-label ${i === currentPointer ? 'active' : ''}`} key={`history_label_${i}`} onClick={() => historyTravelTo(i)}>
        {text}
        <span className="history-time">{moment(time).format('HH:mm:ss')}</span>
      </div>
    )}
  </div>
}

export default History