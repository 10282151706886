import WsConnector from './wsConnector';
// import WsConnector from '../wsWorker/client';

// import * as MoleClient from '@yoursunny/mole-rpc/MoleClient';
// @ts-ignore
// import * as MoleClient from './mole-rpc/MoleClient';
// import * as MoleServer from '@yoursunny/mole-rpc/MoleServer';
// @ts-ignore
// import * as MoleServer from './mole-rpc/MoleServer';

// import * as X from '@yoursunny/mole-rpc/X';
// import X from './mole-rpc/X';


// import TransportWsGeneral from './mole/TransportWsGeneral';


// export const client = new MoleClient({
//   requestTimeout: 20000,
//   transport: new TransportWsGeneral({ wss: WsConnector, })
// });

// export const server = new MoleServer({ transports: [new TransportWsGeneral({ wss: WsConnector })]});

// import { SpookyRPCBatched, RpcPacket, X } from './spooky';
import SpookyRPC, { StringPayloadLimiter, RpcPacket, X } from '../../shared/spooky';

const limiter = new StringPayloadLimiter({
  callback: (s: string) => { WsConnector.send(s); },
  timeOut: 100, charLimit: 16000,
});

export const spooky = new SpookyRPC({
  sendRpcPacket: async (p: RpcPacket | RpcPacket[]) => { limiter.addPacket(p); },
});

// export const spooky = new SpookyRPCBatched({
//   sendRpcPacket: async (p: RpcPacket | RpcPacket[]) => { WsConnector.send(JSON.stringify(p)); },
//   batchTimerLength: 50, // 50
// });

WsConnector.on('message', (msg: string) => {
  try {
    const j = JSON.parse(msg) as RpcPacket;
    spooky.receiveRpcPacket(j);
  } catch (err) {
    console.warn('WsConnector onMessage receiveRpcPacket Error:', err, msg);
  }
});

// let started = false;

// function ensureStarted() {
//   if (!started) {
//     server.run();
//     started = true;
//   }
// }

export function exposeRPCObject(c: any) {
  spooky.expose(c);
  // ensureStarted();
}

// const rpcMethodHolder = {};
export function exposeRPCMethod(methodName: string, method: any, options: any) {
  // rpcMethodHolder[methodName] = method;
  // spooky.expose(rpcMethodHolder);
  spooky.register(methodName, method, options);
  // ensureStarted();
}

export async function invoke(method: string, ...args: any[]) {
  try {
    return await spooky.invoke(method, ...args);
  } catch (error) {
    // console.log('invoke error', JSON.stringify(error), Object.keys(error), typeof error, error.prototype, error instanceof X.ExecutionError);
    if (error instanceof X.ExecutionError || error.code === -32002) {
      throw new Error('Invoke "' + method + '": ' + error.message);
    } else {
      throw error;
    } 
  }
}

export async function notify(method: string, ...args: any[]) {
  try {
    return await spooky.notify(method, ...args);
  } catch (error) {
    if (error instanceof X.ExecutionError || error.code === -32002) {
      throw new Error('Notify "' + method + '": ' + error.message);
    } else {
      throw error;
    } 
  }
}