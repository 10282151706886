/** @format */

import { Localized } from "@fluent/react";
import { useState } from "react";
import Select from "react-select";
import validator from "validator";
import parse from "html-react-parser";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import countries from "i18n-iso-countries";
import english from "i18n-iso-countries/langs/en.json";
import finnish from "i18n-iso-countries/langs/fi.json";
import swedish from "i18n-iso-countries/langs/sv.json";
import { generateExtraFieldComponents } from "./hooks";

countries.registerLocale(english);
countries.registerLocale(finnish);
countries.registerLocale(swedish);

export const ConditionalLogic = props => {
	const { componentsWithCondition = [], children, form = {}, fieldKey, type } = props;

	const componentValue = form[fieldKey];

	const filterConditionalComponents = comp => {
		if (componentValue === undefined || componentValue === null) return false;
		if (type === "select") return componentValue.includes(comp.condition[fieldKey]);
		else if (type === "checkbox") return componentValue === comp.condition[fieldKey];
		else return false;
	};
	const renderConditionalComponents = componentsWithCondition.filter(c => filterConditionalComponents(c));

	if (renderConditionalComponents.length === 0) return children;

	const { errors, language, handleChange } = props;
	const genProps = {
		errors,
		language,
		handleChange,
		data: renderConditionalComponents,
		form,
		componentsWithCondition,
	};

	const formComponents = generateExtraFieldComponents(genProps);

	return (
		<>
			{children}
			{renderConditionalComponents.length > 0 && formComponents}
		</>
	);
};

const LabelComponent = props => {
	const { link, text, language, required } = props;
	return (
		<div className="input-label">
			{link && (
				<>
					<LinkComponent text={text} link={link} language={language} />{" "}
					{required ? <span className="required-star"> *</span> : ""}
				</>
			)}
			{!link && (
				<label className="component-label">
					{text[language]} {required ? <span className="required-star"> *</span> : ""}
				</label>
			)}
		</div>
	);
};

export const LinkComponent = props => {
	const { link, text, language } = props;
	return (
		<div className="link-container">
			<a href={link[language]} target="_blank" rel="noreferrer">
				{text[language]}
			</a>
		</div>
	);
};

export const DateInput = props => {
	const {
		text = {},
		required = false,
		placeholder,
		language = "en",
		handleChange,
		fieldKey,
		min,
		max,
		warning,
	} = props;
	const [value, setValue] = useState(placeholder);
	const handleSelect = e => {
		setValue(e.target.value);
		handleChange(fieldKey, e.target.value);
	};
	return (
		<div className="input-group text-input">
			<LabelComponent text={text} required={required} language={language} />
			<input
				className={`generic-input ${warning ? "warning" : ""}`}
				value={value}
				type="date"
				placeholder={placeholder}
				min={min}
				max={max}
				onChange={e => handleSelect(e)}
			/>
		</div>
	);
};

export const NoteComponent = props => {
	const { text = {}, language = "en" } = props;
	const content = text[language] || "";
	return <p>{parse(content)}</p>;
};

export const TextInput = props => {
	const {
		text = {},
		required = false,
		placeholder = "",
		language = "en",
		handleChange,
		type,
		fieldKey,
		warning,
		form = {},
	} = props;
	const value = form[fieldKey];
	return (
		<div className="input-group text-input">
			<LabelComponent text={text} required={required} language={language} />
			{(type === "text" || type === "textarea") && (
				<textarea
					className={`generic-input ${warning ? "warning" : ""}`}
					rows={type === "text" ? "2" : "4"}
					placeholder={placeholder[language]}
					onChange={e => handleChange(fieldKey, e.target.value)}
					value={value}
				></textarea>
			)}
			{type === "email" && (
				<input
					type="email"
					className={`generic-input ${warning ? "warning" : ""}`}
					placeholder={placeholder[language]}
					onChange={e => handleChange(fieldKey, e.target.value)}
					value={value}
				/>
			)}
		</div>
	);
};

export const PhoneFieldInput = props => {
	const {
		text = {},
		required = false,
		placeholder = "",
		language = "en",
		handleChange,
		type,
		fieldKey,
		warning,
	} = props;
	const labels = countries.getNames(language === "se" ? "sv" : language, { select: "official" });

	const phoneProps = {
		defaultCountry: "FI",
		addInternationalOption: false,
		initialValueFormat: "national",
		labels,
	};

	return (
		<div className="input-group text-input">
			<LabelComponent text={text} required={required} language={language} />
			<PhoneInput
				//className={`generic-input ${warning ? "warning" : ""}`}
				onChange={val => handleChange(fieldKey, val)}
				{...phoneProps}
			/>
		</div>
	);
};

export const CheckboxInput = props => {
	const { text, link, required, language, handleChange, fieldKey } = props;
	const [checked, setChecked] = useState(false);
	const handleSelect = () => {
		handleChange(fieldKey, !checked);
		setChecked(!checked);
	};
	return (
		<div className="input-group checkbox">
			<LabelComponent text={text} link={link} required={required} language={language} />
			<input checked={checked} type="checkbox" className="checkbox-input generic-input" onChange={handleSelect} />
		</div>
	);
};

export const SelectInput = props => {
	const {
		text = {},
		required = false,
		multiple = false,
		choices = [],
		placeholder = "",
		language = "en",
		handleChange,
		fieldKey,
		warning,
		type,
	} = props;

	const countriesObj = countries.getNames(language === "se" ? "sv" : language, { select: "official" });
	const countryOptions = Object.keys(countriesObj).map(k => ({ value: k, label: countriesObj[k] }));

	const options =
		type === "select"
			? choices.map(c => {
					return { label: c.text[language], value: c.key };
			  })
			: countryOptions;

	const handleSelect = value => {
		if (type === "select") {
			if (!value) {
				handleChange(fieldKey, value);
				return;
			}
			const values = multiple ? value.map(v => v.value) : [value.value];
			handleChange(fieldKey, values);
		} else {
			handleChange(fieldKey, value.value);
		}
	};
	const setValue = () => {
		if (type === "select") return "";
		return countryOptions.find(country => country.value === placeholder.toUpperCase()) || "";
	};

	return (
		<div className="input-group select">
			<LabelComponent text={text} required={required} language={language} />
			<div className="select-container">
				<Select
					defaultValue={setValue()}
					options={options}
					isMulti={multiple}
					placeholder={placeholder[language]}
					className={`select-group generic-input ${warning ? "warning" : ""}`}
					menuPortalTarget={document.body}
					styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
					onChange={value => handleSelect(value)}
				/>
			</div>
		</div>
	);
};

export const RatingComponent = props => {
	const {
		choices = [],
		id,
		text,
		questions,
		name,
		handleChange: onChange,
		errors,
		language = "en",
		fieldKey,
		warning,
		type,
		form = {},
	} = props;
	const value = form[fieldKey] || {};
	const handleChange = (val, key) => {
		const newVal = { ...value, [key]: val };
		onChange(fieldKey, newVal);
	};
	const selectProps = {
		ratingKey: fieldKey,
		options: choices,
		onChange: handleChange,
		errors,
		language,
	};
	return (
		<div className="input-group rating">
			<label className="component-label">{text[language]}</label>
			<div className="rating-questions">
				{questions.map(q => (
					<div style={{ width: "100%" }} key={`q-row-${q.key}`}>
						<RatingSelect {...q} {...selectProps} questionKey={q.key} value={value[q.key]} />
					</div>
				))}
			</div>
		</div>
	);
};

const RatingSelect = props => {
	const {
		placeholder = "Select",
		text,
		ratingKey,
		questionKey,
		value,
		onChange,
		options,
		isMulti,
		label = "",
		required = false,
		classNames = {},
		language,
		errors,
	} = props;

	const warning = errors.includes(`${ratingKey}:${questionKey}`);

	return (
		<div className="rating-question-container">
			<label className="question-label">
				{text[language]}
				{required ? <span className="required-star"> *</span> : ""}
			</label>
			<select
				className={`select-group generic-input ${warning ? "warning" : ""}`}
				label={label}
				required={required}
				onChange={e => onChange(e.target.value, questionKey)}
				name={questionKey}
				//value={value}
			>
				{placeholder && <option value="">{placeholder}</option>}
				{options.map(o => (
					<option key={o.value} value={o.key} classNames="rating-question-option-sidemenu">
						{o.text[language]}
					</option>
				))}
			</select>
		</div>
	);
};

export const RegisterContainer = ({ signup_params, registerRef }) => {
	return (
		<div className="auth-form" ref={registerRef}>
			<div className="auth-form-info">
				<div className="event-type">
					<Localized id="auth-info-title-registration">Create account</Localized>
				</div>
				<div className="auth-description">
					<Localized id="auth-register-info" />
				</div>
			</div>

			<div className="auth-form-actions">
				<Localized id="auth-register-link">
					<a
						className="auth-button"
						href={`https://events.liveto.io/signup${signup_params}`}
						target="_blank"
						rel="noreferrer"
						data-cy="register-button"
					>
						Register
					</a>
				</Localized>
			</div>
		</div>
	);
};
