import { auth_token, auth_pass, auth_old_token } from '../../lib/auth';
import { exposeRPCObject, exposeRPCMethod } from '../../lib/rpcInstance';
import ServiceInvoker from '../../lib/serviceInvoker';
import WsConnector from '../../lib/wsConnector';
import EventEmitter from '../../lib/eventEmitter';

// Client Library Entry for Signalling

// TODO: implement session/connection as separate concepts in signalling to ease reconnection logic
// TODO: improve wsconnector in general
// TODO: replace mole-rpc with own version / patched version
// TODO: implement auth in connection header optionally
// TODO: check once() calls for potential leaks?
// TODO: get a better event emitter base class!
// TODO: handle token changing!
// TODO: have signalling notify the authed event?
// TODO: find bug about reconnect multilistener event storm spam?

type SignallingClientOptions = {
  signallingURL?: string,
  token?: string,
};

class SignallingClient extends EventEmitter {
  private inited = false;
  private connected = false;
  private authed = false;
  private token: string;
  private waitForReadyHolder: any;
  private waitForAuthedHolder: any;

  constructor() {
    super(null);
    WsConnector.on('open', async () => {
      this.connected = true;
      this.emit('open', null);
      if (this.token) {
        try {
          await auth_token(this.token);
          this.authed = true;
          this.emit('authed', null);
        } catch (err) {
          this.authed = false;
          this.emit('authError', err);
        }
      }
    });

    WsConnector.on('close', async () => {
      this.connected = false;
      this.authed = false;
      this.emit('close', null);
    });
  }

  public async init(options: SignallingClientOptions) {
    if (this.inited) return;
    if (options.hasOwnProperty('signallingURL')) WsConnector.setSignallingURLOverride(options.signallingURL);
    if (options.hasOwnProperty('token')) this.token = options.token;
    if (this.token) {
      await this.waitForAuthed();
    } else {
      await this.waitForReady();
    }
    this.inited = true;
  }

  public async updateToken(token: string) {
    this.token = token;
  }

  public async waitForReady() {
    if (this.connected) return;
    if (!this.waitForReadyHolder) {
      this.waitForReadyHolder = this._waitForReady();
      this.waitForReadyHolder.then(() => { this.waitForReadyHolder = null; });
      this.waitForReadyHolder.catch(() => { this.waitForReadyHolder = null; });
    }
    return this.waitForReadyHolder;
  }

  private async _waitForReady() {
    return await WsConnector.waitForReady();
  }

  public async waitForAuthed() {
    if (this.authed) return;
    if (!this.waitForAuthedHolder) {
      this.waitForAuthedHolder = this._waitForAuthed();
      this.waitForAuthedHolder.then(() => { this.waitForAuthedHolder = null; });
      this.waitForAuthedHolder.catch(() => { this.waitForAuthedHolder = null; });
    }
    return this.waitForAuthedHolder;
  }

  private async _waitForAuthed() {
    await WsConnector.waitForReady();
    if (this.authed) return;
    return new Promise((resolve, reject) => {
      try {
        this.once('authed', resolve);
        this.once('authError', reject);
      } catch (err) { reject(err); }
    });
  }

  public exposeRPCObject(obj: any) {
    return exposeRPCObject(obj);
  }

  public exposeRPCMethod(method: string, func: any, options?: any) {
    return exposeRPCMethod(method, func, options);
  }

  // Testing Method
  public async auth_pass(user: string, pass: string) {
    try {
      const t = await auth_pass(user, pass);
      this.authed = true;
      this.token = t;
      this.emit('authed', null);
      return t;
    } catch (err) {
      this.authed = false;
      this.token = null;
      this.emit('authError', err);
    }
  }

  public get ServiceInvoker() { return ServiceInvoker; }
  public get isConnected() { return this.connected; }
  public get isAuthed() { return this.authed; }
}

export default new SignallingClient();
