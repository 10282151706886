const moment = require('moment')

//2021-04-09T14:41

export const formatDate = (date, timeValue, endTimeValue) => {
    const day = moment(date).format("YYYY-MM-DD")
    const start = moment(timeValue).format('HH:mm')
    const end = moment(endTimeValue).format('HH:mm')

    const startTime = moment(new Date(`${day}T${start}`))
    const endTime = moment(new Date(`${day}T${end}`))
    const duration = endTime.diff(startTime, 'minutes')

    const timeObject = {
        startTime: startTime.format("YYYY-MM-DDTHH:mm:ss"),
        endTime: endTime.format("YYYY-MM-DDTHH:mm:ss"),
        duration
    }

    return timeObject
}