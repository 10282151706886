import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IconAcceptCall, IconRejectCall } from 'components/icons';
import './callView.scss';
import * as a from 'actiontypes';
import { parseImageLetter } from 'containers/VirtualEvent/contentView/chat/message';
import { Localized } from "@fluent/react";

function mapStateToProps(state) {
    return {
        busy: state.webrtc.busy,
        user_id: state.websocketUser.user_id,
        callingUserId: state.webrtc.callingUserId,
        callingChannelName: state.webrtc.callingChannelName,
        otherParticipantFirstName: state.webrtc.otherParticipantFirstName,
        otherParticipantLastName: state.webrtc.otherParticipantLastName,
        otherParticipantIcon: state.webrtc.otherParticipantIcon,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        ringAccept: (data) => dispatch({ type: a.WRTC_RING_ACCEPT, payload: data }),
        ringReject: (data) => dispatch({ type: a.WRTC_RING_REJECT, payload: data }),
        unring: (data) => dispatch({ type: a.WRTC_RING, payload: { unring: true, ...data } }),
    }

}


class WaitingForAnswer extends Component {

    render() {
        const { otherParticipantFirstName: first_name, otherParticipantLastName: last_name, otherParticipantIcon: icon } = this.props;

        const full_name = `${first_name} ${last_name}`
        return <div className={`call-container${this.props.darkmode ? ' darkmode' : ''}`}>
            <div className="incoming-call">
                <div className="caller-image" style={icon ? { backgroundImage: `url("${icon}")` } : {}}>{parseImageLetter(icon, full_name)}</div>
                <div className="caller-information">
                    <div className="caller-name">{full_name}</div>
                    <div className="caller-is-calling">
                        <Localized id="onetoonemeet-answer-description">
                            {'Wants to start a private video meet with you.'}
                        </Localized>
                        <span>.</span><span>.</span><span>.</span></div>
                    <div className="caller-will-you-accept">
                        <Localized id="onetoonemeet-answer-will-you-accept">
                            {'Will you accept?'}
                        </Localized>
                    </div>
                </div>
                <div className="incoming-call-controls">
                    <div className="incoming-call-control">
                        <button className="incoming-call-button accept" onClick={this.accept}>
                            <IconAcceptCall />
                        </button>
                        <Localized id="onetoonemeet-answer-accept-call">
                            {'accept_call'}
                        </Localized>
                    </div>
                    <div className="incoming-call-control">
                        <button className="incoming-call-button reject" onClick={this.reject}>
                            <IconRejectCall />
                        </button>
                        <Localized id="onetoonemeet-answer-reject-call">
                            {'accept_call'}
                        </Localized>
                    </div>
                </div>
            </div>
        </div>
    }


    accept = () => {
        if (!this.props.busy) return;
        this.props.ringAccept({ user_id: this.props.callingUserId, channel_name: this.props.callingChannelName });
        this.props.unring({ user_id: this.props.user_id }); // Tell other instances to stop ringing
    }

    reject = () => {
        if (!this.props.busy) return;
        this.props.ringReject({ user_id: this.props.callingUserId, channel_name: this.props.callingChannelName });
        this.props.unring({ user_id: this.props.user_id }); // Tell other instances to stop ringing
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WaitingForAnswer)