/** @format */

import { Localized, useLocalization } from "@fluent/react";
import "../css/calendarAgendaEvent.scss";
import { IconStar } from "components/icons";
import moment from "moment";
import { useManageMyCalendar } from "../helpers/hooks";
import { checkTimes, indicator } from "components/lvtHelpers";

const CalendarAgendaEvent = ({ event = {} }) => {
	const { title, slot = {}, schedule = "", start, end, type } = event;
	const { sent_invites = [] } = slot;

	const { l10n } = useLocalization();

	const past = moment().isAfter(moment(event.end));
	const [button, overlay] = useManageMyCalendar(
		slot,
		"more-info-btn",
		<div className="icon">
			<IconStar />
		</div>
	);

	const showDescription = sent_invites.length > 0 || (slot.user && slot.message);
	const showTitle =
		schedule || slot.schedule || sent_invites.length > 0 || (slot.user && slot.message) || type === "schedule";

	const showButtons =
		(schedule || slot.schedule || sent_invites.length > 0 || (slot.user && slot.message)) && type !== "schedule";

	const description = () => {
		if (sent_invites.length > 0) {
			const { user = {} } = sent_invites[0];
			return l10n.getString(
				"agenda-meeting-description",
				{ name: `${user.first_name} ${user.last_name}` },
				`Meeting with ${user.first_name} ${user.last_name}`
			);
		} else if (slot.user && slot.message) {
			return l10n.getString(
				"agenda-meeting-description",
				{ name: `${slot.user.first_name} ${slot.user.last_name}` },
				`Meeting with ${slot.user.first_name} ${slot.user.last_name}`
			);
		} else return "";
	};

	const Title = () => {
		if (showTitle) return title;
		else return <Localized id="status-available">Available</Localized>;
	};

	return (
		<div className="agenda-event">
			{overlay}
			<p>
				<Title /> {showDescription && `(${description()})`}
			</p>
			<div className="actions">
				{indicator(checkTimes(start, end))}
				{showButtons && (
					<>
						{!past && type !== "personal" && button}
						<button className="more-info-btn" data-cy="more-info-btn">
							<Localized id="schedule-agenda-info">Info</Localized>
						</button>
					</>
				)}
			</div>
		</div>
	);
};

export default CalendarAgendaEvent;
