
import { CHAT_REACTIONS } from 'constants.js';
import { Emojione } from 'react-emoji-render';
import * as a from 'actiontypes';
import { connect } from 'react-redux';
import './chat.scss';
import uniqueId from 'lodash/uniqueId';
import {useMemo} from 'react';

const mapStateToProps = state => ({
    active_channel: state.connection.active_channel,
    chatrooms: state.chatrooms,
    is_moderator: state.websocketUser.is_moderator
})
const mapDispatchToProps = dispatch => ({
    reactToMessage: payload => dispatch({ type: a.WS_REACT_WITH_EMOJI, payload: payload }),
})

const ReactionMenu = (props) => {
    const { message_id, room_id, reactToMessage, setReactionsVisible, top } = props;
    
    const reactions = useMemo(
        () => {
            function react(reaction) {
                reactToMessage({
                    room_id: room_id,
                    message_id: message_id,
                    reaction: reaction
                })
                setReactionsVisible(null)
            }
            return CHAT_REACTIONS.map(r => (
            <span onClick={() => react(r)} key={uniqueId()}>
                <Emojione size={32} onlyEmojiClassName="react-with-emoji" text={`:${r}:`} />
            </span>));
        }
        , 
        [room_id, message_id, reactToMessage, setReactionsVisible]
    );
    return <div className={`reaction-menu${top <= 150 ? ' open-down' :''}`}>
        {reactions}
    </div>
}

export default connect(mapStateToProps, mapDispatchToProps)(ReactionMenu)