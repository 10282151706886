/** @format */

import HeaderModal from "headingModal/modal";
import { Localized } from "@fluent/react";
import { useSelector } from "react-redux";
import "./css/moderation.scss";
import { useEffect, useState } from "react";
import { ChatView, ModerationViewHeader, SideNav } from "./components/chatComponents";
import { ModerationSettingsView } from "./components/moderationComponents";
import { chatServiceInvoke } from "middlewares/websocket";
import { LivetoLoader } from "components/icons";

const Moderation = props => {
	const event_slug = useSelector(state => state.event.slug);
	const displayType = useSelector(state => state.displayType);
	const { showModeration = false, setShowModeration } = props;
	const chatrooms = useSelector(state => state.chatrooms) || [];
	const held_messages = useSelector(state => state.moderator.held_messages);

	const [currentView, setCurrentView] = useState("chat");
	const [settings, setSettings] = useState(null);
	const [fetching, setFetching] = useState(false);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setFetching(true);
				const response = await chatServiceInvoke("modGetEventSettings", { event_slug });
				setSettings(response);
				chatrooms.forEach(async room => {
					const { room_id } = room;
					await chatServiceInvoke("modRequestHeldMessages", { room_id });
				});
			} catch (error) {
				console.error("Error fetching global moderation settings: ", error);
			} finally {
				setFetching(false);
			}
		};
		fetchData();
	}, []);

	const close = () => {
		setShowModeration(false);
	};

	const childProps = {
		loading,
		setLoading,
		globalSettings: settings,
		chatrooms,
		displayType,
		setSettings,
		setSuccess,
	};

	return (
		<HeaderModal
			modalClassName={`moderation-modal ${displayType}`}
			showModal={showModeration}
			setShowModal={setShowModeration}
			headingText={<Localized id="moderation-title">{"Moderation"}</Localized>}
			id="polls"
		>
			<div id="virtual-event-moderation">
				<SideNav currentView={currentView} setCurrentView={setCurrentView} displayType={displayType} />
				{!settings && <LivetoLoader size="100" />}
				{settings && (
					<div className="moderation-content">
						<ModerationViewHeader
							currentView={currentView}
							close={close}
							loading={loading}
							success={success}
						/>
						{currentView === "chat" && (
							<ChatView fetching={fetching} held_messages={held_messages} {...childProps} />
						)}
						{currentView === "settings" && <ModerationSettingsView {...childProps} />}
					</div>
				)}
			</div>
		</HeaderModal>
	);
};

export default Moderation;
