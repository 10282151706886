import * as a from "actiontypes";
import { cloneDeep } from 'lodash'

export const moderatorInitial = {
    moderator_mode: false,
    held_messages: [],
    anonymous_mode: null,
    slowmode: null,
};

const moderator = (state = moderatorInitial, action) => {
    switch (action.type) {
        case a.WS_DELETE_MESSAGE:
            return { ...state };
        case a.WS_MESSAGE_DELETED:
            return { ...state };
        case a.WS_DELETE_ALL_USER_MESSAGES:
            return { ...state };
        case a.WS_ALL_USER_MESSAGES_DELETED:
            return { ...state };
        case a.WS_SILENCE_USER:
            return { ...state };
        case a.WS_USER_SILENCED:
            return { ...state };

        case 'TOGGLE_MODERATOR_MODE':
            return { ...state, moderator_mode: action.payload };

        case a.WS_HELD_ROOM_MESSAGES: {
            let newState = cloneDeep(state)
            const { history = [] } = action.payload

            if (newState.held_messages.length === 0) newState.held_messages = history
            else {
                const ids = newState.held_messages.map(m => m.payload.message_id)
                history.forEach(msg => {
                    if (ids.includes(msg.payload.message_id)) return
                    newState.held_messages.push(msg)
                })
            }
            return newState

        }

        case a.WS_MOD_HELD_MSG: {
            let newState = cloneDeep(state)
            newState.held_messages = [...newState.held_messages, action.payload]
            return newState
        }

        case a.WS_MOD_HELD_MSG_SENT:
        case a.WS_MOD_HELD_MSG_DELETED: {
            let newState = cloneDeep(state)
            const { payload = {} } = action.payload
            const { message_id = "" } = payload
            const newHeldMessages = newState.held_messages.filter(m => m.payload.message_id !== message_id)
            newState.held_messages = newHeldMessages
            return newState
        }
        case a.SET_ANONYMOUS_MODE: {
            if (state.anonymous_mode === null) return { ...state, anonymous_mode: action.payload }
            let newState = cloneDeep(state)
            newState.anonymous_mode = { ...newState.anonymous_mode, ...action.payload }
            return newState
        }
        case a.SET_SLOWMODE: {
            if (state.slowmode === null) return { ...state, slowmode: action.payload }
            let newState = cloneDeep(state)
            newState.slowmode = { ...newState.slowmode, ...action.payload }
            return newState
        }
        case a.SET_RESTRICTED: {
            if (state.restricted === null) return { ...state, restricted: action.payload }
            let newState = cloneDeep(state)
            newState.restricted = { ...newState.restricted, ...action.payload }
            return newState
        }
        case a.SET_GLOBAL_ANONYMOUS_MODE: {
            let newState = cloneDeep(state)
            newState.anonymous_mode.global = action.payload
            return newState
        }
        case a.SET_GLOBAL_SLOWMODE: {
            let newState = cloneDeep(state)
            newState.slowmode.global = action.payload
            return newState
        }
        default:
            return state;
    }
};
export default moderator;
