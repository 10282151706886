
const initialState = {
    sidebar: {
        slot: null,
        isOpen: false,
        slotRef: null
    },
    settings: {
        isOpen: false,
        view: 'profile'
    },
    showChat: true
}

const componentReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_SIDEBAR': return { ...state, sidebar: { ...action.payload } };
        case 'HANDLE_EDITOR_CONTENT_REF': return { ...state, editorContentRef: action.payload };
        case 'HANDLE_SETTINGS_MODAL': return { ...state, settings: action.payload };
        case 'HANDLE_SHOW_CHAT': return { ...state, showChat: action.payload };
        default: return state;
    }
}

export default componentReducer