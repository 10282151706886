import { AlignItemsCenterIcon, AlignItemsFlexStartIcon, AlignItemsFlexEndIcon, AlignItemsStretchIcon } from "components/icons";
import React, { useEffect, useState } from "react";
import '../styles/widgets.scss';

const fallbackAlign = 'center';

export default function AlignItemsWidget(props) {
    const { identifiers, editProperties, properties, editLocks, editorDispatch, room } = props;
    const property = 'alignItems';
    const value = properties[property] ? properties[property] : fallbackAlign;
    const [alignItems, setalignItems] = useState(value);
    const changeValue = (newValue) => {
        editProperties(identifiers, property, newValue, editorDispatch, room, editLocks);
    };

    useEffect(() => {
        setalignItems(value);
    }, [value, identifiers, editProperties, property])

    const alignItemsOptions = ['center', 'flex-start', 'flex-end', 'stretch']

    const alignComponent = (option) => {
        switch (option) {
            case "center":
                return { title: "CENTER", icon: <AlignItemsCenterIcon /> }
            case "flex-start":
                return { title: "FLEX-START", icon: <AlignItemsFlexStartIcon /> }
            case "flex-end":
                return { title: "FLEX-END", icon: <AlignItemsFlexEndIcon /> }
            case "stretch":
                return { title: "STRETCH", icon: <AlignItemsStretchIcon /> }
        }
    }

    return (<div className="tool-inner-container alignment">
        <label>Align items</label>
        <div className="tools">
            {alignItemsOptions.map((option, i) =>
                <div className="tool" key={`alignItems_${i}`}>
                    {alignComponent(option).title}
                    <button className={"simple-button" + (option === value ? ' active' : '')} value={option} onClick={() => changeValue(option)}>
                        {alignComponent(option).icon}
                    </button>
                </div>)}
        </div>
    </div>);
};