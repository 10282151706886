/** @format */

import { Localized } from "@fluent/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { getMinMaxDate } from "./inviteHelpers";

export function useFilterAttendees(attendees = [], filter = "", tags = []) {
	const filterByFilter = (attendees, filter) => {
		const filtered = attendees.filter(att => {
			const full_name = att.type === "authenticated" ? `${att.first_name} ${att.last_name}` : att.username;
			return (
				full_name.toLowerCase().includes(filter.toLowerCase()) ||
				att.company_name?.toLowerCase().includes(filter.toLowerCase()) ||
				att.title?.toLowerCase().includes(filter.toLowerCase())
			);
		});
		return filtered;
	};

	const filterByTags = (attendees, tags) => {
		const inGroup = attendees.filter(a => a.groups?.length > 0);
		const filtered = inGroup.filter(a => {
			const inEveryFilterGroup = tags.every(tag => a.groups.includes(tag));
			return inEveryFilterGroup;
		});

		return filtered;
	};

	if (!filter && tags.length === 0) {
		return attendees;
	} else if (!filter) {
		return filterByTags(attendees, tags);
	} else if (tags.length === 0) {
		return filterByFilter(attendees, filter);
	} else {
		const filteredByFilter = filterByFilter(attendees, filter);
		return filterByTags(filteredByFilter, tags);
	}
}

export function useFilterSlots(calendar, attendee_calendar, date, showMe, showAttendee) {
	const { slots: attendeeSlots = [], received_invites: attendeeInvites = [] } = attendee_calendar;
	const { slots: mySlots = [], received_invites: myInvites = [] } = calendar;
	const filterAndSort = (slots, invites) => {
		const filteredSlots = slots.filter(s => {
			// Filtering by date and invites
			const { sent_invites = [] } = s;
			const isSameDate = moment(s.start_time).isSame(moment(date), "day");
			const isBusy = s.status === "busy";
			const isSentInvites = sent_invites.length > 0;
			const isCorrectStatus = isSentInvites
				? sent_invites[0].status === "pending" || sent_invites[0].status === "accepted"
				: true;
			return isSameDate && isBusy && isCorrectStatus;
		});
		const filteredInvites = invites.filter(inv => {
			// Filtering by date
			const isSameDate = moment(inv.slot?.start_time).isSame(moment(date), "day");
			const isCorrectStatus = inv.status === "accepted" || inv.status === "pending";
			return isSameDate && isCorrectStatus;
		});

		// Unifying sent invite and received invite objects
		const slotObjects = filteredSlots.map(slot => {
			const { start_time, end_time, id, name, sent_invites } = slot;
			const obj = {
				type: "sent_invite",
				start_time: start_time,
				end_time: end_time,
				slotID: id,
				name: name,
				user: sent_invites[0]?.user,
				status: sent_invites[0]?.status,
				message: sent_invites[0]?.message
			};
			return obj;
		});
		const invObjects = filteredInvites.map(invite => {
			const { slot = {}, user = {}, message = "", status } = invite;
			const obj = {
				type: "received_invite",
				start_time: slot.start_time,
				end_time: slot.end_time,
				slotID: slot.id,
				name: slot.name,
				user: user,
				status: status,
				message: message
			};
			return obj;
		});
		const combined = slotObjects.concat(invObjects);
		const sorted = combined.sort((a, b) => new Date(a.start_time) - new Date(b.start_time)); // Sorting by date
		return sorted;
	};
	return {
		me: showMe ? filterAndSort(mySlots, myInvites) : null,
		attendee: showAttendee ? filterAndSort(attendeeSlots, attendeeInvites) : null
	};
}

export function useSetTimeline(mySlots = [], attendeeSlots = [], availableSlots = [], select, date) {
	const slotsToMerge = mySlots ? mySlots : [];
	const attendeeSlotsToMerge = attendeeSlots ? attendeeSlots : [];
	const combinedSlots = slotsToMerge.concat(attendeeSlotsToMerge);
	const filteredByDate = combinedSlots.filter(s => moment(s.start_time).isSame(moment(date), "day"));
	let start;
	let end;

	const isAvailable = time => {
		if (availableSlots.length > 0) {
			return (
				availableSlots.some(slot =>
					moment(time).isBetween(moment(slot.start_time), moment(slot.end_time), null, "[)")
				) && moment(time).isSameOrAfter(moment(), "hour")
			);
		} else {
			return moment(time).isSameOrAfter(moment(), "hour");
		}
	};
	const defineFirstHour = () => {
		if (moment(date).isSame(moment(), "day")) {
			if (moment(start).isBefore(moment().startOf("hour")) && filteredByDate.length > 0) return moment(start);
			else return moment();
		} else {
			return moment(date).startOf("day");
		}
	};

	if (filteredByDate.length > 0) {
		start = getMinMaxDate(filteredByDate, "min", "start_time");
		end = moment(date).endOf("day");
	} else {
		start = moment(date).startOf("day");
		end = moment(date).endOf("day");
	}

	let momentHours = [];
	const firstHour = defineFirstHour();
	const startToSend = moment(firstHour).startOf("hour");
	while (!firstHour.isSameOrAfter(end, "minute")) {
		const newDate = new Date(firstHour);
		const available = isAvailable(firstHour);
		const cmpKey = `timeline-hour-${newDate.getTime()}`;
		momentHours.push(
			<div
				key={cmpKey}
				data-cy={`hour-slot-button-${available ? "available" : "unavailable"}`}
				className={`hour ${available ? "available" : ""}`}
				id={new Date(firstHour).getTime()}
				onClick={() => select(newDate, available)}
			>
				{firstHour.format("HH:00")}
			</div>
		);
		firstHour.add(1, "hours");
	}

	return [momentHours, startToSend];
}

export function useSearchByStringProps(list = [], propKeys = null, className, placeholder, localizedId, attrs) {
	const [filtered, setFiltered] = useState(list);
	const [searchTerm, setSearchTerm] = useState("");
	const handleChange = value => {
		setSearchTerm(value);
		const filteredList = list.filter(elem => {
			if (propKeys) {
				return propKeys.some(prop => elem[prop].toLowerCase().includes(value.toLowerCase()));
			} else return elem.toLowerCase().includes(value.toLowerCase());
		});
		setFiltered(filteredList);
	};
	const input = (
		<Localized id={localizedId} attrs={attrs}>
			<input
				className={className}
				value={searchTerm}
				placeholder={placeholder}
				onChange={e => handleChange(e.target.value)}
			/>
		</Localized>
	);
	return [filtered, input];
}

export function useMessageTimeOut(timedOut, time) {
	let timer = null; // eslint-disable-line no-unused-vars
	const [isTimedOut, setIsTimedOut] = useState();

	useEffect(() => {
		if (timedOut) {
			setIsTimedOut(timedOut);
			timer = setTimeout(() => {
				// eslint-disable-line react-hooks/exhaustive-deps
				setIsTimedOut(false);
			}, time);
		}
	}, [timedOut]);

	return [isTimedOut];
}

export function useSilenceTimer(silenced, silencedTime, stopSilence) {
	let timer = null;
	const [isSilenced, setIsSilenced] = useState();
	const [timeUntilUnmuted, setTimeUntilUnmuted] = useState({ hours: 0, minutes: 0, seconds: 0 });

	useEffect(() => {
		if (silenced) {
			setIsSilenced(silenced);
			timer = setInterval(() => {
				// eslint-disable-line react-hooks/exhaustive-deps
				setTimeUntilUnmuted(moment().countdown(silencedTime));

				if (
					moment()
						.utc()
						.local()
						.isAfter(silencedTime)
				) {
					clearInterval(timer);
					setIsSilenced(false);
					stopSilence();
				}
			}, 1000);
		}
	}, [silenced]);

	return [isSilenced, timeUntilUnmuted];
}

export function useUpdateNotifications(inbox, user_id) {
	const [notifications, setNotifications] = useState(0);

	useEffect(() => {
		if (inbox.private_messages) {
			let counter = 0;
			for (let priv of inbox.private_messages) {
				if (priv.preview_message && !priv.preview_message.seen) {
					counter++;
				}
				if (
					priv.chat_history.length > 0 &&
					priv.chat_history[priv.chat_history.length - 1].sender_id === user_id
				) {
					counter--;
				}
			}

			setNotifications(counter);
		}
	}, [inbox]); // eslint-disable-line react-hooks/exhaustive-deps
	return notifications;
}

export function useHandleCooldown(slowmode, guestuser, active_channel) {
	const [cooldown, setCooldown] = useState(0);
	const calculateCooldown = () => {
		const cd = slowmode?.[active_channel] === null ? slowmode?.global : slowmode?.[active_channel];
		if (guestuser && (cd < 5 || cd === undefined)) return 5;
		return cd;
	};
	useEffect(() => {
		setCooldown(calculateCooldown());
	}, [slowmode]);

	const [timeOutTime, setTimeOutTime] = useState(cooldown * 1000);
	const [timedOut, setTimedOut] = useState(false);

	useEffect(() => {
		if (cooldown) setTimeOutTime(cooldown * 1000);
	}, [cooldown]);

	const handleTimeout = () => {
		if (guestuser || cooldown > 0) {
			setTimedOut(true);
			setTimeout(() => {
				setTimedOut(false);
			}, timeOutTime);
		}
	};
	return [cooldown, timeOutTime, timedOut, handleTimeout];
}
