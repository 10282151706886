import { IconTimes } from "components/icons"
import { useEffect } from "react"
import { Localized } from "@fluent/react"
import { connect, useDispatch } from "react-redux"
import * as a from 'actiontypes'
import './notification_center.scss'
import { NotificationInvite } from "./components/NotificationComponents"
import { NotificationEngagement, NotificationCalendar } from "./components/NotificationComponents"
import { IconMore } from "components/icons"
import { useState } from "react"
import { removeNotification } from "middlewares/websocket"
import { useRef } from "react"
import { useOutsideClick } from "../hooks/hooks"
import { useFetchEventParticipants } from "containers/VirtualEvent/apiCalls"

const mapStateToProps = state => {
    return {
        notifications: state.notifications.notifications,
        close_modals: state.close_modals,
        theme: state.room.data?.theme,
        bellRef: state.refs.bellRef,
        isOpen: state.schedule.notification_center,
        pushNotificationRef: state.refs.invitePushNotificationRef,
        displayType: state.displayType
    }
}

const mapDispatchToProps = dispatch => ({
    resetNotifications: () => dispatch({ type: a.WS_RESET_NEW_NOTIFICATION }),
    handleOpenNotifications: payload => dispatch({ type: a.HANDLE_NOTIFICATION_CENTER, payload }),
    handleModals: () => dispatch({ type: a.HANDLE_MODALS, payload: false }),
    handleOpenSettings: (payload) => dispatch({ type: a.HANDLE_SETTINGS_MODAL, payload })
})

const DismissAll = ({ isOpen, notifications, setIsDismissAllOpen, dismissAllRef, openSettings }) => {
    const dispatch = useDispatch()

    const handleDismissAllNotifications = () => {
        dispatch({ type: 'CLEAR_NOTIFICATION_STATE' })
        notifications.forEach(notification => {
            removeNotification(notification.id)
        })
        setIsDismissAllOpen(!isOpen)
    }

    return (
        <div className="notification-center-dismiss-all-container" ref={dismissAllRef}>
            {notifications.length > 0 && <button className={`${notifications.length === 0 ? 'disabled' : ''}`} onClick={handleDismissAllNotifications}><Localized id="notification-center-dismiss-all-button-text">Dismiss all</Localized></button>}
            <button onClick={openSettings}><Localized id="notification-center-settings-button-text">Notification settings</Localized></button>
        </div>
    )
}

const NotificationCenter = (props) => {
    const {
        close_modals,
        handleOpenNotifications,
        isOpen,
        handleInvitation,
        newNotifications,
        notifications = [],
        resetNotifications,
        theme,
        bellRef,
        pushNotificationRef,
        displayType,
        handleModals,
        handleOpenSettings
    } = props
    const [isDismissAllOpen, setIsDismissAllOpen] = useState(false)
    const notificationCenterRef = useRef(null)
    const buttonRef = useRef(null)
    const dismissAllRef = useRef(null)
    let outsideClick = useOutsideClick(isOpen)

    const allAttendees = useFetchEventParticipants()

    useEffect(() => {
        if (newNotifications && isOpen) {
            resetNotifications()
        }
    }, [newNotifications])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isOpen) {
            const isNotificationCenterRef = !notificationCenterRef.current.contains(outsideClick) && notificationCenterRef.current !== outsideClick
            const isBellRef = !bellRef.current?.contains(outsideClick) && bellRef.current !== outsideClick
            const isPushNotificationRef = !pushNotificationRef?.current?.contains(outsideClick) && pushNotificationRef?.current !== outsideClick
            if (isNotificationCenterRef && isBellRef && isPushNotificationRef) {
                handleOpenNotifications(false)
            }

            const isDismissAllRef = !dismissAllRef.current?.contains(outsideClick) && dismissAllRef.current !== outsideClick
            const isButtonRef = !buttonRef.current?.contains(outsideClick) && buttonRef.current !== outsideClick
            if (isButtonRef && isDismissAllRef) {
                setIsDismissAllOpen(false)
            }
        }

    }, [outsideClick])// eslint-disable-line react-hooks/exhaustive-deps

    const openSettings = () => {
        handleOpenSettings({ isOpen: true, view: 'notifications' })
        handleModals()
    }

    return (
        <div className={`notification-center pb-3 ${isOpen ? 'notification-center-open' : 'notification-center-close'} ${theme} ${displayType}`} ref={notificationCenterRef}>
            {isDismissAllOpen && <DismissAll isOpen={isDismissAllOpen} openSettings={openSettings} notifications={notifications} setIsDismissAllOpen={setIsDismissAllOpen} dismissAllRef={dismissAllRef} />}
            <div className="notification-center-header">
                <Localized id="notification-center-header">
                    <h5 className="notification-center-title">Notifications</h5>
                </Localized>
                <div className="notification-header-buttons-container">
                    <button className="notification-center-more-button" ref={buttonRef} onClick={() => setIsDismissAllOpen(!isDismissAllOpen)}><IconMore /></button>
                    <button className="close-notification-center-button" onClick={() => handleOpenNotifications(false)}><IconTimes /></button>
                </div>
            </div>
            <div className="notification-center-container">
                {
                    notifications.length > 0
                        ?
                        notifications.map(notification =>
                            notification.type === 'invite' ?
                                <NotificationInvite notification={notification} key={notification.id} handleInvitation={handleInvitation} /> :
                                notification.type === 'engagement' ?
                                    <NotificationEngagement notification={notification} key={notification.id} /> :
                                    notification.type === 'meeting_reminder' || notification.type === 'event_reminder' ?
                                        <NotificationCalendar notification={notification} key={notification.id} attendees={allAttendees} /> :
                                        ""
                        )

                        :
                        <Localized id='no-invites-msg'>
                            <div className="px-3 pt-3">
                                No new notifications
                            </div>
                        </Localized>
                }
            </div>


        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCenter)