import { parseImageLetter } from "../../message"
import { Localized } from "@fluent/react"
import { GenericTooltip } from "components/ToolTip/Tooltip"
import { Menu, Separator, useContextMenu } from "react-contexify"
import { useRef, useState } from "react"
import { useSelector } from "react-redux"
import { resetUserFromLeaderboard } from "middlewares/websocket"

function useOpenContextMenu(user_id, engagementRef) {
    const { show } = useContextMenu({ id: user_id })
    return function handleModeratorMenu(e) {
        /* const position = {
            x: engagementRef?.current.getBoundingClientRect().x,
            y: engagementRef?.current.getBoundingClientRect().y
        }; */
        const position = {
            x: e.clientX,
            y: e.clientY
        };
        show(e, {
            position
        });
    }
}

const ModeratorMenu = (props) => {
    const [showAreYouSure, setShowAreYouSure] = useState(false)
    const { user } = props
    const { user_id, icon, first_name, last_name } = user
    const event_slug = useSelector(state => state.event.slug)

    const handleAreYouSure = (e, value) => {
        e.stopPropagation()
        setShowAreYouSure(value)
    }

    const handleRemovePerson = () => {
        resetUserFromLeaderboard(event_slug, user_id)
        setShowAreYouSure(false)
    }

    return <Menu id={user_id} className="engagement-moderator-menu">
        <div className="moderator-menu-header">
            <div className="moderator-user-preview-image" style={{ backgroundImage: `url("${icon}")` }}>
                {parseImageLetter(icon, `${first_name} ${last_name}`)}
            </div>
            <div className="moderator-user-name">
                <div className="first">
                    {first_name}
                </div>
                <div className="last">
                    {last_name}
                </div>
            </div>
        </div>

        <Separator />
        {!showAreYouSure && <div className="menu-content">
            <div className="button-row">
                <button className="engagement-ban-button" onClick={(e) => handleAreYouSure(e, true)}><Localized id="engagement-moderator-menu-remove-button-text">Reset score</Localized></button>
            </div>
            <Localized id="engagement-moderator-menu-reset-desc"><p>This person's score will be reset to 0</p></Localized>
        </div>}
        {showAreYouSure && <div className="menu-content">
            <Localized id="engagement-moderator-menu-are-you-sure"><p>Are you sure?</p></Localized>
            <div className="button-row">
                <button className="engagement-ban-button" onClick={handleRemovePerson}><Localized id="engagement-moderator-menu-yes-button">Yes</Localized></button>
                <button className="engagement-ban-button" onClick={(e) => handleAreYouSure(e, false)}><Localized id="engagement-moderator-menu-no-button">No</Localized></button>
            </div>
        </div>}

    </Menu>
}

export const MyPosition = ({ leaderboard, position, xp }) => {
    return (
        <>
            {leaderboard && !leaderboard.isInLeaderboard &&
                <div className="leaderboard-my-position">
                    {/* <LeaderboardRow spot={{ first_name: user.first_name, last_name: user.last_name, xp: myEventXp }} position={myPosition+1} /> */}
                    <div className="position-row">
                        <div className="position-text">
                            <Localized id="engagement-your-position" />
                        </div>
                        <div className="position">
                            {position}
                        </div>
                    </div>
                    <div className="xp-row">
                        {xp}{' '}<Localized id="engagement-pts-text" />
                    </div>
                </div>}
        </>
    )
}

export const Top3Spot = ({ spot, position, handleGoToProfile }) => {
    const engagementRef = useRef(null)
    const handleModeratorMenu = useOpenContextMenu(spot?.user_id, engagementRef)
    const is_moderator = useSelector(state => state.websocketUser.is_moderator)

    return (
        <>
            {spot ?
                <div onContextMenu={is_moderator ? handleModeratorMenu : null} ref={engagementRef} >
                    {is_moderator && <ModeratorMenu user={spot} />}
                    <div className={`engagement-icon ${position === 1 ? 'icon-first-wrapper' : ''}`} onClick={() => handleGoToProfile(spot)}>
                        <div className={`leaderboard-person-icon ${position === 1 ? 'icon-first' : ''}`} style={{ backgroundImage: spot.icon ? 'url("' + spot.icon + '")' : 'unset' }}>
                            {parseImageLetter(spot.icon, `${spot.first_name} ${spot.last_name}`)}
                        </div>
                    </div>

                    <div className="leaderboard-additional-info">
                        <div className="leaderboard-additional-info-name">
                            <strong>{spot.first_name}</strong>
                        </div>
                        <div className="leaderboard-additional-info-name mt-0">
                            <strong>{spot.last_name}</strong>
                        </div>
                        <div className="leaderboard-additional-info-company">
                            {spot.company_name}
                        </div>
                    </div>
                    <div className="leaderboard-xp">
                        {spot.xp}{' '}<Localized id="engagement-pts-text" />
                    </div>
                </div> :
                <div style={{ height: '100%', width: '100%' }} />}
        </>

    )
}

export const LeaderboardRow = ({ spot, myChatID, position, isInLeaderboard, handleGoToProfile, isStealth }) => {
    const engagementRef = useRef(null)
    const handleModeratorMenu = useOpenContextMenu(spot?.user_id, engagementRef)
    const is_moderator = useSelector(state => state.websocketUser.is_moderator)
    return (
        <div className={`leaderboard-row ${myChatID === spot.user_id ? 'me-in-leaderboard' : ''}`} onContextMenu={handleModeratorMenu} ref={engagementRef}>
            {is_moderator && <ModeratorMenu user={spot} />}
            <div className="leaderboard-icon" style={{ backgroundImage: spot.icon ? 'url("' + spot.icon + '")' : 'unset' }} onClick={() => handleGoToProfile(spot)}>
                {parseImageLetter(spot.icon, `${spot.first_name} ${spot.last_name}`)}
            </div>
            <div className={`leaderboard-name-xp-position ${!isInLeaderboard && myChatID === spot.user_id ? 'my-position' : ''}`}>
                <div className="leaderboard-name-xp">
                    <div className="user-name">
                        {
                            !isInLeaderboard && myChatID !== spot.user_id
                                ?
                                `${spot.first_name} ${spot.last_name}`
                                :
                                <Localized id="engagement-your-position" />
                        }
                        {
                            isStealth &&
                            <GenericTooltip orientation="top" text={<Localized id="engagement-stealth-indicator-tooltip" />} hide="100">
                                <div className="engagement-stealth-indicator"><Localized id="engagement-stealth-indicator">stealth mode</Localized></div>
                            </GenericTooltip>
                        }
                    </div>



                    <div className="user-xp">
                        {spot.xp}{' '}<Localized id="engagement-pts-text" />
                    </div>

                </div>

                <div className="leaderboard-position">
                    {position}
                </div>
            </div>

        </div>
    )
}