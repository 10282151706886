/** @format */

import { useContext } from "react";
import "./styles.scss";
import parse from "html-react-parser";
import { APP_PATHS } from "constants.js";
import { IconArrowsRight } from "components/icons";
import { NavLink } from "react-router-dom";
import { useAccessKey } from "containers/VirtualEvent/editorView/apiCalls";
import { SettingContext } from "containers/VirtualEvent/contexts";
import { getDefaultOrAnyContent, useCurrentLanguage } from "util/localization";
import { useDispatch } from "react-redux";
import * as a from "actiontypes";

const getElementContent = (element, elementList, language, languageOptions, isEditor) => {
	const current = elementList.find(e => e.name === element);
	let retval = current?.content;
	if (element !== "Background") {
		retval = getDefaultOrAnyContent(current, language, languageOptions, isEditor);
	}
	return retval ?? "";
};

const generateButtons = (elements, slug, queryString, language, languageOptions, isEditor) => {
	const buttonElements = elements.filter(e => e.name === "Link Button" || e.name === "button");
	const returnButtons = buttonElements.map(btn => {
		const link = btn?.actions?.["link"];
		const buttonInner = (
			<div className="listing-button">
				{parse(getDefaultOrAnyContent(btn, language, languageOptions, isEditor))}
				<IconArrowsRight size={27} />
			</div>
		);
		let buttonComponent;
		if (link?.type === "inner") {
			const innerLink = APP_PATHS.virtual + "/" + slug + "/" + link.link + queryString;
			buttonComponent = <NavLink to={innerLink}>{buttonInner}</NavLink>;
		} else if (link?.type === "outer") {
			buttonComponent = (
				<a target="_blank" rel="noreferrer" href={link.link}>
					{buttonInner}
				</a>
			);
		} else {
			buttonComponent = buttonInner;
		}
		return buttonComponent;
	});
	return returnButtons;
};

function ListingCardMulti(props) {
	const { elements, slug } = props;
	const { languages = ["en", "fi"], isEditor, setActiveItem } = useContext(SettingContext);
	const languageOptions = languages;
	const lang = useCurrentLanguage(isEditor);
	const queryString = useAccessKey();
	const buttonComponent = generateButtons(elements, slug, queryString, lang, languageOptions, isEditor);
	const dispatch = useDispatch();

	const handleDoubleClick = name => {
		if (isEditor) {
			const elem = elements.find(e => e.name === name);
			if (elem) {
				setActiveItem(elem);
				dispatch({ type: a.HANDLE_DEFAULT_WIDGET, payload: { name: elem.renderType, show: true } });
			}
		}
	};

	return (
		<div className="listing-card-multi-container">
			<div
				className="listing-card-multi-left"
				style={{
					backgroundImage: `url(${getElementContent(
						"Background",
						elements,
						lang,
						languageOptions,
						isEditor
					)})`
				}}
			>
				<h5 className="listing-multi-title" onDoubleClick={() => handleDoubleClick("Title")}>
					{parse(getElementContent("Title", elements, lang, languageOptions, isEditor))}
				</h5>
			</div>
			<div className="listing-card-multi-right">
				<p className="listing-multi-description" onDoubleClick={() => handleDoubleClick("Description")}>
					{parse(getElementContent("Description", elements, lang, languageOptions, isEditor))}
				</p>
				<div className="listing-multi-buttons">{buttonComponent}</div>
			</div>
		</div>
	);
}

export default ListingCardMulti;
