import errorCodes from './errorCodes';
import X, { errors } from './X';
import { uniqid, getAllFuncs, safetyFilterFunctionName } from './util';
import {
  SpookyOptions,
  RegisterOptions,
  RpcPacketInterface,
  CallTable,
  WaitingCallbacks,
  RpcPacketCallback,
  RpcPacket,
  RpcFunc,
  LogWarnFunc,
} from './types';

const RPC_DEFAULT_TIMEOUT = 10000;

export default class SpookyRPC {
  protected defaultTimeout = RPC_DEFAULT_TIMEOUT;
  protected sendRpcPacket: RpcPacketCallback;

  protected callTable: CallTable;
  protected waitingCalls: WaitingCallbacks;
  protected destroyed: boolean = false;
  protected logWarnFunc: LogWarnFunc;

  constructor(options: SpookyOptions) {
    if (!options) throw new Error('Missing options!');
    if (options.hasOwnProperty('defaultTimeout')) this.defaultTimeout = options.defaultTimeout;
    if (options.hasOwnProperty('sendRpcPacket')) this.sendRpcPacket = options.sendRpcPacket;
    if (options.hasOwnProperty('logWarnFunc')) this.logWarnFunc = options.logWarnFunc;
    if (!this.sendRpcPacket) throw new Error('Missing sendRpcPacket!');
    this.waitingCalls = {};
    this.callTable = {};
  }

  public destroy() {
    if (this.destroyed) return false;
    this.destroyed = true;
    this.sendRpcPacket = null;
    this.callTable = {};
    for (const o of Object.values(this.waitingCalls)) {
      o.reject(new X.ExecutionError({ message: 'SpookyRPC Destroyed' }));
    }
    this.waitingCalls = {};
    return true;
  }

  public register(funcName: string, func: RpcFunc, options?: RegisterOptions) {
    if (this.destroyed) throw new Error('Destroyed');
    if (safetyFilterFunctionName(funcName)) throw new Error('Illegal Method Name: ' + funcName);
    if ((!options || !options.replace) && this.callTable[funcName]) throw new Error('Duplicate RPC Call!');
    this.callTable[funcName] = {
      func,
      timeout: (options && options.timeout) || this.defaultTimeout,
    };
  }

  private logWarn(...args) {
    if (this.logWarnFunc) {
      try {
        this.logWarnFunc(...args);
      } catch (err) {}
    }
  }

  public expose(obj: any, options?: RegisterOptions) {
    if (this.destroyed) throw new Error('Destroyed');
    if (!obj) throw new Error('Invalid object');
    const funcs = getAllFuncs(obj);
    for (const func of funcs) {
      this.register(func, obj[func].bind(obj), options);
    }
  }

  public getTimeoutForFunc(funcName: string) {
    return (this.callTable[funcName] && this.callTable[funcName].timeout) || this.defaultTimeout || RPC_DEFAULT_TIMEOUT;
  }

  public setTimeoutForFunc(funcName: string, timeout: number) {
    if (this.destroyed) throw new Error('Destroyed');
    if (!this.callTable[funcName]) this.callTable[funcName] = {};
    this.callTable[funcName].timeout = timeout;
  }

  public async invoke(method: string, ...params: any[]) {
    if (this.destroyed) throw new Error('Destroyed');
    if (!this.sendRpcPacket) throw new Error('No sendRpcPacket defined, cannot invoke.');
    const newCallbackID = uniqid('rpc-');
    return new Promise(async (trueResolve: (v: any) => void, trueReject: (v: Error) => void) => {
      const timeoutMs = this.getTimeoutForFunc(method);

      const cleanup = (): boolean|void => {
        if (this.waitingCalls[newCallbackID]) {
          if (this.waitingCalls[newCallbackID].timeoutHolder) {
            clearTimeout(this.waitingCalls[newCallbackID].timeoutHolder);
          }
          delete this.waitingCalls[newCallbackID];
          return true;
        }
      };

      const resolve = (v: any) => {
        if (cleanup()) trueResolve(v);
      };

      const reject = (e: Error) => {
        if (cleanup()) trueReject(e);
      };

      const timeout = () => {
        if (this.waitingCalls[newCallbackID]) {
          this.waitingCalls[newCallbackID].timeoutHolder = null;
        }
        reject(new X.RequestTimout());
      };

      this.waitingCalls[newCallbackID] = {
        resolve, reject, timeoutHolder: setTimeout(timeout, timeoutMs),
      };

      try {
        await this.sendPacketInternal(this.buildPacket({
          method, params, id: newCallbackID,
        }));
      } catch (err) {
        reject(new X.InternalError(err));
      }
    });
  }

  // Notify does not send an ID, does not want or need a reply / error, so no handling needed
  public async notify(method: string, ...params: any[]) {
    if (this.destroyed) throw new Error('Destroyed');
    if (!this.sendRpcPacket) throw new Error('No sendRpcPacket defined, cannot notify.');
    try {
      await this.sendPacketInternal(this.buildPacket({
        method, params,
      }));
    } catch (err) {
      throw new X.InternalError(err);
    }
  }

  protected async sendPacketInternal(packet: RpcPacket) {
    if (this.destroyed) throw new Error('Destroyed'); // Skip sending things when we are dead.
    await this.sendRpcPacket(packet);
  }

  public async receiveRpcPacket(packet: RpcPacket) {
    try {
      if (!packet) return;
      if (this.destroyed) throw new Error('Destroyed');
      if (typeof packet === 'string') {
        await this.receiveRpcPacket(JSON.parse(packet));
      } else if (Array.isArray(packet)) {
        await this.handleRpcBatch(packet);
      } else if (packet.jsonrpc !== '2.0') {
        this.logWarn('Bad RPC Packet, missing jsonrpc version or mismatch:', packet);
      } else if (packet.method) {
        await this.handleRpcRequest(packet);
      } else if (packet.error) {
        this.handleRpcError(packet);
      } else if (packet.result || packet.id) { // If no result, assume is notification of success
        this.handleRpcAnswer(packet);
      } else {
        this.logWarn('Unhandled RPC Packet:', packet);
      }
    } catch (err) {
      this.logWarn('receiveRpcPacket', err.message);
    }
  }

  protected async handleRpcBatch(batch: RpcPacket[]) {
    // Handle array batches in Core RPC because it's cheap and compatible. We just don't send out batches in core.
    const promises = [];
    for (const packet of batch) {
      promises.push(this.receiveRpcPacket(packet));
      // promises.push(immediate().then(this.receiveRpcPacket.bind(this, packet)));
    }
    return await Promise.all(promises);
  }

  protected async handleRpcRequest(packet: RpcPacketInterface) {
    try {
      const entry = this.callTable[packet.method];

      // Check against actual func existing, entry might exist for timeout alone
      if (( safetyFilterFunctionName(packet.method) || ( !entry || !entry.func ) ) && packet.id) {
        await this.sendPacketInternal(this.buildPacket({
          id: packet.id, error: {
            code: errorCodes.METHOD_NOT_FOUND,
            message: 'Method not found',
            data: null
          }
        }));
        return;
      }

      const result = await entry.func(...(packet.params || []));
      if (packet.id) {  // if no ID, is notification, no reply needed
        await this.sendPacketInternal(this.buildPacket({
          id: packet.id, result: result,
        }));
      }
    } catch (err) {
      // TODO: make sure error handling and feedback is nice / similar to mole (then again mole was weird too)?
      if (packet.id) {
        await this.sendPacketInternal(this.buildPacket({
          id: packet.id, error: {
            code: errorCodes.EXECUTION_ERROR,
            message: err.message,
            data: err.toJSON(),
          }
        }));
      }
    }
  }

  protected handleRpcAnswer(packet: RpcPacketInterface) {
    const waiting = this.waitingCalls[packet.id];
    if (!waiting) throw new X.UnknownID(packet.id);
    waiting.resolve(packet.result);
  }

  protected handleRpcError(packet: RpcPacketInterface) {
    const waiting = this.waitingCalls[packet.id];
    if (!waiting) throw new X.UnknownID(packet.id);
    const errorObject = this._makeErrorObject(packet.error);
    waiting.reject(errorObject);
  }

  protected _makeErrorObject(errorData: any) {
    const errorBuilder = errors[errorData.code];
    return errorBuilder ? errorBuilder(errorData) : new Error(`${errorData.code} ${errorData.message}`);
  }

  protected buildPacket(packet: Partial<RpcPacketInterface>): RpcPacketInterface {
    return { ...packet, jsonrpc: '2.0' };
  }
}