import { useState } from 'react';
import { parseImageLetter } from './message';
import * as a from 'actiontypes';
import { connect } from 'react-redux';
import { Localized } from "@fluent/react";
import { useFetchEventParticipants } from 'containers/VirtualEvent/apiCalls';
import { IconChevronRight } from 'components/icons';
import { IconChevronDown } from 'components/icons';
import { defineTextColor } from './helpers/defineColor';
import { useFilterAttendees } from './helpers/hooks';
import { CustomOverlay } from 'components/ToolTip/Tooltip';

const mapStateToProps = state => ({
    websocketUser: state.websocketUser,
    user_groups: state.lobby.user_groups,
    displayType: state.displayType,
    allAttendees: state.all_attendees
})

const mapDispatchToProps = dispatch => ({
    toggleProfile: payload => dispatch({ type: a.WS_TOGGLE_PROFILE, payload: payload }),
    changeView: view => dispatch({ type: a.WS_CHANGE_VIEW, payload: view }),
})

function AttendeesContent(props) {
    const { websocketUser, user_groups, displayType, allAttendees } = props;
    const [expanded, setExpanded] = useState(false)

    const [filter, setFilter] = useState('');
    const [filterTags, setFilterTags] = useState([])

    useFetchEventParticipants()

    let attendeesList = useFilterAttendees(allAttendees, filter, filterTags)
        .map(a => (<Attendee key={a.user_id} {...a} my_user_id={websocketUser.user_id} toggleProfile={props.toggleProfile} changeView={props.changeView} groups={user_groups.filter(u => a.groups?.includes(u.slug))} />))

    const handleFilterByTag = (tag) => {
        if (filterTags.length > 0 && filterTags.includes(tag)) {
            const updatedTags = filterTags
            setFilterTags(updatedTags.filter(t => t !== tag))
        } else {
            setFilterTags([...filterTags, tag])
        }
    }

    return <div className={`attendees-content ${displayType}`}>

        <div className="attendees-filter">

            <button className="attendees-filter-button" onClick={() => setExpanded(!expanded)}><Localized id="attendees-filter-button-text">Filter attendees</Localized>{' '} {!expanded ? <IconChevronRight /> : <IconChevronDown />}</button>


            <div className={`attendees-find-container  ${expanded ? 'expanded' : ''}`}>
                {(user_groups && user_groups.length > 0) &&
                    <div className="filter-group">
                        <Localized id="attendees-search-user-groups"><label className="attendees-find-label">By user group</label></Localized>

                        <div className="filter-by-user-group-container">
                            {user_groups.map(userGroup =>
                                <div
                                    className={`filter-user-group-tag ${filterTags.includes(userGroup.slug) ? 'selected' : ''}`}
                                    onClick={() => handleFilterByTag(userGroup.slug)}
                                    style={{ "--filter-tag-bg": userGroup.color, "--filter-tag-color": defineTextColor(userGroup.color) }}>
                                    {userGroup.name}
                                </div>
                            )}
                        </div>
                    </div>}


                <div className="filter-group">
                    <Localized id="attendees-search">
                        <label className="attendees-find-label">Filter by name, company or title</label>
                    </Localized>

                    <Localized id="attendees-search" attrs={{ placeholder: true }}>
                        <input className="chat-filter-input" autoComplete="off" placeholder="search for an attendee.." onChange={e => setFilter(e.target.value)} value={filter} />
                    </Localized>
                </div>


            </div>
        </div>


        {attendeesList.length > 0 ?
            attendeesList :
            <div className="attendees-no-search-results">
                <Localized id="attendees-no-search-results">No attendees found with given filters</Localized>
            </div>
        }
    </div>
}

const Attendee = (props) => {
    const { icon, first_name, last_name, company_name, title, my_user_id, username, type, isOnline, groups=[] } = props;
    const name = username ? username : `${first_name} ${last_name}`

    function onClick() {
        const my_profile = my_user_id === props.user_id;
        if (!my_profile && type === 'authenticated') {
            props.toggleProfile(props);
            props.changeView('inbox')
        }
    }

    const generateUserGroupComponent = () => {
        return <>
            {groups.map(group =>
                <div className="group-tag" style={{ "--tag-background": group.color, "--tag-color": defineTextColor(group.color) }}>{group.name}</div>
            )}
        </>
    }

    const generateUserGroups = () => {
        const first = groups.shift()
        const theRestLength = groups.length
        return <div className="attendee-user-group-tags">
            <div className="group-tag" style={{ "--tag-background": first.color, "--tag-color": defineTextColor(first.color) }}>{first.name}</div>
            {groups.length > 0 &&
                <CustomOverlay customClass="attendee-usergroups-popover" show="50" hide="200" text={generateUserGroupComponent()} orientation="bottom">
                    <div onClick={e => e.stopPropagation()} className="group-tag" style={{ "--tag-background": "#000000", "--tag-color": defineTextColor("#000000") }}>+{theRestLength}</div>
                </CustomOverlay>}
        </div>
    }

    return <div className={`attendee ${groups.length > 0 ? 'has-groups' : ''}`} onClick={() => onClick()}>
        <div className="attendee-icon-wrapper">
            <div className={`attendee-icon ${isOnline ? 'online' : 'offline'}`} style={{ backgroundImage: `url("${icon}")` }}>
                {parseImageLetter(icon, name)}
            </div>
            <div className={`online-offline-icon ${isOnline ? 'online' : 'offline'}`} />
        </div>

        <div className="attendee-info">
            <div className="attendee-name">{name}</div>
            <div className="attendee-company">{company_name}</div>
            <div className="attendee-title">{title}</div>
        </div>

        {groups.length > 0 && generateUserGroups()}

    </div>
}

export default connect(mapStateToProps, mapDispatchToProps)(AttendeesContent);