/** @format */

import { Localized, useLocalization } from "@fluent/react";
import { chatServiceInvoke, chatServiceNotify } from "middlewares/websocket";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkLists, getLocString } from "./helpers";
import Select from "react-select";

export function useChangeModerationSettings(initSettings, setSettings, setModSettings) {
	const { global, chatroom } = initSettings;
	const [modifiedGlobal, setModifiedGlobal] = useState({});
	const [modifiedChatroom, setModifiedChatroom] = useState({});
	const isGlobal = Object.keys(modifiedGlobal).length > 0; // Global settings modified
	const isRoom = Object.keys(modifiedChatroom).length > 0; // Chat room settings modified
	const [inProgress, setInProgress] = useState(false);
	// Submit button is disabled if there are no changes to the initial value or the request is in progress
	const disabled = (!isGlobal && !isRoom) || inProgress;
	const [status, setStatus] = useState(null);
	const [error, setError] = useState(null);
	const event_slug = useSelector(state => state.event.slug);
	const handleStatus = val => {
		setStatus(val);
		setTimeout(() => {
			setStatus(null);
		}, 5000);
	};

	// Modifying initial data into new objects
	const modify = (type, key, value) => {
		if (type === "global") {
			let cond;
			if (Array.isArray(value)) {
				cond = checkLists(global[key], value);
			} else {
				cond = global[key] === value;
			}
			if (!cond) {
				setModifiedGlobal(prev => {
					return { ...prev, [key]: value };
				});
			} else {
				const { [key]: value, ...newObj } = modifiedGlobal;
				setModifiedGlobal(newObj);
			}
		} else {
			if (chatroom[key] !== value) {
				setModifiedChatroom(prev => {
					return { ...prev, [key]: value };
				});
			} else {
				const { [key]: value, ...newObj } = modifiedChatroom;
				setModifiedChatroom(newObj);
			}
		}
	};
	// Submitting changes
	const submitSettings = async () => {
		try {
			setInProgress(true);
			if (isGlobal) {
				const payload = {
					event_slug,
					changes: modifiedGlobal,
				};
				const response = await chatServiceInvoke("modChangeEventSetting", payload);
				setSettings(response);
				setModifiedGlobal([]);
			}
			if (isRoom) {
				const payload = {
					room_id: chatroom.room_id,
					changes: modifiedChatroom,
				};
				const response = await chatServiceInvoke("modChangeRoomSetting", payload);
				await chatServiceNotify(
					JSON.stringify({
						type: "heartbeat",
						payload: { room_id: chatroom.room_id, restricted: response.restricted },
					})
				);
				setModSettings(response);
				setModifiedChatroom([]);
			}
			handleStatus("success");
		} catch (err) {
			handleStatus("failure");
			setError(err);
		} finally {
			setInProgress(false);
		}
	};
	const submitButton = (
		<button className="submit-setting-change generic-liveto accept" disabled={disabled} onClick={submitSettings}>
			<Localized id="error-session-warning-save-button">Save</Localized>
		</button>
	);
	return { submitButton, inProgress, modify, error, status };
}

export function useCreateSettings(settings, globalSettings, modify) {
	const [cmps, setCmps] = useState(null);
	useEffect(() => {
		if (settings || globalSettings) {
			const components = {
				allow_anonymous: {
					label: <Localized id="moderation-setting-label-allow_anonymous" />,
					roomValue: !settings ? null : (
						<InheritTypeSetting
							settingKey="allow_anonymous"
							val={settings["allow_anonymous"]}
							type="chatroom"
							modify={modify}
						/>
					),
					globalValue: (
						<InheritTypeSetting
							settingKey="allow_anonymous"
							val={globalSettings["allow_anonymous"]}
							type="global"
							modify={modify}
						/>
					),
					description: (
						<Localized id="moderation-setting-description-allow_anonymous">
							Chat users can send messages anonymously
						</Localized>
					),
				},
				restricted: {
					label: <Localized id="moderation-setting-label-restricted" />,
					roomValue: !settings ? null : (
						<InheritTypeSetting
							settingKey="restricted"
							val={settings["restricted"]}
							type="chatroom"
							modify={modify}
						/>
					),
					globalValue: (
						<InheritTypeSetting
							settingKey="restricted"
							val={globalSettings["restricted"]}
							type="global"
							modify={modify}
						/>
					),
					description: (
						<Localized id="moderation-setting-description-restricted">
							Chat room can only be used by moderators
						</Localized>
					),
				},
				hold_all_messages: {
					label: <Localized id="moderation-setting-label-hold_all_messages" />,
					roomValue: !settings ? null : (
						<InheritTypeSetting
							settingKey="hold_all_messages"
							val={settings["hold_all_messages"]}
							type="chatroom"
							modify={modify}
						/>
					),
					globalValue: (
						<InheritTypeSetting
							settingKey="hold_all_messages"
							val={globalSettings["hold_all_messages"]}
							type="global"
							modify={modify}
						/>
					),
					description: (
						<Localized id="moderation-setting-description-hold_all_messages">
							All messages will be held for review (other "holding" settings will be overwritten)
						</Localized>
					),
				},
				hold_all_guest_messages: {
					label: <Localized id="moderation-setting-label-hold_all_guest_messages" />,
					roomValue: !settings ? null : (
						<InheritTypeSetting
							settingKey="hold_all_guest_messages"
							val={settings["hold_all_guest_messages"]}
							type="chatroom"
							modify={modify}
						/>
					),
					globalValue: (
						<InheritTypeSetting
							settingKey="hold_all_guest_messages"
							val={globalSettings["hold_all_guest_messages"]}
							type="global"
							modify={modify}
						/>
					),
					description: (
						<Localized id="moderation-setting-description-hold_all_guest_messages">
							All messages sent by guestusers will be held for review
						</Localized>
					),
				},
				hold_by_blocklisted_user: {
					label: <Localized id="moderation-setting-label-hold_by_blocklisted_user" />,
					roomValue: !settings ? null : (
						<InheritTypeSetting
							settingKey="hold_by_blocklisted_user"
							val={settings["hold_by_blocklisted_user"]}
							type="chatroom"
							modify={modify}
						/>
					),
					globalValue: (
						<InheritTypeSetting
							settingKey="hold_by_blocklisted_user"
							val={globalSettings["hold_by_blocklisted_user"]}
							type="global"
							modify={modify}
						/>
					),
					description: (
						<Localized id="moderation-setting-description-hold_by_blocklisted_user">
							All messages sent by blocklisted users will be held for review
						</Localized>
					),
				},
				hold_by_blocklisted_word: {
					label: <Localized id="moderation-setting-label-hold_by_blocklisted_word" />,
					roomValue: !settings ? null : (
						<InheritTypeSetting
							settingKey="hold_by_blocklisted_word"
							val={settings["hold_by_blocklisted_word"]}
							type="chatroom"
							modify={modify}
						/>
					),
					globalValue: (
						<InheritTypeSetting
							settingKey="hold_by_blocklisted_word"
							val={globalSettings["hold_by_blocklisted_word"]}
							type="global"
							modify={modify}
						/>
					),
					description: (
						<Localized id="moderation-setting-description-hold_by_blocklisted_word">
							All messages that include a blocklisted word will be held for review
						</Localized>
					),
				},
				slowmode: {
					label: <Localized id="moderation-setting-label-slowmode" />,
					roomValue: !settings ? null : (
						<NumberTypeSetting
							settingKey="slowmode"
							val={settings["slowmode"]}
							type="chatroom"
							modify={modify}
						/>
					),
					globalValue: (
						<NumberTypeSetting
							settingKey="slowmode"
							val={globalSettings["slowmode"]}
							type="global"
							modify={modify}
						/>
					),
					description: (
						<Localized id="moderation-setting-description-slowmode">
							Amount of time between chat messages by a single user (in seconds)
						</Localized>
					),
				},
			};
			setCmps(components);
		}
	}, [settings, globalSettings, modify]);
	return cmps;
}

const InheritTypeSetting = props => {
	const { val, type, modify, settingKey } = props;
	const { l10n } = useLocalization();

	const defaultValue = value => {
		switch (value) {
			case true:
				return { label: getLocString(l10n, "moderation-setting-on-label"), value: true };
			case false:
				return { label: getLocString(l10n, "moderation-setting-off-label"), value: false };
			case null:
				return { label: getLocString(l10n, "moderation-setting-inherit-label"), value: null };
			default:
				break;
		}
	};

	const [selectorValue, setSelectorValue] = useState(defaultValue(val));

	useEffect(() => {
		setSelectorValue(defaultValue(val));
	}, [val]);

	const handleChange = value => {
		setSelectorValue(value);
		modify(type, settingKey, value.value);
	};
	let options = [
		{ label: getLocString(l10n, "moderation-setting-on-label"), value: true },
		{ label: getLocString(l10n, "moderation-setting-off-label"), value: false },
	];
	options =
		type === "chatroom"
			? [...options, { label: getLocString(l10n, "moderation-setting-inherit-label"), value: null }]
			: options;
	return (
		<Select
			value={selectorValue}
			options={options}
			isMulti={false}
			className="mod-setting-selector"
			onChange={value => handleChange(value)}
		/>
	);
};

const NumberTypeSetting = props => {
	const { val, type, settingKey, modify } = props;
	const [changedValue, setChangedValue] = useState(val === null ? "" : val);
	const pHolder = val === null || val === "" ? "moderation-setting-inherit-label" : "";
	useEffect(() => {
		setChangedValue(val === null ? "" : val);
	}, [val]);
	const handleChange = value => {
		if (value < 0) return;
		setChangedValue(value);
		const sendVal = !value ? null : value;
		modify(type, settingKey, sendVal);
	};
	return (
		<Localized id={pHolder} attrs={{ placeholder: true }}>
			<input
				type="number"
				className="chat-filter-input moderation-input"
				value={changedValue}
				onChange={e => handleChange(e.target.value)}
			/>
		</Localized>
	);
};
