import moment from 'moment';

export const sameDay = (start, end) => {
    return moment(start).format('DD.MM.YYYY') === moment(end).format('DD.MM.YYYY')
}

export const formatDate = (date, onlyTime) => {
    if (onlyTime) {
        return moment(date).format('HH.mm');
    }
    return moment(date).format('DD.MM.YYYY HH.mm');
}

export const formatDateToTime = (date) => {
    return moment(date).format('HH.mm');
}

export const formatToDate = (date) => {
    return moment(date).format('DD.MM.YYYY')
}

export const getLocaleTime = (timestring, format) => {
    let time = moment(timestring, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss');
    return moment.utc(time).local().format(format)
}

export const compareDates = (date1, date2) => {
    let time1 = moment(date1, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
    let localizedTime1 = moment.utc(time1).local().format('YYYY-MM-DD');

    let time2 = moment(date2, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
    let localizedTime2 = moment.utc(time2).local().format('YYYY-MM-DD');

    return moment(localizedTime1).isSame(localizedTime2, 'day');
}

export const checkIfPollHasStarted = (date) => {
    // Returns true if current time in UTC is past the parameter time (UTC)
    // If date equals null, means that there is no time to compare, and assumes that the time is past the current time
    if (!date || moment().utc().isAfter(date)) {
        return true;
    }
    return false;

}

export const checkIfPollHasEnded = (date) => {
    // Poll can be determined as "Ended", if the date is past current time (UTC), otherwise poll is still active
    if (date && moment(date).isBefore(moment().utc())) {
        return true;
    }
    return false;
}

export const getTimeStamp = (addSeconds) => {
    let time = moment().utc().local().add(addSeconds, 'seconds');
    return time;
}

export const showTimeAsString = (time) => {
    return moment(time).utc().local().format('DD.MM.YYYY HH:mm')
}

export const sortByDate = (list = [], key) => {
    if (key) return list.sort((a,b) => new Date(a[key]) - new Date(b[key]))
    return list.sort((a,b) => new Date(a) - new Date(b))
}