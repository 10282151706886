import EventEmitter from './eventEmitter';

const WS_MAX_RECONNECT_TIMEOUT = 10000;
const WS_ATTEMPTS_TILL_MAX = 20;

class WsConnector extends EventEmitter {
  private ws: WebSocket = undefined;
  // private slug = '/signalling';
  private timeoutHolder: any;
  private failureCounter: number = 0;
  private signallingURLOverride: string;

  constructor() {
    super(null);
  }

  private log(...args) {
    console.log('[WsConnector]', ...args);
  }

  private warn(...args) {
    console.warn('[WsConnector]', ...args);
  }

  public setSignallingURLOverride(url: string) {
    this.signallingURLOverride = url;
  }

  public getWS() {
    return this.ws;
  }

  public get ready() {
    if (this.ws) {
      if (this.ws.readyState === WebSocket.OPEN) {
        return true;
      }
    }
    return false;
  }

  public async waitForReady() {
    return new Promise<void>((resolve, reject) => {
      try {
        if (this.ready) return resolve();
        this.once('open', resolve); // Eventually we should be able to connect.
        if (!this.ws) this.connect();
      } catch(err) {
        reject(err);
      }
    });
  }

  public send(data) {
    if (!this.ready) throw new Error('Websocket not ready');
    this.ws.send(data);
    this.emit('sent', data);
  }

  public connect() {
    if (this.ready) return this.warn('connect called while ready, aborted');
    // const url = this.signallingURLOverride ? (this.signallingURLOverride + '/' + this.slug) : (window.location.origin.replace('http', 'ws') + this.slug);
    const url = this.signallingURLOverride ? this.signallingURLOverride : window.location.origin.replace('http', 'ws');
    this.ws = new WebSocket(url);
    this.ws.onopen = this.onOpen.bind(this);
    this.ws.onclose = this.onClose.bind(this);
    this.ws.onerror = this.onError.bind(this);
    this.ws.onmessage = this.onMessage.bind(this);
  }

  private onOpen(ev) {
    // this.log('onOpen', ev);
    this.emit('open', ev);
    this.failureCounter = 0;
  }

  private onClose(ev) {
    // this.log('onClose', ev);
    this.emit('close', ev);
    this.setReconnectTimeout();
    this.failureCounter++;
  }

  private onError(ev) {
    this.warn('OnError', ev);
    this.emit('error', ev);
  }

  private onMessage(ev) {
    // this.log('onMessage', ev.data);
    this.emit('message', ev.data);
  }

  private setReconnectTimeout() {
    this.clearReconnectTimeout();
    this.timeoutHolder = setTimeout(this.connect.bind(this), this.reconnectTimeoutMs);
    this.log('Set reconnect timeout', this.reconnectTimeoutMs);
  }
  
  private clearReconnectTimeout() {
    if (this.timeoutHolder) clearTimeout(this.timeoutHolder);
    this.timeoutHolder = null;
  }

  private get reconnectTimeoutMs() {
    const calculated = (WS_MAX_RECONNECT_TIMEOUT / WS_ATTEMPTS_TILL_MAX) * this.failureCounter;
    return Math.min(WS_MAX_RECONNECT_TIMEOUT, calculated);
  }

  public reconnect() {
    this.failureCounter = 1;
    if (this.ready) {
      this.ws.close();
    } else {
      this.setReconnectTimeout();
    }
  }
}

export default new WsConnector();