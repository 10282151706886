import React, { useContext, useEffect, useRef, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { SettingContext } from '../contexts';
import MenuWrapper from '../wrappers/menuWrapper';
import CustomCarousel from './components/carousel';
import ListingCard from './components/listingCard';
import ListingCardMulti from './components/listingCardMulti';
import Stream from '../elementLibrary/elements/stream';
import JitsiMeet from '../elementLibrary/elements/jitsi';
import Text from '../elementLibrary/elements/text';
import ImageElement from '../elementLibrary/elements/image';
import ButtonElement from '../elementLibrary/elements/button';
import PollElement from '../elementLibrary/elements/poll';
import Embed from '../elementLibrary/elements/embed';
import Slide from '../elementLibrary/elements/slide';
import Video from '../elementLibrary/elements/video';
import Separator from '../elementLibrary/elements/separator';
import { COMPONENT_CONFIGS, COMPONENT_DEFAULT_STYLE, DEFAULT_ACTIONS } from '../constants';
import { APP_PATHS } from 'constants.js';
import { useAccessKey } from '../editorView/apiCalls';
import SimpleCard from './components/SimpleCard';
import { IconTimes } from 'components/icons';
import { GenericTooltip } from 'components/ToolTip/Tooltip';
import { useDispatch } from 'react-redux';
import * as a from 'actiontypes'
import { useSelector } from 'react-redux';

function renderElement(elem, slug, editorMode) {
    switch (elem.renderType) {
        case 'text': return <Text key={'element' + elem.i} elem={elem} editorMode={editorMode} />;
        case 'slide': return <Slide key={'element' + elem.i} elem={elem} />;
        case 'image': return <ImageElement key={'element' + elem.i} elem={elem} />;
        case 'button': return <ButtonElement key={'element' + elem.i} elem={elem} editorMode={editorMode} />;
        case 'embed': return <Embed key={'element' + elem.i} elem={elem} editorMode={editorMode} />;
        case 'stream': return <Stream key={'element' + elem.i} elem={elem} slug={slug} editorMode={editorMode} />;
        case 'video': return <Video key={'element' + elem.i} elem={elem} slug={slug} editorMode={editorMode} />;
        case 'meet': return <JitsiMeet key={'element' + elem.i} elem={elem} />;
        case 'separator': return <Separator key={'element' + elem.i} elem={elem} />;
        case 'poll': return <PollElement key={'element' + elem.i} elem={elem} editorMode={editorMode} />;
        default: break;
    }
}

const BLACKLISTED_KEYS = ['overlay'];
export const parseComponentStyle = (properties, cmpPreset) => {
    const preset = cmpPreset === "carousel" ? "gallery" : cmpPreset
    const { defaultStyles } = COMPONENT_CONFIGS[preset].component
    const componentStyles = defaultStyles ? { ...COMPONENT_DEFAULT_STYLE, ...defaultStyles } : COMPONENT_DEFAULT_STYLE
    const styles = {};
    for (let key in componentStyles) {
        if (properties[key]) {
            if (!BLACKLISTED_KEYS.includes(key)) {
                if (key === 'border') {
                    styles['border'] = properties['border']['border'];
                    styles['borderRadius'] = properties['border']['borderRadius']
                } else if (key === 'background') {
                    styles['backgroundImage'] = properties['background']['image'];
                    styles['backgroundSize'] = properties['background']['size'];
                    styles['backgroundPosition'] = properties['background']['position'];
                    styles['backgroundColor'] = properties['background']['color'];
                    styles['backgroundRepeat'] = properties['background']['repeat'];
                } else {
                    styles[key] = properties[key]
                }
            }
        } else {
            styles[key] = componentStyles[key]
        }
    }
    return styles
}

function ComponentRenderer(props) {
    const editorMode = props.match.path.includes('/editor') ? true : false;
    const { activeItem, setActiveItem, isEditor } = useContext(SettingContext);
    const componentRef = useRef(null)
    const dispatch = useDispatch()
    const activeRef = useSelector(state => state.editorProgress.activeComponentRef.active)

    const { slug } = props.match.params;

    const {
        cmp,
        editorDispatch,
        view,
        editLocks,
        components,
        room,
        grid,
        isPreview
    } = props;

    const { actions, properties } = cmp;
    const queryString = useAccessKey();

    useEffect(() => {
        if (isEditor) dispatch({ type: a.POPULATE_COMPONENT_REFS, payload: { cmp: cmp.i, ref: componentRef } })
    }, [cmp])

    // For scrolling to the new component
    useEffect(() => {
        if (isEditor && activeItem && activeRef !== componentRef && !activeItem.isRoom) dispatch({ type: a.SET_ACTIVE_COMPONENT_REF, payload: activeItem.i })
    }, [activeItem])

    // Overlay
    const hasOverlay = properties['overlay'] ? true : false;
    let overlayStyles = { pointerEvents: 'none' };
    let overlay = null;
    if (hasOverlay) {
        const legacyOverlay = typeof (properties['overlay']) !== 'object';
        if (legacyOverlay) {
            overlayStyles['background'] = properties['overlay'];
        } else {
            overlayStyles['background'] = properties['overlay']['color'];
            overlayStyles['mixBlendMode'] = properties['overlay']['blendMode'];
        }

        if (properties['border'] && properties['border']['borderRadius']) {
            overlayStyles['borderRadius'] = properties['border']['borderRadius']
        };
        overlay = <div className="virtual-event-component-overlay" style={overlayStyles}></div>;
    }

    // Custom component handling
    let component = null;
    switch (cmp.preset) {
        case 'carousel': component = <CustomCarousel {...cmp} />; break;
        case 'simple card': component = <SimpleCard {...cmp} slug={slug} />; break;
        case 'listing card': component = <ListingCard {...cmp} slug={slug} />; break;
        case 'listing card multi': component = <ListingCardMulti {...cmp} slug={slug} />; break;
        default: component = cmp.elements.map(elem => renderElement(elem, slug, editorMode)); break;
    }

    // Actual virtual event
    if (!editorMode || isPreview) {
        const linking = actions ? actions['link'] : DEFAULT_ACTIONS.link;
        const styles = { ...parseComponentStyle(properties, cmp.preset), gridArea: grid };
        const uniqueClass = `${cmp.parentId}-${view}-${cmp.i}`
        // Links 
        switch (linking.type) {
            case 'inner': {
                const link = APP_PATHS.virtual + '/' + slug + '/' + linking.link + queryString;
                return <NavLink to={link} style={styles} className={'virtual-event-component ' + uniqueClass}>
                    {hasOverlay && overlay}
                    {component}
                </NavLink>
            }

            case 'outer': return <a href={linking.link} target="_blank" rel="noreferrer" style={styles} className={'virtual-event-component ' + uniqueClass}>
                {hasOverlay && overlay}
                {component}
            </a>


            default: return <div key={cmp.i} style={styles} className={'virtual-event-component ' + uniqueClass}>
                {hasOverlay && overlay}
                {component}
            </div>;
        }


    }

    // Editor view components
    const activeId = activeItem ? activeItem.i || activeItem.id : null;
    const isCurrentActive = activeItem && (cmp.i === activeId || activeId.includes(cmp.i));
    const hilight = isCurrentActive ? ' hilight' : '';
    const editor = isEditor ? ' active' : '';
    const classes = `virtual-event-component${editor}${hilight}`

    const menuProps = { cmp, editorDispatch, editLocks, room, view, components, setActiveItem, dispatch }

    function select(e) {
        if (isCurrentActive) {
            e.preventDefault();
        } else {
            dispatch({ type: a.SET_ACTIVE_COMPONENT_REF, payload: cmp.i })
            setActiveItem(cmp)
        }
    }
    return <MenuWrapper {...menuProps}>
        {(!isCurrentActive && activeItem && !activeItem.isRoom) && <div className="virtual-editor-component-inactive" onDoubleClick={select} />}
        <div key={cmp.i} ref={componentRef} style={parseComponentStyle(properties, cmp.preset)} onDoubleClick={select} className={classes}>
            {isCurrentActive &&
                <GenericTooltip text="Deselect component" hide="100">
                    <button
                        className="active-component-deselect-button"
                        onClick={() => setActiveItem(null)}
                    >
                        <IconTimes />
                    </button>
                </GenericTooltip>}
            {hasOverlay && overlay}
            {component}
        </div>
    </MenuWrapper>
}

export default withRouter(ComponentRenderer);