import { Localized } from "@fluent/react"
import { IconClock } from "components/icons";
import { IconUser } from "components/icons";
import moment from "moment"
import { useEffect, useState } from "react"
import { connect, useSelector } from "react-redux"
import { NavLink } from 'react-router-dom';
import * as a from 'actiontypes'

const mapStateToProps = state => ({
    event_slug: state.event.slug,
    isGuestuser: state.user.guestuser,
    inbox: state.inbox,
})

const mapDispatchToProps = dispatch => ({
    closeModals: () => dispatch({ type: a.HANDLE_MODALS, payload: true }),
    changeView: target => dispatch({ type: a.WS_CHANGE_VIEW, payload: target }),
    toggleProfile: payload => dispatch({ type: a.WS_TOGGLE_PROFILE, payload: payload }),
    viewInvite: () => dispatch({ type: a.HANDLE_NOTIFICATION_CENTER, payload: true }),
    showChat: () => dispatch({ type: 'HANDLE_SHOW_CHAT', payload: true }),
})

const generateInfoTypeNotification = (data, localizedText, title, handleViewTheInvite) => {
    const { person, startDateTime, endTime, invite } = data

    return <>
        {title}
        <div className="user-time-wrapper">
            <div className="user-wrapper">
                <IconUser className="push-notification-icon" />
                {`${person.first_name} ${person.last_name}`}
            </div>

            <div className="time-wrapper">
                <IconClock className="push-notification-icon" />
                <div className="notification-slot-time">{`${startDateTime} - ${endTime}`}</div>
            </div>

            {invite && invite.status === 'pending'
                &&
                <Localized id='push-notification-view-the-invite-button'>
                    <button className="proceed-to-profile-button proceed" onClick={(e) => handleViewTheInvite(e, invite)}>View the invite</button>
                </Localized>}
        </div>
    </>
}

export const NotificationType = connect(mapStateToProps, mapDispatchToProps)(({ alert, calendarSlots, showChat, event_slug, accessKeyString, closeModals, toggleProfile, changeView, viewInvite, isGuestuser, inbox }) => {
    const [activeAlert, setActiveAlert] = useState("")
    const attendees = useSelector(state => state.all_attendees)

    const handleProceedToProfile = (e, user_id) => {
        const attendee = attendees.find(a => a.chat_user_id === user_id || a.user_id === user_id)
        toggleProfile(attendee)
        changeView('inbox')
        closeModals()
    }

    const handleViewTheInvite = () => {
        closeModals()
        viewInvite()
    }

    useEffect(() => {
        switch (alert.type) {
            case 'OUTGOING_INVITE_RESPONSE': {
                const slot = alert.data.sent_invites ? alert.data : alert.data.slot
                const person = alert.data.sent_invites ? alert.data.sent_invites[0].user : alert.data.user
                const startDateTime = moment(slot.start_time).format('DD.MM.YYYY HH:mm')
                const endTime = moment(slot.end_time).format('HH:mm') //{'{$slotAmount} timeslots'}
                const slot_name = slot.name
                const alertData = { person, startDateTime, endTime, slot_name }
                if (alert.data.status === 'accepted') {
                    setActiveAlert(
                        generateInfoTypeNotification(alertData,
                            <Localized id='outgoing-invite-response-accepted' vars={{ slot_name: slot_name }}>
                                {'An invite "{$slot_name}" has been accepted'}
                            </Localized>,
                            <Localized id='push-notification-invite-accepted-header'><b><p>Invite accepted</p></b></Localized>
                        )
                    )
                } else if (alert.data.status === 'rejected') {
                    setActiveAlert(
                        generateInfoTypeNotification(alertData,
                            <Localized id='outgoing-invite-response-rejected' vars={{ first_name: person.first_name, last_name: person.last_name, slot_name: slot_name }}>
                                {'An invite "{$slot_name}" has been rejected'}
                            </Localized>,
                            <Localized id='push-notification-invite-rejected-header'><b><p>Invite rejected</p></b></Localized>
                        )
                    )
                } else if (alert.data.status === 'cancelled') {
                    setActiveAlert(
                        generateInfoTypeNotification(alertData,
                            <Localized id='outgoing-invite-response-cancelled' vars={{ first_name: person.first_name, last_name: person.last_name }}>
                                {'You have succesfully cancelled an invite'}
                            </Localized>
                        )
                    )
                }

                break;
            }

            case 'INCOMING_INVITE_RESPONSE': {
                const person = alert.data.user
                const invite = alert.data
                const startDateTime = moment(alert.data.slot.start_time).format('DD.MM.YYYY HH:mm')
                const endTime = moment(alert.data.slot.end_time).format('HH:mm')
                const slot_name = alert.data.slot.name
                const alertData = { person, startDateTime, endTime, slot_name, invite }
                if (invite.status === 'cancelled') {
                    setActiveAlert(
                        generateInfoTypeNotification(alertData,
                            <Localized id='incoming-invite-cancelled' vars={{ first_name: person.first_name, last_name: person.last_name, slot_name: alert.data.slot.name }}>
                                {'{$first_name} {$last_name} has cancelled a timeslot "{$slot_name}"'}
                            </Localized>,
                            <Localized id='push-notification-invite-cancelled-header'><b><p>Meeting cancelled</p></b></Localized>
                        )
                    )
                } else {
                    setActiveAlert(
                        generateInfoTypeNotification(alertData,
                            <Localized id='incoming-invite' vars={{ first_name: person.first_name, last_name: person.last_name, slot_name: alert.data.slot.name }}>
                                {'You have received an invite to a timeslot "{$slot_name}"'}
                            </Localized>,
                            <Localized id='push-notification-new-invite-header'><b><p>New invite</p></b></Localized>,
                            handleViewTheInvite
                        )
                    )
                }

                break;
            }

            case 'OUTGOING_INVITE': {
                const { first_name, last_name } = alert.data.user
                setActiveAlert(
                    <Localized id='outgoing-invite' vars={{ first_name: first_name, last_name: last_name }}>
                        <div>{'Invite sent succesfully to {$first_name} {$last_name}'}</div>
                    </Localized>
                )

                break
            }

            case 'DELETE_INVITE': {
                const slot = alert.data
                const slotName = slot.status === "available" ? "" : slot.name
                setActiveAlert(
                    <Localized id='delete-invite' vars={{ name: slotName }}>
                        <div className="notification-inner" variant="success">{'Timeslot "{$name}" has been deleted succesfully from your calendar'}</div>
                    </Localized>
                )

                break
            }

            case 'ADD_FAVOURITE': {
                const slot = alert.data
                setActiveAlert(
                    <Localized id='add-to-favourites' vars={{ name: slot.name }}>
                        <div className="notification-inner" variant="success">{'Event timeslot "{$name}" has been added succesfully to your event timeslots'}</div>
                    </Localized>
                )
                break
            }

            case 'REMOVE_FAVOURITE': {
                const slot = alert.data
                setActiveAlert(
                    <Localized id='remove-from-favourites' vars={{ name: slot.name }}>
                        <div className="notification-inner" variant="success">{'Event timeslot "{$name}" has been deleted succesfully from your event timeslots'}</div>
                    </Localized>
                )

                break
            }

            case 'ENGAGEMENT_NEW_LEVEL': {
                if (!isGuestuser) {
                    const level = alert.data
                    setActiveAlert(
                        <>
                            <Localized id='new-level-header'><b><p>New Engagement Level</p></b></Localized><br />
                            <Localized id='engagement-new-level' vars={{ level: level }}>
                                <div className="notification-inner" variant="info">{'Congratulations you have reached a new level! Your new level is {$level}!'}</div>
                            </Localized>
                        </>
                    )
                }
                break
            }

            case 'ENGAGEMENT_NEW_LEVEL_MAX': {
                if (!isGuestuser) {
                    const level = alert.data
                    setActiveAlert(
                        <>
                            <Localized id='new-level-header'><b><p>New Engagement Level</p></b></Localized><br />
                            <Localized id='engagement-new-level-max' vars={{ level: level }}>
                                <div className="notification-inner" variant="info">{'Congratulations you have reached the maximum level! Your new level is {$level}! You will still be receiving the XP.'}</div>
                            </Localized>
                        </>
                    )
                }
                break
            }

            case 'ENGAGEMENT_NEW_ACHIEVEMENT': {
                if (!isGuestuser) {
                    const { name, title } = alert.data
                    const achievementHeader = <Localized id='engagement-new-achievement-header'><b><p>Achievement unlocked!</p></b></Localized>
                    setActiveAlert(
                        <>
                            {achievementHeader}<br />

                            <div className="notification-inner" variant="info">
                                <Localized id={`engagement-new-achievement-${name}`}>
                                    <div className="engagement-achievement-title">
                                        {title}
                                    </div>
                                </Localized>
                                <div className="engagement-achievement-description">
                                    <Localized id={`engagement-new-achievement-${name}-description`} />
                                </div>
                            </div>
                        </>
                    )
                }
                break
            }
            case 'ACCEPT_INVITE': {
                const slot = alert.data
                setActiveAlert(
                    <Localized id='accept-invite-alert' vars={{ name: slot.name }}>
                        <div className="notification-inner" variant="success">{'Invite to slot "{$name}" has been accepted succesfully'}</div>
                    </Localized>
                )
                break
            }

            case 'REJECT_INVITE': {
                const slot = alert.data
                setActiveAlert(
                    <Localized id='reject-invite-alert' vars={{ name: slot.name }}>
                        <div className="notification-inner" variant="success">{'Invite to slot "{$name}" has been accepted succesfully'}</div>
                    </Localized>
                )
                break
            }

            case 'CANCEL_OUTGOING_INVITE': {
                const slot = alert.data.slot
                setActiveAlert(
                    <Localized id='cancel-outgoing-invite-alert' vars={{ name: slot.name }}>
                        <div className="notification-inner" variant="success">{'Invite to the slot "{$name}" has been cancelled succesfully'}</div>
                    </Localized>
                )
                break
            }

            case 'CANCEL_INCOMING_INVITE': {
                const slot = alert.data
                const name = slot.slot?.name || slot.name
                setActiveAlert(
                    <Localized id='cancel-incoming-invite-alert' vars={{ name: name }}>
                        <div className="notification-inner" variant="success">{'Invite to the slot "{$name}" has been cancelled succesfully'}</div>
                    </Localized>
                )
                break
            }

            case 'CALENDAR_EVENT_IS_STARTING': {

                const slot_type = alert.data.slot_type

                switch (slot_type) {
                    case 'event_timeslot': {
                        const slot = alert.data
                        setActiveAlert(
                            <>
                                <Localized id='event-start-notification-5min-header'><b><p>Event is starting in 5 minutes!</p></b></Localized>
                                <br />
                                <Localized id='event-start-notification' vars={{ slot_name: slot.name }}>
                                    <div>{'Event: "{$slot_name}"'}</div>
                                </Localized>
                                <div className="notification-slot-time">{`${moment(slot.start_time).format('HH:mm:ss')} - ${moment(slot.end_time).format('HH:mm:ss')}`}</div>
                                {
                                    slot.link.length > 0 &&
                                    <NavLink to={`/${event_slug}/${slot.link}${accessKeyString}`} className="notification-event-link">
                                        <Localized id="proceed-to-the-event-link">
                                            <strong>Proceed to the event</strong>
                                        </Localized>
                                    </NavLink>
                                }

                            </>
                        )

                        break;
                    }
                    case 'meeting': {
                        const slot = alert.data.sent_invites ? alert.data : alert.data.slot
                        const user = alert.data.sent_invites ? alert.data.sent_invites[0].user : alert.data.user
                        setActiveAlert(
                            <>

                                <Localized id='meeting-start-notification-5min-header'><b><p>Meeting is starting in 5 minutes!</p></b></Localized>
                                <br />
                                <Localized id='meeting-start-notification' vars={{ slot_name: slot.name }}>
                                    {'Meeting: "{$slot_name}".'}
                                </Localized>
                                <div className="user-time-wrapper">
                                    <div className="user-wrapper">
                                        <IconUser className="push-notification-icon" />
                                        {`${user.first_name} ${user.last_name}`}
                                    </div>

                                    <div className="time-wrapper">
                                        <IconClock className="push-notification-icon" />
                                        <div className="notification-slot-time">{`${moment(slot.start_time).format('HH:mm:ss')} - ${moment(slot.end_time).format('HH:mm:ss')}`}</div>
                                    </div>
                                    <Localized id='sidebar-proceed-to-profile'>
                                        <button className="proceed-to-profile-button proceed" onClick={(e) => handleProceedToProfile(e, user.user_id)}>Proceed to profile</button>
                                    </Localized>
                                </div>
                            </>
                        )

                        break;
                    }

                    default:
                        break;
                }
                break
            }


            case 'SEND_MESSAGE_FAILED': {
                setActiveAlert(
                    <Localized id="chat-send-message-failed">
                        <div className="notification-inner" variant="success">Too fast!</div>
                    </Localized>
                )
                break
            }

            case 'PRIVATE_MSG_RECEIVED': {
                const { sender = {}, message = '' } = alert.data
                const { first_name = '', last_name = '', user_id } = sender
                const profile = inbox.private_messages.find(pm => pm.user_id === user_id)
                const handleOnClick = () => {
                    closeModals()
                    toggleProfile(profile)
                    changeView('inbox')
                    showChat();
                }
                setActiveAlert(
                    <div className="privete-msg-notification" onClick={handleOnClick}>
                        <div className="title">
                            <Localized id="private-message-notification-title">New Private Message</Localized>
                        </div>
                        <div className="sender">
                            {`${first_name} ${last_name}`}
                        </div>
                        <div className="message">
                            {message}
                        </div>
                    </div>
                )
                break
            }

            case 'GENERIC': {
                const { title = '', message = '' } = alert.data
                setActiveAlert(
                    <div className="privete-msg-notification">
                        {title && <div className="title">
                            {title}
                        </div>}
                        <div className="message">
                            {message}
                        </div>
                    </div>
                )
                break
            }

            default:
                break;
        }

    }, [alert])// eslint-disable-line react-hooks/exhaustive-deps

    return activeAlert
})