import { withRouter, NavLink } from "react-router-dom"
import { useContext } from 'react';
import { DEFAULT_ACTIONS } from "../constants";
import { SettingContext } from "../contexts";
import { APP_PATHS } from "constants.js";
import { useAccessKey } from "../editorView/apiCalls";
import { useDispatch } from "react-redux";
import * as a from 'actiontypes'

const parseStyle = (properties, defaultStyle, blacklist) => {
    const styles = {};
    for (let key in defaultStyle) {
        if (properties[key]) {
            if (!blacklist.includes(key)) {
                if (key === 'border') {
                    styles['border'] = properties['border']['border'];
                    styles['borderRadius'] = properties['border']['borderRadius']
                } else if (key === 'background') {
                    styles['backgroundImage'] = properties['background']['image'];
                    styles['backgroundSize'] = properties['background']['size'];
                    styles['backgroundPosition'] = properties['background']['position'];
                    styles['backgroundColor'] = properties['background']['color'];
                    styles['backgroundRepeat'] = properties['background']['repeat'];
                } else {
                    styles[key] = properties[key]
                }
            }
        } else {
            styles[key] = defaultStyle[key]
        }
    }
    return styles
}

const fallbackElem = {
    properties: null,
    actions: null
}

function ElementWrapper(props) {
    const editorMode = props.match.path.includes('/editor') ? true : false;
    const {
        className,
        elem = fallbackElem,
        defaultStyle,
        children,
        styleBlacklist = [],
        overrideStyle // Overrides wrapper style defining, 
    } = props;

    const { slug } = props.match.params;
    const { properties = null, actions = null } = elem;
    const queryString = useAccessKey();
    const { activeItem, setActiveItem } = useContext(SettingContext);
    const dispatch = useDispatch()
    ///////////////////////////////////////////////////////////////////////////////////////////////
    // OVERLAYS
    const hasOverlay = properties['overlay'] ? true : false;
    let overlayStyles = { pointerEvents: 'none' };
    let overlay = null;

    if (hasOverlay) {
        const legacyOverlay = typeof(properties['overlay']) !== 'object';
        if (legacyOverlay) {
            overlayStyles['background'] = properties['overlay'];
        } else {
            overlayStyles['background'] = properties['overlay']['color'];
            overlayStyles['mixBlendMode'] = properties['overlay']['blendMode'];
        }
        // Add same borderRadius to overlay that the element has
        if (properties['border'] && properties['border']['borderRadius']) {
            overlayStyles['borderRadius'] = properties['border']['borderRadius']
        };
        overlay = <div className="virtual-event-component-overlay" style={overlayStyles}></div>;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////

    // Content mode --- Actual Virtual Event
    
    if (!editorMode) {
        // Logic that should be handled only in the content view
        // Links
        const linking = actions ? actions['link'] : DEFAULT_ACTIONS.link;
        switch (linking.type) {
            case 'inner': {
                const link = APP_PATHS.virtual + '/' + slug + '/' + linking.link + queryString;
                return <NavLink to={link} className={className} style={overrideStyle ? overrideStyle : parseStyle(properties, defaultStyle, styleBlacklist)}>
                    {hasOverlay && overlay}
                    {children}
                </NavLink>
            }
            case 'outer': {
                return <a href={linking.link} className={className} target="_blank" style={overrideStyle ? overrideStyle : parseStyle(properties, defaultStyle, styleBlacklist)}>
                    {hasOverlay && overlay}
                    {children}
                </a>
            }
            default: {
                return <div className={className} style={overrideStyle ? overrideStyle : parseStyle(properties, defaultStyle, styleBlacklist)}>
                    {hasOverlay && overlay}
                    {children}
                </div>
            }
        }

    }

    const handleSelectElem = () => {
      setActiveItem(elem)
      dispatch({ type: a.HANDLE_DEFAULT_WIDGET, payload: { name: elem.renderType, show: true }})
    }


    // Editor mode ->
    /*
        Function wrapping that are included only in the editor view
    */
    const hilight = activeItem && (elem.i === activeItem.i || elem.i === activeItem.id) ? ' hilight' : '';
    return <div className={`${className} element-hilight-wrapper${hilight}`}
        onDoubleClick={handleSelectElem}
        style={overrideStyle ? overrideStyle : parseStyle(properties, defaultStyle, styleBlacklist)}
    >
        {hasOverlay && overlay}
        {props.children}
    </div>
}

export default withRouter(ElementWrapper);