import { useState, useEffect, useCallback } from "react";

function downHandler(e, targetKey, setter) {
    if (e.key === targetKey || e.keyCode === targetKey) {
        setter(true);
    }
}
const upHandler = (e, targetKey, setter) => {
    if (e.key === targetKey || e.keyCode === targetKey) {
        setter(false);
    }
};

export function useKeyPress(targetKey) {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(false);
    // If pressed key is our target key then set to true

    // If released key is our target key then set to false

    // Add event listeners
    useEffect(() => {
        window.addEventListener("keydown", (e) => downHandler(e, targetKey, setKeyPressed));
        window.addEventListener("keyup", (e) => upHandler(e, targetKey, setKeyPressed));
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener("keydown", (e) => downHandler(e, targetKey, setKeyPressed));
            window.removeEventListener("keyup", (e) => upHandler(e, targetKey, setKeyPressed));
        };
    }, [targetKey]); // Empty array ensures that effect is only run on mount and unmount
    return keyPressed;
}

export function useHistoryShortCut(editorDispatch, setActiveItem) {
    const handleKeyPress = useCallback((e) => {
        if (e.keyCode === 90 && e.ctrlKey && e.shiftKey) {
            //setActiveItem(null)
            editorDispatch({ type: "HISTORY_TRAVEL", payload: 1 })
        } else if (e.keyCode === 90 && (e.ctrlKey || e.metaKey)) {
            //setActiveItem(null)
            editorDispatch({ type: "HISTORY_TRAVEL", payload: -1 })
        } else if (e.keyCode === 27) { // Deselect component
          e.preventDefault()
          setActiveItem(null)
        }
      }, []);
    
      useEffect(() => {
        // attach the event listener
        document.addEventListener('keydown', handleKeyPress);
    
        // remove the event listener
        return () => {
          document.removeEventListener('keydown', handleKeyPress);
        };
      }, [handleKeyPress]);
}