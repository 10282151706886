import { Localized } from '@fluent/react'
import moment from 'moment'
import { useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { useSetTimeline } from '../helpers/hooks'
import { includeDatepickerTimes } from '../helpers/inviteHelpers'

export const TimeSelector = (props) => {
    const {
        value,
        target,
        handleChange,
        label,
        lang,
        filterPassedTime,
        interval,
        includeTimeArr,
        startTime,
        isEnd,
        disabled
    } = props
    
    return <div className="time-selector-container">
        <div className="time-selector-label">{label}</div>
        <DatePicker
            selected={value}
            onChange={date => handleChange(date, target)}
            className="datepicker-time"
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={interval}
            dateFormat="HH:mm"
            locale={lang}
            filterTime={(time) => filterPassedTime(time, startTime)}
            includeTimes={includeDatepickerTimes(includeTimeArr, interval, startTime, isEnd)}
            disabled={disabled}
        />
    </div>
}

const InviteSlot = ({ slot, start, user, handleOpenSlot }) => {
    const calculateMargin = () => {
        const slotStart = moment(slot.start_time)
        const end = moment(slot.end_time)

        const hours = (slotStart.minutes() / 60) + (slotStart.diff(start, 'hours'))
        const lengthInMinutes = end.diff(slotStart, 'minutes')
        const lengthToUse = lengthInMinutes < 15 ? 15 : lengthInMinutes
        const height = (lengthToUse / 60) * 4
        return { marginTop: hours * 4, height, lengthInMinutes }
    }
    const { marginTop, height, lengthInMinutes } = calculateMargin()
    const isSmall = lengthInMinutes <= 30
    const slotStyle = {
        marginTop: `${marginTop}rem`,
        height: `${height}rem`
    }

    return <div className={`slot ${user} ${isSmall ? 'small' : ''}`} style={slotStyle} onClick={() => handleOpenSlot(slot, user)} >
        {user === 'attendee-user' && <div className="slot-name"><Localized id="invitations-busy-text">Busy</Localized></div>}
        {user === 'myself' && <div className="slot-name">{slot.name}</div>}
        {lengthInMinutes >= 30 && <span>{`${moment(slot.start_time).format('HH:mm')} - ${moment(slot.end_time).format('HH:mm')}`}</span>}
    </div>
}

export const TimelineContainer = (props) => {
    const { availableSlots, date, select, mySlots, attendeeSlots, handleOpenSlot, setTimelineHeight } = props
    const todayAvailableSlots = availableSlots.filter(s => moment(s.start_time).isSame(moment(date), 'day') || moment(s.end_time).isSame(moment(date), 'day'))

    const [momentHours, startToSend] = useSetTimeline(mySlots, attendeeSlots, todayAvailableSlots, select, date)
    useEffect(() => {
        setTimelineHeight({ "--timeline-height": `${momentHours.length * 4}rem` })
    }, [setTimelineHeight, momentHours.length])

    return <div className="hours-container">
        <div className="hours">
            {momentHours}
        </div>
        <div className={`slots ${(attendeeSlots && !mySlots) ? 'right' : ''}`}>
            {mySlots && <div className="slot-section">
                {mySlots.map((slot, i) =>
                    <InviteSlot slot={slot} start={startToSend} key={`my_invite_slot_${i}`} user='myself' handleOpenSlot={handleOpenSlot} />
                )}
            </div>}
            {attendeeSlots && <div className="slot-section">
                {attendeeSlots.map((slot, i) =>
                    <InviteSlot slot={slot} start={startToSend} key={`attendee_invite_slot_${i}`} user='attendee-user' handleOpenSlot={handleOpenSlot} />
                )}
            </div>}
        </div>
    </div>
}