import { cloneDeep, union } from "lodash";
const moment = require('moment')


const checkSlotDuration = (multiDaySlots) => {
  const multiSlots = []

    if (multiDaySlots.length > 0) {
      for (let i = 0; i < multiDaySlots.length; i++) {
        let start = moment(multiDaySlots[i].start_time)
        const end = moment(multiDaySlots[i].end_time)
        while(true) {
          if (start.isSame(end, 'day')) {
            const parsedSlot = cloneDeep(multiDaySlots[i])
            parsedSlot.start_time = start.startOf('day').format('YYYY-MM-DD[T]HH:mm:ss[Z]')
            parsedSlot.end_time = end.format('YYYY-MM-DD[T]HH:mm:ss[Z]')
            parsedSlot.duration = end.diff(start.startOf('day'), 'minutes')
            multiSlots.push(parsedSlot)
            break
          } else {
            if (start.isSame(moment(multiDaySlots[i].start_time), 'day')) {
              const parsedSlot = cloneDeep(multiDaySlots[i])
              parsedSlot.start_time = multiDaySlots[i].start_time
              parsedSlot.end_time = moment(multiDaySlots[i].start_time).endOf('day').format('YYYY-MM-DD[T]HH:mm:ss[Z]')
              parsedSlot.duration = moment(multiDaySlots[i].start_time).endOf('day').diff(start)
              multiSlots.push(parsedSlot)
            } else {
              const parsedSlot = cloneDeep(multiDaySlots[i])
              parsedSlot.start_time = start.startOf('day').format('YYYY-MM-DD[T]HH:mm:ss[Z]')
              parsedSlot.end_time = start.endOf('day').format('YYYY-MM-DD[T]HH:mm:ss[Z]')
              parsedSlot.duration = start.endOf('day').diff(start.startOf('day'), 'minutes')
              multiSlots.push(parsedSlot)
            }

            const newStart = cloneDeep(start).add(1, 'days')
            start = newStart
          }
        }
      }
    }

    return multiSlots 
}

export const checkScheduleDuration = (scheduleData) => {
  // PARSING SCHEDULES AND SLOTS IF THE SLOT IS LONGER THAN A FULL DAY
  
  let multiDaySchedules = []
  let filteredSchedules = []
  const schedules = cloneDeep(scheduleData.schedules)

  schedules.forEach(schedule => {
    schedule.days.forEach(day => {
      for (const property in day) {
        if (day[property].end.diff(day[property].start, 'days') > 0) {
          multiDaySchedules.push(cloneDeep({schedule, day}))
        }
      }
    })
  })

  if (multiDaySchedules.length > 0) {
    
    for (let i = 0; i < multiDaySchedules.length; i++) {
      let newDays = []
      let multiDaySlots = []
      const day = cloneDeep(multiDaySchedules[i].day)
      const schedule = cloneDeep(multiDaySchedules[i].schedule)
      for (const property in day) {
        multiDaySlots = day[property].timeslots.filter(s => moment(s.end_time).diff(moment(s.start_time), 'days') > 0)
        let parsedSlots = cloneDeep(checkSlotDuration(multiDaySlots))
        parsedSlots.forEach(s => {
          newDays.push({
            [moment(s.start_time).format('DD.MM.YYYY')]: {
              timeslots: [s],
              duration: moment(s.end_time).diff(moment(s.start_time), 'minutes'),
              start: moment(s.start_time),
              end: moment(s.end_time)
            }
          })
        })
      }

      var days = cloneDeep(schedule.days)

      schedule.days.forEach(day => {// eslint-disable-line no-loop-func
        newDays.forEach(d => {
          if (Object.keys(d)[0] === Object.keys(day)[0]) {
            days = schedule.days.filter(sd => sd !== day)
          } 
        })
      })

      schedule.days = cloneDeep(days)
      
      let copiedDays = cloneDeep(schedule.days)
      schedule.days = copiedDays.concat(newDays)
      const newDates = schedule.days.map(d => Object.keys(d)[0])
      let uni = union(newDates, scheduleData.dates)
      const sortedDates  = uni.sort((a,b) => sortDate(a,b))
      scheduleData.dates = sortedDates
      filteredSchedules = schedules.filter(s => s.id !== schedule.id)
      filteredSchedules.push(schedule)
    }

    scheduleData.schedules = cloneDeep(filteredSchedules)

    return scheduleData
    
  } else {
    return scheduleData
  }

  
}

function sortDate(dateA, dateB, direction = 'asc') {
  const formats = ['DD.MM.YYYY']; // can be several
  return (moment(dateA, formats).isBefore(moment(dateB, formats)) ? -1
      : moment(dateA, formats).isAfter(moment(dateB, formats)) ? 1 : 0) * (direction === 'asc' ? 1 : -1)
}

export const getWeekHelper = (slots) => {

    const multiDaySlots = slots.filter(s => moment(s.end_time).diff(moment(s.start_time), 'days') > 0)

    const allDates = slots.map(s => s.start_time)

    let newSlots = slots

    if (multiDaySlots.length > 0) {
      multiDaySlots.forEach(slot => {
        
        newSlots = newSlots.filter(s => s.performers && s.id !== slot.id)

        let dayStart = moment(slot.start_time).startOf('day')
        while(true) {
          dayStart = dayStart.add(1, 'days')
          if (dayStart.format('DD:MM') === moment(slot.end_time).format('DD:MM')) {
            
            allDates.push(dayStart.format('YYYY-MM-DD[T]HH:mm:ss[Z]'))
            break
          } else {
            allDates.push(dayStart.format('YYYY-MM-DD[T]HH:mm:ss[Z]'))
          }
        }
      })
    }

    //unique dates
    const uniqueDates = allDates
        .reduce((acc, x) => acc
            .concat(acc
                .find(y => moment(y).format('YYYY-MM-DD') === moment(x).format('YYYY-MM-DD')) ? [] : [x]), [])
                .sort((a, b) => (a > b) ? 1 : -1)

    // creating a week
    const days = uniqueDates.map(currentDate => {

        let allSlots = [];
        
        if (multiDaySlots.length > 0) {
          const multiSlots = checkSlotDuration(multiDaySlots)
          slots.forEach(slot => {
            multiDaySlots.forEach(mSlot => {
              if (slot.id !== mSlot.id) {
                allSlots.push(slot)
              }
            })
          })
          
          allSlots = allSlots.concat(multiSlots).filter(es => moment(es.start_time).format('YYYY-MM-DD') === moment(currentDate).format('YYYY-MM-DD'))
        } else {
          allSlots = slots.filter(es => moment(es.start_time).format('YYYY-MM-DD') === moment(currentDate).format('YYYY-MM-DD'))
        }
        
        // Adding duration to all the slots
        allSlots.forEach(slot => {
            slot.duration = moment(slot.end_time).diff(moment(slot.start_time), 'minutes')
        })

        // Date now
        const dateNow = moment().format('MMMM Do YYYY');

        // creating a day object
        const dayObject = {
            weekDay: moment(currentDate).format("ddd"),
            dayNumber: moment(currentDate).format("Do"),
            fullDate: moment(currentDate).format('YYYY-MM-DD'),
            active: moment(currentDate).format('MMMM Do YYYY') === dateNow,
            slots: allSlots.sort((a, b) => (a.start_time > b.start_time) ? 1 : -1)
        }

        // returning day object to days array
        return dayObject
    });

    if (days.filter(d => d.active === true).length === 0 && days.length > 0) {
        days[0].active = true
    }
    
    return { uniqueDates, days };
}

export const getTimeline = (thisDaySlots) => {
    
    const timelineScale = [moment(thisDaySlots[0].start_time).startOf('hour').format('YYYY-MM-DDTHH:mm:ss'), moment(thisDaySlots[thisDaySlots.length - 1].end_time).endOf('hour').add(1, 'minutes').format('YYYY-MM-DDTHH:mm:ss')]
    
    let dayScale = []

    const totalHrs = moment(timelineScale[1]).diff(moment(timelineScale[0]), 'hours')

    for (let i = 0; i < totalHrs; i++) {
    dayScale.push({ start: moment(timelineScale[0]).add(i, 'hours'), end: moment(thisDaySlots[0].start_time).startOf('hour').add(i+1, 'hours')})
    }

    return dayScale
}

export const getScheduleTimeline = (schedules) => {

  let dayScale = []

  let arr = schedules.map(s => s.program.timeslots)
  let startTimes = []
  let endTimes = []
  arr.forEach(timeArr => {
    timeArr.forEach(t => {
      startTimes.push(t.start_time)
      endTimes.push(t.end_time)
    })
  })

  let min = moment.min(startTimes.map(st => moment(st)))
  let max = moment.max(endTimes.map(st => moment(st)))

  let first = min.startOf('hour').format('YYYY-MM-DDTHH:mm:ss')
  let last = max.endOf('hour').add(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss')

  const totalHrs = moment(last).diff(moment(first), 'hours')

  for (let i = 0; i < totalHrs; i++) {
    dayScale.push({ start: moment(first).add(i, 'hours'), end: moment(first).add(i+1, 'hours')})
  }
  
  return { dayScale, totalHrs, checkScheduleDuration }
}