import { invoke } from './rpcInstance';

export async function auth_pass(user: string, pass: string) {
  return await invoke('login_test_pass', user, pass);
}

export async function auth_token(token: string) {
  if (token.startsWith('ey')) {
    return await invoke('login_jwt', token);
  } else {
    return await auth_old_token(token);
  }
}

export async function auth_old_token(token: string) {
  return await invoke('login_old_token', token);
}
