/** @format */

import { connect } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import "./chat.scss";
import { compareDates } from "util/time";
import * as a from "actiontypes";
import { elementScrollIntoView } from "seamless-scroll-polyfill";

import Send from "./send";
import { UserMessage, AnonymousMessage, ModeratorInfo, ModeratorMessage, ContinuedMessage } from "./message";
import { LivetoLoader } from "components/icons";
import { IconChevronDown } from "components/icons";
import { Localized } from "@fluent/react";

const mapStateToProps = state => ({
	chatrooms: state.chatrooms,
	connection: state.connection,
	moderator_mode: state.moderator.moderator_mode,
	chatUserId: state.user.chat_user_id,
	event_slug: state.event.slug,
});

const mapDispatchToProps = dispatch => ({
	requestChatHistory: payload => dispatch({ type: a.WS_REQUEST_CHAT_HISTORY, payload: payload }),
	replyToAMessage: payload => dispatch({ type: a.SET_QUOTED_MESSAGE, payload }),
	addHeldMessagesToHistory: payload => dispatch({ type: a.SET_OWN_HELD_MESSAGES, payload }),
});

const ChatFeed = props => {
	const scrollRef = useRef();
	const chatFeedRef = useRef();
	const lastMessageRef = useRef();
	const { chatrooms, connection, replyToAMessage, sendProps } = props;
	const [scrollBlocked, setScrollBlocked] = useState(false);
	const { active_channel, connected } = connection;
	let chatroom = chatrooms.find(room => room.room_id === active_channel) || {};
	const { chat_history = [], room_id, more, fetching_more_history = false } = chatroom;
	const [newMessagesButton, setNewMessagesButton] = useState(false);
	const [reactionsVisible, setReactionsVisible] = useState(null);

	useEffect(() => {
		const hasScrollbar = chatFeedRef.current.scrollHeight > chatFeedRef.current.clientHeight;

		// If scroll has not been blocked, scroll to latest message
		if (scrollRef && !scrollBlocked && hasScrollbar) {
			elementScrollIntoView(scrollRef.current, { block: "nearest", behavior: "auto" });
		}

		// Showing 'new messages' button if not scrolled to bottom or loaded more chat history
		if (chat_history.length > 0) {
			if (scrollBlocked && lastMessageRef.current !== chat_history[chat_history.length - 1].message_id) {
				setNewMessagesButton(true);
			}
			lastMessageRef.current = chat_history[chat_history.length - 1].message_id;
		}
	}, [chat_history.length]); // eslint-disable-line react-hooks/exhaustive-deps

	function scrollToBottom() {
		elementScrollIntoView(scrollRef.current, { block: "nearest", behavior: "auto" });
		setNewMessagesButton(false);
	}

	function onScroll() {
		const scrollThreshold =
			chatFeedRef.current.scrollHeight - chatFeedRef.current.scrollTop - chatFeedRef.current.clientHeight;

		let blocked = false;
		if (chatFeedRef && scrollThreshold > 150) {
			blocked = true;
		}
		setScrollBlocked(blocked);

		if (scrollThreshold <= 0 && newMessagesButton) {
			setNewMessagesButton(false);
		}
	}

	function loadMoreMessages() {
		props.requestChatHistory({ room_id: room_id, skip: chat_history.length });
	}

	const handleReply = (message, message_id, sender_name, isAnonymous = false) => {
		replyToAMessage({ message, message_id, sender_name, isAnonymous });
	};

	let messages = [];
	if (chat_history) {
		for (let [index, msg] of chat_history.entries()) {
			let previousIndex = index - 1;
			let continueMessage = false;

			if (previousIndex >= 0) {
				let sameDates = compareDates(
					chat_history[previousIndex]["timestamp_recv" || "timestamp"],
					msg["timestamp_recv" || "timestamp"]
				);
				let sameSenderId = chat_history[previousIndex].user_id === msg.user_id;
				let sameSenderInstance = chat_history[previousIndex].sender === msg.sender;

				if (sameDates && sameSenderId && sameSenderInstance && msg.sender !== "MODERATOR_MESSAGE") {
					continueMessage = true;
				}
			}
			if (continueMessage) {
				messages.push(
					<ContinuedMessage
						{...msg}
						key={"msg_" + index}
						setReactionsVisible={setReactionsVisible}
						reactionsVisible={reactionsVisible === msg.message_id}
						handleReply={handleReply}
						chat_history={chat_history}
					/>
				);
			} else {
				switch (msg.sender) {
					case "ANONYMOUS_PERSON":
						messages.push(
							<AnonymousMessage
								{...msg}
								key={"msg_" + index}
								setReactionsVisible={setReactionsVisible}
								reactionsVisible={reactionsVisible === msg.message_id}
								handleReply={handleReply}
								chat_history={chat_history}
							/>
						);
						break;
					case "MODERATOR":
						messages.push(<ModeratorInfo {...msg} key={"msg_" + index} />);
						break;
					case "MODERATOR_MESSAGE":
						messages.push(<ModeratorMessage {...msg} key={"msg_" + index} chat_history={chat_history} />);
						break;
					case "PERSON":
						messages.push(
							<UserMessage
								{...msg}
								key={"msg_" + index}
								setReactionsVisible={setReactionsVisible}
								reactionsVisible={reactionsVisible === msg.message_id}
								handleReply={handleReply}
								chat_history={chat_history}
							/>
						);
						break;
					default:
						break;
				}
			}
		}
	}

    return <div className="chat-content">
        <div className="chat-history" id="chat-history" ref={chatFeedRef} onScroll={onScroll} onMouseLeave={() => setReactionsVisible(null)}>
            {more && <div className="fetch-more-messages">{fetching_more_history ?
                <LivetoLoader size="30" /> :
                <button className="chatfeed-load-more-messages-button" onClick={() => loadMoreMessages()}><Localized id="chat-load-more-messages-button">Load more messages</Localized></button>
            }</div>}
            {messages}
            {!connected && <div>Reconnecting...</div>}
            <div id="dummy" ref={scrollRef}></div>

        </div>
        <button className={`chatfeed-show-new-messages${(newMessagesButton && scrollBlocked) ? ' show' : ''}`} onClick={scrollToBottom}><Localized id="chat-new-messages-button" /></button>

        <button className={`scroll-to-bottom-button${scrollBlocked ? ' show' : ''}`} onClick={() => scrollToBottom()}>
            <IconChevronDown size="22" />
        </button>
        <Send {...sendProps} chatRoom={chatroom} />
    </div>
}



export default connect(mapStateToProps, mapDispatchToProps)(ChatFeed);
