export function calcSizeInMB(size) {
  return (size / (1024 * 1024)).toFixed(2);
}

export function checkFileTooBig(size) {
  if (calcSizeInMB(size) > 5) {
      return true
  }
  return false;
}

export const compareImageDimensions = ({ target: img }, setImageStyle) => {
  const { offsetHeight, offsetWidth } = img;
  if (offsetHeight > offsetWidth) setImageStyle({width: 'auto', height: '100%'})
  else if (offsetHeight < offsetWidth) setImageStyle({width: '100%', height: 'auto'})
  else setImageStyle({width: '100%', height: '100%'})
};

export const createFolderHierarchy = (folders = []) => {
  const paths = folders.map(f => f.folder).filter(fr => fr)
  let hierarchy = []
  let level = { hierarchy };

  paths.forEach(path => {
      path.split('/').reduce((r, name, i, a) => {
          if (!r[name]) {
              r[name] = { hierarchy: [] };
              r.hierarchy.push({ name, nodes: r[name].hierarchy, path: a.filter((v, ind) => ind <= i).join('/') })
          }
          return r[name];
      }, level)
  })
  return hierarchy
}

export const checkForSpecialChars = (str) => {
  const regex = new RegExp("^[A-Za-z0-9\/\_]+$")
  return regex.test(str)
}