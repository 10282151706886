import cloneDeep from "lodash/cloneDeep";
import { getTimeStamp } from "util/time";
import * as a from "actiontypes";

export const userInitial = {
    authenticated: null,
    userdata: null,
    signed_in: null,
    user_id: null,
    show_inbox: false,
    show_notifications: false,
    show_system_messages: false,
    send_messages_as_anonymous: false,
    send_messages_as_moderator: false,
    channel_name: null,
    is_moderator: false,
    moderator_mode: false,
    silenced: null, // Time in seconds to be silenced, if null user is not silenced
};

const parseUserData = (payload) => {
    let userData = {};
    let KEYS_TO_PARSE = [];
    if (payload.type === "authenticated") {
        KEYS_TO_PARSE = ["first_name", "last_name", "company_name", "title", "icon", "description"];
    } else {
        KEYS_TO_PARSE = ["bgColor", "username"];
    }
    for (let key in payload) {
        if (KEYS_TO_PARSE.includes(key)) {
            if (key === "icon" && payload[key] === "null") {
                userData[key] = null;
            } else {
                userData[key] = payload[key];
            }
        }
    }
    return userData;
};

const user = (state = userInitial, action) => {
    switch (action.type) {
        case "WEBSOCKET::POPULATE_CONFIGURATIONS":
            return { ...state };
        case "SET_USER_DATA_FETCHED": {
            // company_name, description, title, first_name, last_name
            const newState = cloneDeep(state);
            newState.userdata['first_name'] = action.payload.first_name;
            newState.userdata['last_name'] = action.payload.last_name;
            newState.userdata['company_name'] = action.payload.company_name;
            newState.userdata['title'] = action.payload.title;
            newState.userdata['description'] = action.payload.description;

            return newState
        }

        case a.WS_SEND_HEARTBEAT:
            return { ...state };
        case a.WS_RESET_APPLICATION:
            return userInitial;
        case a.WS_RESET_CONNECTION:
            return { ...state, signed_in: false };
        case a.WS_SIGNIN:
            return { ...state, signed_in: true };
        case a.WS_SET_USER: {
            let newState = cloneDeep(state);
            newState.userdata = parseUserData(cloneDeep(action.payload));
            newState.authenticated = action.payload.type === "authenticated" ? true : false;
            newState.channel_name = action.payload.channel_name;
            newState.signed_in = true;
            newState.user_id = action.payload.user_id;
            if (action.payload.is_moderator) {
                newState["is_moderator"] = true;
            }
            return newState;
        }
        case a.WS_RECONNECTED: {
            let userData = parseUserData(cloneDeep(action.payload));
            let authenticated = action.payload.type === "authenticated" ? true : false;
            let lang = action.payload.language ? action.payload.language : state.lang;
            return {
                ...state,
                signed_in: true,
                userdata: userData,
                authenticated: authenticated,
                channel_name: action.payload.channel_name,
                lang: lang,
            };
        }
        case a.WS_TOGGLE_ANONYMOUS_MODE:
            return { ...state, send_messages_as_anonymous: action.payload };

        case a.WS_USER_SILENCED:
            if (state.user_id === action.payload.user_id) {
                return { ...state, silenced: true, silencedTime: getTimeStamp(action.payload.time) };
            }
            return { ...state };

        case a.WS_STOP_SILENCE:
            return { ...state, silenced: false, silencedTime: null };

        case a.WS_TOGGLE_MODERATOR_MODE:
            return { ...state, send_messages_as_moderator: action.payload };

        case 'SIGNOUT': return userInitial;
        
        default: return { ...state };
    }
};
export default user;
