import React from 'react';
import { Menu, Item, Separator, useContextMenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import './menuWrapper.scss';
import { IconCopy } from 'components/icons';
import { duplicateComponent } from '../editorView/helpers/mainToolbarHelpers';

export default function MenuWrapper(props) {
    const { cmp, editorDispatch, editLocks, room, children,  setActiveItem, dispatch } = props;

    const { show } = useContextMenu({
        id: cmp.i
    });

    function displayMenu(e) {
        // put whatever custom logic you need
        // you can even decide to not display the Menu
        show(e);
    }

    return <div className="context-menu" id="editor-context-menu" onContextMenu={displayMenu} style={{ width: '100%', height: '100%', position: 'relative', overflow: 'hidden' }}>
        <Menu id={cmp.i} className="component-context" >
            <Item disabled><b style={{ fontSize: '12px' }}>Component menu</b></Item>
            <Separator />
            <Item onClick={(e) => duplicateComponent(room, editLocks, cmp, editorDispatch, setActiveItem, dispatch)}>
                <div style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'flex-start'}}>
                    <IconCopy size="12" style={{marginRight: '5px'}} />
                    <b style={{fontSize: '11px'}}>Duplicate component</b>
                </div>
          </Item>
        </Menu>
        {children}

    </div>
}