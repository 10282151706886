import React, { useContext, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import Tree from "@naisutech/react-tree";
import * as a from 'actiontypes'
import cloneDeep from "lodash/cloneDeep";
import {
    IconMoveUp,
    IconMoveDown,
    IconNewItem,
    IconComponent,
    iconElement,
    IconRoom,
    IconEdit,
    IconCopy,
    IconTrash
} from "components/icons";
import { COMPONENT_CONFIGS } from '../../constants';
import '../styles/tree.scss';
import { SettingContext } from "containers/VirtualEvent/contexts";
import { GenericTooltip } from "components/ToolTip/Tooltip";
import { duplicateComponent, editName, openDefaultWidget } from '../helpers/mainToolbarHelpers'

// Components
const LabelComp = (props) => {
    const { component, deleteItem, darkMode, room, editLocks, editorDispatch, setActiveItem } = props;
    const [showConfirm, setShowConfirm] = useState(false);
    const [showRename, setShowRename] = useState(false);
    const dispatch = useDispatch()
    const duplicate = (e) => {
        e.stopPropagation()
        duplicateComponent(room, editLocks, component, editorDispatch, setActiveItem, dispatch)
    }
    return (<div className="element-node-wrapper">
        <b style={{ fontSize: "12px", padding: "0px 5px" }}>{component.name}</b>
        <div className="element-label-buttons">
            <ButtonDuplicate darkMode={darkMode} duplicate={duplicate} />
            <ButtonRename darkMode={darkMode} setShowRename={setShowRename} />
            <ButtonDelete darkMode={darkMode} setShowConfirm={setShowConfirm} />
        </div>
        {showConfirm && <DeleteConfirm deleteItem={deleteItem} setShowConfirm={setShowConfirm} component={component} />}
        {showRename && <RenameComponent cid={component.i} setShowRename={setShowRename} />}

    </div>)
}

const LabelAdd = (props) => {
    return <span style={{ fontSize: "12px", padding: "0px 5px" }}>
        {props.c ? 'Add new component' : 'Add new element'}
    </span>
}

const DeleteConfirm = (props) => {
    const { deleteItem, setShowConfirm, element, component } = props;
    return (<div className="delete-dialog-container">
        <div className="delete-dialog-label">
            <span>Confirm</span>
            <span className="delete-dialog-close" onClick={() => setShowConfirm(false)}>×</span>
        </div>
        <div className="delete-dialog-text-container">
            Are you sure?
        </div>
        <div className="delete-buttons-container">
            <span className="delete-button" onClick={() => setShowConfirm(false)}>NO</span>
            <span className="delete-button" onClick={(e) => { deleteItem(e, element, component); setShowConfirm(false); }}>YES</span>
        </div>
    </div>)
}

const RenameComponent = (props) => {
    const { cid, setShowRename } = props;
    const identifiers = { component: cid, element: null }
    const { roomData, view, editLocks, editorDispatch } = useContext(SettingContext)
    const component = roomData.components[view].find(c => c.i === cid)
    const { name } = component
    const [newName, setNewName] = useState(name)
    const handleSaveName = () => {
        editName(identifiers, newName, editorDispatch, roomData, editLocks)
        setShowRename(false)
    }
    return (<div className="delete-dialog-container">
        <div className="delete-dialog-label">
            <span>Rename component</span>
            <span className="delete-dialog-close" onClick={() => setShowRename(false)}>×</span>
        </div>
        <div className="rename-buttons-container">
            <input type="text" value={newName} className="rename-input" onChange={(e) => setNewName(e.target.value)} />
            <span className="delete-button" onClick={handleSaveName}>Save</span>
        </div>
    </div>)
}

const ButtonMoveUp = (props) => {
    const { eidx, cidx, move, darkMode } = props;
    if (eidx > 0) {
        return <span onClick={(e) => move(e, eidx, cidx, 'up')}>
            <IconMoveUp size="20" style={{ color: darkMode ? "#00a3a8" : "#C4281A" }} />
        </span>
    }
    return <span><IconMoveUp size="20" style={{ color: "#ddd" }} /></span>;
}

const ButtonMoveDown = (props) => {
    const { eidx, cidx, move, showDown, darkMode } = props;
    if (showDown) {
        return <span onClick={(e) => move(e, eidx, cidx, 'down')}>
            <IconMoveDown size="20" style={{ color: darkMode ? "#00a3a8" : "#C4281A" }} />
        </span>
    }
    return <span><IconMoveDown size="20" style={{ color: "#ddd" }} /></span>

}

const ButtonDelete = (props) => {
    const handleDelete = (e) => {
        e.stopPropagation()
        props.setShowConfirm(true)
    }
    return <span onClick={(e) => handleDelete(e)} style={{ paddingLeft: '5px' }}>
        <IconTrash size="20" style={{ color: props.darkMode ? "#00a3a8" : "#C4281A" }} />
    </span>
}

const ButtonRename = (props) => {
    return <span onClick={() => props.setShowRename(true)} style={{ paddingLeft: '10px' }}>
        <GenericTooltip text={"Rename component"} hide="100" orientation="bottom">
            <IconEdit size="20" style={{ color: props.darkMode ? "#00a3a8" : "#C4281A" }} />
        </GenericTooltip>
    </span>
}

const ButtonDuplicate = (props) => {
    return <span onClick={(e) => props.duplicate(e)} style={{ paddingLeft: '5px' }}>
        <GenericTooltip text={"Duplicate component"} hide="100" orientation="bottom">
            <IconCopy size="20" style={{ color: props.darkMode ? "#00a3a8" : "#C4281A" }} />
        </GenericTooltip>
    </span>
}

const LabelButtons = (props) => {
    const { element, elementIndex, componentIndex, deleteItem, move, showDown, darkMode, room, view } = props;
    const [showConfirm, setShowConfirm] = useState(false);
    const { renderType, parentId } = element
    
    const isDeletable = () => {
        const parentComponent = room.components[view].find(c => c.i === parentId)
        const preset = parentComponent.preset === 'carousel' ? 'gallery' : parentComponent.preset
        const config = COMPONENT_CONFIGS[preset].component
        return config.can_add_elements.includes(renderType)
    }
    return (<div className="element-node-wrapper">
        <b className="element-name">{element.name + '-' + element.renderType}</b>
        {/* Show move up&down buttons if the element can be moved up/down*/}
        <div className="element-label-buttons">
            <ButtonMoveUp darkMode={darkMode} eidx={elementIndex} cidx={componentIndex} move={move} />
            <ButtonMoveDown darkMode={darkMode} eidx={elementIndex} cidx={componentIndex} move={move} showDown={showDown} />
            {isDeletable() && <ButtonDelete darkMode={darkMode} setShowConfirm={setShowConfirm} />}
        </div>
        {showConfirm && <DeleteConfirm element={element} deleteItem={deleteItem} setShowConfirm={setShowConfirm} />}

    </div>)
}



// Tree generation


function generateTree(room, view, editLocks, editorDispatch, setActiveItem, lang, darkMode) {
    const move = (e, eidx, cidx, direction) => {
        const indx = direction === 'up' ? -1 : 1;
        e.stopPropagation();
        const newRoom = cloneDeep(room);
        for (let view of editLocks) {
            const parentComponent = newRoom.components[view][cidx];
            // Check needed incase where in the other views the elements or component is not
            if (parentComponent && parentComponent.elements[eidx] && parentComponent.elements[eidx + indx]) {
                const swappedElement = cloneDeep(parentComponent.elements[eidx + indx]);
                parentComponent.elements[eidx + indx] = parentComponent.elements[eidx];
                parentComponent.elements[eidx] = swappedElement;
            }

        }

        editorDispatch({ type: "EDITOR_MOVE_ELEMENT", payload: newRoom });
    }
    const deleteItem = (e, element, component) => {
        e.stopPropagation();
        const newRoom = cloneDeep(room);
        let historyLabel = ""
        const cmpID = component ? component.i : element.parentId
        for (let view of editLocks) {
            const viewComponentArray = newRoom.components[view];
            const cmp = viewComponentArray.find(c => c.i === cmpID);
            if (cmp && !element) {
                historyLabel = `Deleted component: ${component.name}`
                viewComponentArray.splice(viewComponentArray.findIndex(comp => comp.i === cmpID), 1)
            } else if (element) {
                cmp.elements.splice(cmp.elements.findIndex(el => el.i === element.i), 1)
            } else {
                console.error("Something went wrong while deleting an item")
            }
        }
        setActiveItem(null)
        editorDispatch({ type: "EDITOR_DELETE_ITEM", payload: newRoom });
        editorDispatch({ type: "PUSH_TO_HISTORY", payload: { room: newRoom, label: { text: historyLabel, time: new Date().getTime() } } });
    }
    const nodes = [];

    nodes.push({
        id: room.slug,
        icon: <IconRoom size="12" className="item-type-icon" />,
        label: <b style={{ fontSize: "15px", padding: "0px 5px" }}>{room.name[lang]}</b>,
        parentId: null,
        items: [],
        //items: [{ id: `new_component`, label: (<LabelAdd c={true} />), parentId: room.slug, icon: <IconNewItem size="12" className="item-type-icon new-item" /> }],
    });



    room.components[view].forEach((component, componentIndex) => {
        const items = component.elements.map((element, elementIndex) => ({
            id: element.i,
            icon: iconElement(element.renderType),
            label: (<LabelButtons
                darkMode={darkMode}
                showDown={elementIndex < (component.elements.length - 1)}
                element={element}
                elementIndex={elementIndex}
                componentIndex={componentIndex}
                deleteItem={deleteItem}
                move={move}
                room={room}
                view={view} />),
            parentId: element.parentId,
        }));
        const preset = component.preset === 'carousel' ? 'gallery' : component.preset
        const componentSettings = COMPONENT_CONFIGS[preset].component

        if (componentSettings?.can_add_elements?.length > 0) {

            items.push({
                id: `new_element${componentIndex}`,
                icon: <IconNewItem size="12" className="item-type-icon new-item" />,
                label: (<LabelAdd c={false} />),
                parentId: component.i,
                lastElementIndex: component.elements.length,
                allowedElements: componentSettings?.can_add_elements.filter(e => e !== 'blank')
            })
        }
        nodes.push({
            id: component.i,
            parentId: component.parentId,
            icon: <IconComponent size="12" className="item-type-icon" />,
            label: (<LabelComp setActiveItem={setActiveItem} room={room} editLocks={editLocks} editorDispatch={editorDispatch} darkMode={darkMode} component={component} componentIndex={componentIndex} deleteItem={deleteItem} />),
            items: items,
        });
    });

    return nodes;
}

const mapStateToProps = state => ({
    editorLanguage: state.editorLanguage,
    polls: state.polls.currentPolls
})

const mapDispatchToProps = dispatch => ({
    handleAddModal: (payload) => dispatch({ type: a.SET_SHOW_ADD_MODAL, payload }),
    handleAddModalData: (payload) => dispatch({ type: a.SET_ADD_MODAL_DATA, payload })
})

function EditorTree(props) {
    const { room, handleAddModal, handleAddModalData, editorLanguage } = props
    const {
        roomData,
        roomSlug,
        activeItem,
        darkMode,
        setActiveItem,
        view,
        editorDispatch,
        editLocks,
        meets,
        streams,
        polls,
        leftNavView
    } = useContext(SettingContext);
    const treeNodes = generateTree(roomData, view, editLocks, editorDispatch, setActiveItem, editorLanguage, darkMode);
    const dispatch = useDispatch()
    const currentTreeNode = (active) => {
        const activeId = active ? active.id || active.i : null;
        if (!active) {
            return null;
        }
        else if (treeNodes.some(node => node.id === activeId)) {
            return treeNodes.find(node => node.id === activeId)
        } else {
            const currentParent = treeNodes.find(node => node.id === active.parentId)
            if (!currentParent) {
                return null;
            }
            return currentParent.items.find(item => item.id === activeId)
        }
    }
    const themes = {
        "darkMode": {
            text: '#a1c0c1',
            bg: '#484848', // $liveto-light-gray
            padding: '3px',
            height: '100%',
            size: 'small',
            highlight: '#555', // the colours used for selected and hover items
            decal: '#00a3a8', // the colours used  for open folder indicators and icons
            accent: '#999', // the colour used for row borders and empty file indicators
        }, "lightMode": {
            text: '#131313',
            bg: '#f6f6f6', // $liveto-light-gray
            padding: '3px',
            height: '100%',
            size: 'small',
            highlight: '#ddd', // the colours used for selected and hover items
            decal: '#C4281A', // the colours used  for open folder indicators and icons
            accent: '#999', // the colour used for row borders and empty file indicators
        }
    }

    const selectElement = (selected, roomSlug) => {
        if (selected.id === "new_component") {
            handleAddModal(true)
            handleAddModalData({ selected, room: roomData, editLocks, editorDispatch, type: 'component' })
        } else if (selected.id.includes("new_element")) {
            handleAddModal(true)
            handleAddModalData({ selected, room: roomData, editLocks, editorDispatch, type: 'element' })
        } else {
            //dispatch({ type: a.HANDLE_DEFAULT_WIDGET, payload: { name: elem.renderType, show: true }})
            if (selected.id === roomData.slug) {
                setActiveItem({ ...selected, isRoom: true })
            }
            else {
                const componentID = selected.id.split('e')[1] ? selected.parentId : selected.id
                dispatch({ type: a.SET_ACTIVE_COMPONENT_REF, payload: componentID })
                setActiveItem(selected)
                openDefaultWidget(selected, roomData, view, dispatch)
            }
        }
    };

    return (
        <div className={`virtual-event-editor-tree ${leftNavView} ${darkMode ? "dark" : ""}`}>
            <div className="tree-content">
                <Tree

                    selected={currentTreeNode(activeItem) ? currentTreeNode(activeItem) : null}
                    nodes={treeNodes}
                    theme={darkMode ? "darkMode" : "lightMode"}
                    customTheme={themes}
                    onSelect={selectElement}
                />
            </div>

            <div className="footer" onClick={() => selectElement({ id: `new_component`, label: (<LabelAdd c={true} />), parentId: roomSlug, icon: <IconNewItem size="12" className="item-type-icon new-item" /> })}>
                <div className="button-container">
                    <IconNewItem size="20" />
                    Add component
                </div>
            </div>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(EditorTree)