import { Localized } from "@fluent/react"
import { IconAlert } from "components/icons"
import { LivetoMiniLogo } from "components/icons"
import { AUTH_COOKIE, AUTH_COOKIE_TIMESTAMP, CUID_COOKIE } from "constants.js"
import { useDispatch } from "react-redux"
import defaultHeroImage from 'static/img/hero-image.jpg'
import { createCookie } from "util/cookies"
import './styles/errors.scss'

export const ErrorPage = (props) => {
    const { title, description, additionalButton = {}, logoutButton, logo, event = {}, status, links = true } = props
    const { text, show, handleClick } = additionalButton
    const dispatch = useDispatch()
    return <div className="not-found-view" style={{ backgroundImage: `url("${event.event_hero_image ?? defaultHeroImage}")` }}>
        <div className="glass-container">
            <div className="informational">
                {status && <h1 className="heading">{status}</h1>}
                <h3 className="sub-heading">{title}</h3>
                <p>{description}</p>
            </div>
            <div className="buttons">
                {show && <button onClick={handleClick} className="error-button">{text}</button>}
                {logoutButton && <button className="error-button" onClick={() => signOut(dispatch)}><Localized id="error-logout-button" /></button>}
            </div>
            {logo && <LivetoMiniLogo color="#C4281A" size="100" />}
            {links && <div className="links">
                <a href="https://events.liveto.io">https://events.liveto.io</a>
                <a href="https://liveto.io">https://liveto.io</a>
            </div>}
        </div>
    </div>
}

const signOut = (dispatch) => {
    createCookie(CUID_COOKIE, "", 0);
    createCookie(AUTH_COOKIE, "", 0);
    createCookie(AUTH_COOKIE_TIMESTAMP, "", 0);
    setTimeout(() => {
        dispatch({ type: 'SIGNOUT' })
    }, 1000)
}

export const statusHandler = (status) => {
    switch (status) {
        case 401: {
            return <Localized id="error-auth-editor-title">Unauthorized</Localized>
        }
        case 402:
        case 403: {
            return <Localized id="error-no-permission">You don't have a permission to execute this action</Localized>
        }
        case 404: {
            return <Localized id="error-not-found">The requested resource not found</Localized>
        }
        case 405: {
            return <Localized id="error-not-allowed">Method not allowed</Localized>
        }
        default:
            return null;
    }
}

export const MaintenancePage = (props) => {
    return (
		<div className="error-page">
			<IconAlert size="200" />
			<p>
				<Localized id="error-maintenance" />
			</p>
			<p>
				<Localized id="error-try-again" />
			</p>
		</div>
	);
}