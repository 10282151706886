/** @format */

import { Localized, useLocalization } from "@fluent/react";
import { IconStar, LivetoLoader } from "components/icons";
import moment from "moment";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useAccessKey } from "containers/VirtualEvent/editorView/apiCalls";
import { SidebarInviteButtons } from "./SidebarComponents";
import * as a from "actiontypes";
import { useFetchEventParticipants } from "containers/VirtualEvent/apiCalls";
import { Spoiler, LoadingOverlay } from "@mantine/core";
import { useGenerateMultilingualData } from "containers/VirtualEvent/performers/helpers";
import { LvtDrawer } from "components/LvtComponents";
import { cancelInvite, useAddEventSlot, useDeleteEventSlot } from "../apiCalls";
import { useManageMyCalendar } from "../helpers/hooks";

const mapStateToProps = state => ({
	calendar: state.schedule.calendar,
	schedule_performers: state.schedule.schedule.performers,
	event_slug: state.event.slug,
	close_modals: state.close_modals,
	sidebar: state.components.sidebar,
	displayType: state.displayType,
	attendees: state.all_attendees,
	user: state.user,
});

const mapDispatchToProps = dispatch => ({
	changeView: target => dispatch({ type: a.WS_CHANGE_VIEW, payload: target }),
	toggleProfile: payload => dispatch({ type: a.WS_TOGGLE_PROFILE, payload: payload }),
	handleSidebar: payload => dispatch({ type: "HANDLE_SIDEBAR", payload }),
	closeModals: () => dispatch({ type: a.HANDLE_MODALS, payload: true }),
});

const Performers = ({ performers }) => {
	const handleOpenLinkInNewTab = (e, url) => {
		e.stopPropagation();
		window.open(url, "_blank");
	};
	const { generate, generateHtml } = useGenerateMultilingualData();

	return (
		<div className="w-100 text-left mt-3">
			<Localized id="sidebar-performers-text">
				<b className="w-100 text-left">Performers</b>
			</Localized>

			{performers.map((performer, index) => {
				const { name, title, description } = performer;
				const displayName = generate(name);
				const displayTitle = generate(title);
				const displayDesc = generateHtml(description);
				return (
					<Card key={`performer_${index}`} className="performer-card mt-1 mb-2">
						{performer.image && (
							<Card.Img className="performer-card-image" variant="top" src={performer.image} />
						)}

						<Card.Body>
							<Card.Title>{displayName}</Card.Title>
							<Card.Subtitle className="mb-2 text-muted">{displayTitle}</Card.Subtitle>
							<Card.Text>{displayDesc}</Card.Text>
							<Card.Link
								className="cursor-pointer"
								onClick={e => handleOpenLinkInNewTab(e, performer.url)}
							>
								{performer.url}
							</Card.Link>
						</Card.Body>
					</Card>
				);
			})}
		</div>
	);
};

const EventTimeSlot = props => {
	const { slot, calendar, event_slug, closeModals, access_key, close, performers, isGuestuser } = props;
	const { description, translated_description = {} } = slot;
	const { language = "en" } = useSelector(state => state.user);
	const isMultilingualDesc = translated_description[language];
	const { l10n } = useLocalization();

	const { generateHtml } = useGenerateMultilingualData();

	const [addButton, overlay] = useManageMyCalendar(
		slot,
		"sidebar-handle-favourites",
		<IconStar size="30" className="cursor-pointer color-favorite icon-clickable star mx-1" />
	);

	return (
		<>
			{overlay}
			{addButton}
			{slot.link && slot.link.length > 0 && (
				<div>
					<NavLink to={`/${event_slug}/${slot.link}${access_key}`}>
						<button
							className="sidebar-handle-favourites"
							onClick={() => {
								close();
								closeModals();
							}}
						>
							<Localized id="sidebar-proceed-to-event-button">Proceed to this room</Localized>
						</button>
					</NavLink>
				</div>
			)}

			<div className="sidebar-description-and-tags-container">
				<Spoiler
					maxHeight={200}
					showLabel={l10n.getString("virtual-event-show-more-button", null, "Show more")}
					hideLabel={l10n.getString("virtual-event-hide-button", null, "Hide")}
				>
					<div className={`sidebar-slot-description${isMultilingualDesc ? "-multilingual" : ""}`}>
						{generateHtml(translated_description, description)}
					</div>
				</Spoiler>
				{slot.tags.length > 0 && (
					<div className="sidebar-tags-container">
						{slot.tags.map(tag => (
							<div className="sidebar-tag">{tag}</div>
						))}
					</div>
				)}
			</div>

			{slot.performers && slot.performers.length > 0 && <Performers performers={performers} />}
		</>
	);
};

const MeetingTimeSlot = props => {
	const { slot, handleCancelInvite, handleProceedToProfile } = props;

	const generateStatus = status => {
		if (status === "accepted") {
			return (
				<Localized id="slot-invite-status-accepted">
					<span className="accepted">accepted</span>
				</Localized>
			);
		} else if (status === "pending") {
			return (
				<Localized id="slot-invite-status-pending">
					<span className="color-favorite">pending</span>
				</Localized>
			);
		} else return "";
	};
	return (
		<>
			{slot.sent_invites?.length > 0 ? (
				<>
					<Localized
						id="sidebar-slot-description"
						vars={{
							description: slot.description,
							userFullName: `${slot.sent_invites[0].user.first_name} ${slot.sent_invites[0].user.last_name}`,
						}}
					>
						<p>{"{$description}"}</p>
					</Localized>
					<p className="mt-3">
						<Localized id="sidebar-invite-sent-to">Invite sent to</Localized>
						<strong>{` ${slot.sent_invites[0].user.first_name} ${slot.sent_invites[0].user.last_name}`}</strong>
					</p>
					<p>
						<Localized id="sidebar-invitation-msg">Invitation message:</Localized>
						<i>{` "${slot.sent_invites[0].message}"`}</i>
					</p>
					<strong>
						<Localized id="sidebar-invitation-status-text">Status:</Localized>{" "}
						{generateStatus(slot.sent_invites[0].status)}
					</strong>
					<SidebarInviteButtons
						slot={slot}
						handleCancelInvite={handleCancelInvite}
						handleProceedToProfile={handleProceedToProfile}
					/>
				</>
			) : (
				<div className="mt-3">
					<Localized id="sidebar-invite-received-from">
						<span>Received from</span>
					</Localized>
					<strong>{` ${slot.user.first_name} ${slot.user.last_name}`}</strong>
					<p>
						<Localized id="sidebar-invitation-msg">Invitation message:</Localized>
						<i>{` "${slot.message}"`}</i>
					</p>
					<strong>
						<Localized id="sidebar-invitation-status-text">Status:</Localized> {generateStatus(slot.status)}
					</strong>
					<SidebarInviteButtons
						slot={slot}
						handleCancelInvite={handleCancelInvite}
						handleProceedToProfile={handleProceedToProfile}
					/>
				</div>
			)}
		</>
	);
};

const SideBar = ({
	calendar,
	schedule_performers,
	handleSidebar,
	event_slug,
	onClose: close,
	changeView,
	toggleProfile,
	close_modals,
	closeModals,
	sidebar,
	displayType,
	attendees,
	user,
}) => {
	const { isOpen, slot: slotData } = sidebar;
	const slot = slotData ? slotData : {};

	const activeUser = useSelector(state => state.user);
	const [performers, setPerformers] = useState([]);
	const accessKey = useAccessKey();
	useFetchEventParticipants();

	const { generate, generateHtml } = useGenerateMultilingualData();

	const slotName = generate(slot.display_name, slot.name);

	useEffect(() => {
		if (slot && schedule_performers) {
			if (slot.performers) {
				if (slot.performers.length > 0) {
					const filteredPerformers = [];
					slot.performers.forEach(performer => {
						schedule_performers.forEach(p => {
							if (performer.id === p.id) {
								filteredPerformers.push(p);
							}
						});
					});
					setPerformers(filteredPerformers);
				}
			}
		}
	}, [slot, schedule_performers]);

	useEffect(() => {
		if (close_modals) {
			handleSidebar({ slot: null, isOpen: false, slotRef: null });
		}
	}, [close_modals]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleProceedToProfile = (e, slot) => {
		const chat_id = slot.isDuplicate ? slot.received_invite.user.user_id : slot.sent_invites[0].user.user_id;
		const attendee = attendees.find(a => a.chat_user_id === chat_id || a.user_id === chat_id);

		if (attendee) {
			handleSidebar({ slot: null, isOpen: false, slotRef: null });
			close();
			changeView("inbox");
			toggleProfile(attendee);
		}
	};

	const dispatch = useDispatch();

	const handleCancelInvite = async (e, slot) => {
		const { ok } = await cancelInvite(slot, event_slug, dispatch, user.chat_user_id);
		if (ok) {
			handleSidebar({ slot: null, isOpen: false, slotRef: null });
		}
	};

	const renderTime = () => {
		if (!moment(slot.start_time).isSame(moment(slot.end_time), "day")) {
			return `${moment(slot.start_time).format("DD.MM.YYYY HH:mm")} - ${moment(slot.end_time).format(
				"DD.MM.YYYY HH:mm"
			)}`;
		} else {
			return `${moment(slot.start_time).format("HH:mm")} - ${moment(slot.end_time).format("HH:mm")}`;
		}
	};

	return (
		<LvtDrawer
			opened={isOpen}
			onClose={() => handleSidebar({ slot: null, isOpen: false })}
			size="xl"
			position="right"
			classNames={{ drawer: "timeslot-drawer-inner" }}
		>
			<div
				className={`side-menu-slot ${isOpen ? "side-menu-open" : ""} overflow-auto ${displayType}`}
				onClick={e => e.stopPropagation()}
			>
				{slot && (
					<div className="slot-details-sidebar">
						<h4>{slotName}</h4>
						<strong>{renderTime()}</strong>

						{slot.performers && (
							<EventTimeSlot
								slot={slot}
								calendar={calendar}
								event_slug={event_slug}
								access_key={accessKey}
								close={close}
								performers={performers}
								isGuestuser={activeUser.guestuser}
								closeModals={closeModals}
							/>
						)}

						{(slot.sent_invites || slot.message) && (
							<MeetingTimeSlot
								slot={slot}
								handleCancelInvite={handleCancelInvite}
								handleProceedToProfile={handleProceedToProfile}
							/>
						)}
					</div>
				)}
			</div>
		</LvtDrawer>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
