/** @format */

import { chatServiceInvoke } from "middlewares/websocket";
import * as a from "actiontypes";

export const getUserHeldMessages = async (room_id, user_id, dispatch) => {
	try {
		const response = await chatServiceInvoke("getHeldMessagesForUser", { userID: user_id, roomID: room_id });
		if (response.length > 0) {
			const held = response.map(msg => {
				const obj = msg.payload;
				obj.held = true;
				obj.timestamp = obj.timestamp_recv;
				return obj;
			});
			dispatch({ type: a.SET_OWN_HELD_MESSAGES, payload: { held_messages: held, room_id: room_id } });
		}
	} catch (error) {
		console.error("Error fetching own held messages", error);
	}
};
export const fetchSingleSetting = async (room_id, event_slug, setting, dispatch, type) => {
	try {
		const response = await chatServiceInvoke("getSingleModSetting", { room_id, setting: setting, event_slug });
		dispatch({ type, payload: response });
	} catch (error) {
		console.error("Error fetching single moderation setting", error);
	}
};
export const fetchSingleRestrictedSetting = async (room_id, event_slug, setting, dispatch) => {
	try {
		const response = await chatServiceInvoke("getSingleModSetting", { room_id, setting: setting, event_slug });
		const restricted = response[room_id];
		const chatroomRestricted = response[room_id] === null ? response.global : response[room_id];
		dispatch({ type: a.SET_CHANNEL_SETTINGS, payload: { room_id, restricted } });
		dispatch({ type: a.SET_CHATROOM_RESTRICTED, payload: { room_id, restricted: chatroomRestricted } });
	} catch (error) {
		console.error("Error fetching single moderation setting", error);
	}
};
