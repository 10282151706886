import * as a from "actiontypes"
import { cloneDeep, omit } from "lodash";

export const engagementInitial = {}

const engagement = (state = engagementInitial, action) => {
    switch (action.type) {
        case a.ENGAGEMENT_GET_INITIAL_TOTAL_XP: {
            return { ...state, totalXP: action.payload };
        }

        case a.ENGAGEMENT_GET_INITIAL_TOTAL_EVENT_XP: {
            return { ...state, totalEventXP: action.payload };
        }

        case a.ENGAGEMENT_GET_CURRENT_LEADERBOARD: {
            return { ...state, leaderboard: action.payload };
        }

        case a.ENGAGEMENT_GET_ACHIEVEMENTS: {
            return { ...state, achievements: action.payload };
        }

        case a.ENGAGEMENT_GET_MY_LEVEL: {
            let level = action.payload
            let currentLevel = state.levelThresholds.find(t => t.level === level)
            let nextLevel = state.levelThresholds.find(t => t.level === level+1)
            let min = currentLevel.threshold
            let max = nextLevel ? nextLevel.threshold : undefined
            return { ...state, level: {level, min, max } };
        }

        case a.ENGAGEMENT_GET_MY_POSITION: {
            return { ...state, myPosition: action.payload };
        }
        
        case a.ENGAGEMENT_GET_LEVEL_THRESHOLDS: {
            return { ...state, levelThresholds: action.payload };
        }

        case a.WS_ENGAGEMENT_MY_TOTAL_EVENT_XP_CHANGE: {
            return { ...state, totalEventXP: action.payload.xp };
        }

        case a.WS_ENGAGEMENT_MY_TOTAL_XP_CHANGE: {
            return { ...state, totalXP: action.payload.xp };
        }

        case a.WS_ENGAGEMENT_MY_EVENT_LEVEL_CHANGE: {
            let level = action.payload.level
            let currentLevel = state.levelThresholds.find(t => t.level === level)
            let nextLevel = state.levelThresholds.find(t => t.level === level+1)
            let min = currentLevel.threshold
            let max = nextLevel ? nextLevel.threshold : undefined
            return { ...state, level: {level, min, max } };
        }

        case a.WS_EVENT_ACHIEVEMENT_UNLOCKED: {
            return { ...state, achievements: action.payload };
        }

        case a.WS_ENGAGEMENT_LEADERBOARD_CHANGE: {
            let updatedLeaderBoard = state.leaderboard
            let updatedUser = action.payload.userID
            updatedLeaderBoard[updatedUser] = { position: action.payload.position, xp: action.payload.xp }

            return { ...state, leaderboard: updatedLeaderBoard };
        }

        case a.WS_OWN_POSITION_CHANGE: {
            return { ...state, myPosition: action.payload.position };
        }

        case a.ENGAGEMENT_REMOVE_PERSON_FROM_LEADERBOARD: {
            let clonedState = cloneDeep(state)
            const userToRemove = action.payload.userID
            const myChatID = action.payload.myChatID
            const leaderboard = clonedState.leaderboard
            const newLeaderboard = omit(leaderboard, [userToRemove])
            const eventXP = userToRemove === myChatID ? null : state.totalEventXP
            const level = userToRemove === myChatID ? 0 : state.level
            return { ...state, leaderboard: newLeaderboard, totalEventXP: eventXP, level };
        }

        default:
            return state;
    }
};
export default engagement;
