import { IconArrowRight } from "components/icons";
import { LivetoLoader } from "components/icons";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const SaveRoomModal = props => {
    const { showSaveModal, setShowSaveModal, saveLoading, saveSuccess, showSaveStatus, redirectUrl, saveDetail } = props;

    function close() {
        setShowSaveModal(false);
    }

    const [showLoader, setShowLoader] = useState(false);
    const [showStatus, setShowStatus] = useState(false);

    useEffect(() => {
        setShowLoader(saveLoading);
        setShowStatus(!saveLoading && showSaveStatus ? true : false)
    }, [saveLoading])// eslint-disable-line react-hooks/exhaustive-deps

    return <Modal centered show={showSaveModal} backdrop={saveSuccess ? "static" : true} onHide={close} contentClassName="save-modal" backdropClassName="modalbg">
        <Modal.Body>
            {showLoader && <>
                <div className="message">
                    <LivetoLoader />
                </div>
                <div className="message-options">
                    <div className="option transparent center">Loading...</div>
                </div>
            </>}
            {showStatus && <SaveResults saveSuccess={saveSuccess} redirectUrl={redirectUrl} close={close} saveDetail={saveDetail} />}
        </Modal.Body>
    </Modal>
}

export default SaveRoomModal

const SaveResults = ({ saveSuccess, redirectUrl, close, saveDetail }) => {
    return <>
        {saveSuccess && <SaveSuccess redirectUrl={redirectUrl} close={close} />}
        {!saveSuccess && <SaveFailed close={close} saveDetail={saveDetail} />}
    </>
}

const SaveSuccess = ({ redirectUrl, close }) => {
    return <>
        <div className="message success">
            Save success!
        </div>
        <div className="message-options">
            <button className="option success">
                <div className="option-overlay success"></div>
                <NavLink to={redirectUrl}>
                    <span className="option-content">Continue to event</span>
                    <IconArrowRight />
                </NavLink>
            </button>
            <button className="option success" onClick={() => close()}>
                <div className="option-overlay success"></div>
                <span className="option-content">Continue editing</span>
                <IconArrowRight className="option-content" />
            </button>
        </div>

    </>
}

const SaveFailed = ({ close, saveDetail }) => {
    return <>
        <div className="message failed">
            <h3>Save failed</h3>
            <p>{saveDetail}</p>
        </div>
        <div className="message-options">
            <div className="option failure">
                <div className="option-overlay failure"></div>
                <span className="option-content">Support</span>
                <span className="option-content">+358449016818</span>
            </div>
            <div className="option transparent" onClick={() => close()}>Ignore this and continue</div>
        </div>
    </>
}