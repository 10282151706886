import * as a from 'actiontypes'
import { removeNotification } from 'middlewares/websocket'

export const sendAlert = (myChatID, message = {}, dispatch, settings = {}) => {
    dispatch({ type: a.WS_NEW_NOTIFICATION, payload: message.notification })
    //checkSettings(dispatch, settings, message.notification)
    if (message.notification.type === 'invite') {
        const invite = message.notification.data
        invite.notification_id = message.notification.id
        if (myChatID === invite.inviter_id) {
            if (invite.status === 'cancelled') {
                dispatch({ type: a.ADD_ALERT, payload: { variant: 'success', type: a.CANCEL_OUTGOING_INVITE, data: invite, setting: 'other' } })
                dispatch({ type: a.HANDLE_INVITE_RESPONSE, payload: { invite, isMine: true } })
            } else if (invite.status === 'accepted' || invite.status === 'rejected') {
                dispatch({ type: a.ADD_ALERT, payload: { variant: 'info', type: a.OUTGOING_INVITE_RESPONSE, data: invite, setting: 'invite' } })
                dispatch({ type: a.HANDLE_INVITE_RESPONSE, payload: { invite, isMine: true } })
            } else {
                dispatch({ type: a.ADD_ALERT, payload: { variant: 'success', type: 'OUTGOING_INVITE', data: invite, setting: 'other' } })
            }
        } else {
            if (invite.status === 'cancelled') {
                dispatch({ type: a.HANDLE_INVITE_RESPONSE, payload: { invite, isMine: false } })
                dispatch({ type: a.ADD_ALERT, payload: { variant: 'info', type: a.INCOMING_INVITE_RESPONSE, data: invite, setting: 'invite' } })
            } else if (invite.status === 'pending') {
                dispatch({ type: a.ADD_ALERT, payload: { variant: 'info', type: a.INCOMING_INVITE_RESPONSE, data: invite, setting: 'invite' } })
            }
        }
    } else if (message.notification.type === 'event_reminder') {
        const slot = message.notification.data
        dispatch({ type: a.ADD_ALERT, payload: { variant: 'info', type: 'CALENDAR_EVENT_IS_STARTING', data: { ...slot, slot_type: 'event_timeslot' }, setting: 'reminder' } })
    } else if (message.notification.type === 'meeting_reminder') {
        const slot = message.notification.data
        dispatch({ type: a.ADD_ALERT, payload: { variant: 'info', type: 'CALENDAR_EVENT_IS_STARTING', data: { ...slot, slot_type: 'meeting' }, setting: 'reminder' } })
    }
}

const checkSettings = (dispatch, settings = {}, notification = {}) => {
    const { type } = notification
    const { reminder = true } = settings
    const isReminder = type.includes('reminder')
    if (isReminder) dispatchOrRemove(reminder, notification, dispatch)
    else dispatchOrRemove(settings[type], notification, dispatch)
}

const dispatchOrRemove = (condition, notification, dispatch) => {
    if (condition) dispatch({ type: a.WS_NEW_NOTIFICATION, payload: notification })
    else removeNotification(notification.id)
}