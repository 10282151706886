import { cloneDeep } from "lodash";
import * as a from "actiontypes";
import { removeNotification } from "middlewares/websocket";

export const notificationsInitial = {
    notifications: [],
    unread: false
};

const sortByTimestamp = (list) => {
    return list.sort((a, b) => (a.ts < b.ts) ? 1 : -1)
}

const notifications = (state = notificationsInitial, action) => {
    switch (action.type) {
        case a.WS_RESET_NOTIFICATIONS:
            return notificationsInitial;

        case a.WS_NEW_NOTIFICATION: {
            const notification = action.payload
            if (notification.type === 'invite') {
                const inviteTypeNotifications = state.notifications.filter(n => n.type === 'invite')
                const slot = notification.data.slot ? notification.data.slot : notification.data
                const inviterID = notification.data.inviter_id || slot.inviter_id
                const filteredBySlotID = inviteTypeNotifications.filter(n => (n.data.slot?.id === slot.id || n.data.id === slot.id))
                const notificationToRemove = filteredBySlotID.find(n => (n.data.inviter_id === inviterID))
                if (notificationToRemove) {
                    removeNotification(notificationToRemove.id)
                }
            }
            const newNotifications = cloneDeep(state.notifications);
            newNotifications.push(notification)
            const sortedByTimestamp = sortByTimestamp(newNotifications)
            return { notifications: sortedByTimestamp, unread: true };
        }
        case a.WS_GET_NOTIFICATIONS: {
            const sortedByTimestamp = sortByTimestamp(action.payload)
            const unread = sortedByTimestamp.filter(notification => !notification.read).length > 0 ? true : false
            return { notifications: sortedByTimestamp, unread };
        }

        case a.WS_REMOVE_NOTIFICATION: {
            if (state.notifications.length > 0) {
                const notificationID = action.payload
                const clonedNotifications = cloneDeep(state.notifications)
                const filteredNotifications = clonedNotifications.filter(notification => notification.id !== notificationID)
                return { ...state, notifications: filteredNotifications }
            } else {
                return state
            }
        }

        case a.CLEAR_NOTIFICATION_STATE: {
            return { unread: false, notifications: [] }
        }

        case a.WS_MARK_NOTIFICATION_AS_READ: {
            const notificationID = action.payload
            const readNotification = state.notifications.find(notification => notification.id === notificationID)
            if (readNotification) {
                readNotification.read = true
                const filteredNotifications = state.notifications.filter(notification => notification.id !== notificationID)
                filteredNotifications.push(readNotification)
                const sortedNotifications = sortByTimestamp(filteredNotifications)

                return { unread: false, notifications: sortedNotifications }
            } else {
                return state
            }

        }

        case a.HANDLE_UNREAD: {
            return { ...state, unread: action.payload }
        }

        default:
            return state;
    }
};
export default notifications;
