import { Localized } from "@fluent/react"
import { useEffect, useState } from "react"
import { IconTrash } from 'components/icons';
import { connect } from "react-redux"
import * as a from 'actiontypes'
import DatePicker, { registerLocale } from 'react-datepicker'
import moment from 'moment'
import fi from 'date-fns/locale/fi'
import se from 'date-fns/locale/sv'
import en from 'date-fns/locale/en-GB'
import { handleCalendarVisibility, createTimeSlot, deleteTimeSlot } from '../../../schedule/apiCalls';
import { setHours, setMinutes } from "date-fns";
import { useDispatch } from "react-redux";
import { defineDefaultEndTime, disableButton, getMinTime } from "../helpers/settingsHelpers";
registerLocale('fi', fi)
registerLocale('se', se)
registerLocale('en', en)

const mapStateToProps = state => ({
    calendar_visibility: state.schedule.calendar.visibility,
    slots: state.schedule.calendar.slots.filter(s => s.status === 'available'),
    event_slug: state.event.slug,
    language: state.user.language,
    displayType: state.displayType
})

const mapDispatchToProps = dispatch => ({
    reconnectWebsocket: () => dispatch({ type: a.WS_RECONNECT }),
    calendarVisibilityChange: (value) => dispatch({ type: a.SET_CALENDAR_VISIBILITY, payload: value })
})

const AvailableSlot = (props) => {
    const { start_time, end_time, id, deleteSlot, displayType } = props
    return (
        <div className="input-row">
            <div className="time-row">
                {displayType !== "mobile" && <p className="info-label time"><Localized id="settings-calendar-available-slots-from">From</Localized></p>}
                <p>{moment(start_time).format('DD.MM.yyyy HH:mm')}</p>
            </div>
            {displayType === "mobile" ? ` - ` : ''}

            <div className="time-row">
                {displayType !== "mobile" && <p className="info-label time"><Localized id="settings-calendar-available-slots-to">Until</Localized></p>}
                <p>{moment(end_time).format('DD.MM.yyyy HH:mm')}</p>
            </div>
            <button className="content-button" disabled={!start_time || !end_time ? true : false} onClick={() => deleteSlot(id)}>
                {displayType === "mobile" ? <IconTrash /> : <Localized id="settings-calendar-delete-button">Remove</Localized>}
            </button>
        </div>
    )
}

const TimeSlotSelectComponent = (props) => {
    const { language, fromDateTime, toDateTime, setFromDateTime, setToDateTime, save, slots = [] } = props
    const [bookable, setBookable] = useState({ isDisabled: true, component: '' })

    useEffect(() => {
        if (!fromDateTime || !toDateTime) setBookable({ isDisabled: true, component: '' })
        else if (moment(toDateTime).isSameOrBefore(moment(fromDateTime))) setBookable({ isDisabled: true, component: '' })
        else if (moment(fromDateTime).isBefore(moment())) setBookable({ isDisabled: true, component: '' })
        else if (disableButton(fromDateTime, toDateTime, slots)) setBookable({
            isDisabled: true,
            component: <div className="warning-msg"><Localized id="settings-calendar-warning">Bookable slots can't overlap</Localized></div>
        })
        else return setBookable(false)
    }, [fromDateTime, toDateTime])

    const handleChange = (key, date) => {
        if (key === 'start_time') {
            setFromDateTime(date)
            setToDateTime(null)
        } else {
            setToDateTime(date)
        }
    }

    return (
        <div className="slot-datepicker-container">
            <div className="input-row datepicker">
                <div className="time-row">
                    <p className="info-label time"><Localized id="settings-calendar-available-slots-from">From</Localized></p>
                    <DatePicker
                        calendarClassName="calendar-settings"
                        selected={fromDateTime}
                        onChange={date => handleChange('start_time', date)}
                        minDate={new Date()}
                        minTime={getMinTime(fromDateTime).min}
                        maxTime={getMinTime(fromDateTime).max}
                        timeIntervals={60}
                        showDisabledMonthNavigation
                        showTimeSelect
                        locale={language}
                        timeFormat="p"
                        dateFormat="Pp"
                        popperPlacement="bottom-start"
                        popperModifiers={{
                            flip: {
                                enabled: false
                            }
                        }}
                    />
                </div>

                <div className="time-row">
                    <p className="info-label time"><Localized id="settings-calendar-available-slots-to">To</Localized></p>
                    <DatePicker
                        calendarClassName="calendar-settings"
                        selected={toDateTime ? toDateTime : fromDateTime}
                        onChange={date => handleChange('end_time', date)}
                        minDate={fromDateTime}
                        minTime={setHours(setMinutes(new Date(), moment(fromDateTime).get('minutes')), moment(fromDateTime).get('hours'))}
                        maxTime={setHours(setMinutes(new Date(), 30), 23)}
                        timeIntervals={60}
                        showDisabledMonthNavigation
                        showTimeSelect
                        locale={language}
                        timeFormat="p"
                        dateFormat="Pp"
                        disabled={!fromDateTime ? true : false}
                        popperPlacement="bottom-start"
                        popperModifiers={{
                            flip: {
                                enabled: false
                            }
                        }}
                    />
                </div>
                <button className="content-button" disabled={bookable.isDisabled} onClick={save}><Localized id="settings-calendar-add-button">Add</Localized></button>
            </div>
            {bookable.isDisabled && bookable.component}
        </div>
    )
}

const CalendarSettings = ({
    calendar_visibility,
    event_slug,
    setSuccess,
    reconnectWebsocket,
    setShowSuccess,
    calendarVisibilityChange,
    language,
    slots = [],
    setShowLoading,
    displayType
}) => {
    const [fromDateTime, setFromDateTime] = useState(null)
    const [toDateTime, setToDateTime] = useState(null)

    const dispatch = useDispatch()

    const submitCalendarVisibility = async (calendarVisibility) => {
        setShowLoading(true)
        const [result, status, error] = await handleCalendarVisibility(calendarVisibility, event_slug)
        if (status === 200) {
            setSuccess('success')
            calendarVisibilityChange(result.visibility)
            reconnectWebsocket();
        }
        if (error || status !== 200) {
            setSuccess('failure')
        }
        setShowLoading(false);
        setShowSuccess(true);
    }

    const deleteSlot = async (id) => {
        setShowLoading(true)
        const slotToDelete = slots.find(s => s.id === id)
        const [deleteStatus, deleteError] = await deleteTimeSlot(slotToDelete, dispatch, event_slug)
        setShowLoading(false)
        if (deleteError || deleteStatus !== 204) {
            setSuccess('failure')
            setShowSuccess(true)
        } else {
            setSuccess('success')
            setShowSuccess(true)
        }
    }

    const createSlot = async () => {
        const start_time = moment(fromDateTime).format("YYYY-MM-DDTHH:mm:ss")
        const end_time = moment(toDateTime).format("YYYY-MM-DDTHH:mm:ss")
        const slotData = { start_time, end_time }
        setShowLoading(true)
        const [createStatus, createError] = await createTimeSlot(slotData, dispatch, event_slug)
        setShowLoading(false)
        if (createError || createStatus !== 201) {
            setSuccess('failure')
            setShowSuccess(true)
        } else {
            setSuccess('success')
            setShowSuccess(true)
        }
        setFromDateTime(null)
        setToDateTime(null)
    }

    return (
        <div className="settings-inner-container">
            <div className="heading-row">
                <h5><Localized id="settings-calendar-header">Calendar settings</Localized></h5>
            </div>
            <div className="input-row-wrapper">
                <div className="input-row">
                    <p className="info-label">
                        <Localized id="userprofile-calendar-visibility-label">
                            {'Personal calendar visibility'}
                        </Localized>
                    </p>
                    <select defaultValue={calendar_visibility} className="edit-select" onChange={e => submitCalendarVisibility(e.target.value)}>
                        <Localized id="userprofile-calendar-visibility-public-option">
                            <option value="public">
                                Public
                            </option>
                        </Localized>
                        <Localized id="userprofile-calendar-visibility-private-option">
                            <option value="private">
                                Private
                            </option>
                        </Localized>

                    </select>
                </div>
                <div className="settings-calendar-timeslot-form">
                    <div className="add-new-slot-header"><Localized id="settings-calendar-add-new-slot-title">Add new</Localized></div>
                    <TimeSlotSelectComponent
                        language={language}
                        setFromDateTime={setFromDateTime}
                        setToDateTime={setToDateTime}
                        fromDateTime={fromDateTime}
                        toDateTime={toDateTime}
                        save={createSlot}
                        slots={slots}
                    />
                </div>
                {slots.length > 0 && <>
                    <div className="subheading-row">
                        <h5><Localized id="settings-calendar-available-slots-header">Available timeslots</Localized></h5>
                    </div>
                    {slots.map(slot => <AvailableSlot key={`bookable_slot_${slot.id}`} id={slot.id} start_time={slot.start_time} end_time={slot.end_time} deleteSlot={deleteSlot} displayType={displayType} />)}
                </>}
            </div>

        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSettings)