import React, { useEffect, useState } from "react";
import { SketchPicker } from 'react-color';
import useDebounce from "../hooks";
import '../styles/widgets.scss';
const fallbackBorder = {
  border: '0px solid rgba(0,0,0,0)',
  borderRadius: '0px'
}
export default function BorderWidget(props) {
  const { identifiers, editProperties, properties, editLocks, editorDispatch, room } = props;
  const property = 'border';
  const value = properties[property] ? properties[property] : fallbackBorder;
  const { border, borderRadius } = value;

  const [initialWidth, initialStyle, initialColor] = border.split(' ');
  const splittedValue = initialColor.replace('rgba(', '').replace(')', '').split(',');
  const rgbaColor = {
    r: splittedValue[0],
    g: splittedValue[1],
    b: splittedValue[2],
    a: splittedValue[3],
  }

  const [width, setWidth] = useState(initialWidth.replace('px', ''));
  const [style, setStyle] = useState(initialStyle);
  const [color, setColor] = useState(rgbaColor);
  const [borderColor, setBorderColor] = useState(null);
  const [radius, setRadius] = useState(borderRadius.replace('px', ''));

  const borderStyles = ['dotted', 'dashed', 'solid', 'double', 'groove', 'ridge', 'inset', 'outset', 'none'];

  const debouncedColor = useDebounce(borderColor, 500)

  useEffect(() => {
    if (debouncedColor) {
      const { r, g, b, a } = borderColor;
      const cssColor = `rgba(${r},${g},${b},${a})`;
      const newBorder = `${width}px ${style} ${cssColor}`;
      const borderObject = {
        border: newBorder,
        borderRadius
      }
      editProperties(identifiers, property, borderObject, editorDispatch, room, editLocks);
    }
  }, [debouncedColor])

  useEffect(() => {
    setBorderColor(null)
  }, [color])

  function borderSetter(editKey, newValue) {

    let newBorder = `${width}px ${style} ${color}`
    let newRadius = `${radius}px`;

    switch (editKey) {
      case 'width': newBorder = `${newValue}px ${style} ${color}`; break;
      case 'style': newBorder = `${width}px ${newValue} ${color}`; break;
      case 'color': {
        const { r, g, b, a } = newValue;
        const cssColor = `rgba(${r},${g},${b},${a})`;
        newBorder = `${width}px ${style} ${cssColor}`;
        break;
      }
      case 'radius': newRadius = `${newValue}px`; break;
      default: break;
    }
    const borderObject = {
      border: newBorder,
      borderRadius: newRadius,
    }
    editProperties(identifiers, property, borderObject, editorDispatch, room, editLocks);
  }

  useEffect(() => {
    const { border, borderRadius } = value;
    const [width, style, color] = border.split(' ');
    setWidth(width.replace('px', ''))
    setStyle(style);
    setColor(color);
    setRadius(borderRadius.replace('px', ''));
  }, [value, identifiers, editProperties, property])



  const [showContent, setShowContent] = useState('border');

  return (<div className="tool-inner-container">

    <div className="tools">
      BORDER SETTINGS
      <div className="buttons">
        <button className={"editor-menu-button" + (showContent === 'border' ? ' active' : '')} onClick={e => setShowContent('border')}>Border</button>
        <button className={"editor-menu-button" + (showContent === 'borderRadius' ? ' active' : '')} onClick={e => setShowContent('borderRadius')}>Radius</button>
      </div>
    </div>

    {showContent === 'border' && <>

      <div className="tools">

        <div className="buttons">
          BORDER WIDTH
          <input type="text" className="editor-menu-input" value={width} onChange={(e) => borderSetter('width', e.target.value)}></input>
          <span>px</span>
        </div>
      </div>
      <div className="tools">
        <div className="buttons border-width">
          <button className={"editor-menu-button" + (width === '0' ? ' active' : '')} onClick={() => borderSetter('width', 0)}>0px</button>
          <button className={"editor-menu-button" + (width === '2' ? ' active' : '')} onClick={() => borderSetter('width', 2)}>2px</button>
          <button className={"editor-menu-button" + (width === '6' ? ' active' : '')} onClick={() => borderSetter('width', 6)}>6px</button>
          <button className={"editor-menu-button" + (width === '10' ? ' active' : '')} onClick={() => borderSetter('width', 10)}>10px</button>
          <button className={"editor-menu-button" + (width === '15' ? ' active' : '')} onClick={() => borderSetter('width', 15)}>15px</button>
        </div>
      </div>
      <div className="tools">
        BORDER STYLE
        <div className="buttons border-style">
          {borderStyles.map((borderStyle, i) => {
            if (borderStyle === 'none') {
              return (
                <div key={`borderStyle_${i}`} onClick={() => borderSetter('style', borderStyle)} className="widget-noborder-box">
                  <div className="line1"></div>
                  <div className="line2"></div>
                </div>
              )
            } else {
              return (
                <div onClick={() => borderSetter('style', borderStyle)} className={"widget-border-style-select" + (borderStyle === style ? ' active' : '')} style={borderStyle === style ?
                  { border: `6px ${borderStyle} green` } : { border: `6px ${borderStyle} #EE8E88` }
                }></div>
              )
            }
          })}
        </div>
      </div>
      <div className="tools">
        BORDER COLOR
        <SketchPicker color={borderColor || color} presetColors={[]} onChange={(e) => setBorderColor(e.rgb)} />
      </div>
    </>}
    {showContent === 'borderRadius' && <>
      <div className="tools">

        <div className="buttons">
          BORDER RADIUS
          <input type="text" className="editor-menu-input" value={radius} onChange={(e) => borderSetter('radius', e.target.value)}></input>
          <span>px</span>
        </div>
      </div>

      <div className="tools">
        <div className="buttons">
          <button className={"editor-menu-button" + (radius === '0' ? ' active' : '')} onClick={() => borderSetter('radius', 0)}>0px</button>
          <button className={"editor-menu-button" + (radius === '5' ? ' active' : '')} onClick={() => borderSetter('radius', 5)}>5px</button>
          <button className={"editor-menu-button" + (radius === '10' ? ' active' : '')} onClick={() => borderSetter('radius', 10)}>10px</button>
          <button className={"editor-menu-button" + (radius === '15' ? ' active' : '')} onClick={() => borderSetter('radius', 15)}>15px</button>
          <button className={"editor-menu-button" + (radius === '20' ? ' active' : '')} onClick={() => borderSetter('radius', 20)}>20px</button>
        </div>
      </div>


    </>}
  </div>);
};