/** @format */

import "./loginForm.scss";
import { useRef, useState } from "react";
import { CUID_COOKIE, AUTH_COOKIE, AUTH_COOKIE_TIMESTAMP, HOST } from "constants.js";
import { connect } from "react-redux";
import { Localized } from "@fluent/react";
import defaultHeroImage from "../static/img/hero-image.jpg";
import { createCookie } from "util/cookies";
import { IconUsers } from "components/icons";
import ExtraFields from "./extraFields";
import { useCurrentLanguage } from "util/localization";
import { useAccessKey } from "containers/VirtualEvent/editorView/apiCalls";
import { ExpandableInfoContainer, RegisterContainer } from "./components";
import { useViewportSize } from "@mantine/hooks";

const mapDispatchToProps = dispatch => ({
	userFetched: user => dispatch({ type: "USER_FETCHED", payload: user }),
	eventFetched: event => dispatch({ type: "EVENT_FETCHED", payload: event }),
	setGuestUserType: type => dispatch({ type: "SET_GUEST_USER", payload: type }),
});

const mapStateToProps = state => ({
	email: state.user.email,
	event: state.event,
});

function LoginForm(props) {
	const { event = {}, event_slug, query } = props;
	const {
		slug,
		require_guest_ticket_code,
		event_hero_image,
		event_main_image,
		allow_guest_login,
		guest_extra_fields_definition = [],
		name,
	} = event;
	const guest_extra_form = guest_extra_fields_definition.length > 0;
	const [username, setUsername] = useState("");
	const [ticketCode, setTicketCode] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [failed, setFailed] = useState(null);
	const [guestLoading, setGuestLoading] = useState(false);
	const [guestFailed, setGuestFailed] = useState(null);
	const [ticketFailed, setTicketFailed] = useState(null);
	const [formView, setFormView] = useState("");
	const language = useCurrentLanguage();
	const accessKey = useAccessKey();

	const loginContainerStyle = allow_guest_login && guest_extra_form ? { width: "200%" } : {};

	function loginEnter(e) {
		if (e.keyCode === 13) {
			login();
		}
	}
	function loginGuestEnter(e) {
		const trimmedUsername = username.trim();
		const extraFormNeeded = allow_guest_login && guest_extra_form;
		if (e.keyCode === 13 && trimmedUsername) {
			// Enter key
			if (extraFormNeeded) {
				if (require_guest_ticket_code) {
					if (ticketCode) setFormView("extra-form");
				} else setFormView("extra-form");
			} else {
				guestLogin();
			}
		}
	}

	async function login() {
		setLoading(true);
		try {
			setFailed(false);
			const response = await fetch(`${HOST}api/login/`, {
				headers: { "Content-Type": "Application/json" },
				body: JSON.stringify({ username: email, password: password }),
				method: "POST",
			});
			const json = await response.json();
			if (response.ok) {
				props.setGuestUserType(false);
				createCookie(CUID_COOKIE, json.chat_user_id, 1);
				createCookie(AUTH_COOKIE, json.token, 1);
				createCookie(AUTH_COOKIE_TIMESTAMP, Date.now(), 1);
				window.location.reload();
			} else {
				setFailed("Authentication failed");
			}
		} catch (error) {
			setFailed("Something went wrong");
		} finally {
			setLoading(false);
		}
	}

	async function guestLogin(extraFields) {
		setGuestLoading(true);
		try {
			setGuestFailed(false);
			setTicketFailed(false);
			const body = { username, event: slug };
			if (event.require_guest_ticket_code) {
				body.ticket_code = ticketCode;
			}
			if (extraFields) {
				body.extra_fields = extraFields;
			}
			const response = await fetch(`${HOST}api/login/guest/`, {
				headers: { "Content-Type": "Application/json" },
				body: JSON.stringify(body),
				method: "POST",
			});
			const json = await response.json();
			if (response.ok) {
				props.setGuestUserType(true);
				createCookie(CUID_COOKIE, json.chat_user_id, 1);
				createCookie(AUTH_COOKIE, json.token, 1);
				createCookie(AUTH_COOKIE_TIMESTAMP, Date.now(), 1);
				window.location.reload();
			} else {
				setGuestFailed(true);
				setFormView("");
				if (json.ticket_code) setTicketFailed(true);
			}
		} catch (error) {
			setGuestFailed("Something went wrong");
			setFormView("");
		} finally {
			setGuestLoading(false);
		}
	}

	function handleUserName(e) {
		setUsername(e.target.value);
	}
	//const currentPage = window.location.href;

	const disableGuestButton = () => {
		const trimmedUsername = username.trim();
		const trimmedTicketCode = ticketCode.trim();
		if (require_guest_ticket_code) return !trimmedUsername || !trimmedTicketCode;
		else return !trimmedUsername;
	};

	const signup_params = "?redirect=" + encodeURIComponent(window.location.href);

	const registerRef = useRef(null);
	const { width } = useViewportSize();
	const isMobile = width <= 600;
	return (
		<div
			id={`login_form_${event_slug}`}
			className="authenticate-screen"
			style={{ backgroundImage: `url("${event_hero_image ?? defaultHeroImage}")` }}
		>
			<div className="event-info">
				<div className="event-image" style={{ backgroundImage: `url("${event_main_image}")` }}></div>
				<div className="info">
					<div className="event-name"> {name}</div>
					<div className="sign-in-tooltip"></div>
				</div>
			</div>
			<div className="auth">
				<div className={`login-container ${formView}`} style={loginContainerStyle}>
					<div className="login-view">
						<div className="auth-form">
							<div className="auth-form-info">
								<img
									alt="liveto-logo"
									src={"https://liveto.io/wp-content/themes/theme/assets/img/liveto-logo-big.png"}
									className="liveto-logo"
								/>
								<div className="event-type">{"Virtual event platform"}</div>

								<div className="auth-description">
									<Localized id="auth-login-info" />
								</div>
							</div>

							<div className="auth-form-actions">
								<div className="auth-group">
									<label className="auth-label">
										<Localized id="auth-label-email">{"Email"}</Localized>
									</label>

									<Localized id="auth-label-email" attrs={{ placeholder: true }}>
										<input
											className="auth-input"
											onKeyDown={e => loginEnter(e)}
											type="email"
											onChange={e => setEmail(e.target.value)}
											placeholder="email"
										/>
									</Localized>
								</div>

								<div className="auth-group">
									<Localized id="auth-label-password">
										<label className="auth-label">Password</label>
									</Localized>
									<Localized id="auth-label-password" attrs={{ placeholder: true }}>
										<input
											className="auth-input"
											onKeyDown={e => loginEnter(e)}
											type="password"
											placeholder="password"
											onChange={e => setPassword(e.target.value)}
										/>
									</Localized>
									<div className="additional-links-container">
										<small>
											{isMobile && (
												<button
													onClick={() =>
														registerRef.current.scrollIntoView({ behavior: "smooth" })
													}
												>
													<Localized id="auth-register-link">Register</Localized>
												</button>
											)}
										</small>
										<small>
											{/* TODO enviromental links */}
											<a
												href="https://events.liveto.io/password/reset"
												target="_blank"
												rel="noreferrer"
											>
												<Localized id="auth-forgot-password">Unohtuiko salasana?</Localized>
											</a>
										</small>
									</div>
								</div>

								<button
									className="auth-button"
									disabled={!email || !password}
									onClick={() => login()}
									data-cy="authenticate-button"
								>
									{loading ? (
										<Localized id="auth-button-loading">Loading</Localized>
									) : (
										<Localized id="auth-button-authenticate">Authenticate</Localized>
									)}
								</button>
							</div>

							{failed ? <Localized id="auth-status-failed">Authentication failed</Localized> : ""}
						</div>

						{allow_guest_login && (
							<div className="guest-signin">
								<div className="guest-signin-info">
									{guestLoading ? (
										<Localized id="auth-button-loading">Loading</Localized>
									) : (
										<Localized id="auth-guest-heading">
											<h1 className="auth-type-guest">Vieraskirjautuminen</h1>
										</Localized>
									)}

									<div className="auth-description">
										<Localized id="auth-guestlogin-info" />
									</div>
								</div>

								<div className="social-signins">
									{/*
                                                <a href={`${GOOGLE_SIGNIN_PATH}${currentPage}`} className="signin-button google"><IconGoogle size="30" className="icon" /> Google</a>
                        <a href={`${FACEBOOK_SIGNIN_PATH}${currentPage}`} className="signin-button facebook"><IconFacebook size="30" className="icon" />Facebook</a>
                        <a href={`${TWITTER_SIGNIN_PATH}${currentPage}`} className="signin-button twitter"><IconTwitter size="30" className="icon" />Twitter</a>
                        <a href={`${LINKEDIN_SIGNIN_PATH}${currentPage}`} className="signin-button linkedin"><IconLinkedIn size="30" className="icon" />Linkedin</a>
                        */}
									<Localized id="auth-guest-input" attrs={{ placeholder: true }}>
										<input
											onChange={e => handleUserName(e)}
											onKeyDown={e => loginGuestEnter(e)}
											value={username}
											className="guest-input"
										/>
									</Localized>
									{event.require_guest_ticket_code && (
										<Localized id="auth-ticket-code-input" attrs={{ placeholder: true }}>
											<input
												onChange={e => setTicketCode(e.target.value)}
												onKeyDown={e => loginGuestEnter(e)}
												value={ticketCode}
												className="guest-input"
											/>
										</Localized>
									)}
									{guest_extra_fields_definition.length < 1 ? (
										<Localized id="auth-guest-button">
											<button
												onClick={() => guestLogin()}
												className="signin-button guest"
												disabled={disableGuestButton()}
											>
												Tunnistaudu vieraana
											</button>
										</Localized>
									) : (
										<Localized id="auth-guest-next-button">
											<button
												onClick={() => setFormView("extra-form")}
												className="signin-button guest"
												disabled={disableGuestButton()}
											>
												Seuraava
											</button>
										</Localized>
									)}
									{guestFailed && (
										<Localized id="auth-status-failed">Authentication failed</Localized>
									)}
									{ticketFailed && (
										<Localized id="auth-status-ticket-failed">: ticket not found</Localized>
									)}
								</div>
							</div>
						)}

						<RegisterContainer signup_params={signup_params} registerRef={registerRef} />
					</div>
					{allow_guest_login && guest_extra_form && (
						<ExtraFields
							setFormView={setFormView}
							guestLogin={guestLogin}
							extraFields={guest_extra_fields_definition}
							language={language}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
