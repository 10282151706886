import { IconLightbulb } from "components/icons"

export const ToggleSwitchSimple = ({name, set, darkMode}) => {
  const livetoRed = "#c4281a"
  const livetoTeal = "#00a3a8"
    return (
        <div className="darkmode-checkbox-container" style={{padding:"0 0.5rem", display:"flex"}}>
          <input
            type="checkbox"
            className="darkmode-checkbox"
            name={name}
            id={name.toLowerCase().replace(" ", "-")}
            onClick={() => set(mode => !mode)}
            checked={darkMode}
          />
          <IconLightbulb size="20" color={darkMode ? livetoTeal : livetoRed}/>
        </div>
      );
}

export const ToggleSwitchFancy = ({name, set, darkMode}) => {
  return (
      <div className={`toggle-switch ${darkMode ? 'dark' : ''}`}>
        <input
          type="checkbox"
          className={`toggle-switch-checkbox`}
          name={name}
          id={name.toLowerCase().replace(" ", "-")}
          onClick={() => set(mode => !mode)}
        />
        <label className="toggle-switch-label" htmlFor={name.toLowerCase().replace(" ", "-")}>
          <span className="toggle-switch-inner" > 
            <IconLightbulb size="24" color="black"/>
            <IconLightbulb size="24" color="white" />
          </span>
          <span className="toggle-switch-switch" />
        </label>
      </div>
    );
}