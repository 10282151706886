import React, { useEffect, useState } from "react";
import { ChromePicker } from 'react-color';
import useDebounce from "../hooks";
import '../styles/widgets.scss';

const fallbackSeparator = { size: '100%', orientation: 'horizontal', color: 'rgba(0,0,0,1)', thickness: '1px' };

const SeparatorWidget = (props) => {
    const { identifiers, editContent, content, editLocks, editorDispatch, room } = props;
    const { size, orientation, color, thickness } = content;
    const [separatorColor, setSeparatorColor] = useState(null)

    const debouncedColor = useDebounce(separatorColor, 500)

    useEffect(() => {
        if (debouncedColor) {
            const { r, g, b, a } = separatorColor;
            const cssColor = `rgba(${r},${g},${b},${a})`;
            const newContent = { ...content, color: cssColor }
            editContent(identifiers, newContent, editorDispatch, room, editLocks);
        }
    }, [debouncedColor])

    useEffect(() => {
        if (content === '') {
            editContent(identifiers, fallbackSeparator, editorDispatch, room, editLocks);
        }
    }, [])

    useEffect(() => {
        setSeparatorColor(null)
    }, [color])

    const updateContent = (name, value) => {
        let newContent;
        switch (name) {
            case 'size': {
                newContent = { ...content, size: value }
                break;
            }
            case 'orientation': {
                newContent = { ...content, orientation: value }
                break;
            }
            case 'thickness': {
                newContent = { ...content, thickness: value }
                break;
            }
            default: break;
        }
        editContent(identifiers, newContent, editorDispatch, room, editLocks);
    }
    return (
        <div className="tool-inner-container">
            SEPARATOR
            <div className="tools">
                <div>
                    Orientation
                    <div className="buttons orientation">
                        <button className={"editor-menu-button" + (orientation === 'horizontal' ? ' active' : '')} onClick={(e) => updateContent('orientation', 'horizontal')}>Horizontal</button>
                        <button className={"editor-menu-button" + (orientation === 'vertical' ? ' active' : '')} onClick={(e) => updateContent('orientation', 'vertical')}>Vertical</button>
                    </div>
                </div>
                <div>
                    Length
                    <div className="buttons">
                        <button className={"editor-menu-button" + (size === '25%' ? ' active' : '')} onClick={() => updateContent('size', '25%')}>25%</button>
                        <button className={"editor-menu-button" + (size === '50%' ? ' active' : '')} onClick={() => updateContent('size', '50%')}>50%</button>
                        <button className={"editor-menu-button" + (size === '90%' ? ' active' : '')} onClick={() => updateContent('size', '90%')}>90%</button>
                        <button className={"editor-menu-button" + (size === '100%' ? ' active' : '')} onClick={() => updateContent('size', '100%')}>100%</button>
                    </div>
                </div>
                <div>
                    Thickness
                    <div className="buttons">
                        <button className={"editor-menu-button" + (thickness === '1px' ? ' active' : '')} onClick={() => updateContent('thickness', '1px')}>1px</button>
                        <button className={"editor-menu-button" + (thickness === '2px' ? ' active' : '')} onClick={() => updateContent('thickness', '2px')}>2px</button>
                        <button className={"editor-menu-button" + (thickness === '4px' ? ' active' : '')} onClick={() => updateContent('thickness', '4px')}>4px</button>
                        <button className={"editor-menu-button" + (thickness === '8px' ? ' active' : '')} onClick={() => updateContent('thickness', '8px')}>8px</button>
                    </div>
                </div>
                <div>
                    Color
                    <ChromePicker color={separatorColor || color} presetColors={[]} onChange={(e) => setSeparatorColor(e.rgb)} />
                </div>
            </div>
        </div>
    )
}

export default SeparatorWidget