import { cloneDeep } from "lodash";
import { useEffect, useRef } from "react";
import { calculateY, COMPONENT_CONFIGS } from 'containers/VirtualEvent/constants';
import * as a from 'actiontypes'

export function editProperties(identifiers, property, value, editorDispatch, data, editLocks) {
  const { component, element } = identifiers;
  const room = cloneDeep(data);

  for (let view of editLocks) {
    const newComponent = room.components[view].find((c) => c.i === component)
    if (newComponent) {
      if (element) {
        const newElement = cloneDeep(newComponent.elements.find((e) => e.i === element))
        if (newElement) {
          const elemIndex = newComponent.elements.indexOf(newComponent.elements.find((e) => e.i === element), 0) // Getting element index
          newElement.properties[property] = value;
          const newElements = cloneDeep(newComponent.elements.filter(el => el.i !== element))
          //newElements.push(newElement)
          newElements.splice(elemIndex, 0, newElement) // Inserting updated element into it's original position
          newComponent.elements = newElements
          //newComponent.elements = newElements.sort((a, b) => parseInt(a.i.split('e')[1]) - parseInt(b.i.split('e')[1])) //sorting elements by id, sorry
        }
      } else {
        const updatedComponent = cloneDeep(room.components[view].find((c) => c.i === component))
        updatedComponent.properties[property] = value;
        const newComponents = cloneDeep(room.components[view].filter(c => c.i !== component))
        newComponents.push(updatedComponent)
        room.components[view] = newComponents.sort((a, b) => parseInt(a.i.split('c')[1]) - parseInt(b.i.split('c')[1]))
      }
    } else {
      room.styles[property] = value;
    }
  }
  editorDispatch({ type: "EDITOR_PROPERTY_CHANGE", payload: room });
  editorDispatch({ type: "PUSH_TO_HISTORY", payload: { room: room, label: { text: `Edited property: "${capitalizeFirstLetter(property)}"`, time: new Date().getTime() } } });
}

export function editActions(identifiers, action, value, editorDispatch, data, editLocks) {
  const { component, element } = identifiers;
  const room = cloneDeep(data);

  for (let view of editLocks) {
    const newComponent = room.components[view].find((c) => c.i === component);
    if (newComponent) {
      if (element) {
        const newElement = newComponent.elements.find((e) => e.i === element);
        if (newElement) newElement.actions[action] = value;
      } else {
        newComponent.actions[action] = value;
      }
    }
  }
  editorDispatch({ type: "EDITOR_ACTION_CHANGE", payload: room });
  editorDispatch({ type: "PUSH_TO_HISTORY", payload: { room: room, label: { text: `Edited action: "${capitalizeFirstLetter(action)}"`, time: new Date().getTime() } } });
}

export function editContent(identifiers, content, editorDispatch, data, editLocks) {
  const { component, element } = identifiers;
  const room = cloneDeep(data);
  for (let view of editLocks) {
    const newComponent = room.components[view].find((c) => c.i === component);
    if (newComponent) {
      if (element) {
        const newElement = newComponent.elements.find((e) => e.i === element);
        if (newElement) newElement.content = content;
      } else {
        newComponent.content = content;
      }
    }
  }
  editorDispatch({ type: "EDITOR_CONTENT_CHANGE", payload: room });
  editorDispatch({ type: "PUSH_TO_HISTORY", payload: { room: room, label: { text: "Edited content", time: new Date().getTime() } } });
}

export function editName(identifiers, name, editorDispatch, data, editLocks) {
  const { component, element } = identifiers;
  const room = cloneDeep(data);

  for (let view of editLocks) {
    const newComponent = room.components[view].find((c) => c.i === component);
    if (element) {
      const newElement = newComponent.elements.find((e) => e.i === element);
      newElement.name = name;
    } else if (newComponent) {
      newComponent.name = name;
    }
  }
  editorDispatch({ type: "EDITOR_NAME_CHANGE", payload: room });
  editorDispatch({ type: "PUSH_TO_HISTORY", payload: { room: room, label: { text: `Edited component's name: "${name}"`, time: new Date().getTime() } } });
}

export function roomWidgets(activeItem = {}, setActiveItem, data, currentView) {
  if (!activeItem?.parentId) {
    // Room
    return data
  }
  else if (currentView.some(item => item.i === activeItem.id || item.i === activeItem.i)) {
    // Component
    return currentView.find(item => item.i === (activeItem.id ? activeItem.id : activeItem.i))
  }
  else {
    // Element
    const parentComponent = currentView.find(item => item.i === activeItem.parentId);
    if (!parentComponent) {
      setActiveItem(null);
      return null;
    }
    return parentComponent.elements.find(elem => elem.i === activeItem.id || elem.i === activeItem.i)
  }
}

export const capitalizeFirstLetter = (s) => {
  for (let letter of s) {
    if (letter === letter.toUpperCase()) {
      s = s.replace(letter, " " + letter);
    }
  }
  return s[0].toUpperCase() + s.slice(1);
}

export function useTraceUpdate(props) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps);
    }
    prev.current = props;
  });
}

export const openDefaultWidget = (selected, roomData, view, dispatch) => {
  // Opening default widget
  const { id, parentId } = selected
  const { components = {}, slug = "" } = roomData
  const isComponent = parentId === slug
  const componentId = isComponent ? id : parentId
  const component = components[view].find(c => c.i === componentId)
  if (!isComponent) {
    const { elements = [] } = component
    const element = elements.find(el => el.i === id)
    const { renderType } = element
    const { element: elemConfig } = COMPONENT_CONFIGS[renderType]
    const { defaultWidget } = elemConfig
    if (defaultWidget) dispatch({ type: a.HANDLE_DEFAULT_WIDGET, payload: { name: defaultWidget, show: true } })
  }
}

export const makeSubstring = (word, start, end, limit) => {
  if (word.length <= limit) return word
  else return `${word.substring(start, end)}...`
}

export const duplicateComponent = (room, editLocks, cmp, editorDispatch, setActiveItem, dispatch) => {
  const newRoom = cloneDeep(room);
  let component = {}
  const indexes = createDistinctIndexes(newRoom, editLocks)
  for (let view of editLocks) {
    const findComponent = newRoom.components[view].find(c => c.i === cmp.i)
    if (findComponent) { // If the source component isn't present in the view, the duplicated component won't be either
      const newComponent = cloneDeep(findComponent);
      let cIndex = 0;
      indexes.forEach(idx => {
        if (idx >= cIndex) {
          cIndex = idx + 1;
        }
      })

      newComponent.index = cIndex;
      newComponent.i = `c${cIndex}`;
      newComponent.y = calculateY(newRoom.components[view])

      for (let [eindex, e] of newComponent.elements.entries()) {
        e['parentId'] = `c${cIndex}`;
        e['index'] = eindex;
        e['i'] = `c${cIndex}e${eindex}`
      }

      newRoom.components[view].push(newComponent);
      if (component.i !== newComponent.i) component = newComponent
    }
  }

  editorDispatch({
    type: "EDITOR_NEW_COMPONENT",
    payload: newRoom
  });
  editorDispatch({ type: "PUSH_TO_HISTORY", payload: { room: newRoom, label: { text: `Duplicated "${component.name}"`, time: new Date().getTime() } } });
  setActiveItem(component)
  dispatch({ type: 'SET_ACTIVE_COMPONENT_REF', payload: component.i })
}

const createDistinctIndexes = (room, editLocks) => {
  let indexes = []
  for (let view of editLocks) {
    room.components[view].forEach(c => {
      if (!indexes.includes(c.index)) indexes.push(c.index)
    });
  }
  return indexes
}