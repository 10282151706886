////////////////////////////////////////
// REQUESTS MADE TO WEBSOCKET SERVER //
//////////////////////////////////////

export const REQUEST_JOIN_ROOM = (payload) => {
    return JSON.stringify({
        type: 'join_room',
        payload: payload
    })
}

export const SEND_MESSAGE = (payload) => {
    return JSON.stringify({
        type: 'send_message',
        payload: payload,
    })
}

export const SEND_PRIVATE_MESSAGE = (payload) => {
    return JSON.stringify({
        type: 'send_private_message',
        payload: payload,
    })
}

export const REQUEST_CLIENT_TOKEN = (payload) => {
    return JSON.stringify({
        type: 'request_client_token',
        payload: payload
    })
}

export const REQUEST_CHAT_HISTORY = (payload) => {
    return JSON.stringify({
        type: 'request_chat_history',
        payload: payload
    })
}

export const REQUEST_ATTENDEES = () => {
    return JSON.stringify({
        type: 'request_attendees',
    })
}

// == Start Webrtc ==
export const WRTC_RING = (payload) => {
    return JSON.stringify({
        type: 'wrtc_ring',
        payload: payload,
    })
}

export const WRTC_RING_ACCEPT = (payload) => {
    return JSON.stringify({
        // type: 'wrtc_ring_accept',
        // payload: payload,
        type: 'wrtc_send_signal',
        payload: { ...payload, sub: 'ring_answer', accept: true },
    })
}

export const WRTC_RING_REJECT = (payload) => {
    return JSON.stringify({
        // type: 'wrtc_ring_reject',
        // payload: payload,
        type: 'wrtc_send_signal',
        payload: { ...payload, sub: 'ring_answer', accept: false },
    })
}

export const WRTC_SEND_SIGNAL = (payload) => {
    return JSON.stringify({
        type: 'wrtc_send_signal',
        // payload: payload,
        payload: { ...payload, sub: 'signal' },
    })
}

export const WRTC_HANGUP = (payload) => {
    return JSON.stringify({
        type: 'wrtc_send_signal',
        // payload: payload,
        payload: { ...payload, sub: 'hangup' },
    })
}
// == End Webrtc ==

export const HEARTBEAT = () => {
    return JSON.stringify({
        type: 'heartbeat',
        payload: {}
    })
}

export const RECONNECT = (payload) => {
    return JSON.stringify({
        type: 'reconnect',
        payload: payload
    })
}

export const DELETE_MESSAGE = (payload) => {
    return JSON.stringify({
        type: 'delete_message',
        payload: payload
    })
}

export const DELETE_ALL_USER_MESSAGES = (payload) => {
    return JSON.stringify({
        type: 'delete_all_user_messages',
        payload: payload
    })
}

export const SILENCE_USER = (payload) => {
    return JSON.stringify({
        type: 'silence_user',
        payload: payload
    })
}

export const REQUEST_POLL_RESULTS = (event_slug) => {
    return JSON.stringify({
        type: 'request_polls',
        payload: {
            event: event_slug,
        },
    })
} 

export const VOTE_FOR_POLL = (payload, event_slug) => {
    return JSON.stringify({
        type: 'vote_for_poll',
        payload: {
            ...payload,
            event: event_slug,
        },
    })
}

export const UPDATE_LAST_SEEN = (payload) => {
    return JSON.stringify({
        type: 'last_seen_message',
        payload: payload
    })
}

export const REACT_TO_MESSAGE = (payload) => {
    return JSON.stringify({
        type: 'react_to_message',
        payload: payload
    })
}

export const APPROVE_HELD_MSG = (payload) => {
    return JSON.stringify({
        type: 'mod_held_msg_approve',
        payload: payload
    })
}

export const DELETE_HELD_MSG = (payload) => {
    return JSON.stringify({
        type: 'mod_held_msg_delete',
        payload: payload
    })
}

export const REQUEST_HELD_MESSAGES = (payload) => {
    return JSON.stringify({
        type: 'mod_request_held_messages',
        payload: payload
    })
}

export const CHANGE_ROOM_SETTING = (payload) => {
    return JSON.stringify({
        type: 'mod_room_change_setting',
        payload: payload
    })
}

export const GET_MODERATION_SETTINGS = (payload) => {
    return JSON.stringify({
        type: 'getChatSettings',
        payload: payload
    })
}