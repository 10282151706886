import React, { useEffect, useContext } from "react";
import '../styles/editor.scss';
import { useSelector } from "react-redux";
import { SettingContext } from '../../contexts';

const EditorContent = (props) => {
  const activeComponentRef = useSelector(state => state.editorProgress.activeComponentRef.active)
  const settingsContext = useContext(SettingContext);
  const { view } = settingsContext;
  const isNavRightOpen = useSelector(state => state.editorProgress.rightNav)
  const layoutStyle = (view === 'tablet' && isNavRightOpen) ? { paddingRight: "250px" } : (view === 'mobile' && isNavRightOpen) ? { paddingRight: "330px" } : {}

  useEffect(() => {
    if (activeComponentRef?.current) activeComponentRef.current.scrollIntoView({ behavior: "smooth" })
  }, [activeComponentRef])

  return (
    <div id="editor-layout" style={layoutStyle}>

      {props.children}

      {/* {activeItem && (
        <div className="widget-container">
          <GenerateWidgets
            {...roomWidgets(activeItem)}
            editProperties={editProperties}
            editActions={editActions}
            editContent={editContent}
            editName={editName}
            editLocks={editLocks}
            editorDispatch={editorDispatch}
            room={data}
            view={view}
            meets={meets}
            streams={streams}
            links={links}
          />
        </div>
      )} */}
    </div>
  );
};

export default EditorContent;
