
import AlignItemsWidget from "./alignItems";
import FlexDirectionWidget from "./flexDirection";
import JustifyContentWidget from "./justifyContent";

const AlignmentMenu = (props) => {
    return (
        <div className="alignment-container">
            <FlexDirectionWidget {...props} />
            <JustifyContentWidget {...props} />
            <AlignItemsWidget {...props} />
        </div>
    )
}

export default AlignmentMenu