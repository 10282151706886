import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import InvitationFormV2 from "./InvitationFormV2";
import './invitation.scss'
import 'react-calendar/dist/Calendar.css'
import { Localized } from "@fluent/react";


const mapStateToProps = state => ({
    close_modals: state.close_modals,
    attendee_calendar: state.schedule.attendee_calendar,
    displayType: state.displayType,
    me: state.user
})

const InviteModal = (props) => {
    const { attendee_calendar = {}, displayType = 'desktop', me } = props
    const isAvailableSlots = attendee_calendar?.slots?.filter(s => s.status === 'available').length > 0
    const [modalView, setModalView] = useState('calendar-view')

    const [person] = useState({
        id: props.person_id,
        first_name: props.person_first_name,
        last_name: props.person_last_name
    })

    useEffect(() => {
        if (props.close_modals) {
            props.onHide()
        }
    }, [props.close_modals])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            contentClassName='invitation-modal'
            dialogClassName={`invitation-modal-dialog ${displayType}`}
            backdropClassName='invitation-modal-backdrop'
            animation={false}
            className="invite-modal-container"
        >
            <Modal.Header closeButton className="modal-schedule-header">
                <div className="inner-header">
                    <h4>{`${props.person_first_name} ${props.person_last_name}`}</h4>
                    {isAvailableSlots && <Localized id="invitation-calendar-info">This user has bookable timeslots, you can invite this user within their timeslots</Localized>}
                </div>
            </Modal.Header>
            <Modal.Body className={modalView}>
                <InvitationFormV2 person={person} modalView={modalView} setModalView={setModalView} />
            </Modal.Body>
        </Modal>
    )
}

export default connect(mapStateToProps)(InviteModal)