import { dispatchUpdateUserMediaDevicesList } from 'util/webrtc';
import * as a from 'actiontypes';

const initialState = {
    busy: false,                // Webrtc is being used, blocks calling / getting called while ringing or in a call
    userBusy: false,
    incoming: false,            // Are we calling someone, or is someone calling us
    accepted: false,            // Was the call accepted
    connected: false,           // Is the call connected and ready for use
    callingUserId: null,        // UserID of other party in call
    callingChannelName: null,   // Specific channel name of instance that answered the call
    callStartedAt: null,        // Timestamp when the call was answered

    otherParticipantFirstName: null, // User first name of other party in call
    otherParticipantLastName: null,  // User last name of other party in call
    otherParticipantIcon: null,      // User icon of other party in call

    // Mediastreams for video elements
    localUserStream: null,
    remoteUserStream: null,
    localDisplayStream: null,
    remoteDisplayStream: null,

    userStreamAudioDisabled: false, // Freezes sending local audio
    userStreamVideoDisabled: false, // Freezes sending local video

    userMediaDevices: null,
};

const webrtc = (state = initialState, action) => {
    switch (action.type) {
        // Clear on rejection
        case a.WRTC_USER_BUSY: {
          return { ...state, userBusy: action.payload }
        }
        case a.WRTC_RING_REJECT:
        case a.WRTC_RING_REJECTED:
        case a.WRTC_ENDED: return { ...initialState, userMediaDevices: state.userMediaDevices } // Preserve media device list}; 

        case a.WRTC_RING: {
            const { first_name, last_name, icon } = action.payload;
            const { otherParticipantFirstName, otherParticipantLastName, otherParticipantIcon } = state;
            return {
                ...state,
                busy: true,
                incoming: false,
                callingUserId: action.payload.user_id,
                otherParticipantFirstName: otherParticipantFirstName ? otherParticipantFirstName : first_name,
                otherParticipantLastName: otherParticipantLastName ? otherParticipantLastName : last_name,
                otherParticipantIcon: otherParticipantIcon ? otherParticipantIcon : icon,
            };
        }

        case a.WRTC_RINGING: {
            return {
                ...state,
                busy: true,
                incoming: true,
                callingUserId: action.payload.user_id,
                callingChannelName: action.payload.channel_name,
                otherParticipantFirstName: action.payload.sender.first_name,
                otherParticipantLastName: action.payload.sender.last_name,
                otherParticipantIcon: action.payload.sender.icon
            };
        }


        case a.WRTC_RING_ACCEPT: return { ...state, busy: true, accepted: true, incoming: false, callingUserId: action.payload.user_id, callingChannelName: action.payload.channel_name, callStartedAt: new Date() };
        case a.WRTC_RING_ACCEPTED: return { ...state, busy: true, accepted: true, incoming: true, callingUserId: action.payload.user_id, callingChannelName: action.payload.channel_name, callStartedAt: new Date() };
        case a.WRTC_CONNECTED: return { ...state, connected: true };
        case a.WRTC_SET_LOCALUSERSTREAM: return { ...state, localUserStream: action.payload };
        case a.WRTC_SET_REMOTEUSERSTREAM: return { ...state, remoteUserStream: action.payload };
        case a.WRTC_SET_LOCALDISPLAYSTREAM: return { ...state, localDisplayStream: action.payload };
        case a.WRTC_SET_REMOTEDISPLAYSTREAM: return { ...state, remoteDisplayStream: action.payload };
        case a.WRTC_SET_USERADIODISABLED: return { ...state, userStreamAudioDisabled: action.payload };
        case a.WRTC_SET_USERVIDEODISABLED: return { ...state, userStreamVideoDisabled: action.payload };

        case a.WRTC_UPDATE_USERMEDIA_DEVICES_LIST: {
            setImmediate(dispatchUpdateUserMediaDevicesList);
            return state;
        }
        case a.WRTC_SET_USERMEDIA_DEVICES_LIST: return { ...state, userMediaDevices: action.payload };

        default: return state
    }
}
export default webrtc;
