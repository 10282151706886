import { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import ImageGallery from '../imageGallery/imageGallery';
import { IconTimes } from 'components/icons';
import { useTemplates } from '../apiCalls';
import { SettingContext } from 'containers/VirtualEvent/contexts';
import { IconChevronRight } from 'components/icons';
import { IconChevronDown } from 'components/icons';
import { IconNewItem } from 'components/icons';
import SaveTemplateModal from '../UIContainers/modals/SaveTemplateModal';
import { IconCheckmarkDone } from 'components/icons';

const Template = props => {
    const [applied, setApplied] = useState(false)
    const { setActiveItem, tempData } = useContext(SettingContext);
    const { setCurrentTemplate, currentTemplate, template, editorDispatch } = props;
    const { preview_image, name } = template;
    const selected = currentTemplate && currentTemplate.name === name ? true : false;

    const handleRevert = (e) => {
        e.stopPropagation()
        setActiveItem(null)
        editorDispatch({ type: 'RESET_TEMPLATE' });
        setCurrentTemplate(null);
        editorDispatch({ type: "PUSH_TO_HISTORY", payload: { room: tempData, label: { text: "Template revert", time: new Date().getTime() } } });
    }

    return <div className="template">
        {name}
        <div className="template-card" onClick={() => setCurrentTemplate(template)}>
            <div className="template-animation-container">
                {applied && <IconCheckmarkDone />}
            </div>
            {template.preview_image && <img alt={name} className="template-preview-image" src={preview_image} />}
            {selected && <div className="template-buttons">
                <button className="select-template-btn" onClick={() => setApplied(true)}>Apply</button>
                <button className="select-template-btn" onClick={(e) => { handleRevert(e); setApplied(false) }}>Revert</button>
            </div>}
        </div>
    </div>
}

function modifyComponents(data, slug) {
    const modifiedComponents = cloneDeep(data);
    for (let view in modifiedComponents) {
        for (let comp of modifiedComponents[view]) {
            comp.parentId = slug;
        }
    }
    return modifiedComponents;
}
function clearComponentDataFromTemplates(data, slug) {
    const modifiedComponents = cloneDeep(data);
    for (let view in modifiedComponents) {
        for (let comp of modifiedComponents[view]) {
            comp.parentId = slug
            for (let element of comp.elements) {
                if (element.renderType === 'stream') {
                    element.content = {
                        slug: '',
                        name: '',
                        start_time: '',
                        end_time: '',
                        source: '',
                        url: '',
                    }
                }
                if (element.renderType === 'meet') {
                    element.content = {
                        id: '',
                        name: '',
                        slug: '',
                    }
                }
            }
        }
    }
    return modifiedComponents;
}

function TemplateSettings(props) {
    const { currentTemplate, setShowTemplateSettings, showTemplateCategory, streams, meets, editorDispatch, room_slug, setShowTemplates, organizer } = props;
    const { preview_image, name } = currentTemplate;

    const [bgImage, setBgImage] = useState('');
    const [showGallery, setShowGallery] = useState(false);
    const useFunctionalContent = showTemplateCategory === 'stream' || showTemplateCategory === 'meet' ? true : false;
    const [functionalContent, setFunctionalContent] = useState(null);

    function submit() {
        const template = cloneDeep(currentTemplate);
        template.data.styles.background.image = `url("${bgImage}")`;
        let modifiedComponents = template.data.components;

        // Replace meets, streams etc functional on the template
        if (useFunctionalContent) {
            modifiedComponents = modifyComponents(template.data.components, room_slug, showTemplateCategory, functionalContent);
        } else {
            modifiedComponents = clearComponentDataFromTemplates(template.data.components, room_slug)
        }
        editorDispatch({ type: 'SET_TEMPLATE', payload: { components: modifiedComponents, styles: template.data.styles } })

    }
    return <>
        <ImageGallery showGallery={showGallery} setShowGallery={setShowGallery} setFromGallery={setBgImage} />

        <div className="template-row space-between border-bottom">
            <h2 className="template-heading">Tweak template</h2>
            <button className="heading-button" onClick={() => setShowTemplateSettings(false)}><IconTimes /></button>
        </div>
        <div className="template-row column">
            <img className={"template-image"} src={preview_image} />
            <div className="template-row space-between border-bottom">
                <label className="tweak-label">Template name</label>
                <h5>{name}</h5>
            </div>
        </div>
        <div className="template-row column border-bottom  pb-20">
            <label className="tweak-label">Background</label>
            <input type="text" readOnly value={bgImage} className="template-tweak-input" placeholder="url to image..." />
            <div className="template-row space-between">
                <button className="image-select-gallery-button" onClick={() => setShowGallery(!showGallery)}>Open gallery</button>
                <button className="image-remove-button" onClick={() => setBgImage('')}>Remove image</button>
            </div>
        </div>
        {showTemplateCategory === 'stream' && <div className="template-row space-between border-bottom  pb-20">
            <label className="tweak-label">Select Stream</label>
            <select className="tweak-select" onChange={e => setFunctionalContent(e.target.value)}>
                <option hidden>Select Stream</option>
                {streams.map(s => (<option key={`temp_stream_${s.slug}`} value={s.slug}>{s.name}</option>))}

            </select>
        </div>}
        {showTemplateCategory === 'meet' && <div className="template-row space-between border-bottom pb-20">
            <label className="tweak-label">Select Meet</label>
            <select className="tweak-select" onChange={e => setFunctionalContent(e.target.value)}>
                <option hidden>Select Meet</option>
                {meets.map(m => (<option key={`temp_meet_${m.slug}`} value={m.slug}>{m.name}</option>))}

            </select>
        </div>}
        <div className="template-row">
            <button className="select-template" onClick={() => submit()} disabled={useFunctionalContent && !functionalContent}>Use template</button>
        </div>
    </>
}

function Templates(props) {
    const contextData = useContext(SettingContext);
    const { showTemplates, setShowTemplates, toggleTemplates, streams, meets, editorDispatch, roomSlug, setActiveItem, organizer, userType, roomData, leftNavView } = contextData;
    const [currentTemplate, setCurrentTemplate] = useState(null);
    const [showTemplateCategory, setShowTemplateCategory] = useState('');
    const [showTemplateSettings, setShowTemplateSettings] = useState(false);
    const [templates, loading] = useTemplates(organizer, showTemplates)
    const [showCategory, setShowCategory] = useState([])
    const [showTemplateSaveModal, setShowTemplateSaveModal] = useState(false)

    useEffect(() => {
        setActiveItem(null);
        if (currentTemplate) {
            const modifiedComponents = clearComponentDataFromTemplates(currentTemplate.components, roomSlug);
            editorDispatch({ type: 'SET_TEMPLATE', payload: { components: modifiedComponents, styles: currentTemplate.styles } })
            const historyRoom = { ...roomData, components: currentTemplate.components, styles: currentTemplate.styles }
            editorDispatch({ type: "PUSH_TO_HISTORY", payload: { room: historyRoom, label: { text: `Applied template: "${currentTemplate.name}"`, time: new Date().getTime() } } });
        }
    }, [currentTemplate])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setShowTemplates(true)
    }, [])

    const addSavedTemplate = (savedTemplate) => {
        if (!templates.find(t => t.id === savedTemplate.id)) templates.push(savedTemplate)
    }

    let customThemeOptions = [];
    if (templates) {
        let addedTemplates = [];
        customThemeOptions = templates.filter(temp => temp.name.includes('***') && !addedTemplates.includes(temp.name.split()[0])).map(t => {
            let strings = t.name.split('***');
            addedTemplates.push(strings[0])
            return strings[0];
        })
        customThemeOptions = [...new Set(customThemeOptions)].sort();
    }

    const getTemplateLabel = (category) => {
        switch (category) {
            case 'organizer':
                return "Organizer templates"
            case 'superuser':
                return "Superuser templates"
            case 'stream':
                return "Stream templates"
            case 'meet':
                return "Meet templates"
            case 'partner':
                return "Partner templates"
            case 'listing':
                return "Listing templates"
            case 'lobby':
                return "Lobby templates"
            case 'other':
                return "Other templates"
            default: return
        }
    }

    function listAllTemplates() {
        const defaultTemplateCategories = ['superuser', 'stream', 'meet', 'partner', 'listing', 'organizer', 'lobby', 'other'];
        const byCategory = defaultTemplateCategories.map(category => {
            const singleCategoryTemplates = templates.filter(template => template.category === category);
            const sorted = singleCategoryTemplates.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
            const templateObject = Object.assign({}, { name: category, label: getTemplateLabel(category), templates: sorted });
            return templateObject
        })
        return byCategory
    }

    const handleShowTemplateCategory = (category) => {
        if (showCategory.includes(category)) {
            setShowCategory(showCategory.filter(c => c !== category))
        } else {
            setShowCategory([...showCategory, category])
        }

    }

    return <div className={`virtual-event-templates ${leftNavView === 'templates' ? 'active' : ''}`}>
        {showTemplateSettings && <TemplateSettings currentTemplate={currentTemplate}
            setShowTemplateSettings={setShowTemplateSettings}
            showTemplateCategory={showTemplateCategory}
            streams={streams}
            meets={meets}
            editorDispatch={editorDispatch}
            setCurrentTemplate={setCurrentTemplate}
            room_slug={roomSlug}
            userType={userType}
        />}
        <div className="template-row">
            {listAllTemplates(customThemeOptions).map((category, i) =>
                <div key={`temp_category_${i}`} className={`template-dropdown ${showCategory.includes(category.name) ? 'show' : ''}`}>
                    <div className={`title ${category.templates.length < 1 ? 'disabled' : ''}`} onClick={() => handleShowTemplateCategory(category.name)}>{category.label} {(showCategory.includes(category.name) && category.templates.length > 0) ? <IconChevronDown /> : <IconChevronRight />}</div>
                    <div className="template-list">
                        {category.templates.map((template, index) =>
                            <Template
                                key={`editor_template_${i}_${index}`}
                                template={template}
                                setCurrentTemplate={setCurrentTemplate}
                                editorDispatch={editorDispatch}
                                currentTemplate={currentTemplate}
                            />
                        )}
                    </div>
                </div>
            )}

        </div>
        <div className="footer" onClick={() => setShowTemplateSaveModal(true)}>
            <div className="button-container">
                <IconNewItem size="20" className="templates-save-icon" /> Save room as a template
            </div>
        </div>
        {showTemplateSaveModal && <SaveTemplateModal showTemplateSaveModal={showTemplateSaveModal} setShowTemplateSaveModal={setShowTemplateSaveModal} room={roomData} organizer={organizer} addSavedTemplate={addSavedTemplate} />}
    </div>

}

export default withRouter(Templates);