/** @format */

import { HOST } from "constants.js";
import { useState } from "react";
import { useSelector } from "react-redux";
import { AUTH_HEADERS } from "util/api";

export function usePatchChatroom() {
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [status, setStatus] = useState(false);
	const [detail, setDetail] = useState(null);
	const { slug } = useSelector(state => state.event);
	async function saveRoom(room, data, onSuccess) {
		setLoading(true);
		try {
			const response = await fetch(`${HOST}api/adm/events/${slug}/chat/rooms/${room}/`, {
				headers: AUTH_HEADERS,
				method: "PATCH",
				body: JSON.stringify(data)
			});
			const json = await response.json();
			if (response.ok) {
				setSuccess(true);
                onSuccess(json)
			} else {
				setSuccess(false);
				setDetail(json?.detail);
			}
			setStatus(response.status);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	}
	return [loading, success, saveRoom, status, detail];
}
