import errorCodes from './errorCodes';

class Base extends Error {
  public code: number;
  public message: string;
  public data?: any;

  constructor(args: { code: number, message: string, data?: any }) {
    super();

    if (!args) throw new Error('Missing argument');
    if (!args.code) throw new Error('Code required');
    if (!args.message) throw new Error('Message required');

    this.code = args.code;
    this.message = args.message;
    this.data = args.data;
  }
}

class MethodNotFound extends Base {
  constructor() {
    super({
      code: errorCodes.METHOD_NOT_FOUND,
      message: 'Method not found'
    });
  }
}

class InvalidParams extends Base {
  constructor() {
    super({
      code: errorCodes.INVALID_PARAMS,
      message: 'Invalid params'
    });
  }
}

class InternalError extends Base {
  constructor(error: Error) {
    super({
      code: errorCodes.INTERNAL_ERROR,
      message: 'Internal error',
      data: error,
    });
  }
}

class ParseError extends Base {
  constructor() {
    super({
      code: errorCodes.PARSE_ERROR,
      message: 'Parse error'
    });
  }
}

class InvalidRequest extends Base {
  constructor() {
    super({
      code: errorCodes.INVALID_REQUEST,
      message: 'Invalid request'
    });
  }
}

class UnknownID extends Base {
  constructor(id: string) {
    super({
      code: errorCodes.UNKNOWN_ID,
      message: 'Unknown RPC ID for answer: ' + id,
      data: id,
    });
  }
}


class ServerError extends Base {

}

class RequestTimout extends ServerError {
  constructor() {
    super({
      code: -32001,
      message: 'Request exceeded maximum execution time'
    });
  }
}

class ExecutionError extends ServerError {
  public data?: any;

  constructor(options: { message?: string, data?: any }) {
    super({
      code: -32002,
      message: options.message ? 'Method Error: ' + options.message : 'Method returned error',
    });

    this.data = options.data;
  }
}

export const errors = {
  [errorCodes.METHOD_NOT_FOUND]: () => {
    return new MethodNotFound();
  },
  [errorCodes.EXECUTION_ERROR]: ({ message, data }) => {
    return new ExecutionError({ message, data });
  }
};

export default {
  Base,
  MethodNotFound,
  InvalidRequest,
  InvalidParams,
  InternalError,
  ServerError,
  ParseError,
  RequestTimout,
  ExecutionError,
  UnknownID,
};