import { invoke, notify } from './rpcInstance';

class ServiceInvoker {
  constructor() { }

  public async invokeService(serviceName: string, method: string, ...args: any[]) {
    return await invoke('serviceInvocation', serviceName, method, ...args);
  }

  public async notifyService(serviceName: string, method: string, ...args: any[]) {
    return await notify('serviceNotification', serviceName, method, ...args);
  }

  // public async invokeServiceAuthed(serviceName: string, method: string, ...args: any[]) {
  //   return await invoke('serviceInvocationAuthed', serviceName, method, ...args);
  // }

  // public async notifyServiceAuthed(serviceName: string, method: string, ...args: any[]) {
  //   return await notify('serviceNotificationAuthed', serviceName, method, ...args);
  // }
}

export default new ServiceInvoker();