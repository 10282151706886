/** @format */

import { useState, useEffect } from "react";
import moment from "moment";
import "./polls.scss";
import * as a from "actiontypes";
import { IconClock } from "components/icons";
import { connect, useSelector } from "react-redux";
import HeaderModal from "headingModal/modal";
import { Localized } from "@fluent/react";
import { useCountdown } from "../hooks/hooks";
import { chatServiceNotify, requestPolls } from "middlewares/websocket";
import { REQUEST_POLL_RESULTS } from "middlewares/socketRequests";

const mapStateToProps = state => ({
	connection: state.connection,
	initialized: state.polls.initialized,
	wholeState: state,
	close_modals: state.close_modals,
});
const mapDispatchToProps = dispatch => ({
	voteForPoll: payload => dispatch({ type: a.WS_WANT_TO_VOTE_FOR_POLL, payload: payload }),
});

const sortPolls = pollArr => {
	return pollArr.sort(
		(a, b) =>
			new Date(a.start_time).setSeconds(0) - new Date(b.start_time).setSeconds(0) ||
			a.question.toLowerCase().localeCompare(b.question.toLowerCase())
	);
};

function filterPolls(polls, editorMode, time) {
	const filteredList = polls.filter(poll => {
		const { show_in = [] } = poll;
		if (editorMode) return true;

		if (!show_in.includes("virtual")) return false;

		// If showHidden is true (the element is added inside the page content) default to true, else check polls hidden status
		let show = true;
		// If poll has not started && show_question_before_start
		if (time.isBefore(poll.start_time)) {
			show = false;
		}
		// If poll has not ended
		if (moment(poll.end_time).isBefore(time)) {
			show = false;
		}
		return show;
	});
	return filteredList;
}

function Polls(props) {
	const { showPolls, setShowPolls, polls, time, keySuffix, voteForPoll, initialized, close_modals } = props;
	const filteredPolls = filterPolls(polls, false, time);
	const { slug } = useSelector(state => state.event);
	useEffect(() => {
		if (close_modals) {
			setShowPolls(false);
		}
	}, [close_modals, setShowPolls]);
	useEffect(() => {
		chatServiceNotify(REQUEST_POLL_RESULTS());
	}, []);
	return (
		<HeaderModal
			header={true}
			showModal={showPolls}
			setShowModal={setShowPolls}
			headingText={<Localized id="polls-title">{"Polls"}</Localized>}
			id="polls"
		>
			{filteredPolls.length > 0 ? (
				<div id="virtual-event-poll-listing">
					{filteredPolls.map(poll => (
						<Poll
							{...poll}
							initialized={initialized}
							keySuffix={keySuffix}
							key={"poll-" + poll.id}
							time={time}
							voteForPoll={voteForPoll}
						/>
					))}
				</div>
			) : (
				<div className="centered-notification">
					<Localized id="polls-no-polls-available">No polls available</Localized>
				</div>
			)}
		</HeaderModal>
	);
}

const CountDown = props => {
	const { startTime } = props;
	const [startDays, startHours, startMinutes] = useCountdown(startTime);
	return (
		<div className="poll-starts">
			<IconClock size="30" style={{ color: "#555", padding: "0px 10px" }} />
			<Localized
				id="poll-start"
				vars={{
					startDays: startDays,
					startHours: startHours,
					startMinutes: startMinutes,
				}}
			>
				<span className="timer">{"starting in: {$startDays}{$startHours}{$startMinutes}"}</span>
			</Localized>
		</div>
	);
};

export const Poll = props => {
	const {
		question,
		id,
		options,
		voting_start_time: startTime,
		start_time,
		keySuffix = "",
		editorMode,
		show_results: showResults,
		voteForPoll,
		initialized,
		time,
		voting_end_time,
	} = props;

	const usersVotedOption = options.find(option => option.has_voted);
	const hasVoted = typeof usersVotedOption !== "undefined";
	const isClosed = time.isAfter(moment(voting_end_time));

	const [voted, setVoted] = useState(false);
	const hasStarted = moment(time).isAfter(moment(start_time) /* , "YYYY-MM-DD +-hh:mm:ss" */);
	const votingStarted = moment(time).isAfter(startTime);

	//const { days: startDays, hours: startHours, minutes: startMinutes } = moment(current).countdown(startTime, 'YYYY-MM-DD +-hh:mm:ss');
	const handleVoting = vote => {
		// Just a failsafe so only valid votes get sent
		if (editorMode || hasVoted || voted || !hasStarted || isClosed) return;
		setVoted(true);
		voteForPoll(vote);
	};

	const totalVotes = options.reduce((accumulator, current) => {
		return current.votes ? accumulator + current.votes : accumulator;
	}, 0);

	const pollOptions = options.map(opt => (
		<PollOption
			key={`${keySuffix}-${opt.id}`}
			voteForPoll={handleVoting}
			opt={opt}
			userHasVoted={hasVoted}
			id={id}
			totalVotes={totalVotes}
			showResults={showResults}
			hasStarted={hasStarted}
			isClosed={isClosed}
		/>
	));
	return (
		<div className="poll-list-item">
			{hasStarted && <h3 className="poll-heading">{question}</h3>}
			{(votingStarted || editorMode) && initialized ? (
				<>
					<div className={`poll-content${hasVoted || isClosed ? " voted" : ""}`}>
						<div className="poll-timer">
							{hasVoted || editorMode ? (
								<Localized id="poll-thank-you-for-voting">
									<h2>
										{"Thank you for participating in the poll! Participate in other polls too!"}
									</h2>
								</Localized>
							) : isClosed ? (
								<Localized id="poll-voting-time-closed">
									<h2>{"Voting time has ended"}</h2>
								</Localized>
							) : (
								<Localized id="poll-results-are-shown-when-answered">
									<h2>{"Results are shown once you have answered"}</h2>
								</Localized>
							)}
						</div>
						{/* 
                    Show the options if showResults is true,
                    the user hasn't voted yet or the element is shown in editor
                */}
						{(showResults || !hasVoted || editorMode) && <div className="poll-answers">{pollOptions}</div>}

						{!showResults && hasVoted && (
							<div className="poll-users-vote">
								<Localized id="poll-option-you-voted">{"You voted"}</Localized>
								<div className="vote-answer">{usersVotedOption?.label}</div>
							</div>
						)}
					</div>

					{showResults && (
						<div>
							<Localized id="poll-votes-amount" vars={{ votes: totalVotes }}>
								{hasVoted && `${totalVotes} votes`}
							</Localized>
						</div>
					)}
				</>
			) : (
				<>{initialized && <CountDown startTime={startTime} />}</>
			)}
		</div>
	);
};

const PollOption = props => {
	const { userHasVoted, opt, id, totalVotes, voteForPoll, hasStarted, isClosed } = props;
	const { has_voted = false, id: opt_id, label, votes = 0 } = opt;

	function calcPercentage(total, votes, accuracy, has_voted) {
		if (total === 0 && votes === 0) return "0%";
		if (has_voted || isClosed) {
			return parseFloat((votes / total) * 100).toFixed(accuracy) + "%";
		}
		return "";
	}

	const calcScale = () => {
		if (totalVotes === 0) return 0;
		return votes / totalVotes;
	};

	const cssTransform = userHasVoted || isClosed ? `scaleX(${parseFloat(calcScale()).toFixed(1)})` : "unset";

	return (
		<button
			className={`option-button${userHasVoted || isClosed ? " voted" : ""}${has_voted ? " my-vote" : ""}`}
			disabled={userHasVoted || !hasStarted}
			onClick={() => {
				voteForPoll({ answer_id: opt_id, poll_id: id });
			}}
		>
			<div className="option-row">
				<div className="label-row">
					{!userHasVoted && !isClosed && <div className="circle-dot" />}
					{label}
				</div>
				<span className="percentage-value">{calcPercentage(totalVotes, votes, 1, userHasVoted)}</span>
			</div>
			{(userHasVoted || isClosed) && (
				<div className="option-row">
					<div className="visualization">
						<div
							className="percentage"
							style={{ transform: cssTransform, backgroundColor: "#89d22c" }}
						></div>
					</div>
				</div>
			)}
		</button>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(Polls);
