import SimplePeer from 'simple-peer';
import { getIceServers } from './iceServers';
import * as a from 'actiontypes';
import store from 'store';
// Values taken from source of https://webrtc.github.io/samples/src/content/getusermedia/resolution/
// Could use 'exact' constraints instead of 'max', but unsupported resolution handling is annoying, so just put a ceiling for the moment
// Just realized you could use 'ideal' as well to have it try to aproximate the best it can
// TODO: Use exact constraints and properly handle the OverConstrained error?
// TODO: Make userMedia reapply constraints when quality or deviceId settings change!

export const videoConstraints = {
    fullhd: { width: { max: 1920 }, height: { max: 1080 } },
    hd: { width: { max: 1280 }, height: { max: 720 } },
    vga: { width: { max: 640 }, height: { max: 480 } },
    qvga: { width: { max: 320 }, height: { max: 240 } },
};

export function getCurrentVideoContraint() {
    const state = store.getState();
    const chosenQuality = state.webrtcSettings.userMediaVideoQuality;
    const chosenVideoDevice = state.webrtcSettings.userMediaVideoDeviceId;
    const videoQualityConstraint = videoConstraints[chosenQuality] || {}; // Take constraint by quality name, default to true if results in undefined, just in case
    const videoConstraint = { ...videoQualityConstraint, deviceId: chosenVideoDevice };
    console.log('VIDEO CONSTRAINT:', chosenQuality, chosenVideoDevice, videoConstraint);
    return videoConstraint;
}

export function getCurrentAudioContraint() {
    const chosenAudioDevice = store.getState().webrtcSettings.userMediaAudioDeviceId;
    const audioConstraint = { deviceId: chosenAudioDevice };
    console.log('AUDIO CONSTRAINT:', chosenAudioDevice, audioConstraint);
    return audioConstraint;
}

let currentUserMedia = null;
export async function getUserMedia() {
    if (!currentUserMedia) {
        try {
            // currentUserMedia = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            currentUserMedia = await navigator.mediaDevices.getUserMedia({ video: getCurrentVideoContraint(), audio: getCurrentAudioContraint() });
        } catch (err) {
            console.warn('Failed to getUserMedia with video, retrying audio only', err);
            try {
                currentUserMedia = await navigator.mediaDevices.getUserMedia({ video: false, audio: getCurrentAudioContraint() });
            } catch (err) {
                console.warn('Failed to getUserMedia with audio only, returning nothing');
            }
        }
        store.dispatch({ type: a.WRTC_SET_LOCALUSERSTREAM, payload: currentUserMedia });
        //store.dispatch(setLocalUserStream(currentUserMedia));
    }
    return currentUserMedia;
}

export function closeUserMedia() {
    if (currentUserMedia) {
        currentUserMedia.getTracks().forEach(function (track) {
            track.stop();
        });
        currentUserMedia = null;
        store.dispatch({ type: a.WRTC_SET_LOCALUSERSTREAM, payload: null })
        //store.dispatch(setLocalUserStream(null));
    }
}

export function attemptVideoReapplyConstraints() {
    try {
        if (!currentUserMedia) return;
        const videoTrack = currentUserMedia.getVideoTracks()[0];
        if (!videoTrack) return;
        if (!videoTrack.applyConstraints) return;
        const constraints = getCurrentVideoContraint();
        videoTrack.applyConstraints(constraints);
        console.log('attemptVideoReapplyConstraints!', constraints, videoTrack.getConstraints());
    } catch (err) {
        console.warn('attemptVideoReapplyConstraints Error:', err);
    }
}

export function attemptAudioReapplyConstraints() {
    try {
        if (!currentUserMedia) return;
        const audioTrack = currentUserMedia.getAudioTracks()[0];
        if (!audioTrack) return;
        if (!audioTrack.applyConstraints) return;
        const constraints = getCurrentAudioContraint();
        audioTrack.applyConstraints(constraints);
        console.log('attemptAudioReapplyConstraints!', constraints, audioTrack.getConstraints());
    } catch (err) {
        console.warn('attemptAudioReapplyConstraints Error:', err);
    }
}

let currentDisplayMedia = null;
export async function getDisplayMedia() {
    if (!currentDisplayMedia) {
        currentDisplayMedia = await navigator.mediaDevices.getDisplayMedia({});
        store.dispatch({ type: a.WRTC_SET_LOCALDISPLAYSTREAM, payload: currentDisplayMedia })
        //store.dispatch(setLocalDisplayStream(currentDisplayMedia));
        currentDisplayMedia.getVideoTracks()[0].onended = function () {
            stopScreenCapture();
        };
    }
    return currentDisplayMedia;
}

export function closeDisplayMedia() {
    if (currentDisplayMedia) {
        currentDisplayMedia.getTracks().forEach(function (track) {
            track.stop();
        });
        currentDisplayMedia = null;
        store.dispatch({ type: a.WRTC_SET_LOCALDISPLAYSTREAM, payload: null })
        //store.dispatch(setLocalDisplayStream(null));
    }
}

export async function listUserMediaDevices() {
    const deviceDict = { videoInput: [], audioInput: [], audioOutput: [] };
    try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        for (let i = 0; i < devices.length; i++) {
            const deviceInfo = devices[i];
            if (deviceInfo.kind === 'videoinput') {
                deviceDict.videoInput.push({
                    label: deviceInfo.label || `camera ${deviceDict.videoInput.length + 1}`,
                    id: deviceInfo.deviceId,
                });
            } else if (deviceInfo.kind === 'audioinput') {
                deviceDict.audioInput.push({
                    label: deviceInfo.label || `microphone ${deviceDict.audioInput.length + 1}`,
                    id: deviceInfo.deviceId,
                });
            } else if (deviceInfo.kind === 'audiooutput') {
                deviceDict.audioOutput.push({
                    label: deviceInfo.label || `speaker ${deviceDict.audioOutput.length + 1}`,
                    id: deviceInfo.deviceId,
                });
            }
        }
    } catch (err) {
        console.warn('listUserMediaDevices Error, returning empty', err);
    }
    return deviceDict;
}

export async function dispatchUpdateUserMediaDevicesList() {
    const list = await listUserMediaDevices();
    console.log(list)
    store.dispatch({ type: a.WRTC_SET_USERMEDIA_DEVICES_LIST, payload: list })
    //store.dispatch(setUserMediaDevicesList(list));
}

let currentPeer = null;
let startCallPromise = null;
let startCallPromiseResolve = null;

export async function startCall(user_id, channel_name, initiator) {
    console.log('webrtc startCall', user_id, channel_name, initiator);

    startCallPromise = new Promise((resolve) => {
        startCallPromiseResolve = resolve;
    });

    if (currentPeer) {
        console.warn('webrtc startCall - already have currentPeer:', currentPeer);
        return;
    }

    const localStream = await getUserMedia();

    const peer = new SimplePeer({
        initiator: initiator,
        trickle: true,
        config: {
            iceServers: await getIceServers(),
        },
        stream: localStream,
    });

    currentPeer = peer;

    peer.on("signal", data => {
        // console.log('webrtc peer signal', data);
        store.dispatch({ type: a.WRTC_SEND_SIGNAL, payload: { user_id, channel_name, signal: data } })
        //store.dispatch(sendSignal({ user_id, channel_name, signal: data }));
    })

    peer.on("stream", stream => {
        // console.log('webrtc peer stream', stream);

        // const videoTrack = stream.getVideoTracks()[0];
        // const audioTrack = stream.getAudioTracks()[0];
        // console.log('STREAM', videoTrack, audioTrack);

        let setAsDisplayStream = false;
        if (store.getState().webrtc.remoteUserStream) {
            // TODO: Better way to figure out which is which?
            setAsDisplayStream = true;
        }

        if (setAsDisplayStream) {
            store.dispatch({ type: a.WRTC_SET_REMOTEDISPLAYSTREAM, payload: stream })
            //store.dispatch(setRemoteDisplayStream(stream));
            stream.oninactive = stream.onended = () => {
                store.dispatch({ type: a.WRTC_SET_REMOTEDISPLAYSTREAM, payload: null })
                //store.dispatch(setRemoteDisplayStream(null));
            }
        } else {
            store.dispatch({ type: a.WRTC_SET_REMOTEUSERSTREAM, payload: stream })
            //store.dispatch(setRemoteUserStream(stream));
            stream.oninactive = stream.onended = () => {
                store.dispatch({ type: a.WRTC_SET_REMOTEUSERSTREAM, payload: null })
                //store.dispatch(setRemoteUserStream(null));
            }
        }
    });

    peer.on('connect', (err) => {
        // console.log('webrtc peer connect', err);
        store.dispatch({ type: a.WRTC_CONNECTED })
        //store.dispatch(callConnected());
    });

    peer.on('error', (err) => {
        console.warn('WebRTC Peer Error:', err);
        endCall()
    });

    peer.on('close', () => {
        // console.log('webrtc peer close');
        endCall()
    });

    if (startCallPromiseResolve) {
        startCallPromiseResolve();
        startCallPromiseResolve = null;
        startCallPromise.then(() => { startCallPromise = null; });
    }
}

export async function endCall() {
    if (!currentPeer || currentPeer.ending) {
        return;
    }
    // console.log('webrtc endCall', currentPeer);
    currentPeer.ending = true; // Prevent double-calling by close event
    currentPeer.destroy();
    currentPeer = null;
    closeUserMedia();
    stopScreenCapture();
    store.dispatch({type: a.WRTC_ENDED})
    //store.dispatch(callEnded());
}

export async function receiveSignal(signal) {
    if (!currentPeer && startCallPromise) {
        console.log('webrtc receiveSignal - Waiting for promise');
        await startCallPromise;
        console.log('webrtc receiveSignal - Done waiting');
    }
    if (!currentPeer) {
        console.warn('webrtc receiveSignal - signal but no peer object:', signal);
        return;
    }
    currentPeer.signal(signal);
}

export async function startScreenCapture() {
    if (!currentPeer) {
        return;
    }
    const stream = await getDisplayMedia();
    currentPeer.addStream(stream);
}

export async function stopScreenCapture() {
    if (currentPeer) {
        currentPeer.removeStream(currentDisplayMedia);
    }
    closeDisplayMedia();
}

export async function userAudioDisabled(val) {
    if (currentUserMedia) {
        currentUserMedia.getAudioTracks()[0].enabled = !val;
    }
}

export async function userVideoDisabled(val) {
    if (currentUserMedia) {
        currentUserMedia.getVideoTracks()[0].enabled = !val;
    }
}
