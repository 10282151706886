import React, { useState, useContext } from 'react';
import { SettingContext } from '../../contexts';
import {
    IconColumns,
    IconImage,
    IconTabletEditor,
    IconLaptop,
    IconIphone
} from 'components/icons';

import { GenericTooltip } from 'components/ToolTip/Tooltip';
import { useSelector } from 'react-redux';

const BottomToolbar = (props) => {

    const [hover, setHoverActive] = useState(false);
    const contextValues = useContext(SettingContext)
    const isRightNavOpen = useSelector(state => state.editorProgress.rightNav)

    const {
        setClearBackground,
        clearBackground,
        setView,
        view,
        darkMode
    } = contextValues;

    const {
        setShowGrid,
        showGrid,
        editLocks=[],
        setEditLocks
    } = props;

    const isChecked = (viewLock) => {
        return editLocks.includes(viewLock)
    }

    const handleEditLock = (viewLock) =>{
        if (isChecked(viewLock)) {
            const updatedEditLocks = editLocks.filter(el => el !== viewLock)
            setEditLocks(updatedEditLocks)
        } else {
            const updatedEditLocks = [ ...editLocks, viewLock ]
            setEditLocks(updatedEditLocks)
        }
    }

    return (
        <div className={`editor-bottom-toolbar ${hover ? 'active' : ''} ${darkMode ? 'dark' : 'light'}`} onMouseEnter={() => setHoverActive(true)} onMouseLeave={() => setHoverActive(false)}>
            <GenericTooltip text="Desktop view" orientation="bottom" show={50} hide={5}>
                <div className='toggle-button-container'>
                    <button className={`toggle-button view ${view === 'desktop' ? 'active' : ''}`} onClick={() => {
                        setView('desktop');
                    }}>
                        <div className="hover-area"></div>
                        <IconLaptop />
                    </button>
                    <input className='toggle-editlock' type="checkbox" checked={isChecked('desktop')} onClick={() => handleEditLock('desktop')} />
                </div>
            </GenericTooltip>

            <GenericTooltip text="Tablet view" orientation="bottom" show={50} hide={5}>

                <div className='toggle-button-container'>
                    <button className={`toggle-button view ${view === 'tablet' ? 'active' : ''}`} onClick={() => {
                        setView('tablet');
                    }}>
                        <div className="hover-area"></div>
                        <IconTabletEditor />
                    </button>
                    <input className='toggle-editlock' type="checkbox" checked={isChecked('tablet')} onClick={() => handleEditLock('tablet')} />
                </div>
            </GenericTooltip>

            <GenericTooltip text="Mobile view" orientation="bottom" show={50} hide={5}>
                <div className='toggle-button-container'>
                    <button className={`toggle-button view ${view === 'mobile' ? 'active' : ''}`} onClick={() => {
                        setView('mobile');
                    }}>
                        <div className="hover-area"></div>
                        {/* style={{ color: view === 'mobile' ? '#fff' : '#555' }} */}
                        <IconIphone />
                    </button>
                    <input className='toggle-editlock' type="checkbox" checked={isChecked('mobile')} onClick={() => handleEditLock('mobile')} />
                </div>
            </GenericTooltip>
            <div className="bottom-divider"></div>
            <GenericTooltip orientation="bottom" text="Toggle background" show={50} hide={5}>
                <button className="toggle-button background" onClick={() => setClearBackground(!clearBackground)}>
                    <div className="hover-area"></div>
                    <IconImage size="26" style={{ color: clearBackground ? 'darkgray' : 'gray' }} />
                    <span className={`toggle-disabled-line ${clearBackground && 'active'}`}></span>
                </button>
            </GenericTooltip>

            <GenericTooltip orientation="bottom" text="Toggle grid" show={50} hide={5}>
                <button className="toggle-button grid" onClick={() => setShowGrid(!showGrid)}>
                    <div className="hover-area"></div>
                    <IconColumns size="26" style={{ color: !showGrid ? 'darkgray' : 'gray' }} />
                    <span className={`toggle-disabled-line grid ${!showGrid && 'active'}`}></span>
                </button>
            </GenericTooltip>
            {props.children}
        </div>
    )
}

export default BottomToolbar;



