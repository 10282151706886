import { useEffect, useState } from "react"
import { Form, Button } from "react-bootstrap"
import { formatDate } from "./helpers/dateFormatter"
import { Localized } from "@fluent/react"
import { connect } from "react-redux"
import { calculateTime } from "./helpers/inviteHelpers"
import Calendar from 'react-calendar'
import { cloneDeep } from "lodash";
import moment from "moment"
import { IconArrowLeft, IconArrowRight, IconTimes } from "components/icons"
import { useFilterSlots } from "./helpers/hooks"
import { registerLocale } from 'react-datepicker'
import fi from 'date-fns/locale/fi'
import se from 'date-fns/locale/sv'
import en from 'date-fns/locale/en-GB'
import { TimeSelector, TimelineContainer } from "./components/InvitationFormComponents"
import { useDispatch } from "react-redux"
import { cancelInvite, sendInvite } from "containers/VirtualEvent/schedule/apiCalls"
import { LivetoLoader } from "components/icons"
import { IconChevronLeft } from "components/icons"
registerLocale('fi', fi)
registerLocale('se', se)
registerLocale('en', en)

const mapStateToProps = state => ({
    calendar: state.schedule.calendar,
    attendee_calendar: state.schedule.attendee_calendar,
    virtual_event: state.event,
    me: state.user
})

function useGetDistinctDates(slots = []) {
    let timeArray = []
    const copiedSlots = cloneDeep(slots)

    copiedSlots.forEach(s => {
        const startIsSameOrAfter = moment(s.start_time).isSameOrAfter(moment(), 'day')
        const endIsSameOrAfter = moment(s.end_time).isSameOrAfter(moment(), 'day')
        if (moment(s.start_time).isSame(moment(s.end_time), 'day') && startIsSameOrAfter) {
            timeArray.push(new Date(s.start_time))
        } else if (moment(s.end_time).diff(moment(s.start_time), 'days') > 1 && (startIsSameOrAfter || endIsSameOrAfter)) {
            const diff = moment(s.end_time).diff(moment(s.start_time), 'days')
            let daysToAdd = 0
            while (daysToAdd !== (diff + 1)) { //if there are more that 1 day in between start and end
                const dateToAdd = moment(s.start_time).add(daysToAdd, 'days')
                const isZeroHour = dateToAdd.isSame(moment(s.end_time), 'day') && moment(s.end_time).get('hours') < 1 // check if end time is 00:00
                if (!isZeroHour) timeArray.push(new Date(dateToAdd))
                daysToAdd++
            }
        } else {
            if (startIsSameOrAfter || endIsSameOrAfter) {
                timeArray.push(new Date(s.start_time))
                timeArray.push(new Date(s.end_time))
            }
        }
    });
    return timeArray
}

const TIME_INTERVAL = 5 // Time selection interval

const InvitationFormV2 = ({ me, calendar, attendee_calendar, person, virtual_event, setModalView }) => {
    const availableSlots = attendee_calendar?.slots // available slots filtered by start_time
        .filter(s => s.status === 'available')
        .sort((a, b) => Date.parse(a.start_time) > Date.parse(b.start_time))

    const includeDates = useGetDistinctDates(availableSlots)

    const findStartDate = () => {
        const sorted = includeDates.sort((a, b) => Date.parse(a) > Date.parse(b))
        const findStartDate = sorted.find(date => moment(date).isSameOrAfter(moment(), 'day'))
        if (findStartDate) return findStartDate
        else return new Date()
    }

    const [existingSlot, setExistingSlot] = useState(null)
    const [date, setDate] = useState(findStartDate())
    const [showInvitation, setShowInvitation] = useState(false)
    const [selectedTime, setSelectedTime] = useState(null)
    const [showMe, setShowMe] = useState(true)
    const [showAttendee, setShowAttendee] = useState(true)
    const { me: mySlots, attendee: attendeeSlots } = useFilterSlots(calendar, attendee_calendar, date, showMe, showAttendee)
    const [timelineHeight, setTimelineHeight] = useState({})
    const [loading, setLoading] = useState(false)
    const [warningMsg, setWarningMsg] = useState(null)

    const dateAvailableSlots = availableSlots.filter(slot => moment(slot.start_time).isSame(moment(date), 'day'))

    const dispatch = useDispatch()

    const handleCalendarDayChange = (date) => {
        setDate(date)
    }

    const handleCreateInviteClick = (time, endTime, slotName, inviteMsg) => {
        const slotTime = formatDate(date, time, endTime)

        const newData = {
            start_time: slotTime.startTime,
            end_time: slotTime.endTime,
            name: slotName.length > 100 ? slotName.substring(0, 100) : slotName,
            description: `Meeting between ${me.first_name} ${me.last_name} and ${person.first_name} ${person.last_name}`,
            status: "busy",
        }

        const newUserData = {
            message: inviteMsg,
            user: person.id,
            first_name: person.first_name,
            last_name: person.last_name
        }

        const data = {
            slot: newData,
            user: newUserData
        }

        handleCreateInvite(data)
    }

    const handleCreateInvite = async (userData) => {
        try {
            setLoading(true)
            const { slot, user } = userData
            const { status, error } = await sendInvite(slot, virtual_event.slug, user, me, dispatch)
            if (status === 201) {
                setShowInvitation(false)
                handleGoBack()
            }
            if (error) {
                setWarningMsg(error.message)
            } else if (status !== 201) {
                setWarningMsg(<Localized id="invitation-generic-error-msg">Something went wrong</Localized>)
            }
        } catch (err) {
            console.error(err)
            setWarningMsg(err.message)
        } finally {
            setLoading(false)
        }
    }

    const disableConditions = (dateToCheck) => {
        const findDate = includeDates.find(d => moment(d).isSame(moment(dateToCheck), 'day'))
        const isDateAvailable = includeDates.length >= 1 && !findDate
        const isDateInThePast = moment(dateToCheck).isBefore(moment(), 'day')
        const isDateAfterTheEvent = moment(dateToCheck).isAfter(moment(virtual_event.end_time))
        return isDateAvailable || isDateInThePast || isDateAfterTheEvent
    }

    const getDayChangeValue = (value) => {
        if (includeDates === 0) return value
        const findDate = (d) => moment(d).isSame(moment(date), 'day')
        const dateIndex = includeDates.findIndex(findDate)
        const nextIndex = dateIndex + value
        if (!includeDates[nextIndex]) return value
        const nextDate = includeDates[nextIndex]
        const diffInDays = moment(nextDate).startOf('day').diff(moment(date).startOf('day'), 'days')
        return diffInDays
    }

    //Disabling arrow buttons
    const disableChangeDayButton = (value) => {
        const updatedDate = moment(date).add(value, 'days')
        return disableConditions(updatedDate)
    }

    // Disabling calendar dates
    const tileDisabled = ({ date, view }) => {
        // Add class to tiles in month view only
        if (view === 'month') {
            // Check if a date React-Calendar wants to check is within any of the ranges
            return disableConditions(date)
        }
    }

    const handleOpenInvitationModal = (selectedTime, available) => {
        if (available) {
            setExistingSlot(null)
            setWarningMsg(null)
            setSelectedTime(selectedTime)
            setShowInvitation(true)
            setModalView('invitation-form-view')
        }
    }

    const handleOpenSlot = (slotData, who) => {
        if (who === 'myself') {
            setExistingSlot(slotData)
            setShowInvitation(true)
            setModalView('invitation-form-view')
        }
    }

    const handleCancelInvite = async (slotData) => {
        try {
            setLoading(true)
            const { slotID, type, status, start_time, end_time } = slotData
            const slotObj = {
                id: slotID
            }
            let findSlot
            if (type === 'sent_invite') {
                findSlot = calendar.slots.find(slot => slot.id === slotID)
                const user = findSlot.sent_invites[0].user
                slotObj.sent_invites = []
                slotObj.sent_invites.push({ status: status, user, slot: { id: slotID, start_time, end_time } })
            } else {
                const findInvite = calendar.received_invites.find(inv => inv.slot.id === slotID && inv.user.user_id === person.id)
                slotObj.isDuplicate = true
                slotObj.name = findInvite.slot.name
            }
            const { status: requestStatus, error } = await cancelInvite(slotObj, virtual_event.slug, dispatch, me.chat_user_id)
            if (requestStatus === 200 || requestStatus === 204) {
                setShowInvitation(false)
                handleGoBack()
            } else if (requestStatus === 404) {
                setWarningMsg(<Localized id="invitation-404">404: Invite not found</Localized>)
            } else {
                setWarningMsg(<Localized id="invitation-generic-error-msg">Something went wrong</Localized>)
            }
            if (error) {
                setWarningMsg(error.message)
            }
        } catch (err) {
            console.error(err)
            setWarningMsg(err.message)
        } finally {
            setLoading(false)
        }
    }

    const handleGoBack = () => {
        setModalView('calendar-view')
        setTimeout(() => {
            setShowInvitation(!showInvitation)
        }, 500)
    }

    const invitationProps = {
        existingSlot,
        handleCreateInviteClick,
        language: me.language,
        selectedTime,
        handleCancelInvite,
        loading,
        warningMsg,
        dateAvailableSlots,
        setModalView,
        handleGoBack,
        setWarningMsg
    }
    const invitationCalendarProps = {
        handleCalendarDayChange,
        date,
        me,
        tileDisabled,
        timelineHeight,
        disableChangeDayButton,
        getDayChangeValue,
        setDate,
        handleOpenSlot,
        setTimelineHeight,
        handleOpenInvitationModal,
        mySlots,
        attendeeSlots,
        availableSlots
    }
    return (
        <div className="invitation-body-wrapper">
            <div className="invitation-calendar">
                <div className="invitation-calendar-settings">
                    <div className="input-group">
                        <div className="color-code myself"><div className="half-1" /><div className="half-2" /></div>
                        <Localized id="invitation-calendar-checkbox-label">Me</Localized>
                        <input type="checkbox" data-cy="show-slots-checkbox-me" checked={showMe} name="show_me" onChange={() => setShowMe(!showMe)} />
                    </div>
                    <div className="input-group">
                        <div className="color-code attendee"><div className="half-1" /><div className="half-2" /></div>
                        {`${person.first_name} ${person.last_name}`}
                        <input type="checkbox" data-cy="show-slots-checkbox-them" checked={showAttendee} name="show_attendee" onChange={() => setShowAttendee(!showAttendee)} />
                    </div>

                </div>
                <div className="invitation-modal-form-body">

                    <InvitationCalendar {...invitationCalendarProps} />
                </div>
            </div>
            {showInvitation && <InvitationData {...invitationProps} showInvitation={showInvitation} setShowInvitation={setShowInvitation} selectedTime={selectedTime} />}
        </div>
    )
}

const InvitationCalendar = (props) => {
    const {
        handleCalendarDayChange,
        date,
        me,
        tileDisabled,
        timelineHeight,
        disableChangeDayButton,
        getDayChangeValue,
        setDate,
        handleOpenSlot,
        setTimelineHeight,
        handleOpenInvitationModal,
        mySlots,
        attendeeSlots,
        availableSlots
    } = props
    return <>
        <div className="calendar-wrapper">
            <Calendar
                onChange={handleCalendarDayChange}
                value={date}
                locale={me.language}
                tileDisabled={tileDisabled}
            />
        </div>

        <div className="day-timeline" style={timelineHeight} >
            <div className="selected-date-title">

                <button className="change-day" data-cy="set-previous-date-button" disabled={disableChangeDayButton(getDayChangeValue(-1))} onClick={() => setDate(new Date(moment(date).add(getDayChangeValue(-1), 'days')))}><IconArrowLeft size="30" /></button>
                <div className="date-title">
                    {moment(date).format('DD.MM.YYYY')}
                </div>
                <button className="change-day" data-cy="set-next-date-button" disabled={disableChangeDayButton(getDayChangeValue(1))} onClick={() => setDate(new Date(moment(date).add(getDayChangeValue(1), 'days')))}><IconArrowRight size="30" /></button>
            </div>
            <TimelineContainer handleOpenSlot={handleOpenSlot} setTimelineHeight={setTimelineHeight} select={handleOpenInvitationModal} mySlots={mySlots} attendeeSlots={attendeeSlots} availableSlots={availableSlots} date={date} />
        </div>
    </>
}

const InvitationData = (props) => {
    const {
        existingSlot,
        handleCreateInviteClick,
        language,
        selectedTime,
        handleCancelInvite,
        loading,
        warningMsg,
        setWarningMsg,
        dateAvailableSlots,
        handleGoBack
    } = props
    const [slotName, setSlotName] = useState(existingSlot ? existingSlot.name : "")
    const [inviteMsg, setInviteMsg] = useState(existingSlot ? existingSlot.message : "")
    const [time, setTime] = useState(calculateTime(selectedTime, existingSlot).time)
    const [endTime, setEndTime] = useState(calculateTime(selectedTime, existingSlot).endTime)
    const [warningStyle, setWarningStyle] = useState('')

    const isToday = moment(selectedTime).isSame(moment(), 'day')
    const isInputDisabled = existingSlot ? true : false

    useEffect(() => {
        if (moment(time).isSameOrAfter(moment(endTime))) {
            setWarningMsg(<Localized id="invitation-time-warning">The duration of the meeting can not be 0</Localized>)
            setWarningStyle('time')
        } else if (!existingSlot && moment(time).isBefore(moment())) {
            setWarningMsg(<Localized id="invitation-time-warning-past">The meeting cannot be in the past</Localized>)
            setWarningStyle('time')
        } else setWarningMsg('')
    }, [time, endTime, setWarningMsg])

    const filterPassedTime = (timeValue, startTime) => {
        const currentDate = startTime ? new Date(startTime) : new Date();
        const selectedDate = new Date(timeValue);

        return isToday ? currentDate.getTime() < selectedDate.getTime() : true
    };

    const handleChange = (value = new Date(), target) => {
        const hour = moment(value).hours()
        const min = moment(value).minutes()
        const sec = moment(value).seconds()
        const updatedDateString = moment(selectedTime).set({ h: hour, m: min, s: sec, ms: 0 }).format('YYYY-MM-DDTHH:mm:ss')
        const updatedDate = new Date(updatedDateString)
        if (target === 'start_time') setTime(updatedDate)
        else setEndTime(updatedDate)
    }

    const disableButton = () => {
        const isTimeIncorrect = moment(time).isSameOrAfter(moment(endTime))
        const isPast = moment(time).isBefore(moment())
        return isTimeIncorrect || !time || !endTime || !slotName || !inviteMsg || inviteMsg.length > 150 || loading || isPast
    }

    const status = () => {
        const { status } = existingSlot
        if (!status) return null
        return <span className={status}><Localized id={`slot-invite-status-${status}`}>{status}</Localized></span>
    }

    return <div className="invitation-data-modal">
        <div className="header">
            <Localized id="invitation-data-header-label">Invitation data</Localized>
            <button data-cy="close_invitation_data_modal" className="close" onClick={handleGoBack}>
                <IconChevronLeft />
                <Localized id="back-button-text"><p className="back-button-text">Back</p></Localized>
            </button>
        </div>
        <div className="body">
            <div className="body-section">
                <div className="invitation-name-section">
                    <Localized id='invitation-slot-name-text'>
                        Slot name
                    </Localized>

                    <input disabled={isInputDisabled} data-cy="invitation_data_slot_name_input" className="invite-input" type="text" value={slotName} onChange={e => setSlotName(e.target.value)} />

                    <Form.Text className="text-muted color-liveto invitation-static-heght-tooltip">
                        {slotName.length < 1 && <Localized id='invitation-slot-name-tooltip' />}
                    </Form.Text>
                </div>
                <div className="start-time-container">
                    <TimeSelector
                        target={"start_time"}
                        handleChange={handleChange}
                        value={time}
                        label={<Localized id='invitation-select-start-time-label' />}
                        lang={language}
                        filterPassedTime={filterPassedTime}
                        interval={TIME_INTERVAL}
                        includeTimeArr={dateAvailableSlots}
                        isEnd={false}
                        disabled={isInputDisabled}
                    />
                    <TimeSelector
                        target={"end_time"}
                        handleChange={handleChange}
                        value={endTime}
                        label={<Localized id='invitation-select-end-time-label' />}
                        lang={language}
                        filterPassedTime={filterPassedTime}
                        interval={TIME_INTERVAL}
                        includeTimeArr={dateAvailableSlots}
                        startTime={time}
                        isEnd={true}
                        disabled={isInputDisabled}
                    />
                    {existingSlot && <div className="status"><Localized id="sidebar-invitation-status-text">Status:</Localized>{' '}{status()}</div>}
                </div>
            </div>

            <div className="body-section">
                <div className="invitation-msg-section">
                    <div className="label">
                        <div className="input-label">
                            <Localized id='invitation-slot-invitation-msg-text'>
                                Invitation message
                            </Localized>
                        </div>

                        <div className="invite-msg-warning">
                            {inviteMsg.length > 150 &&
                                <Localized id="invitation-msg-warning">
                                    <p className='color-liveto mr-1'>Can't be over 150 characters!</p>
                                </Localized>}
                            <div className={`input-label ${inviteMsg.length > 150 ? 'color-liveto' : ""}`}>
                                {`${inviteMsg.length}/150`}
                            </div>
                        </div>
                    </div>
                    <textarea disabled={isInputDisabled} data-cy="invitation_data_message_input" className="invite-textarea" value={inviteMsg} rows="7" onChange={e => setInviteMsg(e.target.value)} />
                    <Form.Text className="text-muted color-liveto invitation-static-heght-tooltip">
                        {inviteMsg.length < 1 && <Localized id='invitation-slot-invitation-msg-tooltip' />}
                    </Form.Text>
                </div>
            </div>
        </div>
        <div className="footer">
            <div className="warning-message">
                {warningMsg && warningMsg}
                {loading && <LivetoLoader size="40" />}
            </div>
            <div className="buttons">
                {existingSlot && <Button data-cy="invitation_data_cancel_invite_data" className='submit-invitation-button' onClick={() => handleCancelInvite(existingSlot)} variant="outline-danger" disabled={disableButton()} ><Localized id="invite-cancel-button" /></Button>}
                {!isInputDisabled && <Button data-cy="invitation_data_submit_invite_button" className='submit-invitation-button' onClick={() => handleCreateInviteClick(time, endTime, slotName, inviteMsg)} variant="outline-dark" disabled={disableButton()} ><Localized id='invitation-submit-invitation-button'>Submit</Localized></Button>}
            </div>
        </div>
    </div>
}

export default connect(mapStateToProps)(InvitationFormV2)