import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as a from 'actiontypes';
import { IconRejectCall, IconCalling } from 'components/icons';
import './callView.scss';
import { Localized } from "@fluent/react";

function mapStateToProps(state) {
  return {
    busy: state.webrtc.busy,
    userBusy: state.webrtc.userBusy,
    incoming: state.webrtc.incoming,
    accepted: state.webrtc.accepted,
    connected: state.webrtc.connected,

    first_name: state.websocketUser.userdata.first_name,
    last_name: state.websocketUser.userdata.last_name,
    userMediaVideoQuality: state.webrtcSettings.userMediaVideoQuality,
    userMediaVideoDeviceId: state.webrtcSettings.userMediaVideoDeviceId,
    userMediaAudioDeviceId: state.webrtcSettings.userMediaAudioDeviceId,
    userMediaDevices: state.webrtc.userMediaDevices,
    activeProfile: state.inbox.active_profile,
    private_messages: state.inbox.private_messages,
    callingUserId: state.webrtc.callingUserId,
    callingChannelName: state.webrtc.callingChannelName,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    unring: (data) => dispatch({ type: a.WRTC_RING, payload: { unring: true, ...data } }),
    callEnded: () => dispatch({ type: a.WRTC_ENDED }),
    setUserMediaAudioDeviceId: (device) => dispatch({ type: a.WRTC_SETTING_SET_USERMEDIA_AUDIO_DEVICEID, payload: device }),
    setUserMediaVideoDeviceId: (device) => dispatch({ type: a.WRTC_SETTING_SET_USERMEDIA_VIDEO_DEVICEID, payload: device }),
    updateUserMediaDevicesList: (devices) => dispatch({ type: a.WRTC_UPDATE_USERMEDIA_DEVICES_LIST, payload: devices })
  }

}




class Calling extends Component {
  componentDidMount() {
    this.props.updateUserMediaDevicesList();
  }

  render() {
    const profile = this.props.activeProfile ? this.props.private_messages.find(pr => pr.user_id === this.props.activeProfile) : null;
    const name = profile ? profile.first_name + ' ' + profile.last_name : '';

    return <div className={`call-container${this.props.darkmode ? ' darkmode' : ''}`}>
      <div className="call-pending">
        <div className="calling">
          <div className="calling-emotion">
            <IconCalling size="30" className="calling-icon" /><span className="dot-pulse"></span>
          </div>
          <span className="calling-text">
            <Localized vars={{ name: name }} id="onetoonemeet-calling-heading">
              {'calling {$name}...'}
            </Localized>
          </span>
        </div>
        {this.props.userBusy ?
          <div className="user-is-busy-info">
            <Localized id="onetoonemeet-user-is-busy-info">The user is on another call</Localized>
          </div> :
          this.props.userMediaDevices ? <div className="media-devices">
            <div className="media-device-row">
              <label className="media-device-label">
                <Localized id="onetoonemeet-video-input-label">
                  {'video input'}
                </Localized>
              </label>
              <select className="media-device-select" name="videoInput" value={this.props.userMediaVideoDeviceId} onChange={this.onVideoInputChange}>
                {this.props.userMediaDevices.videoInput.map((e) => {
                  return <option key={e.id} value={e.id}>{e.label}</option>;
                })}
              </select>
            </div>
            <div className="media-device-row">
              <label className="media-device-label">
                <Localized id="onetoonemeet-audio-input-label">
                  {'audio input'}
                </Localized>
              </label>
              <select className="media-device-select" name="audioInput" value={this.props.userMediaAudioDeviceId} onChange={this.onAudioInputChange}>
                {this.props.userMediaDevices.audioInput.map((e) => {
                  return <option key={e.id} value={e.id}>{e.label}</option>;
                })}
              </select>
            </div>

          </div> : ''}
        <button className="pending-call-cancel-button" onClick={this.cancel}><IconRejectCall /></button>
      </div>
    </div>
  }

  cancel = (e) => {
    if (!this.props.busy) return;
    if (this.props.incoming) return;
    if (this.props.accepted) return;
    this.props.unring({ user_id: this.props.callingUserId, channel_name: this.props.callingChannelName }); // Tell user to stop ringing
    this.props.callEnded();
  }

  onVideoInputChange = (ev) => { this.props.setUserMediaVideoDeviceId(ev.target.value); }
  onAudioInputChange = (ev) => { this.props.setUserMediaAudioDeviceId(ev.target.value); }
}


export default connect(mapStateToProps, mapDispatchToProps)(Calling)