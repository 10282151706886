import cloneDeep from "lodash/cloneDeep";
import * as a from "actiontypes";

export const messageInitial = {
    messageSent: false,
    messageData: null,
    reacted: false,
};

const message = (state = messageInitial, action) => {
    switch (action.type) {
        case a.WS_RESET_APPLICATION:
            return messageInitial;

        case a.WS_SEND_MESSAGE:
            // Logic for sending message is handled in websocket handler, it parses the message from store, when 'SEND_MESSAGE' action is called
            let newState = cloneDeep(state);
            let newMessage = action.payload;

            newState.messageData = newMessage;
            newState.messageSent = true;
            return newState;

        case a.WS_SEND_PRIVATE_MESSAGE:
            return { ...state };
        case a.WS_MESSAGE_SENT:
            return { ...state, messageSent: false, messageData: null };
        case a.WS_REACT_WITH_EMOJI:
            return { ...state, reacted: true };
        case a.WS_MESSAGE_REACTION:
            return { ...state, reacted: false };
        default:
            return state;
    }
};
export default message;
