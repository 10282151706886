const initialState = null;

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'EVENT_FETCHED': return action.payload;

    default: return state;
  }
};

export default eventReducer;
