import * as a from "actiontypes";
import { v4 as uuid } from 'uuid';

export const alertsInitial = []

const notificationReducer = (state = alertsInitial, action) => {
    switch (action.type) {

        case a.ADD_ALERT: {
            let newAlert = action.payload
            newAlert.id = uuid()
            return [...state, newAlert ];
        }

        case a.POP_ALERT: {
            let updatedState = state.filter(note => note.id !== action.payload)
            return updatedState
        }   

        default:
            return state;
    }
};

export default notificationReducer;
