/** @format */

import { useState, useEffect } from "react";
import { IconSendIC, IconSettings, IncognitoIcon, ShieldIcon } from "components/icons";
import * as a from "actiontypes";
import { connect } from "react-redux";
import { Localized } from "@fluent/react";
import { useRef } from "react";
import { IconTimes } from "components/icons";
import { MessageWrapper } from "./message";
import TextareaAutosize from "react-textarea-autosize";
import { makeSubstring } from "containers/VirtualEvent/editorView/helpers/mainToolbarHelpers";
import { useSilenceTimer } from "./helpers/hooks";

const mapStateToProps = state => ({
	websocketUser: state.websocketUser,
	user: state.user,
	connection: state.connection,
	config: state.config,
	quotedMsg: state.messages.replyTo,
	anonymousMode: state.moderator.anonymous_mode,
});
const mapDispatchToProps = dispatch => ({
	sendMessage: payload => dispatch({ type: a.WS_SEND_MESSAGE, payload: payload }),
	stopSilence: () => dispatch({ type: a.WS_STOP_SILENCE }),
	toggleAnonymousMode: boolean => dispatch({ type: a.WS_TOGGLE_ANONYMOUS_MODE, payload: boolean }),
	toggleModeratorMessagingMode: boolean => dispatch({ type: a.WS_TOGGLE_MODERATOR_MODE, payload: boolean }),
	replyToAMessage: payload => dispatch({ type: a.SET_QUOTED_MESSAGE, payload }),
});

const ReplyTo = props => {
	const { quotedMsg = {}, replyToAMessage } = props;
	const { message, message_id, sender_name, isAnonymous } = quotedMsg;
	const subsMsg = makeSubstring(message, 0, 99, 100);
	return (
		<div className="reply-message-container">
			<div className="message-container">
				<div className="sender">
					{isAnonymous ? <Localized id="chat-anonymous-name">Anonymous</Localized> : sender_name}
				</div>
				<div className="message">
					<MessageWrapper message={subsMsg} />
				</div>
			</div>
			<div className="unquote-button" onClick={() => replyToAMessage(null)}>
				<IconTimes size="20" />
			</div>
		</div>
	);
};

const ModeratorInfo = props => {
	return (
		<div className="moderator-message-container">
			<ShieldIcon size={30} />
			<Localized id="chat-moderator-info">
				<h3>MODERATOR MESSAGE</h3>
			</Localized>
		</div>
	);
};
const AnonymousInfo = props => {
	return (
		<div className="anonymous-message-container">
			<IncognitoIcon size={30} />
			<Localized id="chat-anonymous-info">
				<h3>ANONYMOUS MESSAGE</h3>
			</Localized>
		</div>
	);
};

function Send(props) {
	const { quotedMsg = null, replyToAMessage, anonymousMode, isTimedOut, handleTimeout, timedOut } = props;
	const {
		send_messages_as_anonymous = false,
		send_messages_as_moderator = false,
		userdata,
		silenced,
		silencedTime,
		user_id,
		is_moderator,
	} = props.websocketUser;
	const { guestuser, username = "Guest user" } = props.user;
	const { active_channel } = props.connection;
	const { first_name, last_name, icon } = userdata;
	const { restricted = false } = props.chatRoom;

	const [showSetting, setShowSetting] = useState(false);
	const [message, setMessage] = useState("");

	const [isSilenced, timeUntilUnmute] = useSilenceTimer(silenced, silencedTime, props.stopSilence);

	const chatTextAreaRef = useRef(null);

	const anonymous_mode =
		anonymousMode?.[active_channel] === null ? anonymousMode?.global : anonymousMode?.[active_channel];

	const showSettingsButton = anonymous_mode || is_moderator;

	useEffect(() => {
		let timer;
		clearInterval(timer);
		if (!timedOut && chatTextAreaRef) {
			setTimeout(() => {
				chatTextAreaRef.current.focus();
			}, 50);
		}
		if (quotedMsg && chatTextAreaRef) chatTextAreaRef.current.focus();
	}, [timedOut, quotedMsg]);

	useEffect(() => {
		if (!anonymous_mode) props.toggleAnonymousMode(false);
	}, [anonymous_mode]);

	function handleMessage(e) {
		let value = e.target.value;
		if (value.length > 500) {
			value = value.substring(0, 500);
		}
		setMessage(value);
	}

	const handleSendMessage = () => {
		if (!timedOut) {
			const sender = send_messages_as_moderator
				? "MODERATOR_MESSAGE"
				: send_messages_as_anonymous
				? "ANONYMOUS_PERSON"
				: "PERSON";

			const payload = {
				sender: sender,
				sender_name: guestuser ? username : first_name + " " + last_name,
				message: message,
				room_id: active_channel,
				icon: icon,
				user_id: user_id,
				quoted: quotedMsg,
			};
			props.sendMessage(payload);
			replyToAMessage(null);
			setMessage("");
			handleTimeout();
		}
	};

	function onKeyDown(e) {
		if (e.keyCode === 13 && message.trim().length > 0) {
			e.preventDefault();
			handleSendMessage();
		}
	}

	function sendMessage() {
		if (message.trim().length > 0) {
			handleSendMessage();
		}
	}

	return (
		<div
			className={`send-message ${
				quotedMsg || send_messages_as_moderator || send_messages_as_anonymous ? "expanded" : ""
			}`}
		>
			{restricted && !is_moderator && (
				<div className="restricted">
					<Localized id="chat-send-restricted">Chattiin kommentoiminen estetty.</Localized>
				</div>
			)}
			{send_messages_as_moderator && <ModeratorInfo />}
			{send_messages_as_anonymous && <AnonymousInfo />}
			{quotedMsg && <ReplyTo quotedMsg={quotedMsg} replyToAMessage={replyToAMessage} />}
			{/* TODO CONDITIONAL TRANSLATION FOR PLACEHOLDER */}
			{isSilenced ? (
				<Localized
					id="chat-send-message-muted"
					attrs={{ placeholder: true }}
					vars={{
						hours: timeUntilUnmute.hours,
						minutes: timeUntilUnmute.minutes,
						seconds: timeUntilUnmute.seconds,
					}}
				>
					<TextareaAutosize
						className="send-input"
						disabled={true}
						placeholder={"You have been muted for {$hours}:{$minutes}:{$seconds}"}
						value={message}
						onChange={e => handleMessage(e)}
						onKeyDown={e => onKeyDown(e)}
						rows={1}
					/>
				</Localized>
			) : (
				<Localized id="chat-send-message" attrs={{ placeholder: true }}>
					<TextareaAutosize
						className={`send-input ${
							quotedMsg || send_messages_as_moderator || send_messages_as_anonymous ? "quoted" : ""
						}`}
						disabled={isTimedOut}
						placeholder={"Type a message.."}
						value={message}
						onChange={e => handleMessage(e)}
						onKeyDown={e => onKeyDown(e)}
						ref={chatTextAreaRef}
						rows={1}
					/>
				</Localized>
			)}

			<div className={`send-message-actions ${showSettingsButton ? "" : "flex-end"}`}>
				{showSettingsButton && (
					<button className="settings-button" onClick={() => setShowSetting(!showSetting)}>
						<IconSettings size={25} />
					</button>
				)}
				{showSetting && (
					<div className="togglers">
						{anonymous_mode && (
							<label>
								<input
									onChange={() => props.toggleAnonymousMode(!send_messages_as_anonymous)}
									type="checkbox"
									checked={send_messages_as_anonymous}
									disabled={send_messages_as_moderator}
								/>
								<span>
									<Localized id="chat-anonymous-name">Anonymous mode</Localized>
								</span>
							</label>
						)}

						{is_moderator && (
							<label>
								<input
									onChange={() => props.toggleModeratorMessagingMode(!send_messages_as_moderator)}
									type="checkbox"
									checked={send_messages_as_moderator}
									disabled={send_messages_as_anonymous}
								/>
								<span>
									<Localized id="moderator-message-checkbox-label">Moderator message</Localized>
								</span>{" "}
							</label>
						)}
					</div>
				)}
				<div className="button-containder">
					<div className="char-counter">{message.length}/500</div>
					<button
						className="send-message-button"
						disabled={isSilenced || isTimedOut}
						onClick={() => sendMessage()}
					>
						<IconSendIC />
					</button>
				</div>
			</div>
		</div>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Send);
