import React from 'react';
import ReactPlayer from 'react-player';
import ElementWrapper from 'containers/VirtualEvent/wrappers/elementWrapper';

export default function Video(props) {
    const { elem, editorMode } = props;
    const fallbackSettings = { autoPlay: false, loop: false };
    const { content, properties } = elem;
    const { loop, autoPlay } = properties?.['videoSettings'] ?? fallbackSettings;
    const wrapperStyle = { 
        backgroundColor: '#131313', 
        width: '100%', 
        paddingBottom: '56.25%', 
        position: 'relative', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        zIndex: 2,
        pointerEvents: editorMode ? 'none' : ''
    }
    const streamStyle = {
        width: "100%",
        height: "100%",
        position: "absolute",
        backgroundColor: 'transparent',
        top: "0",
        left: "0",
    };
    const playerConfigs = {
        youtube: {
            playerVars: { showinfo: 0, controls: 1, autoplay: autoPlay && !editorMode ? 1 : 0, iv_load_policy: 0, modestbranding: 0, rel: 0 }
        },
        vimeo: {
            playerOptions: { autoplay: autoPlay && !editorMode, controls: true }
        }
    }
    const wrapperProps = {
        elem: props.elem,
        overrideStyle: wrapperStyle,
        className: `video-element-wrapper ${props.elem.i}`
    }

    let streamComponent = <ReactPlayer loop={loop} style={streamStyle}className="video-embed" url={content} config={playerConfigs} width="100%" height="100%" />;
    
    return <ElementWrapper {...wrapperProps}>
        {streamComponent}
    </ElementWrapper>
}