import { Localized } from "@fluent/react"
import { IconCalendarCheck, IconTrophy, IconMore, IconTimes, IconCheck } from "components/icons"
import { removeNotification } from "middlewares/websocket"
import moment from "moment"
import { useEffect, useState } from "react"
import { useHistory } from "react-router";
import { calculateWhen } from "../helpers/dateHelpers"
import { useAccessKey } from "containers/VirtualEvent/editorView/apiCalls"
import { connect, useDispatch, useSelector } from "react-redux"
import * as a from 'actiontypes'
import { IconTrash } from "components/icons"
import { cancelInvite } from "containers/VirtualEvent/schedule/apiCalls"
import { GenericTooltip } from "components/ToolTip/Tooltip"

const mapDispatchToProps = dispatch => ({
    closeModals: () => dispatch({ type: a.HANDLE_MODALS, payload: true }),
    changeView: target => dispatch({ type: a.WS_CHANGE_VIEW, payload: target }),
    toggleProfile: payload => dispatch({ type: a.WS_TOGGLE_PROFILE, payload: payload }),
    closeNotificationCenter: () => dispatch({ type: a.HANDLE_NOTIFICATION_CENTER, payload: false })
})

const mapStateToProps = state => ({
    slots: state.schedule.calendar.slots,
    received_invites: state.schedule.calendar.received_invites,
    eventSlug: state.event.slug,
    myChatID: state.user.chat_user_id
})

function inviteTime(start_time, end_time) {
    return `${moment(start_time).format('HH:mm')} - ${moment(end_time).format('HH:mm, DD.MM.YYYY')}`

}



const WhenComponent = ({ date }) => {
    return (
        <div className="when-row">
            {calculateWhen(date)}
        </div>
    )
}

const FromToTimeComponent = ({ slot }) => {
    return <div className="more-info-row">
        <label className="label">
            <Localized id="notification-center-invite-when">
                When
            </Localized>
        </label>
        <div className="value">
            {inviteTime(slot.start_time, slot.end_time)}
        </div>
    </div>
}

export const NotificationInvite = connect(mapStateToProps, mapDispatchToProps)((props) => {
    const dispatch = useDispatch()
    const [inviteData, setInviteData] = useState(null)
    const {
        notification,
        handleInvitation,
        slots,
        received_invites,
        eventSlug,
        myChatID
    } = props
    const { id, data } = notification
    const [slotData, setSlotData] = useState(null)

    const [showMore, setShowMore] = useState(false)
    useEffect(() => {
        if (inviteData) {
            const isInviteMine = (inviteData.status === 'pending' && inviteData.inviter_id !== myChatID) || (inviteData.status === 'rejected' || inviteData.status === 'cancelled')

            if (!isInviteMine && slots && received_invites) {
                let slotToCancel = slots.find(s => (s.id === inviteData.slot_id && s.start_time && inviteData.start_time))
                if (slotToCancel?.sent_invites) slotToCancel.sent_invites[0].user = { first_name: inviteData.first_name, last_name: inviteData.last_name, user_id: inviteData.user_id }
                if (slotToCancel) {
                    setSlotData(slotToCancel)
                } else {
                    const inviteToCancel = received_invites.find(inv => inv.slot?.id === inviteData.slot_id)
                    if (inviteToCancel) {
                        let duplicateSlot = inviteToCancel.slot
                        duplicateSlot.isDuplicate = true
                        duplicateSlot.received_invite = inviteToCancel
                        setSlotData(duplicateSlot)
                    }
                }
            }
        }
    }, [inviteData])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (data.sent_invites) {
            const { description, id: slot_id, name, sent_invites, start_time, end_time, inviter_id } = data
            const { message, user, status } = sent_invites[0]
            const { first_name, last_name, user_id } = user
            const dataObject = { first_name, last_name, user_id, status, message, slot_id, name, description, start_time, end_time, notification_id: id, inviter_id }
            dataObject.type = "sent_invite"
            setInviteData(dataObject)
        } else {
            const { user, slot, message, status, inviter_id } = data
            const { first_name, last_name, user_id } = user
            const { start_time, end_time, id: slot_id, name, description } = slot
            const dataObject = { first_name, last_name, user_id, status, message, slot_id, name, description, start_time, end_time, notification_id: id, inviter_id }
            dataObject.type = "received_invite"
            setInviteData(dataObject)
        }
    }, [data])// eslint-disable-line react-hooks/exhaustive-deps

    const handleCancelInvite = () => {
        if (slotData) {
            cancelInvite(slotData, eventSlug, dispatch)
        }
    }

    return (
        <div className="notification-center-invite-type">
            {inviteData && <div className="notification-content">
                <div className="notification-info">
                    <div className="notification-body">
                        <IconCalendarCheck size="25" className={`notification-icon ${inviteData.status}`} />
                        {(inviteData.inviter_id === myChatID) ?
                            <p><Localized id="notification-invite-from-me" vars={{ name: `${inviteData.first_name} ${inviteData.last_name}` }} /></p> :
                            <p><Localized id="notification-invite-to-me" vars={{ name: `${inviteData.first_name} ${inviteData.last_name}` }} /></p>
                        }
                    </div>
                    <div className="notification-timestamp">
                        <WhenComponent date={notification.ts} />
                    </div>
                </div>
                <div className={`notification-more-info${showMore ? ' show' : ''}`}>
                    <div className="more-info-row">
                        <label className="label">
                            <Localized id="notification-center-invite-title">
                                Title
                            </Localized>
                        </label>
                        <div className="value">{inviteData.name}</div>
                    </div>

                    <FromToTimeComponent slot={inviteData} />

                    <div className="more-info-row">
                        <label className="label">
                            <Localized id="notification-center-invite-message">
                                Message
                            </Localized>
                        </label>
                        <div className="value">
                            {inviteData.message}
                        </div>
                    </div>
                    <div className="more-info-row">
                        <label className="label">
                            <Localized id="notification-center-invite-description">
                                Description
                            </Localized>
                        </label>
                        <div className="value">
                            {inviteData.description}
                        </div>
                    </div>
                </div>

                <div className="notification-actions">
                    <div className="button-group invite">
                        {inviteData.status !== 'pending' && <div className={inviteData.status}>
                            {inviteData.status === 'accepted' && <div className="accepted-button-group">
                                <div className="accepted">
                                    <IconCheck size="14" />
                                    <Localized id="notification-center-invite-status-accepted">Accepted</Localized>
                                </div>
                                <button className={`notification-button decline`} onClick={handleCancelInvite}>
                                    <Localized id="invite-cancel-button">Cancel</Localized>
                                </button>
                            </div>}
                            {inviteData.status === 'rejected' && <>
                                <IconTimes size="14" />
                                <Localized id="notification-center-invite-status-rejected">Rejected</Localized>
                            </>}
                            {inviteData.status === 'cancelled' && <>
                                <IconTimes size="14" />
                                <Localized id="notification-center-invite-status-cancelled">Cancelled</Localized>
                            </>}
                        </div>}


                        {(inviteData.status === 'pending' && inviteData.inviter_id !== myChatID) && <>
                            <button className={`notification-button accept`} onClick={(e) => handleInvitation(e, inviteData, true)} >
                                <Localized id="invite-accept-button">Accept</Localized>
                            </button>
                            <button className={`notification-button decline`} onClick={(e) => handleInvitation(e, inviteData, false)}>
                                <Localized id="invite-decline-button">Reject</Localized>
                            </button>
                        </>}
                        {(inviteData.status === 'pending' && inviteData.inviter_id === myChatID) && <>
                            <button className={`notification-button decline`} onClick={handleCancelInvite}>
                                <Localized id="invite-cancel-button">Cancel</Localized>
                            </button>
                        </>}

                    </div>
                    <div className="button-group">
                        <GenericTooltip text={<Localized id="notification-center-more-tooltip" />} hide="100">
                            <button className="notification-button more" onClick={() => setShowMore(!showMore)}>
                                <IconMore />
                            </button>
                        </GenericTooltip>
                        <GenericTooltip text={<Localized id="notification-center-remove-tooltip" />} hide="100">
                            <button className="notification-button more" onClick={() => removeNotification(id)}>
                                <IconTrash />
                            </button>
                        </GenericTooltip>
                    </div>
                </div>
            </div>}
        </div>
    )
})

const AchievementTitle = ({ achievement }) => {
    return <>
        <Localized id="notification-center-engagement-new-achievement-header" />: <Localized id={`engagement-new-achievement-${achievement}`} />
    </>
}

const MoreAchievementInfo = ({ achievement }) => {
    return <div className="more-info-row">
        <label className="label">
            <Localized id="notification-center-invite-description">
                Description
            </Localized>
        </label>
        <div className="value">
            <Localized id={`engagement-new-achievement-${achievement}-description`} />
        </div>
    </div>
}

export const NotificationEngagement = (props) => {
    const { notification } = props
    const { data, id } = notification
    const [engagementNotification, setEngagementNotification] = useState("")
    const [showMore, setShowMore] = useState(false)



    const buildAchievementObject = (achievement) => {
        const title = <AchievementTitle achievement={achievement} />
        const more = <MoreAchievementInfo achievement={achievement} />
        const component = { title, more }
        return component
    }

    useEffect(() => {
        if (data) {
            switch (data.type) {
                case "OwnEventLevelChanged": {
                    const title = <Localized id="new-level-header" />
                    const more = <div className="more-info-row">
                        <label className="label">
                            <Localized id="notification-center-invite-description">
                                Description
                            </Localized>
                        </label>
                        <div className="value">
                            <Localized id="engagement-new-level" vars={{ level: data.level }} />
                        </div>
                    </div>
                    const newLevel = { title, more }
                    setEngagementNotification(newLevel)
                    break;
                }

                case "EventAchievementUnlocked": {
                    setEngagementNotification(buildAchievementObject(data.achievementInfo.name))
                    break;
                }

                default:
                    break;
            }
        }
    }, [data])

    return (
        <div className="notification-center-other-type">
            <div className="notification-content">
                <div className="notification-info">
                    <div className="notification-body">
                        <IconTrophy size="25" className="notification-icon" />
                        <p>{engagementNotification.title}</p>
                    </div>
                    <div className="notification-timestamp">
                        <WhenComponent date={notification.ts} />
                    </div>
                </div>

                <div className={`notification-more-info${showMore ? ' show' : ''}`}>
                    {engagementNotification.more}
                </div>

                <div className="notification-actions">
                    <div className="button-group engagement">
                        <GenericTooltip text={<Localized id="notification-center-more-tooltip" />} hide="100">
                            <button className="notification-button more" onClick={() => setShowMore(!showMore)}>
                                <IconMore />
                            </button>
                        </GenericTooltip>
                        <GenericTooltip text={<Localized id="notification-center-remove-tooltip" />} hide="100">
                            <button className="notification-button more" onClick={() => removeNotification(id)}>
                                <IconTrash />
                            </button>
                        </GenericTooltip>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const NotificationCalendar = connect(null, mapDispatchToProps)((props) => {
    const { notification, toggleProfile, changeView, closeModals, closeNotificationCenter } = props
    const { data, id, event, type } = notification
    const [calendarNotification, setCalendarNotification] = useState(null)
    const accessKey = useAccessKey()
    const history = useHistory()
    const [showMore, setShowMore] = useState(false)
    const attendees = useSelector(state => state.all_attendees)

    const handleProceedToProfile = (e, user_id) => {
        const attendee = attendees.find(a => a.chat_user_id === user_id || a.user_id === user_id)
        if (attendee) {
            closeModals()
            closeNotificationCenter()
            changeView('inbox')
            toggleProfile(attendee)
        }
    }

    const handleProceedToEvent = () => {
        history.push(`/${event}/${data.link}${accessKey}`)
    }

    useEffect(() => {
        if (data) {
            if (type === 'event_reminder') {
                const slot = data
                const title = <Localized id='notification-center-event-reminder-header' vars={{ event_name: slot.name }} />
                const button = <button className={`notification-button accept`} onClick={handleProceedToEvent} >
                    <Localized id="notification-center-proceed-button">Proceed</Localized>
                </button>
                const more = <>
                    <FromToTimeComponent slot={slot} />

                    {slot.description && <div className="more-info-row">
                        <label className="label">
                            <Localized id="notification-center-invite-description">
                                Description
                            </Localized>
                        </label>
                        <div className="value">
                            {slot.description}
                        </div>
                    </div>}

                </>
                const component = { title, more, button }

                setCalendarNotification(component)
            } else {
                const slot = data.sent_invites ? data : data.slot
                const user = data.sent_invites ? data.sent_invites[0].user : data.user
                const title = <Localized id='notification-center-meeting-reminder-header' vars={{ meeting: slot.name }} />
                const button = <button className="notification-button accept" onClick={(e) => handleProceedToProfile(e, user.user_id)} >
                    <Localized id='sidebar-proceed-to-profile' />
                </button>
                const more = <>
                    <div className="more-info-row">
                        <label className="label">
                            <Localized id="notification-center-invite-user">
                                User
                            </Localized>
                        </label>
                        <div className="value">
                            {`${user.first_name} ${user.last_name}`}
                        </div>
                    </div>

                    <FromToTimeComponent slot={slot} />

                </>
                const component = { title, more, button }
                setCalendarNotification(component)
            }
        }
    }, [data])
    return (
        <div className="notification-center-other-type">
            {calendarNotification && <div className="notification-content">
                <div className="notification-info">
                    <div className="notification-body">
                        <IconCalendarCheck size="25" className="notification-icon" />
                        <p>{calendarNotification.title}</p>
                    </div>
                    <div className="notification-timestamp">
                        <WhenComponent date={notification.ts} />
                    </div>
                </div>

                <div className={`notification-more-info${showMore ? ' show' : ''}`}>
                    {calendarNotification.more}
                </div>


                <div className="notification-actions">
                    {(data.link || type === 'meeting_reminder') && <div className="button-group">
                        {calendarNotification.button}
                    </div>}

                    <div className={`button-group ${(!data.link && type === 'event_reminder') ? 'engagement' : ''}`}>
                        <GenericTooltip text={<Localized id="notification-center-more-tooltip" />} hide="100">
                            <button className="notification-button more" onClick={() => setShowMore(!showMore)}>
                                <IconMore />
                            </button>
                        </GenericTooltip>
                        <GenericTooltip text={<Localized id="notification-center-remove-tooltip" />} hide="100">
                            <button className="notification-button more" onClick={() => removeNotification(id)}>
                                <IconTrash />
                            </button>
                        </GenericTooltip>
                    </div>
                </div>
            </div>}
        </div>
    )
})