import moment from 'moment';

const getSnowplowCtx = (state) => {
    const {displayType, event, user, room, websocketUser} = state;
    let role;
    if (user.type === 'guest') {
        role = 'guest';
    }
    else if (user.type === 'authenticated') {
        if (websocketUser.is_moderator) {
            // TODO get superuser or organizer status from api instead of this wacky stuff
            if (user.email.endsWith('@liveto.io')) {
                role = 'staff';
            }
            else {
                role = 'moderator';
            }
        }
        else {
            role = 'user';
        }
    }

    if (!role) {
        console.warn("couldn't determine role, falling back to user");
        role = 'user';
    }

    return [{
        schema: 'iglu:io.liveto/virtual_event/jsonschema/1-0-0',
        data: {
            event: event.slug,
            room: `/${room.data.slug}`,
            private: event.is_private,
            active: moment().isBetween(event.start_time, event.end_time),
            displayType: displayType,
            role: role,
        }
    }];
};

export default function analyticsMiddleware({dispatch, getState}) {
    return next => action => {
        if (!window.sp) return next(action);

        switch (action.type) {
            // Initialize snowplow tracker when lobby is loaded
            case 'LOBBY_LOADED': {
                if (window.sp) {
                    const COOKIE_PREFIX = process.env.REACT_APP_COOKIE_PREFIX;
                    const sp = window.sp;
                    const allow_pii = (document.cookie || '').indexOf(`${COOKIE_PREFIX}_auth`) !== -1;
                    const allow_cookies = allow_pii || (document.cookie || '').indexOf('cookie_consent=true') !== -1;
                    sp('newTracker', 'v', process.env.REACT_APP_COLLECTOR_DOMAIN, {
                        appId: 'virtual-event',
                        platform: 'web',
                        contexts: { webPage: true },
                        anonymousTracking: allow_pii ? false : { withSessionTracking: allow_cookies, withServerAnonymisation: true },
                        eventMethod: 'post',
                        stateStorageStrategy: allow_cookies ? 'cookieAndLocalStorage' : 'none',
                        bufferSize: 1,
                        forceSecureTracker: true,
                    });
                    sp('enableActivityTracking:v', 30, 30);
                    if (allow_pii) {
                        const user = (document.cookie || '').match(`${COOKIE_PREFIX}_cuid=([a-zA-Z0-9]+);?`);
                        if (user) sp('setUserId:v', user[1]);
                    }
                    window.sp_tracking = true;
                }
                break;
            }
            
            // Send page view for each room visit
            case 'ROOM_LOADED': {
                if (window.sp_tracking)
                    window.sp('trackPageView:v', null, getSnowplowCtx(getState()));
                
                break;
            }

            // Send events from sent chat messages, PMs and reactions
            case 'SEND_MESSAGE': {
                if (window.sp_tracking)
                    window.sp('trackStructEvent:v',
                        'chat',                  // category
                        'message-sent',          // action
                        action.payload.room_id,  // label (room id)
                        '',                      // property
                        String(action.payload.message.length), // value (msg length)
                        getSnowplowCtx(getState())
                    );
                break;
            }
            case 'SEND_PRIVATE_MESSAGE': {
                // track less metadata about private conversations by design
                if (window.sp_tracking)
                    window.sp('trackStructEvent:v',
                        'chat',             // category
                        'private-message',  // action
                        '',                 // label
                        '',                 // property
                        '',                 // value
                        getSnowplowCtx(getState())
                    );
                break;
            }
            case 'REACT_WITH_EMOJI': {
                if (window.sp_tracking)
                    window.sp('trackStructEvent:v',
                        'chat',     // category
                        'reaction', // action
                        action.payload.room_id, // label (room id)
                        action.payload.reaction, // property (reaction)
                        '', // value (TODO: +1/-1 depending on if reaction was added or removed?)
                        getSnowplowCtx(getState())
                    );
                break;
            }

            // Send events from created invites and accepted invites, both from the inviter's client
            case 'CREATE_SLOT': {
                if (window.sp_tracking)
                    window.sp('trackStructEvent:v',
                        'networking',   // category
                        'invite',       // action
                        '',             // label
                        '',             // property
                        '',             // value (TODO: slot length?)
                        getSnowplowCtx(getState())
                    );
                break;
            }
            case 'HANDLE_INVITE_RESPONSE': {
                if (window.sp_tracking && action.payload.isMine && action.payload.invite.status === 'accepted')
                    window.sp('trackStructEvent:v',
                        'networking',   // category
                        'meeting-booked', //action
                        '',             // label
                        '',             // property
                        '',             // value
                        getSnowplowCtx(getState())
                    );
                break;
            }

            // Send event from caller when 1on1 call ends
            case 'WRTC_ENDED': {
                if (window.sp_tracking) {
                    const state = getState();
                    // TODO: I think this incoming bool tells exactly the opposite of what it means.
                    // When incoming === true, we are the caller, and when it's false we are the callee
                    // Bunch of logic in the actual application seems to rely on it though and it works so I'm too scared to change it.
                    if (state.webrtc.incoming === true) {
                        window.sp('trackStructEvent:v',
                            'networking',   // category
                            'call',         // action
                            '',             // label
                            '',             // property
                            String(Math.round((new Date().getTime() - state.webrtc.callStartedAt.getTime()) / 1000)), // value (call length in secs)
                            getSnowplowCtx(state)
                        );
                    }
                }
                break;
            }

            default: break;
        }
        return next(action);
    };
}