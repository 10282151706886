import { AUTH_COOKIE, HOST } from 'constants.js';
import { getCookie } from './cookies';

async function getTurnServer() {
  const cookie = getCookie(AUTH_COOKIE);
  if (!cookie) return; // Nothing to do if we aren't authenticated
  const r = await fetch(`${HOST}api/turn/`, {
    headers: {
      'Authorization': 'Token ' + cookie,
    },
  });
  const j = await r.json();
  if (r.status !== 200 && j.detail) throw new Error('API ERROR: ' + j.detail);
  return {
    urls: 'turn:coturn.liveto.io:443?transport=tcp', // TODO: put into config variable / request?
    username: j.username,
    credential: j.credential
  };
}

export async function getIceServers() {
  const servers = [];

  servers.push({
    urls: 'stun:stun.l.google.com:19302',
  });

  servers.push({
    urls: 'stun:meet-jit-si-turnrelay.jitsi.net:443',
  });

  try {
    const turn = await getTurnServer();
    if (turn) servers.push(turn);
  } catch (err) {
    console.warn('getIceServers, getTurnServer Error:', err.message);
  }

  console.log('getIceServers', servers);
  return servers;
}

// getIceServers(); // Testing