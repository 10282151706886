const formatStringToCamelCase = str => {
    const splitted = str.split("-");
    if (splitted.length === 1) return splitted[0];
    return (
        splitted[0] +
        splitted
            .slice(1)
            .map(word => word[0].toUpperCase() + word.slice(1))
            .join("")
    );
};

export const getStyleObjectFromString = str => {
    const style = {};
    str.split(";").forEach(el => {
        const [property, value] = el.split(":");
        if (!property) return;

        const formattedProperty = formatStringToCamelCase(property.trim());
        style[formattedProperty] = value.trim();
    });

    return style;
};

export const gridifyComponent = cmp => {
    const grid = {
      x: (cmp.x || 0) + 1,
      y: (cmp.y || 0) + 1,
      w: (cmp.w || 0),
      h: (cmp.h || 0)
    };
    return `${grid.y} / ${grid.x} / ${grid.y + grid.h} / ${grid.x + grid.w}`
}