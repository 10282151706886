import React, { useEffect } from 'react';
import EditorTree from "../containers/tree";
import SiteMap from "../containers/sitemap";
import History from '../containers/history';
import { useContext } from 'react';
import { SettingContext } from 'containers/VirtualEvent/contexts';
import Templates from "../containers/templates";

const NavigationLeft = (props) => {
    const {
        darkMode,
        leftNavView,
        setLeftNavView,
        history,
        historyLabels,
        historyTravelTo,
        currentPointer,
        userType, 
        organizer
    } = props;
    const settingsContext = useContext(SettingContext);
    const {
        setActiveItem,
        editorDispatch,
        streams, 
        meets,
        activeItem
    } = settingsContext;

    useEffect(() => {
        if (leftNavView !== "components") setLeftNavView("components")
    }, [activeItem])

    return (
    <div className={`editor-navigation-left ${darkMode ? 'dark' : ''}`}>
        <div className={`editor-navigation-left-links`}>
            <div onClick={() => setLeftNavView("sitemap")} className={`navleft-link link-rooms ${leftNavView === 'sitemap' ? 'active' : ''}`}>Rooms</div>
            <div onClick={() => setLeftNavView("components")} className={`navleft-link link-tree ${leftNavView === 'components' ? 'active' : ''}`}>Components</div>
            <div onClick={() => setLeftNavView("history")} className={`navleft-link link-history ${leftNavView === 'history' ? 'active' : ''}`}>History</div>
            <div onClick={() => setLeftNavView("templates")} className={`navleft-link link-templates ${leftNavView === 'templates' ? 'active' : ''}`}>Templates</div>
        </div>
        <SiteMap />
        <EditorTree />
        <History history={history} historyLabels={historyLabels} historyTravelTo={historyTravelTo} currentPointer={currentPointer} />
        <Templates userType={userType} organizer={organizer} setActiveItem={setActiveItem} editorDispatch={editorDispatch} streams={streams} meets={meets} />
    </div>
    )
}

export default NavigationLeft;