import { IconTimes, LivetoLoader } from "components/icons";
import { AUTH_COOKIE_TIMESTAMP, AUTH_COOKIE, CUID_COOKIE } from "../../../constants";
import HeaderModal from "headingModal/modal";
import { useDispatch, useSelector } from "react-redux";
import { createCookie } from "util/cookies";
import { refreshAuthToken } from "../apiCalls";
import './warning.scss'
import { useState, useEffect } from "react";
import { statusHandler } from "../editorView/errors";
import { useSaveRoom } from "../editorView/apiCalls";
import { Localized } from "@fluent/react";


const WarningModal = (props) => {
    const { isEditor, slug, room_slug, editorData, editorDispatch } = props
    const { session = {} } = useSelector(state => state.user)
    const { expiring = false, ignored = false } = session
    const dispatch = useDispatch()
    const [info, setInfo] = useState({ msg: '', error: true })
    const [saved, setSaved] = useState(false)
    const [loading, success, show, status] = useSaveRoom(slug, room_slug, saved, setSaved, editorData, editorDispatch);

    useEffect(() => {
        if (success) setInfo({ msg: <Localized id="error-session-warning-saved">Saved</Localized>, error: false })
        else setInfo({ msg: statusHandler(status), error: true })
    }, [loading])

    const ignore = () => {
        dispatch({ type: "SESSION_IGNORED", payload: true });
    }

    const saveRoom = () => {
        setSaved(true)
    }

    const refreshToken = async () => {
        const [ok, error, data, status] = await refreshAuthToken()
        if (ok) {
            dispatch({ type: "SESSION_EXPIRING", payload: false });
            createCookie(CUID_COOKIE, data.chat_user_id, 1);
            createCookie(AUTH_COOKIE, data.token, 1);
            createCookie(AUTH_COOKIE_TIMESTAMP, Date.now(), 1);
            window.location.href = window.location.href;
        }
        const statusErrMsg = statusHandler(status)
        if (statusErrMsg) setInfo({ msg: statusErrMsg, error: true })
        if (error) {
            setInfo({ msg: error.message, error: true })
        }
    }

    return <HeaderModal showModal={(expiring && !ignored)} setShowModal={ignore} id="session-warning" modalClassName="warning-modal">
        <div className="warning-card">
            <div className="warning-card-header">
                <div className="warning-card-title">
                    <Localized id="error-session-warning-title">Session is expiring</Localized>
                </div>
                <div className="close-button" onClick={ignore}>
                    <IconTimes />
                </div>
            </div>
            <div className="warning-card-body">
                <p><Localized id="error-session-warning-body">Your session will expire within 2 hours</Localized></p>
                {isEditor ? <p><Localized id="error-session-warning-body-editor">please save your work before refreshing the session</Localized></p> :
                    <p><Localized id="error-session-warning-body-virtual">please refresh you session</Localized></p>}
            </div>
            <div className="warning-card-footer">
                <div className={`info ${info?.error ? 'error' : ''}`}>
                    {loading ? <LivetoLoader size="50" /> : info.msg}
                </div>
                <div className="buttons">
                    {isEditor && <button className="refresh-session generic-liveto accept" onClick={saveRoom}>
                        <Localized id="error-session-warning-save-button">Save</Localized>
                    </button>}
                    <button className="refresh-session generic-liveto accept" onClick={refreshToken}>
                        <Localized id="error-session-warning-refresh-button">Refresh</Localized>
                    </button>
                </div>
            </div>
        </div>
    </HeaderModal>
}

export default WarningModal