import { cloneDeep } from "lodash";
import * as a from "actiontypes";

export const attendeesInitial = [];

function sortAttendees(list) {
    return list.sort((a, b) => {
        const user1 = a.type === "anonymous" ? a.username : a.first_name;
        const user2 = b.type === "anonymous" ? b.username : b.first_name;
        if (user1 < user2) {
            return -1;
        }
        if (user1 > user2) {
            return 1;
        }
        return 0;
    });
}

const attendees = (state = attendeesInitial, action) => {
    switch (action.type) {
        case a.WS_RESET_APPLICATION:
            return attendeesInitial;

        case a.WS_ATTENDEES_REQUESTED:
            let newState = cloneDeep(action.payload.attendees);
            newState = sortAttendees(newState);
            return newState;

        case a.WS_USER_JOINED_CHANNEL: {
            let newState = cloneDeep(state);
            let person = newState.find((person) => person.user_id === action.payload.user_id);
            if (!person) {
                newState.push(action.payload);
            }
            newState = sortAttendees(newState);
            return newState;
        }
        default:
            return state;
    }
};
export default attendees;
