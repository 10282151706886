import styled from 'styled-components'

export const NodeText = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: 100%;
  display: flex;
  align-items: center;
`
