import React, { useState } from "react";
import '../styles/widgets.scss';

const EmbedWidget = (props) => {
    const { identifiers, editContent, content, editLocks, editorDispatch, room } = props;
    const { script = "", container = "" } = content;
    const [updatedScript, setUpdatedScript] = useState(script)
    const [updatedContainer, setUpdatedContainer] = useState(container)

    const changeScript = () => {
        const { container = "" } = props.content;
        if (updatedScript !== content) {
            const content = {
                script: updatedScript,
                container: container
            };
            editContent(identifiers, content, editorDispatch, room, editLocks);
        }
    }
    const changeContainer = () => {
        const { script = "" } = props.content;
        if (updatedContainer !== content) {
            const content = {
                script: script,
                container: updatedContainer
            };
            editContent(identifiers, content, editorDispatch, room, editLocks);
        }

    }
    return (
        <div className="tool-inner-container">
            EMBED
            <div className="tools">
                <div>
                    Embed script to (Head)
                    <textarea style={{ width: '100%', fontSize: '10px' }} rows="10" onChange={e => setUpdatedScript(e.target.value)} value={updatedScript}></textarea>
                    <button className={`editor-menu-button ${script === updatedScript ? 'disabled' : ''}`} onClick={changeScript}>Submit</button>
                </div>
                <div className="widget-row column">
                    Script content to embed to layout
                    <textarea style={{ width: '100%', fontSize: '10px' }} rows="10" onChange={e => setUpdatedContainer(e.target.value)} value={updatedContainer}></textarea>
                    <button className={`editor-menu-button ${container === updatedContainer ? 'disabled' : ''}`} onClick={changeContainer}>Submit</button>
                </div>
            </div>

        </div>
    )
}

export default EmbedWidget



