import React, { useEffect, useState } from "react";
import '../styles/widgets.scss';

const fallbackShadow = 'none';

export default function BoxShadowWidget(props) {
    const { identifiers, editProperties, properties, editLocks, editorDispatch, room } = props;
    const property = 'boxShadow';
    const value = properties[property] ? properties[property] : fallbackShadow;
    const shadows = {
        sm: 'rgb(0 0 0 / 20%) 0px 1px 4px 0px',
        sm_inset: 'rgb(0 0 0 / 20%) 0px 1px 4px 0px inset',
        md: 'rgb(0 0 0 / 20%) 0 4px 8px 0',
        md_inset: 'rgb(0 0 0 / 20%) 0 4px 8px 0 inset',
        lg: 'rgb(0 0 0 / 20%) 0px 11px 14px 0px',
        lg_inset: 'rgb(0 0 0 / 20%) 0px 11px 14px 0px inset',
        none: 'none'
    }


    const [shadow, setShadow] = useState(getShadowKey(value));

    function getShadowKey(shadowValue) {
        for (let key in shadows) {
            if (shadows[key] === shadowValue) {
                return key;
            }
        }
    }

    function editProperty(value) {
        editProperties(identifiers, property, shadows[value], editorDispatch, room, editLocks);
    }

    useEffect(() => {
        setShadow(getShadowKey(value))
    }, [value, identifiers, editProperties, property]);

    return (
        <div className="tool-inner-container">
            <div className="tool">
                <button className={"simple-button" + (shadow === 'none' ? ' active' : '')} onClick={() => editProperty("none")}>
                    No shadow
                </button>
            </div>
            <div className="tool">
                <button className={"simple-button" + (shadow === 'sm' ? ' active' : '')} onClick={() => editProperty("sm")}>
                    Small
                </button>
            </div>
            <div className="tool">
                <button className={"simple-button" + (shadow === 'sm_inset' ? ' active' : '')} onClick={() => editProperty("sm_inset")}>
                    Small (inside)
                </button>
            </div>
            <div className="tool">
                <button className={"simple-button" + (shadow === 'md' ? ' active' : '')} onClick={() => editProperty("md")}>
                    Medium
                </button>
            </div>
            <div className="tool">
                <button className={"simple-button" + (shadow === 'md_inset' ? ' active' : '')} onClick={() => editProperty("md_inset")}>
                    Medium (inside)
                </button>
            </div>
            <div className="tool">
                <button className={"simple-button" + (shadow === 'lg' ? ' active' : '')} onClick={() => editProperty("lg")}>
                    Large
                </button>
            </div>
            <div className="tool">
                <button className={"simple-button" + (shadow === 'lg_inset' ? ' active' : '')} onClick={() => editProperty("lg_inset")}>
                    Large (inside)
                </button>
            </div>
        </div>);
};