import { AUTH_COOKIE } from "constants.js";
import { getCookie } from "./cookies";

function encodeQueryParam(key, value) {
  const prefix = encodeURIComponent(key) + '=';
  let components = [];
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return undefined;
    }
    for (let i = 0; i < value.length; ++i) {
      components.push(prefix + encodeURIComponent(value[i]));
    }
  } else {
    if (!value) {
      return undefined;
    }
    components.push(prefix + encodeURIComponent(value));
  }

  return components.join('&');
}

export function encodeQueryParams(params) {
  const query = Object.keys(params)
    .map(k => encodeQueryParam(k, params[k]))
    .filter(x => x !== undefined)
    .join('&');
  if (query !== '') {
    return '?' + query;
  } else {
    return '';
  }
}

export const Get = (path, params = {}, headers) => apicall(path + encodeQueryParams(params), 'GET', null, headers);
export const Post = (path, params = {}, data, headers) => apicall(path + encodeQueryParams(params), 'POST', data, headers);
export const Patch = (path, params = {}, data, headers) => apicall(path + encodeQueryParams(params), 'PATCH', data, headers);

export const apicall = async (path, method, data, headers) => {
  return fetch(
    path, {
    method: method,
    headers: headers,
    body: data ? JSON.stringify(data) : null
  }
  )
};

export const AUTH_HEADERS = {
  'Authorization': `Token ${getCookie(AUTH_COOKIE)}`,
  'Content-Type': 'Application/json'
}

export const IMAGE_UPLOAD_HEADERS = {
  'Authorization': `Token ${getCookie(AUTH_COOKIE)}`,
}