import * as a from "actiontypes";

export const close_initial = false

const closeModals = (state = close_initial, action) => {
    switch (action.type) {

        case a.HANDLE_MODALS: {
            return action.payload
        }

        default:
            return state;
    }
};

export default closeModals;
