/** @format */

import { Localized } from "@fluent/react";
import { IconChevronLeft, IconChevronRight } from "components/icons";

const RBCCustomToolbar = props => {
	const { label, views, onView } = props;

	const active = view => {
		if (view === props.view) return { className: "rbc-active" };
		else return {};
	};
	return (
		<div className="rbc-toolbar">
			<div className="rbc-btn-group">
				<button type="button" onClick={() => props.onNavigate("TODAY")} data-cy="today-button">
					<Localized id="calendar-today-btn">Today</Localized>
				</button>
				<button type="button" onClick={() => props.onNavigate("PREV")} data-cy="prev-button">
					<IconChevronLeft />
				</button>
				<button type="button" onClick={() => props.onNavigate("NEXT")} data-cy="next-button">
					<IconChevronRight />
				</button>
			</div>
			<span className="rbc-toolbar-label">{label}</span>
			<div className="rbc-btn-group">
				{views.map(view => (
					<button
						type="button"
						onClick={() => onView(view)}
						data-cy={`${view}-view-button`}
						{...active(view)}
					>
						<Localized id={`calendar-${view}-btn`}>{view}</Localized>
					</button>
				))}
			</div>
		</div>
	);
};

export default RBCCustomToolbar;
