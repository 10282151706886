import React, { useEffect, useState } from "react";
import { IconLink } from "components/icons"
import '../styles/widgets.scss';
const fallbackPadding = '0px 0px 0px 0px';

export default function PaddingWidget(props) {
    const { identifiers, editProperties, properties, editLocks, editorDispatch, room } = props;
    const property = 'padding';
    const value = properties[property] ? properties[property] : fallbackPadding;
    const splittedValues = value.split(" ");
    const [top, setTop] = useState(splittedValues[0].replace('px', ''));
    const [right, setRight] = useState(splittedValues[1].replace('px', ''));
    const [bottom, setBottom] = useState(splittedValues[2].replace('px', ''));
    const [left, setLeft] = useState(splittedValues[3].replace('px', ''));
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        const splittedValues = value.split(" ");
        setTop(splittedValues[0].replace('px', ''));
        setRight(splittedValues[1].replace('px', ''));
        setBottom(splittedValues[2].replace('px', ''));
        setLeft(splittedValues[3].replace('px', ''));
    }, [value, identifiers, editProperties, property]);

    function setPadding(key, value) {
        let newValue = `${top} ${right} ${bottom} ${left}`;
        switch (key) {
            case 'top': newValue = `${value}px ${right}px ${bottom}px ${left}px`; break;
            case 'right': newValue = `${top}px ${value}px ${bottom}px ${left}px`; break;
            case 'bottom': newValue = `${top}px ${right}px ${value}px ${left}px`; break;
            case 'left': newValue = `${top}px ${right}px ${bottom}px ${value}px`; break;
            case 'all': newValue = `${value}px ${value}px ${value}px ${value}px`; break;
            default: break;
        }
        editProperties(identifiers, property, newValue, editorDispatch, room, editLocks);
    }

    return (
        <>
            <div className="tool-inner-container">
                <div className="tool padding">
                    <button className={"simple-button" + (selectAll ? ' active' : '')} onClick={() => setSelectAll(!selectAll)}><IconLink /></button>
                </div>
            </div>
            <div className="tool-inner-container">

                <div className="tool padding">
                    <div className="name">
                        Top
                    </div>
                    <div className="value">
                        {/* <input type="number" className="padding-input" name="padding-top" value={top} onChange={(e) => setPadding(selectAll ? 'all' : 'top', e.target.value)}></input> */}
                        <input type="number" className="padding-input" name="padding-top" value={top} onChange={(e) => setPadding(selectAll ? 'all' : 'top', e.target.value)}></input>
                        <span>px</span>
                    </div>
                </div>
                <div className="tool padding">
                    <div className="name">
                        Left
                    </div>
                    <div className="value">
                        <input type="number" className="padding-input" name="padding-left" value={left} onChange={(e) => setPadding(selectAll ? 'all' : 'left', e.target.value)}></input>
                        <span>px</span>
                    </div>
                </div>
                <div className="tool padding">
                    <div className="name">
                        Right
                    </div>
                    <div className="value">
                        <input type="number" className="padding-input" name="padding-right" value={right} onChange={(e) => setPadding(selectAll ? 'all' : 'right', e.target.value)}></input>
                        <span>px</span>
                    </div>
                </div>
                <div className="tool padding">
                    <div className="name">
                        Bottom
                    </div>
                    <div className="value">
                        <input type="number" className="padding-input" name="padding-bottom" value={bottom} onChange={(e) => setPadding(selectAll ? 'all' : 'bottom', e.target.value)}></input>
                        <span>px</span>
                    </div>
                </div>
            </div >
        </>
    );
};