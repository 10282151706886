const initialState = {
    bellRef: null,
    invitePushNotificationRef: null
}

const refs = (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_BELL_REF': return { ...state, bellRef: action.payload };
        case 'HANDLE_INVITE_PUSH_NOTIFICATION_REF': return { ...state, invitePushNotificationRef: action.payload };
        default: return state;
    }
};

export default refs;
