import { LivetoMiniLogo } from "components/icons"

const UnsupportedWarning = ({ displayType }) => {
    return <>
        {(displayType === "mobile" || displayType === "tablet") &&
            <div className="virtual-event-editor-not-supported">
                Virtual event editor is not supported on mobile devices
                <LivetoMiniLogo color="#c4281a" size={100} />
            </div>
        }
    </>
}

export default UnsupportedWarning