import { parseElementStyle } from "../../constants";
import { Image } from 'react-bootstrap';
import ElementWrapper from 'containers/VirtualEvent/wrappers/elementWrapper';

export const IMAGE_ELEMENT_DEFAULT_PROPERTIES = {
    padding: '0 0 0 0',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    background: {
        "size": "contain",
        "color": "rgba(0,0,0,0)",
        "image": "",
        "repeat": "repeat",
        "position": "center"
    },
    border: {
        border: '0px solid rgba(0,0,0,0)',
        borderRadius: '0px',
    },
    filter: 'none',
    alignItems: 'center',
    display: 'content',
    height: 'auto',
    width: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    boxShadow: 'none',
    pointerEvents: 'none'
}

export default function ImageElement(props) {
    const { elem } = props;
    const { content, properties, i } = elem;
    const wrapperProps = {
        overrideStyle: { display: 'contents' },
        elem: props.elem,
        className: `image-element-wrapper ${i}`
    }
    return <ElementWrapper {...wrapperProps}>
        <Image style={parseElementStyle(properties, IMAGE_ELEMENT_DEFAULT_PROPERTIES)} src={content} />
    </ElementWrapper>

}



