import React from "react";
import '../styles/widgets.scss';

const fallbackSlides = { slides: 0, settings: { autoplay: true, speed: 1000, autoplaySpeed: 3000, vertical: false } };

const CarouselWidget = (props) => {
  const { identifiers, editContent, content = fallbackSlides, editLocks, editorDispatch, room } = props;
  const { settings } = content;

  const updateContent = (name, value) => {
    let newContent;
    switch (name) {
      case 'slides': {
        newContent = { ...content, slides: value }
        break;
      }
      case 'autoplay': {
        newContent = { ...content, settings: { ...settings, autoplay: value } }
        break;
      }
      case 'orientation': {
        newContent = { ...content, settings: { ...settings, vertical: value } }
        break;
      }
      case 'speed': {
        const newSpeed = !value ? 0 : value
        newContent = { ...content, settings: { ...settings, speed: newSpeed } }
        break;
      }
      case 'autoplaySpeed': {
        const newSpeed = !value ? 0 : value
        newContent = { ...content, settings: { ...settings, autoplaySpeed: newSpeed } }
        break;
      }
      default: break;
    }
    editContent(identifiers, newContent, editorDispatch, room, editLocks);
  }

  return (
    <div className="tool-inner-container">
      CAROUSEL
      <div className="tools">
        <div className="tool">
          Autoplay
          <input type="checkbox" checked={settings.autoplay} onChange={(e) => updateContent('autoplay', e.target.checked)} />
        </div>
        <div className="tool">
          Direction
          <div className="buttons">
            <button className={"editor-menu-button" + (!settings.vertical ? ' active' : '')} onClick={(e) => updateContent('orientation', false)}>Horizontal</button>
            <button className={"editor-menu-button" + (settings.vertical ? ' active' : '')} onClick={(e) => updateContent('orientation', true)}>Vertical</button>
          </div>
        </div>
        <div className="tool">
          Speed
          <input className="editor-menu-input" type="number" value={settings.speed} onChange={(e) => updateContent('speed', e.target.value)} />
        </div>
        <div className="tool">
          Autoplay Speed
          <input className="editor-menu-input" type="number" value={settings.autoplaySpeed} onChange={(e) => updateContent('autoplaySpeed', e.target.value)} />
        </div>
      </div>
    </div>
  )
}

export default CarouselWidget