/** @format */

import { HOST } from "constants.js";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AUTH_HEADERS } from "util/api";

export function useFetchPerformers(slug) {
	const [state, setState] = useState({ loading: false, ok: true, status: null, error: null });
	const [data, setData] = useState([]);
	const dispatch = useDispatch();

	async function fetchPerformers(event_slug) {
		try {
			setState(prev => ({ ...prev, laoding: true }));
			const response = await fetch(`${HOST}api/v1/events/${event_slug}/performers/?show_in=virtual`, {
				headers: AUTH_HEADERS,
			});
			setState(prev => ({ ...prev, status: response.status, ok: response.ok }));
			const json = await response.json();
			if (response.ok) {
				setData(json);
				dispatch({ type: "GET_PERFORMER_LISTS", payload: json });
			}
		} catch (error) {
			console.error("error: ", error);
			setState(prev => ({ ...prev, error }));
		} finally {
			setState(prev => ({ ...prev, laoding: false }));
		}
	}

	useEffect(() => {
		if (slug) {
			fetchPerformers(slug);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	return [state, fetchPerformers, data];
}
