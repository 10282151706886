/** @format */

const initialState = {
	performer_lists: [],
	performers: [],
};

const performerReducer = (state = initialState, action) => {
	switch (action.type) {
		case "GET_PERFORMER_LISTS":
			return { ...state, performer_lists: action.payload };
		case "GET_PERFORMERS":
			return { ...state, performers: action.payload };
		default:
			return state;
	}
};

export default performerReducer;
