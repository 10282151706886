import React, { useEffect, useMemo, useState } from "react";
import {
    IconFlexDirection,
    IconTimes,
    IconImage,
    IconAlignCenter,
    IconShadow,
    IconNewItem,
    IconSeparator,
    IconCarousel,
    IconLink,
    IconAlignItems,
    IconEmbed,
    IconVideo,
    IconBarChart,
    IconUsers,
    IconEdit,
    EditorBorderIcon,
    EditorTextIcon,
    EditorPaddingIcon,
    EditorAlignmentIcon,
    EditorBackgroundIcon,
    EditorShadowIcon,
    EditorOverlayIcon
} from "components/icons";

import BorderWidget from '../widgets/border';
import PaddingWidget from '../widgets/padding';
import FlexDirectionWidget from '../widgets/flexDirection';
import JustifyContentWidget from '../widgets/justifyContent';
import AlignItemsWidget from '../widgets/alignItems';
import LinkWidget from '../widgets/link';
import UrlWidget from '../widgets/url';
import TextWidget from '../widgets/text';
import EmbedWidget from '../widgets/embed';
import SeparatorWidget from '../widgets/separator';
import CarouselWidget from '../widgets/carousel';
import ImageUrlWidget from '../widgets/imageUrl';
import BackgroundWidget from '../widgets/background';
import BoxShadowWidget from "../widgets/boxShadow";
import DropShadowWidget from "../widgets/dropShadow";
import VideoSettingsWidget from "../widgets/videoSettings";
import StreamWidget from "../widgets/stream";
import MeetWidget from "../widgets/meet";
import PollWidget from "../widgets/poll";
import { LAYOUT_PROPERTIES_WIDGETS } from '../../constants';
import OverlayWidget from "../widgets/overlay";
import { GenericTooltip } from "components/ToolTip/Tooltip";
import Draggable from 'react-draggable';
import AddModal from "../addModal";
import AlignmentMenu from "../widgets/alignment";

export const PRESET_WIDGETS = {
    component: {
        blank: {
            properties: [
                'border',
                'padding',
                'alignment',
                'background',
                'boxShadow',
                'overlay'
            ],
            actions: [
                'link'
            ],
            can_add_elements: [
                '*'
            ]
        },
        meet: {
            properties: [
                'border',
                'padding',
                'background',
                'boxShadow',
            ],
            actions: [
            ],
            can_add_elements: []
        },
        stream: {
            properties: [
                'border',
                'padding',
                'boxShadow',
                'background'
            ],
            actions: [
            ],
            can_add_elements: []
        },
        poll: {
            properties: [
                'border',
                'padding',
                'boxShadow',
                'background'
            ],
            actions: [
            ],
            can_add_elements: []
        },
        carousel: {
            properties: [
                'border',
                'padding',
                'flexDirection',
                'justifyContent',
                'alignItems',
                'background',
                'boxShadow',
            ],
            actions: [
                'link'
            ],
            content: [
                'carousel'
            ],
            can_add_elements: [
                'slide'
            ]
        },
        'simple card': {
            properties: [
                'boxShadow',
                'border'
            ],
            actions: [
                'link'
            ],
            can_add_elements: [
                'button'
            ]
        },
        'listing card': {
            properties: [
                'boxShadow',
            ],
            actions: [
                'link'
            ],
            can_add_elements: [
                'button'
            ]
        },
        'listing card multi': {
            properties: [
                'boxShadow',
            ],
            actions: [
                'link'
            ],
            can_add_elements: [
                'button'
            ]
        }
    },
    element: {
        image: {
            properties: [],
            actions: [
                'link'
            ],
            content: ['image'],
        },
        text: {
            properties: [
                'filter',
            ],
            actions: [
                'link'
            ],
            content: [
                'text'
            ]
        },
        stream: {
            properties: [
                'videoSettings'
            ],
            actions: [],
            content: [
                'stream'
            ]
        },
        video: {
            properties: [
                'videoSettings'
            ],
            actions: [],
            content: [
                'url'
            ]
        },
        meet: {
            properties: [],
            actions: [],
            content: [
                'meet'
            ]
        },
        poll: {
            properties: [],
            actions: [],
            content: [
                'poll'
            ]
        },
        slide: {
            properties: [
                'background',
                'overlay'
            ],
            actions: [
                'link'
            ],
            content: [
                'text'
            ]
        },
        embed: {
            properties: [

            ],
            actions: [],
            content: [
                'embed'
            ]
        },
        button: {
            properties: [
                'background',
                'border',
                'padding',
                'boxShadow'
            ],
            actions: [
                'link'
            ],
            content: [
                'text'
            ]
        },
        separator: {
            properties: [
                'background',
            ],
            actions: [
            ],
            content: [
                'separator'
            ]
        }
    }
}

export const WIDGET_COMPONENTS = {
    content: {
        'separator': {
            component: <SeparatorWidget />,
            button: <IconSeparator />,
        },
        'text': {
            component: <TextWidget />,
            button: <EditorTextIcon />
        },
        'embed': {
            component: <EmbedWidget />,
            button: <IconEmbed />,
        },
        'image': {
            component: <ImageUrlWidget />,
            button: <IconImage />,
        },
        'carousel': {
            component: <CarouselWidget />,
            button: <IconCarousel />,
        },
        'url': {
            component: <UrlWidget />,
            button: <IconVideo />,
        },
        'stream': {
            component: <StreamWidget />,
            button: <IconVideo />,
        },
        'meet': {
            component: <MeetWidget />,
            button: <IconUsers />
        },
        'poll': {
            component: <PollWidget />,
            button: <IconBarChart />
        }
    },
    actions: {
        'link': {
            component: <LinkWidget />,
            button: <IconLink />
        },
    },
    properties: {
        'border': {
            component: <BorderWidget />,
            button: <EditorBorderIcon />,
        },
        'padding': {
            component: <PaddingWidget />,
            button: <EditorPaddingIcon />,
        },
        'flexDirection': {
            component: <FlexDirectionWidget />,
            button: <IconFlexDirection />
        },
        'alignment': {
            component: <AlignmentMenu />,
            button: <EditorAlignmentIcon />,
        },
        'justifyContent': {
            component: <JustifyContentWidget />,
            button: <IconAlignCenter />,
        },
        'alignItems': {
            component: <AlignItemsWidget />,
            button: <IconAlignItems />
        },
        'background': {
            component: <BackgroundWidget />,
            button: <EditorBackgroundIcon />
        },
        'boxShadow': {
            component: <BoxShadowWidget />,
            button: <EditorShadowIcon />
        },
        'overlay': {
            component: <OverlayWidget />,
            button: <EditorOverlayIcon />
        },
        'filter': {
            component: <DropShadowWidget />,
            button: <IconShadow />
        },
        'videoSettings': {
            component: <VideoSettingsWidget />,
            button: <IconVideo />
        }
    }
}

// Creates the corret component according to the constant list
export const createWidgetComponent = (props, identifiers, type, widgetType) => {
    if (!type) return;
    return React.cloneElement(WIDGET_COMPONENTS[widgetType][type].component, { ...props, identifiers });
}

// Capitalizes first letter of a string, adds space between words
const capitalizeFirstLetter = (s) => {
    for (let letter of s) {
        if (letter === letter.toUpperCase()) {
            s = s.replace(letter, " " + letter);
        }
    }
    return s[0].toUpperCase() + s.slice(1);
}

function widgetPositions(widget) {
    switch (widget) {
        case 'border': return { x: 0, y: 200 };
        case 'padding': return { x: 10, y: 200 };
        case 'flexDirection': return { x: 20, y: 200 };
        case 'justifyContent': return { x: 30, y: 200 };
        case 'alignItems': return { x: 40, y: 200 };
        case 'background': return { x: 50, y: 200 };
        case 'boxShadow': return { x: 60, y: 200 };
        case 'overlay': return { x: 70, y: 200 };
        case 'link': return { x: 80, y: 200 };
        case 'text': return { x: 90, y: 200 };
        case 'image': return { x: 100, y: 200 };
        case 'url': return { x: 100, y: 200 };
        case 'embed': return { x: 110, y: 200 };
        case 'videoSettings': return { x: 110, y: 200 };

        default: return { x: 0, y: 0 };
    }

}
function Drag(props) {
    const { widget, children, setExpanded, show } = props;
    if (!show) return <></>;
    return <div className="widget-canvas">
        <Draggable
            axis="both"
            handle=".widget-handle"
            defaultPosition={widgetPositions(widget)}
            grid={[1, 1]}
            scale={1}
            bounds={{ top: 0 }}

        >
            <div className="widget">
                <div className="widget-close" onClick={e => setExpanded(false)}><IconTimes style={{ color: '#C4281A' }} size="18" /></div>
                <div className="widget-handle">
                    <div className="widget-handle-border"></div>
                    <div className="widget-handle-border"></div>
                    <div className="widget-handle-border"></div>
                </div>
                {children}
            </div>
        </Draggable>
    </div>
}

export const GenerateWidgets = (props) => {
    // Generate correct widgets for the item and add them to navigational tabs
    // Properties
    const [showPropertyWidgets, setShowPropertyWidgets] = useState([]);

    // Actions
    const [showActionWidgets, setShowActionWidgets] = useState(false);

    // Content
    const [showContentWidget, setShowContentWidget] = useState(true)

    const [showRename, setShowRename] = useState(false)
    // Add Element
    const [showAddElement, setShowAddElement] = useState(false);
    const {
        type,
        parentId,
        i,
        slug = null,
        editLocks,
        editorDispatch,
        room,
        view,
    } = props;
    const identifiers = {
        component: type === 'element' ? parentId : i,
        element: type === 'element' ? i : null
    }

    const memoizedIdentifiers = useMemo(() => (identifiers), [identifiers.component, identifiers.element])

    function renameComponent(e) {
        props.editName(identifiers, e.target.value)
    }

    useEffect(() => {
        setShowContentWidget(true)
        setShowActionWidgets(false)
        setShowAddElement(false)
        setShowPropertyWidgets(Array(propertyWidgets.length).fill(false))
    }, [memoizedIdentifiers])

    let propertyWidgets = [];
    let actionWidgets = [];
    let contentWidgets = [];
    let elementAddButton = [];
    if (slug) {
        propertyWidgets = LAYOUT_PROPERTIES_WIDGETS;
    } else {
        const presetKey = type === 'component' ? props.preset : props.renderType;
        propertyWidgets = PRESET_WIDGETS[type][presetKey]['properties'];
        actionWidgets = props.actions ? Object.keys(props.actions).filter(action => PRESET_WIDGETS[type][presetKey]['actions'].includes(action)) : [];
        contentWidgets = PRESET_WIDGETS[type][presetKey]['content'] ? PRESET_WIDGETS[type][presetKey]['content'] : [];
        elementAddButton = PRESET_WIDGETS[type][presetKey]['can_add_elements']?.length > 0;
    }
    const name = typeof props.name === 'string' ? props.name : props.name['en'];
    return (<div>
        <Draggable
            axis="both"
            handle=".widget-handle"
            defaultPosition={{ x: 0, y: 0 }}
            grid={[1, 1]}
            scale={1}
            bounds={{ top: 0, right: 0 }}
        >
            <div className="widget-explorer">
                <div className="widget-handle">
                    <div className="widget-handle-border"></div>
                    <div className="widget-handle-border"></div>
                    <div className="widget-handle-border"></div>
                </div>
                {showRename ? <div id="rename-container">
                    <input className="rename-input" onChange={e => renameComponent(e)} value={name} />
                    <button className="save-rename" onClick={() => setShowRename(false)}>save</button>
                </div> : <>
                    <h2 className="component-name">{name}</h2>
                    {!slug && <button className="toggle-rename" onClick={() => setShowRename(!showRename)}><IconEdit /></button>}
                </>
                }
                {contentWidgets?.length > 0 && <div className="widget-explorer-content">
                    <h2 className="widget-explorer-heading">Content editors</h2>
                    <GenericTooltip orientation={'bottom'} show={200} hide={200} text={capitalizeFirstLetter(contentWidgets[0])}>
                        <button className={"open-widget-button" + (showContentWidget ? ' active' : '')} onClick={e => setShowContentWidget(!showContentWidget)}>
                            {WIDGET_COMPONENTS.content[contentWidgets[0]].button}
                        </button>
                    </GenericTooltip>

                </div>}
                {propertyWidgets.length > 0 && <div className="widget-explorer-properties">
                    <h2 className="widget-explorer-heading">Style editors</h2>
                    {propertyWidgets?.map((button, i) => {
                        return (<GenericTooltip orientation={'bottom'} show={200} hide={200} text={capitalizeFirstLetter(propertyWidgets[i])}>
                            <button
                                className={"open-widget-button" + (showPropertyWidgets[i] ? ' active' : '')}
                                onClick={e => setShowPropertyWidgets(widgets => {
                                    const _widgets = widgets.slice();
                                    _widgets[i] = !_widgets[i]
                                    return _widgets
                                })}>
                                {WIDGET_COMPONENTS.properties[propertyWidgets[i]].button}
                            </button>
                        </GenericTooltip>)
                    })}
                </div>}

                {actionWidgets?.length > 0 && <div className="widget-explorer-add-element">
                    <h2 className="widget-explorer-heading">Action editors</h2>
                    <GenericTooltip orientation={'bottom'} show={200} hide={200} text={'Link'}>
                        <button className={"open-widget-button" + (showActionWidgets ? ' active' : '')} onClick={e => setShowActionWidgets(!showActionWidgets)}><IconLink /></button>
                    </GenericTooltip>
                </div>}


                {elementAddButton?.length > 0 && <div className="widget-explorer-add-element">
                    <h2 className="widget-explorer-heading">Add element</h2>
                    <GenericTooltip orientation={'bottom'} show={200} hide={200} text={'Add Element'}>
                        <button className={"open-widget-button" + (showAddElement ? ' active' : '')} onClick={e => setShowAddElement(!showAddElement)}><IconNewItem /></button>
                    </GenericTooltip>
                </div>}
            </div>

        </Draggable>

        {propertyWidgets?.map((propWidget, i) => {
            return (
                <Drag show={showPropertyWidgets[i]} widget={propertyWidgets[i]} setExpanded={e => setShowPropertyWidgets(prevState => {
                    const newState = prevState.slice();
                    newState[i] = !newState[i]
                    return newState;
                })}>
                    {createWidgetComponent(props, memoizedIdentifiers, propWidget, 'properties')}
                </Drag>)
        })}

        <Drag show={contentWidgets[0] && showContentWidget} widget={contentWidgets?.[0]} setExpanded={setShowContentWidget}>
            {createWidgetComponent(props, memoizedIdentifiers, contentWidgets?.[0], 'content')}
        </Drag>

        <Drag show={actionWidgets[0] && showActionWidgets} widget={actionWidgets?.[0]} setExpanded={setShowActionWidgets}>
            {createWidgetComponent(props, memoizedIdentifiers, actionWidgets?.[0], 'actions')}
        </Drag>

        {/* {showAddElement && <AddModal room={room} view={view} editorDispatch={editorDispatch} editLocks={editLocks} type={'element'} showModal={showAddElement} setShowModal={setShowAddElement} selected={{ ...props, parentId: props.i, lastElementIndex: props?.elements?.length }} />} */}
    </div>)

};

